import { StorageHandler } from "@lumen-developer/lumen-common-js";
import { store } from "../../../../store";
import Endpoint from "../../Endpoint";

const getPayload = (nextButtonText) => {
  let { sessionId, studyId, cellId, showGaze, participantId } = store.getState().studyReducer;
  let { region } = store.getState().baseReducer;
  let { stepData } = store.getState().stepReducer;

  let body = {
    sessionId: !!sessionId.toString ? sessionId.toString() : sessionId,
    study_id: studyId,
    cell_id: cellId,
    participant_id: participantId,
    show_gaze_points: !!showGaze ? showGaze : false,
    calibrationString:
      '{ "model": ' +
      StorageHandler.getItem("lr_model_data") +
      ', "ec": ' +
      StorageHandler.getItem("lr_errorcorrection_data") +
      "}",
    environment:  process.env.REACT_APP_LUMEN_ENV_VALUE,
    step_data: stepData,
    region: region,
    nextButtonText: nextButtonText,
  };

  return body
};

export default (nextButtonText) => {
  return new Endpoint(
    "save-calibration-data",
    `${process.env.REACT_APP_PARTICIPATION_URL}/ctt_calibration_data/save`,
    "POST",
    () => getPayload(nextButtonText),
    undefined,
    true
  );
};
