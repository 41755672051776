import React from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import anime from "animejs";
import LSMenuCont from "../components/menu/containers/menuCont";
import Button from "../components/shared/Button";
import { useNavigate } from "react-router-dom";
import StepController from "../controllers/StepController";
import PageController from "../controllers/PageController";

const Footer = () => {
  let footerRef = useRef();
  let { footerState, footerType, pageRouteOut, pageShowNext } = useSelector(
    (state) => state.pageReducer
  );

  let { stepCompleted, stepRouteOut, stepShowNext } = useSelector(
    (state) => state.stepReducer
  );

  let navigate = useNavigate();

  if (footerRef && footerState === "transitionIn") {
    anime({
      targets: footerRef.current,
      height: "70px",
      duration: 200,
      easing: "linear",
      elasticity: "none",
      delay: 500,
      endDelay: 500,
    });
  } else if (footerRef && footerState === "transitionOut") {
    anime({
      targets: footerRef.current,
      height: "0px",
      duration: 200,
      easing: "linear",
      elasticity: "none",
      delay: 500,
      endDelay: 500,
    });
  }

  const handlePageNavigate = async () => {
    let routeOut = await PageController.moveNext();
    if (routeOut) {
      navigate(pageRouteOut);
    }
  };

  const handleStepComplete = async () => {
    await StepController.moveNext();
  };

  const switchType = () => {
    switch (footerType) {
      case "index":
        return (
          <>
            <div className="ls-footer-mob">
              <LSMenuCont></LSMenuCont>
            </div>
            {pageShowNext && (
              <Button
                dataTestId="next-button"
                onClick={handlePageNavigate}
                text="Next"
                translationId="app.button.next"
                singleClick={true}
              ></Button>
            )}
          </>
        );
      case "question":
        return <></>;
      case "preview":
        return (
          <>
            {stepShowNext && (
              <Button
                dataTestId="next-button"
                onClick={handleStepComplete}
                text="Next"
                translationId="app.button.next"
                singleClick={true}
              ></Button>
            )}
          </>
        );
      case "setup":
        return (
          <>
            {pageShowNext && (
              <Button
                dataTestId="next-button"
                onClick={handlePageNavigate}
                text="Next"
                translationId="app.button.next"
                singleClick={true}
              ></Button>
            )}
          </>
        );
      default:
        return (
          <>
            {stepShowNext && (
              <Button
                dataTestId="next-button"
                onClick={handleStepComplete}
                text="Next"
                translationId="app.button.next"
                singleClick={true}
              ></Button>
            )}
          </>
        );
    }
  };

  return (
    <div
      style={{ display: footerState === "hide" ? "none" : "block" }}
      className="ls-footer"
      ref={footerRef}
    >
      <div className={`ls-footer-cont ls-footer-${footerType}`}>
        {switchType()}
      </div>
    </div>
  );
};

export default Footer;
