{
  "title": "﻿منصة Lumen Study - سياسة الخصوصية",
  "date": "يوليو 2022",
  "contactDetails": {
    "title": "البيانات المفصلة للاتصال بنا ",
    "body": [
      "الاسم: Lumen Research Ltd (يُشار ليها بـ \"Lumen \")",
      "العنوان:Screenworks, Unit 215, 22 Highbury Grove, London N5 2ER",
      "رقم الهاتف: 2037355199(0)44+ ",
      "البريد الإلكتروني: dataprotection@lumen-research.com",
      "الرقم المرجعي للتسجيل بمفوضية المعلومات: ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "تشرح سياسة الخصوصية هذه طبيعة المعلومات التي نجمعها من المشاركين الذين يشاركون في استبيان تتبع العين على منصة Lumen Study، وكذلك سياستنا التي تحكم أي معلومات شخصية يمكن جمعها. "
      ],
      "heading": "مقدمة"
    },
    {
      "body": [
        "في أي استبيان عادي، لا تجمع Lumen المعلومات الشخصية نظرًا لالتزامها بما تنص عليه اللائحة العامة لحماية البيانات بالمملكة المتحدة، ولكنها تجمع تقديرات نقاط تحديق النظر؛ والأجوبة على الاستبيان؛ والبيانات الوصفية حول وقت جمع البيانات ونوع الجهاز الذي جُمِعت البيانات من خلاله؛ ومُعرِّفات المشاركين في الاستبيان لأغراض إدارة مكافآتهم.",
        "ومن ثم، في أي استبيان عادي، سيُطلب منك الجلوس أمام كاميرا الويب أو الكاميرا الأمامية بجهازك وإجراء معايرة بحيث يمكن تقدير المكان الذي تنظر إليه في شاشتك. وسوف يُطلب منك خلال الاستبيان عرض بعض العناصر (مثل الصور أو مواقع الويب أو مقاطع الفيديو) أثناء تقدير أداة الاستبيان للمكان الذي تنظر فيه. وتكون البيانات التي يتم جمعها من خلال \"تتبع العين\" عبارة عن إحداثيات نقاط النظر المقدرة على شاشتك بمرور الوقت. وسوف يستخدم البرنامج صورًا لك قمت أنت بالتقاطها من خلال كاميرا الويب بجهازك لإجراء هذه التقديرات ولكنه لن يلتقط صورًا أو مقاطع فيديو لك. ",
        "وقد تُطرح عليك أيضًا بعض الأسئلة حول نفسك وتجربتك والأشياء التي رأيتها خلال الاستبيان. ",
        "سوف تُرسل البيانات المستمدة من هذه الاستبيان إلى شركة Lumen Research Ltd (التي يُشار ليها بـ \"Lumen \"). حيث ستقوم Lumen بتحليل البيانات ومشاركة النتائج مع عملائها. ",
        "وفي هذه الاستبيانات، سيتم تقديم مُعرِّف المشارك الأبجدي الرقمي من قِبل طرف خارجي إلى Lumen حتى تتمكن Lumen من تحديد أي المشاركين الذي يكمل الاستبيان. وسوف تشارك Lumen معلومات إكمال الاستبيان تلك مع الأطراف الخارجية بغرض إدارة عملية تقديم المكافآت إلى أولئك الذين أكملوا الاستبيان. "
      ],
      "heading": "نوع المعلومات التي نجمعها"
    },
    {
      "body": [
        "في بعض الأحيان، قد يُطلب من المشاركين المشاركة في مشروع بحثي يتطلب جمع البيانات في فترات متعددة. وفي هذه الحالة، سيتم استخدام مُعرِّف المشارك كمُعرِّف دائم وستتم معاودة الاتصال بكل مشارك بشأن بدء مشاركته في أي نشاط لاحق لجمع البيانات. وفي سياق هذا السيناريو، سيعتبر مُعرِّف المشارك معلوماتٍ شخصية.  "
      ],
      "heading": "نوع المعلومات الشخصية التي نجمعها "
    },
    {
      "body": [
        "كما هو الحال في أي استبيان عادي، سوف يتم تقديم مُعرِّف المشارك الدائم من قِبل الطرف الخارجي الذي قام بتوظيف المشارك في بحثنا. وسنقوم نحن باستخدام ذلك المُعرِّف لمعاودة الاتصال بالأشخاص بشأن بدء مشاركتهم اللاحقة في البحث، وقد نشارك هذا المُعرِّف مع أطراف خارجية لتيسير تلك المشاركة اللاحقة.",
        "وبموجب اللائحة العامة لحماية البيانات بالمملكة المتحدة (UK GDPR)، تُعد موافقتك هي الأساس القانوني الذي نستند إليه في معالجة تلك المعلومات. ويمكنك سحب موافقتك في أي وقت، وذلك عن طريق التواصل معنا من خلال dataprotection@lumen-research.com"
      ],
      "heading": "كيف نحصل على المعلومات الشخصية ولماذا"
    },
    {
      "body": [
        "يتم تخزين معلوماتك وتشفيرها بأمان باستخدام تقنية طبقة المنافذ الآمنة \"SSL\". ويُعد هذا هو المعيار الرفيع الأكثر استخدامًا لتقنية التشفير والذي تستخدمه المصارف لتأمين المعاملات المصرفية وغيرها من الاتصالات شديدة الحساسية. كما تستخدمه أيضًا مواقع ويب أمازون وباي بال وإي باي وغيرها من مواقع الويب التي تتطلب أنظمة أمان على مستوى أمان المصارف. ويُقدر العلماء أن فك تشفير 256 بت باستخدام التكنولوجيا الحالية سيتطلب وقتًا أطول بكثير من عمر الكون. وتُخزَّن البيانات التي يتم جمعها على خوادم ويب آمنة تديرها شركة Amazon Web Services (يُشار إليها بـ \"AWS\"). وتُعد منصة شركة AWS واحدة من أكثر منصات خدمات الحوسبة السحابية استخدامًا وأمانًا وموثوقية، حيث تستخدمها الخدمات التجارية السحابية الرئيسية بما في ذلك خدمة Netflix و Expedia. ",
        "نحن نحتفظ بالمُعرِّفات الدائمة طوال الفترة الزمنية اللازمة لمعاودة الاتصال بالمشاركين في أي بحث متعدد المراحل. وقد يختلف ذلك الأمر استنادًا إلى طبيعة البحث، وسوف تتلقى إخطارًا بذلك عند تقديمك موافقتك على المشاركة. وعندما ينتهي جمع البيانات المتعلقة بأي مشروع، فإننا لا نحتفظ بالمُعرِّفات إلا على النحو اللازم لتحليل البيانات وإعداد التقارير بشأنها. وحينما يتم الانتهاء من تحليل المشروع وإعداد التقارير بشأنه، فإننا سنتخلص من هذه المعلومات الشخصية عن طريق حذفها من قواعد بياناتنا."
      ],
      "heading": "كيف نخزن معلوماتك الشخصية "
    }
  ],
  "dataProtection": {
    "body": ["بموجب قانون حماية البيانات، فإن حقوقك تشمل ما يلي:"],
    "bullets": [
      {
        "title": "حقك في الوصول",
        "text": ["يحق لك أن تطلب منا نسخًا من معلوماتك الشخصية."]
      },
      {
        "title": "حقك في التصحيح",
        "text": [
          "يحق لك أن تطلب منا تصحيح المعلومات الشخصية التي تعتقد أنها غير دقيقة، كما يحق لك أيضًا أن تطلب منا إكمال المعلومات التي تعتقد أنها غير مكتملة."
        ]
      },
      {
        "title": "حقك في المحو",
        "text": ["يحق لك أن تطلب منا محو معلوماتك الشخصية في ظروف معينة."]
      },
      {
        "title": "حقك في تقييد المعالجة",
        "text": [
          "يحق لك أن تطلب منا تقييد معالجة معلوماتك الشخصية في ظروف معينة."
        ]
      },
      {
        "title": "حقك في سحب الموافقة",
        "text": ["يحق لك سحب موافقتك على معالجة معلوماتك الشخصية."]
      },
      {
        "title": "حقك في نقل البيانات",
        "text": [
          "يحق لك أن تطلب منا نقل المعلومات الشخصية التي قدمتها إلينا إلى مؤسسة أخرى، أو إليك، في ظل ظروف معينة.",
          "لا يُطلب منك دفع أية رسوم مقابل ممارستك لحقوقك. وفي حال تقديمك أي طلب إلينا، فقد نستغرق حينئذ ما يصل إلى شهر واحد للرد عليك.",
          "يُرجى التواصل معنا من خلال البريد الإلكتروني dataprotection@lumen-research.com إذا كنت ترغب في تقديم طلب."
        ]
      }
    ],
    "title": "حقوق حماية بياناتك"
  },
  "complaints": {
    "body": [
      "إذا كانت لديك أية مخاوف بشأن استخدامنا لمعلوماتك الشخصية، فيمكنك تقديم شكوى إلينا من خلال البريد الإلكتروني dataprotection@lumen-research.com.",
      "ويمكنك أيضًا تقديم شكوى إلى مفوضية المعلومات إذا كنت غير راضٍ عن كيفية استخدامنا لبياناتك."
    ],
    "address": {
      "body": [
        "Information Commissioner’s Office",
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ],
      "title": "عنوان مفوضية المعلومات:            "
    },
    "title": "كيفية تقديم الشكاوى",
    "number": "رقم هاتف المساعدة: 1113 123 0303",
    "website": "الموقع الإلكتروني لمفوضية المعلومات: https://www.ico.org.uk"
  }
}
