{
    "app.button.faqs": "Često postavljana pitanja",
    "app.button.terms": "Uvjeti uporabe",
    "app.button.privacy": "Politika zaštite privatnosti",
    "app.button.feedback": "Povratne informacije",
    "app.button.selectLanguage": "Odaberite jezik",
    "app.button.help": "Pomoć",
    "app.button.start": "Pokreni",
    "app.button.next": "Dalje",
    "app.button.submit": "Pošalji",
    "app.button.close": "Zatvori",
    "app.button.takepart": "U redu – Sudjelovat ću",
    "app.menu.feedback.content": "Ako ste naišli na problem u vezi s istraživanjem ili biste samo željeli ostaviti komentar, molimo da to učinite ovdje.",
    "app.contactModal.feedback": "Hvala vam na vašoj povratnoj informaciji!",
    "app.takePartModal.line1": "Ako sada napustite stranicu, nećete imati pravo na nagradu za sudjelovanje.",
    "app.takePartModal.line2": "Sve što vam je potrebno je web kamera!",
    "app.takePartModal.reason1": "Nemam web kameru.",
    "app.takePartModal.reason2": "Nije mi ugodno koristiti web kameru za ovu anketu.",
    "app.takePartModal.reason3": "Nemam sada vremena za ovo.",
    "app.takePartModal.reason4": "Drugi razlog.",
    "app.generic.redirect": "Uskoro ćemo vas preusmjeriti.",
    "app.init.welcome": "Dobrodošli na platformu za istraživanje Lumen Research",
    "app.init.intro": "Platforma za istraživanje omogućuje nam dodavanje i postavljanje korisnika u određenom istraživanju oglasa.",
    "app.init.message1": "Ovisno o vrsti istraživanja, korisnika će voditi kroz skupinu zadataka.",
    "app.init.message2": "Ti zadaci uključuju sudjelovanje u anketi, čitanje kompleta uputa i izvođenje kalibracije praćenja pokreta očiju",
    "app.init.message3": "Sada ćemo vas provesti kroz demonstracijsko istraživanje.",
    "app.init.message4": "Tijekom istraživanja možda ćemo omogućiti vašu web kameru kako bismo proveli praćenje očiju, no nikakvi vaši podaci neće biti zabilježeni.",
    "app.init.message5": "Trenutačno istraživanje je postavljeno za upotrebu sljedećih poveznica pri probiru/završetku.",
    "app.prestart.loading": "Učitavanje u tijeku, molimo pričekajte.",
    "app.index.title": "Dobrodošli",
    "app.index.content.line1": "Tijekom ove ankete upotrijebit ćemo vašu kameru kako bismo procijenili u kojem smjeru gledate. Ne brinite, nećemo napraviti nikakve fotografije niti videozapise.",
    "app.index.content.line1alt": "Molimo vas, pobrinite se da udobno sjedite i zatim dodirnite pokreni za početak.",
    "app.index.content.line2": "Ima li vaš uređaj kameru? Ako ima, pritisnite gumb za pokretanje u nastavku.",
    "app.index.rejection": "Žao nam je, izgleda da ste kliknuli na neispravnu poveznicu.",
    "app.index.webview": "Žao nam je, izgleda da ste našoj stranici pristupili unutar web prikaza. Molimo, otvorite ovu poveznicu u svom pregledniku. To je možda moguće napraviti izravno putem izbornika web prikaza.",
    "app.index.webview.chrome.1": "Dodirnite gumb izbornika iznad i zatim odaberite opciju „Otvori u Chromeu“.",
    "app.index.webview.chrome.2": "Anketa će se zatim otvoriti u aplikaciji preglednika kako bi mogla raditi ispravno.",
    "app.index.webview.android.1": "Dodirnite Pokreni u nastavku kako biste otvorili ovu anketu u aplikaciji preglednika kako bi mogla raditi ispravno.",
    "app.index.webview.android.2": "Ako to ne uspije, otvorite poveznicu na ovu stranicu u aplikaciji preglednika ručno (npr. kopirajte i zalijepite poveznicu u traku za adresu u Chromeu ili upotrijebite opciju „Otvori u Chromeu“ u traci izbornika iznad).",
    "app.index.webview.safari.1": "Dodirnite ikonu Safari na dnu ekrana kako biste nastavili.",
    "app.index.webview.safari.2": "Izgleda ovako:",
    "app.index.webview.safari.3": "Otvorit će se anketa u Safariju kako bi mogla raditi ispravno.",
    "app.index.wifi": "Izgleda da je vaša internetska veza prespora. Jeste li spojeni na WiFi?",
    "app.index.tellmemore": "Reci mi više.",
    "app.index.takepart": "Ne želim sudjelovati.",
    "app.index.webcamRetry": "Nije bilo moguće detektirati web kameru koja je potrebna za ovo istraživanje. Molimo, omogućite pristup svojoj web kameri i pokušajte ponovno.",
    "app.index.adblockRetry": "Ups! Detektirali smo program za blokiranje reklama. Za najbolje iskustvo s istraživanjem, molimo vas da ga isključite i pokušate ponovno.",
    "app.instructions.title": "Upute",
    "app.instructions.instruction1": "Ako vam to nije problem, nemojte nositi naočale tijekom ankete.",
    "app.instructions.instruction2": "Sjednite kako biste svoju glavu i uređaj udobno mogli držati na miru.",
    "app.instructions.instruction3": "Pomičite svoje oči, a ne glavu.",
    "app.instructions.instruction4": "Jeste li spremni započeti?",
    "app.instructions.instruction5": "Molimo, upotrebljavajte WiFi kako biste izbjegli naknade za podatkovni promet.",
    "app.instructions.instruction6": "Molimo vas da pažljivo pročitate sljedeće upute.",
    "app.instructions.instruction7": "Neka vaš telefon leži na stabilnoj površini.",
    "app.instructions.instruction8": "Pobrinite se da vaša soba bude dobro osvijetljena – bilo bi dobro da to bude odozgo (ne od iza).",
    "app.setup.title": "Postavljanje",
    "app.setup.instruction0": "Držite svoj uređaj u portretnom načinu rada.",
    "app.setup.instruction0a": "Molimo, okrenite svoj uređaj u portretni način rada.",
    "app.setup.instruction0b": "Molimo, okrenite svoj uređaj kako bi njegova kamera bila na lijevoj strani.",
    "app.setup.instruction0c": "Molimo, okrenite svoj uređaj kako bi njegova kamera bila na desnoj strani.",
    "app.setup.instruction0d": "Molimo, okrenite svoj uređaj u pejzažni način rada.",
    "app.setup.instructioncamera": "Molimo, kliknite gumb u nastavku kako biste zatražili pristup kameri. Kako biste dovršili anketu, trebate dopustiti dozvole kamere kroz istraživanje.",
    "app.setup.cameraText": "Zatraži pristup",
    "app.setup.instruction1": "Inicijalizacija...",
    "app.setup.instruction2": "Kada ste detektirani, postavite svoju glavu unutar kvadrata.",
    "app.setup.instruction3": "Držite svoju glavu i uređaj u tom položaju do dovršetka ankete.",
    "app.setup.instruction4": "Sada moramo samo napraviti potvrdu.",
    "app.setup.instruction5": "Hvala! Ne zaboravite držati glavu i uređaj u istom položaju sve do dovršetka ankete.",
    "app.setup.instruction6": "Prstom prijeđite prema gore kako biste sakrili traku s adresom.",
    "app.setup.retry": "Detektirana je pogreška. Molimo, držite svoju glavu i uređaj mirnima i ne pokrivajte kameru prstima. Za ponovni pokušaj upotrijebite gumb ispod.",
    "app.setup.retryInit": "Detektirana je pogreška. Pobrinite se da preglednik ima pristup vašoj kameri. Ako ste prije odbacili dopuštenja za pristup kameri, morat ćete ih prihvatiti u postavkama za privatnost preglednika. Za ponovni pokušaj upotrijebite gumb ispod.",
    "app.setup.retryButton": "Ponovno pokušaj",
    "app.setup.calibrationProp.watch": "Slijedite točku kada se pojavi na ekranu",
    "app.setup.calibrationProp.watch.george": "Zatim, kada vidite lice, nastavite ga gledati dok zrcalite njegove pokrete glave.",
    "app.setup.calibrationProp.click": "Za nastavak kliknite bilo gdje",
    "app.setup.calibrationProp.tap": "Za nastavak dodirnite bilo gdje",
    "app.study.validationProp.watch": "Slijedite točku kada se pojavi na ekranu",
    "app.study.validationProp.restart": "Molimo, pokušajte ponovno",
    "app.study.validationProp.click": "Za nastavak kliknite bilo gdje",
    "app.study.validationProp.tap": "Za nastavak dodirnite bilo gdje",
    "app.study.render.error": "Domena nije valjana za korak renderiranja.",
    "app.error.title": "Pogreška",
    "app.error.generic": "Ups! Nešto je pošlo po zlu.",
    "app.error.initialise": "Inicijalizacija nije moguća!",
    "app.error.findCamera": "Nije moguće pronaći kameru!",
    "app.error.headPosition": "Nije moguće odrediti položaj glave.",
    "app.error.calibrate": "Kalibracija nije moguća.",
    "app.error.validate": "Potvrda nije moguća.",
    "app.error.start": "Pokretanje nije uspjelo.",
    "app.error.assign": "Dodjela nije uspjela.",
    "app.error.osnotallowed": "Probir: Vaš operativni sustav nije dopušten, molimo, provjerite koji uređaj možete koristiti za ovu anketu i pokušajte ponovno.",
    "app.error.demoquotafullnoactivecell": "Kvota je ispunjena: Hvala vam na zanimanju. Ovo istraživanje sada je zatvoreno za vašu demografsku grupu.",
    "app.error.missingpanelparamter": "Nedostaje parametar panela.",
    "app.invalid.title": "Nije valjano",
    "app.invalid.generic": "Žao nam je, ne ispunjavate kriterije za ovo istraživanje.",
    "app.completion.title": "Dovršetak",
    "app.completion.congratulations": "Čestitamo! Dovršili ste istraživanje. Pristup vašoj kameri u sklopu ovog istraživanja sada će biti prekinut.",
    "app.questions.required": "Ovo pitanje je obavezno.",
    "app.faq.question1": "P: Što je platforma Lumen Study Platform i što uključuje sudjelovanje u anketi s praćenjem pokreta očiju?",
    "app.faq.answer1": "O: Ova anketa uključuje tehnologiju praćenja očiju. Zamolit ćemo vas da sjednete ispred web kamere uređaja ili tako da budete okrenuti licem prema kameri i da izvedete kalibraciju kako bismo napravili procjenu smjera vašeg pogleda na vašem ekranu. Tijekom ankete zamolit ćemo vas za pogledate neke stavke (npr. slike, web stranice ili videozapise) dok alat ankete procjenjuje smjer vašeg pogleda. Tim „praćenjem pokreta očiju“ prikupljaju se podaci koji služe kao koordinate procijenjenih točaka pogleda na vašem ekranu tijekom vremena. Softver koristi vaše slike s vaše web kamere kako bi napravio te procjene, no ne radi snimke niti videozapise vas. Možda ćemo vam i postaviti nekoliko pitanja o vama, vašem iskustvu i stavkama koje ste vidjeli tijekom ankete. Podaci se šalju tvrtki Lumen Research Ltd. Tvrtka Lumen Research analizirat će podatke i podijeliti rezultate sa svojim klijentima.",
    "app.faq.question2": "P: Zašto je softveru potrebna web kamera / kamera i kako se ona koristi?",
    "app.faq.answer2": "O: Softver koristi slike s vaše web kamere ili kamere uređaja kako bi procijenio smjer vašeg pogleda na ekranu. To čini kombinacijom softvera za praćenje lica i praćenje pokreta očiju koji analizira slike lokalno na vašem stroju u stvarnom vremenu. Te slike se obrađuju i prikazuju se vama tijekom kalibracije kako biste se mogli ispravno postaviti u položaj, no slike se ne snimaju pa je vaša privatnost zaštićena. Podaci koji se snimaju i prikupljaju putem praćenja pokreta očiju nisu slike, već numerički podaci o procijenjenim točkama pogleda na stimulansu tijekom određenog vremena.",
    "app.faq.question3": "P: Snimate li slike mene tijekom provedbe praćenja pokreta očiju?",
    "app.faq.answer3": "O: Jedino kada se slike koriste je za omogućavanje ispravnog postavljanja u položaj tijekom kalibracije. Te slike se ne spremaju, a vidite ih samo vi. Podaci koji se snimaju i prikupljaju putem praćenja pokreta očiju nisu slike, već numerički podaci o procijenjenim točkama pogleda na stimulansu tijekom određenog vremena.",
    "app.faq.question4": "P: Koji se podaci prikupljaju tijekom ankete?",
    "app.faq.answer4.line1": "O: Softver će prikupljati neke ili svih sljedećih pet vrsta podataka:",
    "app.faq.answer4.line2": "„Korisnički ID“ – Korisnički ID koristi se za identifikaciju sudionika (u svrhu upravljanja nagradama za sudjelovanje);",
    "app.faq.answer4.line3": "„Podaci o pogledu“ – Procjene mjesta pogleda korisnika na ekranu kada im se na ekranu pokaže stimulans",
    "app.faq.answer4.line4": "„Podaci ankete“ – Odgovori na pitanja na koja odgovaraju korisnici",
    "app.faq.answer4.line5": "„Metapodaci“ – Podaci o vremenu u kojem su pojedinci uspješno sudjelovali u istraživanju.",
    "app.faq.answer4.line6": "„Podaci o fizičkim značajkama uređaja“ – Podaci o računalu putem kojeg korisnik sudjeluje, poput razine RAM-a, vrste procesorske jedinice (CPU) u stroju i korisničkog agenta (operativnog sustava i verzije preglednika), kao i informacije o ekranu računala (veličina i rezolucija), web kameri (model i rezolucija) i antivirusnim programima.",
    "app.faq.question5": "P: Na koji će se način koristiti prikupljeni podaci?",
    "app.faq.answer5.line1": "O: Prikupljeni podaci koristit će se za istraživanje o tome kako pojedinci gledaju i stupaju u interakciju s raznim vrstama vizualnih slika i medija kako bi se procijenila mišljenja ljudi o slikama koje im se prikazuju.",
    "app.faq.answer5.line2": "Tvrtka Lumen može podijeliti podatke istraživanja s povezanim društvima, poslovnim partnerima i klijentima. Iako se podaci istraživanja mogu podijeliti s povezanim društvima, partnerima i klijentima, to će obično biti na anonimnoj bazi. Takva društva, partneri i klijenti mogu koristiti te podatke kako bi izradili sažete opise o širokom rasponu ponašanja potrošača i segmenata potrošača s ciljem razvoja proizvoda ili pružanja marketinških rješenja za svoje kupce ili potrošače.",
    "app.faq.answer5.line3": "Tvrtka Lumen prikuplja „podatke o fizičkim značajkama uređaja“ i „metapodatke“ kako bi lakše upravljala prikupljanjem i obradom podataka, uključujući kontrolom kvalitete i upravljanjem obujmom.",
    "app.faq.answer5.line4": "Prikupljeni podaci bit će zadržani tijekom razdoblja koje je potrebno za ispunjavanje navedene svrhe.",
    "app.faq.question6": "P: Koliko su sigurni moji podaci?",
    "app.faq.answer6": "O: Podaci koje prikupi tvrtka Lumen šifrirani su pomoću SSL tehnologije. To se najčešće provodi pomoću visokog standarda tehnologije za enkripciju koje koriste banke za osiguravanje bankovnih transakcija i ostale visoko osjetljive komunikacije. Tu tehnologiju koriste i Amazon, PayPal, eBay, kao i druge internetske stranice kojima je potreban sigurnosni sustav na razini banke. Znanstvenici procjenjuju da bi 256-bitna enkripcija pomoću trenutačne tehnologije zahtijevala puno više vremena, čak od starosti svemira. Prikupljeni podaci spremaju se na sigurnim web poslužiteljima kojima upravlja Amazon Web Services (AWS). AWS je jedan od najčešće korištenih, sigurnih i pouzdanih platformi s uslugama računalstva u oblaku i koriste ga velike komercijalne službe na bazi oblaka, uključujući Netflix i Expediu.",
    "app.faq.question7": "P: Koja obaveze imate u vezi sa sadržajem koji vam se prikazuje?",
    "app.faq.answer7": "O: Sudjelovanjem potvrđujete da razumijete i da se slažete da je sadržaj koji vam se prikazuje u sklopu ovog istraživanja povjerljiv, vlasništvo treće strane i/ili podložan zaštiti trgovačke marke ili zakonom autorskih prava i da nećete napraviti snimke zaslona, snimati ili na bilo koji drugačiji način kopirati ijedan dio sadržaja, niti ponovno distribuirati bilo koji dio sadržaja istraživanja na bilo koji način.",
    "app.faq.question8": "P: Nisam siguran/a koliko je dobra moja web kamera, hoće li biti dobra za praćenje pokreta očiju?",
    "app.faq.answer8": "O: Sustav radi s velikim rasponom web kamera – to ne bi smio biti problem.",
    "app.faq.question9": "P: Dovršio sam istraživanje. Moram li učiniti još što?",
    "app.faq.answer9": "O: Ne, nije potrebno učiniti ništa drugo. Softver će se automatski zatvoriti.",
    "app.faq.question10": "P: Moram li za sudjelovanje biti spojen/a na internet?",
    "app.faq.answer10": "O: Da, trebat će vam internetska veza za preuzimanje programa i dovršavanje zadatka.",
    "app.faq.question11": "P: Trebam li zadatak dovršiti na stabilnoj površini?",
    "app.faq.answer11": "O: Da, za najbolje rezultate trebali biste sjediti udobno ispred ekrana računala, a nakon kalibracije držati svoju glavu mirnom dok vam se prikazuje stimulans, do obavijesti. Savjetujemo vam da ne sudjelujete ako te na vlaku ili ako svoje prijenosno računalo balansirate u svom krilu itd.",
    "app.faq.question12": "P: Često postavljana pitanja nisu mi dala odgovor na moj problem. Što da sada učinim?",
    "app.faq.answer12": "O: Pošaljite nam e-poruku na adresu study-feedback@lumen-research.com i javit ćemo vam se što brže možemo.",
    "app.faq.patent": "Testiranje web sadržaja tvrtke Lumen podržano je našom vlastitom originalnom tehnologijom testiranja unutar konteksta (UK broj prijave patenta 1816158.8)",
    "app.terms.line1": "Ovim uvjetima uporabe („Uvjeti uporabe“) uređuje se sporazum između vas i tvrtke Lumen Research Ltd („Lumen“ ili „Mi“), tvrtke registrirane u Engleskoj, broj tvrtke 8682432 sa sjedištem na adresi Unit 215, 22 Highbury Grove, London N5 2EF i referentnom registracijskom oznakom za zaštitu podataka ZA130772, u vezi s vašim korištenjem softvera za praćenje pokreta očiju „ViewPoints“ („softver“) na vašem uređaju.",
    "app.terms.line2": "1. Slaganje s uvjetima",
    "app.terms.line3": "Uporabom ovom softvera potvrđujete da se slažete na obvezivanje ovim Sporazumom. Ako se ne slažete s uvjetima i odredbama ovog Sporazuma, nemojte ispunjavati anketu ViewPoints.",
    "app.terms.line4": "2. Povjerljive informacije",
    "app.terms.line5": "Tijekom instalacije, korištenja ili na bilo koji način stupanja u interakciju sa softverom ViewPoints, imate pristup informacijama i/ili sadržaju koji pripada ili koji se odnosi na Lumen, uključujući informacije koje su identificirane ili tretirane od strane Lumena ili njihovih klijenata ili kupaca kao povjerljive, ili koje su zbog svog značaja ili okolnosti ili načina otkrivanja evidentno povjerljive, uključujući (bez utjecaja na gore navedenu općenitost) bilo koje informacije o poslovnim planovima, zrelim novim poslovnim prilikama, istraživačkim i razvojnim projektima, dizajnima, tajnim procesima, kodovima, softverskim dizajnima, poslovnim tajnama, razvoju i formulama proizvoda ili usluga, know-how, izumima, prodajnim statistikama i procjenama, marketinškim strategijama i planovima, troškovima, dobiti i gubitku i drugim financijskim informacijama (osim u mjeri u kojoj su objavljeni u revidiranim financijskim izvještajima), cijenama i strukturama popusta (sve bez obzira je li zabilježeno u pisanom ili elektroničkom ili drugom formatu) („Povjerljive informacije“). Takve povjerljive informacije ostat će u jedinom i isključivom vlasništvu tvrtke Lumen i/ili njezinih kupaca, i u nijednom slučaju nećete dobiti ili primiti prava, udjele i/ili vlasništvo nad tim povjerljivim informacijama.",
    "app.terms.line6": "Tijekom vašeg sudjelovanja u ViewPointsu ili u bilo koje drugo vrijeme (bez ograničenja) nakon dovršetka takvog sudjelovanja ne smijete otkrivati ili komunicirati s bilo kojom drugom osobom, upotrebljavati u vlastite svrhe, ili radi nedostatka brižnosti ili ustrajnosti dovesti do neovlaštenog otkrivanja bilo kakvih povjerljivih informacija. U svakom trenutku morate uložiti najveće moguće napore kako biste spriječili objavu ili otkrivanje bilo kakvih povjerljivih informacija. Te restrikcije prestat će se primjenjivati na bilo koje informacije koje postanu dostupne javnosti općenito na drugačiji način osim vašim naporima.",
    "app.terms.line7": "3. Vlasništvo tvrtke Lumen",
    "app.terms.line8": "Softver ViewPoints i svi podaci, kodovi, ključevi, lozinke, dizajni, crteži, zapisi ili drugi dokumenti ili materijali u vezi sa softverom jesu i ostat će u vlasništvu tvrtke Lumen tijekom trajanja vašeg sudjelovanja u anketi ViewPoints. Lumen posjeduje sva intelektualna ili vlasnička prava na bilo koja i sva postojeća i buduća intelektualna ili industrijska vlasnička prava na sve dokumente, materijale, modele, dizajne, crteže, procese, izume, formule, kompjutorske kodove, podatke, sučelja sustava, metodologije, know-how, povjerljive informacije ili druge radove, izvršene, izrađene, dobivene, razvijene, poboljšane, rekonstruirane ili otkrivene s vaše strane, samostalno ili s bilo kojom drugom osobom u vezi s ili povezane sa softverom i/ili uređajem, ili koje je moguće koristiti ili prilagoditi za upotrebu ili u vezi s time (registrirano ili neregistrirano), uključujući sve postojeće i buduće patente, autorska prava, prava na dizajn, prava na baze podataka, trgovačke marke, prava na topografiju poluvodiča, prava na biljne sorte, internetska prava/nazive domena, know-how i bilo koje i sve aplikacije za sve gore navedeno, i bilo koja i sva prava na primjenu svega gore navedenog („Vlasnička prava“). Vlasnička prava ni u kojem trenutku neće prijeći na vas.",
    "app.terms.line9": "4. Uvjeti i ograničenja upotrebe",
    "app.terms.line10": "Softver ViewPoints licencira se krajnjim korisnicima. Podložno ovom Sporazumu, ovime se slažemo da vam dozvoljavamo, na neisključivoj, neprenosivoj, ograničenoj bazi s mogućnošću opoziva, bez mogućnosti podlicenciranja, upotrebu softvera ViewPoints na uređaju kojem ste vlasnik ili kojim upravljate ili na osobnom računalu, uz poštovanje i jamstvo da ćete imati sva potrebna prava i dozvole za takvu instalaciju. Kako bi se izbjegla svaka sumnja, ViewPoints ne možete koristiti za bilo koju svrhu izvan opisane, prevoditi, kopirati, reproducirati, prenositi telekomunikacijskim putem, prilagođavati, spajati, mijenjati ili izmjenjivati softver na bilo koji način.",
    "app.terms.line11": "Slažete se da nećete: (i) kopirati, izmjenjivati, prilagođavati, učiniti dostupnim, iznajmljivati, zakupljivati, vremenski dijeliti, izrađivati izvedena djela ili pokušavati prodavati ili na drugi način prenositi bilo koje pravo softvera (ii) rastavljati, dekompilirati ili primijeniti obrnuti inženjering na bilo kojem dijelu softvera na bilo koji način; dozvoliti ili pomoći bilo kojoj strani da izvede ili pokušava izvesti izvorni kod, rastavlja, dešifrira, dekompilira ili primjenjuje obrnuti inženjering na softveru; poduzimati bilo koje druge korake za izvoz informacija o dizajnu u vezi sa softverom; (iii) upotrebljavati softver u bilo koju drugu svrhu osim svrhe za koju je originalno distribuiran vama; (iv) upotrebljavati spidere, robote ili druge automatizirane tehnike za rudarenje podataka radi katalogiziranja, preuzimanja, spremanja ili na drugačiji način reproduciranja softvera, ili radi manipuliranja rezultatima ankete ili bilo koje druge aktivnosti (v) poduzimati nikakve radnje kako biste ometali softver ili upotrebu softvera od strane pojedinca, uključujući i bez ograničenja na prekomjerno opterećivanje, „gomilanje“, „bombardiranje poštom“ ili „rušenje“ softvera i sustava za pohranu podataka koji softver koristi putem opcije Amazon Web Services (vi) slati nam bilo kakav ilegalan, varljiv, štetan kod, uključujući i bez ograničenja na viruse, špijunske softvere, reklamne softvere ili bilo kakve druge štetne kodove.",
    "app.terms.line12": "Sva prava nisu vam izričito dodijeljena i rezervirana su za nas i naše davatelje licence. Slažete se s time da ovim Sporazumom nemamo obvezu niti drugačije da ispravljamo bugove, kvarove ili pogreške u softveru, ili da na drugačiji način podržavamo, održavamo, poboljšavamo, izmjenjujemo, nadograđujemo, ažuriramo ili unaprjeđujemo softver. Ne preuzimamo odgovornost za pružanje pomoći vama ili vašim zamjenicima tijekom upotrebe softvera na bilo koji način.",
    "app.terms.line13": "Nismo odgovorni za bilo kakvu štetu nastalu na vašem osobnom računalu, uključujući i bez ograničenja na rezultate vaše upotrebe softvera. Nismo odgovorni za bilo kakve dodatne troškove za podatkovni promet koji su nastali kao rezultat prikupljanja podataka od strane softvera tijekom vašeg sudjelovanja u istraživanju.",
    "app.terms.line14": "5. Izjava o odricanju odgovornosti",
    "app.terms.line15": "Mi i naši određeni direktori, službenici, zaposlenici, agenti, predstavnici, davatelji licenci i pružatelji ne predstavljamo, jamčimo ili uvjetujemo (a)da će softver biti dostupan pravovremeno; ili da će pristup softveru biti neprekinut ili siguran; (b)da će kvarovi ili pogreške biti ispravljeni; ili (c)da su softver ili poslužitelji ili mreže putem kojih su softver i uređaj dostupni sigurni ili da ne sadrže viruse ili druge štetne komponente. Nemamo nikakvih obaveza brinuti o održavanju i/ili uslugama podrške u odnosu na softver.",
    "app.terms.line16": "6. Ograničenje odgovornosti",
    "app.terms.line17": "(a) U najvećoj mjeri dopuštenoj važećim zakonom, slažete se s time da mi i naši određeni direktori, službenici, zaposlenici, agenti, predstavnici, davatelji licenci i pružatelji neće preuzimati nikakvu odgovornost za bilo kakve gubitke, štete ili troškove bilo koje vrste nastale radi vaše instalacije, upotrebe ili pristupa softveru, vaše upotrebe ili nemogućnosti upotrebe softvera, bilo kakvih informacija ili materijala dostupnih na ili putem softvera i/ili drugo u vezi sa softverom ili drugačije u vezi s ovim sporazumom, bez obzira na prirodu zakonskog, pravičnog ili propisanog prava za koje se tvrdi da je prekršeno.",
    "app.terms.line18": "(b) U najvećoj mjeri dopuštenoj važećim zakonom, vaš jedini i isključivi lijek za nezadovoljstvo softverom je da ga prestanete koristiti.",
    "app.terms.line19": "(c) Ni u kojem slučaju nijedno naše tijelo, direktori, službenici, zaposlenici, agenti, predstavnici neće biti odgovorni vama za bilo kakvu slučajnu, neizravnu, posljedičnu, primjernu ili kaznenu štetu, sudske troškove, gubitak prihoda, gubitak prilika, gubitak ugleda, gubitak upotrebe, gubitak podataka, gubitak ostale nematerijalne imovine ili neovlašteno presretanje podataka prikupljenih od strane softvera od strane treće strane, bez obzira na to je li to bilo moguće predvidjeti ili ne, kako god da je došlo do toga, i pod bilo kojim ugovorom, deliktom (uključujući i bez ograničenja na nemar), strogom odgovornosti ili drugom teorijom, čak i ako je savjetovano unaprijed o mogućnosti da bi moglo doći do takvih šteta.",
    "demo.index.content.line1": "Tijekom ove demonstracije koristit ćemo vašu kameru kako bismo procijenili smjer vašeg pogleda. Ne brinite, nećemo napraviti nikakve fotografije niti videozapise."
  }