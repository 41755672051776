import anime from "animejs";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import PreviewController from "../../controllers/PreviewController";
import TimerBar from "./TimerBar";

const PreviewTab = () => {
  const tabRef = useRef();
  const showRef = useRef(false);

  let {
    stepComplete,
    stepType,
    timeToMoveNext,
    timeToShowNext,
    showHud,
    previewActive,
    startTimers,
    showNext,
    moveNext,
    extraData,
  } = useSelector((state) => state.previewReducer);

  const toggleShow = () => {
    if (tabRef.current) {
      if (!!!showRef.current) {
        anime({
          targets: tabRef.current,
          translateX: -275,
          complete: () => (showRef.current = true),
        });
      } else {
        anime({
          targets: tabRef.current,
          translateX: 0,
          complete: () => (showRef.current = false),
        });
      }
    }
  };

  return (
    <>
      {previewActive && (
        <div ref={tabRef} className="ls-preview-tab">
          <div onClick={toggleShow} className="ls-preview-tab-button"></div>
          <div className="ls-preview-tab-content">
            <div
              style={{
                backgroundColor: stepComplete ? "forestgreen" : "goldenrod",
              }}
              className="ls-preview-tab-status"
            >
              {stepComplete ? "Step Complete" : "In Progress"}
            </div>
            <div className="ls-preview-tab-item">{`Step Type: ${stepType}`}</div>
            <div className="ls-preview-tab-badge">
              <div className="ls-preview-tab-badge-label">{`Time to move next: `}</div>
              <div className="ls-preview-tab-badge-value" style={{backgroundColor:moveNext ? "forestgreen" : timeToMoveNext <= 0 ? "firebrick" : "goldenrod"}}>
                {`${
                  moveNext
                    ? "Fired"
                    : timeToMoveNext <= 0
                    ? "disabled"
                    : timeToMoveNext / 1000 + "s"
                }
                `}
              </div>
            </div>
            {timeToMoveNext > 0 && (
              <div className="ls-preview-tab-item">
                <TimerBar
                  active={startTimers}
                  time={timeToMoveNext / 1000}
                ></TimerBar>
              </div>
            )}
            <div className="ls-preview-tab-badge">
              <div className="ls-preview-tab-badge-label">{`Time to show next: `}</div>
              <div className="ls-preview-tab-badge-value" style={{backgroundColor:showNext ? "forestgreen" : timeToShowNext <= 0 ? "firebrick" : "goldenrod"}}>
                {`${
                  showNext
                    ? "Fired"
                    : timeToShowNext <= 0
                    ? "disabled"
                    : timeToShowNext / 1000 + "s"
                }
                `}
              </div>
            </div>
            {timeToShowNext > 0 && (
              <div className="ls-preview-tab-item">
                <TimerBar
                  active={startTimers}
                  time={timeToShowNext / 1000}
                ></TimerBar>
              </div>
            )}
            <div className="ls-preview-tab-item">
              <div>Show HUD?</div>
              <input
                type={"checkbox"}
                checked={showHud}
                onChange={() => PreviewController.showHud(!showHud)}
              ></input>
            </div>
            {!!extraData && (
              <div className="ls-preview-tab-extra">
                {Object.entries(extraData).map((entry) => {
                  return (
                    <div className="ls-preview-tab-item">{`${entry[0]}: ${entry[1]}`}</div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PreviewTab;
