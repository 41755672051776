import { store } from "../../../../store";
import DeviceController from "../../../../utils/controllers/DeviceController";
import Endpoint from "../../Endpoint";

const getPayload = (cleanReportMessage) => {
  let {
    accessCode,
    hasWebcam,
    hasAdBlock,
    routedRegion,
    fastestRegion,
    graphics,
    panelCompanyCode,
    demographicsSurveyResponsesInternal,
    participant,
    participantId,
    test,
  } = store.getState().studyReducer;

  let {
    device,
    osString,
    os,
    name,
    version,
    connectionType,
    connectionSpeed,
    isMobile,
  } = DeviceController.getDeviceInfo();

  let urlParams = new URLSearchParams(window.location.search);

  let failPayload = {};

  if (participantId) {
    failPayload.participant_id = participantId;
  } else {
    failPayload.participant = participant;
  }

  failPayload = {
    ...failPayload,
    access_code: accessCode,
    reason: cleanReportMessage,
    hasWebcam,
    hasAdBlock,
    routedRegion,
    fastestRegion,
    graphics,
    test,
    device,
    os: osString.toLowerCase(),
    os_version: os.version,
    browser: name.toLowerCase(),
    browser_version: version,
    connection_speed: connectionType,
    connection_speed_absolute:
      typeof connectionSpeed == "object"
        ? connectionSpeed.speedKbps
        : connectionSpeed,
    is_mobile: isMobile,
    device_model: device,
    browser_language: window.navigator.language,
    panel: panelCompanyCode,
    gender:
      demographicsSurveyResponsesInternal["gender"] ||
      urlParams.get("g") ||
      null,
    age:
      demographicsSurveyResponsesInternal["age"] || urlParams.get("a") || null,
    social_grade:
      demographicsSurveyResponsesInternal["social_grade"] ||
      urlParams.get("s") ||
      null,
    family:
      demographicsSurveyResponsesInternal["family"] ||
      urlParams.get("f") ||
      null,
    earnings:
      demographicsSurveyResponsesInternal["earnings"] ||
      urlParams.get("hi") ||
      null,
    ethnicity:
      demographicsSurveyResponsesInternal["ethnicity"] ||
      urlParams.get("et") ||
      null,
    extra1:
      demographicsSurveyResponsesInternal["e1"] || urlParams.get("e1") || null,
    extra2:
      demographicsSurveyResponsesInternal["e2"] || urlParams.get("e2") || null,
    extra3:
      demographicsSurveyResponsesInternal["e3"] || urlParams.get("e3") || null,
  };

  return failPayload;
};

export default (study_id, cleanReportMessage) => {
  return new Endpoint(
    "fail",
    `${process.env.REACT_APP_PARTICIPATION_URL}/participation/studies/${study_id}/fail`,
    "POST",
    () => getPayload(cleanReportMessage),
    undefined,
    false
  );
};
