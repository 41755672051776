{
  "app.thankyou":"谢谢",
  "app.button.faqs": "常见问题",
  "app.button.terms": "使用条款",
  "app.button.privacy": "隐私政策",
  "app.button.feedback": "反馈",
  "app.button.selectLanguage": "选择语言",
  "app.button.help": "帮助",
  "app.button.start": "开始",
  "app.button.next": "下一页",
  "app.button.submit": "提交",
  "app.button.close": "关闭",
  "app.button.return": "返回",
  "app.button.takepart": "好的，我愿意参与",
  "app.menu.feedback.content": "如果您在此次调查过程中遇到了问题，或者只是想发表评论，请随时在此提出。",
  "app.contactModal.feedback": "感谢您的反馈！",
  "app.takePartModal.line1": "如果您现在离开，您将没有资格获得任何参与奖励。",
  "app.takePartModal.line2": "您只需要一个网络摄像头！",
  "app.takePartModal.reason1": "我没有网络摄像头。",
  "app.takePartModal.reason2": "我不希望在本次调查中使用我的网络摄像头。",
  "app.takePartModal.reason3": "我现在没时间参与调查。",
  "app.takePartModal.reason4": "其他原因",
  "app.generic.redirect": "您将很快被重新定向。",
  "app.generic.screenshotfail": "请截图此页作为进入失败凭证。您将很快被重新定向。",
  "app.generic.screenshotsuccess": "请截图此页作为完成凭证。您将很快被重新定向。",
  "app.init.welcome": "欢迎来到Lumen Research调查平台",
  "app.init.intro": "该调查平台允许我们引导并设置用户参与指定的广告调查。",
  "app.init.message1": "根据调查的不同类型，我们将引导用户完成一系列任务。",
  "app.init.message2": "这些任务包括：填写调查问卷、阅读一组指令并执行眼动追踪校准",
  "app.init.message3": "现在，我们将引导您完成一项演示调查。",
  "app.init.message4": "在整个调查过程中，我们可能会启用您的网络摄像头进行眼动追踪，但不会记录您的任何数据。",
  "app.init.message5": "当前调查设置为在筛选排除/完成时使用以下链接。",
  "app.prestart.loading": "正在加载，请稍后。",
  "app.index.title": "欢迎",
  "app.index.content.line1": "在本次调查过程中，我们将使用您的摄像头来估算您正在注视的位置。别担心，我们不会拍摄任何照片或视频。",
  "app.index.content.line1gpdrwarning": "在本次调查中，我们将使用您设备上的前置摄像头拍摄您的图像和视频片段，与此同时您将进行各种涉及观看刺激物的活动。",
  "app.index.content.line1alt": "请确保您舒适坐好，然后点击“开始”按钮开始。",
  "app.index.content.line2": "您的设备有摄像头吗？如果有，请按下面的“开始”按钮。",
  "app.index.rejection": "抱歉，您似乎点击了一个格式错误的链接。",
  "app.index.webview": "抱歉，您似乎是从网页视图中访问我们的页面。请在您的浏览器中打开此链接。您可以直接从网页视图菜单中打开。",
  "app.index.webview.chrome.1": "点击上方的菜单按钮，然后选择“在Chrome浏览器中打开”。",
  "app.index.webview.chrome.2": "这样将在浏览器应用程序中打开此调查问卷，确保其能够顺畅运行。",
  "app.index.webview.android.1": "点击下方的“开始”按钮，在浏览器应用程序中打开此调查问卷，确保其能够顺畅运行。",
  "app.index.webview.android.2": "如果此操作失败，请在浏览器应用程序中手动打开该页面的链接（例如，将链接复制并粘贴到Chrome浏览器的地址栏中，或者使用上方菜单栏中“在Chrome浏览器中打开”之类的选项）。",
  "app.index.webview.safari.1": "点击屏幕底部的Safari图标继续。",
  "app.index.webview.safari.2": "它的外观如下所示：",
  "app.index.webview.safari.3": "这样将在Safari中打开此调查问卷，确保其能够顺畅运行。",
  "app.index.wifi": "您的网络连接速度似乎太慢了。您是否已连上无线网络？",
  "app.index.tellmemore": "请告诉我更多信息。",
  "app.index.takepart": "我不想参与。",
  "app.index.webcamRetry": "我们无法检测到网络摄像头，这是本调查必需的。请提供对您的网络摄像机的访问权限，然后重试。",
  "app.index.adblockRetry": "哎呀！我们检测到了广告拦截程序。为了获得最佳调查体验，请将其关闭，然后重试。",
  "app.index.zoomRetry": "我们要求将浏览器设置为100%缩放比例，请放大/缩小操作后重试",
  "app.index.fullscreendesktopretry": "这项调查需要全屏视图，请确保您的浏览器窗口最大化显示",
  "app.index.fullscreendesktoptest":"这项调查要求您的浏览器窗口设置为全屏查看模式，我们需要执行一项测试来确保您的浏览器窗口最大化显示",
  "app.index.fullscreendesktoptest2":"稍后会出现两个按钮，请点击它们继续",
  "app.index.fullscreendesktopmaximised":"我的屏幕已经最大化显示",
  "app.index.fullscreendesktopskip":"谢谢，我们已经记录了这一情况，并将改进我们的检测过程。",
  "app.index.fullscreendesktopreset":"我们已经检测到窗口大小发生了变化，正在重置测试。",
  "app.index.gotomobile":"桌面用户不符合参与此项调查的要求，请扫描下方二维码在移动设备上打开该调查内容。",
  "app.index.returntopanel":"或者，点击下方按钮返回面板",
  "app.instructions.title": "说明",
  "app.instructions.instruction1": "如果可能的话，请在调查过程中不要戴眼镜。",
  "app.instructions.instruction2": "请坐好，这样您就可以舒适地保持头部和设备不动。",
  "app.instructions.instruction3": "移动您的眼睛，但不要动头。",
  "app.instructions.instruction4": "准备好开始了吗？",
  "app.instructions.instruction5": "请使用无线网络以避免产生数据费。",
  "app.instructions.instruction6": "请仔细阅读以下说明。",
  "app.instructions.instruction7": "将手机放在一个稳定的表面上。",
  "app.instructions.instruction8": "请确保房间内光线充足——最好是从上方（而不是后方）照明。",
  "app.setup.title": "设置",
  "app.setup.instruction0": "将您的设备保持在竖屏模式。",
  "app.setup.instruction0a": "请将您的设备旋转为竖屏。",
  "app.setup.instruction0b": "请旋转您的设备，使摄像头位于左侧。",
  "app.setup.instruction0c": "请旋转您的设备，使摄像头位于右侧。",
  "app.setup.instruction0d": "请将您的设备旋转为横屏。",
  "app.setup.instructioncamera": "请点击下方按钮请求相机访问权限。要完成调查，您需要在整个调查过程中允许使用相机。",
  "app.setup.cameraText": "请求访问",
  "app.setup.instruction1": "正在初始化...",
  "app.setup.instruction2": "当您被检测到后，请将您的头部置于方框内。",
  "app.setup.instruction3": "请将您的头部和设备保持在适当的位置，直至调查完成。",
  "app.setup.instruction3audio":"请仔细听，并按照屏幕上的指令进行操作。",
  "app.setup.instruction4": "现在我们只需要验证一下。",
  "app.setup.instruction5": "谢谢！请务必将您的头部和设备保持在原位不动，直至调查完成。",
  "app.setup.instruction6": "请向上滑动以隐藏地址栏。",
  "app.setup.retry": "检测到错误。请保持您的头部和设备不动，不要用手指等遮住相机。使用下方的按钮重试。",
  "app.setup.retryPositioning": "我们无法确定您的头部位置。请保持您的头部和设备不动，不要用手指等遮住相机。使用下方的按钮重试。",
  "app.setup.retryInit": "检测到错误。请确保浏览器可以访问您的相机。如果您之前拒绝了相机访问权限，您需要在浏览器的隐私设置中开启这一权限。使用下方的按钮重试。",
  "app.setup.retryBroker": "我们无法完成校准过程。请尝试关闭任何未使用的应用程序和标签页以释放资源",
  "app.setup.retryButton": "重试",
  "app.setup.calibrationProp.watch": "当在屏幕上出现小圆点时，请视线跟随此小圆点",
  "app.setup.calibrationProp.watch.george": "接下来，当您看到一张脸时，请一直看着它，同时模仿它的头部动作",
  "app.setup.calibrationProp.click": "点击任意位置继续",
  "app.setup.calibrationProp.tap": "轻触任意处继续",
  "app.study.validationProp.watch": "当屏幕上出现圆点时，请跟随圆点",
  "app.study.validationProp.restart": "请重试",
  "app.study.validationProp.click": "点击任意位置继续",
  "app.study.validationProp.tap": "轻点任意位置继续",
  "app.study.render.error": "域名在渲染步骤中无效。",
  "app.error.title": "链接错误",
  "app.error.generic": "哎呀！出错了。",
  "app.error.initialise": "无法初始化！",
  "app.error.findCamera": "找不到相机！",
  "app.error.headPosition": "无法确定您的头部位置。",
  "app.error.calibrate": "无法校准。",
  "app.error.validate": "无法验证。",
  "app.error.start": "启动失败。",
  "app.error.assign": "分配失败。",
  "app.error.osnotallowed": "筛选排除：您的操作系统不支持，请选择符合要求的设备进行此调查，然后重试。",
  "app.error.demoquotafullnoactivecell": "配额已满：感谢您的关注。此项调查现已对您所属群体关闭。",
  "app.error.cellquotafullnoactivecell":"未找到此调查的可用分组。分组配额已满。",
  "app.error.missingpanelparameter": "缺少面板参数。",
  "app.error.browsernotallowed": "你的浏览器没有被允许接入。",
  "app.invalid.title": "无效",
  "app.invalid.generic": "抱歉，您不符合参与此次调查的条件。",
  "app.completion.title": "恭喜你完成测试",
  "app.completion.congratulations": "摄像头的使用现在已经终止。",
  "app.completion.congratulations-noet": "恭喜！您已经完成了本次调查。",
  "app.questions.required": "此题是必答题。",
  "app.faq.gdpr.intro1": "在这项调查中，我们将使用移动设备上的前置摄像头拍摄用户的图像和视频片段，与此同时要求用户在各种环境条件下（例如不同光线和位置）进行各种涉及观看刺激物的活动。  完成本次调查预计大约需要5分钟。  这些数据将用于帮助持续开发能够估算出用户在屏幕上注视位置的软件。",
  "app.faq.gdpr.intro2": "本次调查是根据《市场调查协会行为准则》和2018年《数据保护法》开展的。  我们非常重视您的隐私。  在参与本调查之前，您必须确认您同意：参与本调查，即表示您了解我们按照本隐私政策所述收集的数据以及这些数据将如何被使用，并同意以这种方式使用这些数据。  该政策将更详细、准确地说明收集哪些信息以及如何使用和共享这些信息。",
  "app.faq.gdpr.question1": "将收集哪些数据？",
  "app.faq.gdpr.answer1.line1": "本调查将收集以下类型的数据：",
  "app.faq.gdpr.answer1.line2": "“现场特征”——用于识别特定数据收集“现场”和调查期间主要环境测试条件数据的匿名ID。",
  "app.faq.gdpr.answer1.line3": "“参与者特征”——关于参加特定现场的受访者的数据，包括参与者的个人背景（如年龄、性别）、种族（如肤色类型）和生物特征（如眼睛颜色）等信息。",
  "app.faq.gdpr.answer1.line4": "“视频文件”——使用设备的前置摄像头拍摄的图像和/或视频片段，以捕捉参与者在观看或可能与屏幕上显示的刺激物互动时的图像。前置摄像头将捕捉参与者的眼睛和面部图像，以及完成任务时前置摄像头可能无意中捕捉到的任何其他图像。 ",
  "app.faq.gdpr.answer1.line5": "“元数据”——关于个人与刺激物互动时间的数据，与每张采集图像的时间戳相关联。",
  "app.faq.gdpr.answer1.line6": "“物理设备数据”——关于设备的物理数据，包括设备名称、屏幕尺寸、屏幕旋转角度和摄像头传感器旋转角度。",
  "app.faq.gdpr.answer1.line7": "参与本次调查，即表示您了解并同意本调查中向您展示的内容可能是保密信息，属于第三方财产和/或受商标法或版权法的保护，并且您不会截屏、录制或以其他方式拷贝任何内容，也不会以任何方式重新分发本调查的任何内容。",
  "app.faq.gdpr.question2": "这些数据将如何使用/处理？",
  "app.faq.gdpr.answer2": "这些数据将用于开发软件，该软件可以估算出用户是否出现在设备前，是否正在注视刺激物，以及可能在注视刺激物的哪个位置。  这些数据还可用于演示目的，或向第三方（客户、供应商、合作伙伴）解释或帮助评估我们技术的开发过程及其性能表现。  这些数据可能会被任何数量的数据“标注员”审查，数据“标注员”可能是员工、分包商、合作伙伴或任何其他被要求回放视频片段/图像的第三方，以评估调查期间参与者的注意力“状态”（例如，他们是否出现在摄像头的视野范围内？他们是否正在看屏幕？）  处理这些数据的法律依据是知情同意和正当权益。 ",
  "app.faq.gdpr.question3": "这些数据会保留多长时间？",
  "app.faq.gdpr.answer3": "我们将保留这些数据，以便在合理必要的时间内用于上述目的。  由于正在开发的软件会定期更新和评估，且每一次软件迭代都将使用这些数据进行评估，因此，预计这些数据将为此目的保留并持有数年，甚至可能是无限期。  如果这些数据被认为对开发或评估刺激测试软件不再有用，则将予以删除。 ",
  "app.faq.gdpr.question4": "谁将接收和处理这些数据？",
  "app.faq.gdpr.answer4": "这些数据将由Lumen Research Ltd持有和保留。Lumen Research Ltd是一家在英格兰注册的公司，公司注册号为8682432。  其注册办事处位于LABS house, 15-19 Bloomsbury Way, Holborn, London WC1A 2TH。该公司的数据保护注册编号为ZA130772。  其数据保护专员是Matt Robinson（matt.robinson@lumen-research.com）。  您有权随时就您的数据运用和处理向英国数据保护机构——信息专员办公室（ICO）提出投诉。  ICO的联系方式可在其网站https://ico.org.uk/global/contact-us/上查询。参与本调查，即表示您授予Lumen全球范围内免版税、可转授权和可转让的许可，以托管、存储、使用、展示、复制、修改、改编、编辑、发布和分发这些数据。",
  "app.faq.gdpr.question5": "我是否有权访问我的个人数据？",
  "app.faq.gdpr.answer5": "是，您有权访问您的个人数据，但是请务必注意，在本次活动中收集的数据中不会存储您的姓名。  为了识别您的数据记录，您需要向我们提供您参与调查的确切时间和日期，并且我们需要进行视觉识别，以确保数据记录正确匹配。",
  "app.faq.gdpr.question7": "我是否有权撤回对处理我的个人数据的同意，或有权要求删除我的个人数据？",
  "app.faq.gdpr.answer7": "是，您有权撤回对处理您的个人数据的同意，并有权要求删除这些数据。  但是，请务必注意，在本次活动中收集的数据中不会存储您的姓名。  为了识别您的数据记录，您需要向我们提供您参与调查的确切时间和日期，并且我们会要求进行视觉识别，以确保数据记录正确匹配。  ",
  "app.faq.gdpr.question8": "我如何知道我的个人信息是否安全？",
  "app.faq.gdpr.answer8": "Lumen Research收集的数据使用安全套接层（SSL）技术加密。  这是一种常用的高标准加密技术。这项技术用于保护传送信道和最终存储的安全。",
  "app.faq.gdpr.question9": "我们将如何使用和披露我们收集的信息？",
  "app.faq.gdpr.answer9": "我们可能会将我们收集的信息披露给其他第三方服务提供商，以便他们能够向我们提供服务。在对我们的全部或任何部分业务、资产或股票进行任何重组、合并、出售、合资、转让、过户或其他处置的情况下（包括与任何破产或类似程序有关的情况），我们也可能会将信息披露给第三方。我们还可能在我们认为必要或适当的情况下使用或披露我们从您那里收集的关于您个人的信息：（a）根据适用法律，包括您居住国家/地区以外的法律；（b）遵守法律程序；（c）响应公共和政府机构的要求，包括您居住国家/地区以外的公共和政府机构的要求；（d）执行我们的最终用户许可协议；（e）保护我们或我们任何关联公司的运营；（f）保护我们和/或我们的关联公司、您或其他人的权利、隐私、安全或财产；（g）允许我们寻求可用的补救措施或限制我们可能遭受的损害；以及（h）发现并解决任何欺诈或安全问题。 ",
  "app.faq.gdpr.question10": "采取哪些措施来安全地保存数据？",
  "app.faq.gdpr.answer10": "我们会采取合理的措施来保护您的个人信息，使用适合我们保管或控制的信息的敏感性的物理、电子或程序性安全措施，其中包括防止信息丢失或被盗，以及防止未经授权的访问、披露、拷贝、使用或修改信息的保障措施。 ",
  "app.faq.question1": "问：Lumen调查平台是什么？参与眼动追踪调查涉及哪些内容？",
  "app.faq.answer1": "答：这项调查涉及使用眼动追踪技术。您将被要求坐在设备的网络摄像头或前置摄像头前并进行校准，以便估算出您在屏幕上的注视位置。在调查过程中，您将被要求观看一些内容（如图像、网站或视频），与此同时调查工具会估算您正在注视的位置。从这种“眼动追踪”中捕获的数据是估算出的屏幕上随着时间变化注视点的坐标。该软件使用来自网络摄像头中您的图像来进行这些估算，但不会捕捉您的照片或视频。您可能还会被问及一些关于您自己、您的经历以及您在调查过程中看到的内容的问题。这些数据将被发送到Lumen Research Ltd。Lumen Research将对这些数据进行分析，并与其客户分享分析结果。",
  "app.faq.question2": "问：为什么该软件需要网络摄像头/相机？它是如何使用的？",
  "app.faq.answer2": "答：该软件使用来自网络摄像头或设备摄像头中您的图像来估算您正在屏幕上注视的位置。它结合使用面部追踪和眼动追踪软件来实现这一点，即在您的设备上对本地图像进行实时分析。在校准过程中，这些图像会经过处理并展示给您，以便您能够正确定位自己，但这些图像不会被记录，从而保护您的隐私。眼动追踪所记录和收集的数据并不是图像，而是估算出的刺激物上随时间变化注视点的数值数据。",
  "app.faq.question3": "问：你们在做眼动追踪时会拍摄我的照片吗？",
  "app.faq.answer3": "答：使用图像的唯一目的是让您在校准过程中正确定位自己。这些图像不会被保存，只有您自己能看到。眼动追踪所记录和收集的数据并不是图像，而是估算出的刺激物上随时间变化注视点的数值数据。",
  "app.faq.question4": "问：在调查期间会收集哪些数据？",
  "app.faq.answer4.line1": "答：该软件将收集以下五种类型数据中的部分或全部：",
  "app.faq.answer4.line2": "“用户ID”——用于识别受访者的用户ID（用于管理参与奖励）；",
  "app.faq.answer4.line3": "“注视数据”——当屏幕上显示刺激物时，估算出用户在屏幕上的注视位置",
  "app.faq.answer4.line4": "“调查数据”——用户对所提问题的回答",
  "app.faq.answer4.line5": "“元数据”——关于个人成功参与调查的时间数据。",
  "app.faq.answer4.line6": "“物理设备数据”——关于用户参与调查所用计算机的数据，例如内存水平、设备CPU类型和用户代理（操作系统和浏览器版本），以及关于计算机屏幕（尺寸和分辨率）、网络摄像头（品牌和分辨率）和防病毒程序的信息。",
  "app.faq.question5": "问：收集的数据将如何使用？",
  "app.faq.answer5.line1": "答：收集的数据将用于探索个人如何观看各种类型的视觉图像和媒体并与之互动，以及评估人们对他们所看到的图像的看法。",
  "app.faq.answer5.line2": "Lumen可能会与附属公司、商业合作伙伴和客户共享调查数据。虽然调查数据可能会与附属公司、合作伙伴和客户共享，但通常是匿名的。这些附属公司、合作伙伴和客户可能会使用这些数据来创建各种消费者行为和消费者细分的概要描述，以开发产品或提供适用其客户或消费者的市场营销解决方案。",
  "app.faq.answer5.line3": "Lumen收集“物理设备数据”和“元数据”，以帮助管理数据的收集和处理，包括质量控制和卷管理。",
  "app.faq.answer5.line4": "收集的数据将保留一段时间，直至实现所述目的。",
  "app.faq.question6": "问：我的数据能否获得安全保障？",
  "app.faq.answer6": "答：Lumen收集的数据使用SSL技术加密。这是最常用的高标准加密技术，被银行用于保障银行交易和其他高度敏感通信的安全。亚马逊、贝宝、易贝及其他需要银行级安全系统的网站也使用此技术。科学家估计，使用现有技术破解256位加密所需的时间远远超过了宇宙的年龄。收集的数据存储在由亚马逊网络服务（AWS）管理的安全网络服务器上。AWS是最常用的安全可靠的云计算服务平台之一，Netflix和Expedia等主要基于云的商业服务都在使用。",
  "app.faq.question7": "问：您对所展示的内容有哪些义务？",
  "app.faq.answer7": "答：参与本次调查，即表示您也了解并同意本调查中向您展示的内容可能是保密信息，属于第三方财产和/或受商标法或版权法的保护，并且您不会截屏、录制或以其他方式拷贝任何内容，也不会以任何方式重新分发本调查的任何内容。",
  "app.faq.question8": "问：我不确定我的网络摄像头功能如何以及是否适合用于眼动追踪？",
  "app.faq.answer8": "答：该系统支持多种网络摄像头，所以应该没有问题。",
  "app.faq.question9": "问：我已经完成了该调查，我还需要做什么吗？",
  "app.faq.answer9": "答：否，无需执行任何其他操作。该软件将会自动关闭。",
  "app.faq.question10": "问：我需要连接到互联网才能参与吗？",
  "app.faq.answer10": "答：是，您需要联网才能下载程序并完成任务。",
  "app.faq.question11": "问：我需要在稳定的表面上完成任务吗？",
  "app.faq.answer11": "答：是，为了达到最佳效果，您应该舒适地坐在电脑屏幕前，在校准完成后，当屏幕上展示刺激物时，请保持头部不动，直到收到进一步指示。我们建议您不要在火车上或把笔记本电脑放在膝盖上等不稳定的地方参与。",
  "app.faq.question12": "问：常见问题解答没有解决我的问题，我现在该怎么做？",
  "app.faq.answer12": "答：请发送邮件至study-feedback@lumen-research.com，我们会尽快给您回复。",
  "app.faq.patent": "Lumen的网络内容测试是由我们自己专有的情境测试技术提供支持（英国专利申请号1816158.8）",
  "app.terms.line1": "本使用条款（以下简称“使用条款”）适用于您与 Lumen Research Ltd（以下简称“Lumen”或“我们”）之间就您在设备上使用“ViewPoints”眼动追踪软件（以下简称“软件”）达成的协议。Lumen Research Ltd 是一家在英国注册的公司，公司注册号为 8682432，注册办公地址位于 Unit 215, 22 Highbury Grove, London N5 2EF，数据保护注册编号为 ZA130772。",
  "app.terms.line2": "1.同意条款",
  "app.terms.line3": "使用该软件，即表示您确认您已同意受本协议的约束。如果您不同意本协议的条款和条件，请不要完成ViewPoints调查。",
  "app.terms.line4": "2.保密信息",
  "app.terms.line5": "在安装、使用或以任何方式与ViewPoints互动时，您可能会接触到属于Lumen或与之有关的信息和/或内容，包括Lumen或Lumen的任何客户或顾客认定或视为机密的信息，或由于其性质或披露的情境或方式而明显属于机密的信息，包括（在不影响前述一般性的情况下）有关商业计划、成熟的新商机、研发项目、设计、秘密流程、代码、软件设计、商业秘密、产品或服务开发和配方、专有技术、发明、销售统计和预测、营销策略和计划、成本、盈亏及其他财务信息（除了已在审计账目中公布的内容）、价格和折扣结构（无论是否以书面、电子或其他格式记录）（以下简称“保密信息”）。该等保密信息应始终是Lumen和/或其客户的独有财产，在任何情况下，您均不得获取或接收该等保密信息的任何权利、所有权和/或权益。",
  "app.terms.line6": "您不得在参与ViewPoints期间或在此类参与终止后的任何时间（无限制）向任何人泄露或传达；用于您自己的目的；或因未能尽到应有的谨慎和勤勉而导致未经授权披露任何保密信息。您必须始终尽最大努力防止公布或披露任何保密信息。这些限制将不再适用于非因您的违约行为而将普遍为公众所知的任何信息。",
  "app.terms.line7": "3.Lumen财产",
  "app.terms.line8": "在您参与ViewPoints调查的整个过程中，ViewPoints软件以及与该软件相关的所有数据、代码、密钥、密码、设计、图纸、记录和其他文档或材料应是并始终是Lumen的财产。Lumen拥有任何及所有现有和未来知识产权或工业产权中的所有知识产权或专有权利，包括任何文档、材料、模型、设计、图纸、流程、发明、配方、计算机编码、数据、系统接口、方法、专有技术、保密信息或其他工作，包括您单独或与任何其他人共同执行、制作、创建、设计、开发、增强、解构或发现的与软件和/或设备有关或与之相关，或能够用于或改编用于其中或与之相关的内容（无论是否已注册），包括所有现有和未来的专利、版权、设计权、数据库权、商标、半导体布图设计权、植物品种权、互联网权/域名、专有技术以及前述各项的任何和所有申请以及申请前述各项的任何和所有权利（以下简称“所有权”）。任何时候都不得将所有权转让给您。",
  "app.terms.line9": "4.使用条款和限制",
  "app.terms.line10": "ViewPoints软件已授权给最终用户使用。根据本协议，我们特此同意在非排他性、可撤销、不可转让、不可再授权、有限的基础上，允许您在您拥有或控制的设备或个人计算机上使用ViewPoints软件，您声明并保证拥有安装该软件所需的所有必要权利和权限。为避免疑问，您不得出于ViewPoints以外的任何目的使用、翻译、拷贝、复制、通过电信传输、改编、合并、以任何方式更改或修改软件。",
  "app.terms.line11": "您同意不会：（i）拷贝、修改、改编、提供、出租、租赁、分时使用、创建衍生作品，或试图出售或以其他方式转让软件中的任何权利；（ii）以任何方式反汇编、反编译或反向工程软件的任何部分；也不允许或协助任何一方推导或试图推导软件的源代码、反汇编、解码、反编译或反向工程软件；也不采取任何其他步骤来推导有关软件的设计信息；（iii）将本软件用于除最初分发给您的目的之外的任何目的；（iv）使用蜘蛛程序、机器人或其他自动化数据挖掘技术对软件进行编目、下载、存储或以其他方式复制或分发，或操纵任何调查或任何其他活动的结果；（v）采取任何行动干扰软件或个人对软件的使用，包括但不限于通过过载、“泛洪”、“邮件轰炸”或“崩溃”等手段攻击该软件或该软件通过亚马逊网络服务所使用的数据存储系统；（vi）向我们发送任何非法的、欺骗性的或有害的代码，包括但不限于任何病毒、间谍软件、广告软件或任何其他有害代码。",
  "app.terms.line12": "本协议中未明确授予您的所有权利均保留给我们及我们的许可人。您同意，根据本协议或其他协议，我们没有义务纠正该软件中的任何漏洞、缺陷或错误，或以其他方式支持、维护、改进、修改、升级、更新或增强该软件。我们不负责协助您或您的指定人员以任何方式使用该软件。",
  "app.terms.line13": "我们对您个人计算机的任何损坏不承担责任，包括但不限于因您使用该软件而造成的损坏。我们不承担因您参与本项调查而由该软件收集数据所产生的任何额外数据费用。",
  "app.terms.line14": "5.免责声明",
  "app.terms.line15": "我们和我们各自的董事、高级管理人员、员工、代理人、代表、许可人和提供商对以下情况均不作任何声明或保证或规定（a）该软件将及时可用，或者访问该软件将不受干扰且安全；（b）缺陷或错误将得到纠正；或（c）该软件或提供软件和设备的服务器或网络很安全或没有病毒或其他有害组件。我们没有义务提供与该软件相关的任何维护和/或支持服务。",
  "app.terms.line16": "6.赔偿责任限制",
  "app.terms.line17": "（a）在适用法律允许的最大范围内，您同意我们和我们各自的董事、高级管理人员、员工、代理人、代表、许可人和提供商对于因您安装、使用或访问该软件，您使用或无法使用该软件，在该软件上或通过本软件提供的任何信息或材料，和/或与该软件或本协议有关的其他情况而造成的任何或所有损失、损害或费用不承担任何责任，无论所声称受到侵犯的法律权利、衡平法权利或法定权利的性质如何。",
  "app.terms.line18": "（b）在适用法律允许的最大范围内，您对该软件不满意的唯一补救措施是停止使用它。",
  "app.terms.line19": "（c）在任何情况下，我们的任何实体、董事、高级管理人员、员工、代理人、代表均不对您的任何附带、间接、后果性、法定、惩戒性、惩罚性或特殊损害赔偿、收入损失、机会丧失、声誉受损、使用性损失、数据丢失、其他无形资产损失或第三方未经授权拦截软件收集的数据承担责任，无论这些损害是否可预见，也无论是何种原因造成的，也无论基于任何合同、侵权行为（包括但不限于过失）、严格责任或其他理论，即使事先已被告知可能会发生此类损害。",
  "demo.index.content.line1": "在本次演示过程中，我们将使用您的摄像头来估算您正在注视的位置。别担心，我们不会拍摄任何照片或视频。",
  "app.error.previousfail": "您之前未能完成此项调查，请使用原始链接从头开始。",
  "app.error.previoussuccess": "您之前已经完成了此项调查。",
  "app.error.route": "您试图开始一项已经在进行的调查，请使用原始链接从头开始。",
  "app.error.accesscode": "您试图开始一项不存在的调查，请检查您的链接。",
  "app.error.webcam": "这项调查需要访问网络摄像头",
  "app.error.adblocker": "这项调查要求禁用广告拦截程序。",
  "app.error.startsession": "无法启动会话",
  "app.error.fullscreen": "这项调查需要全屏视图。",
  "app.error.zoom": "这项调查需要将浏览器缩放比例设置为100%。",
  "app.index.webcamInstructions": "我们即将请求访问您的网络摄像头，如果收到弹出窗口，请点击“允许”。",
  "app.setup.retryBack": "在整个调查过程中，请不要按浏览器的后退按钮，否则可能会导致失去奖励。使用下方的按钮重试。",
  "app.setup.retryaudible": "请确保您的设备未设置静音，然后重试。",
  "app.restore.retry":"您在上一个步骤中花费的时间未达到要求， ",
  "app.restore.timeremaining":" 剩余分钟",
  "app.error.postexposure":"Sorry, you have now used up all your attempts and unfortunately, you cannot proceed in the study."
}