import { combineReducers } from "redux";
import modalReducer from "../components/modal/reducer";
import helpTagReducer from "../components/helpTag/reducer";
import menuReducer from "../components/menu/reducer";
import studyReducer from "../components/lumenController/reducer";
import baseReducer from "./baseReducer";
import pageReducer from "./PageState/reducer";
import stepReducer from "./StepState/reducer"
import deviceReducer from "./DeviceState/reducer"
import previewReducer from "./PreviewState/reducer"
import storage from "redux-persist/lib/storage";
import LoggerController from "../controllers/Logger/LoggerController";

/**
 * This file is for combining reducers from different components across the whole application.
 */
export const appReducer = combineReducers({
  baseReducer,
  modalReducer,
  helpTagReducer,
  menuReducer,
  studyReducer,
  pageReducer,
  stepReducer,
  deviceReducer,
  previewReducer
});

const rootReducer = (state, action) => {
  if (action.type === "HARD_RESET") {
    LoggerController.events.logInfo.log(`StateReset - ${action.payload}`)
    storage.removeItem("persist:root");
    state = undefined;
  }

  if (action.type === "TEST_STATE_REPLACE") {
    state = action.payload;
  }

  return appReducer(state, action);
};

export default rootReducer;
