import React, { useEffect, useRef } from "react";
import { LSIntlFormat } from "../../translations/controller";
import CSSTransition from "react-transition-group/CSSTransition";
import { store } from "../../store/index";
import { TransitionGroup } from "react-transition-group";
import tabChangeListener from "../../utils/listeners/TabChangeListener";
import LoggerController from "../../controllers/Logger/LoggerController";

const LSLoadingNew = ({ noLog }) => {
  let loadingCounter = useRef(0);
  let loadingTimeout = useRef();

  useEffect(() => {
    if (!noLog) {
      tabChangeListener.setIsLoading(true);

      loadingTimeout.current = setTimeout(handleLongLoad, 10000);
    }

    return () => {
      tabChangeListener.setIsLoading(false);
      if (loadingTimeout.current) {
        clearTimeout(loadingTimeout.current);
      }
    };
  }, []);

  const handleLongLoad = () => {
    if (loadingCounter && typeof loadingCounter.hasOwnProperty("current")) {
      if (loadingCounter.current === 0) {
        loadingCounter.current = 10000;
        setTimeout(handleLongLoad, 30000);
      } else if (loadingCounter.current === 10000) {
        loadingCounter.current = 40000;
        setTimeout(handleLongLoad, 30000);
      } else {
        loadingCounter.current = 70000;
      }
      LoggerController.panelistAction("LS:LongLoad", {
        timeTaken: loadingCounter.current,
      });
      LoggerController.events.logLongLoad.log(`Waiting for loading for ${loadingCounter.current}ms`)
    }
  };

  return (
    <TransitionGroup>
      <CSSTransition
        timeout={{ enter: 300, exit: 300 }}
        classNames="toggle-fade"
        children={
          <div className="absoluteCenter centerAlign ls-loading">
            <div className="lds-css">
              <div className="lds-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
            {store.getState()._persist.rehydrated ? (
              <h1>
                {LSIntlFormat.IntlMessageFormat(
                  "app.prestart.loading",
                  "Loading, please wait."
                )}
              </h1>
            ) : null}
          </div>
        }
      ></CSSTransition>
    </TransitionGroup>
  );
};

/**
 * Loading component.
 */
class LSLoading extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <TransitionGroup>
        <CSSTransition
          timeout={{ enter: 300, exit: 300 }}
          classNames="toggle-fade"
          children={
            <div
              data-lumentest="loading-overlay"
              className="absoluteCenter centerAlign ls-loading"
            >
              <div className="lds-css">
                <div className="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
              </div>
              {store.getState()._persist.rehydrated ? (
                <h1>
                  {LSIntlFormat.IntlMessageFormat(
                    "app.prestart.loading",
                    "Loading, please wait."
                  )}
                </h1>
              ) : null}
            </div>
          }
        ></CSSTransition>
      </TransitionGroup>
    );
  }
}

export default LSLoadingNew;
