import { REGIONS } from "../types/types";

const STATES = [
  "prestart",
  "index",
  "demographics",
  "instructions",
  "setup",
  "study",
  "completion",
  "error",
  "invalid",
  "debug",
  "preview",
];

const initialState = {
  // base
  basestate: "prestart",
  progressAvailable: false,
  lastError: {
          publicMessage:
          "Generic Error.",
          fullMessage: `Generic Error.`,
          translationId: "app.error.generic"
        },
  errorMessage: "Generic Error.",
  errorMessageLong: null,
  errorMessageId: "app.error.generic",
  errorLong:'',
  isLoading: false,
  loadedAt: new Date(),
  deviceInfo: null,
  submissionInProgress: false,
  progressBarTiming: 0,
  region: REGIONS.DEFAULT,
  debugLog: false,
  lastKnownPath: null,
  clickData: null,
  clickListener: true,
  lastUserAction:'',
  waitingForApi:'',

};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "PROGRESS_STATE":
      if (state.basestate === "completion" || state.basestate === "error") {
        console.log("CANNOT PROGRESS ANY FURTHER!");
        return state;
      } else {
        let currentStateIndex = STATES.indexOf(state.basestate);
        return {
          ...state,
          basestate: STATES[currentStateIndex + 1],
        };
      }
    case "REGRESS_STATE":
      if (state.basestate === "index") {
        console.log("CANNOT REGRESS ANY FURTHER!");
        return state;
      } else {
        let currentStateIndex = STATES.indexOf(state.basestate);
        return {
          ...state,
          basestate: STATES[currentStateIndex - 1],
        };
      }
    case "CHANGE_STATE_TO":
      if (STATES.indexOf(action.payload) === -1) {
        console.log("STATE " + action.payload + " DOES NOT EXISTS!");
        return state;
      } else {
        return {
          ...state,
          basestate: action.payload,
        };
      }
    case "ERROR_STATE":
      if (!!action.payload) {
        return {
          ...state,
          lastError: action.payload
        };
      } else {
        return {
          ...state,
          errorMessage: "Oops! Something went wrong.",
          errorMessageId: "app.error.generic",
          basestate: "error",
        };
      }
    case "INVALIDATE_USER":
      return {
        ...state,
        basestate: "invalid",
      };
    case "PROGRESS_AVAILABLE_TRUE":
      return {
        ...state,
        progressAvailable: true,
      };
    case "PROGRESS_AVAILABLE_FALSE":
      return {
        ...state,
        progressAvailable: false,
      };
    case "IS_LOADING_TRUE":
      return {
        ...state,
        isLoading: true,
      };
    case "IS_LOADING_FALSE":
      return {
        ...state,
        isLoading: false,
      };
    case "DEBUG_STATE":
      return {
        ...state,
        basestate: "debug",
      };
    case "STORE_DEVICE_INFO":
      return {
        ...state,
        deviceInfo: action.payload,
      };
    case "SUBMISSION_IN_PROGRESS_TRUE":
      return {
        ...state,
        submissionInProgress: true,
      };
    case "SUBMISSION_IN_PROGRESS_FALSE":
      return {
        ...state,
        submissionInProgress: false,
      };
    case "PROGRESS_BAR_FINISHED":
      return {
        ...state,
        progressBarTiming: 0,
      };
    case "PROGRESS_BAR_TIMING":
      return {
        ...state,
        progressBarTiming: action.payload,
      };
    case "SET_REGION":
      return {
        ...state,
        region: action.payload,
      };
    case "SET_DEBUG_LOG":
      return {
        ...state,
        debugLog: true,
      };
    case "SET_PATH":
      return {
        ...state,
        lastKnownPath: action.payload,
      };
    case "SET_SPEEDTEST":
      return {
        ...state,
        deviceInfo: { ...state.deviceInfo, connectionSpeed: action.payload },
      };
    case "STORE_CLICK_DATA":
      if (state.clickData && state.clickData.click1 && state.clickData.click2) {
        if (action.payload.screenX < state.clickData.click1.screenX) {
          return {
            ...state,
            clickData: { ...state.clickData, click1: action.payload },
          };
        } else if (action.payload.screenX > state.clickData.click2.screenX) {
          return {
            ...state,
            clickData: { ...state.clickData, click2: action.payload },
          };
        } else {
          return state;
        }
      } else if (state.clickData && state.clickData.click1) {
        if (action.payload.screenX < state.clickData.click1.screenX) {
          return {
            ...state,
            clickData: {
              click1: action.payload,
              click2: state.clickData.click1,
            },
          };
        } else {
          return {
            ...state,
            clickData: {
              ...state.clickData,
              click2: action.payload,
            },
          };
        }
      } else {
        return {
          ...state,
          clickData: { click1: action.payload },
        };
      }
    case "SET_CLICK_DATA":
      return {...state, clickData: action.payload}
    case "RESET_CLICK_DATA":
      return { ...state, clickData: null };
    case "STOP_CLICK_LISTENER":
      return { ...state, clickListener: false };
    case "SET_WAITING_FOR_API":
      return {...state, waitingForApi: action.payload}
    case "SET_LAST_USER_ACTION":
      return {...state, lastUserAction: action.payload}
    case "RESET_LOADED_AT":{
      return {...state, loadedAt: new Date()}
    }
    default:
      return state;
  }
}
