import Rollbar from "rollbar";
import { navigateWithOutParams } from "../../history";
import { store } from "../../store";
import errors from "./errors";
import warnings from "./warnings";

class ErrorController {
  constructor() {
    this.errors = errors;
    this.warnings = warnings;
    this.tracker = null;
    this.platformToken = "445598a05850426583db5e7c20fd7357";
    this.lookalikeToken = "66555f90ada0451e8e452e0f56a88ddb";
  }

  initTracker = (which) => {
    if (which === "LOOKALIKES") {
      this.tracker = new Rollbar({
        accessToken: this.lookalikeToken,
        captureUncaught: true,
        captureUnhandledRejections: true,
        // ignoredMessages: ["play() can only be initiated by a user gesture.", "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."],
        payload: {
          environment:
            process.env.REACT_APP_LUMEN_ENV_VALUE == "release"
              ? "production"
              : "development",
          currentState: null,
        },
      });
    } else {
      this.tracker = new Rollbar({
        accessToken: this.platformToken,
        captureUncaught: true,
        captureUnhandledRejections: true,
        // ignoredMessages: ["play() can only be initiated by a user gesture.", "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."],
        payload: {
          environment:
            process.env.REACT_APP_LUMEN_ENV_VALUE == "release"
              ? "production"
              : "development",
          currentState: null,
        },
      });

      this.tracker.configure({
        onSendCallback: function (isUncaught, args, payload) {
          payload.currentState = store.getState();
        },
      });
    }
  };

  changeTrackerToken = (which) => {
    if (which === "LOOKALIKES") {
      this.tracker.configure({
        accessToken: this.lookalikeToken,
        onSendCallback: undefined
      });
    } else {
      this.tracker.configure({
        accessToken: this.platformToken,
        onSendCallback: function (isUncaught, args, payload) {
          payload.currentState = store.getState();
        },
      });
    }
  };

  sendTrackerWarning = (warning, payload) => {
    if (this.tracker) {
      this.tracker.warning(warning, payload);
    }
  };

  sendTrackerError = (error, payload) => {
    if (this.tracker) {
      this.tracker.warning(error, payload);
    }
  };

  sendTrackerInfo = (info, payload) => {
    if (this.tracker) {
      this.tracker.info(info, payload);
    }
  };

  unhandledError = (error) => {
    this.sendTrackerError(error);

    let { studyId } = store.getState().studyReducer;
    let fromUrl = parseInt(window.location.pathname.split("/"))[2];

    store.dispatch({
      type: "ERROR_STATE",
      payload: {
        publicMessage: "Oops! Something went wrong.",
        fullMessage: `Unhandled Error: ${error.message}`,
        translationId: "app.error.generic",
      },
    });

    navigateWithOutParams(`/study/${studyId || fromUrl}/error/`);
  };
}

export default new ErrorController();
