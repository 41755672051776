﻿{
    "app.button.faqs": "FAQ",
    "app.button.terms": "Ketentuan Penggunaan",
    "app.button.privacy": "Kebijakan Privasi",
    "app.button.feedback": "Feedback",
    "app.button.selectLanguage": "Pilih Bahasa",
    "app.button.help": "Bantuan",
    "app.button.start": "Mulai",
    "app.button.next": "Selanjutnya",
    "app.button.submit": "Kirim",
    "app.button.takepart": "OK – Saya akan bergabung",
    "app.menu.feedback.content": "Jika Anda mengalami masalah dalam studi atau ingin meninggalkan komentar, harap tinggalkan pesan di sini.",
    "app.contactModal.feedback": "Terima kasih atas feedback Anda!",
    "app.takePartModal.line1": "Jika Anda keluar sekarang, Anda tidak dapat memenuhi syarat untuk bisa mendapatkan hadiah partisipasi apapun.",
    "app.takePartModal.line2": "Yang Anda butuhkan hanyalah webcam!",
    "app.takePartModal.reason1": "Saya tidak punya webcam.",
    "app.takePartModal.reason2": "Saya tidak nyaman menggunakan webcam dalam kegiatan survei ini.",
    "app.takePartModal.reason3": "Saya tidak punya waktu untuk ini sekarang.",
    "app.takePartModal.reason4": "Alasan lain.",
    "app.generic.redirect": "Anda akan terhubung segera.",
    "app.prestart.loading": "Memuat, harap tunggu.",
    "app.index.title": "Selamat Datang",
    "app.index.content.line1": "Dalam survei ini, kami akan menggunakan kamera Anda untuk memperkirakan ke mana Anda mencari. Jangan khawatir, kami tidak akan mengambil gambar atau video.",
    "app.index.content.line1alt": "Harap pastikan Anda duduk dengan nyaman, lalu ketuk mulai untuk memulai.",
    "app.index.content.line2": "Apakah perangkat Anda memiliki kamera? Jika iya, harap tekan tombol mulai di bawah ini.",
    "app.index.rejection": "Maaf, tampaknya Anda telah menggunakan link yang salah.",
    "app.index.webview": "Maaf, tampaknya Anda telah mengakses halaman kami dari dalam webview. Harap buka link ini di dalam browser Anda. Ini mungkin dapat dilakukan langsung dari menu webview.",
    "app.index.webview.chrome.1": "Ketuk tombol menu di atas dan pilih ‘Buka di Chrome’.",
    "app.index.webview.chrome.2": "Akan membuka survei ini dalam aplikasi browser, sehingga dapat bekerja dengan baik.",
    "app.index.webview.android.1": "Ketuk mulai di bawah untuk membuka survei ini di aplikasi browser, sehingga dapat bekerja dengan baik.",
    "app.index.webview.android.2": "Jika ini gagal, buka link ke halaman ini dalam browser secara manual (contoh, copy dan paste link pada address bar di Chrome atau gunakan opsi seperti ‘Buka di Chrome’ pada menu bar di atas).",
    "app.index.webview.safari.1": "Ketuk ikon Safari di bagian bawah layar untuk melanjutkan.",
    "app.index.webview.safari.2": "Itu terlihat seperti ini:",
    "app.index.webview.safari.3": "Ini akan membuka survei di Safari sehingga dapat bekerja dengan baik.",
    "app.index.wifi": "Tampaknya koneksi internet Anda terlalu lambat. Apakah Anda terhubung dengan wifi?",
    "app.index.tellmemore": "Beritahu selengkapnya.",
    "app.index.takepart": "Saya tidak ingin bergabung.",
    "app.instructions.title": "Instruksi",
    "app.instructions.instruction1": "Jika praktis, jangan gunakan kacamata selama survei.",
    "app.instructions.instruction2": "Duduklah, sehingga Anda dapat menjaga dengan nyaman kepala dan perangkat Anda tidak bergerak.",
    "app.instructions.instruction3": "Gerakkan mata bukan kepala Anda.",
    "app.instructions.instruction4": "Siap untuk mulai?",
    "app.instructions.instruction5": "Harap gunakan wifi untuk menghindari pemakaian data.",
    "app.instructions.instruction6": "Harap baca instruksi berikut dengan seksama.",
    "app.instructions.instruction7": "Letakkan ponsel Anda pada permukaan yang stabil.",
    "app.instructions.instruction8": "Harap pastikan ruangan Anda cukup terang – sebaiknya dari atas (bukan dari belakang).",
    "app.setup.title": "Atur",
    "app.setup.instruction0": "Letakkan perangkat Anda dalam posisi berdiri.",
    "app.setup.instruction0a": "Harap putar perangkat Anda menjadi posisi berdiri.",
    "app.setup.instruction0b": "Harap putar perangkat Anda sehingga kamera ada di sebelah kiri.",
    "app.setup.instruction0c": "Harap putar perangkat Anda sehingga kamera ada di sebelah kanan.",
    "app.setup.instruction1": "Memulai...",
    "app.setup.instruction2": "Saat Anda terdeteksi, posisikan kepala Anda di dalam kotak.",
    "app.setup.instruction3": "Pertahankan posisi kepala dan perangkat Anda sampai survei selesai.",
    "app.setup.instruction4": "Sekarang kami hanya perlu memvalidasi.",
    "app.setup.instruction5": "Terima kasih! Ingat untuk tetap menjaga kepala dan perangkat Anda di posisi yang sama sampai survei selesai.",
    "app.setup.instruction6": "Silahkan geser ke atas untuk menyembunyikan bilah alamat.",
    "app.setup.retry": "Kesalahan terdeteksi. Tetap jaga kepala dan perangkat Anda diam dan jangan tutupi kamera, misalnya dengan jari. Gunakan tombol di bawah untuk mencoba lagi.",
    "app.setup.retryInit": "Kesalahan terdeteksi. Pastikan browser dapat mengakses kamera Anda. Jika sebelumnya Anda menolak izin akses kamera, Anda harus mengizinkannya di pengaturan privasi browser. Gunakan tombol di bawah untuk mencoba lagi.",
    "app.setup.retryButton": "Coba lagi",
    "app.setup.calibrationProp.watch": "Ikuti titik yang muncul di layar",
    "app.setup.calibrationProp.watch.george": "Selanjutnya, saat Anda melihat wajah, harap terus melihatnya sambil mencerminkan gerakan kepalanya",
    "app.setup.calibrationProp.click": "Klik di mana saja untuk melanjutkan",
    "app.setup.calibrationProp.tap": "Ketuk di mana saja untuk melanjutkan",
    "app.study.validationProp.watch": "Ikuti titik saat muncul di layar",
    "app.study.validationProp.restart": "Harap coba kembali",
    "app.study.validationProp.click": "Klik di mana saja untuk melanjutkan",
    "app.study.validationProp.tap": "Ketuk di mana saja untuk melanjutkan",
    "app.study.render.error": "Domain tidak valid untuk langkah render.",
    "app.error.title": "Kesalahan",
    "app.error.generic": "Oops! Terjadi kesalahan.",
    "app.error.initialise": "Tidak dapat memulai!",
    "app.error.findCamera": "Tidak dapat menemukan kamera!",
    "app.error.headPosition": "Tidak dapat menemukan posisi kepala Anda.",
    "app.error.calibrate": "Tidak dapat mengkalibrasi.",
    "app.error.validate": "Tidak dapat memvalidasi.",
    "app.error.start": "Gagal untuk memulai.",
    "app.error.assign": "Gagal menetapkan.",
    "app.error.osnotallowed": "OS Anda tidak diizinkan, harap periksa perangkat mana yang dapat Anda gunakan untuk survei ini dan coba lagi.",
    "app.error.demoquotafullnoactivecell": "Terima kasih atas minat Anda. Studi ini sekarang tertutup untuk wilayah Anda.",
    "app.invalid.title": "Tidak sah",
    "app.invalid.generic": "Maaf, Anda tidak memenuhi syarat untuk studi ini.",
    "app.completion.title": "Penyelesaian",
    "app.completion.congratulations": "Selamat! Anda telah menyelesaikan studi. Akses survei ini ke kamera Anda sekarang akan dihentikan.",
    "app.questions.required": "Pertanyaan ini diperlukan.",
    "app.faq.question1" : "Q: Apa itu Platform Studi Lumen dan apa yang terlibat dalam survei pelacakan mata?",
    "app.faq.answer1" : "A: Survei ini melibatkan penggunaan teknologi pelacakan mata. Anda akan diminta untuk duduk di depan webcam atau kamera depan perangkat Anda dan melakukan kalibrasi sehingga perkiraan dapat dibuat saat Anda melihat layar. Selama survei, Anda akan diminta untuk melihat beberapa item (misalnya gambar, situs web atau video) sementara alat survei memperkirakan di mana Anda melihat. Data yang diambil dari ‘pelacakan mata’ ini adalah koordinat perkiraan titik pandangan di layar Anda dari waktu ke waktu. Perangkat lunak ini menggunakan gambar dari webcam Anda untuk membuat perkiraan, tetapi tidak mengambil foto atau video Anda. Anda mungkin juga akan ditanyai beberapa pertanyaan tentang diri Anda, pengalaman Anda, dan hal-hal yang telah Anda lihat selama survei. Data dikirim ke Lumen Research Ltd. Lumen Research akan menganalisa data dan membagikan hasil kepada kliennya.",
    "app.faq.question2" : "Q: Mengapa perangkat lunak memerlukan webcam/kamera dan bagaimana ini digunakan?",
    "app.faq.answer2" : "A: Perangkat lunak ini menggunakan gambar dari webcam atau kamera perangkat Anda untuk memperkirakan di mana Anda melihat layar. Ini dilakukan dengan menggunakan kombinasi perangkat lunak pelacakan wajah dan pelacakan mata yang menganalisi gambar secara local di mesin Anda secara real time. Gambar-gambar ini diproses dan ditunjukkan kepada Anda selama kalibrasi sehingga Anda dapat memposisikan diri Anda dengan benar, tetapi gambar tidak direkam sehingga privasi Anda terlindungi. Data yang direkam dan dikumpulkan dari pelacakan mata bukanlah gambar, tetapi data numerik tentang perkiraan titik pandangan pada stimulus dari waktu ke waktu.",
    "app.faq.question3" : "Q: Apakah Anda mengambil gambar saat saya melakukan pelacakan mata?",
    "app.faq.answer3" : "A: Satu-satunya gambar yang digunakan adalah untuk memungkinkan Anda memposisikan diri dengan benar selama kalibrasi. Gambar-gambar ini tidak disimpan, dan hanya dilihat oleh Anda. Data yang direkam dan dikumpulkan dari pelacakan mata bukanlah gambar, tetapi data numerik tentang perkiraan titik pandangan pada stimulus dari waktu ke waktu.",
    "app.faq.question4" : "Q: Data apa yang dikumpulkan selama survei?",
    "app.faq.answer4.line1" : "A: Perangkat lunak akan mengumpulkan beberapa atau semua dari lima jenis data berikut:",
    "app.faq.answer4.line2" : "“ID Pengguna” – ID Pengguna digunakan untuk mengidentifikasi responden (untuk tujuan pemberian hadiah partisipasi);",
    "app.faq.answer4.line3" : "“Data Pandangan” – Perkiraan di mana pengguna melihat di layar mereka saat ditampilkan rangsangan di layar",
    "app.faq.answer4.line4" : "“Data survei” – Tanggapan atas pertanyaan yang dijawab oleh pengguna",
    "app.faq.answer4.line5" : "“Metadata” – Data tentang waktu di mana individu berhasil berpartisipasi dalam penelitian.",
    "app.faq.answer4.line6" : "“Data Perangkat Fisik” – Data tentang computer di mana pengguna berpartisipasi seperti tingkat RAM, jenis CPU di mesin dan agen pengguna (system operasi dan versi browser) serta informasi tentang layar komputer (ukuran dan resolusi) webcam (membuat dan resolusi), dan program antivirus.",
    "app.faq.question5" : "Q: Bagaimana data yang dikumpulkan akan digunakan?",
    "app.faq.answer5.line1" : "A: Data yang dikumpulkan akan digunakan untuk mengeksplorasi bagaimana individu melihat dan berinteraksi dengan berbagai jenis gambar visual dan media, dan untuk mengukur pendapat orang tentang gambar yang ditampilkan.",
    "app.faq.answer5.line2" : "Lumen dapat berbagi data penelitian dengan perusahaan afiliasi dan dengan mitra bisnis serta klien. Meskipun data penelitian mungkin dibagikan dengan afiliasi, mitra, dan klien, ini biasanya dilakukan secara anonym. Afiliasi, mitra dan klien tersebut dapat menggunakan data ini untuk membuat deskripsi ringkasan dari berbagai perilaku konsumen dan segmen konsumen untuk mengembangkan produk atau untuk memberikan solusi pemasaran kepada pelanggan atau konsumen mereka.",
    "app.faq.answer5.line3" : "Lumen mengumpulkan “Data Perangkat Fisik” dan “Metadata” untuk membantu mengelola pengumpulan dan pemrosesan data, termasuk kontrol kualitas dan manajemen volume.",
    "app.faq.answer5.line4" : "Data yang dikumpulkan akan disimpan selama periode yang diperlukan untuk memenuhi tujuan yang digariskan.",
    "app.faq.question6" : "Q: Seberapa aman data saya?",
    "app.faq.answer6" : "A: Data yang dikumpulkan oleh Lumen dienkripsi menggunakan teknologi SSL. Ini adalah teknologi enkripsi standar tinggi yang paling umum digunakan dan digunakan oleh bank untuk mengamankan transaksi perbankan dan komunikasi sangat sensitif lainnya. Ini juga digunakan oleh Amazon, PayPal, eBay, dan situs web lain yang membutuhkan sistem keamanan tingkat bank. Para ilmuwan memperkirakan bahwa memecahkan enkripsi 256 bit menggunakan teknologi saat ini akan membutuhkan waktu yang jauh lebih lama daripada usia alam semesta. Data yang dikumpulkan disimpan di server web aman yang dikelola oleh Amazon Web Services (AWS). AWS adalah salah satu platform layanan komputasi awan yang aman dan dapat diandalkan yang paling umum digunakan dan digunakan oleh layanan komersial berbasis cloud utama termasuk Netflix dan Expedia.",
    "app.faq.question7" : "Q: Kewajiban apa yang Anda miliki terkait Konten yang ditampilka??",
    "app.faq.answer7" : "A: Dengan berpartisipasi, Anda juga memahami dan setuju bahwa Konten yang Anda perlihatkan dalam penelitian ini mungkin bersifat rahasia, milik pihak ketiga dan/atau tunduk pada undang-undang merek dagang atau hak cipta dan Anda tidak akan membuat tangkapan layar, merekam, atau menyalin apa pun dari Konten atau mendistribusikan ulang Konten penelitian dengan cara apa pun.",
    "app.faq.question8" : "Q: Saya tidak yakin seberapa bagus webcam saya, apakah ini akan berfungsi untuk pelacakan mata?",
    "app.faq.answer8" : "A: Sistem bekerja dengan berbagai macam webcam – seharusnya tidak menjadi masalah.",
    "app.faq.question9" : "Q: Saya telah menyelesaikan studi, apakah saya perlu melakukan hal lain??",
    "app.faq.answer9" : "A: Tidak, tidak perlu melakukan hal lain. Perangkat lunak akan menutup secara otomatis.",
    "app.faq.question10" : "Q: Apakah saya perlu terhubung ke internet untuk berpartisipasi?",
    "app.faq.answer10" : "A: Ya, Anda memerlukan koneksi internet untuk mengunduh program dan menyelesaikan tugas.",
    "app.faq.question11" : "Q: Apakah saya perlu menyelesaikan tugas di permukaan yang stabil?",
    "app.faq.answer11" : "A: Ya, untuk hasil terbaik, Anda harus duduk dengan nyaman di depan layar komputer Anda, setelah kalibrasi tetap diam sementara. Anda akan diberikan stimulus, sampai pemberitahuan. Kami menyarankan untuk tidak ikut serta  jika Anda berada di kereta atau menyeimbangkan laptop di pangkuan Anda, dll.",
    "app.faq.question12" : "Q: FAQ belum menyelesaikan masalah saya, apa yang harus saya lakukan sekarang??",
    "app.faq.answer12" : "A: Silakan email kami di study-feedback@lumen-research.com dan kami akan menghubungi Anda secepat mungkin.",
    "app.faq.patent" : " Pengujian konten web Lumen didukung oleh teknologi pengujian dalam konteks milik kami sendiri (Nomor Aplikasi Paten Inggris 1816158.8)",
    "app.terms.line1" : "Ketentuan penggunaan ini (“Ketentuan Penggunaan”) mengatur perjanjian antara Anda dan Lumen Research Ltd (“Lumen” atau “Kami”), sebuah perusahaan yang terdaftar di Inggris, nomor perusahaan 8682432 dengan kantor terdaftar di Unit 215, 22 Highbury Grove, London N5 2EF dan referensi pendaftaran perlindungan data ZA130772, mengenai penggunaan Anda atas perangkat lunak pelacakan mata 'ViewPoints' ('Perangkat Lunak') pada perangkat Anda.",
    "app.terms.line2" : "1. Kesepakatan untuk persyaratan ",
    "app.terms.line3" : " Dengan menggunakan Perangkat Lunak, Anda mengakui bahwa Anda telah setuju untuk terikat dengan Perjanjian ini. Jika Anda tidak menyetujui syarat dan ketentuan Perjanjian ini, jangan selesaikan survei ViewPoints.",
    "app.terms.line4" : "2. Informasi Rahasia ",
    "app.terms.line5" : " Saat memasang, menggunakan, atau dengan cara apa pun terlibat dengan ViewPoints, Anda mungkin memiliki akses ke informasi dan/atau konten yang dimiliki atau berkaitan dengan Lumen termasuk informasi yang diidentifikasi atau diperlakukan oleh Lumen atau klien atau pelanggan Lumen sebagai rahasia atau karena alasan karakternya atau keadaan atau cara pengungkapannya jelas bersifat rahasia termasuk (tanpa berprasangka sebelumnya) setiap informasi tentang rencana bisnis, peluang bisnis baru yang matang, proyek penelitian dan pengembangan, desain, proses rahasia, kode, desain perangkat lunak, rahasia dagang , pengembangan dan formula produk atau layanan, pengetahuan, penemuan, statistik dan prakiraan penjualan, strategi dan rencana pemasaran, biaya, laba rugi dan informasi keuangan lainnya (kecuali sejauh dipublikasikan dalam akun yang diaudit), harga dan struktur diskon (semua baik dicatat secara tertulis atau dalam format elektronik atau lainnya) (“Informasi Rahasia”). Informasi Rahasia tersebut akan tetap menjadi milik tunggal dan eksklusif Lumen dan/atau pelanggannya, dan dalam keadaan apa pun Anda tidak boleh memperoleh atau menerima hak, kepemilikan, dan/atau kepentingan apa pun atas Informasi Rahasia tersebut.",
    "app.terms.line6" : "Anda tidak boleh entah itu selama berpartisipasi di ViewPoints atau kapan pun (tanpa batas) setelah penghentian partisipasi tersebut: membocorkan atau berkomunikasi dengan siapa pun; gunakan untuk tujuan Anda sendiri; atau melalui kegagalan apa pun untuk melakukan kehati-hatian dan ketekunan, menyebabkan pengungkapan Informasi Rahasia apa pun yang tidak sah. Anda harus selalu menggunakan upaya terbaik Anda untuk mencegah publikasi atau pengungkapan Informasi Rahasia apa pun. Pembatasan ini akan berhenti berlaku untuk informasi apa pun yang akan tersedia untuk umum secara umum selain melalui default Anda.",
    "app.terms.line7" : "3. Properti Lumen",
    "app.terms.line8" : "Perangkat Lunak ViewPoints dan semua data, kode, kunci, kata sandi, desain, gambar, catatan, dan dokumen atau materi lain yang berkaitan dengan Perangkat Lunak akan dan tetap menjadi milik Lumen selama durasi partisipasi Anda dalam survei ViewPoints. Lumen memiliki semua hak intelektual atau hak milik atas setiap dan semua hak kekayaan intelektual atau industri yang ada dan yang akan datang dalam dan atas dokumen, bahan, model, desain, gambar, proses, penemuan, formula, pengkodean komputer, data, antarmuka sistem, metodologi, pengetahuan -bagaimana, Informasi Rahasia atau pekerjaan lain, yang dilakukan, dibuat, dibuat, dirancang, dikembangkan, ditingkatkan, didekonstruksi atau ditemukan oleh Anda sendiri atau dengan orang lain sehubungan dengan atau terkait dengan Perangkat Lunak dan/atau Perangkat atau yang dapat digunakan atau diadaptasi untuk digunakan di dalamnya atau sehubungan dengannya (baik terdaftar atau tidak), termasuk semua paten yang ada dan yang akan datang, hak cipta, hak desain, hak basis data, merek dagang, hak topografi semikonduktor, hak varietas tanaman, hak internet/nama domain, pengetahuan dan setiap dan semua aplikasi untuk salah satu hal di atas dan setiap dan semua hak untuk mengajukan salah satu dari yang disebutkan di atas (“Hak Kepemilikan”). Tidak ada Hak Kepemilikan yang akan diberikan kepada Anda setiap saat.",
    "app.terms.line9" : "4. Syarat dan batasan penggunaan ",
    "app.terms.line10" : "Perangkat Lunak ViewPoints dilisensikan kepada pengguna akhir. Tunduk pada Perjanjian ini, kami dengan ini setuju untuk mengizinkan Anda, secara non-eksklusif, dapat dibatalkan, tidak dapat dipindahtangankan, tidak dapat disublisensikan, secara terbatas, untuk menggunakan Perangkat Lunak ViewPoints pada perangkat yang Anda miliki atau kendalikan atau komputer pribadi sehubungan dengan yang Anda nyatakan dan menjamin bahwa Anda memiliki semua hak dan izin yang diperlukan untuk pemasangan tersebut. Untuk menghindari keraguan, Anda tidak boleh menggunakan untuk tujuan apa pun di luar ViewPoints, menerjemahkan, menyalin, mereproduksi, mengirimkan melalui telekomunikasi, mengadaptasi, menggabungkan, mengubah, atau memodifikasi Perangkat Lunak dengan cara apa pun.",
    "app.terms.line11" : "Anda setuju untuk tidak: (i) menyalin, memodifikasi, mengadaptasi, menyediakan, menyewakan, menyewakan, membagi waktu, membuat karya turunan dari, atau berupaya menjual atau mengalihkan hak apa pun dalam Perangkat Lunak (ii) membongkar, mendekompilasi, atau membalikkan merekayasa bagian apa pun dari Perangkat Lunak dengan cara apa pun; atau mengizinkan atau membantu pihak mana pun untuk memperoleh atau mencoba memperoleh kode sumber, membongkar, mendekripsi, mendekompilasi, atau merekayasa balik Perangkat Lunak; atau mengambil langkah lain untuk mendapatkan informasi desain mengenai Perangkat Lunak; (iii) menggunakan Perangkat Lunak untuk tujuan apa pun selain tujuan awalnya didistribusikan kepada Anda; (iv) menggunakan spider, robot, atau teknik penambangan data otomatis lainnya untuk membuat katalog, mengunduh, menyimpan, atau mereproduksi atau mendistribusikan Perangkat Lunak, atau untuk memanipulasi hasil survei atau aktivitas lainnya; (v) mengambil tindakan apa pun untuk mengganggu Perangkat Lunak atau penggunaan Perangkat Lunak oleh individu termasuk, tanpa batasan, dengan membebani, “membanjiri”, “membom surat”, atau “menghancurkan” Perangkat Lunak dan sistem penyimpanan data yang digunakan Perangkat Lunak melalui Amazon Layanan Web (vi) mengirimkan, kepada kami setiap kode ilegal, menipu, atau berbahaya, termasuk, tanpa batasan, virus, spyware, adware, atau kode berbahaya lainnya..",
    "app.terms.line12" : "Semua hak yang tidak secara tegas diberikan kepada Anda di bawah ini adalah milik kami dan pemberi lisensi kami. Anda setuju bahwa kami tidak berkewajiban berdasarkan Perjanjian ini atau dengan cara lain untuk memperbaiki bug, cacat, atau kesalahan apa pun dalam Perangkat Lunak, atau untuk mendukung, memelihara, meningkatkan, memodifikasi, meningkatkan, memperbarui, atau menyempurnakan Perangkat Lunak. Kami tidak akan bertanggung jawab untuk memberikan bantuan kepada Anda atau orang yang Anda tunjuk dalam penggunaan Perangkat Lunak dengan cara apa pun.",
    "app.terms.line13" : "Kami tidak bertanggung jawab atas segala kerusakan pada PC Anda. Termasuk namun tidak terbatas pada akibat penggunaan Perangkat Lunak oleh Anda. Kami tidak bertanggung jawab atas biaya data tambahan yang Anda keluarkan sebagai akibat dari data yang dikumpulkan oleh Perangkat Lunak melalui partisipasi Anda dalam Penelitian.",
    "app.terms.line14" : "5. Sangkalan",
    "app.terms.line15" : "Kami dan direktur, pejabat, karyawan, agen, perwakilan, pemberi lisensi, dan penyedia kami masing-masing tidak membuat pernyataan atau jaminan atau ketentuan (a) bahwa perangkat lunak akan tersedia secara tepat waktu, atau bahwa akses ke perangkat lunak tidak akan terganggu atau aman; (b) bahwa cacat atau kesalahan akan diperbaiki; atau (c) bahwa perangkat lunak atau server atau jaringan yang digunakan untuk menyediakan perangkat lunak dan perangkat tersebut aman atau bebas dari virus atau komponen berbahaya lainnya. Kami tidak berkewajiban untuk menyediakan layanan pemeliharaan dan/atau dukungan apa pun sehubungan dengan perangkat lunak.",
    "app.terms.line16" : "6. Batasan tanggung jawab ",
    "app.terms.line17" : "(a) Sejauh yang diizinkan berdasarkan hukum yang berlaku, Anda setuju bahwa kami dan direktur, pejabat, karyawan, agen, perwakilan, pemberi lisensi, dan penyedia kami masing-masing tidak bertanggung jawab atas setiap atau semua kerugian, kerusakan, atau biaya dalam bentuk apa pun yang diakibatkan oleh pemasangan Anda, penggunaan atau akses perangkat lunak, penggunaan atau ketidakmampuan Anda untuk menggunakan perangkat lunak, informasi atau materi apa pun yang tersedia pada atau melalui perangkat lunak dan/atau yang berkaitan dengan perangkat lunak atau lainnya dengan perjanjian ini, terlepas dari sifat hukum , hak yang adil atau menurut undang-undang yang diklaim telah dilanggar.",
    "app.terms.line18" : "(b) Sejauh yang diizinkan berdasarkan hukum yang berlaku, satu-satunya solusi eksklusif Anda untuk ketidakpuasan dengan perangkat lunak adalah berhenti menggunakannya.",
    "app.terms.line19" : "(c) Dalam keadaan apa pun, entitas, direktur, pejabat, karyawan, agen, perwakilan kami tidak akan bertanggung jawab kepada Anda atas kerugian insidental, tidak langsung, konsekuensial, undang-undang, teladan, hukuman atau khusus, kehilangan pendapatan, kehilangan peluang, kehilangan reputasi , kehilangan penggunaan, kehilangan data, kehilangan benda tak berwujud lainnya, atau penyadapan data yang dikumpulkan oleh perangkat lunak oleh pihak ketiga, baik yang dapat diperkirakan atau tidak, bagaimanapun penyebabnya, dan berdasarkan kontrak apa pun, kesalahan (termasuk tanpa batasan kelalaian), tanggung jawab yang ketat atau teori lain, bahkan jika diberitahukan sebelumnya tentang kemungkinan terjadinya kerusakan tersebut.",
    "demo.index.content.line1": " Selama demo ini kami akan menggunakan kamera Anda untuk memperkirakan ke mana Anda mencari. Jangan khawatir, kami tidak akan mengambil foto atau video apa pun."
}