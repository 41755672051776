import * as DEBUGLOG from "../../../components/util/debugLog";
import requestTimer from "../../requestTimer";
import tabChangeListener from "../../../utils/listeners/TabChangeListener";
import participationUrl from "../participationUrl";

const completeStep = async (studyId, body) => {
  tabChangeListener.setWaitingForApi("completeStep");
  let completeStepRequestDate = DEBUGLOG.debugLogRequestStart("complete-step");

  let response = await fetch(
    participationUrl() + "/participation/studies/" + studyId + "/complete-step",
    {
      method: "POST",
      body: JSON.stringify(body),
    }
  );
  DEBUGLOG.debugLogRequestFinish(completeStepRequestDate);

  if (response.ok) {
    let responseBody = await response.json();
    tabChangeListener.setWaitingForApi("");
    return responseBody;
  } else {
    let e = new Error(response.statusText);
    e.name = response.status || "";
    throw e;
  }
};

export default (...args) => requestTimer(() => completeStep(...args));
