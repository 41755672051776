import DeviceController from "../controllers/DeviceController";
import LoggerController from "../../controllers/Logger/LoggerController";

class GestureListener {
  constructor() {
    this.videoElement = null;
    this.videoAutoplayAllowed = false;
    this.gestureCaptured = false;
    this.isListening = false;
  }

  gestureRequired() {
    let { isMobile, name } = DeviceController.getDeviceInfo();
    // && name === 'Chrome Mobile WebView'
    if (
      isMobile &&
      name === "Chrome Mobile WebView" &&
      !gestureListener.gestureCaptured
    ) {
      return true;
    } else {
      return false;
    }
  }

  createVideoElement() {
    if (!gestureListener.videoElement) {
      gestureListener.videoElement = document.createElement("video");
      gestureListener.videoElement.id = "video-placeholder";
      gestureListener.videoElement.className = "ls-video-placeholder";
      gestureListener.videoElement.src =
        "https://content.lumen-research.com/Platform_Resources/PlaceholderVid_2.mp4";
      gestureListener.videoElement.volume = 0;
      gestureListener.videoElement.muted = false;
      gestureListener.videoElement.playsInline = true;
      gestureListener.videoElement.style.width = "1px";
      gestureListener.videoElement.style.height = "1px";
      document.body.appendChild(gestureListener.videoElement);
    }
  }

  async playVideo() {
    if (!this.videoAutoplayAllowed) {
      gestureListener.videoElement.volume = 0;
      await new Promise((resolve) => {
        setTimeout(() => resolve(1), 250);
      });
      await gestureListener.videoElement.play();
      await gestureListener.videoElement.pause();
      await new Promise((resolve) => {
        setTimeout(() => resolve(1), 250);
      });
      gestureListener.videoElement.volume = 1;
      gestureListener.videoAutoplayAllowed = true;
    }
  }

  async returnVideoElement() {
    gestureListener.videoElement.id = "video-placeholder";
    gestureListener.videoElement.className = "ls-video-placeholder";
    gestureListener.videoElement.src =
      "https://content.lumen-research.com/Platform_Resources/PlaceholderVid_2.mp4";
    gestureListener.videoElement.style.width = "1px";
    gestureListener.videoElement.style.height = "1px";
    document.body.appendChild(gestureListener.videoElement);
  }

  async recordGesture() {
    gestureListener.gestureCaptured = true;
    gestureListener.unRegister();
  }

  resetGesture() {
    gestureListener.gestureCaptured = false;
    gestureListener.register();
  }

  register() {
    LoggerController.events.logSubscribe.log("GestureListener")
    if (!gestureListener.isListening) {
      window.addEventListener("click", this.recordGesture);
      window.addEventListener("touchend", this.recordGesture);
      gestureListener.createVideoElement();
    }
  }

  unRegister() {
    if (gestureListener.isListening) {
      LoggerController.events.logRevoke.log("GestureListener")
      window.removeEventListener("click", this.recordGesture);
      window.removeEventListener("touchend", this.recordGesture);
    }
  }
}

let gestureListener = new GestureListener();

export default gestureListener;
