import BaseStep from "./BaseStep";
import LumenEyeTracking from "..";
import { StorageHandler } from "@lumen-developer/lumen-common-js/storageHandler.bundle";
import TrackingBundleInternal from "@lumen-developer/lumen-common-js/trackingbundleinternal.bundle";
import { BROKER_TYPES } from "../../../types/types";

class ReInit extends BaseStep {
  constructor() {
    super("reInit", "BrokerReInit");

    this.args = [];

    this.cleanUp = this.cleanUp.bind(this);
    this.onError = this.onError.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.runProcess = this.runProcess.bind(this);
  }

  cleanUp() {
    this.removeErrorListener();

    LumenEyeTracking.removeInstructions();

    let calibrationCanvas = document.getElementById("lr_calibrator");
    if (calibrationCanvas) {
      calibrationCanvas.remove();
    }

    let validationElement = document.getElementById("validationElement");
    if (validationElement) {
      validationElement.style.display = "none";
    }
  }

  onError(error) {
    if (this.state !== "cancelled") {
      super.onError();

      //event from errPassthrough
      if (error && error.detail) {
        LumenEyeTracking.lastError = new Error(error.detail.error.message);
        LumenEyeTracking.lastError.name = "ReinitError";
        LumenEyeTracking.lastError.retryCode = error.detail.error.retryCode;
      } else if (error && error.message) {
        LumenEyeTracking.lastError = new Error(
          `[${error.name}] ${error.message}`
        );
        LumenEyeTracking.lastError.name = "ReinitError";
      } else {
        LumenEyeTracking.lastError = new Error("ReinitError");
        LumenEyeTracking.lastError.name = "ReinitError";
      }

      if (
        !!LumenEyeTracking.broker.trackersController &&
        !LumenEyeTracking.broker.trackersController.frameReader().running()
      ) {
        LumenEyeTracking.lastError = new Error("Failed to turn on camera.");
        LumenEyeTracking.lastError.name = "ReinitError";
      }

      //if the function is completed before the resolver is registered we need to wait for it to exist
      if (this.resolver) {
        LumenEyeTracking.errorHandler(
          this.resolver,
          this.cleanUp,
          "reInit",
          this.startTime
        );
      } else {
        setTimeout(() => {
          LumenEyeTracking.errorHandler(
            this.resolver,
            this.cleanUp,
            "reInit",
            this.startTime
          );
        }, 250);
      }
    }
  }

  onComplete() {
    if (this.state !== "cancelled") {
      super.onComplete();

      this.removeErrorListener();

      let [studyId, userId, sessionId, cellId] = this.args;

      LumenEyeTracking.trackingBundle = new TrackingBundleInternal(
        LumenEyeTracking.broker,
        userId,
        studyId,
        sessionId,
        cellId
      );

      if (this.resolver) {
        this.resolver({ success: true });
      } else {
        setTimeout(() => this.resolver({ success: true }), 250);
      }
    }
  }

  async runProcess(
    studyId,
    userId,
    sessionId,
    brokerUrl,
    cellId = null,
    isMobile = false,
    isCrawler = false,
    newTrackingTables = false
  ) {
    LumenEyeTracking.newTrackingTables = newTrackingTables;
    LumenEyeTracking.isCrawler = isCrawler;
    this.args = [studyId, userId, sessionId, cellId];

    let customEventHandlers = LumenEyeTracking.initialiseCustomEventHandlers();

    if (userId != null && !!StorageHandler.getItem("environmentVars") == true) {
      var environmentVars = JSON.parse(
        StorageHandler.getItem("environmentVars")
      );

      LumenEyeTracking.is_tracking = environmentVars.is_tracking;
      LumenEyeTracking.validation_step_id = environmentVars.validation_step_id;
      LumenEyeTracking.step_order_id = environmentVars.step_order_id + 1;
      LumenEyeTracking.startCalibrationTime =
        environmentVars.startCalibrationTime;

      var timeout = 45000;

      if (!!brokerUrl) {
        LumenEyeTracking.initBrokerType(brokerUrl);
        LumenEyeTracking.getBroker();

        let dynConfig = {};

        if (isCrawler) {
          dynConfig.testEyetracking = true;
          dynConfig.videoSrc =
            "https://content.lumen-research.com/Platform_Resources/eyetrackingMov.mp4";
        } else {
          dynConfig.isMobile = isMobile;
          if (brokerUrl.includes("/models/george/")) {
            dynConfig.configV2 = true;
            LumenEyeTracking.multiPartCalib = true;
          }
        }

        LumenEyeTracking.initialiseErrorPassthrough();
        this.setupErrorListener(this.onError);

        // reinit does not require tracker div
        if (
          LumenEyeTracking.brokerType == BROKER_TYPES.MOUSE ||
          LumenEyeTracking.brokerType == BROKER_TYPES.MOBILEMOUSE ||
          LumenEyeTracking.brokerType == BROKER_TYPES.AUDIBLE ||
          LumenEyeTracking.brokerType == BROKER_TYPES.NO_EYETRACKING ||
          LumenEyeTracking.brokerType == BROKER_TYPES.AUTOMATED_TESTING_BROKER
        ) {
          this.onComplete(userId, studyId, sessionId, cellId);
        } else {
          LumenEyeTracking.broker.init(
            timeout,
            null,
            dynConfig,
            customEventHandlers
          ).then(async () => {
            this.onComplete(userId, studyId, sessionId, cellId);
          }).catch(async (error) => {
            if(!!LumenEyeTracking.broker.trackersController && !LumenEyeTracking.broker.trackersController.frameReader().running()) {
              this.onError({message:"Failed to turn on camera."})
            } else {
              this.onError(error);
            }
          });
        }
      }
    } else {
      this.onError();
    }
  }
}

export default ReInit;
