import * as DEBUGLOG from "../../../components/util/debugLog";
import requestTimer from "../../requestTimer";
import tabChangeListener from "../../../utils/listeners/TabChangeListener";
import participationUrl from "../participationUrl";

const assign = async (studyId, body) => {
    tabChangeListener.setWaitingForApi('assign');

    let assignRequestDate = DEBUGLOG.debugLogRequestStart("assign");
    let response = await fetch(participationUrl() + "/participation/studies/" + studyId + "/assign", {
        method: 'POST',
        body: JSON.stringify(body)
    })
    DEBUGLOG.debugLogRequestFinish(assignRequestDate);

    if (response.ok) {
        let responseBody = await response.json()
        tabChangeListener.setWaitingForApi('');
        return responseBody
    } else {
      let e = new Error(response.statusText);
      e.name = response.status || "";
      throw e;
    }
}

export default (...args) => requestTimer(() => assign(...args))