{
    "app.button.faqs": "Perguntas Frequentes",
    "app.button.terms": "Termos de Utilização",
    "app.button.privacy": "Política de Privacidade",
    "app.button.feedback": "Comentários",
    "app.button.selectLanguage": "Selecione o idioma",
    "app.button.help": "Ajuda",
    "app.button.start": "Iniciar",
    "app.button.next": "Seguinte",
    "app.button.submit": "Submeter",
    "app.button.close": "Fechar",
    "app.button.takepart": "OK - Vou participar",
    "app.menu.feedback.content": "Se teve algum problema com o estudo ou se quer apenas deixar um comentário, sinta-se à vontade para o fazer aqui.",
    "app.contactModal.feedback": "Obrigado pelos seus comentários!",
    "app.takePartModal.line1": "Se sair agora, deixará de ter direito a qualquer prémio de participação.",
    "app.takePartModal.line2": "Precisa apenas de uma webcam!",
    "app.takePartModal.reason1": "Não tenho uma webcam.",
    "app.takePartModal.reason2": "Não me sinto à vontade em a minha webcam ser usada neste inquérito.",
    "app.takePartModal.reason3": "Não tenho tempo para isto agora.",
    "app.takePartModal.reason4": "Outra razão.",
    "app.generic.redirect": "Vai ser redirecionado(a) em breve.",
    "app.init.welcome": "Bem-vindo(a) à plataforma de estudos da Lumen Research",
    "app.init.intro": "A plataforma de estudos permite-nos configurar os utilizadores para um determinado estudo.",
    "app.init.message1": "Dependendo do género de estudo, o utilizador será orientado nas várias tarefas.",
    "app.init.message2": "Estas tarefas incluem, participar num inquérito, ler um conjunto de instruções e efetuar a calibração do rastreio ocular",
    "app.init.message3": "Agora vamos orientá-lo(a) numa demonstração do estudo.",
    "app.init.message4": "No decorrer deste estudo poderemos ativar a sua webcam para efetuar o rastreio ocular, mas os seus dados não serão registados.",
    "app.init.message5": "O estudo atual está configurado para usar as seguintes hiperligações ao sair do ecrã / terminar.",
    "app.prestart.loading": "A carregar, aguarde.",
    "app.index.title": "Bem-vindo(a)",
    "app.index.content.line1": "Durante este inquérito vamos usar a sua câmara para calcular o sítio para onde está a olhar. Não se preocupe, não vamos tirar fotografias nem gravar vídeos.",
    "app.index.content.line1alt": "Certifique-se de que se senta confortavelmente e toque em \"Iniciar\" para começar.",
    "app.index.content.line2": "O seu dispositivo tem uma câmara? Nesse caso, prima o botão \"Iniciar\" abaixo.",
    "app.index.rejection": "Lamentamos, parece que seguiu uma hiperligação com erro.",
    "app.index.webview": "Lamentamos, parece que acedeu à nossa página do interior do WebView. Abra esta hiperligação no seu navegador. Pode ser possível efetuar isto a partir do menu do WebView.",
    "app.index.webview.chrome.1": "Toque no botão do menu acima e selecione \"Abrir no Chrome\".",
    "app.index.webview.chrome.2": "Isto vai abrir este inquérito numa aplicação de navegação, para que funcione devidamente.",
    "app.index.webview.android.1": "Toque em \"Iniciar\" abaixo para abrir este inquérito numa aplicação de navegação, para que funcione devidamente.",
    "app.index.webview.android.2": "Se falhar, abra a hiperligação desta página manualmente numa aplicação de navegação (por exemplo, copie e cole a hiperligação na barra de endereços do Chrome ou use uma opção como \"Abrir no Chrome\", na barra de menus acima).",
    "app.index.webview.safari.1": "Toque no ícone do Safari no fundo do ecrã para continuar.",
    "app.index.webview.safari.2": "Aparece assim:",
    "app.index.webview.safari.3": "Isto vai abrir o inquérito no Safari, para que funcione devidamente.",
    "app.index.wifi": "Parece que a sua ligação à Internet é muito lenta. Está ligado(a) ao Wi-fi?",
    "app.index.tellmemore": "Diga-me mais.",
    "app.index.takepart": "Não desejo participar.",
    "app.index.webcamRetry": "Não conseguimos detetar uma webcam, que é necessária para este estudo. Conceda acesso à sua webcam e volte a tentar.",
    "app.index.adblockRetry": "Ups! Detetámos um bloqueador de anúncios. Para uma melhor experiência no estudo, desligue-o e volte a tentar.",
    "app.instructions.title": "Instruções",
    "app.instructions.instruction1": "Se for possível, evite usar óculos durante o inquérito.",
    "app.instructions.instruction2": "Sente-se confortavelmente e não mexa a cabeça nem o dispositivo.",
    "app.instructions.instruction3": "Mexa os olhos, não a cabeça.",
    "app.instructions.instruction4": "Pronto(a) para começar?",
    "app.instructions.instruction5": "Use o Wi-fi para evitar despesas com dados.",
    "app.instructions.instruction6": "Leia com atenção as seguintes instruções.",
    "app.instructions.instruction7": "Coloque o telemóvel numa superfície estável.",
    "app.instructions.instruction8": "Certifique-se de que a sala está bem iluminada - de preferência de cima (não por trás).",
    "app.setup.title": "Configuração",
    "app.setup.instruction0": "Mantenha o dispositivo na vertical.",
    "app.setup.instruction0a": "Rode o dispositivo para a vertical.",
    "app.setup.instruction0b": "Rode o dispositivo para a câmara ficar do lado esquerdo.",
    "app.setup.instruction0c": "Rode o dispositivo para a câmara ficar do lado direito.",
    "app.setup.instruction0d": "Rode o dispositivo para a horizontal.",
    "app.setup.instructioncamera": "Clique no botão abaixo para solicitar acesso à câmara. Para preencher o inquérito, precisa de conceder autorizações à sua câmara durante o inquérito.",
    "app.setup.cameraText": "Solicitar Acesso",
    "app.setup.instruction1": "A iniciar...",
    "app.setup.instruction2": "Quando for detetado(a), posicione a sua cabeça dentro do quadrado.",
    "app.setup.instruction3": "Mantenha a cabeça e o dispositivo nessa posição até terminar o inquérito.",
    "app.setup.instruction4": "Agora apenas precisamos de validar.",
    "app.setup.instruction5": "Obrigado(a)! Lembre-se de manter a cabeça e o dispositivo na mesma posição até terminar o inquérito.",
    "app.setup.instruction6": "Deslize para cima para ocultar a barra de endereços.",
    "app.setup.retry": "Erro detetado. Mantenha a cabeça e o dispositivo parados e não tape a câmara, por exemplo, com os dedos. Use o botão abaixo para tentar novamente.",
    "app.setup.retryPositioning": "Não conseguimos determinar a posição da sua cabeça. Mantenha a cabeça e o dispositivo parados e não tape a câmara, por exemplo, com os dedos. Use o botão abaixo para tentar novamente.",
    "app.setup.retryInit": "Erro detetado. Certifique-se de que o seu navegador consegue aceder à sua câmara. Se anteriormente rejeitou as autorizações de acesso à câmara, terá de aceitar isso nas configurações de privacidade do navegador. Use o botão abaixo para tentar novamente.",
    "app.setup.retryBroker": "Não conseguimos terminar o processo de calibração. Experimente encerrar as aplicações e os separadores que não estão em uso para libertar os recursos",
    "app.setup.retryButton": "Voltar a tentar",
    "app.setup.calibrationProp.watch": "Siga o ponto quando surgir no ecrã",
    "app.setup.calibrationProp.watch.george": "A seguir, quando vir uma cara, mantenha-se a olhar para ela enquanto copia os movimentos da cabeça",
    "app.setup.calibrationProp.click": "Clique em qualquer sítio para continuar",
    "app.setup.calibrationProp.tap": "Toque em qualquer sítio para continuar",
    "app.study.validationProp.watch": "Siga o ponto quando surgir no ecrã",
    "app.study.validationProp.restart": "Volte a tentar",
    "app.study.validationProp.click": "Clique em qualquer sítio para continuar",
    "app.study.validationProp.tap": "Toque em qualquer sítio para continuar",
    "app.study.render.error": "O domínio não é válido para realizar o passo.",
    "app.error.title": "Erro",
    "app.error.generic": "Ups! Algo correu mal.",
    "app.error.initialise": "Incapaz de iniciar!",
    "app.error.findCamera": "Incapaz de encontrar a câmara!",
    "app.error.headPosition": "Incapaz de determinar a posição da sua cabeça.",
    "app.error.calibrate": "Incapaz de calibrar.",
    "app.error.validate": "Incapaz de validar.",
    "app.error.start": "Não conseguiu iniciar.",
    "app.error.assign": "Não conseguiu atribuir.",
    "app.error.osnotallowed": "Sair do ecrã: O seu sistema operativo não é permitido. Verifique que dispositivo pode usar para este inquérito e volte a tentar.",
    "app.error.demoquotafullnoactivecell": "Quota preenchida: Obrigado pelo seu interesse. Este estudo está encerrado na sua zona geográfica.",
    "app.error.missingpanelparameter": "Falta de parâmetro no painel.",
    "app.invalid.title": "Inválido",
    "app.invalid.generic": "Lamentamos mas não é elegível para este estudo.",
    "app.completion.title": "Terminado",
    "app.completion.congratulations": "Parabéns! Terminou o estudo. O acesso deste inquérito à sua câmara vai ser terminado agora.",
    "app.completion.congratulations-noet": "Parabéns! Terminou o estudo.",
    "app.questions.required": "Esta pergunta é necessária.",
    "app.faq.question1": "P: O que é a Plataforma de Estudo da Lumen e o que envolve participar num inquérito com rastreio ocular?",
    "app.faq.answer1": "R: Este inquérito envolve o uso de tecnologia de rastreio ocular. Vai-lhe ser pedido que se sente em frente à webcam do seu dispositivo ou da sua câmara frontal e que execute uma calibração para que se possa calcular o sítio para onde está a olhar no seu ecrã. Durante o inquérito, vamos pedir-lhe que veja alguns itens (por exemplo, imagens, páginas da Internet ou vídeos) enquanto a ferramenta do inquérito calcula o sítio para onde está a olhar. Os dados recolhidos por este \"rastreio ocular\" são as coordenadas dos pontos de contemplação no seu ecrã, calculadas ao longo do tempo. O software usa imagens de si da sua webcam para fazer estas estimativas mas não tira fotografias nem grava vídeos de si. Também lhe podem ser feitas algumas perguntas sobre si, sobre a sua experiência e sobre os itens que viu durante o inquérito. Os dados são enviados para a Lumen Research Ltd. A Lumen Research analisará os dados e partilhará os resultados com os seus clientes.",
    "app.faq.question2": "P: Qual a razão para o software necessitar de uma webcam / câmara e como é que é usada?",
    "app.faq.answer2": "R: O software usa as imagens da sua webcam ou da câmara do seu dispositivo para calcular o sítio para onde está a olhar no ecrã. Faz isto usando um software combinado de rastreio facial e rastreio ocular que analisa imagens localmente no seu dispositivo em tempo real. Estas imagens são processadas e são-lhe apresentadas durante a calibração para que se possa posicionar corretamente, mas as imagens não são gravadas, protegendo a sua privacidade. Os dados do rastreio ocular que são registados e guardados não são imagens mas dados numéricos sobre os pontos de contemplação calculados sobre o estímulo ao longo do tempo.",
    "app.faq.question3": "P: Registam imagens de mim enquanto fazem o rastreio ocular?",
    "app.faq.answer3": "R: As únicas imagens temporais são usadas para lhe permitir posicionar-se corretamente durante a calibração. Estas imagens não são guardadas e são apenas vistas por si. Os dados do rastreio ocular que são registados e guardados não são imagens mas dados numéricos sobre os pontos de contemplação calculados sobre o estímulo ao longo do tempo.",
    "app.faq.question4": "P: Que dados são recolhidos durante o inquérito?",
    "app.faq.answer4.line1": "R: O software recolherá alguns ou todos dos seguintes cinco tipos de dados:",
    "app.faq.answer4.line2": "“Identificação do Utilizador” - A Identificação do Utilizador é usada para identificar os inquiridos (para efeitos de atribuição dos prémios de participação);",
    "app.faq.answer4.line3": "“Dados de contemplação” – Estimativas do sítio para onde os utilizadores estão a olhar no seu ecrã quando são exibidos os estímulos no ecrã",
    "app.faq.answer4.line4": "“Dados do inquérito” – Respostas às perguntas respondidas pelos utilizadores",
    "app.faq.answer4.line5": "“Metadados” - Dados sobre a altura em que as pessoas participaram com sucesso no Inquérito.",
    "app.faq.answer4.line6": "“Dados dos Dispositivos Físicos” – Dados sobre o computador em que o utilizador participa, como a quantidade de RAM, o tipo de CPU e o agente do utilizador (versão do sistema operativo e do navegador) bem como informação sobre o ecrã do computador (tamanho e resolução), webcam (marca e resolução) e programas antivírus.",
    "app.faq.question5": "P: Como serão usados os dados recolhidos?",
    "app.faq.answer5.line1": "R: Os dados recolhidos serão usados para explorar a forma como as pessoas veem e interagem com os vários géneros de imagens e meios visuais e para avaliar as opiniões das pessoas sobre as imagens exibidas.",
    "app.faq.answer5.line2": "A Lumen poderá partilhar os dados do inquérito com empresas afiliadas, com parceiros de negócio e com clientes. Apesar de os dados do inquérito poderem ser partilhados com afiliados, parceiros e clientes, tal será feito numa base de anonimato. Esses afiliados, parceiros e clientes poderão usar estes dados para criar descrições resumidas de uma vasta gama de comportamentos e de segmentos de consumidores para desenvolver produtos ou fornecer soluções de marketing aos seus clientes ou consumidores.",
    "app.faq.answer5.line3": "A Lumen recolhe “Dados dos Dispositivos Físicos” e “Metadados” para ajudar a gerir a recolha e o processamento dos dados, incluindo o controlo de qualidade e a gestão do volume.",
    "app.faq.answer5.line4": "Os dados recolhidos serão mantidos durante o período necessário para alcançar os objetivos descritos.",
    "app.faq.question6": "P: Qual o nível de segurança dos meus dados?",
    "app.faq.answer6": "R: Os dados recolhidos pela Lumen são encriptados usando a tecnologia SSL. Este é o padrão de tecnologia de encriptação mais comum e mais elevado e é usado pelos bancos para proteger as transações bancárias e outras comunicações altamente sensíveis. Também é usado pela Amazon, PayPal, eBay e outros portais que necessitam de sistemas de segurança de nível bancário. Os cientistas estimam que, para quebrar a encriptação de 256 bits usando a tecnologia atual, seria necessário substancialmente mais tempo do que a idade do universo. Os dados recolhidos são armazenados em servidores Web seguros, geridos pela Amazon Web Services (AWS). A AWS é uma das plataformas mais usadas, seguras e fiáveis de serviços de computação em nuvem e é usada pelos principais serviços comerciais baseados em nuvem, incluindo a Netflix e a Expedia.",
    "app.faq.question7": "P: Que obrigações tem em relação ao Conteúdo que lhe é mostrado?",
    "app.faq.answer7": "R: Ao participar, também compreende e concorda que o Conteúdo que lhe é mostrado neste estudo pode ser confidencial, propriedade de terceiros e/ou sujeito às leis de marca registada ou direitos de autor e que não efetuará capturas do ecrã, gravações nem copiar qualquer Conteúdo ou redistribuir qualquer Conteúdo do estudo por qualquer meio.",
    "app.faq.question8": "P: Não tenho a certeza da qualidade da minha webcam, irá funcionar com o rastreio ocular?",
    "app.faq.answer8": "R: O sistema funciona com uma vasta gama de webcams – não deve haver problemas.",
    "app.faq.question9": "P: Terminei o estudo, preciso de fazer mais alguma coisa?",
    "app.faq.answer9": "R: Não é necessário fazer mais nada. O software encerrará automaticamente.",
    "app.faq.question10": "P: Preciso de estar ligado à Internet para participar?",
    "app.faq.answer10": "R: Sim, vai precisar de uma ligação à Internet para descarregar o programa e concretizar a tarefa.",
    "app.faq.question11": "P: Preciso de concretizar a tarefa numa superfície estável?",
    "app.faq.answer11": "R: Sim, para obter os melhores resultados, deve sentar-se confortavelmente à frente do ecrã do seu computador e, depois da calibração, não mexer a cabeça enquanto lhe são apresentados os estímulos, até ser notificado(a). Aconselhamos a não participar se estiver num comboio ou a equilibrar o seu computador portátil no colo, etc.",
    "app.faq.question12": "P: As Perguntas Frequentes não resolveram o meu problema. O que faço agora?",
    "app.faq.answer12": "R: Envie-nos um email para study-feedback@lumen-research.com e responderemos o mais rápido possível.",
    "app.faq.patent": "Os testes de conteúdos Web da Lumen são efetuados com a nossa própria tecnologia em contexto (Número do Pedido de Patente no Reino Unido 1816158.8)",
    "app.terms.line1": "Estes termos de utilização (“Termos de Utilização”) regem o acordo entre Si e a Lumen Research Ltd. (“Lumen” ou “Nós”), uma empresa registada em Inglaterra com o número de registo empresarial 8682432, com sede em Unit 215, 22 Highbury Grove, London N5 2EF e com a referência de proteção de dados ZA130772, relativamente à sua utilização do software de rastreio ocular “ViewPoints” (o “Software”) no seu dispositivo.",
    "app.terms.line2": "1. Concordância com os termos",
    "app.terms.line3": "Ao usar o Software, confirma que concordou em ficar sujeito a este Acordo. Caso não concorde com os termos e condições deste Acordo, não termine o inquérito ViewPoints.",
    "app.terms.line4": "2. Informação Confidencial",
    "app.terms.line5": "Ao instalar, usar ou interagir de qualquer forma com o ViewPoints, pode ter acesso a informação e/ou conteúdo pertencente ou relacionado com a Lumen, incluindo informação que é identificada ou tratada pela Lumen ou pelos clientes da Lumen como confidencial ou que por razão do seu caráter, circunstâncias ou forma de divulgação seja evidentemente confidencial, incluindo (em detrimento das generalidades anteriores) qualquer informação sobre planos de negócios, novas oportunidades de negócio em maturação, projetos de pesquisa e desenvolvimento, designs, processos secretos, códigos, design de software, segredos comerciais, desenvolvimento e fórmulas de produtos ou serviços, know-how, invenções, estatísticas e previsões de vendas, estratégias e planos de marketing, custos, balanços e outra informação financeira (salvo o que for publicado em contabilidade auditada), estruturas de preços e de descontos (todos quer estejam ou não registados por escrito, em formato eletrónico ou noutro formato) (“Informação Confidencial”). Essa Informação Confidencial manter-se-á única e exclusivamente propriedade da Lumen e/ou dos seus clientes, e não obterá ou receberá em caso algum qualquer direito, título e/ou interesse sobre essa Informação Confidencial.",
    "app.terms.line6": "Não deve, quer durante a sua participação no ViewPoints ou em qualquer altura (sem limite) após terminar tal participação, divulgar ou comunicar a qualquer pessoa, usar para uso próprio ou por qualquer falha em exercer o devido cuidado, provocar qualquer divulgação não autorizada de qualquer Informação Confidencial. Tem sempre de exercer os melhores esforços para evitar a publicação ou a divulgação de qualquer Informação Confidencial. Estas restrições deixarão de se aplicar a qualquer informação que fique disponível ao público em geral, desde que a culpa não seja sua.",
    "app.terms.line7": "3. Propriedade da Lumen",
    "app.terms.line8": "O software ViewPoints e todos os dados, códigos, chaves, palavras-passe, designs, desenhos, registos e outros documentos ou materiais relacionados com o Software, serão e manter-se-ão propriedade da Lumen durante toda a duração da sua participação num inquérito da ViewPoints. A Lumen possui todos os direitos intelectuais ou proprietários e todos os direitos de propriedade intelectual ou industrial existentes ou futuros sobre quaisquer documentos, materiais, modelos, designs, desenhos, processos, invenções, fórmulas, códigos informáticos, dados, interfaces de sistema, metodologias, know-how, Informação Confidencial ou outros trabalhos desempenhados, fabricados, criados, concebidos, desenvolvidos, melhorados, desconstruídos ou descobertos por si, quer sozinho(a) ou na companhia de outra pessoa, ligados ou relacionados ao Software e/ou ao Dispositivo ou capaz de ser usado ou adaptado para ser usado ou em ligação a este (esteja ou não registado), incluindo todas as patentes existentes ou futuras, direitos de autor, direitos de design, direitos sobre as bases de dados, marcas registadas, direitos sobre a topografia dos semicondutores, direitos sobre as variedades das fábricas, direitos sobre os nomes / domínios na Internet, know-how e todos e quaisquer pedidos sobre os mencionados anteriormente e todos e quaisquer direitos serão aplicáveis a qualquer dos anteriores (“Direitos de Propriedade”). Nenhuns Direitos de Propriedade passarão para si em qualquer altura.",
    "app.terms.line9": "4. Termos e restrições na utilização",
    "app.terms.line10": "O software ViewPoints é licenciado aos utilizadores finais. Sujeito a este Acordo, concordamos em conceder-lhe, numa base limitada não exclusiva, revogável, não transferível e sem sublicenciamento o uso do software ViewPoints num dispositivo que tenha ou que controle ou num computador pessoal em relação ao qual representa e garante que tem todos os direitos e autorizações necessárias para essa instalação. Para evitar dúvidas, não pode usar o ViewPoints fora do âmbito a que se destina ou traduzir, copiar, reproduzir, transmitir por telecomunicações, adaptar, unir, alterar ou modificar o Software de forma alguma.",
    "app.terms.line11": "Concorda em não: (i) copiar, modificar, adaptar, disponibilizar, alugar, arrendar, timeshare, criar um trabalho derivado ou tentar vender ou transferir qualquer direito do Software por qualquer meio (ii) desmontar, descompilar ou usar engenharia reversa em qualquer parte do Software por qualquer meio; nem permitir ou ajudar terceiros a decifrar ou tentar decifrar o código de origem, desmontar, descodificar, descompilar ou usar engenharia reversa no Software; nem tomar outras medidas para decifrar informação de design do Software; (iii) usar o Software fora do âmbito para o qual lhe foi inicialmente distribuído; (iv) usar aranhas, robôs ou outras técnicas automatizadas de recolha de dados (mining) para catalogar, descarregar, armazenar ou reproduzir ou distribuir o Software por qualquer meio ou manipular os resultados de qualquer inquérito ou de outra atividade qualquer; (v) tomar qualquer medida para interferir com o Software ou com a utilização do Software por terceiros incluindo, sem limitações, por sobre carregamento, “flooding”, “mail bombing” ou “crashing” do Software e do sistema de armazenamento de dados que o Software usa através da Amazon Web Services (vi) enviar-nos qualquer código ilegal, enganador ou maligno, incluindo, sem limitações, qualquer vírus, spyware, adware ou qualquer outro código maligno.",
    "app.terms.line12": "Todos os direitos que não lhe sejam expressamente outorgados aqui são reservados a nós e aos nossos licenciantes. Concorda em como não temos nenhuma obrigação ao abrigo deste Acordo ou de outra forma de corrigir quaisquer erros ou defeitos no Software ou apoiar, manter, melhorar, modificar, atualizar ou otimizar o Software. Não seremos responsáveis por lhe prestar qualquer tipo de assistência, a si ou aos seus representantes, na utilização do Software.",
    "app.terms.line13": "Não somos responsáveis por quaisquer danos no seu computador incluindo, mas não se limitando, como resultado da sua utilização do Software. Não somos responsáveis por quaisquer despesas com dados incorridas por si e resultantes de dados recolhidos pelo Software devido à sua participação no Inquérito.",
    "app.terms.line14": "5. Renúncia",
    "app.terms.line15": "Nós e os nossos respetivos diretores, oficiais, colaboradores, agentes, representantes, licenciantes e fornecedores não representamos, garantimos nem aceitamos qualquer responsabilidade (a) que o software esteja disponível em tempo útil ou que o acesso ao software não seja interrompido ou seja seguro; (b) que os defeitos ou os erros sejam corrigidos; ou (c) que o software, os servidores ou as redes através dos quais o software e o dispositivo são disponibilizados sejam seguros e isentos de vírus ou de outros componentes malignos. Não teremos nenhuma obrigação de fornecer quaisquer serviços de apoio e/ou manutenção em relação ao software.",
    "app.terms.line16": "6. Limitação de responsabilidade",
    "app.terms.line17": "(a) À extensão máxima permitida ao abrigo da lei aplicável, concorda que nós e os nossos respetivos diretores, oficiais, colaboradores, agentes, representantes, licenciantes e fornecedores não terão qualquer responsabilidade sobre quaisquer perdas, danos ou despesas de qualquer género resultantes da sua instalação, uso ou acesso do software, a sua utilização ou a sua incapacidade em usar o software, quaisquer informações ou materiais disponibilizados no software, ou através do mesmo, e/ou de outra forma relacionada com o software ou de outra forma com este acordo, seja qual for a natureza do direito jurídico, equitativo ou estatutário que seja reivindicado como tendo sido violado.",
    "app.terms.line18": "(b) À extensão máxima permitida ao abrigo da lei aplicável, a sua única e exclusiva solução para o descontentamento com o software é deixar de usá-lo.",
    "app.terms.line19": "(c) Em qualquer caso, nenhuma das nossas entidades, diretores, oficiais, colaboradores, agentes, representantes serão responsáveis perante si por quaisquer danos inerentes, indiretos, consequentes, estatutários, exemplares, punitivos ou especiais, perda de rendimento, perda de oportunidade, perda de reputação, perda de utilização, perda de dados, perda de outros intangíveis, ou interceções não autorizadas por terceiros de dados recolhidos pelo software, fosse ou não previsível, seja qual for a forma que o provocou, e ao abrigo de qualquer contrato, dano (incluindo sem limitação de negligência), responsabilidade objetiva ou outra teoria, mesmo que anteriormente advertido sobre a possibilidade da ocorrência desses danos.",
    "demo.index.content.line1": "Durante esta demonstração vamos usar a sua câmara para calcular o sítio para onde está a olhar. Não se preocupe, não vamos tirar fotografias nem gravar vídeos.",
    "app.error.previousfail": "Já reprovou neste estudo anteriormente. Use a hiperligação original para começar do princípio.",
    "app.error.previoussuccess": "Já preencheu este estudo no passado.",
    "app.error.route": "Tentou iniciar um estudo que já está a decorrer. Use a hiperligação original para começar do princípio.",
    "app.error.accesscode": "Tentou iniciar um estudo que não existe. Verifique a sua hiperligação.",
    "app.error.webcam": "Este estudo necessita de acesso a uma webcam",
    "app.error.adblocker": "Este estudo precisa que o AdBlock esteja desativado.",
    "app.error.startsession": "Não foi possível iniciar a sessão",
    "app.error.fullscreen": "Este estudo precisa de uma vista de ecrã completo.",
    "app.error.zoom": "Este estudo precisa que o Zoom do seu navegador esteja configurado para 100%.",
    "app.index.webcamInstructions": "Estamos prestes a solicitar o acesso à sua webcam. Se recebeu um popup, clique em permitir.",
    "app.setup.retryBack": "Evite premir o botão de \"Anterior\" no seu navegador durante este estudo. Pode resultar na perda do prémio. Use o botão abaixo para tentar novamente."
  }