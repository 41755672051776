import config from "../config";
import PlatformError from "../PlatformError";

export default new PlatformError(
  "ERROR",
  config.defaultMessage,
  "ModelLoadError",
  "Failed to load model data",
  config.defaultMessageId
);
