import * as DEBUGLOG from "../../../components/util/debugLog";
import requestTimer from "../../requestTimer";
import tabChangeListener from "../../../utils/listeners/TabChangeListener";
import participationUrl from "../participationUrl";

const completeCalibration = async (studyId, body) => {
  tabChangeListener.setWaitingForApi("completeCalibration");
  let completePositionCalibrationRequestDate = DEBUGLOG.debugLogRequestStart(
    "complete-position-calibration"
  );
  let response = await fetch(
    participationUrl() +
      "/participation/studies/" +
      studyId +
      "/complete-position-calibration",
    {
      method: "POST",
      body: JSON.stringify(body),
    }
  );

  DEBUGLOG.debugLogRequestFinish(completePositionCalibrationRequestDate);

  if (response.ok) {
    let responseBody = await response.json();
    tabChangeListener.setWaitingForApi("");
    return responseBody;
  } else {
    let e = new Error(response.statusText);
    e.name = response.status || "";
    throw e;
  }
};

export default (...args) => requestTimer(() => completeCalibration(...args));
