{
  "app.thankyou": "Terima kasih",
  "app.button.faqs": "Soalan Lazim",
  "app.button.terms": "Syarat Penggunaan",
  "app.button.privacy": "Polisi Privasi",
  "app.button.feedback": "Maklum balas",
  "app.button.selectLanguage": "Pilih Bahasa",
  "app.button.help": "Bantuan",
  "app.button.start": "Mula",
  "app.button.next": "Seterusnya",
  "app.button.submit": "Hantar",
  "app.button.close": "Tutup",
  "app.button.return": "Kembali",
  "app.button.takepart": "OK - Saya sertai",
  "app.menu.feedback.content": "Jika anda mempunyai isu tentang kajian atau ingin meninggalkan komen, sila lakukannya disini.",
  "app.contactModal.feedback": "Terima kasih atas maklum balas anda!",
  "app.takePartModal.line1": "Jika anda berhenti sekarang, anda tidak layak untuk menerima sebarang ganjaran penyertaan.",
  "app.takePartModal.line2": "Anda hanya perlukan kamera web!",
  "app.takePartModal.reason1": "Saya tidak mempunyai kamera web.",
  "app.takePartModal.reason2": "Saya tidak selesa menggunakan kamera web untuk kajian ini.",
  "app.takePartModal.reason3": "Saya tidak mempunyai masa sekarang.",
  "app.takePartModal.reason4": "Sebab lain.",
  "app.generic.redirect": "Anda akan diarahkan tidak lama lagi.",
  "app.init.welcome": "Selamat datang ke platform kajian Lumen Research",
  "app.init.intro": "Platform pengkajian membolehkan kami mendaftar dan menyediakan pengguna untuk kajian iklan tertentu.",
  "app.init.message1": "Bergantung kepada jenis kajian, pengguna akan dibimbing menerusi beberapa siri tugasan.",
  "app.init.message2": "Tugas-tugas ini termasuk; menjalankan tinjauan, membaca satu set arahan, dan melaksanakan kalibrasi pengesanan mata",
  "app.init.message3": "Kini kami akan membimbing anda melalui demo kajian.",
  "app.init.message4": "Sepanjang kajian ini, kami mungkin mengaktifkan kamera web anda untuk melakukan pengesanan mata, tetapi tiada sebarang data anda akan dirakam.",
  "app.init.message5": "Kajian semasa dirangka untuk menggunakan pautan berikut pada skrin keluar/penyelesaian.",
  "app.prestart.loading": "Memuatkan, sila tunggu.",
  "app.index.title": "Selamat datang",
  "app.index.content.line1": "Semasa kajian ini, kami akan menggunakan kamera anda untuk menganggar arah mata anda melihat. Jangan risau, kami tidak akan mengambil sebarang foto atau video.",
  "app.index.content.line1gpdrwarning": "Semasa kajian ini, kami akan menggunakan kamera hadapan pada peranti anda untuk mengambil rakaman imej dan video anda semasa anda melakukan pelbagai latihan yang melibatkan melihat pada ransangan.",
  "app.index.content.line1alt": "Sila pastikan anda duduk dalam keadaan selesa dan klik mula untuk bermula.",
  "app.index.content.line2": "Adakah peranti anda mempunyai kamera? Jika ya, sila tekan butang mula dibawah.",
  "app.index.rejection": "Maaf, nampaknya anda telah mengikuti pautan yang salah.",
  "app.index.webview": "Maaf, nampaknya anda telah mengakses halaman kami melalui paparan web. Sila buka pautan ini di pelayar anda. Ini boleh juga dilakukan melalui menu di paparan web.",
  "app.index.webview.chrome.1": "Klik pada butang menu di atas, kemudian pilih 'Buka di Chrome'.",
  "app.index.webview.chrome.2": "Ia akan membuka kajian ini di dalam aplikasi pelayar, supaya ia dapat berfungsi dengan baik.",
  "app.index.webview.android.1": "Klik Mula di bawah untuk membuka kajian ini di aplikasi pelayar, supaya ia dapat berfungsi dengan baik.",
  "app.index.webview.android.2": "Jika tidak berhasil, buka pautan ke halaman ini di aplikasi pelayar secara manual (contohnya, salin dan tampal pautan ke bar alamat di Chrome atau menggunakan pilihan 'Buka di Chrome' di bar menu di atas.",
  "app.index.webview.safari.1": "Klik ikon Safari di bahagian bawah skrin untuk meneruskan.",
  "app.index.webview.safari.2": "Ia kelihatan seperti ini:",
  "app.index.webview.safari.3": "Ini akan membuka kajian di dalam Safari supaya ia dapat berfungsi dengan baik.",
  "app.index.wifi": "Nampaknya capaian internet anda terlalu perlahan. Adakah anda sedang disambungkan dengan wifi?",
  "app.index.tellmemore": "Ceritakan lebih lanjut.",
  "app.index.takepart": "Saya tidak mahu menyertai.",
  "app.index.webcamRetry": "Kami tidak dapat mengesan kamera web, yang diperlukan untuk kajian ini. Sila sambungkan akses pada kamera web anda dan cuba sekali lagi.",
  "app.index.adblockRetry": "Oops! Kami mengesan penyekat iklan. Untuk pengalaman kajian yang terbaik, sila nyahaktifkan fungsi ini dan cuba semula.",
  "app.index.zoomRetry": "Kami memerlukan pelayar ditetapkan pada zum 100%, sila zum masuk/keluar dan cuba lagi.",
  "app.index.fullscreendesktopretry": "Kajian ini memerlukan paparan penuh, sila pastikan tetingkap pelayar anda dimaksimakan",
  "app.index.fullscreendesktoptest": "Kajian ini memerlukan tetingkap pelayar anda pada paparan penuh, kami perlu menjalankan ujian untuk memastikan tetingkap pelayar anda telah dimaksimakan",
  "app.index.fullscreendesktoptest2": "Dua butang akan muncul sebentar lagi, sila klik pada butang untuk meneruskan",
  "app.index.fullscreendesktopmaximised": "Skrin saya telah berada pada tahap maksima",
  "app.index.fullscreendesktopskip": "Terima kasih, kami telah merekod kejadian ini dan akan memperbaiki proses pengesanan kami.",
  "app.index.fullscreendesktopreset": "Kami telah mengesan perubahan pada saiz tetingkap, ujian ditetapkan semula.",
  "app.index.gotomobile": "Pengguna desktop tidak layak untuk kajian ini, sila imbas kod QR di bawah untuk membuka kajian pada telefon bimbit.",
  "app.index.returntopanel": "Atau, klik pada butang di bawah untuk kembali ke panel",
  "app.instructions.title": "Arahan",
  "app.instructions.instruction1": "Jika praktikal, jangan gunakan cermin mata semasa kajian.",
  "app.instructions.instruction2": "Sila duduk supaya anda dapat mengekalkan kedudukan kepala dan peranti anda dengan selesa.",
  "app.instructions.instruction3": "Gerakkan mata anda, bukan kepala anda.",
  "app.instructions.instruction4": "Sedia untuk mula?",
  "app.instructions.instruction5": "Sila gunakan wifi untuk mengelakkan caj data.",
  "app.instructions.instruction6": "Sila baca arahan selanjutnya dengan teliti.",
  "app.instructions.instruction7": "Letakkan telefon anda pada permukaan yang rata.",
  "app.instructions.instruction8": "Sila pastikan bilik anda mempunyai cahaya mencukupi - sebaiknya dari atas (bukan dari belakang).",
  "app.setup.title": "Tetapan",
  "app.setup.instruction0": "Pastikan peranti anda berada di dalam mod potret.",
  "app.setup.instruction0a": "Sila putar peranti anda ke orientasi potret.",
  "app.setup.instruction0b": "Sila putar peranti anda supaya kamera berada di bahagian kiri.",
  "app.setup.instruction0c": "Sila putar peranti anda supaya kamera berada di bahagian kanan.",
  "app.setup.instruction0d": "Sila putar peranti anda ke mod landskap.",
  "app.setup.instructioncamera": "Sila klik butang di bawah untuk meminta akses kamera. Untuk melengkapkan kajian ini, anda perlu membenarkan akses pada kamera sepanjang kajian dilakukan.",
  "app.setup.cameraText": "Meminta Akses",
  "app.setup.instruction1": "Memulakan...",
  "app.setup.instruction2": "Apabila anda dikesan, posisikan kepala anda di dalam kotak.",
  "app.setup.instruction3": "Pastikan kepala dan peranti anda berada pada kedudukan yang betul sehingga kajian selesai.",
  "app.setup.instruction3audio": "Sila dengar dengan teliti dan ikuti arahan yang tertera pada skrin.",
  "app.setup.instruction4": "Sekarang kami hanya perlu mengesahkan.",
  "app.setup.instruction5": "Terima kasih! Sentiasa pastikan kepala dan peranti anda berada di posisi yang sama sehingga kajian selesai.",
  "app.setup.instruction6": "Sila geser ke atas untuk menyembunyikan bar alamat.",
  "app.setup.retry": "Ralat dikesan. Sila pastikan kepala dan peranti anda tidak bergerak dan jangan menutup kamera seperti menggunakan jari. Gunakan butang di bawah untuk mencuba semula.",
  "app.setup.retryPositioning": "Kami tidak dapat mengesan kedudukan kepala anda. Sila pastikan kepala dan peranti anda tidak bergerak dan jangan menutup kamera seperti menggunakan jari. Gunakan butang di bawah untuk mencuba semula.",
  "app.setup.retryInit": "Ralat dikesan. Sila pastikan pelayar dapat mengakses kamera anda. Jika anda telah membatalkan akses kamera sebelum ini, anda perlu membenarkannya di tetapan privasi pada pelayar. Gunakan butang di bawah untuk mencuba semula.",
  "app.setup.retryBroker": "Kami tidak dapat menyelesaikan proses kalibrasi. Sila tutup sebarang aplikasi dan tab yang tidak digunakan untuk mengosongkan sumber",
  "app.setup.retryButton": "Cuba lagi",
  "app.setup.calibrationProp.watch": "Ikuti titik apabila ia muncul di skrin",
  "app.setup.calibrationProp.watch.george": "Seterusnya, apabila anda melihat wajah, sila perhatikan sambil mengikut pergerakan kepala yang dilakukannya ",
  "app.setup.calibrationProp.click": "Klik di mana-mana untuk meneruskan",
  "app.setup.calibrationProp.tap": "Ketuk di mana-mana untuk meneruskan",
  "app.study.validationProp.watch": "Ikut titik apabila ia muncul di skrin",
  "app.study.validationProp.restart": "Sila cuba semula",
  "app.study.validationProp.click": "Klik di mana-mana untuk meneruskan",
  "app.study.validationProp.tap": "Ketuk di mana-mana untuk meneruskan",
  "app.study.render.error": "Domain tidak sah untuk langkah paparan.",
  "app.error.title": "Ralat",
  "app.error.generic": "Oops! Ada yang tidak kena.",
  "app.error.initialise": "Tidak dapat dimulakan!",
  "app.error.findCamera": "Tidak dapat mengesan kamera!",
  "app.error.headPosition": "Tidak dapat mengesan posisi kepala anda.",
  "app.error.calibrate": "Tidak dapat mengkalibrasi.",
  "app.error.validate": "Tidak dapat mengesahkan.",
  "app.error.start": "Gagal untuk memulakan.",
  "app.error.assign": "Gagal untuk memilih.",
  "app.error.osnotallowed": "Tidak layak: OS anda tidak dibenarkan, sila semak peranti yang anda boleh gunakan untuk kajian ini dan cuba semula.",
  "app.error.demoquotafullnoactivecell": "Kuota Penuh: Terima kasih atas minat anda. Kajian ini ditutup untuk lokasi anda.",
  "app.error.cellquotafullnoactivecell": "Tiada Sel yang aktif untuk kajian ini. Kuota Sel telah penuh.",
  "app.error.missingpanelparameter": "Parameter panel tidak ada.",
  "app.invalid.title": "Tidak sah",
  "app.invalid.generic": "Maaf, anda tidak layak untuk kajian ini.",
  "app.completion.title": "Siap",
  "app.completion.congratulations": "Tahniah! Anda telah melengkapkan kajian. Akses kamera untuk kajian ini akan dinyahaktifkan.",
  "app.completion.congratulations-noet": "Tahniah! Anda telah melengkapkan kajian.",
  "app.questions.required": "Soalan ini diperlukan.",
  "app.faq.gdpr.intro1": "Dalam kajian ini, kami akan menggunakan kamera hadapan pada telefon bimbit untuk mengambil gambar dan rakaman video pengguna sambil mereka perlu melakukan pelbagai latihan melibatkan penglihatan pada ransangan dengan pelbagai keadaan (contoh, cahaya dan kedudukan). Penglibatan dianggarkan berlangsung selama 5 minit. Maklumat ini akan digunakan untuk membangunkan perisian secara berterusan yang dapat menganggar arah pengguna melihat pada skrin.",
  "app.faq.gdpr.intro2": "Kajian ini dijalankan mengikut Kod Etika MRS dan Akta Perlindungan Data 2018. Privasi anda adalah penting bagi kami. Sebelum menyertai kajian ini, anda perlu mengesahkan bahawa anda bersetuju apabila anda menyertai kajian ini, anda memahami data yang kami kumpulkan dan bagaimana kami akan menggunakannya, seperti yang tertera di Polisi Privasi ini, dan memberi kebenaran untuk menggunakan data dengan cara ini. Polisi ini menerangkan lebih lanjut apakah data yang akan dikumpulkan dan bagaimana cara data itu dikongsi dan digunakan.",
  "app.faq.gdpr.question1": "Apakah data yang akan dikumpulkan?",
  "app.faq.gdpr.answer1.line1": "Kajian ini akan melibatkan pengumpulan data yang berikut:",
  "app.faq.gdpr.answer1.line2": "“Ciri-ciri Sesi” – ID tanpa nama yang digunakan untuk mengenalpasti pengumpulan data tertentu 'Sesi' dan data mengenai keadaan persekitaran semasa sesi kajian.",
  "app.faq.gdpr.answer1.line3": "“Ciri-ciri peserta” – Data mengenai seorang responden yang mengambil bahagian dalam satu 'Sesi', termasuk data tentang demografi peserta (contohnya umur, jantina), etnik (contohnya warna kulit) dan atribut biologi (contohnya warna mata).",
  "app.faq.gdpr.answer1.line4": "“Fail video” – Gambar dan/atau rakaman video yang diambil menggunakan kamera hadapan peranti untuk mengambil imej peserta sambil mereka melihat dan mungkin berinteraksi dengan ransangan yang dipaparkan pada skrin. Imej diambil pada mata, muka atau sebarang reaksi peserta yang dapat dikesan secara tidak sengaja oleh kamera hadapan semasa melakukan tugasan. ",
  "app.faq.gdpr.answer1.line5": "“Metadata” - Data mengenai masa setiap individu berinteraksi dengan ransangan, dipadankan dengan julat masa setiap imej yang dikumpulkan.",
  "app.faq.gdpr.answer1.line6": "“Data peranti fizikal” - Data mengenai fizikal peranti, termasuk nama peranti, saiz skrin, putaran skrin dan sensor putaran kamera.",
  "app.faq.gdpr.answer1.line7": "Dengan menyertai kajian ini, anda memahami dan bersetuju bahawa Kandungan yang ditunjukkan kepada anda dalam kajian ini mungkin bersifat sulit, milik pihak ketiga dan/atau tertakluk kepada undang-undang hak cipta atau cap dagang, dan anda tidak akan mengambil tangkapan skrin, merakam atau menyalin sebarang Kandungan atau mengedarkan sebarang Kandungan kajian ini dalam apa jua cara.",
  "app.faq.gdpr.question2": "Bagaimanakah cara data ini digunakan/diproses?",
  "app.faq.gdpr.answer2": "Data akan digunakan untuk pembangunan perisian yang dapat menganggarkan sama ada pengguna berada di hadapan peranti, sama ada mereka melihat ke arah ransangan, dan ke arah manakah mereka melihat pada ransangan. Ia juga digunakan untuk tujuan demonstrasi atau tujuan memberi penerangan, atau membantu menilai bagi pihak ketiga (klien, pembekal,rakan kongsi) bagaimana teknologi kami dibangunkan dan prestasinya. Data ini mungkin disemak oleh sejumlah 'pengannotasi' data-pekerja atau subkontraktor atau rakan kongsi atau mana-mana pihak ketiga yang akan diminta untuk menonton semula rakaman video/gambar untuk menilai 'keadaan' fokus peserta semasa kajian (contohnya, adakah mereka berada di ruang pandangan kamera? adakah mereka melihat ke arah skrin?) Undang-undang asas untuk memproses data ini adalah berdasarkan persetujuan yang diberikan dan untuk tujuan yang sah. ",
  "app.faq.gdpr.question3": "Berapa lamakah data ini akan disimpan?",
  "app.faq.gdpr.answer3": "Data ini akan disimpan oleh kami untuk digunakan selama mana yang dianggap perlu untuk tujuan yang dinyatakan di atas. Perisian yang sedang dibangunkan akan dikemas kini dan dinilai secara berkala dan data ini akan digunakan untuk menilai setiap iterasi, jadi ia dijangka disimpan untuk tujuan ini selama beberapa tahun, atau sebaliknya. Data ini akan dipadam sekiranya ianya dianggap tidak relevan untuk kegunaan pembangunan atau penilaian perisian ujian ransangan. ",
  "app.faq.gdpr.question4": "Siapa yang akan menerima dan memproses data ini?",
  "app.faq.gdpr.answer4": "Data ini akan diterima dan disimpan oleh Lumen Research Ltd. Lumen Research Ltd adalah sebuah syarikat yang didaftarkan di England, nombor syarikat 8682432. Pejabat yang didaftarkan beralamat di LABS house, 15-19 Bloomsbury Way, Holborn, London WC1A 2TH. Rujukan pendaftaran perlindungan datanya ialah ZA130772. Pegawai Perlindungan Datanya adalah Matt Robinson (matt.robinson@lumen-research.com). Anda mempunyai hak untuk membuat aduan berkenaan pengurusan dan pemprosesan data anda pada bila-bila masa dengan menghubungi Pihak Berkuasa Perlindungan Data UK, Pejabat Persuruhjaya Maklumat. Butiran perhubungan ICO boleh didapati di laman web mereka iaitu https://ico.org.uk/global/contact-us/. Dengan menyertai kajian ini, anda memberikan Lumen kebenaran secara global, tanpa royalti, boleh dilesen semula, dan boleh dipindahkan untuk menghos, menyimpan, menggunakan, memaparkan, menghasilkan semula, mengubah, mengadaptasi, menyunting, menerbitkan, dan mengedarkan data ini.",
  "app.faq.gdpr.question5": "Adakah saya mempunyai hak untuk mengakses data peribadi saya?",
  "app.faq.gdpr.answer5": "Ya, anda mempunyai hak untuk mengakses data peribadi anda, walaubagaimanapun adalah penting untuk mengetahui bahawa nama anda tidak akan disimpan bersama data yang dikumpul semasa latihan ini. Untuk mengenalpasti rekod data anda, anda perlu memberikan kepada kami masa dan tarikh yang tepat semasa anda menjalani kajian ini, dan kami akan meminta pengesahan visual anda untuk memastikan rekod data adalah sepadan.",
  "app.faq.gdpr.question7": "Adakah saya mempunyai hak untuk menarik semula kebenaran untuk memproses data peribadi saya, atau untuk memadam data peribadi saya?",
  "app.faq.gdpr.answer7": "Ya, anda mempunyai hak untuk menarik semula kebenaran untuk memproses data peribadi anda, dan memadam data ini. Walaubagaimanapun, adalah penting untuk mengetahui bahawa nama anda tidak akan disimpan bersama data yang dikumpul semasa latihan ini. Untuk mengenalpasti rekod data anda, anda perlu memberikan kepada kami masa dan tarikh yang tepat semasa anda menjalani kajian ini, dan kami akan meminta pengesahan visual untuk memastikan rekod data adalah sepadan.  ",
  "app.faq.gdpr.question8": "Bagaimanakah untuk mengetahui data peribadi saya adalah selamat?",
  "app.faq.gdpr.answer8": "Data yang dikumpul oleh Lumen Research disulitkan menggunakan teknologi Secure Sockets Layer (SSL). Ini selalu digunakan untuk teknologi sulit tahap tinggi. Teknologi ini digunakan untuk melindungi saluran pemindahan serta penyimpanan akhir.",
  "app.faq.gdpr.question9": "Bagaimana kami akan menggunakan dan mendedahkan maklumat yang kami kumpul?",
  "app.faq.gdpr.answer9": "Kami mungkin akan mendedahkan maklumat yang kami kumpul kepada penyedia perkhidmatan pihak ketiga lain untuk membolehkan mereka memberi perkhidmatan kepada kami. Kami juga mungkin mendedahkan maklumat kepada pihak ketiga bagi keadaan penyusunan semula, penggabungan, penjualan, usaha sama, pemindahan atau pengeluaran lain bagi semua atau sebahagian dari perniagaan, aset, atau saham kami (termasuk berkaitan dengan proses bankrap atau serupa dengannya). Kami juga mungkin menggunakan dan mendedahkan maklumat yang dikumpul dari dan mengenai anda yang dirasakan perlu dan sesuai: (a) dibawah undang-undang yang berkenaan, termasuk undang-undang luar dari negara anda; (b) untuk mematuhi proses undang-undang; (c) untuk menjawab kepada permintaan dari pihak berkuasa awam atau kerajaan, termasuk pihak berkuasa awam dan tempatan di luar dari negara anda; (d) untuk menguatkuasakan lesen pengguna kami; (e) untuk melindungi operasi kami atau mana-mana afiliasi kami; (f) untuk melindungi hak, privasi, keselamatan, atau harta kami, dan/atau afiliasi kami, anda, atau pihak lain; (g) untuk membolehkan kami mengejar remedi yang ada atau menghadkan kerugian yang mungkin kami alami; dan (h) untuk mengesan dan menyelesaikan sebarang isu penipuan atau keselamatan. ",
  "app.faq.gdpr.question10": "Apakah langkah yang diambil untuk menyimpan data dengan selamat?",
  "app.faq.gdpr.answer10": "Kami mengambil langkah yang munasabah untuk melindungi maklumat anda menggunakan langkah keselamatan fizikal, elektronik, atau prosedur yang sesuai dengan tahap kesulitan maklumat dibawah jagaan atau kawalan kami, termasuk langkah-langkah perlindungan terhadap kehilangan dan kecurian, serta akses, pendedahan, penyalinan, penggunaan dan pengubahsuaian yang tidak sah. ",
  "app.faq.question1": "Q: Apakah itu Lumen Study Platform dan apakah kaitan menyertai kajian pengesanan mata?",
  "app.faq.answer1": "A:Kajian ini melibatkan penggunaan teknologi pengesanan mata. Anda akan diminta untuk duduk di hadapan kamera web atau kamera hadapan peranti anda dan melakukan kalibrasi supaya anggaran dapat dibuat untuk melihat arah anda lihat pada skrin. Semasa kajian, anda akan diminta untuk melihat beberapa item (contohnya imej, laman web atau video) sementara alat kajian menganggarkan arah anda melihat. Data yang diambil dari 'pengesanan mata' ini adalah koordinat titik pandangan yang dianggarkan pada skrin dari masa ke semasa. Perisian menggunakan gambar anda dari kamera web untuk membuat anggaran ini tetapi tidak merakam gambar atau video anda. Anda juga mungkin ditanya beberapa soalan tentang diri anda, pengalaman anda dan item yang anda lihat semasa kajian. Data akan dihantar pada Lumen Research Ltd. Lumen Research akan menganalisis data dan berkongsi keputusan dengan pelanggannya.",
  "app.faq.question2": "Q: Mengapa perisian memerlukan kamera web/kamera dan bagaimanakah ianya digunakan?",
  "app.faq.answer2": "A: Perisian ini menggunakan gambar dari kamera web atau kamera peranti anda untuk menganggar arah yang anda lihat pada skrin. Ia melakukan ini menggunakan kombinasi perisian pengesanan wajah dan pengesanan mata yang menganalisis gambar terus di mesin anda dan pada masa yang sebenar. Gambar-gambar ini diproses dan ditunjukkan pada anda semasa kalibrasi supaya anda dapat memperbetulkan posisi anda tetapi gambar anda tidak dirakam untuk melindungi privasi anda. Data yang direkod dan diambil dari pengesanan mata bukanlah gambar tetapi data numerik tentang titik pandangan yang dianggarkan pada ransangan dari masa ke semasa.",
  "app.faq.question3": "Q: Adakah anda mengambil gambar saya semasa melakukan pengesanan mata?",
  "app.faq.answer3": "A: Satu-satunya masa yang menggunakan gambar adalah untuk membolehkan anda membetulkan posisi anda semasa kalibrasi. Gambar-gambar ini tidak akan disimpan, dan hanya dapat dilihat oleh anda. Data yang dirakam dan dikumpul dari pengesanan mata bukanlah berbentuk gambar tetapi data numerik tentang titik pandangan yang dianggarkan pada ransangan dari masa ke semasa.",
  "app.faq.question4": "Q: Apakah data yang dikumpul semasa kajian?",
  "app.faq.answer4.line1": "A: Perisian akan mengumpul sebahagian atau kesemua data di bawah:",
  "app.faq.answer4.line2": "“ID Pengguna” - ID pengguna digunakan untuk mengenalpasti identiti responden (untuk tujuan pengurusan ganjaran penyertaan);",
  "app.faq.answer4.line3": "“Data Pandangan” – Anggaran arah mata pengguna melihat pada skrin mereka apabila ransangan ditunjuk pada skrin",
  "app.faq.answer4.line4": "“Data Kajian” – Respon kepada soalan yang dijawab oleh pengguna",
  "app.faq.answer4.line5": "“Metadata” - Data mengenai masa di mana peserta berjaya menyertai Kajian ini.",
  "app.faq.answer4.line6": "“Data fizikal peranti” – Data mengenai komputer yang digunakan oleh peserta seperti jenis RAM, jenis CPU dan ejen pengguna (sistem operasi dan versi pelayar) termasuk juga maklumat mengenai skrin komputer (saiz dan resolusi), kamera web (resolusi), dan program antivirus.",
  "app.faq.question5": "Q: Bagamainakah data akan digunakan?",
  "app.faq.answer5.line1": "A: Data yang dikumpul akan digunakan untuk meneroka bagaimana individu melihat dan berinteraksi dengan pelbagai jenis imej dan media visual, serta untuk mengukur pendapat orang tentang imej yang ditunjukkan kepada mereka.",
  "app.faq.answer5.line2": "Lumen mungkin berkongsi data kajian dengan syarikat afiliasi dan rakan kongsi perniagaan serta pelanggan. Walaupun data kajian mungkin dikongsi dengan afiliasi, rakan kongsi dan pelanggan, ini kebiasaannya akan dilakukan secara anonim. Afiliasi, rakan kongsi dan pelanggan mungkin menggunakan data ini untuk membuat ringkasan tentang tingkah laku pengguna yang pelbagai dan segmen pengguna untuk membangunkan produk atau untuk menyediakan penyelesaian pemasaran kepada pelanggan atau pengguna mereka.",
  "app.faq.answer5.line3": "Lumen mengumpul 'Data fizikal peranti' dan 'Metadata' untuk membantu menguruskan pengumpulan dan pemprosesan data, termasuk kawalan kualiti dan pengurusan jumlah.",
  "app.faq.answer5.line4": "Data yang dikumpulkan akan disimpan untuk tempoh masa yang diperlukan bagi memenuhi tujuan yang digariskan.",
  "app.faq.question6": "Q: Sejauh manakah selamat data saya?",
  "app.faq.answer6": "A: Data yang diperoleh oleh Lumen disulitkan menggunakan teknologi SSL. Ini biasa digunakan untuk teknologi penyulitan tahap tinggi dan digunakan oleh bank untuk melindungi transaksi perbankan dan komunikasi lain yang mempunyai sensitiviti tinggi. Ia juga digunakan oleh Amazon, PayPal, eBay, dan laman web lain yang memerlukan sistem keselamatan setaraf bank. Para saintis menganggarkan bahawa untuk memecahkan penyulitan 256 bit menggunakan teknologi semasa akan memerlukan lebih banyak masa daripada usia alam semesta. Data yang dikumpul disimpan di pelayan web yang selamat yang diurskan oleh Amazon Web Services (AWS). AWS adalah salah satu platform perkhidmatan pengkomputeran awan yang paling biasa digunakan dan boleh dipercayai, serta digunakan oleh perkhidmatan komersial awan utama termasuk Netflix dan Expedia.",
  "app.faq.question7": "Q: Apakah peraturan yang anda ada berkenaan Kandungan yang anda paparkan?",
  "app.faq.answer7": "A: Dengan menyertai, anda juga memahami dan bersetuju bahawa Kandungan yang ditunjukkan kepada anda di dalam kajian ini mungkin bersifat sulit, milik pihak ketiga dan/atau tertakluk kepada undang-undang hak cipta dan cap dagang, dan bahawa anda tidak akan mengambil tangkapan skrin, merakam atau dengan cara lain menyalin mana-mana Kandungan atau mengedarkan semula mana-mana Kandungan dalam kajian dengan apa cara sekalipun.",
  "app.faq.question8": "Q: Saya tidak pasti kualiti kamera web saya, adakah ia boleh digunakan untuk pengesanan mata?",
  "app.faq.answer8": "A: Sistem ini boleh beroperasi dengan sejumlah besar kamera web - pasti tiada masalah.",
  "app.faq.question9": "Q: Saya telah menyelesaikan kajian, perlukah saya melakukan hal lain?",
  "app.faq.answer9": "A: Tidak, anda tidak perlu melakukan perkara lain. Perisian akan ditutup secara automatik.",
  "app.faq.question10": "Q: Adakah saya perlu disambungkan dengan internet untuk menyertai?",
  "app.faq.answer10": "A: Ya, anda memerlukan sambungan internet untuk memuat turun program dan menyelesaikan latihan.",
  "app.faq.question11": "Q: Adakah saya perlu melengkapkan tugasan ini pada permukaan yang rata?",
  "app.faq.answer11": "A: Ya, untuk keputusan yang terbaik, anda perlu duduk dengan selesa di hadapan skrin komputer anda dan selepas kalibrasi, sila pastikan kepala anda tidak bergerak sementara anda dipaparkan dengan ransangan, sehingga diberitahu. Kami mencadangkan anda untuk tidak sertai sekiranya anda berada di dalam keretapi atau komputer riba anda berada di atas pangkuan yang memerlukan keseimbangan dan sebagainya.",
  "app.faq.question12": "Q: Soalan lazim tidak menyelesaikan masalah saya, apakah yang perlu saya lakukan sekarang?",
  "app.faq.answer12": "A: Sila emel kepada kami di study-feedback@lumen-research.com dan kami akan cuba memberi respon kepada anda secepat yang mungkin.",
  "app.faq.patent": "Ujian kandungan web Lumen dikuasakan oleh teknologi ujian milik kami sendiri  (Nombor Permohonan Paten UK 1816158.8)",
  "app.terms.line1": "Terma penggunaan ini ('Terma Penggunaan') mengawal perjanjian antara Anda dan Lumen Research Ltd ('Lumen' atau 'Kami'), sebuah syarikat berdaftar di England, nombor syarikat 8682432 dengan pejabat yang berdaftar di Unit 215, 22 Highbury Grove, London N5 2EF dan rujukan pendaftaran perlindungan data ZA130772, berkenaan penggunaan anda terhadap perisian pengesanan mata 'ViewPoints' ('Perisian') pada peranti anda.",
  "app.terms.line2": "1. Persetujuan terhadap terma",
  "app.terms.line3": "Dengan menggunakan perisian ini, anda mengakui bahawa anda telah bersetuju untuk terikat oleh Perjanjian ini. Jika anda tidak bersetuju dengan terma dan syarat Perjanjian ini, jangan lengkapkan kajian ViewPoint.",
  "app.terms.line4": "2. Maklumat Sulit",
  "app.terms.line5": "Semasa memasang, menggunakan atau dalam apa cara terlibat dengan ViewPoints, anda mungkin mempunyai akses kepada maklumat dan/atau kandungan kepunyaan atau berkaitan dengan Lumen termasuk maklumat yang berkaitan atau diiktiraf oleh Lumen atau mana-mana pelanggan Lumen sebagai sulit atau yang disebabkan oleh sifatnya atau keadaan atau cara pendedahannya jelas bersifat sulit. Ini termasuk (tanpa menjejaskan generaliti di atas) maklumat tentang rancangan perniagaan, peluang perniagaan baru yang sedang berkembang, projek penyelidikan dan pembangunan, reka bentuk, proses rahsia, kod, reka bentuk perisian, rahsia perdagangan, pembangunan produk atau perkhidmatan dan formula, kepakaran, ciptaan, statistik dan ramalan jualan, strategi dan rancangan pemasaran, kos, untung dan rugi serta maklumat kewangan lain (kecuali yang disimpan di dalam terbitan akaun audit), harga dan struktur diskaun (semua sama ada direkodkan secara bertulis atau dalam format elektronik atau format lain) ('Maklumat Sulit'). Maklumat sulit tersebut akan kekal menjadi harta eksklusif Lumen dan/atau pelanggannya, dan dalam apa jua keadaan, anda tidak akan memperoleh atau menerima sebarang hak, pemilikan dan/atau kepentingan terhadap Maklumat Sulit tersebut.",
  "app.terms.line6": "Anda tidak boleh, sama ada semasa penyertaan anda di ViewPoints atau pada bila-bila masa (tanpa had) selepas penamatan penyertaan tersebut: membocorkan atau berkomunikasi kepada mana-mana individu; menggunakan untuk kepentingan sendiri; atau melalui sebarang kegagalan untuk bertanggungjawab dan berhati-hati, yang menyebabkan pendedahan tidak sah terhadap mana-mana Maklumat Sulit. Anda mesti sentiasa memastikan bahawa anda berusaha untuk mengelakkan penerbitan atau pendedahan mana-mana Maklumat Sulit. Sekatan ini tidak sah terhadap mana-mana maklumat sekiranya maklumat tersebut tersedia kepada umum dengan cara lain selain dari kecuaian dan kesilapan anda.",
  "app.terms.line7": "3. Harta Lumen",
  "app.terms.line8": "Perisian ViewPoints dan semua data, kod, kunci, kata laluan, reka bentuk, lukisan, rekod dan dokumen atau bahan lain berkenaan Perisian akan menjadi harta mutlak Lumen sepanjang tempoh penyertaan anda dalam kajian ViewPoints. Lumen memiliki semua hak intelektual atau hak milik dalam semua hak harta intelek atau industri sedia ada atau yang akan datang berkenaan dengan mana-mana dokumen, bahan, model, reka bentuk, lukisan, proses, ciptaan, formula, pengkodan komputer, data, antara muka sistem, metodologi, kepakaran, Maklumat Sulit atau kerja lain yang dilakukan, dibuat, dicipta, dirancang, dibangunkan, ditambah baik, diuraikan atau ditemui oleh anda sama ada secara sendirian atau dengan mana-mana orang lain berkaitan dengan atau berkaitan dengan Perisian dan/atau Peranti atau yang boleh digunakan atau disesuaikan untuk digunakan dalam atau berkaitan dengannya (sama ada berdaftar atau tidak berdaftar), termasuk semua paten, hak cipta, hak reka bentuk, hak pangkalan data, tanda dagangan, hak topografi semikonduktor, hak varieti tumbuhan, hak internet/nama domain, kepakaran, dan semua permohonan yang dinyatakan di atas serta mana-mana dan semua hak untuk memohon bagi mana-mana yang dinyatakan di atas ('Hak Milik'). Tiada Hak Milik akan dipindahkan kepada anda pada bila-bila masa.",
  "app.terms.line9": "4. Terma dan Sekatan penggunaan",
  "app.terms.line10": "Perisian ViewPoints dilesenkan untuk pengguna akhir. Tertakluk kepada perjanjian ini, kami dengan ini bersetuju untuk memberi kebenaran kepada anda, atas asas yang tidak eksklusif, boleh dibatalkan, tidak boleh dipindah milik, tidak boleh disub-lesenkan, dan terhad, untuk menggunakan perisian ViewPoints pada peranti anda atau yang dikawal anda atau komputer peribadi yang mana anda menyatakan dan menjamin bahawa anda mempunyai semua hak dan kebenaran yang diperlukan untuk pemasangan tersebut. Untuk mengelakkan sebarang keraguan, anda tidak boleh menggunakan untuk sebarang tujuan di luar ViewPoints, menterjemah, menyalin, menghasilkan semula, menghantar melalui telekomunikasi, mengadaptasi, menggabungkan, menggubah atau memodifikasi Perisian dalam apa jua cara.",
  "app.terms.line11": "Anda bersetuju untuk tidak: (i) menyalin, mengubah, menyesuaikan, membuat tersedia, menyewa, memajak, mencipta karya derivatif, atau cuba menjual atau memindahkan sebarang hak dalam Perisian (ii) membongkar, mendekompilasi, atau melakukan rekacipta terbalik pada mana-mana bahagian Perisian dengan apa cara sekalipun; atau membenarkan atau membantu mana-mana pihak untuk mendapatkan atau cuba mendapatkan kod sumber, membongkar, menyahsulit, mendekompilasi atau melakukan rekacipta terbalik pada Perisian; atau mengambil langkah lain untuk mendapatkan maklumat reka bentuk mengenai Perisian; (iii) menggunakan Perisian untuk sebarang tujuan selain daripada tujuan asal ia diedarkan kepada anda; (iv) menggunakan labah-labah, robot atau teknik perlombongan data automatik lain untuk menganalog, memuat turun, menyimpan, atau menghasilkan semula atau mengedarkan Perisian, atau untuk memanipulasi keputusan sebarang kajian atau aktiviti lain; (v) mengambil sebarang tindakan untuk menganggu Perisian atau penggunaan Perisian oleh individu, termasuk tetapi terhad kepada, dengan membebankan, 'menghujani', 'menghantar bom melalui email', atau 'menghancurkan' Perisian dan sistem penyimpanan data yang digunakan oleh Perisian melalui Amazon Web Services; (vi) menghantar kepada kami sebarang kod yang haram, menipu, atau berbahaya, termasuk tetapi tidak terhad kepada sebarang virus, spyware, adware dan kod berbahaya lain.",
  "app.terms.line12": "Semua hak yang tidak secara jelas diberikan kepada anda di sini adalah terpelihara untuk kami dan pemberi lesen kami. Anda bersetuju bahawa kami tidak mempunyai obligasi di bawah Perjanjian ini atau sebaliknya untuk membetulkan sebarang pepijat, kecacatan atau ralat dalam Perisian, atau untuk menyokong, menyelenggara, meningkatkan, menaik taraf, mengemas kini atau memperbaik Perisian. Kami tidak akan bertanggungjawab untuk memberikan bantuan kepada anda atau wakil yang ditetapkan oleh anda dalam penggunaan Perisian dengan apa jua cara.",
  "app.terms.line13": "Kami tidak bertanggungjawab atas sebarang kerosakan pada komputer anda, termasuk tetapi tidak terhad kepada kerosakan yang disebabkan oleh penggunaan Perisian. Kami juga tidak bertanggungjawab atas sebarang caj data tambahan yang ditanggung oleh anda akibat data yang dikumpulkan oleh Perisian melalui penyertaan anda dalam Kajian.",
  "app.terms.line14": "5. Penafian",
  "app.terms.line15": "Kami dan pengarah, pegawai, pekerja, ejen, wakil, pemberi lesen, dan pembekal kami masing-masing tidak membuat sebarang penyataan, jaminan atau syarat (a) bahawa perisian akan tersedia dalam waktu yang tepat, atau bahawa akses kepada perisian tidak akan terputus atau selamat; (b)bahawa kecacatan atau ralat akan dibaiki; atau (c)bahawa perisian atau pelayan atau rangkaian melalui mana-mana perisian dan peranti adalah selamat atau bebas daripada virus atau ancaman bahaya lain. Kami tidak mempunyai tuntutan untuk menyediakan sebarang perkhidmatan penyelenggaraan dan/atau sokongan berkaitan perisian.",
  "app.terms.line16": "6. Had Tanggungjawab",
  "app.terms.line17": "(a) Setakat yang dibenarkan sepenuhnya di bawah undang-undang yang berkenaan, anda bersetuju bahawa kami dan pengarah, pegawai, pekerja, ejen, wakil, pemberi lesen, dan penyedia kami tidak akan bertanggungjawab atas sebarang atau semua kerugian, kerosakan, atau kos daripada sebarang jenis yang hasilnya daripada pemasangan, penggunaan atau akses anda terhadap perisian, penggunaan anda atau ketidakmampuan anda untuk menggunakan perisian, sebarang maklumat atau bahan yang disediakan pada atau melalui perisian dan/atau berkaitan dnegan perisian atau sebaliknya dengan perjanjian ini, tanpa mengira hak undang-undang, ekuiti, atau statut yang telah dilanggar.",
  "app.terms.line18": "(b) Setakat yang dibenarkan sepenuhnya di bawah undang-undang yang berkenaan, satu-satunya dan penyelesaian eksklusif anda untuk ketidakpuasan terhadap perisian adalah untuk menghentikan penggunaannya.",
  "app.terms.line19": "(c) Dalam apa jua keadaan, tiada entiti kami, pengarah, pegawai, pekerja, ejen, atau wakil akan bertanggungjawab kepada anda untuk sebarang kerugian secara kebetulan, tidak langsung, berbangkit, statutori, diteladani, punitif, atau khas, termasuk tetapi tidak terhad kepada kehilangan pendapatan, kehilangan peluang, kehilangan reputasi, kehilangan penggunaan, kehilangan data, kehilangan tidak ketara lain, atau pemintasan data yang tidak sah oleh pihak ketiga yang dikumpulkan oleh perisian, sama ada boleh diramalkan atau tidak, bagaimana ia berlaku, dan di bawah sebarang kontrak, tort (termasuk tanpa had kecuaian), tanggungjawab ketat, atau teori lain, walaupun telah diberitahu lebih awal tentang kemungkinan bahawa kerugian tersebut mungkin berlaku.",
  "demo.index.content.line1": "Semasa demo ini kami akan menggunakan kamera anda untuk menganggar arah anda melihat. Jangan risau, kami tidak akan merakam sebarang gambar atau video.",
  "app.error.previousfail": "Jika anda telah gagal kajian ini sebelumnya, sila gunakan pautan asli untuk bermula dari awal.",
  "app.error.previoussuccess": "Anda telah melengkapkan kajian sebelum ini.",
  "app.error.route": "Anda telah cuba untuk mulakan kajian yang sedang berlangsung, sila gunakan pautan asli untuk bermula dari awal.",
  "app.error.accesscode": "Anda telah cuba untuk memulakan kajian yang tidak wujud, sila semak pautan anda.",
  "app.error.webcam": "Kajian ini memerlukan akses ke kamera web",
  "app.error.adblocker": "Kajian ini memerlukan fungsi adblock dinyahaktifkan.",
  "app.error.startsession": "Gagal memulakan sesi",
  "app.error.fullscreen": "Kajian ini memerlukan paparan penuh.",
  "app.error.zoom": "Kajian ini memerlukan zum pelayar ditetapkan pada 100%.",
  "app.index.webcamInstructions": "Kami akan memohon akses kepada kamera web anda, jika anda menerima notifikasi, sila klik benarkan.",
  "app.setup.retryBack": "Sila elak dari menekan butang kembali pada pelayar sepanjang kajian ini, ia akan menyebabkan kehilangan ganjaran. Gunakan buatang dibawah untuk mencuba semula.",
  "app.setup.retryaudible": "Sila pastikan peranti anda tidak berada dalam mod senyap dan cuba semula.",
  "app.restore.retry": "Anda tidak meluangkan masa yang mencukupi pada langkah sebelum ini, ",
  "app.restore.timeremaining": " baki beberapa minit",
  "app.error.postexposure": "Maaf,anda telah menggunakan kesemua peluang anda dan malangnya, anda tidak boleh meneruskan kajian ini ."
}