import { LSIntlFormat } from "../../translations/controller";
import DeviceController from "../../utils/controllers/DeviceController";

export default {
  tickRate: 100,
  calibrationPoints: {
    desktop: {},
    mobile: {
      1374: [
        [50, 50],
        [5, 5],
        [5, 95],
        [50, 95],
        [70, 95],
        [95, 95],
        [95, 5],
        [70, 5],
        [50, 5],
        [50, 50],
      ],
      1372: [
        [50, 50],
        [5, 5],
        [5, 95],
        [50, 95],
        [70, 95],
        [95, 95],
        [95, 5],
        [70, 5],
        [50, 5],
        [50, 50],
      ],
      1304: [
        [50, 50],
        [5, 5],
        [5, 95],
        [50, 95],
        [70, 95],
        [95, 95],
        [95, 5],
        [70, 5],
        [50, 5],
        [50, 50],
      ],
    },
  },
  validationPoints: {
    1160: [
      [50, 50],
      [5, 5],
      [5, 95],
      [50, 95],
      [95, 95],
      [95, 5],
      [50, 5],
    ],
    1168: [
      [50, 50],
      [5, 5],
      [5, 95],
      [50, 95],
      [95, 95],
      [95, 5],
      [50, 5],
    ],
  },
  instructions: {
    calibrate: {
      top: () => {
        return LSIntlFormat.IntlMessageFormat(
          "app.setup.calibrationProp.watch",
          "Follow the dot when it appears on screen"
        ).toUpperCase();
      },
      bottom: () => {
        return DeviceController.getIsMobile()
          ? LSIntlFormat.IntlMessageFormat(
              "app.setup.calibrationProp.tap",
              "Tap anywhere to continue"
            ).toUpperCase()
          : LSIntlFormat.IntlMessageFormat(
              "app.setup.calibrationProp.click",
              "Click anywhere to continue"
            ).toUpperCase();
      },
    },
    validate: {
      top: () => {
        return LSIntlFormat.IntlMessageFormat(
          "app.study.validationProp.watch",
          "Watch this dot"
        ).toUpperCase();
      },
      restart: () => {
        return LSIntlFormat.IntlMessageFormat(
          "app.study.validationProp.restart",
          "Please try again"
        ).toUpperCase();
      },
      bottom: () => {
        return DeviceController.getIsMobile()
          ? LSIntlFormat.IntlMessageFormat(
              "app.study.validationProp.tap",
              "Tap anywhere to continue"
            ).toUpperCase()
          : LSIntlFormat.IntlMessageFormat(
              "app.study.validationProp.click",
              "Click anywhere to continue"
            ).toUpperCase();
      },
    },
    retry: {
      pointTimeout: () => {
        return {
          text: "We were unable to determine your head position. Please keep your head and device still and do not cover the camera eg with fingers. Use the button below to retry.",
          id: "app.setup.retryPositioning",
        };
      },
      cameraAccess: () => {
        return {
          text: "Error detected. Please make sure that the browser can access your camera. If you have previously rejected camera access permissions, you will need to accept this in the browser's privacy settings. Use the button below to retry.",
          id: "app.setup.retryInit",
        };
      },
      brokerError: () => {
        return {
          text: "We were unable to complete the calibration process. Please try to close any unused apps and tabs to free up resources",
          id: "app.setup.retryBroker",
        };
      },
      genericError: () => {
        return {
          text: "Please keep your head and device still and do not cover the camera eg with fingers. Use the button below to retry.",
          id: "app.setup.retry",
        };
      },
      backButton: () => {
        return {
          text: "Please refrain from pressing the browser back button throughout this study, it may result in a loss of reward. Use the button below to retry.",
          id: "app.setup.retryBack",
        };
      },
      audible: () => {
        return {
          text: "Please ensure your device is not muted and try again.",
          id: "app.setup.retryaudible",
        };
      },
    },
  },
  brokerSubEvents: [
    "onFrameReaderStart",
    "onWebcamStreamCreate",
    "onVideoStart",
    "onEmitterStart",
    "onHeadPositioningVideoStart",
    "onLandmarkDetectorInitialized",
    "onGazeDetectorInitialized",
    "onWasmLoaded",
  ],
  errorConfig: {
    init: { errorCode: "InitError", failAction: "LS:InitFail" },
    headPositioning: {
      errorCode: "HeadPositioningError",
      failAction: "LS:HeadPositioningFail",
    },
    calibrate: { errorCode: "CalibrateError", failAction: "LS:CalibrateFail" },
    validate: { errorCode: "ValidateError", failAction: "LS:ValidateFail" },
    reInit: { errorCode: "ReinitError", failAction: "LS:ReInitFail" },
    cancel: { errorCode: "BackButtonError", failAction: "LS:SetupCancelled" },
    loadModel: { errorCode: "ModelLoadError", failAction: "LS:ModelLoadFail" },
  },
};
