import urlWithRegion from "../urlWithRegion";
import * as DEBUGLOG from "../../../components/util/debugLog";
import requestTimer from "../../requestTimer";
import { store } from "../../../store";

const validationResult = async (body) => {
  let validationResultRequestDate = DEBUGLOG.debugLogRequestStart("validation_result");
    let response

    if(store.getState().studyReducer.newTrackingSystem){
      response = await fetch(urlWithRegion() + "/ctt-validation-result/", {
        method: 'POST',
        body: JSON.stringify(body)
      })
    } else {
      response = await fetch(urlWithRegion() + "/new-validation-result/", {
        method: 'POST',
        body: JSON.stringify(body)
      })
    }

    DEBUGLOG.debugLogRequestFinish(validationResultRequestDate);
    
    return response
}

export default (...args) => requestTimer(() => validationResult(...args))