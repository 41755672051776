import { store } from "../../../../store";
import Endpoint from "../../Endpoint";

const getPayload = (currentStepId) => {
  let {accessCode, participantId} = store.getState().studyReducer

  let body = {
    access_code: accessCode,
    participant_id: participantId,
    step_id: currentStepId,
  };

  return body
};

export default (study_id, currentStepId) => {
  return new Endpoint(
    "complete-step",
    `${process.env.REACT_APP_PARTICIPATION_URL}/participation/studies/${study_id}/complete-step`,
    "POST",
    () => getPayload(currentStepId),
    undefined,
    false
  );
};
