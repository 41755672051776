import { BROKER_TYPES } from "../../../types/types";

export default (brokerType) => {
  if (
    brokerType === BROKER_TYPES.MOUSE ||
    brokerType === BROKER_TYPES.MOBILEMOUSE
  ) {
    return new Promise((resolve, reject) => {
      resolve({ success: false, message: "Mouse Broker" });
    });
  }
  return new Promise((resolve, reject) => {
    let startTime = new Date();
    if (
      (navigator && typeof navigator.mediaDevices === "undefined") ||
      (navigator &&
        navigator.mediaDevices &&
        typeof navigator.mediaDevices.getUserMedia === "undefined")
    ) {
      resolve({
        success: false,
        hasDevice: false,
        hasPermission: false,
        message: "Browser does not support webcam access",
      });
    } else {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((result) => {
          resolve({ success: true, hasDevice: true, hasPermission: true });
        })
        .catch((error) => {
          let endTime = new Date();
          switch (error.message) {
            case "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.":
              if (startTime - endTime > 20) {
                resolve({
                  success: false,
                  hasDevice: true,
                  hasPermission: false,
                  message: "User has denied camera access",
                });
              } else {
                resolve({
                  success: false,
                  hasDevice: true,
                  hasPermission: false,
                  message:
                    "Camera access was restricted at a domain or system level",
                });
              }
            case "Requested device not found":
              resolve({
                success: false,
                hasDevice: false,
                hasPermission: false,
                message: "User doesnt have a video camera",
              });
              break;
            case "Permission denied":
              resolve({
                success: false,
                hasDevice: true,
                hasPermission: false,
                message:
                  "Camera access was restricted at a domain or system level",
              });
              break;
            case "Permission denied by system":
              resolve({
                success: false,
                hasDevice: true,
                hasPermission: false,
                message:
                  "Camera access was restricted at a domain or system level",
              });
              break;
            default:
              resolve({
                success: false,
                hasDevice: false,
                hasPermission: false,
                message: error.message,
              });
              break;
          }
        });
    }
  });
};
