const saveHistory = (studyId, participant, accessCode, type, reason) => {
    if(studyId){
        let storageDest = localStorage ? localStorage : sessionStorage

        let studyHistory = storageDest.getItem('lr_study_history') ? JSON.parse(storageDest.getItem('lr_study_history')) : {}
    
        studyHistory[studyId] = {studyId, participant, result:type, at:new Date(), accessCode}

        if(reason){
            studyHistory[studyId].reason = reason
        }
      
        storageDest.setItem('lr_study_history', JSON.stringify(studyHistory))
    }
}

const loadHistory = (studyId, participant) => {
    let storageDest = localStorage ? localStorage : sessionStorage

    if(storageDest.getItem('lr_study_history')){
        let studyHistory = JSON.parse(storageDest.getItem('lr_study_history'))
        if(studyHistory[studyId] && (participant === null || studyHistory[studyId].participant === participant)){
            return studyHistory[studyId]
        }
    }

    return {}
}

const getAccessCode = (studyId) => {
    let storageDest = localStorage ? localStorage : sessionStorage
    
    if(storageDest['lr_study_history']){
        let studyHistory = JSON.parse(storageDest['lr_study_history'])
        if(studyHistory[studyId] && studyHistory[studyId].accessCode){
            return studyHistory[studyId].accessCode
        }
    }

    else return undefined
}

const getLastParticipantId = (studyId) => {
    let storageDest = localStorage ? localStorage : sessionStorage

    if(storageDest['lr_study_history']){
        let studyHistory = JSON.parse(storageDest['lr_study_history'])
        if(studyHistory[studyId] && studyHistory[studyId].participant){
            return studyHistory[studyId].participant
        }
    }

    return null
}

const clearhistory = (studyId) => {
    let storageDest = localStorage ? localStorage : sessionStorage

    if(storageDest.getItem('lr_study_history')){
        let studyHistory = JSON.parse(storageDest.getItem('lr_study_history'))
        if(studyHistory[studyId]){
            delete studyHistory[studyId]
            storageDest.setItem('lr_study_history', JSON.stringify(studyHistory))
        }
    }
}

export default {saveHistory, loadHistory, clearhistory, getLastParticipantId, getAccessCode}