{
    "app.button.faqs": "SSS",
    "app.button.terms": "Kullanım Şartları",
    "app.button.privacy": "Gizlilik Politikası",
    "app.button.feedback": "Geri bildirim",
    "app.button.selectLanguage": "Dil Seç",
    "app.button.help": "Yardım",
    "app.button.start": "Başlat",
    "app.button.next": "Sonraki",
    "app.button.submit": "Gönder",
    "app.button.close": "Kapat",
    "app.button.takepart": "Tamam - Katılacağım",
    "app.menu.feedback.content": "Çalışmayla ilgili bir sorun yaşadıysanız veya sadece bir yorum bırakmak istiyorsanız, lütfen burada bunu yapmaktan çekinmeyin.",
    "app.contactModal.feedback": "Geri bildiriminiz için teşekkür ederiz!",
    "app.takePartModal.line1": "Şimdi ayrılırsanız, herhangi bir katılım ödülü almaya hak kazanamayacaksınız.",
    "app.takePartModal.line2": "Tek ihtiyacınız olan bir web kamerası!",
    "app.takePartModal.reason1": "Web kameram yok.",
    "app.takePartModal.reason2": "Bu anket için web kameramın kullanılmasından memnun değilim.",
    "app.takePartModal.reason3": "Şu an bunun için zamanım yok.",
    "app.takePartModal.reason4": "Diğer sebep.",
    "app.generic.redirect": "Hemen yönlendirileceksiniz.",
    "app.init.welcome": "Lumen Research çalışma platformuna hoş geldiniz",
    "app.init.intro": "Çalışma platformu, kullanıcıları belirli bir reklam çalışmasına dâhil edip ayarlamaları yapmamıza olanak tanır.",
    "app.init.message1": "Çalışmanın türüne bağlı olarak, kullanıcı bir dizi görev boyunca yönlendirilecektir.",
    "app.init.message2": "Bu görevler şunları içerir; bir anket yapma, bir dizi talimatı okuma ve göz takip kalibrasyonu yapma",
    "app.init.message3": "Şimdi size bir demo çalışması ile rehberlik edeceğiz.",
    "app.init.message4": "Bu çalışma boyunca web kameranızın göz takibi yapmasını sağlayabiliriz, ancak verilerinizin hiçbiri kaydedilmeyecektir.",
    "app.init.message5": "Mevcut çalışma, uygunluk denetimi/tamamlama sırasında aşağıdaki bağlantıları kullanacak şekilde ayarlanmıştır.",
    "app.prestart.loading": "Yükleniyor, lütfen bekleyin.",
    "app.index.title": "Hoş geldiniz",
    "app.index.content.line1": "Bu anket sırasında nereye baktığınızı tahmin etmek için kameranızı kullanacağız. Endişelenmeyin, hiç bir fotoğraf veya video çekmeyeceğiz.",
    "app.index.content.line1alt": "Lütfen rahatça oturduğunuzdan emin olun ve ardından başlamak için başlat'a dokunun.",
    "app.index.content.line2": "Cihazınızda kamera var mı? Eğer varsa, lütfen aşağıdaki başlat düğmesine basın.",
    "app.index.rejection": "Üzgünüz, hatalı biçimlendirilmiş bir bağlantıyı takip ettiğiniz anlaşılıyor.",
    "app.index.webview": "Üzgünüz, sayfamıza bir web görünümünden eriştiğiniz anlaşılıyor. Lütfen bu bağlantıyı tarayıcınızın içinden açın. Bunu doğrudan web görünümü menüsünden yapmak mümkün olabilir.",
    "app.index.webview.chrome.1": "Yukarıdaki menü düğmesine dokunun ve ardından ‘Chrome'da Aç’ seçeneğini seçin.",
    "app.index.webview.chrome.2": "Bu şekilde anket, bir tarayıcı uygulamasında açılacak, böylece düzgün çalışabilecek.",
    "app.index.webview.android.1": "Bu anketi düzgün çalışabilmesi için bir tarayıcı uygulamasında açmak üzere aşağıda Başlat'a dokunun.",
    "app.index.webview.android.2": "Bu işlem başarısız olursa, bu sayfanın bağlantısını bir tarayıcı uygulamasında manuel olarak açın (örneğin, bağlantıyı kopyalayıp Chrome'daki adres çubuğuna yapıştırın ya da yukarıdaki menü çubuğunda ‘Chrome'da Aç’ gibi bir seçenek kullanın).",
    "app.index.webview.safari.1": "Devam etmek için ekranın altındaki Safari simgesine dokunun.",
    "app.index.webview.safari.2": "Şuna benziyor:",
    "app.index.webview.safari.3": "Bu şekilde anket, Safari’de açılacak, böylece düzgün çalışabilecek.",
    "app.index.wifi": "Görünüşe göre internet bağlantınız çok yavaş. Wi-Fi'ye bağlı mısınız?",
    "app.index.tellmemore": "Daha fazla bahsedin.",
    "app.index.takepart": "Katılmak istemiyorum.",
    "app.index.webcamRetry": "Bu çalışma için gerekli olan bir web kamerası tespit edemedik. Lütfen web kameranıza erişim sağlayın ve tekrar deneyin.",
    "app.index.adblockRetry": "Pardon! Bir reklam engelleyici tespit ettik. En iyi çalışma deneyimi için lütfen bunu kapatıp tekrar deneyin.",
    "app.instructions.title": "Talimatlar",
    "app.instructions.instruction1": "Mümkünse anket sırasında gözlük takmayın.",
    "app.instructions.instruction2": "Rahat bir şekilde başınızı ve cihazınızı sabit tutabilmek için oturun.",
    "app.instructions.instruction3": "Başınızı değil gözlerinizi hareket ettirin.",
    "app.instructions.instruction4": "Başlamaya hazır mısınız?",
    "app.instructions.instruction5": "Veri ücretlerini önlemek için lütfen wi-fi kullanın.",
    "app.instructions.instruction6": "Lütfen aşağıdaki talimatları dikkatlice okuyun.",
    "app.instructions.instruction7": "Telefonunuzu sabit bir yüzeye koyun.",
    "app.instructions.instruction8": "Lütfen odanızın tercihen yukarıdan (arkadan değil) iyi aydınlatıldığından emin olun.",
    "app.setup.title": "Kurulum",
    "app.setup.instruction0": "Cihazınızı portre modunda tutun.",
    "app.setup.instruction0a": "Lütfen cihazınızı dikey olacak şekilde döndürün.",
    "app.setup.instruction0b": "Lütfen cihazınızı, kamerası solda olacak şekilde döndürün.",
    "app.setup.instruction0c": "Lütfen cihazınızı, kamerası sağda olacak şekilde döndürün.",
    "app.setup.instruction0d": "Lütfen cihazınızı yatay olacak şekilde döndürün.",
    "app.setup.instructioncamera": "Kamera erişimini talep etmek için lütfen aşağıdaki düğmeyi tıklayın. Anketi tamamlamak için çalışma boyunca kamera izinlerine onay vermeniz gerekiyor.",
    "app.setup.cameraText": "Erişim Talebi",
    "app.setup.instruction1": "Başlatılıyor...",
    "app.setup.instruction2": "Algılandığınızda başınızı kutunun içine yerleştirin.",
    "app.setup.instruction3": "Anket tamamlanana kadar başınızı ve cihazınızı mevcut pozisyonda tutun.",
    "app.setup.instruction4": "Şimdi sadece doğrulamamız gerekiyor.",
    "app.setup.instruction5": "Teşekkürler! Anket tamamlanana kadar başınızı ve cihazınızı aynı pozisyonda tutmayı unutmayın.",
    "app.setup.instruction6": "Adres çubuğunu gizlemek için lütfen yukarı kaydırın.",
    "app.setup.retry": "Hata algılandı. Lütfen başınızı ve cihazınızı sabit tutun ve örneğin kamerayı  parmaklarınızla kapatmayın. Yeniden denemek için aşağıdaki düğmeyi kullanın.",
    "app.setup.retryInit": "Hata algılandı. Lütfen tarayıcının kameranıza erişebildiğinden emin olun. Daha önce kamera erişim izinlerini reddettiyseniz, bunu tarayıcının gizlilik ayarlarından kabul etmeniz gerekecektir. Yeniden denemek için aşağıdaki düğmeyi kullanın.",
    "app.setup.retryButton": "Yeniden dene",
    "app.setup.calibrationProp.watch": "Ekranda göründüğünde noktayı takip edin",
    "app.setup.calibrationProp.watch.george": "Ardından bir yüz gördüğünüzde, lütfen kafa hareketlerini yansıtarak ona bakmaya devam edin",
    "app.setup.calibrationProp.click": "Devam etmek için herhangi bir yere tıklayın",
    "app.setup.calibrationProp.tap": "Devam etmek için herhangi bir yere dokunun",
    "app.study.validationProp.watch": "Ekranda göründüğünde noktayı takip edin",
    "app.study.validationProp.restart": "Lütfen tekrar deneyin",
    "app.study.validationProp.click": "Devam etmek için herhangi bir yere tıklayın",
    "app.study.validationProp.tap": "Devam etmek için herhangi bir yere dokunun",
    "app.study.render.error": "Etki alanı, oluşturma adımı için geçerli değil.",
    "app.error.title": "Hata",
    "app.error.generic": "Pardon! Bir şeyler yanlış gitti.",
    "app.error.initialise": "Başlatılamadı!",
    "app.error.findCamera": "Kamera bulunamadı!",
    "app.error.headPosition": "Baş pozisyonunuz belirlenemiyor.",
    "app.error.calibrate": "Kalibrasyon yapılamıyor.",
    "app.error.validate": "Doğrulanamıyor.",
    "app.error.start": "Başlatılamadı.",
    "app.error.assign": "Atanamadı.",
    "app.error.osnotallowed": "Uygunluk Denetimi: İşletim sisteminize izin verilmiyor, lütfen bu anket için hangi cihazı kullanabileceğinizi kontrol edip tekrar deneyin.",
    "app.error.demoquotafullnoactivecell": "Kota dolu: İlginiz için teşekkürler. Bu çalışma artık demografinize kapalıdır.",
    "app.error.missingpanelparameter": "Eksik panel parametresi.",
    "app.invalid.title": "Geçersiz",
    "app.invalid.generic": "Üzgünüz, bu çalışma için uygun değilsiniz.",
    "app.completion.title": "Tamamlama",
    "app.completion.congratulations": "Tebrikler! Çalışmayı tamamladınız. Bu anketin kameranıza erişimi şimdi sonlandırılacak.",
    "app.questions.required": "Bu soru gerekli.",
    "app.faq.question1": "S: Lumen Çalışma Platformu nedir ve göz takip anketine katılmak, neleri kapsar?",
    "app.faq.answer1": "C: Bu anket, göz takip teknolojisinin kullanımını içerir. Cihazınızın web kamerasının veya ön kamerasının önünde oturmanız ve bir kalibrasyon gerçekleştirmeniz istenir, böylece ekranınızda nereye baktığınıza dair bir tahmin yapılabilir. Anket sırasında, anket aracı nereye baktığınızı tahmin ederken bazı öğeleri (örn. resimler, web siteleri veya videolar) görüntülemeniz istenecektir. Bu ‘göz takibi’nden elde edilen veriler, zaman içinde oluşan ekranınızdaki tahmini bakış noktalarının koordinatlarıdır. Yazılım, bu tahminleri yapmak için web kameranızdaki görüntülerinizi kullanır, ancak fotoğraflarınızı veya videolarınızı çekmez. Ayrıca kendiniz, deneyiminiz ve anket sırasında gördüğünüz öğeler hakkında bazı sorular da sorulabilir. Veriler Lumen Research Ltd.'ye gönderilir. Lumen Research, verileri analiz edecek ve sonuçları müşterileriyle paylaşacaktır.",
    "app.faq.question2": "S: Yazılım neden bir web kamerası / kamera gerektiriyor ve bu nasıl kullanılıyor?",
    "app.faq.answer2": "C: Yazılım, bir ekranda nereye baktığınızı tahmin etmek için web kameranızdaki veya cihazınızın kamerasındaki görüntüleri kullanır. Bunu, görüntüleri gerçek zamanlı olarak makinenizde yerel olarak analiz eden bir yüz takip ve göz takip yazılımı kombinasyonunu kullanarak yapar. Bu görüntüler işlenir ve kalibrasyon sırasında size gösterilir, böylece kendinizi doğru bir şekilde konumlandırabilirsiniz, ancak görüntüler kaydedilmez, böylece gizliliğiniz korunur. Göz takibinden kaydedilen ve toplanan veriler, görüntüler değil,  uyaran üzerinde zaman içinde oluşan tahmini bakış noktalarına ilişkin sayısal verilerdir.",
    "app.faq.question3": "S: Göz takibi yaparken benim resimlerimi çekiyor musunuz?",
    "app.faq.answer3": "C: Görüntülerin kullanıldığı tek zaman, kalibrasyon sırasında kendinizi doğru şekilde konumlandırmanıza olanak sağlamak içindir. Bu görüntüler kaydedilmez ve yalnızca sizin tarafınızdan görülür. Göz takibinden kaydedilen ve toplanan veriler, görüntüler değil,  uyaran üzerinde zaman içinde oluşan tahmini bakış noktalarına ilişkin sayısal verilerdir.",
    "app.faq.question4": "S: Anket sırasında hangi veriler toplanıyor?",
    "app.faq.answer4.line1": "C: Yazılım, aşağıdaki beş veri türünün bir kısmını veya tamamını toplayacaktır:",
    "app.faq.answer4.line2": "'Kullanıcı Kimliği' - Katılımcıları tanımlamak için kullanılan Kullanıcı Kimliği (katılım ödüllerini tayin etmek amacıyla);",
    "app.faq.answer4.line3": "'Bakış verileri' – Ekranda uyaranlar gösterildiğinde kullanıcıların ekranlarında nereye baktığına dair tahminler",
    "app.faq.answer4.line4": "'Anket verileri' – Kullanıcılar tarafından cevaplanan sorulara verilen yanıtlar",
    "app.faq.answer4.line5": "'Meta veri' - Bireylerin Araştırmaya başarıyla katıldıkları zamana ilişkin veriler.",
    "app.faq.answer4.line6": "'Fiziksel Cihaz verileri' – RAM düzeyi, makinedeki CPU türü ve kullanıcı aracısı (işletim sistemi ve tarayıcı sürümü) gibi bir kullanıcının katıldığı bilgisayarla ilgili veriler ve ayrıca bilgisayarın ekranı (boyut ve çözünürlük), web kamerası (model ve çözünürlük) ve antivirüs programları ile ilgili bilgiler.",
    "app.faq.question5": "S: Toplanan veriler nasıl kullanılacak?",
    "app.faq.answer5.line1": "C: Toplanan veriler, bireylerin çeşitli görsel görüntü ve medya türlerini nasıl görüntülediklerini ve bunlarla nasıl etkileşime girdiğini keşfetmek ve insanların gösterilen görüntüler hakkındaki görüşlerini ölçmek için kullanılacaktır.",
    "app.faq.answer5.line2": "Lumen, araştırma verilerini bağlı şirketler, iş ortakları ve müşterilerle paylaşabilir. Araştırma verileri bağlı kuruluşlar, ortaklar ve müşterilerle paylaşılabilir olmasına rağmen, bu süreç genellikle anonim olarak yapılacaktır. Bu tür bağlı kuruluşlar, ortaklar ve müşteriler, ürün geliştirmek ya da müşterilerine veya tüketicilerine pazarlama çözümleri sağlamak için geniş kapsamlı tüketici davranışlarının ve tüketici kesimlerinin özet açıklamalarını oluşturmak üzere bu verileri kullanabilirler.",
    "app.faq.answer5.line3": "Lumen, kalite kontrol ve hacim yönetimi dâhil olmak üzere verilerin toplanması ve işlenmesinin yönetilmesine yardımcı olmak için 'Fiziksel Cihaz verilerini' ve 'Meta Verileri' toplar.",
    "app.faq.answer5.line4": "Toplanan veriler, belirtilen amaçları yerine getirmek için gerekli süre boyunca saklanacaktır.",
    "app.faq.question6": "S: Verilerim ne kadar güvende?",
    "app.faq.answer6": "C: Lumen tarafından toplanan veriler, SSL teknolojisi kullanılarak şifrelenir. Bu, şifreleme teknolojisinin en yaygın olarak kullanılan yüksek standardıdır ve bankalar tarafından bankacılık işlemlerini ve diğer son derece hassas iletişimleri güvence altına almak için kullanılır. Ayrıca Amazon, PayPal, eBay ve banka düzeyinde güvenlik sistemleri gerektiren diğer web siteleri tarafından da kullanılır. Bilim adamları, mevcut teknolojiyi kullanarak 256 bit şifrelemeyi kırmanın evrenin yaşından çok daha fazla zaman gerektireceğini tahmin etmektedir. Toplanan veriler, Amazon Web Services (AWS) tarafından yönetilen güvenli web sunucularında depolanır. AWS, en yaygın olarak kullanılan güvenli ve güvenilir bulut bilişim hizmetleri platformlarından biridir ve Netflix ve Expedia dâhil olmak üzere başlıca önemli bulut tabanlı ticari hizmetler tarafından kullanılmaktadır.",
    "app.faq.question7": "S: Size gösterilen İçerikle ilgili yükümlülükleriniz nelerdir?",
    "app.faq.answer7": "C: Bu çalışmaya katılarak, bu çalışmada size gösterilen İçeriğin gizli, üçüncü bir şahsa ait ve/veya ticari marka veya telif hakkı yasalarına tabi olabileceğini ve herhangi bir şekilde İçeriğin ekran görüntüsünü almayacağınızı, onu kaydetmeyeceğinizi veya başka bir şekilde kopyalamayacağınızı veya çalışmanın İçeriğini hiç bir suretle yeniden yayınlamayacağınızı da anlar ve kabul edersiniz.",
    "app.faq.question8": "S: Web kameramın ne kadar iyi olduğundan emin değilim, göz takibi için işe yarar mı?",
    "app.faq.answer8": "C: Sistem çok çeşitli web kameralarıyla çalışır – bir sorun olmaz.",
    "app.faq.question9": "S: Çalışmayı tamamladım, başka bir şey yapmam gerekiyor mu?",
    "app.faq.answer9": "C: Hayır, başka bir şey yapmak gerekmiyor. Yazılım otomatik olarak kapanacaktır.",
    "app.faq.question10": "S: Katılmak için internete bağlı olmam gerekiyor mu?",
    "app.faq.answer10": "C: Evet, programı indirmek ve görevi tamamlamak için bir internet bağlantısına ihtiyacınız olacak.",
    "app.faq.question11": "S: Görevi sabit bir yüzeyde mi tamamlamam gerekiyor?",
    "app.faq.answer11": "C: Evet, en iyi sonuçları elde etmek için, bilgisayar ekranınızın önünde rahatça oturmalı ve kalibrasyondan sonra, uyaran size sunulurken size bildirilinceye kadar başınızı sabit tutmalısınız. Trendeyseniz veya dizüstü bilgisayarınızı kucağınızda tutuyorsanız vb. durumlarda katılmamanızı tavsiye ederiz.",
    "app.faq.question12": "S: SSS sorunumu çözmedi, şimdi ne yapacağım?",
    "app.faq.answer12": "C: Lütfen bize study-feedback@lumen-research.com adresinden e-posta gönderin, elimizden geldiğince hızlı şekilde size geri dönüş yapacağız.",
    "app.faq.patent": "Lumen'in web içeriklerini test etmesi, kendi tescilli bağlam içi test teknolojimiz tarafından desteklenmektedir (Birleşik Krallık Patent Başvuru Numarası 1816158.8)",
    "app.terms.line1": "Bu kullanım koşulları, cihazınızda bulunan 'ViewPoints' göz takip yazılımının ('Yazılım') kullanımına ilişkin Siz ve şirket numarası 8682432, kayıtlı ofisi Unit 215, 22 Highbury Grove, London N5 2EF adresinde bulunan ve veri koruma tescil referansı ZA130772 olan İngiltere'de kayıtlı bir şirket Lumen Research arasındaki sözleşmeyi düzenler.",
    "app.terms.line2": "1. Sözleşme şartları",
    "app.terms.line3": "Yazılımı kullanarak, bu Sözleşmeye bağlı kalmayı kabul ettiğinizi onaylamış olursunuz. Bu Sözleşmenin hüküm ve koşullarını kabul etmiyorsanız, ViewPoints anketini doldurmayın.",
    "app.terms.line4": "2. Gizli Bilgiler",
    "app.terms.line5": "ViewPoints'i kurarken, kullanırken veya herhangi bir şekilde etkileşimde bulunurken, Lumen veya Lumen'in müşterileri veya kullanıcılarından herhangi biri tarafından gizli olarak tanımlanan veya değerlendirilen ya da karakteri veya ifşasının koşulları veya şekli nedeniyle açık bir şekilde gizli olan bilgiler de dâhil olmak üzere (yukarıdaki hükümlerin genel niteliğine halel getirmeksizin), iş planları, olgunlaşan yeni iş fırsatları, araştırma ve geliştirme projeleri, tasarımlar, gizli süreçler, kodlar, yazılım tasarımları, ticari sırlar, ürün veya hizmet geliştirme ve formüller, teknik bilgiler, buluşlar, satış istatistikleri ve tahminleri, pazarlama stratejileri ve planları, maliyetler, kâr ve zarar ve diğer finansal bilgiler (ancak denetlenen hesaplarda yayınlandığı ölçüde), fiyatlar ve indirim yapıları (yazılı veya elektronik veya başka bir formatta kaydedilmiş olsun ya da olmasın tümü) ('Gizli Bilgiler') dâhil olmak üzere Lumen'e ait veya Lumen ile ilgili bilgilere ve/veya içeriğe erişebilirsiniz. Bu tür Gizli Bilgiler, Lumen ve/veya müşterilerinin tek ve münhasır mülkiyeti olarak kalacak ve hiçbir durumda bu tür Gizli Bilgiler üzerinde herhangi bir hak, unvan ve/veya menfaat elde etmeyecek veya almayacaksınız",
    "app.terms.line6": "ViewPoints'e katılımınız sırasında veya bu tür bir katılımın sona ermesinden sonra (sınırlama olmaksızın) herhangi bir zamanda, herhangi bir kişiye ifşada bulunmamalı veya iletişim kurmamalısınız; kendi amaçlarınız için kullanmamalısınız; ya da gereken özen ve ihtimamı göstermemeniz durumunda, herhangi bir Gizli Bilginin yetkisiz olarak ifşa edilmesine neden olmamalısınız. Herhangi bir Gizli Bilginin yayınlanmasını veya ifşa edilmesini önlemek için her zaman elinizden gelenin en iyisini yapmalısınız. Bu kısıtlamalar, yükümlülüğünüzü yerine getirmemeniz dışında başka şekilde genel olarak kamuya açık hale gelecek herhangi bir bilgi için geçerli olmayacaktır.",
    "app.terms.line7": "3. Lumen Mülkiyeti",
    "app.terms.line8": "ViewPoints Yazılımı ve Yazılımla ilgili tüm veriler, kodlar, anahtarlar, şifreler, tasarımlar, çizimler, kayıtlar ve diğer belgeler veya materyaller, bir ViewPoints anketine katıldığınız süre boyunca Lumen'in mülkiyetinde olacak ve öyle kalacaktır. Lumen, tüm mevcut ve gelecekteki patentler, telif hakları, tasarım hakları, veri tabanı hakları, ticari markalar, yarı iletken topografya hakları, bitki yetiştiricileri hakları, internet hakları/alan adları, teknik bilgiler ve yukarıdakilerden herhangi biri için yapılan tüm başvurular ve yukarıdakilerden herhangi biri için uygulanacak tüm haklar ('Mülkiyet Hakları') dâhil olmak üzere, Yazılım ve/veya Cihaz ile bağlantılı veya bunlarla ilgili olarak ya da burada veya bunlarla bağlantılı olarak (kayıtlı veya kayıtsız olsun) kullanılabilen veya uyarlanabilen tek başınıza veya başka bir kişi ile birlikte gerçekleştirilen, oluşturulan, tasarlanan, geliştirilen, ilerletilen, analiz edilen veya keşfedilen herhangi bir belge, malzeme, model, tasarım, çizim, süreç, buluş, formül, bilgisayar kodlama, veri, sistem arayüzleri, metodolojiler, teknik bilgiler, Gizli Bilgiler veya diğer işlerdeki mevcut ve gelecekteki tüm fikri veya sınai mülkiyet haklarının tüm fikri veya mülkiyet haklarına sahiptir. Hiçbir Mülkiyet Hakkı, hiçbir zaman size geçmez.",
    "app.terms.line9": "4. Kullanıma ilişkin şartlar ve kısıtlamalar",
    "app.terms.line10": "ViewPoints Yazılımı, son kullanıcılara lisanslanır. İşbu Sözleşmeye tabi olarak, ViewPoints Yazılımını münhasır olmayan, geri alınabilir, devredilemez, alt lisans verilemez, sınırlı bir temelde, sahip olduğunuz veya kontrol ettiğiniz bir cihazda veya bu tür bir kurulum için gerekli tüm haklara ve izinlere sahip olduğunuzu beyan ve garanti ettiğiniz kişisel bir bilgisayarda kullanmanıza izin vermeyi kabul ediyoruz. Şüpheye mahal vermemek için, ViewPoints dışında herhangi bir amaçla Yazılımı kullanamaz, çeviremez, kopyalayamaz, çoğaltamaz, telekomünikasyon yoluyla iletemez, herhangi bir şekilde uyarlayamaz, birleştiremez, değiştiremez veya modifike edemezsiniz.",
    "app.terms.line11": "Şunları yapmamayı kabul edersiniz: (i) kopyalamak, değiştirmek, uyarlamak, kullanıma sunmak, kiralamak, kiraya vermek, türev bir iş oluşturmak veya Yazılımdaki herhangi bir hakkı satmaya veya başka bir suretle devretmeye teşebbüs etmek; (ii) Yazılımın herhangi bir parçasını herhangi bir şekilde sökmek, ayrıştırmak veya tersine mühendislik yapmak; herhangi bir tarafın Yazılımın kaynak kodunu türetmesine veya türetmeye çalışmasına, sökmesine, şifresini çözmesine, kaynak koda dönüştürmesine veya tersine mühendislik yapmasına izin vermek veya yardım etmek; (iii) Yazılımı, başlangıçta size verildiği amaç dışındaki herhangi bir amaç için kullanmak; (iv) Yazılımı kataloglamak, indirmek, depolamak veya başka bir şekilde çoğaltmak veya dağıtmak ya da herhangi bir anketin veya başka bir faaliyetin sonuçlarını manipüle etmek için örümcekler, robotlar veya diğer otomatik veri madenciliği tekniklerini kullanmak; (v) Yazılımı ve Yazılımın Amazon Web Hizmetleri aracılığıyla kullandığı veri depolama sistemini aşırı yüklemek, 'flooding yapmak', 'ileti bombamdırmanı yapmak' veya 'çökertmek' dâhil ancak bunlarla sınırlı olmamak üzere Yazılımı veya bireyin Yazılımı kullanımına müdahale etmek için herhangi bir işlem yapmak; (vi), herhangi bir virüs, casus yazılım, reklam yazılımı veya diğer zararlı kodlar dâhil ancak bunlarla sınırlı olmamak üzere herhangi bir yasa dışı, aldatıcı veya zararlı kodu bize göndermek.",
    "app.terms.line12": "Size burada açıkça verilmeyen tüm haklar bize ve lisans verenlerimize aittir. Bu Sözleşme kapsamında veya Yazılımdaki herhangi bir virüsü, kusuru veya hatayı düzeltmek veya Yazılımı başka bir şekilde desteklemek, sürdürmek, iyileştirmek, değiştirmek, yükseltmek, güncellemek veya geliştirmek için hiçbir yükümlülüğümüzün olmadığını kabul edersiniz. Yazılımın kullanımında size veya görevlendirdiğiniz kişilere herhangi bir şekilde yardım sağlamaktan sorumlu değiliz.",
    "app.terms.line13": "Yazılımı kullanımınızın bir sonucu olarak fakat bunlarla sınırlı olmamak üzere, PC'nize gelebilecek herhangi bir hasardan sorumlu değiliz. Araştırmaya katılımınız yoluyla Yazılım tarafından toplanan verilerin bir sonucu olarak tarafınızca maruz kalınan ek veri ücretlerinden sorumlu değiliz.",
    "app.terms.line14": "5. Sorumluluk Reddi",
    "app.terms.line15": "Biz ve ilgili yöneticilerimiz, görevlilerimiz, çalışanlarımız, mümesillerimiz, temsilcilerimiz, lisans verenlerimiz ve sağlayıcılarımız, (a) yazılımın zamanında kullanılabilir olacağına veya yazılıma erişimin kesintisiz veya güvenli olacağına; (b) kusurların veya hataların düzeltileceğine; veya (c) yazılımın ya da yazılımın ve cihazın kullanılabilir olduğu sunucuların veya ağların güvenli olduğuna veya virüslerden veya diğer zararlı bileşenlerden arınmış olduğuna dair hiçbir beyanda bulunmayız veya garanti vermeyiz veya şart koşmayız. Yazılımla ilgili olarak herhangi bir bakım ve/veya destek hizmeti sunma yükümlülüğümüz yoktur.",
    "app.terms.line16": "6. Sorumluluğun sınırlandırılması",
    "app.terms.line17": "(a) Yürürlükteki yasalar uyarınca izin verilen en geniş ölçüde, bizim ve ilgili yöneticilerimizin, görevlilerimizin, çalışanlarımızın, mümesillerimizin, temsilcilerimizin, lisans verenlerimizin ve sağlayıcılarımızın; yazılımın kurulumundan, kullanımından veya erişiminden, yazılımı kullanmanızdan veya kullanamamanızdan, yazılım üzerinde veya yazılım aracılığıyla sunulan herhangi bir bilgi veya materyalden kaynaklanan ve/veya yazılımla veya bu sözleşmeyle ilgili olarak ihlal edildiği iddia edilen kanuni, eşitlikçi veya yasal hakkın niteliğine bakılmaksızın herhangi bir kayıp, hasar veya masraftan sorumlu olmadığımızı kabul edersiniz.",
    "app.terms.line18": "(b) Yürürlükteki yasaların izin verdiği en geniş ölçüde, yazılımdan memnuniyetsizliğe karşı tek ve münhasır çareniz, bu yazılımı kullanmayı bırakmaktır.",
    "app.terms.line19": "(c) Hiçbir durumda, herhangi bir kuruluşumuz, yöneticilerimiz, görevlilerimiz, çalışanlarımız, mümesillerimiz, temsilcilerimiz; herhangi bir tesadüfi, dolaylı, sonuç olarak ortaya çıkan, yasal, örnek teşkil eden, cezai veya özel zararlardan, gelir kaybından, fırsat kaybından, itibar kaybından, kullanım kaybından, veri kaybından, diğer maddi olmayan varlıkların kaybından veya yazılım tarafından toplanan verilerin üçüncü taraflarca, öngörülebilir olsun veya olmasın, hangi nedenle olursa olsun yetkisiz olarak engellenmesinden ve herhangi bir sözleşme kapsamında, tazminat yükümlülüğü (sınırlama olmaksızın ihmal dâhil), kusursuz sorumluluk veya diğer ihtimallerden, bu tür zararların ortaya çıkma olasılığı önceden bildirilmiş olsa bile, size karşı sorumlu olmayacaktır.",
    "demo.index.content.line1": "Bu demo sırasında nereye baktığınızı tahmin etmek için kameranızı kullanacağız. Endişelenmeyin, hiç bir fotoğraf veya video çekmeyeceğiz.",
    "app.error.previousfail": "Bu çalışmada daha önce başarısız oldunuz, lütfen baştan başlamak için orijinal bağlantıyı kullanın.",
    "app.error.previoussuccess": "Bu çalışmayı daha önce tamamladınız.",
    "app.error.route": "Hâlihazırda devam eden bir çalışma başlatmayı denediniz, lütfen baştan başlamak için orijinal bağlantıyı kullanın.",
    "app.error.accesscode": "Var olmayan bir çalışmayı başlatmayı denediniz, lütfen bağlantınızı kontrol edin.",
    "app.error.webcam": "Bu Çalışma, bir web kamerasına erişim gerektiriyor",
    "app.error.adblocker": "Bu çalışma, reklam engelleyicinin devre dışı bırakılmasını gerektiriyor.",
    "app.error.startsession": "Oturum başlatılamıyor",
    "app.error.fullscreen": "Bu çalışma tam ekran görünümü gerektiriyor.",
    "app.error.zoom": "Bu çalışma, tarayıcı yakınlaştırmasının %100 olarak ayarlanmasını gerektiriyor.",
    "app.index.webcamInstructions": "Birazdan web kameranıza erişim talep edeceğiz. Açılır bir pencere görecek olursanız, lütfen ‘izin ver’ seçeneğini tıklayın.",
    "app.setup.retryBack": "Lütfen bu alıştırma boyunca tarayıcının geri düğmesine basmaktan kaçının. Aksi takdirde ödül hakkınızı kaybedebilirsiniz. Tekrar denemek için aşağıdaki düğmeyi kullanın.",
    "app.setup.retryaudible": "Lütfen cihazınızın sesinin kapalı olmadığından emin olup, tekrar deneyin.",
    "app.restore.retry": "Önceki adıma yeterince zaman ayırmadınız, ",
    "app.restore.timeremaining": " dakika kaldı",
    "app.error.postexposure": "Üzgünüm, şu anda tüm girişimlerinizi kullandınız ve ne yazık ki çalışmaya devam edemezsiniz.",
    "app.faq.gdpr.intro1": "Bu çalışmada, bir mobil cihazın ön kamerasını kullanarak, bir dizi çevresel (örn. aydınlatma ve konumlandırma) koşul altında uyaranlara bakmayı içeren çeşitli egzersizler yapmaları istenirken bir kullanıcının görüntülerini ve video görüntülerini çekeceğiz.  Katılımın yaklaşık 5 dakika sürmesi beklenmektedir.  Veriler, kullanıcıların bir ekranda nereye baktıklarını tahmin edebilen yazılımın sürekli geliştirilmesine yardımcı olmak için kullanılacaktır.",
    "app.faq.gdpr.intro2": "Bu araştırma MRS Davranış Kuralları ve 2018 Veri Koruma Yasası'na uygun olarak yürütülmektedir.  Gizliliğiniz bizim için önemlidir.  Bu çalışmaya katılmadan önce, bu çalışmaya katılarak, topladığımız verileri ve bu Gizlilik Politikasında açıklandığı şekilde nasıl kullanılacağını anladığınızı ve bu verilerin bu şekilde kullanılmasına izin verdiğinizi kabul ettiğinizi onaylamanız gerekir.  Bu politika tam olarak hangi bilgilerin toplandığını ve bunların nasıl kullanıldığını ve paylaşıldığını daha ayrıntılı olarak açıklamaktadır.",
    "app.faq.gdpr.question1": "Hangi veriler toplanacak?",
    "app.faq.gdpr.answer1.line1": "Bu çalışma aşağıdaki veri türlerinin toplanmasını içerecektir:",
    "app.faq.gdpr.answer1.line2": "'Oturma özellikleri' - Belirli bir veri toplama 'Oturmasını' tanımlamak için kullanılan anonim bir kimlik ve oturum sırasında geçerli çevresel test koşulları hakkında veriler.",
    "app.faq.gdpr.answer1.line3": "'Katılımcı özellikleri' - Katılımcının demografik (örn. yaş, cinsiyet), etnik (örn. cilt tipi) ve biyolojik (örn. göz rengi) özelliklerine ilişkin bilgiler de dahil olmak üzere, belirli bir Oturuma katılan bir katılımcı hakkındaki veriler.",
    "app.faq.gdpr.answer1.line4": "'Video dosyası' - Katılımcıların bir ekranda veya ekranlarda görüntülenen uyaranlara bakarken ve potansiyel olarak bunlarla etkileşim halindeyken görüntülerini yakalamak için cihazın ön kamerası kullanılarak çekilen resimler ve/veya video görüntüleri. Katılımcının gözleri, yüzü ve görev tamamlanırken ön kamera tarafından istemeden çekilebilecek diğer her şeyin görüntüsü alınacaktır. ",
    "app.faq.gdpr.answer1.line5": "'Meta Veriler' - Toplanan her görüntünün zaman damgasıyla bağlantılı olarak, bireylerin uyaranlarla etkileşime girdiği zamanla ilgili veriler.",
    "app.faq.gdpr.answer1.line6": "'Fiziksel Cihaz verileri' - Cihaz adı, ekran boyutu, ekran dönüşü ve kamera sensörü dönüşü dahil olmak üzere cihazla ilgili fiziksel veriler.",
    "app.faq.gdpr.answer1.line7": "Bu araştırmaya katılarak, bu çalışmada size gösterilen İçeriğin gizli olabileceğini, üçüncü bir tarafın mülkiyetinde olabileceğini ve/veya ticari marka veya telif hakkı yasalarına tabi olabileceğini ve İçeriğin ekran görüntüsünü almayacağınızı, kaydetmeyeceğinizi veya başka bir şekilde kopyalamayacağınızı veya çalışmanın İçeriğini herhangi bir şekilde yeniden dağıtmayacağınızı anlar ve kabul edersiniz.",
    "app.faq.gdpr.question2": "Veriler nasıl kullanılacak / işlenecek?",
    "app.faq.gdpr.answer2": "Veriler, kullanıcıların bir cihazın önünde bulunup bulunmadıklarını, uyarıcılara bakıp bakmadıklarını ve uyarıcıların neresine bakıyor olabileceklerini tahmin edebilen yazılımların geliştirilmesi için kullanılacaktır.  Ayrıca tanıtım amacıyla veya üçüncü taraflara (müşteriler, tedarikçiler, ortaklar) teknolojimizin nasıl geliştirildiğini ve performansını açıklamak veya değerlendirmeye yardımcı olmak için de kullanılabilir.  Veriler, araştırma sırasında katılımcıların dikkat 'durumlarını' değerlendirmek için video görüntülerini / görüntüleri izlemeleri istenecek olan çalışanlar veya alt yükleniciler veya ortaklar veya başka herhangi bir üçüncü taraf olan herhangi bir sayıda veri 'açıklayıcısı' tarafından incelenebilir (örneğin, kameranın görüş alanında varlar mı? ekrana bakıyorlar mı?)  Verilerin işlenmesi için yasal dayanaklar bilgilendirilmiş rıza ve meşru menfaatler olacaktır. ",
    "app.faq.gdpr.question3": "Veriler ne kadar süreyle saklanacak?",
    "app.faq.gdpr.answer3": "Veriler, yukarıda açıklanan amaçlar için makul olarak gerekli olduğu sürece kullanılmak üzere tarafımızca saklanacaktır.  Geliştirilmekte olan yazılım periyodik olarak güncellenmekte ve değerlendirilmektedir ve bu veriler her bir yinelemeyi değerlendirmek için kullanılacaktır, bu nedenle süresiz olmasa da birkaç yıl boyunca bu amaçla saklanması ve tutulması beklenmektedir.  Veriler, uyarıcı test yazılımının geliştirilmesi veya değerlendirilmesi amacıyla artık yararlı olmadıkları düşünüldüğünde silinecektir. ",
    "app.faq.gdpr.question4": "Verileri kim alacak ve işleyecek?",
    "app.faq.gdpr.answer4": "Veriler Lumen Research Ltd. tarafından tutulacak ve saklanacaktır.  Lumen Research Ltd, İngiltere'de 8682432 şirket numarasıyla kayıtlı bir şirkettir.  Kayıtlı ofisi LABS house, 15-19 Bloomsbury Way, Holborn, London WC1A 2TH adresindedir. Veri koruma kayıt referansı ZA130772'dir.  Veri Koruma Görevlisi Matt Robinson'dur (matt.robinson@lumen-research.com).  Birleşik Krallık'ın Veri Koruma Otoritesi olan Bilgi Komiserliği Ofisi ile iletişime geçerek verilerinizin ele alınması ve işlenmesi hakkında istediğiniz zaman şikayette bulunma hakkına sahipsiniz.  ICO'nun iletişim bilgileri https://ico.org.uk/global/contact-us/ adresindeki web sitesinde mevcuttur. Çalışmaya katılarak, Lumen'e bu verileri barındırmak, saklamak, kullanmak, görüntülemek, çoğaltmak, değiştirmek, uyarlamak, düzenlemek, yayınlamak ve dağıtmak için dünya çapında, telifsiz, alt lisanslanabilir ve devredilebilir bir lisans vermiş olursunuz.",
    "app.faq.gdpr.question5": "Kişisel verilerime erişim hakkım var mı?",
    "app.faq.gdpr.answer5": "Evet, kişisel verilerinize erişme hakkına sahipsiniz, ancak adınızın bu çalışma sırasında toplanan verilerle birlikte saklanmayacağını bilmeniz önemlidir.  Veri kayıtlarınızı tanımlamak için, bize araştırmayı gerçekleştirdiğiniz kesin saat ve tarihi sağlamanız gerekecek ve veri kayıtlarının doğru bir şekilde eşleştirilmesini sağlamak için görsel tanımlama istememiz gerekecektir.",
    "app.faq.gdpr.question7": "Kişisel verilerimin işlenmesine verdiğim onayı geri çekme veya kişisel verilerimin silinmesini talep etme hakkım var mı?",
    "app.faq.gdpr.answer7": "Evet, kişisel verilerinizin işlenmesine verdiğiniz onayı geri çekme ve bu verilerin silinmesini talep etme hakkına sahipsiniz.  Ancak, adınızın bu uygulama sırasında toplanan verilerle birlikte saklanmayacağını bilmeniz önemlidir.  Veri kaydınızı tanımlamak için, bize araştırmayı gerçekleştirdiğiniz kesin saat ve tarihi sağlamanız gerekecek ve veri kayıtlarının doğru bir şekilde eşleştirilmesini sağlamak için görsel tanımlama isteyeceğiz. ",
    "app.faq.gdpr.question8": "Kişisel bilgilerimin güvende olduğunu nasıl bilebilirim?",
    "app.faq.gdpr.answer8": "Lumen Research tarafından toplanan veriler Secure Sockets Layer (SSL) teknolojisi kullanılarak şifrelenir.  Bu, yaygın olarak kullanılan yüksek standartta bir şifreleme teknolojisidir. Bu teknoloji hem aktarım kanallarının hem de nihai depolamanın güvenliğini sağlamak için kullanılır.",
    "app.faq.gdpr.question9": "Topladığımız bilgileri nasıl kullanacağız ve ifşa edeceğiz?",
    "app.faq.gdpr.answer9": "Topladığımız bilgileri, bize hizmet sunmalarını sağlamak için diğer üçüncü taraf hizmet sağlayıcılarına ifşa edebiliriz. Ayrıca, herhangi bir yeniden yapılanma, birleşme, satış, ortak girişim, görevlendirme, devir veya işimizin, varlıklarımızın veya hisselerimizin tamamının veya bir kısmının başka bir şekilde elden çıkarılması durumunda (herhangi bir iflas veya benzer işlemlerle bağlantılı olarak) bilgileri üçüncü bir tarafa ifşa edebiliriz. Ayrıca, sizden ve sizin hakkınızda topladığımız bilgileri gerekli veya uygun olduğuna inandığımız şekilde kullanabilir veya ifşa edebiliriz: (a) ikamet ettiğiniz ülke dışındaki yasalar da dahil olmak üzere yürürlükteki yasalar uyarınca; (b) yasal sürece uymak için; (c) ikamet ettiğiniz ülke dışındaki kamu ve hükümet yetkilileri de dahil olmak üzere kamu ve hükümet yetkililerinden gelen taleplere yanıt vermek için; (d) son kullanıcı lisans sözleşmemizi uygulamak için; (e) faaliyetlerimizi veya bağlı kuruluşlarımızın faaliyetlerini korumak; (f) haklarımızı, gizliliğimizi, güvenliğimizi veya mülkümüzü ve/veya bağlı kuruluşlarımızın, sizin veya başkalarının haklarını korumak; (g) mevcut çözüm yollarını takip etmemize veya maruz kalabileceğimiz zararları sınırlamamıza izin vermek; ve (h) herhangi bir dolandırıcılık veya güvenlik sorununu tespit etmek ve çözmek. ",
    "app.faq.gdpr.question10": "Verileri güvenli bir şekilde tutmak için hangi adımlar atılıyor?",
    "app.faq.gdpr.answer10": "Bilgilerinizi, gözetimimiz veya kontrolümüz altındaki bilgilerin hassasiyetine uygun fiziksel, elektronik veya prosedürel güvenlik önlemleri kullanarak korumak için makul adımlar atarız; bu önlemler, kayıp veya hırsızlığın yanı sıra yetkisiz erişim, ifşa, kopyalama, kullanım veya değişikliğe karşı koruma önlemlerini de içerir. ",
    "app.completion.congratulations-noet": "Tebrikler! Çalışmayı tamamladınız.",
    "app.error.cellquotafullnoactivecell": "Bu Çalışma için aktif bir Hücre bulunamadı. Hücre Kotaları dolu.",
    "app.setup.retryBroker": "Kalibrasyon işlemini tamamlayamadık. Kaynakları boşaltmak için lütfen kullanılmayan uygulamaları ve sekmeleri kapatmayı deneyin",
    "app.setup.retryPositioning": "Baş pozisyonunuzu belirleyemedik. Lütfen başınızı ve cihazınızı sabit tutun ve kamerayı örneğin parmaklarınızla kapatmayın. Yeniden denemek için aşağıdaki düğmeyi kullanın.",
    "app.setup.instruction3audio": "Lütfen dikkatlice dinleyin ve ekranda çıkan talimatları izleyin.",
    "app.index.zoomRetry": "Tarayıcının %100 yakınlaştırmaya ayarlanmasını istiyoruz, lütfen yakınlaştırıp/uzaklaştırıp tekrar deneyin",
    "app.index.fullscreendesktopretry": "Bu çalışma tam ekran görüntü gerektirir, lütfen tarayıcı pencerenizin büyütülmüş olduğundan emin olun",
    "app.index.fullscreendesktoptest": "Bu çalışma tarayıcı pencerenizin tam ekran olmasını gerektirir, tarayıcı pencerenizin maksimize edildiğinden emin olmak için bir test gerçekleştirmemiz gerekir",
    "app.index.fullscreendesktoptest2": "Kısa süre içinde iki düğme görünecektir, devam etmek için lütfen bunlara tıklayın",
    "app.index.fullscreendesktopmaximised": "Ekranım zaten en üst düzeye çıkarılmış durumda",
    "app.index.fullscreendesktopskip": "Teşekkür ederiz, bu örneği kaydettik ve tespit süreçlerimizi iyileştireceğiz.",
    "app.index.fullscreendesktopreset": "Pencere boyutunda bir değişiklik tespit ettik ve testi sıfırladık.",
    "app.index.gotomobile": "Masaüstü kullanıcıları bu çalışma için uygun değildir, çalışmayı mobil olarak açmak için lütfen aşağıdaki QR kodunu kullanın.",
    "app.index.returntopanel": "VEYA, Panele dönmek için aşağıdaki düğmeye tıklayın",
    "app.index.content.line1gpdrwarning": "Bu anket sırasında, siz uyaranlara bakmayı içeren çeşitli egzersizler yaparken fotoğraflarınızı ve video görüntülerinizi çekmek için cihazınızdaki ön kamerayı kullanacağız.",
    "app.button.return": "Dönüş",
    "app.thankyou": "Teşekkür ederim"
}