import React from 'react';
import { useLayoutEffect, useState } from "react"
import { Router } from "react-router-dom";
import { store } from "../../store/index";
import { setPath } from '../../reducers/actions';

export const CustomRouter = ({ history, ...props }) => {
    const [state, setState] = useState({
      action: history.action,
      location: history.location
    });
  
    useLayoutEffect(() => history.listen(setState), [history]);

    useLayoutEffect(() => history.listen((location) => {
      store.dispatch(setPath(location.location.pathname));
    }), [history]);
  
    return (
      <Router
        {...props}
        location={state.location}
        navigationType={state.action}
        navigator={history}
      />
    );
  };