{
  "app.button.faqs": "Häufige Fragen und Antworten",
  "app.button.terms": "Nutzungsbedingungen",
  "app.button.privacy": "Datenschutzrichtlinie",
  "app.button.feedback": "Feedback",
  "app.button.selectLanguage": "Sprache auswählen",
  "app.button.help": "Hilfe",
  "app.button.start": "Start",
  "app.button.next": "Weiter",
  "app.button.submit": "Einreichen",
  "app.button.close": "Schließen",
  "app.button.takepart": "Ok, ich nehme teil",
  "app.menu.feedback.content": "Sind Sie in dieser Studie einem Problem begegnet oder möchten Sie sich anderweitig dazu äußern, können Sie dies gerne hier tun.",
  "app.contactModal.feedback": "Vielen Dank für Ihr Feedback!",
  "app.takePartModal.line1": "Falls Sie jetzt abbrechen, verlieren Sie Ihren Anspruch auf eine Teilnahmevergütung.",
  "app.takePartModal.line2": "Sie brauchen lediglich eine Webcam!",
  "app.takePartModal.reason1": "Ich habe keine Webcam.",
  "app.takePartModal.reason2": "Ich möchte nicht, dass meine Webcam zur Teilnahme an dieser Umfrage benutzt wird.",
  "app.takePartModal.reason3": "Ich habe gerade keine Zeit.",
  "app.takePartModal.reason4": "Anderer Grund.",
  "app.generic.redirect": "Sie werden bald weitergeleitet.",
  "app.init.welcome": "Willkommen auf der Studienplattform von Lumen Research",
  "app.init.intro": "Diese Plattform ermöglicht uns, Nutzer zu registrieren und die nötigen Einstellungen und Vorbereitungen für die Teilnahme an einer Werbestudie vorzunehmen.",
  "app.init.message1": "Je nach Art der Studie wird der Nutzer dabei durch eine Reihe von Aufgaben geleitet.",
  "app.init.message2": "Diese Aufgaben sind u.a. die Beantwortung einer Umfrage, Durchlesen einer Reihe von Anweisungen sowie Aufgaben zur Kalibrierung der Eye Tracking-Software",
  "app.init.message3": "Sie werden nun zur Demonstration durch eine Beispiel-Studie geleitet.",
  "app.init.message4": "Im Rahmen dieser Studie greifen wir eventuell auf Ihre Webcam zu, um Ihre Augenbewegungen per Eye Tracking zu erfassen. Dabei werden jedoch keine Daten von Ihnen aufgezeichnet.",
  "app.init.message5": "Die aktuelle Studie verwendet die folgenden Links bei Screenout/Abschluss der Umfrage.",
  "app.prestart.loading": "Ladevorgang, bitte warten.",
  "app.index.title": "Herzlich willkommen!",
  "app.index.content.line1": "In dieser Umfrage nutzen wir eine Kamera, die erfasst, wo Sie hinschauen. Wir machen dabei keine Fotos oder Video-Aufnahmen.",
  "app.index.content.line1alt": "Stellen Sie bitte sicher, dass Sie bequem sitzen und drücken Sie dann auf „Start“, um zu beginnen.",
  "app.index.content.line2": "Verfügt Ihr Gerät über eine Kamera? Wenn ja, klicken Sie bitte auf die Schaltfläche „Start“ unten.",
  "app.index.rejection": "Es tut uns leid, anscheinend sind Sie einem fehlerhaften Link gefolgt.",
  "app.index.webview": "Es tut uns leid. Es scheint, dass Sie unsere Seite über eine Webansicht aufgerufen haben. Bitte öffnen Sie diesen Link in Ihrem Browser. Dies ist eventuell direkt über das Webansicht-Menü möglich.",
  "app.index.webview.chrome.1": "Tippen Sie auf die Menütaste oben im Fenster und wählen Sie „In Chrome öffnen“.",
  "app.index.webview.chrome.2": "Das öffnet die Umfrage in einer Browser-App, damit alles korrekt funktioniert.",
  "app.index.webview.android.1": "Tippen Sie auf „Start“, um die Umfrage in einer Browser-App zu öffnen, damit alles korrekt funktioniert.",
  "app.index.webview.android.2": "Sollte dies nicht funktioniert, öffnen Sie den Link manuell in einer Browser-App (z. B. durch Kopieren und Einfügen in die Adressleiste von Chrome oder über eine Option wie „In Chrome öffnen“ in der Menüleiste).",
  "app.index.webview.safari.1": "Tippen Sie auf das Safari-Symbol unten auf dem Bildschirm, um fortzufahren.",
  "app.index.webview.safari.2": "Es sieht so aus:",
  "app.index.webview.safari.3": "Das öffnet die Umfrage in Safari, damit sie nun korrekt funktioniert.",
  "app.index.wifi": "Es scheint, dass Ihre Internetverbindung sehr langsam ist. Sind Sie an ein drahtloses Datennetzwerk (WLAN) angeschlossen?",
  "app.index.tellmemore": "Mehr Infos anzeigen.",
  "app.index.takepart": "Ich möchte nicht teilnehmen.",
  "app.index.webcamRetry": "Wir konnten keine Webcam ermitteln. Diese ist jedoch für diese Studie erforderlich. Bitte erlauben Sie den Zugriff auf Ihre Webcam und versuchen Sie es noch einmal.",
  "app.index.adblockRetry": "Für die Dauer dieser Studie muss alle Werbeblocker-Software deaktiviert werden. Bitte deaktivieren Sie Ihren Werbeblocker und versuchen Sie es noch einmal.",
  "app.instructions.title": "Anweisungen",
  "app.instructions.instruction1": "Wenn möglich, tragen Sie bitte während der Durchführung der Umfrage keine Brille.",
  "app.instructions.instruction2": "Setzen Sie sich so hin, dass Sie Ihren Kopf und das Gerät bequem ruhig halten können, ohne sich zu bewegen.",
  "app.instructions.instruction3": "Bewegen Sie Ihre Augen und nicht Ihren Kopf.",
  "app.instructions.instruction4": "Können wir beginnen?",
  "app.instructions.instruction5": "Bitte nutzen Sie eine WLAN-Verbindung, um Gebühren für eine mobile Datenverbindung zu vermeiden.",
  "app.instructions.instruction6": "Bitte lesen Sie sich die folgenden Anweisungen sorgfältig durch.",
  "app.instructions.instruction7": "Platzieren Sie Ihr Handy auf einer stabilen Oberfläche.",
  "app.instructions.instruction8": "Bitte stellen Sie sicher, dass Ihr Zimmer gut beleuchtet ist - vorzugsweise von oben (nicht von hinten).",
  "app.setup.title": "Einrichten",
  "app.setup.instruction0": "Bitte achten Sie darauf, dass Ihr Gerät im Portraitmodus ist.",
  "app.setup.instruction0a": "Drehen Sie bitte Ihr Gerät, bis es im Portraitmodus ist.",
  "app.setup.instruction0b": "Drehen Sie bitte Ihr Gerät, so dass die Kamera auf der linken Seite ist.",
  "app.setup.instruction0c": "Drehen Sie bitte Ihr Gerät, so dass die Kamera auf der rechten Seite ist.",
  "app.setup.instruction0d": "Drehen Sie bitte Ihr Gerät ins Querformat.",
  "app.setup.instructioncamera": "Bitte klicken Sie unten auf die Taste, um Kamerazugriff zu erlauben. Zur Durchführung dieser Umfrage müssen Sie für die Dauer der Studie Kamerazugriff erlauben.",
  "app.setup.cameraText": "Zugriff erlauben",
  "app.setup.instruction1": "Initialisierung ...",
  "app.setup.instruction2": "Wenn Ihr Bild erfasst wird, positionieren Sie Ihren Kopf so, dass er im angezeigten Feld erscheint.",
  "app.setup.instruction3": "Halten Sie Ihren Kopf und das Gerät in dieser Position, bis die Umfrage abgeschlossen ist.",
  "app.setup.instruction4": "Es fehlt nur noch die Validierung.",
  "app.setup.instruction5": "Vielen Dank! Halten Sie Ihren Kopf und das Gerät in dieser Position, bis die Umfrage abgeschlossen ist.",
  "app.setup.instruction6": "Fahren Sie bitte mit dem Finger nach oben, um die Adressleiste auszublenden.",
  "app.setup.retry": "Fehler erkannt. Halten Sie Ihren Kopf und das Gerät bitte still und stellen Sie sicher, dass die Kamera nicht versehentlich verdeckt wird (zum Beispiel mit Ihrem Finger). Über die Schaltfläche unten können Sie es erneut versuchen.",
  "app.setup.retryInit": "Fehler erkannt. Bitte achten Sie darauf, dass der Browser berechtigt ist, die Kamera zu nutzen. Wenn Sie den Zugriff auf die Kamera zuvor abgelehnt haben, müssen Sie ihn in den Browser-Einstellungen zulassen. Über die Schaltfläche unten können Sie es erneut versuchen.",
  "app.setup.retryButton": "Wiederholen",
  "app.setup.calibrationProp.watch": "Folgen Sie dem Punkt, wenn er auf dem Bildschirm angezeigt wird",
  "app.setup.calibrationProp.watch.george": "Sie werden gleich ein Gesicht sehen. Schauen Sie dieses bitte kontinuierlich an, während Sie die gleichen Kopfbewegungen nachmachen",
  "app.setup.calibrationProp.click": "Klicken Sie auf eine beliebige Stelle, um fortzufahren.",
  "app.setup.calibrationProp.tap": "Tippen Sie auf eine beliebige Stelle, um fortzufahren.",
  "app.study.validationProp.watch": "Folgen Sie dem Punkt, wenn er auf dem Bildschirm angezeigt wird",
  "app.study.validationProp.restart": "Bitte versuchen Sie es erneut.",
  "app.study.validationProp.click": "Klicken Sie auf eine beliebige Stelle, um fortzufahren.",
  "app.study.validationProp.tap": "Tippen Sie auf eine beliebige Stelle, um fortzufahren.",
  "app.study.render.error": "Domain für diese Renderphase ungültig.",
  "app.error.title": "Fehler",
  "app.error.generic": "Ups! Es tut uns leid, es ist ein Fehler aufgetreten.",
  "app.error.initialise": "Initialisierung fehlgeschlagen!",
  "app.error.findCamera": "Kamera kann nicht gefunden werden!",
  "app.error.headPosition": "Kopfposition kann nicht bestimmt werden.",
  "app.error.calibrate": "Kalibrierung fehlgeschlagen.",
  "app.error.validate": "Validierung fehlgeschlagen.",
  "app.error.start": "Start gescheitert.",
  "app.error.assign": "Zuweisung gescheitert.",
  "app.error.osnotallowed": "Ihr Betriebssystem wird nicht unterstützt. Bitte prüfen Sie, auf welchen Geräten diese Umfrage genutzt werden kann und versuchen Sie es dann erneut.",
  "app.error.demoquotafullnoactivecell": "Danke für Ihre Interesse. Diese Studie ist jetzt für Ihre Demografie geschlossen.",
  "app.invalid.title": "Ungültig",
  "app.invalid.generic": "Es tut uns leid, Sie erfüllen die Teilnahmekriterien für diese Studie nicht.",
  "app.completion.title": "Fertig",
  "app.completion.congratulations": "Glückwunsch! Sie haben die Studie abgeschlossen. Die Berechtigung, für die Umfrage auf die Kamera zuzugreifen, endet hiermit.",
  "app.questions.required": "Diese Frage muss beantwortet werden.",
  "app.faq.question1": "F: Worum handelt es sich bei der Lumen-Studienplattform und was beinhaltet die Teilnahme an einer Eye-Tracking-Studie?",
  "app.faq.answer1": "A: Diese Studie beinhaltet den Einsatz von Eye-Tracking-Technologie. Sie werden gebeten, vor der Webcam Ihres Geräts oder der Frontkamera zu sitzen und eine Kalibrierung vorzunehmen, damit erfasst werden kann, wohin Sie auf Ihrem Bildschirm ungefähr schauen. Im Rahmen der Studie werden Sie gebeten, sich einige Elemente anzuschauen (z. B. Bilder, Websites oder Videos), während das Umfragetool erfasst, wo Sie ungefähr hinschauen. Die Daten, die im Rahmen dieses „Eye-Tracking“ erfasst werden, sind die Koordinaten der geschätzten Blickpunkte auf Ihrem Bildschirm im Verlauf der Zeit. Um diese Schätzung zu erstellen, verwendet die Software Bilder von Ihnen, die über Ihre Webcam erfasst werden. Dabei werden jedoch keine Fotos oder Videoaufnahmen von Ihnen gemacht. Möglicherweise werden Ihnen auch Fragen zu Ihrer Person, Ihrer Erfahrung und den Elementen, die Ihnen im Rahmen der Studie gezeigt wurden, gestellt. Die Daten werden an Lumen Research Ltd. gesandt, und Lumen Research wird die Daten analysieren und die Ergebnisse mit seinen Auftraggebern teilen.",
  "app.faq.question2": "F: Warum benötigt diese Software eine Webcam/Kamera und wie wird diese verwendet?",
  "app.faq.answer2": "A: Die Software verwendet Bilder von der Webcam oder der Kamera Ihres Geräts, um zu erfassen, wohin Sie auf einem Bildschirm ungefähr schauen. Dafür wird eine Kombination aus Softwareprogrammen zum Tracking von Gesichts- und Augenbewegungen verwendet, die in Echtzeit Bilder lokal auf Ihrer Maschine analysiert. Diese Bilder werden verarbeitet und Ihnen während der Kalibrierung gezeigt, damit Sie sich richtig positionieren können. Jedoch werden diese Bilder nicht aufgezeichnet, um Ihre Privatsphäre zu schützen. Bei den Daten, die im Rahmen der Eye-Tracking-Studie aufgezeichnet und erhoben werden, handelt es sich nicht um Bilder, sondern um numerische Daten darüber, wo auf den Stimulus Ihr Blick im Verlauf der Zeit fällt.",
  "app.faq.question3": "F: Machen Sie beim Eye-Tracking Aufnahmen von mir?",
  "app.faq.answer3": "A: Aufnahmen werden nur im Rahmen der Kalibration verwendet, damit Sie sich richtig positionieren können. Diese Aufnahmen werden nicht gespeichert und nur Ihnen angezeigt. Bei den Daten, die im Rahmen der Eye-Tracking-Studie aufgezeichnet und erhoben werden, handelt es sich nicht um Bilder, sondern um numerische Daten darüber, wo auf den Stimulus Ihr Blick im Verlauf der Zeit fällt.",
  "app.faq.question4": "F: Welche Daten werden im Verlauf der Studie erfasst?",
  "app.faq.answer4.line1": "A: Die Software wird ein Teil oder alle der folgenden fünf Datentypen erheben:",
  "app.faq.answer4.line2": "„Benutzer-ID“: Die Benutzer-ID wird verwendet um Teilnehmer zu identifizieren (für die Zwecke der Verwaltung von Teilnahmevergütungen).",
  "app.faq.answer4.line3": "„Blickpunktdaten“: Schätzwerte darüber, wohin der Benutzer auf dem Bildschirm schaut, wenn die Stimuli auf dem Bildschirm eingeblendet werden.",
  "app.faq.answer4.line4": "„Umfragedaten“: Antworten auf Fragen, die von den Benutzern beantwortet wurden",
  "app.faq.answer4.line5": "„Metadaten“: Daten über die Zeitpunkte, zu denen Privatpersonen erfolgreich an der Studie teilgenommen haben",
  "app.faq.answer4.line6": "„Physikalische Gerätedaten“: Daten über den Computer mit dem ein Benutzer teilnimmt, wie zum Beispiel wie viel Arbeitsspeicher, der CPU-Typ des Geräts und der User Agent (Betriebssystem und Browserversion) sowie Informationen über den Computerbildschirm (Grösse und Auflösung), die Webcam (Marke und Auflösung) und Antivirusprogramme.",
  "app.faq.question5": "F: Wie werden die erhobenen Daten verwendet?",
  "app.faq.answer5.line1": "A: Die erhobenen Daten werden verwendet, um herauszufinden, wie Privatpersonen verschiedene Arten von visuellen Bildern und Medien anschauen und damit interagieren, und um die Meinung der Teilnehmer über die Bilder, die ihnen gezeigt wurden, einzuschätzen.",
  "app.faq.answer5.line2": "Lumen kann die Studiendaten mit Tochtergesellschaften sowie mit Geschäftspartnern und Auftraggebern teilen. Wenngleich die Studiendaten mit Tochtergesellschaften, Geschäftspartnern und Auftraggebern geteilt werden können, erfolgt dies normalerweise anonym. Die entsprechenden Tochtergesellschaften, Partner und Auftraggeber können diese Daten verwenden, um zusammenfassende Beschreibungen verschiedenster Verbraucherverhalten und Verbrauchersegmente zu erstellen, um Produkte für ihre Kunden oder Konsumenten zu entwickeln oder ihnen Marketinglösungen anzubieten.",
  "app.faq.answer5.line3": "Lumen erhebt „physikalische Gerätedaten“ und „Metadaten“, um die Erhebung und Verarbeitung von Daten zu unterstützen. Dazu zählt auch die Qualitätskontrolle und das Volumenmanagement.",
  "app.faq.answer5.line4": "Die erhobenen Daten werden für den Zeitraum aufbewahrt, der nötig ist, um die beschriebenen Zwecke zu erfüllen.",
  "app.faq.question6": "F: Wie sicher sind meine Daten?",
  "app.faq.answer6": "A: Die von Lumen erfassten Daten werden mithilfe von SSL-Technologie verschlüsselt. Dabei handelt es sich um den am weitesten verbreiteten bewährten Verschlüsselungsstandard, der auch von Banken für die Abwicklung sicherer Bankgeschäfte und anderer streng vertraulicher Mitteilungen verwendet wird. Zudem wird dieser Standard auch von Amazon, PayPal, eBay und anderen Websites verwendet, die auf für Banken geeignete Sicherheitssysteme angewiesen sind. Wissenschaftlern zufolge würde das Brechen der 256 Bit-Verschlüsselung beim Stand der heutigen Technologie wesentlich mehr Zeit als das Alter des Universums in Anspruch nehmen. Die erhobenen Daten werden auf sicheren Webservern gespeichert, die von Amazon Web Services (AWS) gemanagt werden. AWS ist eine der populärsten, sichersten und zuverlässigsten Cloud Computing Services-Plattformen und wird von großen cloud-basierten kommerziellen Serviceanbietern, darunter Netflix und Ex PDA, verwendet.",
  "app.faq.question7": "F: Welche Verpflichtungen haben Sie im Hinblick auf den Inhalt, der Ihnen gezeigt wird?",
  "app.faq.answer7": "A: Durch Ihre Teilnahme erklären Sie sich ausdrücklich damit einverstanden, dass der Inhalt, der Ihnen in dieser Studie gezeigt wird, vertraulicher Natur sein kann, das Eigentum von Dritten sein kann bzw. Schutzrechten oder Urheberrechtsgesetzen unterliegen kann, und das Sie weder Screenshots, Aufzeichnungen noch anderweitige Kopien beliebiger Teile der Inhalte machen werden noch beliebige Teile der Inhalte der Studie auf irgendeine Art weiter vertreiben werden.",
  "app.faq.question8": "F: Ich bin mir nicht sicher, ob die Qualität meiner Webcam gut genug ist. Kann sie für das Eye-Tracking verwendet werden?",
  "app.faq.answer8": "A: Das System ist darauf aufgerichtet, mit einer breiten Spanne an Webcams zu arbeiten. Normalerweise sollte dies kein Problem sein.",
  "app.faq.question9": "F: Ich habe die Studie abgeschlossen. Muss ich noch etwas tun?",
  "app.faq.answer9": "A: Nein, Sie müssen nichts mehr tun. Die Software wird das Programm automatisch beenden. Sie können jetzt die Software deinstallieren und die heruntergeladene Datei löschen.",
  "app.faq.question10": "F: Muss ich für die Teilnahme mit dem Internet verbunden sein?",
  "app.faq.answer10": "A: Ja, Sie benötigen eine Internetverbindung, um das Programm herunterzuladen und die gestellte Aufgabe zu erfüllen.",
  "app.faq.question11": "F: Benötige ich für das Erfüllen der gestellten Aufgabe eine stabile Oberfläche?",
  "app.faq.answer11": "A: Ja, für ein optimales Ergebnis sollten Sie bequem vor Ihrem Computerbildschirm sitzen. Nach der Kalibration müssen Sie Ihren Kopf solange stillhalten wie der Stimulus präsentiert wird und bis eine entsprechende Mitteilung ergeht. Wir raten von der Teilnahme während einer Zugfahrt ab. Auch sollten Sie nicht teilnehmen, wenn Sie Ihren Laptop auf dem Schoß haben o. ä.",
  "app.faq.question12": "F: Meine Frage wurde in diesem Abschnitt nicht beantwortet. An wen kann ich mich wenden?",
  "app.faq.answer12": "A: Bitte schicken Sie uns eine E-Mail an study-feedback@lumen-research.com und wir melden uns baldmöglichst bei Ihnen.",
  "app.faq.patent": "Die von Lumen durchgeführte Prüfung von Webinhalten wird von unserer eigenen In-context-Prüfungstechnologie gestützt (britische Patentantragsnummer 1816158.8).",
  "app.terms.line1": "Diese Nutzungsbedingungen („Nutzungsbedingungen“) regeln die Vereinbarung zwischen Ihnen und Lumen Research Ltd („Lumen“ oder „wir“), einem in England unter der Firmennummer 8682432 eingetragenen Unternehmen mit Geschäftssitz in Unit 215, 22 Highbury Grove, London N5 2EF und Datenschutzregistrierungs-Referenz ZA130772 hinsichtlich Ihrer Nutzung der „ViewPoints“ Eye-Tracking-Software (die „Software“) auf Ihrem Gerät.",
  "app.terms.line2": "1. Einhaltung der Bestimmungen",
  "app.terms.line3": "Durch die Verwendung der Software geben Sie Ihre Zustimmung, durch die Bedingungen dieser Vereinbarung gebunden zu sein. Falls Sie den Bestimmungen dieser Vereinbarung nicht zustimmen, nehmen Sie bitte nicht an der ViewPoint-Umfrage teil.",
  "app.terms.line4": "2. Vertrauliche Informationen",
  "app.terms.line5": "Während der Installation, Verwendung oder sonstigem Umgang mit ViewPoints in irgendeiner Form haben Sie möglicherweise Zugang auf Informationen und/oder Inhalte, die Lumen gehören oder betreffen, einschließlich Informationen, die von Lumen bzw. dessen Auftraggebern oder Kunden als vertraulich identifiziert oder behandelt wurden oder die aufgrund ihrer Eigenschaften, der Umstände oder der Art und Weise ihrer Offenlegung offensichtlich vertraulich sind, einschließlich (unbeschadet der Allgemeingültigkeit des Vorstehenden) jegliche Informationen über Geschäftspläne, anreifende neue Geschäftsmöglichkeiten, Forschungs- und Entwicklungsprojekte, Entwürfe, Geheimverfahren, Codes, Software-Design, Geschäftsgeheimnisse, Produkt- und Dienstleistungsentwicklung und -formeln, Know-How, Erfindungen, Umsatzstatistiken und -prognosen, Marketingstrategien und -pläne, Kosten, Gewinn- und Verlustrechnungen und andere Finanzdaten (mit der Ausnahme solcher, die in geprüften Jahresabschlüssen veröffentlicht wurden), sämtliche Preis- und Rabattstrukturen (sei dies schriftlich oder elektronisch oder anderweitig erfasst) („vertrauliche Informationen“). Solche vertraulichen Informationen bleiben das alleinige und exklusive Eigentum von Lumen und/oder seinen Kunden. Unter keinen Umständen erlangen oder halten Sie jegliches Recht, Anteil oder Anspruch auf solche vertraulichen Informationen.",
  "app.terms.line6": "Während Ihrer Teilname an einer ViewPoints-Umfrage oder zu einem beliebigen Zeitpunkt nach Abschluss Ihrer Teilnahme (mit unbegrenzter Gültigkeit) ist es Ihnen untersagt, jegliche vertrauliche Informationen an eine beliebige Person weiterzuleiten oder zu kommunizieren, für Ihre eigene Zwecke zu verwenden oder infolge Vernachlässigung Ihrer Sorgfaltspflicht unerlaubt zu veröffentlichen. Sie erklären sich damit einverstanden, jederzeit nach besten Kräften bemüht zu sein, die Veröffentlichung oder Offenlegung jeglicher vertraulicher Daten zu verhindern. Diese Einschränkungen gelten nicht weiter für Informationen, die der allgemeinen Öffentlichkeit anderweitig, nicht aufgrund einer Nichterfüllung der Bestimmungen Ihrerseits, zugänglich werden.",
  "app.terms.line7": "3. Eigentum von Lumen",
  "app.terms.line8": "Die ViewPoints-Software und sämtliche Daten, Codes, Schlüssel, Passwörter, Designs, Skizzen und andere Dokumente oder Material in Zusammenhang mit der Software sind und bleiben während Ihrer Teilnahme an der ViewPoints-Umfrage jederzeit Eigentum von Lumen. Lumen ist Inhaber aller Rechte an geistigem Eigentum oder Eigentumsrechte an jeglichen und allen bestehenden und zukünftigen oder gewerblichen Schutzrechten an und betreffend jegliche Dokumente, Material, Modelle, Designs, Zeichnungen, Verfahren, Erfindungen, Formeln, Computercodierung, Daten, Systemschnittstellen, Vorgehensweisen, Know-How, vertrauliche Informationen oder andere Arbeit, die von Ihnen entweder alleine oder mit einer beliebigen anderen Person in Verbindung mit oder in Zusammenhang mit der Software und/oder dem Gerät verrichtet, erstellt, geschaffen, konzipiert, entwickelt, verbessert, dekonstruiert oder entdeckt wurden, bzw. die möglicherweise darin oder in Verbindung damit (ob eingetragen oder nicht) verwendet oder adaptiert werden können, einschließlich alle bestehenden und zukünftigen Patente, Urheberrechte, Designrechte, Datenbankrechte, Handelsmarken, Halbleiter-Topgraphierechte, Sortenrechte, Internetrechte/Domainnamen, Know-How und sämtliche Anwendungen für jegliche der oben Genannten sowie sämtliche Rechte, die für das Vorstehende gelten („Eigentumsrechte“). Zu keinem Zeitpunkt werden Eigentumsrechte auf Sie übertragen.",
  "app.terms.line9": "4. Nutzungsbedingungen und Anwendungsbeschränkungen",
  "app.terms.line10": "Die ViewPoints-Software ist für Endbenutzer zugelassen. Gemäß den Bestimmungen in dieser Vereinbarung erklären wir hiermit, Ihnen die Nutzung der ViewPoint-Software auf einer nicht-exklusiven, widerrufbaren, nicht-übertragbaren, nicht-lizenzierbaren, beschränkten Grundlage zu erlauben, auf einem Gerät, das Sie besitzen oder bedienen oder auf einem PC, für den Sie angeben und gewährleisten, dass Sie über alle nötigen Rechte und Zulassungen für eine solche Installation verfügen. Zur Vermeidung von Missverständnissen: Es ist Ihnen nicht erlaubt, die Software auf jegliche Weise und für irgendwelche Zwecke außerhalb von Viewpoints zu übersetzen, zu kopieren, nachzubilden, via Telekommunikation zu übermitteln, zu adaptieren, zu fusionieren, zu verändern oder zu modifizieren.",
  "app.terms.line11": "Sie stimmen zu, Folgendes zu unterlassen: (i) jegliche Rechte an der Software zu kopieren, zu modifizieren, verfügbar zu machen, zu vermieten, zu leasen, ein Teilnutzungsrecht anzubieten, ein abgeleitetes Produkt davon zu erstellen oder zu versuchen, ein beliebiges Recht an der Software zu verkaufen bzw. anderweitig zu übertragen (ii) jegliche Bestandteile der Software auf jegliche Weise abzuändern, zu dekompillieren oder rückzuentwickeln; oder Dritten zu erlauben oder diese dabei zu unterstützen, den Quellcode der Software abzuleiten oder dies zu versuchen bzw. die Software abzuändern, zu entschlüsseln, zu dekompillieren oder rückzuentwickeln; oder jegliche Schritte zu unternehmen, um Designinformationen der Software abzuleiten; (iii) die Software für jegliche Zwecke zu verwenden, die nicht dem Zweck entsprechen, für den diese Ihnen ursprünglich bereitgestellt wurde; (iv) Spiders, Robots oder andere automatische Datenerfassungsmethoden zu verwenden, um die Software zu katalogisieren, herunterzuladen, zu speichern oder anderweitig zu vervielfältigen bzw. zu vertreiben oder um die Ergebnisse einer beliebigen Umfrage oder anderer Aktivität zu manipulieren; (v) jegliche Schritte zu unternehmen, um die Software oder die Nutzung der Software durch eine Einzelperson zu beeinträchtigen, einschließlich aber ohne Beschränkung auf Überladen, Mail-Flooding, Mail-Bombardierung oder mutwilliges Herbeiführen eines Fehlschlagens der Software und des Datenspeichersystems, das die Software über die Amazon Web Services nutzt (vi) uns jegliche illegale, irreführende oder schädliche Codes zu senden, einschließlich aber ohne Beschränkung auf beliebige Viren, Spyware, Adware oder sonstige schädliche Codes.",
  "app.terms.line12": "Wir behalten uns und unseren Lizenzgebern alle Rechte vor, die in dieser Vereinbarung nicht ausdrücklich gewährt werden. Sie stimmen zu, dass wir keine Verpflichtungen gemäß dieser Vereinbarung haben, jegliche Fehler, Mängel oder Irrtümer in der Software zu beheben bzw. die Software anderweitig zu unterstützen, instandzuhalten, zu verbessern, zu modifizieren, aufzurüsten, zu aktualisieren oder zu erweitern. Wir tragen keine Verantwortung, Ihnen und Ihren Bevollmächtigten auf jegliche Art und Weise Unterstützung in Bezug auf die Nutzung der Software bereitzustellen.",
  "app.terms.line13": "Wir sind nicht haftbar für jegliche Schäden auf Ihrem PC, einschließlich aber nicht beschränkt auf Schäden infolge der Softwarenutzung. Wir sind nicht haftbar für zusätzliche Datengebühren, die Ihnen möglicherweise infolge der durch die Software erhobenen Daten im Rahmen Ihrer Teilnahme an der Forschung anfallen.",
  "app.terms.line14": "5. Haftungsausschluss",
  "app.terms.line15": "Wir und unsere jeweiligen Geschäftsführer, Amtsträger, Angestellten, Agenten, Vertreter, Lizenzgeber und Dienstleister machen keine Zusicherung bzw. geben keine Gewährleistung oder Garantie, (a) dass die Software zeitnah verfügbar bzw. der Zugriff auf die Software ununterbrochen oder sicher ist; (b) dass Mängel oder Fehler berichtigt werden; oder (c) dass die Software oder die Server oder die Netzwerke, über die die Software und das Gerät verfügbar gemacht werden, sicher oder frei von Viren oder anderen schädlichen Komponenten sind. Wir sind nicht verpflichtet, jegliche Instandhaltungs- oder Support-Dienstleistungen im Zusammenhang mit der Software bereitzustellen.",
  "app.terms.line16": "6. Haftungsbeschränkung",
  "app.terms.line17": "(a) Vorbehaltlich der geltenden Rechtsvorschriften erklären Sie sich einverstanden, dass wir und unsere jeweiligen Geschäftsführer, Amtsträger, Angestellten, Agenten, Vertreter, Lizenzgeber und Dienstleister keinerlei Haftung übernehmen für jegliche oder sämtliche Verluste, Schäden oder Kosten beliebiger Art, die sich ergeben aus der Installation, Nutzung oder dem Zugriff auf die Software Ihrerseits, Ihrer Nutzung oder Unfähigkeit, diese zu verwenden, jeglichen Informationen oder Material, die auf oder über die Software bereitgestellt werden und/oder die anderweitig mit der Software oder anderweitig mit dieser Vereinbarung im Zusammenhang stehen, ohne Rücksicht auf die Natur des legalen, gleichberechtigten oder gesetzlichen Rechts, gegen das vermeintlich verstoßen wurde.",
  "app.terms.line18": "(b) Vorbehaltlich der geltenden Rechtsvorschriften besteht Ihr einziges Recht und Rechtsmittel in Bezug auf jegliche Unzufriedenheit darin, die Software zu deinstallieren und nicht weiter zu verwenden.",
  "app.terms.line19": "(c) Unter keinen Umständen haften unsere juristischen Personen, Geschäftsführer, Amtsträger, Angestellten, Agenten, Vertreter für jegliche zufällige, indirekte, folgende Schäden, gesetzlichen, exemplarischen oder Strafschadenersatz, Einnahmeverluste, entgangene Geschäftsmöglichkeiten, Ansehensverlust, Nutzungsausfall, Datenverlust, Verlust anderer immaterieller Werte, oder das unbefugte Abfangen von Daten, die mittels der Software durch Drittparteien erhoben wurden, ob vorhersehbar oder nicht und unabhängig von der Ursache und unter jeglichem Vertrag, Schadenersatzrecht (einschließlich, ohne Beschränkung auf, Fahrlässigkeit), Gefährdungshaftung oder anderen Rechtstheorien, selbst wenn im Voraus auf die Möglichkeit solcher Schäden hingewiesen wurde.",
  "demo.index.content.line1": "In dieser Demo werden wir eine Kamera verwenden, die erfasst, worauf Sie schauen. Wir machen dabei keine Fotos oder Video-Aufnahmen.",
  "app.error.previousfail": "Sie haben diese Umfrage zuvor nicht erfolgreich durchgeführt. Bitte klicken Sie den ursprünglichen Link an, um noch einmal von Anfang an zu beginnen.",
  "app.error.previoussuccess": "Sie haben die Befragung bereits abgeschlossen.",
  "app.error.route": "Sie haben versucht, eine Umfrage zu starten, die bereits in Bearbeitung ist. Bitte klicken Sie den ursprünglichen Link an, um noch einmal von Anfang an zu beginnen.",
  "app.error.accesscode": "Sie haben versucht, eine Umfrage zu starten, die nicht existiert. Bitte überprüfen Sie Ihren Link.",
  "app.error.webcam": "Für diese Umfrage ist eine Webcam erforderlich.",
  "app.error.adblocker": "Für diese Umfrage muss jegliche Werbeblocker-Software deaktiviert werden.",
  "app.error.startsession": "Befragung kann nicht gestartet werden",
  "app.error.fullscreen": "Für diese Umfrage ist der Vollbildmodus erforderlich.",
  "app.error.zoom": "Für diese Umfrage muss der Zoomfaktor des Browsers auf 100 % eingestellt sein.",
  "app.index.webcamInstructions": "Wir sind dabei, den Zugriff auf Ihre Webcam anzufordern. Wenn Sie ein Popup-Fenster erhalten, klicken Sie bitte auf Zulassen.",
  "app.setup.retryBack": "Bitte drücken Sie während dieser Studie nicht die Zurück-Taste des Browsers, da dies zu einem Verlust der Belohnung führen kann. Verwenden Sie die Schaltfläche unten, um es erneut zu versuchen.",
  "app.index.zoomRetry": "Der Browser muss auf 100 % Zoom eingestellt sein. Bitte verkleinern/vergrößern Sie das Zoomverhältnis und versuchen Sie es erneut",
  "app.index.fullscreendesktopretry": "Für diese Studie ist Vollbildanzeige erforderlich. Bitte sorgen Sie dafür, dass Ihr Browserfenster maximiert ist",
  "app.index.fullscreendesktoptest": "Für diese Studie muss Ihr Browserfenster auf Vollbildanzeige eingestellt sein. Es muss ein Test durchgeführt werden, um zu gewährleisten, dass Ihr Browserfenster maximiert ist",
  "app.setup.retryPositioning": "Die Kopfposition konnte nicht bestimmt werden. Halten Sie Ihren Kopf und das Gerät bitte still und sorgen Sie dafür, dass die Kamera nicht versehentlich verdeckt wird (zum Beispiel mit Ihrem Finger). Über die nachstehende Schaltfläche können Sie es erneut versuchen.",
  "app.setup.retryBroker": "Der Kalibrierungsprozess konnte nicht abgeschlossen werden. Bitte versuchen Sie, alle ungenutzten Apps und Registerkarten zu schließen, um Ressourcen freizugeben",
  "app.error.missingpanelparameter": "Fehlende Panel-Parameter.",
  "app.completion.congratulations-noet": "Glückwunsch! Sie haben die Studie abgeschlossen.",
  "app.thankyou": "Danke",
  "app.button.return": "Zurück",
  "app.index.content.line1gpdrwarning": "Während dieser Umfrage werden wir die Frontkamera Ihres Geräts verwenden, um Ihre Bilder und Videos aufzunehmen, während Sie verschiedene Aufgaben durchführen, bei denen Sie Stimuli betrachten.",
  "app.index.fullscreendesktoptest2": "In Kürze werden zwei Schaltflächen erscheinen, bitte klicken Sie darauf, um fortzufahren",
  "app.index.fullscreendesktopmaximised": "Mein Bildschirm ist bereits maximiert",
  "app.index.fullscreendesktopskip": "Danke, wir haben diesen Fall aufgenommen und werden unsere Erkennungsprozesse verbessern",
  "app.index.fullscreendesktopreset": "Wir haben eine Änderung der Fenstergröße festgestellt und den Test zurückgesetzt.",
  "app.index.gotomobile": "Desktop-Benutzer/-innen sind für diese Studie nicht zugelassen. Bitte verwenden Sie den unten stehenden QR-Code, um die Studie auf einem Mobilgerät zu öffnen.",
  "app.index.returntopanel": "ODER klicken Sie auf die Schaltfläche unten, um zum Panel zurückzukehren",
  "app.setup.instruction3audio": "Bitte hören Sie gut zu und folgen Sie den Anweisungen auf dem Bildschirm.",
  "app.error.cellquotafullnoactivecell": "Es wurde keine aktive Zelle für diese Studie gefunden, die Zellenquoten sind voll.",
  "app.faq.gdpr.intro1": "In dieser Studie werden wir die Frontkamera eines Mobilgeräts verwenden, um Bilder und Videoaufnahmen von Benutzer/-innen zu machen, während sie gebeten werden, verschiedene Aufgaben durchzuführen, bei denen sie Stimuli unter einer Reihe von Umgebungsbedingungen (z. B. Beleuchtung und Positionierung) anschauen. Die Teilnahme wird voraussichtlich etwa 5 Minuten dauern. Die Daten sollen dazu dienen, die Weiterentwicklung von Software zu unterstützen, die abschätzen kann, wohin Benutzer/-innen auf einem Bildschirm schauen.",
  "app.faq.gdpr.intro2": "Diese Studie wird in Übereinstimmung mit dem MRS-Verhaltenskodex und dem Data Protection Act 2018 durchgeführt. Ihre Privatsphäre ist uns wichtig. Bevor Sie an dieser Studie teilnehmen, müssen Sie bestätigen, dass Sie durch Ihre Teilnahme an dieser Studie verstehen, welche Daten wir erheben und wie sie verwendet werden, wie in diesen Datenschutzbestimmungen beschrieben, und dass Sie damit einverstanden sind, dass diese Daten auf diese Weise verwendet werden. In diesen Bestimmungen wird genau beschrieben, welche Daten erhoben werden und wie sie verwendet und weitergegeben werden.",
  "app.faq.gdpr.question1": "Welche Daten werden erhoben?",
  "app.faq.gdpr.answer1.line1": "Im Rahmen dieser Studie werden die folgenden Arten von Daten erhoben:",
  "app.faq.gdpr.answer1.line2": "Sitzungscharakteristik' – Eine anonyme ID, die zur Identifizierung einer bestimmten Datenerhebungs sitzung verwendet wird, sowie Daten über die vorherrschenden Umwelttestbedingungen während der Sitzung.",
  "app.faq.gdpr.answer1.line3": "Teilnehmendenmerkmale – Daten über eine befragte Person, die an einer bestimmten Sitzung teilnimmt, einschließlich Informationen über die demografischen (z. B. Alter, Geschlecht), ethnischen (z. B. Hauttyp) und biologischen (z. B. Augenfarbe) Merkmale der Teilnehmenden.",
  "app.faq.gdpr.answer1.line4": "Videodatei – Bilder und/oder Videoaufnahmen, die mit der Frontkamera des Geräts gemacht werden, um Bilder der Teilnehmenden aufzunehmen, während sie die auf einem oder mehreren Bildschirmen angezeigten Stimuli betrachten und möglicherweise mit ihnen interagieren. Es werden Bilder der Augen und des Gesichts der Teilnehmenden sowie alles andere aufgenommen, was unbeabsichtigt von der nach vorne gerichteten Kamera erfasst werden kann, während die Aufgabe ausgeführt wird.",
  "app.faq.gdpr.answer1.line5": "Metadaten – Daten über den Zeitpunkt, zu dem die Personen mit den Stimuli interagiert haben, verknüpft mit dem Zeitstempel jedes erfassten Bildes.",
  "app.faq.gdpr.answer1.line6": "Physikalische Gerätedaten – Physikalische Daten über das Gerät, einschließlich des Gerätenamens, der Bildschirmgröße, der Bildschirmdrehung und der Drehung des Kamerasensors.",
  "app.faq.gdpr.answer1.line7": "Durch Ihre Teilnahme an dieser Studie verstehen Sie und erklären sich damit einverstanden, dass die Inhalte, die Ihnen in dieser Studie gezeigt werden, vertraulich sein können, das Eigentum Dritter sein können und/oder dem Marken- oder Urheberrecht unterliegen, und dass Sie keine Screenshots anfertigen, Inhalte aufzeichnen oder anderweitig kopieren oder Inhalte der Studie in irgendeiner Weise weiterverbreiten werden.",
  "app.faq.gdpr.question2": "Wie werden die Daten verwendet/verarbeitet?",
  "app.faq.gdpr.answer2": "Die Daten werden für die Entwicklung von Software verwendet, die abschätzen kann, ob Nutzende vor einem Gerät anwesend sind, ob sie auf Stimuli schauen und wohin auf den Stimuli sie schauen. Sie können auch für Demonstrationszwecke oder zur Erklärung oder zur Unterstützung der Einschätzung von Dritten (Kunden, Lieferanten, Partnern) verwendet werden, wie unsere Technologie entwickelt wurde und wie sie funktioniert. Die Daten können von einer beliebigen Anzahl von Daten-Annotatoren – Mitarbeitenden oder Unterauftragnehmenden oder Partnern oder anderen Dritten – überprüft werden, die gebeten werden, sich das Videomaterial/die Bilder anzusehen, um den Aufmerksamkeits„zustand der Teilnehmenden während der Studie zu bewerten (z. B. Sind sie im Sichtfeld der Kamera anwesend? Schauen sie auf den Bildschirm?) Die Rechtsgrundlagen für die Verarbeitung der Daten sind die informierte Zustimmung und die berechtigten Interessen.",
  "app.faq.gdpr.question3": "Wie lange werden die Daten aufbewahrt?",
  "app.faq.gdpr.answer3": "Die Daten werden von uns so lange aufbewahrt, wie es für die oben beschriebenen Zwecke vernünftigerweise erforderlich ist. Die entwickelte Software wird regelmäßig aktualisiert und evaluiert, und diese Daten werden zur Evaluierung jeder Iteration verwendet, so dass davon ausgegangen wird, dass sie zu diesem Zweck mehrere Jahre, wenn nicht sogar unbegrenzt, aufbewahrt werden. Die Daten werden gelöscht, wenn sie für die Entwicklung oder Evaluierung der Software zur Prüfung der Stimuli als nicht mehr nützlich erachtet werden.",
  "app.faq.gdpr.question4": "Wer erhält und verarbeitet die Daten?",
  "app.faq.gdpr.answer4": "Die Daten werden von Lumen Research Ltd. gespeichert und aufbewahrt.  Lumen Research Ltd ist ein in England eingetragenes Unternehmen mit der Firmennummer 8682432.  Ihr eingetragener Sitz befindet sich in LABS House, 15-19 Bloomsbury Way, Holborn, London WC1A 2TH. Die Registrierungsnummer für den Datenschutz lautet ZA130772.  Ihr Datenschutzbeauftragter ist Matt Robinson (matt.robinson@lumen-research.com).  Sie haben das Recht, jederzeit eine Beschwerde über die Verarbeitung Ihrer Daten einzureichen, indem Sie sich an die britische Datenschutzbehörde, das Information Commissioner’s Office, wenden.  Die Kontaktdaten des ICO finden Sie auf dessen Website unter https: //ico.org.uk/global/contact-us/. Mit der Teilnahme an der Studie gewähren Sie Lumen eine weltweite, kostenlose, unterlizenzierbare und übertragbare Lizenz zur Aufnahme, Speicherung, Nutzung, Darstellung, Vervielfältigung, Änderung, Anpassung, Bearbeitung, Veröffentlichung und Verbreitung dieser Daten.",
  "app.faq.gdpr.question5": "Habe ich ein Recht auf Zugriff zu meinen personenbezogenen Daten?",
  "app.faq.gdpr.answer5": "Ja, Sie haben das Recht, auf Ihre persönlichen Daten zuzugreifen, aber es ist wichtig zu wissen, dass Ihr Name nicht zusammen mit den im Rahmen dieser Aktion erhobenen Daten gespeichert wird. Um Ihre Datensätze zu identifizieren, müssen Sie uns die genaue Uhrzeit und das Datum nennen, an dem Sie an der Studie teilgenommen haben, und wir müssen Sie um eine visuelle Identifizierung bitten, um sicherzustellen, dass die Datensätze korrekt zugeordnet werden.",
  "app.faq.gdpr.question7": "Habe ich das Recht, meine Zustimmung zur Verarbeitung meiner personenbezogenen Daten zu widerrufen oder die Löschung meiner personenbezogenen Daten zu verlangen?",
  "app.faq.gdpr.answer7": "Ja, Sie haben das Recht, Ihre Zustimmung zur Verarbeitung Ihrer personenbezogenen Daten zu widerrufen und die Löschung dieser Daten zu verlangen. Es ist jedoch wichtig zu wissen, dass Ihr Name nicht zusammen mit den im Rahmen dieser Aktion gesammelten Daten gespeichert wird. Um Ihren Datensatz zu identifizieren, müssen Sie uns die genaue Uhrzeit und das Datum mitteilen, an dem Sie an der Studie teilgenommen haben, und wir werden Sie um eine visuelle Identifizierung bitten, um sicherzustellen, dass die Datensätze korrekt zugeordnet werden.",
  "app.faq.gdpr.question8": "Woher weiß ich, dass meine personenbezogenen Daten sicher sind?",
  "app.faq.gdpr.answer8": "Die von Lumen Research gesammelten Daten werden mit der Secure Sockets Layer (SSL)-Technologie verschlüsselt. Dies ist ein allgemein verwendeter hoher Standard der Verschlüsselungstechnologie. Diese Technologie wird sowohl für die Sicherung der Übertragungskanäle als auch für die endgültige Speicherung verwendet.",
  "app.faq.gdpr.question9": "Wie werden wir die von uns erhobenen Daten verwenden und weitergeben?",
  "app.faq.gdpr.answer9": "Wir können die von uns erhobenen Daten an andere Drittanbieter weitergeben, damit diese für uns Dienstleistungen erbringen können. Wir können auch im Falle einer Umstrukturierung, einer Fusion, eines Verkaufs, eines Joint Ventures, einer Abtretung, einer Übertragung oder einer anderen Verfügung über unser gesamtes Geschäft, unsere Vermögenswerte oder Aktien (einschließlich in Verbindung mit einem Konkurs- oder ähnlichen Verfahren) Daten an Dritte weitergeben. Wir können die Daten, die wir von Ihnen und über Sie erheben, auch verwenden oder weitergeben, wenn wir dies für notwendig oder angemessen halten: (a) gemäß geltendem Recht, einschließlich Gesetzen außerhalb des Landes, in dem Sie Ihren Wohnsitz haben; (b) zur Einhaltung rechtlicher Verfahren; (c) zur Beantwortung von Anfragen öffentlicher und staatlicher Behörden, einschließlich öffentlicher und staatlicher Behörden außerhalb des Landes, in dem Sie Ihren Wohnsitz haben; (d) zur Durchsetzung unserer Endbenutzer-Lizenzvereinbarung; (e) um unseren Betrieb oder den Betrieb einer unserer Tochtergesellschaften zu schützen; (f) um unsere Rechte, unsere Privatsphäre, unsere Sicherheit oder unser Eigentum und/oder das unserer Tochtergesellschaften, Ihrer Person oder anderer Personen zu schützen; (g) um es uns zu ermöglichen, verfügbare Rechtsmittel einzulegen oder den Schaden zu begrenzen, den wir erleiden könnten; und (h) um Betrug oder Sicherheitsprobleme aufzudecken und zu beheben.",
  "app.faq.gdpr.question10": "Welche Maßnahmen werden ergriffen, um die Daten sicher aufzubewahren?",
  "app.faq.gdpr.answer10": "Wir ergreifen angemessene Maßnahmen zum Schutz Ihrer Daten, indem wir physische, elektronische oder verfahrenstechnische Sicherheitsmaßnahmen ergreifen, die der Sensibilität der in unserem Gewahrsam oder unter unserer Kontrolle befindlichen Daten angemessen sind und die Schutzmaßnahmen zum Schutz vor Verlust oder Diebstahl sowie vor unbefugtem Zugriff, Offenlegung, Kopieren, Verwendung oder Änderung umfassen.",
  "app.setup.retryaudible": "Vergewissern Sie sich, dass Ihr Gerät nicht stummgeschaltet ist, und versuchen Sie es erneut.",
  "app.restore.retry": " Sie haben nicht genug Zeit für den vorherigen Schritt aufgewendet",
  "app.restore.timeremaining": "Minuten übrig ",
  "app.error.postexposure": "Tut mir leid, Sie haben jetzt alle Ihre Versuche verbraucht und können leider nicht mit der Studie fortfahren.",
  "app.generic.screenshotfail": "Bitte machen Sie einen Screenshot dieser Seite als Beweis für fehlende Eingabe. Sie werden bald umgeleitet.",
  "app.generic.screenshotsuccess": "Bitte machen Sie einen Screenshot dieser Seite als Abschlussnachweis. Sie werden bald umgeleitet.",
  "app.instructions.instruction7nav": "Verwenden Sie Ihren Finger, um sich auf dem Bildschirm in eine beliebige Richtung zu bewegen.",
  "app.error.browsernotallowed": "Browser nicht erlaubt."
}