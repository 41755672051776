{
  "title": "﻿Lumen Studie Plattform - Integritetspolicy",
  "date": "Juli 2022",
  "contactDetails": {
    "title": "Vår kontaktinformation ",
    "body": [
      "Namn: Lumen Research Ltd (”Lumen”)",
      "Adress: Screenworks, Unit 215, 22 Highbury Grove, London N5 2ER",
      "Telefonnummer: +44(0)2037355199 ",
      "E-post: dataprotection@lumen-research.com",
      "ICO Registrering Referens: ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "Denna integritetspolicy förklarar vilken typ av information vi samlar in från deltagare som deltar i denna ögonspårningsstudie på Lumen Studie Plattformen, och vår policy som styr den information som kan komma att samlas in. "
      ],
      "heading": "Introduktion"
    },
    {
      "body": [
        "I en typisk studie samlar Lumen inte in någon personlig information enligt definitionen i Storbritanniens Allmänna Dataskyddsreglering, men vi samlar in blickpunktsuppskattningar; frågeformulärsvar; metadata om när och på vilken enhetstyp som insamlingen skedde; och respondenters ID i syfte att administrera belöningar.",
        "I en typisk undersökning kommer du att bli ombedd att sitta framför din enhets webbkamera eller framåtriktade kamera och utföra en kalibrering så att en uppskattning kan göras av var du tittar på din skärm. Under studien kommer du att bli ombedd att titta på några objekt (t.ex. bilder, webbsidor eller videor) medan studieverktyget gör en uppskattning av var du tittar. Data som fångas in från denna ‘ögonspårning’ är koordinaterna för de uppskattade blickpunkterna på din skärm över tid. Mjukvaran använder din webbkamera för att göra dessa uppskattningar men tar inga foton eller videor av dig. ",
        "Det kan även hända att du blir ombedd att besvara några frågor om dig själv, din erfarenhet och objekten du har sett under studien. ",
        "Data från dessa undersökningar skickas till Lumen Research Ltd (“Lumen”). Lumen kommer att analysera informationen och delar resultaten med sina uppdragsgivare. ",
        "I dessa studier kommer dessutom ett alfanumeriskt respondent-ID att skickas av en tredjepart till Lumen, så att Lumen kan spåra vilka respondenter som slutför studien. Lumen kommer att dela denna information med tredje parter i syfte att administrera belöningar till de som slutför studien. "
      ],
      "heading": "Typen av information vi samlar in"
    },
    {
      "body": [
        "Ibland kan respondenter bli ombedda att delta i forskningsprojekt som kräver mer än en period av datainsamling. I dessa fall kommer respondent-ID:t att användas som en bestående identifierare för att kontakta en unik deltagare igen i syfte att initiera deras deltagande i eventuell efterföljande datainsamling. I sådana fall, kommer respondent-ID:t att betraktas som personlig information.  "
      ],
      "heading": "Typen av information vi samlar in "
    },
    {
      "body": [
        "Som med en typisk undersökning, kommer ett bestående respondent-ID att föras vidare till oss via en tredje part som har rekryterat deltagaren för vår forskning. Vi kommer att använda detta för att kontakta individer igen för att initiera deras efterföljande deltagande i undersökningen och kan komma att dela detta med tredje parter för att underlätta sådant deltagande.",
        "Enligt Storbritanniens allmänna dataskyddsförordning (UK GDPR), är de lagliga grunderna för att behandla denna information ditt samtycke. Du kan när som helst dra tillbaka ditt samtycke. Du kan göra detta genom att kontakta oss på dataprotection@lumen-research.com"
      ],
      "heading": "Hur vi samlar in personuppgifterna och varför vi har dem"
    },
    {
      "body": [
        "Dina uppgifter lagras säkert och krypteras med hjälp av SSL-teknologi. Detta är den vanligaste använda högsta standarden av kryptering och används av banker för att säkra banktransaktioner och annan mycket känslig kommunikation. Det används också av Amazon, PayPal, eBay, och andra webbsidor som kräver säkerhetssystem på banknivå. Experter uppskattar att det skulle ta betydligt längre tid än universums ålder för att knäcka 256-bitars kryptering med dagens teknik. Insamlade data lagras på säkra webbservrar som förvaltas av Amazon Web Services (AWS). AWS är en av de mest använda, säkra och pålitliga plattformarna för molntjänster och används av stora molnbaserade kommersiella tjänster såsom Netflix och Expedia. ",
        "Vi behåller bestående identifierare under den tidsperiod som krävs för att kontakta respondenter igen i flerstegsstudier. Detta kan variera beroende på typen av forskning och du kommer att meddelas när du ger ditt samtycke till att delta. När datainsamling för sådana projekt har avslutats kommer identifierare enbart att behållas i den mån det krävs för att analysera och rapportera data. När analys och rapportering av projektet har avslutats kommer vi att kassera dessa personuppgifter genom att radera dem från våra databaser."
      ],
      "heading": "Hur vi lagrar dina personuppgifter "
    }
  ],
  "dataProtection": {
    "body": ["Under dataskyddslagar, inkluderar dina rättigheter:"],
    "bullets": [
      {
        "title": "Rätt till åtkomst",
        "text": ["Du har rätten att be oss om kopior av dina personuppgifter. "]
      },
      {
        "title": "Din rätt till rättelse",
        "text": [
          "Du har rätten att be oss korrigera personuppgifter som du anser är inkorrekta. Du har även rätten att be oss komplettera information som du anser är ofullständig. "
        ]
      },
      {
        "title": "Din rätt att radera",
        "text": [
          "Du har rätt att be oss radera dina personuppgifter under vissa omständigheter. "
        ]
      },
      {
        "title": "Din rätt att begränsa behandling",
        "text": [
          "Du har rätt att be oss begränsa behandlingen av dina personuppgifter under vissa omständigheter. "
        ]
      },
      {
        "title": "Din rätt att dra tillbaka samtycke",
        "text": [
          "Du har rätt att dra tillbaka ditt samtycke till behandlingen av dina personuppgifter."
        ]
      },
      {
        "title": "Din rätt till dataportabilitet",
        "text": [
          "Du har rätt att be oss överföra de personuppgifter som du gett oss till en annan organisation eller till dig under vissa omständigheter.",
          "Du behöver inte betala någon avgift för att du utövar dina rättigheter. Om du gör en begäran, har vi en månad på oss att svara dig.",
          "Vänligen kontakta oss på dataprotection@lumen-research.com Om du vill göra en begäran."
        ]
      }
    ],
    "title": "Dina dataskyddsrättigheter"
  },
  "complaints": {
    "body": [
      "Om du har några frågor om vår användning av dina personuppgifter kan du lämna in ett klagomål till oss på dataprotection@lumen-research.com."
    ],
    "address": {
      "body": [
        "Du kan även lämna in ett klagomål till Information Commissioner's Office (ICO)",
        "Information Commissioner’s Office",
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ],
      "title": "ICO:s adress:            "
    },
    "title": "Hur man lämnar in klagomål",
    "number": "Hjälplinjenummer: 0303 123 1113",
    "website": "ICO:s webbsida: https://www.ico.org.uk"
  }
}
