import React from "react";
import { Col, Row } from "react-bootstrap";
import Retry from "../../layouts/Retry";

const BackLanding = ({continueFunc}) => {
  const handleClick = () => {
    continueFunc()
  }

  return (
    <div className="ls-back-pressed">
      <Row>
        <Col xs={12}>
          <div className="ls-main">
            <Retry
              text="Please refrain from pressing the browser back button throughout this study, it may result in a loss of reward. Use the button below to retry."
              id="app.setup.retryBack"
              onClick={handleClick}
            ></Retry>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BackLanding;
