export default (asyncFunc, args) => {
    let rejector 
    let resolver
    let promise = new Promise(async (resolve, reject) => {
        let cancelled = false

        rejector = (reason) => {
            cancelled = true
            reject(reason)
        }

        resolver = (payload) => {
            if(!cancelled){
                resolve(payload)
            }
        }

        await asyncFunc(...args)
    })

    return [resolver, rejector, promise]
}