import * as DEBUGLOG from "../../../components/util/debugLog";
import requestTimer from '../../requestTimer';
import tabChangeListener from '../../../utils/listeners/TabChangeListener';
import participationUrl from '../participationUrl';

const getPanelDetails = async (studyId, body) => {
    tabChangeListener.setWaitingForApi("getPanelDetails");
    let startPanelRequestDate = DEBUGLOG.debugLogRequestStart("get-panel-details");
    let response = await fetch(participationUrl() + "/participation/studies/" + studyId + "/get-panel-details", { method:'POST', body: JSON.stringify(body)})
    DEBUGLOG.debugLogRequestFinish(startPanelRequestDate);
    
    if (response.ok) {
        let responseBody = await response.json()
        tabChangeListener.setWaitingForApi("");
        return responseBody
    } else {
      let e = new Error(response.statusText);
      e.name = response.status || "";
      throw e;
    }
}

export default (...args) => requestTimer(() => getPanelDetails(...args))