import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import LSMenuCont from "../components/menu/containers/menuCont";
import anime from "animejs";
import { useRef } from "react";
import { store } from "../store";
import { changeHeaderFooterState } from "../reducers/PageState/actions";

const Header = () => {
  let headerRef = useRef();
  let { headerState } = useSelector((state) => state.pageReducer);

  if (headerRef && headerState === "transitionIn") {
    anime({
      targets: headerRef.current,
      height: window.innerWidth <= 812 ? "20px" : "70px",
      duration: 200,
      easing: "linear",
      elasticity: "none",
      complete: () => {
        store.dispatch(changeHeaderFooterState("show"));
      },
      delay:500,
      endDelay:500,
    });
  } else if (headerRef && headerState === "transitionOut") {
    anime({
      targets: headerRef.current,
      height: "0px",
      duration: 200,
      easing: "linear",
      elasticity: "none",
      complete: () => {
        store.dispatch(changeHeaderFooterState("hide"));
      },
      delay:500,
      endDelay:500,
    });
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const handleResize = () => {
    if (headerRef && headerRef.current) {
      if (
        window.innerWidth <= 812 &&
        headerRef.current.style.height === "70px"
      ) {
        headerRef.current.style.height = "20px";
      } else if (
        window.innerWidth > 812 &&
        headerRef.current.style.height === "20px"
      ) {
        headerRef.current.style.height = "70px";
      }
    }
  };

  return (
    <nav  style={{display : headerState === 'hide' ? 'none' : 'block'}}  ref={headerRef} className="ls-header">
      <div className="ls-header-cont">
        <div style={{position:'fixed', width:'100%'}}>
          <LSMenuCont></LSMenuCont>
        </div>
      </div>
    </nav>
  );
};

export default Header;
