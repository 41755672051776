{
  "title": "﻿Plataforma de estudio Lumen - Política de privacidad",
  "date": "Julio de 2022",
  "contactDetails": {
    "title": "Nuestros datos de contacto ",
    "body": [
      "Nombre: Lumen Research Ltd («Lumen»)",
      "Dirección: Screenworks, Unit 215, 22 Highbury Grove, Londres N5 2ER",
      "Teléfono móvil: +44(0)2037355199 ",
      "Correo electrónico: dataprotection@lumen-research.com",
      "Referencia de registro del ICO: ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "Esta política de privacidad explica la naturaleza de la información que recopilamos de las personas que participan en la encuesta de rastreo visual en la plataforma de estudio Lumen, además de la política que regula cualquier información personal que se pueda recopilar. "
      ],
      "heading": "Introducción"
    },
    {
      "body": [
        "En una encuesta normal, Lumen no recopila información personal según se define en el Reglamento General de Protección de Datos de Reino Unido, pero recopilará estimaciones de puntos de observación, respuestas a las encuestas, metadatos sobre cuándo y en qué tipo de dispositivos se ha hecho la recopilación de datos y las identificaciones de los encuestados con el fin de gestionar las compensaciones.",
        "En una encuesta normal le pediremos que se siente frente a un dispositivo con cámara web mirando hacia la cámara y que la calibre para poder estimar hacia dónde mira en la pantalla. En el transcurso de la encuesta, le pediremos que observe algunos objetos (por ej., imágenes, sitios web o vídeos) mientras la herramienta de la encuesta calcula hacia dónde está mirando. Los datos recopilados de este rastreo visual son las coordenadas de los puntos de observación estimados en su pantalla a medida que transcurre el tiempo. El software utiliza imágenes suyas de su cámara web para realizar estas estimaciones, pero no captura fotografías ni vídeos. ",
        "Además, le formularemos algunas preguntas sobre usted, sobre su experiencia y sobre los objetos que ha visto durante la encuesta. ",
        "Los datos de estas encuestas se envían a Lumen Research Ltd (en lo sucesivo, «Lumen»). Lumen analizará los datos y compartirá los resultados con sus clientes. ",
        "Además, en estas encuestas un tercero transferirá una identificación alfanumérica del encuestado a Lumen para que Lumen pueda monitorizar qué encuestados han completado la encuesta. Lumen compartirá esta información al finalizar con terceros con el fin de administrar las compensaciones con aquellos que hayan completado la encuesta. "
      ],
      "heading": "Tipo de información personal que recopilamos"
    },
    {
      "body": [
        "En algunos casos, les pediremos a los encuestados que participen en un proyecto de investigación que requiere más de un periodo de recopilación de datos. En este caso, la identificación del encuestado se utilizará como un identificador permanente para volver a contactar con un participante único a fin de que participe en los siguientes ejercicios de recopilación de datos. En tal caso, la identificación del encuestado se considerará información personal.  "
      ],
      "heading": "Tipo de información personal que recopilamos "
    },
    {
      "body": [
        "Como sucede con una encuesta normal, un tercero que ha reclutado a un participante para nuestra investigación transferirá una identificación de encuestado permanente. La utilizaremos para volver a contactar con los individuos a fin de que participen en la siguiente investigación, y puede que se comparta con terceros para facilitar esta participación.",
        "Según el Reglamento General de Protección de Datos de Reino Unido (GDPR, por sus siglas en inglés), las bases legislativas de las que dependemos para procesar esta información es su consentimiento. Puede retirar su consentimiento en cualquier momento. Puede hacerlo escribiendo a dataprotection@lumen-research.com"
      ],
      "heading": "Cómo obtenemos información personal y por qué la tenemos"
    },
    {
      "body": [
        "Su información está encriptada y se almacena con seguridad, utilizando una tecnología de seguridad de la capa de transporte (SSL, por sus siglas en inglés). Este es el estándar alto de tecnología de encriptación de uso más generalizado y los bancos la utilizan para asegurar las transacciones bancarias y otras comunicaciones altamente sensibles. Además, la usa Amazon, PayPal, eBay y otros sitios web que utilizan sistemas de seguridad de nivel bancario. Los científicos estiman que, para romper la encriptación de 256 utilizando la tecnología actual, se necesitaría substancialmente más tiempo que la edad del universo. Los datos recopilados se almacenan en servidores de web seguros gestionados por Amazon Web Services (AWS). AWS es una de las plataformas de servicios informáticos más seguras y con base en la nube más comunes y la utilizan servicios comerciales con base en la nube como Netflix o Expedia. ",
        "Mantenemos los identificadores permanentes durante el periodo de tiempo requerido para volver a contactar con los encuestados para una investigación de varias etapas. Esto podría variar dependiendo de la naturaleza del estudio y se le notificará cuando proporcione su consentimiento para participar. Cuando se haya completado la recopilación de datos para dicho proyecto, se retendrán los identificadores según se requiera para analizar e informar sobre los datos. Una vez que se complete el análisis y el informe de un proyecto, desecharemos esta información personal y la eliminaremos de las bases de datos."
      ],
      "heading": "Cómo almacenamos su información personal "
    }
  ],
  "dataProtection": {
    "body": [
      "Según la ley de protección de datos, tiene los siguientes derechos:"
    ],
    "bullets": [
      {
        "title": "Derecho de acceso",
        "text": ["tiene derecho a pedirnos copias de su información personal."]
      },
      {
        "title": "Derecho de rectificación",
        "text": [
          "tiene derecho a pedirnos que rectifiquemos la información personal que usted considere imprecisa. Además, tiene derecho a pedirnos que completemos la información que usted considere incompleta."
        ]
      },
      {
        "title": "Derecho de eliminación",
        "text": [
          "tiene derecho a pedirnos que eliminemos su información personal en ciertas circunstancias."
        ]
      },
      {
        "title": "Derecho de restricción del tratamiento",
        "text": [
          "tiene derecho a pedirnos que limitemos el tratamiento de su información personal en ciertas circunstancias. "
        ]
      },
      {
        "title": "Derecho a retirar su consentimiento",
        "text": [
          "tiene derecho a retirar su consentimiento para el tratamiento de su información personal."
        ]
      },
      {
        "title": "Derecho a la portabilidad de datos",
        "text": [
          "tiene derecho a pedir que transfiramos la información personal que nos ha proporcionado a otra organización o a usted, en ciertas circunstancias.",
          "No tiene que pagar nada por ejercer sus derechos. Si realiza una petición, tenemos un mes para responderle.",
          "Póngase en contacto con dataprotection@lumen-research.comsi desea realizar una petición."
        ]
      }
    ],
    "title": "Sus derechos de protección de datos"
  },
  "complaints": {
    "body": [
      "Si tiene dudas acerca de cómo utilizamos su información personal, puede presentar una queja y enviarla a dataprotection@lumen-research.com.",
      "También puede enviar una queja a ICO si no está de acuerdo con la manera en la que hemos utilizado sus datos."
    ],
    "address": {
      "body": [
        "Dirección de la Oficina del Comisario de Información (ICO)            ",
        "Information Commissioner’s Office",
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ]
    },
    "title": "Cómo presentar una queja",
    "number": "Asistencia telefónica: 0303 123 1113",
    "website": "Sitio web de ICO: https://www.ico.org.uk"
  }
}
