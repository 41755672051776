{
  "app.thankyou": "감사합니다",
  "app.button.faqs": "FAQ",
  "app.button.terms": "이용 약관",
  "app.button.privacy": "개인정보 처리방침",
  "app.button.feedback": "피드백",
  "app.button.selectLanguage": "언어 선택",
  "app.button.help": "도움말",
  "app.button.start": "시작",
  "app.button.next": "다음",
  "app.button.submit": "제출",
  "app.button.close": "종료",
  "app.button.return": "되돌아가기",
  "app.button.takepart": "OK - 참가하겠습니다",
  "app.menu.feedback.content": "본 연구와 관련된 문제가 있으시거나 의견을 남기고 싶으신 경우, 여기에서 자유롭게 진행해 주십시오.",
  "app.contactModal.feedback": "피드백을 해 주셔서 감사합니다!",
  "app.takePartModal.line1": "지금 설문 조사의 참가를 중단하시면, 참가에 대한 보상을 받으실 수 없습니다.",
  "app.takePartModal.line2": "설문 조사의 참가를 위해 필요한 것은 웹캠뿐입니다!",
  "app.takePartModal.reason1": "나는 웹캠이 없다.",
  "app.takePartModal.reason2": "이 설문 조사를 하기 위해 내 웹캠을 사용하는 것이 불편하다.",
  "app.takePartModal.reason3": "나는 지금 설문 조사를 할 시간이 없다.",
  "app.takePartModal.reason4": "다른 이유.",
  "app.generic.redirect": "곧 다른 페이지로 이동됩니다.",
  "app.init.welcome": "루멘 리서치(Lumen Research) 연구 플랫폼에 오신 것을 환영합니다.",
  "app.init.intro": "본 연구 플랫폼은 특정 광고 연구에 사용자들을 등록하고 설정할 수 있도록 해줍니다.",
  "app.init.message1": "연구 유형에 따라 사용자는 일련의 작업을 수행하도록 안내받게 됩니다.",
  "app.init.message2": "이런 작업에는 설문 조사 참가, 지시 사항 읽고 파악하기,시선 추적을 위한 조정 작업을 수행하는 것이 포함됩니다.",
  "app.init.message3": "이제 시범 설문 조사로 안내하도록 하겠습니다.",
  "app.init.message4": "본 연구 과정에서 저희는 귀하의 웹캠을 사용하여 귀하의 시선 추적 테스트를 실시하겠지만, 귀하의 데이터는 기록되지 않을 것입니다.",
  "app.init.message5": "본 연구는 스크린아웃/종료에 있어서 다음의 링크들을 사용하도록 설정되어 있습니다.",
  "app.prestart.loading": "로딩하는 중입니다. 잠시만 기다려 주십시오.",
  "app.index.title": "환영합니다.",
  "app.index.content.line1": "본 설문 조사를 진행하는 동안 귀하의 시선을 측정하기 위해 귀하의 카메라를 사용할 것입니다. 하지만 안심하십시오. 어떤 사진이나 영상도 촬영되지 않을 것입니다.",
  "app.index.content.line1gpdrwarning": "본 설문 조사에서는 귀하의 기기에 설치된 정면 카메라를 사용하여 자극물에 반응하는 귀하의 시선 추적을 포함하여 여러 가지 작업을 진행하는 동안 귀하의 이미지와 동영상을 촬영할 것입니다.",
  "app.index.content.line1alt": "편안하게 착석하신 후 시작을 눌려 주십시오.",
  "app.index.content.line2": "귀하의 기기에 카메라가 설치되어 있습니까? 그렇다면, 아래의 시작 버튼을 눌러 주십시오.",
  "app.index.rejection": "죄송합니다만, 잘못된 링크를 따라 오신 것 같습니다.",
  "app.index.webview": "죄송합니다만, 웹뷰에서 저희 페이지에 접근하신 것 같습니다. 귀하의 브라우저 내에서 이 링크를 열어 주십시오. 웹뷰 메뉴에서 직접 하실 수도 있습니다.",
  "app.index.webview.chrome.1": "위의 메뉴 버튼을 누르시고 ‘크롬에서 열기’를 선택해 주십시오.",
  "app.index.webview.chrome.2": "이렇게 하면 브라우저 앱에서 본 설문 조사가 열리고 제대로 작동될 것입니다.",
  "app.index.webview.android.1": "아래의 시작을 눌러 브라우저 앱에서 본 설문 조사를 제대로 작동시켜 주십시오.",
  "app.index.webview.android.2": "이 방법이 제대로 작동하지 않는 경우, 브라우저 앱에서 이 페이지의 링크를 수동으로 열어 주십시오(예를 들어, 크롬의 주소창에 해당 링크를 복사하여 붙여넣기를 하시거나 위의 메뉴 바에서 '크롬에서 열기'와 같은 옵션을 사용해 주십시오).",
  "app.index.webview.safari.1": "계속 진행하시려면, 스크린 하단의 사파리 아이콘을 눌러 주십시오.",
  "app.index.webview.safari.2": "그럼 다음과 같이 보이실 것입니다:",
  "app.index.webview.safari.3": "이렇게 하면 사파리에서 본 설문 조사가 열리고 제대로 작동될 것입니다.",
  "app.index.wifi": "귀하의 인터넷 연결 속도가 느린 것 같습니다. 와이파이에 연결되어 있습니까?",
  "app.index.tellmemore": "더 자세히 말씀해 주십시오.",
  "app.index.takepart": "나는 설문 조사의 참가를 원하지 않는다.",
  "app.index.webcamRetry": "이번 연구에서 필요한 웹캠을 감지하지 못했습니다. 귀하의 웹캠에 접근할 수 있도록 허용하신 후 다시 시도해 주십시오.",
  "app.index.adblockRetry": "앗! 광고 차단 프로그램이 감지되었습니다. 최상의 연구 경험을 위해서 이 기능을 끄신 후에 다시 시도해 주십시오.",
  "app.index.zoomRetry": "브라우저의 크기가 100%로 설정되어야 하니, 확대/축소를 조정하신 후 다시 시도해 주십시오.",
  "app.index.fullscreendesktopretry": "본 설문 조사에서는 전체 화면 보기가 요구됩니다. 귀하의 브라우저 창이 최대화되어 있는지 확인해 주십시오.",
  "app.index.fullscreendesktoptest": "본 설문 조사에서는 귀하의 브라우저 창이 전체 화면으로 설정되어야 합니다. 귀하의 브라우저 창이 최대화되어 있어야 테스트를 진행할 수 있습니다.",
  "app.index.fullscreendesktoptest2": "두 개의 버튼이 곧 나타날 것입니다. 계속 진행하시려면 두 개의 버튼을 클릭해 주십시오.",
  "app.index.fullscreendesktopmaximised": "내 화면은 이미 최대화되어 있다.",
  "app.index.fullscreendesktopskip": "감사합니다. 이 사례를 기록하여 저희의 감지 과정을 개선하겠습니다.",
  "app.index.fullscreendesktopreset": "창 크기의 변경이 감지되어 테스트를 초기화합니다.",
  "app.index.gotomobile": "데스크톱 컴퓨터 사용자는 본 설문 조사에 참여할 수 없습니다. 아래의 QR 코드를 사용하여 모바일 기기에서 본 설문 조사를 열어 주십시오.",
  "app.index.returntopanel": "그렇지 않으면, 아래의 버튼을 클릭하여 패널로 돌아가 주십시오.",
  "app.instructions.title": "안내 사항",
  "app.instructions.instruction1": "가능하시다면 설문 조사 중에 안경을 착용하지 말아 주십시오.",
  "app.instructions.instruction2": "머리와 기기를 편안하게 고정할 수 있는 자세로 앉아 주십시오.",
  "app.instructions.instruction3": "머리가 아니라 눈을 움직여 주십시오.",
  "app.instructions.instruction4": "시작할 준비가 되셨습니까?",
  "app.instructions.instruction5": "데이터 요금이 부과되지 않도록 Wi-Fi를 사용해 주십시오.",
  "app.instructions.instruction6": "다음 안내 사항을 주의 깊게 읽어 주십시요.",
  "app.instructions.instruction7": "귀하의 휴대폰을 안정된 평면에 놓아 주십시오.",
  "app.instructions.instruction8": "귀하의 방의 조명이 잘 켜져 있는지 확인해 주십시오 - 가능하면 위쪽에서부터(뒤에서가 아닌) 조명이 비춰지게 해 주십시오.",
  "app.setup.title": "설정",
  "app.setup.instruction0": "기기를 세로 모드로 유지해 주십시오.",
  "app.setup.instruction0a": "기기를 세로 방향으로 돌려 주십시오.",
  "app.setup.instruction0b": "기기를 왼쪽에 카메라가 위치하도록 돌려 주십시오.",
  "app.setup.instruction0c": "기기를 오른쪽에 카메라가 위치하도록 돌려 주십시오.",
  "app.setup.instruction0d": "기기를 가로 방향으로 돌려 주십시오.",
  "app.setup.instructioncamera": "카메라 접근 권한을 요청하려면 아래의 버튼을 클릭해 주십시오. 설문 조사를 완료하시려면 설문 조사가 진행되는 전체 기간 동안 카메라 권한을 허용하셔야 합니다.",
  "app.setup.cameraText": "접근 권한 요청",
  "app.setup.instruction1": "초기화 중입니다...",
  "app.setup.instruction2": "본인이 감지되시면, 귀하의 머리를 상자 안에 위치시켜 주십시오.",
  "app.setup.instruction3": "설문 조사가 완료될 때까지 귀하의 머리와 기기의 위치를 유지해 주십시오.",
  "app.setup.instruction3audio": "화면에 나오는 안내 사항을 주의 깊게 듣고 따라 주시기 바랍니다.",
  "app.setup.instruction4": "이제 확인만 하면 됩니다.",
  "app.setup.instruction5": "감사합니다! 설문 조사가 완료될 때까지 귀하의 머리와 기기의 위치를 동일하게 유지해야 한다는 점을 명심해 주십시오。",
  "app.setup.instruction6": "주소창을 숨기시려면 위로 밀어 올려 주십시오.",
  "app.setup.retry": "오류가 발견되었습니다. 귀하의 머리와 기기를 고정하시고 손가락 등으로 카메라를 가리지 않도록 주의해 주십시오. 다시 시도하시려면 아래의 버튼을 사용해 주십시오.",
  "app.setup.retryPositioning": "저희가 귀하의 머리 위치를 파악할 수 없었습니다. 귀하의 머리와 기기를 고정하시고 손가락 등으로 카메라를 가리지 않도록 주의해 주십시오. 다시 시도하시려면 아래의 버튼을 사용해 주십시오.",
  "app.setup.retryInit": "오류가 발견되었습니다. 브라우저가 귀하의 카메라에 접근할 수 있는지 확인해 주십시오. 이전에 카메라 접근 권한을 거부하신 경우, 브라우저의 개인 정보 설정에서 이를 허용해 주셔야 합니다. 다시 시도하시려면 아래의 버튼을 사용해 주십시오.",
  "app.setup.retryBroker": "저희가 조정 과정을 완료할 수 없었습니다. 리소스를 확보하기 위해 사용하지 않는 앱과 탭을 닫아 주십시오.",
  "app.setup.retryButton": "다시 시도하기",
  "app.setup.calibrationProp.watch": "화면에 점이 나타나면 그 점을 따라가 주십시오.",
  "app.setup.calibrationProp.watch.george": "다음으로 어떤 얼굴이 나타나면, 그 얼굴을 응시하면서 머리의 움직임을 따라해 주십시오.",
  "app.setup.calibrationProp.click": "계속하시려면 아무 곳이나 클릭해 주십시오.",
  "app.setup.calibrationProp.tap": "계속하시려면 아무 곳이나 눌러 주십시오.",
  "app.study.validationProp.watch": "화면에 점이 나타나면 그 점을 따라가 주십시오.",
  "app.study.validationProp.restart": "다시 시도해 주십시오.",
  "app.study.validationProp.click": "계속하시려면 아무 곳이나 클릭해 주십시오.",
  "app.study.validationProp.tap": "계속하시려면 아무 곳이나 눌러 주십시오.",
  "app.study.render.error": "렌더 단계에 유효한 도메인이 아닙니다.",
  "app.error.title": "오류",
  "app.error.generic": "앗! 문제가 발생했습니다.",
  "app.error.initialise": "초기화할 수 없습니다!",
  "app.error.findCamera": "카메라를 찾을 수 없습니다!",
  "app.error.headPosition": "귀하의 머리 위치를 파악할 수 없습니다.",
  "app.error.calibrate": "조정할 수 없습니다.",
  "app.error.validate": "확인할 수 없습니다.",
  "app.error.start": "시작하지 못했습니다.",
  "app.error.assign": "할당하지 못했습니다.",
  "app.error.osnotallowed": "스크린아웃: 귀하의 운영 체제는 허용되지 않은 운영 체계입니다. 본 설문 조사에서 사용할 수 있는 기기를 확인하시고 다시 시도해 주십시오.",
  "app.error.demoquotafullnoactivecell": "할당 인원 도달: 관심을 가져 주셔서 감사합니다. 본 설문 조사는 현재 귀하의 인구통계학적 특성을 가진 집단에 대해서 종료되었습니다.",
  "app.error.cellquotafullnoactivecell": "본 연구에 대한 활성 셀이 발견되지 않았습니다. 셀 할당량에 도달했습니다.",
  "app.error.missingpanelparameter": "패널 매개변수가 누락되었습니다.",
  "app.invalid.title": "유효하지 않습니다.",
  "app.invalid.generic": "죄송합니다만, 귀하께서는 본 연구의 참가 대상이 아니십니다.",
  "app.completion.title": "완료",
  "app.completion.congratulations": "축하합니다! 귀하께서는 본 연구를 완료하셨습니다. 본 설문 조사를 위한 귀하의 카메라에 대한 접근 허용을 이제 종료하겠습니다.",
  "app.completion.congratulations-noet": "축하합니다! 귀하께서는 본 연구를 완료하셨습니다.",
  "app.questions.required": "이 질문은 필수입니다.",
  "app.faq.gdpr.intro1": "본 연구에서는 모바일 기기의 전면 카메라를 사용하여 사용자가 다양한 환경(예를 들어, 조명 및 위치)에서 자극물을 응시하면서 여러 가지 작업을 수행하는 동안 사용자의 이미지와 비디오 영상을 촬영할 것입니다  본 설문 조사의 완료까지는 약 5분 정도 소요될 예정입니다.  이 데이터는 사용자가 화면에서 어디를 보는지를 추정할 수 있는 소프트웨어의 지속적인 개발을 지원하기 위해 사용됩니다.",
  "app.faq.gdpr.intro2": "본 연구는 시장 조사 협회(Market Research Society, MRS)의 행동 강령과 2018년 데이터보호법(Data Protection Act)에 따라 실시되고 있습니다.  귀하의 개인정보는 저희에게 매우 중요합니다.  본 연구에 참여하시기 전에, 본 개인정보 처리방침에 설명된 대로 저희가 데이터 수집하는 데이터와 데이터의 활용 방법에 대해 이해하셨으며 이에 동의하신다는 점을 확인해 주셔야 합니다.  본 정책은 어떤 정보가 수집되며, 어떻게 사용되고 공유되는지에 대해 보다 자세히 설명합니다.",
  "app.faq.gdpr.question1": "어떤 데이터가 수집됩니까?",
  "app.faq.gdpr.answer1.line1": "본 연구에서는 다음과 같은 유형의 데이터가 수집됩니다:",
  "app.faq.gdpr.answer1.line2": "“앉은 자세의 특성” – 세션이 진행되는 동안 '앉은 자세'에 대한 특정 데이터 및 우세한 환경적 테스트 조건에 대한 데이터를 파악하기 위해 사용되는 익명의 ID.",
  "app.faq.gdpr.answer1.line3": "“참가자 특성” – 참가자의 인구통계학적 특성(예를 들어, 나이, 성별), 인종적 특성(예를 들어, 피부색) 및 생물학적 특성(예를 들어, 눈 색깔)에 대한 정보를 포함하여 특정한 앉은 자세로 참가하는 응답자에 대한 데이터.",
  "app.faq.gdpr.answer1.line4": "“비디오 파일” – 참가자가 한 개의 화면 또는 여러 개의 화면에 나타나는 자극물을 응시하거나 상호작용하는 동안의 참가자의 이미지를 포착하기 위해 기기의 전면 카메라를 사용하여 촬영한 이미지 및/또는 동영상 참가자가 과제를 수행하는 동안 전면 카메라가 참가자의 눈과 얼굴, 그리고 우연히 포착될 수 있는 다른 사물들의 이미지를 쵤영할 수 있습니다. ",
  "app.faq.gdpr.answer1.line5": "“메타데이터” - 각각 수집된 이미지의 타임스탬프와 연결된 개인이 자극물과 상호작용한 시간에 대한 데이터.",
  "app.faq.gdpr.answer1.line6": "“기기의 물리적 데이터” -  기기 명칭, 화면 크기, 화면 회전 및 카메라 센서 회전 등을 포함한 해당 기기에 대한 물리적 데이터.",
  "app.faq.gdpr.answer1.line7": "귀하께서는 본 연구에 참여함으로써 본 연구에서 보시는 콘텐츠가 기밀일 수 있으며, 제3자의 소유물 및/또는 상표권 또는 저작권법의 대상일 수 있다는 점, 그리고 콘텐츠의 스크린샷을 만들고, 기록하거나 복사하면 안 되고, 어떠한 형태로든 본 연구의 콘텐츠를 재배포하면 안 된다는 점을 이해하셨으며, 이에 동의하시는 것으로 간주됩니다.",
  "app.faq.gdpr.question2": "수집된 데이터는 어떻게 사용되고/처리됩니까?",
  "app.faq.gdpr.answer2": "수집된 데이터는 사용자가 기기 앞에 있는지, 자극물을 보고 있는지, 그리고 자극물의 어느 부분을 보고 있는지를 추정할 수 있는 소프트웨어 개발에 사용될 것입니다.  또한 기술 개발 방법 및 성능을 제3자(고객사, 공급 업체, 파트너사)에게 설명 또는 시연 목적, 또는 평가를 돕기 위해서도 사용될 수 있습니다.  수집된 데이터는 연구 중 참가자들의 집중 ‘상태’(예를 들어, 참가자들이 카메라의 시야에 위치해 있는가? 참가자들이 화면을 보고 있는가?)를 평가하기 위해 촬영된 영상/이미지의 확인을 요청받은 직원 또는 협력사 또는 파트너사 또는 기타 제3자들을 포함한 데이터 '주석 작성자'들에 의해 검토될 수 있습니다.  해당 데이터 처리의 법적 근거는 사전 동의와 적법한 이해 관계로 이루어질 것입니다. ",
  "app.faq.gdpr.question3": "해당 데이터는 얼마 동안 보관됩니까?",
  "app.faq.gdpr.answer3": "위에서 설명한 목적을 위해 합리적으로 필요한 기간 동안 해당 데이터가 보관될 것입니다.  개발 중인 소프트웨어는 주기적으로 업데이트 및 평가되며, 이 데이터는 각 개발 프로세스의 반복을 평가하기 위해 사용될 예정이므로 무기한 보관된 될 수 있습니다.  자극 테스트 소프트웨어의 개발 또는 평가라는 목적에 더 이상 유용하지 않다고 판단되는 경우, 해당 데이터는 삭제될 것입니다. ",
  "app.faq.gdpr.question4": "해당 데이터를 받아 처리하는 사람은 누구입니까?",
  "app.faq.gdpr.answer4": "해당 데이터는 루멘 리서치 엘티디(Lumen Research Ltd.)에서 보관하고 보유할 것입니다. 루멘 리서치 엘티디(Lumen Research Ltd.)는 영국에 등록된 회사로써, 회사 번호는 8682432입니다.  등록 사무실은 LABS house, 15-19 Bloomsbury Way, Holborn, London WC1A 2TH에 위치해 있습니다. 루멘의 데이터 보호 등록 참조 번호는 ZA130772입니다.  루멘의 데이터 보호 담당자는 Matt Robinson (matt.robinson@lumen-research.com)입니다.  귀하께서는 언제든지 영국의 데이터 보호 기관인 정보 위원회(Information Commissioner's Office, ICO)에 연락하여 귀하의 개인 정보 처리와 관련하여 불만을 제기할 권리가 있습니다.  ICO의 연락 정보는 다음의 웹사이트(https://ico.org.uk/global/contact-us/)에서 확인하실 수 있습니다. 본 연구에 참여하심으로써, 해당 데이터를 관리, 저장, 사용, 표시, 복제, 수정, 조정, 편집, 출판 및 배포할 수 있는 전 세계적인 저작권 무료, 하위 라이센스를 허여할 수 있는(sublicensable) 양도 가능한 라이센스를 루멘에 부여하는 것에 동의하시는 것으로 간주됩니다.",
  "app.faq.gdpr.question5": "저에게 제 개인 데이터에 접근할 권리가 있습니까?",
  "app.faq.gdpr.answer5": "네, 귀하께서는 귀하의 개인 데이터에 접근할 권리가 있으십니다. 다만 이 연구 과정에서 수집된 데이터에는 귀하의 성함이 저장되지 않는다는 점을 유의해 주시기 바랍니다.  귀하의 데이터 기록을 식별하기 위해서는 본 연구에 참여하신 정확한 시간과 날짜를 제공해 주셔야 하며, 해당 데이터 기록이 정확히 맞는지를 파악하기 위해 저희가 육안으로 신원을 확인하는 과정을 요청할 것입니다.",
  "app.faq.gdpr.question7": "저에게 제 개인 데이터 처리에 대한 동의를 철회하거나 개인 데이터 삭제를 요청할 권리가 있습니까?",
  "app.faq.gdpr.answer7": "네, 귀하는 귀하의 개인 데이터 처리에 대한 동의를 철회하고 해당 데이터의 삭제를 요청할 권리가 있으십니다.  다만 이 연구 과정에서 수집된 데이터에는 귀하의 성함이 저장되지 않는다는 점을 유의해 주시기 바랍니다.  귀하의 데이터 기록을 식별하기 위해서는 본 연구에 참여하신 정확한 시간과 날짜를 제공해 주셔야 하며, 해당 데이터 기록이 정확히 맞는지를 파악하기 위해 저희가 육안으로 신원을 확인하는 과정을 요청할 것입니다.  ",
  "app.faq.gdpr.question8": "제가 제 개인 정보가 안전하게 보호되고 있는지를 어떻게 알 수 있습니까?",
  "app.faq.gdpr.answer8": "루멘 리서치(Lumen Research)가 수집한 데이터는 보안 소켓 계층(Secure Sockets Layer, SSL) 기술을 사용하여 암호화됩니다.  이 기술은 흔히 사용되는 고급 암호화 기술입니다. 이 기술은 전송 채널과 최종 저장 과정에 보안을 제공하기 위해 사용됩니다.",
  "app.faq.gdpr.question9": "루멘에서는 수집한 정보를 어떻게 사용하고 공개합니까?",
  "app.faq.gdpr.answer9": "저희는 제3자 서비스 제공 업체가 저희에게 서비스를 제공할 수 있도록 저희가 수집한 정보를 이들에게 공개할 수 있습니다. 저희는 또한 비즈니스, 자산 또는 주식의 일부 또는 전부를 재조직, 합병, 판매, 합작, 양도, 이전 또는 기타 처분하는 경우에도(파산 또는 유사한 절차와 관련된 경우 포함) 제3자에게 정보를 공개할 수도 있습니다 저희는 또한 다음의 경우와 같이 필요하거나 적절하다고 판단되는 경우에도 저희가 수집한 정보를 사용하거나 공개할 수 있습니다: (a) 귀하의 거주 국가 외의 법률을 포함하여 적용 법률에 따라서, (b) 법적 절차를 준수하기 위해, (c) 귀하의 거주 국가 외의 공공 기관 및 정부 기관을 포함하여 해당 기관의 요청에 대응하기 위해, (d) 최종 사용자 라이선스 동의서를 시행하기 위해, (e) 루멘이나 루멘의 제휴사의 운영권을 보호하기 위해, (f) 루멘이나 루멘의 제휴사, 귀하 또는 다른 사람들의 권리, 개인 정보, 안전 또는 재산을 보호하기 위해, (g) 루멘에서 가능한 대응책을 추구하거나 루멘이 입을 수 있는 손해를 막기 위해, 그리고 (h) 부정 행위나 보안 관련 우려를 감지하고 이를 해결하기 위해\r\n ",
  "app.faq.gdpr.question10": "데이터를 안전하게 보호하기 위해 어떤 조치가 취해집니까?",
  "app.faq.gdpr.answer10": "저희는 저희가 보유하거나 통제하는 정보의 민감도에 적합한 물리적, 전자적, 절차적 보안 조치를 적용하여 귀하의 정보를 보호하기 위해 합리적인 조치를 취합니다. 여기에는 분실 또는 도난으로부터의 보호 조치뿐만 아니라 무단으로 접근, 공개, 모방, 사용 또는 변경으로부터의 보호 조치도 포함됩니다. ",
  "app.faq.question1": "Q: 루멘 스터디 플랫폼( Lumen Study Platform)은 무엇이며, 시선 추적에 대한 설문 조사의 참여에 수반되는 것은 무엇입니까?",
  "app.faq.answer1": "A: 본 설문 조사에는 시선 추적 기술의 사용이 수반됩니다. 귀하께서는 귀하의 기기의 웹캠 또는 전면 카메라 앞에 앉으셔서 화면에서 어디를 보고 계시는지를 추정할 수 있도록 자세와 기기를 조정해 주셔야 합니다. 설문 조사 과정에서 귀하께서 특정 아이템(예를 들어 이미지, 웹사이트 또는 동영상)을 보시는 동안 설문 조사 도구가 귀하께서 어디를 보고 계시는지 추정합니다. 이렇게 '시선 추적'으로 포착되는 데이터는 테스트 시간 동안 화면에서 추정되는 응시점의 좌표입니다. 이 소프트웨어는 귀하의 웹캠에서 찍은 귀하의 이미지를 사용하지만, 귀하의 사진이나 동영상을 캡처하지는 않습니다. 귀하께서는 자신, 자신의 경험 및 설문 조사 과정에서 보신 아이템에 대한 몇 가지 질문을 받으실 수도 있습니다. 해당 데이터는 루멘 리서치 엘티디(Lumen Research Ltd.)에 전송됩니다. 루멘 리서치(Lumen Research)는 해당 데이터를 분석하고 결과를 루멘의 고객사들과 공유할 것입니다.",
  "app.faq.question2": "Q: 이 소프트웨어가 웹캠/카메라가 필요한 이유는 무엇이며 어떻게 사용됩니까?",
  "app.faq.answer2": "A: 이 소프트웨어는 귀하께서 화면에서 어디를 보고 계시는지를 추정하기 위해 귀하의 웹캠 또는 기기 카메라에서 찍은 이미지를 사용합니다. 이를 위해 얼굴 추적 및 시선 추적 소프트웨어를 함께 사용하여 귀하의 기기에서 실시간으로 이미지들을 국소적으로 분석합니다. 귀하께서 올바른 자세로 위치하실 수 있도록 조정 과정에서 이런 이미지들이 처리되어 귀하께 보여집니다. 하지만 해당 이미지들은 기록되지 않기 때문에 귀하의 프라이버시가 침해되지 않습니다. 시선 추적으로 기록 및 수집되는 데이터는 이미지가 아니라 테스트 과정에서 자극물 위에 추정된 응시점에 대한 숫자 데이터입니다.",
  "app.faq.question3": "Q: 시선 추적 테스트가 진행되는 동안 제 사진이 찍힙니까?",
  "app.faq.answer3": "A: 귀하께서 올바르게 위치하실 수 있도록 귀하의 자세 및 기기의 조정 과정에서만 이미지들이 사용됩니다. 이런 이미지들은 저장되지 않으며 귀하께서만 보실 수 있습니다. 시선 추적으로 기록 및 수집되는 데이터는 이미지가 아니라 테스트 과정에서 자극물 위에 추정된 응시점에 대한 숫자 데이터입니다.",
  "app.faq.question4": "Q: 본 설문 조사 중에 어떤 데이터가 수집됩니까?",
  "app.faq.answer4.line1": "A: 이 소프트웨어는 다음의 다섯 가지 유형의 데이터 중 일부 또는 전부를 수집할 수 있습니다:",
  "app.faq.answer4.line2": "“사용자 ID” - 참가자를 식별하기 위해 사용되는 사용자 ID(참가자에 대한 보상을 진행하기 위한 목적)",
  "app.faq.answer4.line3": "“시선 데이터” – 화면에 자극물이 나타날 때 사용자가 화면에서 어디를 보고 있는지에 대한 추정치",
  "app.faq.answer4.line4": "“설문 조사 데이터” – 사용자가 질문에 대해 응답한 답변",
  "app.faq.answer4.line5": "“메타데이터” - 개별 참가자들이 본 연구에 성공적으로 참여해서 완료한 시간에 대한 데이터",
  "app.faq.answer4.line6": "“기기의 물리적 데이터” – RAM 수준, 기기의 CPU 종류 및 사용자 에이전트(운영 체제 및 브라우저 버전) 정보뿐만 아니라 컴퓨터의 화면(크기와 해상도), 웹캠( 제조사와 해상도) 및 백신 프로그램과 같은 사용자가 사용하는 컴퓨터에 대한 데이터 ",
  "app.faq.question5": "Q: 수집된 데이터는 어떻게 사용됩니까?",
  "app.faq.answer5.line1": "A: 수집된 데이터는 개별 참가자들이 다양한 유형의 시각적 이미지와 미디어를 어떻게 보고 상호 작용하는지, 그리고 제시된 이미지들에 대한 참가자들의 의견을 파악하기 위해 사용될 것입니다.",
  "app.faq.answer5.line2": "루멘은 루멘의 계열사, 그리고 비즈니스 협력사 및 고객사와 본 연구 데이터를 공유할 수 있습니다. 본 연구 데이터는 루멘의 계열사, 비즈니스 협력사 및 고객사와 공유할 때는 일반적으로 익명으로 처리됩니다. 이런 계열사, 협력사 및 고객사에서는 해당 데이터를 사용하여 다양한 소비자 행동 및 소비자 집단에 대한 요약 설명을 작성하여 제품 개발이나 고객 또는 소비자에게 마케팅 솔루션을 제공할 수 있습니다.",
  "app.faq.answer5.line3": "루멘은 품질과 수량 관리를 포함한 데이터 수집 및 처리를 관리하기 위해 \"기기의 물리적 데이터\"와 \"메타데이터\"를 수집합니다.",
  "app.faq.answer5.line4": "수집된 데이터는 상기에 제시된 목적을 달성하기 위해 필요한 기간 동안 보관될 것입니다.",
  "app.faq.question6": "Q: 제 데이터가 얼마나 안전하게 수집 및 보관됩니까?",
  "app.faq.answer6": "A: 루멘이 수집한 데이터는 보안 소켓 계층(SSL) 기술을 사용하여 암호화됩니다. 이 기술은 가장 일반적으로 사용되는 고급 암호화 기술이며, 은행들은 은행 거래 및 다른 매우 민감한 통신을 보호하기 위해 사용합니다. 또한 은행 수준의 보안 시스템이 필요한 아마존(Amazon), 페이팔(PayPal), 이베이(eBay) 및 다른 웹사이트에서도 사용됩니다. 과학자들은 현재의 기술을 사용하여 256비트 암호화를 해독하는 데는 우주의 나이보다 훨씬 더 많은 시간이 필요할 것으로 추정합니다. 수집된 데이터는 아마존 웹 서비스(Amazon Web Services, AWS)가 관리하는 안전한 웹 서버에 저장됩니다. 아마존 웹 서비스(AWS)는 가장 일반적으로 사용되는 안전하고 신뢰할 수 있는 클라우드 컴퓨팅 서비스 플랫폼 중 하나이며, 넷플릭스(Netflix)와 익스피디아(Expedia)를 비롯한 주요 클라우드 기반 상업 서비스에서 사용됩니다.",
  "app.faq.question7": "Q: 제게 보여지는 콘텐츠와 관련해서 제게 요구되는 의무가 무엇입니까?",
  "app.faq.answer7": "A: 귀하께서는 본 연구에 참여함으로써, 본 연구에서 보시는 콘텐츠가 기밀일 수 있으며, 제3자의 소유물 및/또는 상표권 또는 저작권법의 대상일 수 있다는 점, 그리고 콘텐츠의 스크린샷을 만들고, 기록하거나 복사하면 안 되고, 어떠한 형태로든 본 연구의 콘텐츠를 재배포하면 안 된다는 점을 이해하셨으며, 이에 동의하시는 것으로 간주됩니다.",
  "app.faq.question8": "Q: 제 웹캠이 얼마나 좋은 것인지 잘 모르겠습니다. 그래도 시선 추적을 할 수 있습니까?",
  "app.faq.answer8": "A: 본 시스템은 광범위한 종류의 웹캠에서 작동하기 때문에 문제가 되지 않을 것입니다.",
  "app.faq.question9": "Q: 본 설문 조사를 완료했습니다, 제가 해야 할 다른 작업이 있습니까?",
  "app.faq.answer9": "A: 아닙니다. 더 하셔야 할 다른 작업은 없습니다. 본 소프트웨어가 자동으로 종료될 것입니다.",
  "app.faq.question10": "Q: 연구에 참가하기 위해서 인터넷 연결이 필요합니까?",
  "app.faq.answer10": "A: 네, 본 프로그램을 다운로드하시고 작업을 완료하기 위해서는 인터넷 연결이 필요합니다.",
  "app.faq.question11": "Q: 안정된 평면에서 작업을 진행해야 합니까?",
  "app.faq.answer11": "A: 네, 최상의 결과를 위해서는 컴퓨터 화면 앞에서 편안하게 앉으시고, 자세와 기기를 조정하신 후에는 자극물이 제시되는 동안 머리를 고정하시고 공지가 있을 때까지 그 상태를 유지하셔야 합니다. 열차에 타고 있거나 무릎 위에 노트북을 놓고 균형을 잡아야 하는 상황 등의 경우에는 본 연구에 참여하시지 않는 것이 좋습니다.",
  "app.faq.question12": "Q: FAQ로 제 문제를 해결하지 못했습니다. 이제 어떻게 해야 합니까?",
  "app.faq.answer12": "A: study-feedback@lumen-research.com으로 이메일을 보내주시면 가능한 빠르게 회신하겠습니다.",
  "app.faq.patent": "루멘의 웹 콘텐츠 테스트는 자체의 맥락 연구 테스트(in-context testing) 기술을 사용하여 시행됩니다(영국 특허 출원 번호 1816158.8).",
  "app.terms.line1": "본 이용 약관(\"약관\")은 영국에 등록된 법인인 루멘 리서치 엘티디(Lumen Research Ltd.)(\"루멘\" 또는 \"저희\")와 귀하 사이의 귀하의 기기에서 \"뷰포인츠(ViewPoints)\"라는 시선 추적 소프트웨어(\"소프트웨어\")의 사용에 관한 계약을 규정합니다. 루멘 리서치 엘티디(Lumen Research Ltd.)는 영국에서 등록된 회사로, 등록 번호는 8682432이며, 등록 사무실은 Unit 215, 22 Highbury Grove, London N5 2EF에 위치하고 있으며, 데이터 보호 등록 참조 번호는 ZA130772입니다. ",
  "app.terms.line2": "1. 약관에 대한 동의",
  "app.terms.line3": "본 소프트웨어를 사용함으로써, 귀하는 본 약관에 동의한 것으로 간주됩니다. 본 약관의 조건에 동의하시지 않는 경우, 뷰포인츠(ViewPoints) 설문 조사를 완료하지 마십시오.",
  "app.terms.line4": "2. 기밀 정보",
  "app.terms.line5": "귀하께서는 뷰포인츠(ViewPoints)를 설치, 사용 또는 어떤 방식으로든 관련성을 갖게 됨으로써, 루멘에 속하거나 루멘이나 루멘의 고객사 또는 고객이 기밀로 판단하거나 처리, 또는 비즈니스 계획, 새로운 비즈니스 기회, 연구 및 개발 프로젝트, 디자인, 비밀 프로세스, 코드, 소프트웨어 디자인, 기업 비밀, 제품 또는 서비스 개발 및 공식, 노하우, 발명, 판매 통계 및 예측, 마케팅 전략 및 계획, 비용, 손익 및 기타 재무 정보 (감사 보고서에 공개된 내용 제외) 및 가격 및 할인 구조(서면, 전자 또는 기타 형식으로 기록되었는지의 여부와 관계없이)를 포함해서(앞서 언급한 일반적인 사항에 영향을 미치지 않는 한) 특성 또는 공개의 환경 또는 방식이 명백히 기밀로 인정되는 정보 및/또는 콘텐츠에 접근하실 수 있습니다(\"기밀 정보\"). 해당 기밀 정보는 루멘 및/또는 루멘의 고객의 독점적 소유물로써, 귀하께서는 어떤 경우에도 해당 기밀 정보에 대한 권리, 소유권 및/또는 이익을 획득하거나 받으시지 않습니다.",
  "app.terms.line6": "귀하께서는 뷰포인츠(ViewPoints)를 통해 본 연구에 참여하시는 동안 또는 참여 종료 후 어떤 경우에도(제한없이) 기밀 정보를 다른 사람에게 누설하거나 전달하거나, 개인 목적으로 사용하거나, 적절한 주의와 조치를 취하지 않아 해당 기밀 정보의 무단 공개를 초래해서는 안 됩니다. 귀하께서는 해당 기밀 정보의 게시 또는 누설을 방지하기 위해 항상 최선을 다하셔야 합니다. 이런 제한 사항은 귀책 사유가 아닌 일반적으로 대중에게 공개되는 정보에는 적용되지 않습니다.",
  "app.terms.line7": "3. 루멘의 소유물",
  "app.terms.line8": "뷰포인츠(ViewPoints) 소프트웨어 및 해당 소프트웨어와 관련된 모든 데이터, 코드, 키, 비밀 번호, 디자인, 도면, 기록 및 기타 문서 또는 자료는 귀하께서 뷰포인츠(ViewPoints) 설문 조사에 참여하시는 기간 동안 루멘의 소유물로 유지됩니다. 루멘은 특허, 저작권, 디자인권, 데이터베이스권, 상표권, 반도체 배치 설계권, 식물 다양성권, 인터넷권/도메인 이름, 노하우 및 상기와 관련된 모든 출원 및 상기와 관련된 어떠한 출원권 및 모든 출원권(등록 또는 미등록 포함)을 포함하여, 귀하 단독으로 또는 다른 사람과 함께 실행, 제작, 창조, 창안, 개발, 개선, 하거나, 해체 또는 발견한 소프트웨어 및/또는 기기와 관련하여 또는 관련 범주 안에서 사용되거나 적용될 수 있는  모든 문서, 자료, 모델, 디자인, 도면, 공정, 발명, 공식, 컴퓨터 코드, 데이터, 시스템 인터페이스, 방법론, 노하우, 비밀 정보 또는 기타 작업에 대한 모든 현존 및 미래의 지적 재산권 또는 소유권을 보유합니다(\"소유권\"). 어떠한 경우에도 귀하께 해당 소유권이 이전되지 않습니다",
  "app.terms.line9": "4. 사용 조건 및 제한 사항",
  "app.terms.line10": "뷰포인트(ViewPoints) 소프트웨어는 최종 사용자에게 라이선스가 부여됩니다 본 계약에 따라, 저희는 여기에 명시적으로 귀하께, 귀하가 소유하거나 제어하는 기기 또는 설치에 필요한 모든 권리와 허가를 보유하고 있다는 것을 귀하께서 대표하고 보증하는 개인용 컴퓨터에 대해, 배타적이지 않고, 철회 가능하며, 양도 불가능고, 하위 라이센스를 허여할 수 없는(non-sublicensable) 제한된 범위에서 뷰포인츠(ViewPoints) 소프트웨어를 사용하실 수 있는 권한을 부여합니다. 다시 말해, 귀하께서는 뷰포인츠(ViewPoints) 이외의 목적으로 소프트웨어를 사용하거나 번역, 복사, 복제, 전화 통신으로 전송, 개조, 병합, 변경 또는 어떤 방식으로든 수정하는 등의 행위를 하실 수 없습니다.",
  "app.terms.line11": "귀하께서는 다음의 행위를 하지 않겠다는 점에 동의합니다: (i) 소프트웨어의 복사, 수정, 개조, 제공, 임대, 리스, 공동 소유, 파생 작품 작성 또는 소프트웨어 권리의 판매 또는 양도 시도 (ii) 어떤 방식으로든 소프트웨어의 특정 부분의 해체, 디컴파일 또는 역공학 시도, 또는 소프트웨어의 소스 코드를 추출을 시도하거나, 해체, 해독, 디컴파일 또는 역공학을 하려는 다른 이의 행위를 허용하거나 지원, 또는 소프트웨어에 대한 설계 정보의 유출을 위한 다른 조치 시도 (iii) 소프트웨어의 원래 배포된 목적 이외의 목적으로 사용 (iv) 스파이더(Spider), 로봇 또는 기타 자동화된 데이터 마이닝 기술을 사용하여 소프트웨어를 카탈로그화, 다운로드, 저장, 또는 복제, 배포, 또는 설문 조사나 기타 활동의 결과를 조작 (v) 소프트웨어와 소프트웨어가 아마존 웹 서비스(Amazon Web Service)를 통해 사용하는 데이터 저장 시스템을 \"오버로딩(overloading)\", \"플러딩(flooding)\", \"메일 폭탄\" 또는 \"충돌”하는 행위를 포함하여 소프트웨어나 개인의 소프트웨어 사용을 방해하는 행위 (vi) 바이러스, 스파이웨어, 애드웨어 또는 기타 해로운 코드를 포함하여 루멘에게 불법, 허위, 또는 악성 코드를 보내는 행위",
  "app.terms.line12": "이하 귀하께 명시적으로 부여되지 않은 모든 권리는 저희와 저희의 라이선서 보유자에게 귀속됩니다. 귀하께서는 이 계약에 따라 또는 그 외의 다른 조건으로 저희가 소프트웨어의 버그, 결함 또는 오류를 수정하거나 그 외에 소프트웨어를 지원, 유지, 개선, 수정, 업그레이드, 업데이트 또는 향상시켜야 하는 의무를 가지지 않는다는 것에 동의합니다. 저희는 귀하 또는 귀하의 대리인에게 소프트웨어 사용에 대해 어떤 형태로든 지원을 해야 할 의무가 없습니다.",
  "app.terms.line13": "저희는 귀하의 소프트웨어 사용으로 인해 발생한 손해를 포함하여 귀하의 컴퓨터에 발생하는 어떠한 손해에 대해서도 책임을 지지 않습니다. 저희는 귀하께서 본 연구에 참여하는 과정에서 소프트웨어가 수집한 데이터의 결과로써 귀하에 의해 귀하께 발생하는 추가적인 데이터 요금에 대해 책임을 지지 않습니다.",
  "app.terms.line14": "5. 면책 조항",
  "app.terms.line15": "저희와 저희의 이사, 임원, 직원, 대리인, 대표자, 라이선서 보유자 및 제공 업체는 다음과 대한 어떠한 고지, 또는 보증 또는 조건을 제공하지 않습니다: (a) 소프트웨어가 적시에 이용 가능하거나 소프트웨어에 대한 접근이 중단되지 않고 안전할 것이다 (b) 결함이나 오류가 수정될 것이다 (c) 소프트웨어와 기기가 이용 가능한 서버 또는 네트워크가 안전하거나 바이러스나 기타 유해한 구성 요소가 없을 것이다 저희는 소프트웨어에 관련된 유지와 보수 및 지원 서비스를 제공할 의무가 없습니다.",
  "app.terms.line16": "6. 책임의 제한",
  "app.terms.line17": "(a) 해당 법에 따라 허용되는 최대 한도 내에서, 귀하께서는, 그 어떤 성격의 법적, 공정적 또는 법적 권리가 침해되었다는 주장과 상관없이, 소프트웨어의 설치, 사용 또는 접근, 소프트웨어, 소프트웨어를 통해 제공되거나 그리고/또는 소프트웨어 또는 이 계약과 관련한 정보 또는 자료의 사용 또는 사용 불가능으로 인해 발생한 어떠한 종류의 손실, 손해 또는 비용에 대해서도, 저희와 저희의 이사, 임원, 직원, 대리인, 대표자, 라이선스 제공자 및 제공 업체가 책임을 지지 않는다는 점에 동의합니다.",
  "app.terms.line18": "(b) 해당 법에 따라 허용되는 최대 한도 내에서, 소프트웨어에 대한 불만족의 유일하고 독점적인 구제 방법은 소프트웨어의 사용을 중단하는 것입니다.",
  "app.terms.line19": "(c) 미리 손해가 발생할 가능성이 있다는 점을 사전에 통보받았다고 해도, 이런 손해가 예견 가능하거나 그렇지 않은 경우에도 그 원인과 상관없이, 어떠한 계약, 불법 행위(예를 들어, 과실을 포함하되 이에 국한되지 않음), 엄격한 책임 또는 기타 이론에 따르는 어떠한 경우에도, 저희와 저희의 이사, 임원, 직원, 대리인, 대표자는 부수적, 간접적, 결과적, 법적, 징벌적, 또는 특별 손해, 수익 손실, 기회 손실, 명예 실추, 사용 손실, 데이터 손실, 기타 무형 자산의 손실 또는 제3자에 의해 소프트웨어가 무단으로 수집한 데이터의 불법적인 유출에 대한 어떠한 귀하의 손해에 대해서도 책임을 지지 않습니다.",
  "demo.index.content.line1": "본 시험 테스트 과정에서 저희는 귀하의 카메라를 사용하여 귀하께서 응시하는 지점에 대해 추정할 것입니다 하지만 안심하십시오. 어떤 사진이나 영상도 촬영되지 않을 것입니다.",
  "app.error.previousfail": "귀하께서는 이전에 본 설문 조사를 성공적으로 완료하지 못하셨습니다. 처음부터 시작하시려면 원래 링크를 사용해 주십시오.",
  "app.error.previoussuccess": "귀하께서는 이미 본 설문 조사를 완료하셨습니다.",
  "app.error.route": "귀하께서는 이미 진행 중인 설문 조사를 시작하려고 시도하셨습니다. 처음부터 시작하시려면 원래 링크를 사용해 주십시오",
  "app.error.accesscode": "귀하께서는 존재하지 않는 설문 조사를 시작하려고 시도하셨습니다. 귀하의 링크를 확인해 주십시오.",
  "app.error.webcam": "본 연구에서는 웹캠에 대한 접근이 허용하셔야 합니다.",
  "app.error.adblocker": "본 연구에서는 광고 차단을 해제하셔야 합니다.",
  "app.error.startsession": "세션 시작 불가",
  "app.error.fullscreen": "본 연구에서는 전체 화면 보기를 하셔야 합니다.",
  "app.error.zoom": "본 연구에서는 브라우저의 크기를 100%로 설정하셔야 합니다.",
  "app.index.webcamInstructions": "이제 귀하의 웹캠에 대한 접근을 요청하려고 합니다. 팝업 창이 열리면 허용을 클릭해 주십시오.",
  "app.setup.retryBack": "본 설문 조사 과정에서 브라우저의 뒤로 가기 버튼을 사용하지 말아 주십시오. 참가에 대한 보상을 받지 못하시는 결과를 초래할 수도 있습니다. 다시 시도하시려면 아래의 버튼을 사용해 주십시오.",
  "app.setup.retryaudible": "귀하 기기의 음소거를 해제하신 후에 다시 시도해 주십시오.",
  "app.restore.retry": "귀하께서는 이전 단계에서 충분한 시간을 사용하셨습니다. ",
  "app.restore.timeremaining": " 몇 분 남음",
  "app.error.postexposure": "죄송합니다만, 이미 허용된 재시도 횟수를 다 사용하셨습니다. 안타깝지만 본 설문 조사를 계속 진행하실 수 없습니다."
}