{
  "title": "﻿Platform Studi Lumen - Kebijakan Privasi",
  "date": "Juli 2022",
  "contactDetails": {
    "title": "Rincian kontak kami ",
    "body": [
      "Nama: Lumen Research Ltd (“Lumen”)",
      "Alamat: Screenworks, Unit 215, 22 Highbury Grove, London N5 2ER",
      "Nomor Telepon: +44(0)2037355199 ",
      "E-mail: dataprotection@lumen-research.com",
      "Referensi Pendaftaran KKI: ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "Kebijakan Privasi ini menerangkan jenis informasi yang kami kumpulkan dari peserta yang berpartisipasi dalam survei pelacakan mata di Platform Studi Lumen, dan kebijakan kami yang mengatur informasi pribadi apa pun yang mungkin dikumpulkan. "
      ],
      "heading": "Pengantar"
    },
    {
      "body": [
        "Dalam survei umumnya, Lumen tidak mengumpulkan informasi pribadi seperti yang didefinisikan dalam Peraturan Perlindungan Data Umum Inggris, melainkan akan mengumpulkan perkiraan titik pandang; tanggapan survei; data meta tentang kapan dan pada jenis perangkat apa saja pengumpulan data dilakukan; dan ID responden untuk tujuan penyerahan hadiah.",
        "Dalam survei umumnya, Anda akan diminta untuk duduk di depan webcam atau kamera depan perangkat Anda dan melakukan kalibrasi sehingga dapat dibuat perkiraan ke mana Anda melihat di layar Anda. Selama survei Anda akan diminta untuk melihat beberapa item (misalnya gambar, situs jaringan atau video) sementara alat survei memperkirakan ke mana Anda melihat. Data yang diambil dari 'pelacakan mata' ini adalah koordinat perkiraan titik pandang di layar Anda dari waktu ke waktu. Perangkat lunak ini menggunakan gambar-gambar Anda dari webcam Anda untuk membuat perkiraan ini namun tidak mengambil foto atau video Anda. ",
        "Anda mungkin juga akan ditanyai beberapa pertanyaan seputar diri Anda, pengalaman Anda, dan item apa saja yang telah Anda lihat selama survei. ",
        "Data dari survei ini dikirim ke Lumen Research Ltd (“Lumen”). Lumen akan menganalisis data dan membagikan hasilnya ke klien-kliennya. ",
        "Dalam survei ini, ID responden alfanumerik juga akan diteruskan oleh pihak ketiga ke Lumen agar Lumen dapat melacak responden mana yang menyelesaikan survei. Lumen akan membagikan informasi ini secara lengkap kepada pihak ketiga untuk tujuan penyerahan hadiah kepada mereka yang telah menyelesaikan survei. "
      ],
      "heading": "Jenis informasi yang kami kumpulkan"
    },
    {
      "body": [
        "Terkadang, responden mungkin akan diminta untuk berpartisipasi dalam proyek penelitian yang membutuhkan lebih dari satu periode pengumpulan data. Dalam hal ini, ID responden akan digunakan sebagai pengenal tetap untuk menghubungi kembali peserta tertentu untuk memulai keterlibatan mereka dalam latihan pengumpulan data berikutnya. Dalam skenario ini, ID responden akan dianggap sebagai informasi pribadi.  "
      ],
      "heading": "Jenis informasi pribadi yang kami kumpulkan "
    },
    {
      "body": [
        "Seperti survei pada umumnya, ID tetap responden akan diberikan kepada kami oleh pihak ketiga yang telah merekrut peserta untuk penelitian kami. Kami akan menggunakannya untuk menghubungi kembali individu untuk memulai keterlibatan mereka yang selanjutnya dalam penelitian dan mungkin akan membagikannya dengan pihak ketiga untuk memfasilitasi partisipasi berikutnya ini.",
        "Berdasarkan Peraturan Perlindungan Data Umum Inggris (UK GDPR), dasar hukum yang kami gunakan untuk memproses informasi ini adalah persetujuan Anda. Anda dapat menghapus persetujuan Anda kapan saja. Anda dapat melakukannya dengan cara menghubungi kami di dataprotection@lumen-research.com"
      ],
      "heading": "Bagaimana kami memperoleh informasi pribadi dan mengapa kami memilikinya"
    },
    {
      "body": [
        "Informasi Anda tersimpan dengan aman dan dienkripsi menggunakan teknologi SSL. Ini adalah teknologi enkripsi berstandar tinggi yang paling umum digunakan dan digunakan oleh bank untuk mengamankan transaksi perbankan dan komunikasi sensitif lainnya. Ini juga digunakan oleh Amazon, PayPal, eBay, dan situs-situs jaringan lainnya yang membutuhkan sistem keamanan tingkat perbankan. Para ilmuwan memperkirakan bahwa memecahkan enkripsi 256 bit menggunakan teknologi saat ini akan membutuhkan waktu yang jauh lebih lama dari usia alam semesta ini. Data yang dikumpulkan disimpan di server jaringan terjamin yang dikelola oleh Amazon Web Services (AWS). AWS adalah salah satu platform layanan komputasi cloud yang aman dan dapat diandalkan yang paling umum digunakan dan digunakan oleh layanan komersial besar berbasis cloud termasuk Netflix dan Expedia. ",
        "Kami menyimpan pengenal tetap selama periode waktu yang diperlukan untuk menghubungi kembali responden untuk penelitian multi-tahapan apa pun. Ini dapat bervariasi tergantung pada sifat penelitian dan akan diberitahukan ketika Anda menyetujui untuk berpartisipasi. Ketika pengumpulan data untuk proyek semacam itu telah selesai, pengidentifikasi akan disimpan hanya jika diperlukan untuk tujuan menganalisis dan melaporkan data. Ketika analisis dan pelaporan proyek telah selesai, kami akan membuang informasi pribadi ini dengan menghapusnya dari database kami."
      ],
      "heading": "Bagaimana kami menyimpan informasi pribadi Anda "
    }
  ],
  "dataProtection": {
    "body": [
      "Di bawah hukum perlindungan data, Anda memiliki hak di antaranya:"
    ],
    "bullets": [
      {
        "title": "Hak Anda atas akses",
        "text": [
          "Anda berhak meminta salinan informasi pribadi Anda kepada kami. "
        ]
      },
      {
        "title": "Hak Anda untuk meralat",
        "text": [
          "Anda berhak meminta kami untuk meralat informasi pribadi yang menurut Anda tidak sesuai. Anda juga berhak meminta kami untuk melengkapi informasi yang menurut Anda belum lengkap. "
        ]
      },
      {
        "title": "Hak Anda untuk menghapus",
        "text": [
          "Anda berhak meminta kami untuk menghapus informasi pribadi Anda dalam keadaan tertentu. "
        ]
      },
      {
        "title": "Hak Anda untuk membatasi pemrosesan",
        "text": [
          "Anda berhak meminta kami untuk membatasi pemrosesan informasi pribadi Anda dalam keadaan tertentu. "
        ]
      },
      {
        "title": "Hak Anda untuk menarik kembali persetujuan",
        "text": [
          "Anda berhak untuk menarik kembali persetujuan Anda untuk memproses informasi pribadi Anda."
        ]
      },
      {
        "title": "Hak Anda atas portabilitas data",
        "text": [
          "Anda berhak meminta kami untuk mentransfer informasi pribadi yang Anda berikan kepada kami ke organisasi lain, atau kepada Anda, dalam keadaan tertentu.",
          "Anda tidak diwajibkan untuk membayar biaya apa pun untuk menggunakan hak Anda. Apabila Anda mengajukan permintaan, kami memiliki waktu satu bulan untuk menanggapi Anda.",
          "Mohon hubungi kami di dataprotection@lumen-research.com jika Anda ingin mengajukan permintaan."
        ]
      }
    ],
    "title": "Hak perlindungan data Anda"
  },
  "complaints": {
    "body": [
      "Jika Anda memiliki kekhawatiran tentang penggunaan informasi pribadi Anda oleh kami, Anda dapat mengajukan keluhan kepada kami melalui dataprotection@lumen-research.com.",
      "Anda juga dapat mengajukan keluhan ke KKI jika Anda tidak menyukai cara kami menggunakan data Anda."
    ],
    "address": {
      "body": [
        "Kantor Komisaris Informasi",
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ],
      "title": "Alamat KKI:            "
    },
    "title": "Cara mengajukan keluhan",
    "number": "Nomor telepon bantuan: 0303 123 1113",
    "website": "Situs jaringan KKI: https://www.ico.org.uk"
  }
}
