import anime from "animejs";
import React, {useRef} from "react";
import { useSelector } from "react-redux";
import Loading from "../components/shared/Loading";
import { ChangeLoadingOverlayState } from "../reducers/PageState/actions";
import { store } from "../store";

const LoadingOverlay = () => {
  let overlayRef = useRef();

  let { overlayState, hideLoading } = useSelector((state) => state.pageReducer);

  if (overlayRef && overlayState === "transitionIn") {
    anime({
      targets: overlayRef.current,
      opacity: 1,
      duration: 250,
      easing: "linear",
      elasticity: "none",
      begin: () => {
        if (overlayRef.current) {
          overlayRef.current.style.zIndex = 100000;
          overlayRef.current.style.height = "100%";
        }
      },
      complete: () => {
        store.dispatch(ChangeLoadingOverlayState("show"))
      }
    });
  } else if (overlayRef && overlayState === "transitionOut") {
    anime({
      targets: overlayRef.current,
      opacity: 0,
      duration: 1000,
      easing: "linear",
      elasticity: "none",
      complete: () => {
        if (overlayRef.current) {
          overlayRef.current.style.zIndex = -1000;
          overlayRef.current.style.height = 0;
        }
        store.dispatch(ChangeLoadingOverlayState("hide"))
      },
    });
  } else if (overlayRef.current && overlayRef.current.style.zIndex === "100000" && overlayState === "hide") {
    overlayRef.current.style.zIndex = -1000;
    overlayRef.current.style.height = 0;
  }

  return (
    <div style={{ zIndex: 100000, height: "100%" }} ref={overlayRef} className="ls-overlay">
      {!hideLoading && <Loading useTimer={false}></Loading>}
    </div>
  );
};

export default LoadingOverlay;
