import events from "./events";
import { routeType } from "../../components/util/util";
import { store } from "../../store";
import { toggleExtendedLogger } from "../../components/lumenController/actions";
import ApiController from "../Api/ApiController";
import LogUserActionInternal from "@lumen-developer/lumen-common-js/loguseractioninternal.bundle";

class LoggerController {
  constructor() {
    this.extendedLogger = false;
    this.participant = "";
    this.device = "";
    this.platform = "";
    this.studyId = 0;
    this.cellId = 0;
    this.events = events
  }

  initExtendedLogger = (participant, device, platform, browser, studyId) => {
    store.dispatch(toggleExtendedLogger());
    this.extendedLogger = true;
    this.participant = participant;
    this.device = device;
    this.platform = platform;
    this.browser = browser;
    this.studyId = studyId;
  };

  setCellId = (cellId) => {
    this.cellId = cellId;
  };

  sendLog = (
    level,
    title,
    message,
    sectionOverride,
    panelistAction,
    panelistActionTitle,
    panelistActionData
  ) => {
    let section = sectionOverride || routeType();
    if (message !== "") {
      console.log(`[LRSP] - ${section}|${level}|${title} - ${message}`);
    } else {
      console.log(`[LRSP] - ${section}|${level}|${title}`);
    }

    if (panelistAction) {
      this.panelistAction(`LS:${panelistActionTitle}`, panelistActionData);
    }
  };

  panelistAction = (action, log = null) => {
    let { participant, studyId, panelCompanyCode, newTrackingSystem } =
      store.getState().studyReducer;

    if (studyId && participant) {
      if (!!log) {
        LogUserActionInternal(
          participant,
          studyId,
          action,
          {
            log: log,
          },
          panelCompanyCode,
          newTrackingSystem
        );
      } else {
        LogUserActionInternal(
          participant,
          studyId,
          action,
          {},
          panelCompanyCode,
          newTrackingSystem
        );
      }
    }
  };

  updateLastSectionCompleted = (action) => {
    let { studyId } = store.getState().studyReducer;
    ApiController.request.participation.updatelastsectioncompleted(
      studyId,
      action
    );
  };

  sendToExtendedLogger = (
    lastSection,
    type,
    errorName,
    errorMessage,
    actionName,
    actionDetail
  ) => {
    if (this.extendedLogger) {
      let timestamp = Date.now();

      let url = `${
        process.env.REACT_APP_PARTICIPATION_URL
      }/logger/progress?participant=${this.participant}&device=${
        this.device
      }&platform=${this.platform}&browser=${this.browser}&study_id=${
        this.studyId
      }&cell_id=${
        this.cellId ? this.cellId : 0
      }&timestamp=${timestamp}&last_section=${lastSection}&type=${type}`;

      switch (type) {
        case "error":
          url = `${url}&error_name=${errorName}&error_message=${errorMessage}`;
        case "action":
          url = `${url}&action_name=${actionName}&action_detail=${actionDetail}`;
      }

      navigator.sendBeacon(url);
    }
  };
}

export default new LoggerController();
