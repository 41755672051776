import ar from "./ar.json";
import cl from "./cl.json";
import da from "./da.json";
import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import hr from "./hr.json";
import id from "./id.json";
import ja from "./ja.json";
import no from "./no.json";
import po from "./po.json";
import pt from "./pt.json";
import sr from "./sr.json";
import sv from "./sv.json";
import tr from "./tr.json";

export default { ar, cl, da, de, en, es, fr, id, ja, no, po, pt, sr, sv, tr, hr};
