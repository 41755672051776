import * as DEBUGLOG from "../../../components/util/debugLog";
import requestTimer from "../../requestTimer";
import tabChangeListener from "../../../utils/listeners/TabChangeListener";
import participationUrl from "../participationUrl";

const questionResponse = async (studyId, body) => {
    tabChangeListener.setWaitingForApi("questionResponse");
    let questionResponseRequestDate = DEBUGLOG.debugLogRequestStart("question-response");
    let response = await fetch(participationUrl() + "/participation/studies/" + studyId + "/question-response", {
      method: 'POST',
      body: JSON.stringify(body)
    })

    DEBUGLOG.debugLogRequestFinish(questionResponseRequestDate);

    if (response.ok) {
        let responseBody = await response.json()
        tabChangeListener.setWaitingForApi("");
        return responseBody
    } else {
      let e = new Error(response.statusText);
      e.name = response.status || "";
      throw e
    }
}

export default (...args) => requestTimer(() => questionResponse(...args))