import { routeType } from "../../components/util/util";
import { store } from "../../store";
import LoggerController from "./LoggerController";
import LogUserActionInternal from "@lumen-developer/lumen-common-js/loguseractioninternal.bundle";

class PlatformEvent {
  constructor(
    level,
    title,
    panelistActionTitle,
    useExtendedLogger
  ) {
    this.level = level;
    this.title = title;
    this.panelistActionTitle = panelistActionTitle;
    this.useExtendedLogger = useExtendedLogger;
  }

  log = (message, sectionOverride) => {
    let section = sectionOverride || routeType();
    if (message !== "") {
      console.log(
        `[LRSP] - ${section}|${this.level}|${this.title} - ${message}`
      );
    } else {
      console.log(`[LRSP] - ${section}|${this.level}|${this.title}`);
    }

    if(this.useExtendedLogger){
        this.extendedLog(message)
    }
  };

  extendedLog = (message) => {
    let page = routeType();

    LoggerController.sendToExtendedLogger(
      page,
      "action",
      undefined,
      undefined,
      "checkfail",
      message
    );
  };

  panelistAction = (message, data, actionOverride) => {
    this.log(message);

    let { participant, studyId, panelCompanyCode, newTrackingSystem } =
      store.getState().studyReducer;

    if (studyId && participant) {
      if (!!data) {
        LogUserActionInternal(
          participant,
          studyId,
          `LS:${actionOverride || this.panelistActionTitle}`,
          {
            log: data,
          },
          panelCompanyCode,
          newTrackingSystem
        );
      } else {
        LogUserActionInternal(
          participant,
          studyId,
          `LS:${actionOverride || this.panelistActionTitle}`,
          {},
          panelCompanyCode,
          newTrackingSystem
        );
      }
    }
  };
}

export default PlatformEvent;
