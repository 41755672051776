import { default as PreviewCss } from "../components/PreviewTab/preview.css"; // eslint-disable-line import/no-webpack-loader-syntax
import StyleController from "../app/styleController";
import LumenEyeTracking from "../components/lumenTracker";
import { store } from "../store";
import DeviceController from "../utils/controllers/DeviceController";

class PreviewController {
  constructor() {
    this.active = false;
  }

  initPreview = async (stepData) => {
    this.active = true;
    StyleController.injectCss("preview", PreviewCss);

    let stepType = stepData.type;

    if (stepData.type === "StimulusStep") {
      if (
        stepData.stim_implementation_type === 2 &&
        !DeviceController.getIsMobile()
      ) {
        stepType = "Zoom Step";
      } else if (stepData.stim_implementation_config) {
        stepType = "Bespoke Step";
      } else if (stepData.stim_url.includes(".mp4")) {
        stepType = "Video Step";
      } else if (
        stepData.stim_implementation_type === 4 ||
        stepData.stim_implementation_type === 6
      ) {
        stepType = "Shelf Step";
      } else {
        stepType = "Image Step";
      }
    }

    let payload = {
      stepType,
    };

    if (
      stepType === "Video Step" ||
      stepType == "Shelf Step" ||
      stepType == "Bespoke Step"
    ) {
      payload.timeToMoveNext = -1000;
      payload.timeToShowNext = -1000;
    } else if (stepType !== "SocialStep") {
      payload.timeToMoveNext = stepData.time_to_move_next_button_ms;
      payload.timeToShowNext = stepData.time_to_show_next_button_ms;
    }

    if (stepType === "Video Step") {
      payload.extraData = {
        Autoplay: !!stepData.video_autoplay,
        "Show Controls": !!stepData.video_showcontrols,
        Fullscreen: !!stepData.video_fullscreen,
      };
    } else if (stepType === "SocialStep") {
      let socialDetails = await this.fetchSocialData(stepData.social_persona);
      payload.timeToMoveNext = socialDetails.auto_move ? socialDetails.auto_move_time * 1000 : 0;
      payload.timeToShowNext = socialDetails.show_next ? socialDetails.show_next_time * 1000 : 0;

      payload.extraData = {
        Template: stepData.social_template.split("_")[1],
        "Persona Id": stepData.social_persona_id,
        UUID: stepData.social_persona,
        "Prompts Active": !!socialDetails.prompts,
        "Auto Move Type": socialDetails.auto_move_type === 0 ? "Page Start" : socialDetails.auto_move_type === 1 ? "Ad Start" : "Ad End",
        "Show Next Type": socialDetails.show_next_type === 0 ? "Page Start" : socialDetails.show_next_type === 1 ? "Ad Start" : "Ad End"
      };
    }

    store.dispatch({ type: "INIT_PREVIEW", payload });
  };

  fetchSocialData = async (personaId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let response = await fetch(
      `${process.env.REACT_APP_SOCIAL_API}/db/getPerson`,
      {
        method: "POST",
        body: JSON.stringify({ id: personaId }),
        redirect: "follow",
        headers: myHeaders,
      }
    );

    let responseJson = await response.json();

    return responseJson[0];
  };

  stepComplete = () => {
    store.dispatch({ type: "PREVIEW_STEP_COMPLETE" });
  };

  showHud = (which) => {
    store.dispatch({ type: "PREVIEW_SHOW_HUD", payload: which });
    LumenEyeTracking.showHud(which);
  };

  startTimers = () => {
    if (this.active) {
      store.dispatch({ type: "PREVIEW_START_TIMERS" });
    }
  };

  showNext = () => {
    if (this.active) {
      store.dispatch({ type: "PREVIEW_SHOW_NEXT" });
    }
  };

  moveNext = () => {
    if (this.active) {
      store.dispatch({ type: "PREVIEW_MOVE_NEXT" });
    }
  };
}

export default new PreviewController();
