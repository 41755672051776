const initialState = {
  device_dimensions: {
    browser_zoom: 0.0,
    browser_width: 0.0,
    browser_height: 0.0,
    browser_left: 0.0,
    browser_top: 0.0,
    viewport_width: 0.0,
    viewport_height: 0.0,
    screen_resolution_width: 0.0,
    screen_resolution_height: 0.0,
  },
  dimensions_loaded:false,
  device_details: {
    name: "",
    version: "",
    product: "",
    manufacturer: "",
    layout: "",
    os: "",
    osString: "",
    description: "",
    iosModelPredicition: "",
    connectionType: "",
    connectionSpeed: -1,
    isMobile: false,
    device: "",
    graphics: ""
  },
  details_loaded:false

};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_DEVICE_DIMENSIONS":
      return {...state, device_dimensions:{...state.device_dimensions, ...action.payload}, dimensions_loaded: true}
    case "UPDATE_DEVICE_DETAILS":
      return {...state, device_details:{...state.device_details, ...action.payload}, details_loaded: true}
    case "UPDATE_CONNECTION_SPEED":
      return {...state, device_details:{...state.device_details, connectionSpeed: action.payload}}
    default:
      return state;
  }
}
