export function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

export function detectQueryString(currentUrl) {
    // regex pattern for detecting ? character
    var patternqm = new RegExp(/\?+/g);

    let qmbool = patternqm.test(currentUrl)

    var patternh = new RegExp(/\#+/g);

    let hbool = patternh.test(currentUrl)

    return qmbool || hbool;
}

export function dedupeByKey(arr, key) {
    const temp = arr.map(el => el[key]);
    return arr.filter((el, i) =>
      temp.indexOf(el[key]) === i
    );
  }

export function getStudyUrl(studyId, sr, stepIndex) {
  return `/${studyId}/${sr.cellId}/${sr.stepData[stepIndex].id}/`
}

export const routeTypeFrom = (pathname) => {
  let params = pathname.split("/").filter(n => n);
  if(pathname.includes("landing")){
    return "landing"
  } else if(pathname.includes("prestart")){
    return "prestart"
  } else if(pathname.includes("start")) {
    return "index"
  } else if (pathname.includes("survey")) {
    return "demographics"
  } else if (pathname.includes("instructions")) {
    return "instructions"
  } else if (pathname.includes("setup")) {
    return "setup"
  } else if (pathname.includes("completion")) {
    return "completion"
  } else if (pathname.includes("error")) {
    return "error"
  } else if (pathname.includes("invalidation")) {
    return "invalidation"
  } else if (pathname.includes("debug")) {
    return "debug"
  } else if (pathname.includes("preview")) {
    return "preview"
  } else if (pathname.includes("restore")) {
    return "restore"
  } else if (pathname.includes("prestart")) {
    return "prestart"
  } else if (params.length == 3) {
    return "init"
  } else if (params.length == 4) {
    return "study"
  } else {
    return null
  }
}

export const routeType = () => {
    return routeTypeFrom(window.location.pathname)
  }

export function incontextStepData(studyReducer, currentStepId, language = "en", nextText = "Next", region = 1) {
  let currentStepDetail = studyReducer.stepData.filter(
    (step) => step.id == parseInt(currentStepId)
  )[0];

  let incompleteStepDetails = studyReducer.stepData.filter(
    (step) => step.order > currentStepDetail.order
  );

  return {
    "sessionId": studyReducer.sessionId,
    "studyId": studyReducer.studyId,
    "cellId": studyReducer.cellId,
    "stepId": currentStepId,
    "access_code": studyReducer.accessCode,
    "language": language,
    "participant_id": studyReducer.participantId,
    "participant": studyReducer.cleanedParticipantReference,
    "calibrationData": "",
    "showGazePoints": studyReducer.showGaze,
    "nextUrl": incompleteStepDetails.length > 0 ? incompleteStepDetails[0].url ? incompleteStepDetails[0].url : "" : "",
    "js_enabled": studyReducer.brokerDetails.js_enabled,
    "eyetracking_enabled": studyReducer.brokerDetails.eyetracking_enabled,
    "broker": studyReducer.brokerDetails.name,
    "timeHiddenAtStart": 2000,
    "nextButtonTime": currentStepDetail.time_to_show_next_button_ms > 0 ? currentStepDetail.time_to_show_next_button_ms / 1000 : 0,
    "forcedMoveTime": currentStepDetail.time_to_move_next_button_ms > 0 ? currentStepDetail.time_to_move_next_button_ms / 1000 : 0,
    "nextButtonText": nextText,
    "nextButtonTypeId": currentStepDetail.next_type_id,
    "nextButtonType": currentStepDetail.next_type,
    "environment": process.env.REACT_APP_LUMEN_ENV_VALUE,
    "region": region,
    "next_step_details": incompleteStepDetails[0],
    "current_step_details": currentStepDetail,
    "incomplete_step_details": incompleteStepDetails,
    "newTrackingSystem":studyReducer.newTrackingSystem
  }
}


export function reformatParams(urlParams, ignoreList) {
  let paramArr = [];
  let newParams = "";
  for (let pair of urlParams.entries()) {
    if (ignoreList.indexOf(pair[0]) < 0) {
      paramArr.push(pair);
    }
  }
  paramArr.forEach((pair, i) => {
    if (i == 0) {
      newParams = newParams.concat(`?${pair[0]}=${pair[1]}`);
    } else {
      newParams = newParams.concat(`&${pair[0]}=${pair[1]}`);
    }
  });

  return newParams;
}