import React from 'react';
import Button from 'react-bootstrap/Button';
import {connect} from 'react-redux';
import LSModal from '../modal';
import {FormattedMessage} from 'react-intl';
import { toggleTakePartModal } from '../actions';
import { progressAvailableFalse, invalidateUser } from '../../../reducers/actions';
import { navigateWithParams } from "@history/";

function LSTakePartModalCont(props) {
  let progressButton = () => {
    props.toggleTakePartModal();
    props.progressAvailableFalse();
    let continueEvent = new Event('LSModalTakePart')
    window.dispatchEvent(continueEvent)
  }
  let invalidateLink = () => {
    props.toggleTakePartModal();
    props.invalidateUser();
    navigateWithParams("invalidation");
  }
  var modalContent = <React.Fragment>
                      <p><FormattedMessage id="app.takePartModal.line1" defaultMessage="If you leave now you will not be eligible for any participation reward." description="Take part modal"/></p>
                      <p><FormattedMessage id="app.takePartModal.line2" defaultMessage="All you need is a webcam!" description="Take part modal"/></p>
                      <Button onClick={progressButton}>
                        <FormattedMessage id="app.button.takepart" defaultMessage="OK - I'll Take Part" description="Take Part Button"/>
                      </Button>
                      <p></p>
                      <p><a onClick={invalidateLink}><FormattedMessage id="app.takePartModal.reason1" defaultMessage="I do not have a webcam." description="Invalidate reason"/></a></p>
                      <p><a onClick={invalidateLink}><FormattedMessage id="app.takePartModal.reason2" defaultMessage="I'm not comfortable with my webcam being used for this survey." description="Invalidate reason"/></a></p>
                      <p><a onClick={invalidateLink}><FormattedMessage id="app.takePartModal.reason3" defaultMessage="I don't have time for this now." description="Invalidate reason"/></a></p>
                      <p><a onClick={invalidateLink}><FormattedMessage id="app.takePartModal.reason4" defaultMessage="Other reason." description="Invalidate reason"/></a></p>
                    </React.Fragment>
  return (
    props.displayTakePartModal
    ? <LSModal title="Take Part" titleId="app.index.takepart" content={modalContent} modalToggle={props.toggleTakePartModal}/>
    : null)
}

const mapStateToProps = function(state) {
  return {displayTakePartModal: state.modalReducer.displayTakePartModal}
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    toggleTakePartModal: () => dispatch(toggleTakePartModal()),
    progressAvailableFalse: () => dispatch(progressAvailableFalse()),
    invalidateUser: () => dispatch(invalidateUser())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LSTakePartModalCont);
