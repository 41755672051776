import { default as BootstrapCss } from "./bootstrap.css"; // eslint-disable-line import/no-webpack-loader-syntax
import { default as BasePageCss } from "../layouts/BasePage.css"; // eslint-disable-line import/no-webpack-loader-syntax
import { default as StudyStepsCss } from "../components/steps/studySteps.css"; // eslint-disable-line import/no-webpack-loader-syntax
import { routeType } from "../components/util/util";
import { store } from "../store";
import { changeHeaderFooterState } from "../reducers/PageState/actions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { default as MainCss } from "./App.css"; // eslint-disable-line import/no-webpack-loader-syntax

export default class StyleController {
  static injectCss(cssId, styles) {
    if (!document.getElementById(cssId)) {
      let styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.setAttribute("id", cssId); // should be configured per theme
      styleSheet.innerText = styles;
      document.head.appendChild(styleSheet);
    }
  }

  static ejectCss(stylesheetId) {
    try {
      var stylesheet = document.getElementById(stylesheetId);
      stylesheet.parentNode.removeChild(stylesheet);
    } catch {
      console.log("no css to eject!");
    }
  }

  static run(which) {
    if (which) {
      this.injectCss("bootstrapstylesheet", BootstrapCss);
      this.injectCss("dynamicstylesheet", MainCss);
      this.injectCss("sharedstylesheet", BasePageCss);
      if (routeType() === "study" || routeType() === "preview") {
        this.injectCss("stepsstylesheet", StudyStepsCss);
      }
    } else {
      this.ejectCss("bootstrapstylesheet");
      this.ejectCss("dynamicstylesheet");
      this.ejectCss("sharedstylesheet");
      if (routeType() === "study" || routeType() === "preview") {
        this.ejectCss("stepsstylesheet");
      }
    }
  }

  static setVh() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  static addHeightListener() {
    window.addEventListener("resize", this.setVh);
  }

  static async hideHeaderFooter(which) {
    let { headerState } = store.getState().pageReducer;

    if (
      (which && headerState === "show") ||
      (!which && headerState === "hide")
    ) {
      if (which && headerState === "show") {
        store.dispatch(changeHeaderFooterState("transitionOut"));
      } else if (!which && headerState === "hide") {
        store.dispatch(changeHeaderFooterState("transitionIn"));
      }

      return new Promise((resolve) => {
        const checkHeaderState = () => {
          let { headerState } = store.getState().pageReducer;

          if (which && headerState === "hide") {
            resolve();
          } else if (!which && headerState === "show") {
            resolve();
          }

          setTimeout(checkHeaderState, 100);
        };

        checkHeaderState();
      });
    }

    return true;
  }

  static loadFont(fontName) {
    let fontMap = {
      "Space Grotesk": {
        cssValue: "'Space Grotesk', sans-serif",
        import:
          "@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');",
      },
    };

    if (fontMap[fontName]) {
      if(!document.getElementById(`font-${fontName.replace(" ", "-")}`)){
        let fontStyle = document.createElement("style");
        fontStyle.innerText = fontMap[fontName].import;
        fontStyle.id = `font-${fontName.replace(" ", "-")}`;
        document.body.appendChild(fontStyle);

        const cleanUp = () => {
          let fontStyle = document.getElementById(`font-${fontName.replace(" ", "-")}`)
          fontStyle.remove()
        };

        return { value: fontMap[fontName].cssValue, cleanUp };
      }

      const cleanUp = () => {
        let fontStyle = document.getElementById(`font-${fontName.replace(" ", "-")}`)
        fontStyle.remove()
      };

      return { value: fontMap[fontName].cssValue, cleanUp };

      
    }

    return { value: false, cleanUp: () => {} };
  }
}
