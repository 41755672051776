import { store } from "../../../../store";
import Endpoint from "../../Endpoint";

const getPayload = (frame_rate) => {
  let { participantId, accessCode } = store.getState().studyReducer;

  let body = {
    participant_id: participantId,
    access_code: accessCode,
    frame_rate: frame_rate,
  };

  return body
};

export default (study_id, frame_rate) => {
  return new Endpoint(
    "complete-position-calibration",
    `${process.env.REACT_APP_PARTICIPATION_URL}/participation/studies/${study_id}/complete-position-calibration`,
    "POST",
    () => getPayload(frame_rate),
    undefined,
    false
  );
};
