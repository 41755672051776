import BaseStep from "./BaseStep";
import { BROKER_TYPES } from "../../../types/types";
import LumenEyeTracking from "..";
import config from "../config";

class Calibrate extends BaseStep {
  constructor() {
    super("calibrate", "Calibration");

    this.startHandler = null;
    this.element = null;
    this.timeout = 0;

    this.cleanUp = this.cleanUp.bind(this);
    this.onError = this.onError.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.continueCalibration = this.continueCalibration.bind(this);
    this.secondaryCalibration = this.secondaryCalibration.bind(this);
    this.runProcess = this.runProcess.bind(this);
  }

  cleanUp() {
    this.removeErrorListener();

    if (this.startHandler) {
      this.startHandler.remove();
    }

    if (LumenEyeTracking.broker.trackersController) {
      LumenEyeTracking.broker.trackersController.validatorCalibrator().stop();
    }

    if (LumenEyeTracking.pointListener) {
      LumenEyeTracking.pointListener.remove();
    }

    LumenEyeTracking.removeInstructions();

    let calibrationCanvas = document.getElementById("lr_calibrator");
    if (calibrationCanvas) {
      calibrationCanvas.remove();
    }

    let calibrationElement = document.getElementById("calibrationElement");
    if (calibrationElement) {
      calibrationElement.style.display = "none";
    }
  }

  onError(error) {
    if (this.state !== "cancelled") {
      super.onError();

      //event from errPassthrough
      if (error && error.detail) {
        LumenEyeTracking.lastError = new Error(error.detail.error.message);
        LumenEyeTracking.lastError.name = "CalibrateError";
        LumenEyeTracking.lastError.retryCode = error.detail.error.retryCode;
      } else if (error && error.message) {
        LumenEyeTracking.lastError = new Error(error.message);
        LumenEyeTracking.lastError.name = "CalibrateError";
      } else {
        LumenEyeTracking.lastError = new Error("Unable to Calibrate!");
        LumenEyeTracking.lastError.name = "CalibrateError";
      }
      LumenEyeTracking.errorHandler(
        this.resolver,
        this.cleanUp,
        "calibrate",
        LumenEyeTracking.setupStart
      );
    }
  }

  async onComplete() {
    if (this.state !== "cancelled") {
      super.onComplete()

      if (!LumenEyeTracking.errored) {
        if (LumenEyeTracking.pointListener) {
          LumenEyeTracking.pointListener.remove();
        }

        LumenEyeTracking.setupComplete = true;

        this.removeErrorListener();

        try {
          if (LumenEyeTracking.broker.saveModel) {
            LumenEyeTracking.broker.saveModel(
              "lr_model_data",
              "lr_errorcorrection_data"
            );
          }
          this.element.style.display = "none";
          this.resolver({ success: true });
        } catch (e) {
          e.name = "ModelSaveError";
          this.element.style.display = "none";
          this.resolver({ success: false, error: e });
        }
      }
    }
  }

  async continueCalibration() {
    if (this.element) {
      this.element.removeEventListener("click", this.continueCalibration);
      this.element.removeEventListener("touchstart", this.continueCalibration);
      LumenEyeTracking.removeInstructions();
      this.element.style.background = "white";
      LumenEyeTracking.startCalibrationTime = Date.now();
      LumenEyeTracking.broker.calibrate(
        this.element,
        this.timeout
      ).then(async () => {
        if (LumenEyeTracking.multiPartCalib) {
          this.element.style.height =
            this.element.parentElement.offsetHeight + "px";
          this.element.style.display = "unset";
          this.element.style.background = "rgba(0, 0, 0, 0.5)";

          LumenEyeTracking.createInstructions(
            this.element,
            "bottom",
            config.instructions.calibrate.bottom()
          );
          LumenEyeTracking.createInstructions(
            this.element,
            "top",
            "LOOK AT EACH DOT AND ROTATE YOUR HEAD TO JOIN UP THE RING."
          );

          this.element.addEventListener("click", this.secondaryCalibration);
          this.element.addEventListener(
            "touchstart",
            this.secondaryCalibration
          );
          window.LRSkipStep = () => {
            this.secondaryCalibration();
            window.LRSkipStep = () => {};
          };
        } else {
          await this.onComplete();
        }
      }).catch(async () => {
        await this.onError();
      });
    }
  }

  secondaryCalibration() {
    this.element.removeEventListener("click", this.secondaryCalibration);
    this.element.removeEventListener("touchstart", this.secondaryCalibration);
    LumenEyeTracking.removeInstructions();
    this.element.style.background = "white";
    LumenEyeTracking.broker.calibrate(
      this.element,
      this.timeout
    ).then(async () => {
      await this.onComplete();
    }).catch(async () => {
      await this.onError();
    });
  }

  async runProcess(element, timeout = 120000) {
    this.element = element;
    this.timeout = timeout;

    LumenEyeTracking.currentStep = "calibrate";

    LumenEyeTracking.calibrationPoint = 0;
    LumenEyeTracking.calibrationTotal = 0;
    LumenEyeTracking.startPointListener("calibration");

    function calibrationShakeFix() {
      let calibShake = document.querySelector("body > #lr_calibration_shake");
      let isIOS =
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      if (isIOS && !!calibShake) {
        calibShake.style.position = "fixed";
        calibShake.style.pointerEvents = "none";
        calibShake.style.width = "1px";
        calibShake.style.height = "1px";
        calibShake.style.top = "0";
        calibShake.style.opacity = "0.1";
      } else if (!!calibShake) {
        calibShake.style.display = "none";
      }
    }

    if (LumenEyeTracking.broker.trackersController) {
      this.startHandler = LumenEyeTracking.broker.trackersController
      .eventManager()
      .subscribe("onCalibrationStarted", LumenEyeTracking.startPointTimeout);
    }

    this.setupErrorListener(this.onError);

    if (
      LumenEyeTracking.brokerType == BROKER_TYPES.MOUSE ||
      LumenEyeTracking.brokerType == BROKER_TYPES.MOBILEMOUSE ||
      LumenEyeTracking.brokerType == BROKER_TYPES.NO_EYETRACKING
    ) {
      LumenEyeTracking.startCalibrationTime = Date.now();
      await this.onComplete();
    } else if (LumenEyeTracking.brokerType == BROKER_TYPES.AUDIBLE) {
      LumenEyeTracking.startCalibrationTime = Date.now();
      LumenEyeTracking.broker.calibrate(
        this.element,
        this.timeout
      ).then(async () => {
        await this.onComplete();
      }).catch(async () => {
        await this.onError();
      });
    } else if (LumenEyeTracking.brokerType == BROKER_TYPES.X2) {
      LumenEyeTracking.startCalibrationTime = Date.now();
      LumenEyeTracking.broker.calibrate(
        this.element,
        this.timeout
      ).then(async () => {
        await this.onComplete();
      }).catch(async () => {
        await this.onError();
      });
    } else {
      if (this.element) {
        this.element.style.height =
          this.element.parentElement.offsetHeight + "px";
        this.element.style.display = "unset";

        LumenEyeTracking.createInstructions(
          this.element,
          "top",
          config.instructions.calibrate.top()
        );
        LumenEyeTracking.createInstructions(
          this.element,
          "bottom",
          config.instructions.calibrate.bottom()
        );
      }

      document.arrive("body > #lr_calibration_shake", () => {
        document.unbindArrive();
        calibrationShakeFix();
      });

      if (this.element) {
        this.element.addEventListener("click", this.continueCalibration);
        this.element.addEventListener("touchstart", this.continueCalibration);

        window.LRSkipStep = () => {
          this.continueCalibration();
          window.LRSkipStep = () => {};
        };
      }
    }
  }
}

export default Calibrate;
