export const PROGRESS_STATE = "PROGRESS_STATE";
export const REGRESS_STATE = "REGRESS_STATE";
export const CHANGE_STATE_TO = "CHANGE_STATE_TO";
export const ERROR_STATE = "ERROR_STATE";
export const DEBUG_STATE = "DEBUG_STATE";
export const INVALIDATE_USER = "INVALIDATE_USER";
export const PROGRESS_AVAILABLE_FALSE = "PROGRESS_AVAILABLE_FALSE";
export const PROGRESS_AVAILABLE_TRUE = "PROGRESS_AVAILABLE_TRUE"
export const IS_LOADING_FALSE = "IS_LOADING_FALSE";
export const IS_LOADING_TRUE = "IS_LOADING_TRUE"
export const STORE_DEVICE_INFO = "STORE_DEVICE_INFO";
export const SUBMISSION_IN_PROGRESS_TRUE = "SUBMISSION_IN_PROGRESS_TRUE";
export const SUBMISSION_IN_PROGRESS_FALSE = "SUBMISSION_IN_PROGRESS_FALSE";
export const PROGRESS_BAR_FINISHED = "PROGRESS_BAR_FINISHED";
export const PROGRESS_BAR_TIMING = "PROGRESS_BAR_TIMING";
export const SET_REGION = "SET_REGION";
export const SET_DEBUG_LOG = "SET_DEBUG_LOG";
export const SET_PATH = "SET_PATH";
export const SET_SPEEDTEST = "SET_SPEEDTEST";
export const STORE_CLICK_DATA = "STORE_CLICK_DATA"
export const SET_CLICK_DATA = "SET_CLICK_DATA"
export const RESET_CLICK_DATA = "RESET_CLICK_DATA"
export const STOP_CLICK_LISTENER = "STOP_CLICK_LISTENER"
export const SET_LAST_USER_ACTION = "SET_LAST_USER_ACTION"
export const SET_WAITING_FOR_API = "SET_WAITING_FOR_API"
export const RESET_LOADED_AT = "RESET_LOADED_AT"