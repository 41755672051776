import React from 'react';
import {connect} from 'react-redux';
import LSModal from '../modal'
import {toggleFaqModal} from '../actions'
import {FormattedMessage} from 'react-intl';
import { store } from '../../../store';

const modalContent = () => {
  return <React.Fragment>
    <p><b><FormattedMessage id="app.faq.question1" defaultMessage="Q: What is ViewPoints and what does taking part in an eye tracking survey involve?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer1" defaultMessage="A: This survey involves the use of eye tracking technology. You will be asked to sit in front of your device’s webcam or front-facing camera and perform a calibration so that an estimate can be made of where you are looking on your screen. During the survey you will be asked to view some items (eg images, websites or videos) whilst the survey tool estimates where you are looking. The data that is captured from this ‘eye tracking’ are the coordinates of the estimated gaze points on your screen over time. The software uses images of you from your webcam to make these estimates but does not capture photos or videos of you. You may also be asked some questions about yourself, your experience and the items you have seen during the survey. The data is sent to Lumen Research Ltd. Lumen Research will analyse the data and share results with its clients." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question2" defaultMessage="Q: Why does the software require a webcam / camera and how is this used?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer2" defaultMessage="A: The software uses images from your webcam or device’s camera to estimate where you are looking on a screen. It does this using a combination of facial tracking and eye tracking software that analyses images locally on your machine in real time. These images are processed and shown to you during calibration so that you can correctly position yourself but the images are not recorded so your privacy is protected. The data that is recorded and collected from the eye tracking is not images but numeric data about estimated gaze points on the stimulus over time." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question3" defaultMessage="Q: Do you take images of me whilst doing the eye tracking?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer3" defaultMessage="A: The only time images are used is to allow you to correctly position yourself during calibration. These images are not saved, and only seen by you. The data that is recorded and collected from the eye tracking is not images but numerical data about estimated gaze points on the stimulus over time." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question4" defaultMessage="Q: What data is collected during the survey?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer4.line1" defaultMessage="A: The software will collect some or all of the following five types of data:" description="FAQ Content"/></p>
    <ul>
      <li><FormattedMessage id="app.faq.answer4.line2" defaultMessage="“User ID” - The User ID used to identify respondents (for the purpose of administering participation rewards);" description="FAQ Content"/></li>
      <li><FormattedMessage id="app.faq.answer4.line3" defaultMessage="“Gaze data” – Estimates of where users are looking on their screen when shown stimuli on the screen" description="FAQ Content"/></li>
      <li><FormattedMessage id="app.faq.answer4.line4" defaultMessage="“Survey data” – Responses to questions answered by users" description="FAQ Content"/></li>
      <li><FormattedMessage id="app.faq.answer4.line5" defaultMessage="“Metadata” - Data about the time at which individuals successfully participated in the Research." description="FAQ Content"/></li>
      <li><FormattedMessage id="app.faq.answer4.line6" defaultMessage="“Physical Device data” – Data about the computer on which a user participates such as the level of RAM, the type of CPU in the machine and the user agent (operating system and browser version) as well as information about the computer’s screen (size and resolution), webcam (make and resolution), antivirus program, and the computer’s IP address." description="FAQ Content"/></li>
    </ul>
    <p><b><FormattedMessage id="app.faq.question5" defaultMessage="Q: How will the data collected be used?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer5.line1" defaultMessage="A: The data collected will be used to explore how individuals view and interact with various types of visual images and media and to gauge people’s opinions of the images they are shown." description="FAQ Content"/></p>
    <p><FormattedMessage id="app.faq.answer5.line2" defaultMessage="Lumen may share the research data with affiliated companies and with business partners and clients. While the research data might be shared with affiliates, partners and clients, this will typically be on an anonymous basis. Such affiliates, partners and clients may use this data to create summary descriptions of a wide range of consumer behaviours and consumer segments to develop products or to provide marketing solutions to their customers or consumers." description="FAQ Content"/></p>
    <p><FormattedMessage id="app.faq.answer5.line3" defaultMessage="Lumen collect “Physical Device data” and “Metadata” to help manage the collection and processing of data, including quality control and volume management." description="FAQ Content"/></p>
    <p><FormattedMessage id="app.faq.answer5.line4" defaultMessage="The data collected will be retained for the period necessary to fulfill the purposes outlined." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question6" defaultMessage="Q: How secure is my data?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer6" defaultMessage="A: The data collected by Lumen is encrypted using SSL technology. This is the most commonly used high standard of encryption technology and is used by banks to secure banking transactions and other highly sensitive communications. It is also used by Amazon, PayPal, eBay, and other websites requiring bank-level security systems. Scientists estimate that breaking 256 bit encryption using current technology would require substantially more time than the age of the universe. Collected data is stored on secure web servers managed by Amazon Web Services (AWS). AWS is one of the most commonly used safe and dependable cloud computing services platforms and is used by major cloud-based commercial services including Netflix and Expedia." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question7" defaultMessage="Q: What obligations do you have regarding the Content you are shown?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer7" defaultMessage="A: By participating, you also understand and agree that the Content you are shown in this study may be confidential, the property of a third party and/or subject to trademark or copyright laws and that you will not make screenshots, record or otherwise copy any of the Content or redistribute any of the Content of the study in any way." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question8" defaultMessage="Q: I'm not sure how good my webcam is, will it work for eye tracking?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer8" defaultMessage="A: The system works with a large range of webcams – it should not be a problem." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question9" defaultMessage="Q: I've completed the study, do I need to do anything else?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer9" defaultMessage="A: No it is not necessary to do anything else. The software will close down automatically. You can now uninstall the software and delete the downloaded file." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question10" defaultMessage="Q: Do I need to be connected to the internet to participate?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer10" defaultMessage="A: Yes, you will need an internet connection to download the program and to complete the task." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question11" defaultMessage="Q: Do I need to complete the task on a stable surface?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer11" defaultMessage="A: Yes, for the best results, you should sit comfortably in front of your computer screen and, after the calibration, keep your head still whilst you are presented with the stimulus, until notified. We advise not taking part if you are on a train or balancing your laptop on your lap etc." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question12" defaultMessage="Q: The FAQs haven't solved my problem, what do I do now?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer12" defaultMessage="A: Please email us on ViewPoints@lumen-research.com and we'll get back to you as quickly as we can." description="FAQ Content"/></p>
    <p><FormattedMessage id="app.faq.patent" defaultMessage="Lumen's testing of web content is powered by our own proprietary in-context testing technology (UK Patent Application Number 1816158.8)" description="FAQ Content"/></p>
  </React.Fragment>
}
const gdprModalContent = () => {
  return <React.Fragment>

    <p><FormattedMessage id="app.faq.gdpr.intro1" defaultMessage="In this study we will use the front-facing camera on a mobile device to take images and video footage of a user whilst they are asked to undertake various exercises involving looking at stimuli under a range of environmental (e.g., lighting and positioning) conditions.  Participation is expected to take around 5 minutes.  The data is to be used to aid the continued development of software that can estimate where users are looking on a screen." descrition="FAQ Content"/></p>
    <p><FormattedMessage id="app.faq.gdpr.intro2" defaultMessage="This research is being conducted in accordance with the MRS Code of Conduct and the Data Protection Act 2018.  Your privacy is important to us.  Before taking part in this study, you must confirm that you agree that by taking part in this study, you understand the data we are collecting and how it will be used, as described in this Privacy Policy, and consent to this data being used in this way.  This policy describes in more detail precisely what information is collected and how it is used and shared." descrition="FAQ Content"/></p>
    <b><p><FormattedMessage id="app.faq.gdpr.question1" defaultMessage="What data will be collected?" descrition="FAQ Content"/></p></b>
    <ul>
      <li><FormattedMessage id="app.faq.gdpr.answer1.line1" defaultMessage="This study will involve the collection of the following types of data:" descrition="FAQ Content"/></li>
      <li><FormattedMessage id="app.faq.gdpr.answer1.line2" defaultMessage="“Sitting characteristics” – An anonymous ID used to identify a particular data collection ‘Sitting’ and data about the prevailing environmental test conditions during the session." descrition="FAQ Content"/></li>
      <li><FormattedMessage id="app.faq.gdpr.answer1.line3" defaultMessage="“Participant characteristics” – Data about a respondent taking part in a given Sitting, including information about the participant’s demographic (e.g., age, gender), ethnic (e.g., skin type) and biological (e.g., eye colour) attributes." descrition="FAQ Content"/></li>
      <li><FormattedMessage id="app.faq.gdpr.answer1.line4" defaultMessage="“Video file” – Images and/or video footage taken using the device’s front-facing camera to capture images of participants whilst they are looking at, and potentially interacting with, stimuli displayed on a screen or screens. Images will be captured of a participant’s eyes and face and anything else that unintentionally may be captured by the front-facing camera whilst the task is being completed. " descrition="FAQ Content"/></li>
      <li><FormattedMessage id="app.faq.gdpr.answer1.line5" defaultMessage="“Metadata” - Data about the time at which individuals interacted with the stimuli, linked to the timestamp of each collected image." descrition="FAQ Content"/></li>
      <li><FormattedMessage id="app.faq.gdpr.answer1.line6" defaultMessage="“Physical Device data” - Physical data about the device, including the device name, the screen size, screen rotation and camera sensor rotation." descrition="FAQ Content"/></li>
    </ul>
    <p><FormattedMessage id="app.faq.gdpr.answer1.line7" defaultMessage="By taking part in this research, you understand and agree that the Content you are shown in this study may be confidential, the property of a third party and/or subject to trademark or copyright laws and that you will not make screenshots, record or otherwise copy any of the Content or redistribute any of the Content of the study in any way." descrition="FAQ Content"/></p>
    
    <b><p><FormattedMessage id="app.faq.gdpr.question2" defaultMessage="How will the data be used / processed?" descrition="FAQ Content"/></p></b>
    <p><FormattedMessage id="app.faq.gdpr.answer2" defaultMessage="The data will be used for the development of software that can estimate whether users are present in front of a device, whether they are looking at stimuli, and where on the stimuli they may be looking.  It may also be used for demonstration purposes or for explaining to, or helping to evaluate for, third parties (clients, suppliers, partners) how our technology has been developed, and its performance.  The data may be reviewed by any number of data ‘annotators’ – employees or subcontractors or partners or any other third party that will be asked to watch back the video footage / images to assess the attention ‘states’ of participants during the research (e.g., are they present in the field of view of the camera? are they looking at the screen?)  The legal bases for processing the data will be informed consent and legitimate interests. " descrition="FAQ Content"/></p>
    <b><p><FormattedMessage id="app.faq.gdpr.question3" defaultMessage="How long will the data be kept?" descrition="FAQ Content"/></p></b>
    <p><FormattedMessage id="app.faq.gdpr.answer3" defaultMessage="The data will be retained by us for use for as long as is reasonably necessary for the purposes described above.  The software being developed is periodically updated and evaluated and this data will be used to evaluate each iteration, so is expected to be retained and held for this purpose for several years, if not indefinitely.  The data will be deleted if it is deemed to be no longer useful for the purpose of developing or evaluating the stimuli-testing software. " descrition="FAQ Content"/></p>
    <b><p><FormattedMessage id="app.faq.gdpr.question4" defaultMessage="Who will receive and process the data?" descrition="FAQ Content"/></p></b>
    <p><FormattedMessage id="app.faq.gdpr.answer4" defaultMessage="The data will be held and retained by Lumen Research Ltd.  Lumen Research Ltd is a company registered in England, company number 8682432.  Its registered office is at LABS house, 15-19 Bloomsbury Way, Holborn, London WC1A 2TH. Its data protection registration reference is ZA130772.  Its Data Protection Officer is Matt Robinson (matt.robinson@lumen-research.com).  You have the right to lodge a complaint about the handling and processing of your data at any time by contacting the UK’s Data Protection Authority, the Information Commissioner’s Office.  The ICO’s contact details are available on its website at https://ico.org.uk/global/contact-us/. By taking part in the study, you grant Lumen a worldwide, royalty-free, sublicensable, and transferable license to host, store, use, display, reproduce, modify, adapt, edit, publish, and distribute this data." descrition="FAQ Content"/></p>
    <b><p><FormattedMessage id="app.faq.gdpr.question5" defaultMessage="Do I have a right to access my personal data?" descrition="FAQ Content"/></p></b>
    <p><FormattedMessage id="app.faq.gdpr.answer5" defaultMessage="Yes, you have the right to access your personal data, however it is important to be aware that your name will not be stored with the data collected during this exercise.  To identify your data records, you will need to provide us with the precise time and date at which you undertook the research, and we will need to ask for visual identification to ensure that the data records are correctly matched." descrition="FAQ Content"/></p>
    <b><p><FormattedMessage id="app.faq.gdpr.question7" defaultMessage="Do I have a right to withdraw my consent to the processing of my personal data, or to the erasure of my personal data?" descrition="FAQ Content"/></p></b>
    <p><FormattedMessage id="app.faq.gdpr.answer7" defaultMessage="Yes, you have the right to withdraw your consent to the processing of your personal data, and to the erasure of this data.  However, it is important to be aware that your name will not be stored with the data collected during this exercise.  To identify your data record, you will need to provide us with the precise time and date at which you undertook the research, and we will ask for visual identification to ensure that the data records are correctly matched.  " descrition="FAQ Content"/></p>
    <b><p><FormattedMessage id="app.faq.gdpr.question8" defaultMessage="How do I know my personal information is safe?" descrition="FAQ Content"/></p></b>
    <p><FormattedMessage id="app.faq.gdpr.answer8" defaultMessage="The data collected by Lumen Research is encrypted using Secure Sockets Layer (SSL) technology.  This is a commonly used high standard of encryption technology. This technology is used for both securing the transfer channels and the final storage." descrition="FAQ Content"/></p>
    <b><p><FormattedMessage id="app.faq.gdpr.question9" defaultMessage="How will we use and disclose information we collect?" descrition="FAQ Content"/></p></b>
    <p><FormattedMessage id="app.faq.gdpr.answer9" defaultMessage="We may disclose the information we collect to other third-party service providers, to enable them to provide services to us. We may also disclose information to a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings). We may also use or disclose the information we collect from and about you as we believe to be necessary or appropriate: (a) under applicable law, including laws outside your country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities, including public and government authorities outside your country of residence; (d) to enforce our end user license agreement; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or others; (g) to allow us to pursue available remedies or limit the damages that we may sustain; and (h) to detect and resolve any fraud or security concerns. " descrition="FAQ Content"/></p>
    <b><p><FormattedMessage id="app.faq.gdpr.question10" defaultMessage="What steps are taken to hold the data securely?" descrition="FAQ Content"/></p></b>
    <p><FormattedMessage id="app.faq.gdpr.answer10" defaultMessage="We take reasonable steps to protect your information using physical, electronic, or procedural security measures appropriate to the sensitivity of the information in our custody or control, which include safeguards to protect against loss or theft, as well as unauthorized access, disclosure, copying, use or modification. " descrition="FAQ Content"/></p>
  </React.Fragment>
}

function LSFaqModalCont(props) {
  const { showGdprWarning } = store.getState().studyReducer;

  return (
    props.displayFaqModal
    ? <LSModal
        title="Privacy Policy / FAQs"
        titleId="app.button.faqs"
        content={showGdprWarning ? gdprModalContent() : modalContent()}
        modalToggle={props.toggleFaqModal}
      />
    : null)
}

const mapStateToProps = function(state) {
  return {displayFaqModal: state.modalReducer.displayFaqModal}
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    toggleFaqModal: () => dispatch(toggleFaqModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LSFaqModalCont);
