{
  "app.thankyou": "Muito obrigado",
  "app.button.faqs": "Perguntas mais frequentes",
  "app.button.terms": "Termos de uso",
  "app.button.privacy": "Política de Privacidade",
  "app.button.feedback": "Feedback",
  "app.button.selectLanguage": "Selecione o idioma",
  "app.button.help": "Ajuda",
  "app.button.start": "Início",
  "app.button.next": "Próximo",
  "app.button.submit": "Enviar para",
  "app.button.close": "Fechar",
  "app.button.return": "Voltar",
  "app.button.takepart": "OK - Vou participar",
  "app.menu.feedback.content": "Se você teve algum tipo de dificuldade com o estudo ou gostaria apenas de deixar um comentário, por favor sinta-se à vontade para fazê-lo aqui.",
  "app.contactModal.feedback": "Obrigado por seu feedback!",
  "app.takePartModal.line1": "Se você sair agora, não terá direito a nenhuma gratificação pela participação.",
  "app.takePartModal.line2": "Você só precisa de uma webcam!",
  "app.takePartModal.reason1": "Não tenho webcam.",
  "app.takePartModal.reason2": "Não me sinto confortável com o uso de minha webcam para esta pesquisa.",
  "app.takePartModal.reason3": "Não tenho tempo para isso agora.",
  "app.takePartModal.reason4": "Outro motivo.",
  "app.generic.redirect": "Você será redirecionado em breve.",
  "app.generic.screenshotfail": "Faça uma captura de tela desta página como prova de falha na entrada. Você será redirecionado em breve.",
  "app.generic.screenshotsuccess": "Por favor, faça uma captura de tela desta página como prova de falha na entrada. Você será redirecionado em breve.",
  "app.init.welcome": "Bem-vindo à plataforma de estudos da Lumen Research",
  "app.init.intro": "A plataforma de estudos nos permite integrar e configurar usuários para um determinado estudo de anúncio.",
  "app.init.message1": "Dependendo do tipo de estudo, o usuário será guiado por um conjunto de tarefas.",
  "app.init.message2": "Essas tarefas incluem: realizar uma pesquisa, ler um conjunto de instruções e executar a calibração do rastreamento ocular",
  "app.init.message3": "Agora, guiaremos você por um estudo de demonstração.",
  "app.init.message4": "Ao longo deste estudo, poderemos habilitar sua webcam para realizar rastreamento ocular, mas nenhum dos seus dados será registrado.",
  "app.init.message5": "O estudo atual está configurado para usar os seguintes links em filtrar/completar.",
  "app.prestart.loading": "Carregando, por favor aguarde.",
  "app.index.title": "Bem-vindo",
  "app.index.content.line1": "Durante esta pesquisa, usaremos sua câmera para avaliarmos para onde você está olhando. Não se preocupe, não vamos tirar fotos nem gravar vídeos.",
  "app.index.content.line1gpdrwarning": "Durante esta pesquisa, usaremos a câmera frontal do seu dispositivo para tirar fotos e vídeos enquanto você realiza vários exercícios envolvendo a observação de estímulos.",
  "app.index.content.line1alt": "Por favor, verifique se você está sentado de forma confortável e em seguida toque em iniciar para começar.",
  "app.index.content.line2": "Seu aparelho possui câmera? Em caso afirmativo, por favor pressione o botão Iniciar abaixo.",
  "app.index.rejection": "Desculpe, parece que você seguiu um link incorreto.",
  "app.index.webview": "Desculpe, parece que você acessou nossa página de dentro de uma visualização web. Por favor, abra este link em seu navegador. Isso pode ser feito diretamente no menu de visualização da web.",
  "app.index.webview.chrome.1": "Toque no botão de menu acima e selecione \"Abrir no Chrome\".",
  "app.index.webview.chrome.2": "Com isso, a pesquisa será aberta em um aplicativo de navegador, para que possa funcionar corretamente.",
  "app.index.webview.android.1": "Toque em Start (Iniciar) abaixo para abrir esta pesquisa em um aplicativo de navegação, dessa forma ela irá funcionar corretamente.",
  "app.index.webview.android.2": "Caso isso falhe, abra manualmente o link para esta página em um aplicativo de navegação (por exemplo, copie e cole o link na barra de endereço no Chrome) ou use uma opção como \"Abrir no Chrome\" na barra de menu acima).",
  "app.index.webview.safari.1": "Toque no ícone do navegador Safari na parte inferior da tela para continuar.",
  "app.index.webview.safari.2": "É assim:",
  "app.index.webview.safari.3": "Isso abrirá a pesquisa no navegador Safari para que ela possa funcionar corretamente.",
  "app.index.wifi": "Parece que sua conexão com a Internet está muito lenta. Você está conectado ao wifi?",
  "app.index.tellmemore": "Me conte mais.",
  "app.index.takepart": "Não tenho interesse em participar.",
  "app.index.webcamRetry": "Não conseguimos detectar uma webcam, necessária para este estudo. Forneça acesso à sua webcam e tente novamente.",
  "app.index.adblockRetry": "Opa! Detectamos um bloqueador de anúncios. Para uma melhor experiência de estudo, desative esta opção e tente novamente.",
  "app.index.zoomRetry": "Exigimos que o navegador esteja configurado para 100% de zoom; amplie/reduza o zoom e tente novamente",
  "app.index.fullscreendesktopretry": "Este estudo requer uma visualização em tela cheia, porisso certifique-se de que a janela do seu navegador esteja maximizada",
  "app.index.fullscreendesktoptest": "Este estudo requer que a janela do seu navegador esteja em tela cheia, precisamos realizar um teste para garantir que a janela do seu navegador esteja maximizada",
  "app.index.fullscreendesktoptest2": "Dois botões aparecerão em breve. Clique neles para continuar",
  "app.index.fullscreendesktopmaximised": "Minha tela já está maximizada",
  "app.index.fullscreendesktopskip": "Obrigado, registramos esta ocorrência e melhoraremos nossos processos de detecção.",
  "app.index.fullscreendesktopreset": "Detectamos uma alteração no tamanho da janela, redefinindo o teste.",
  "app.index.gotomobile": "Usuários de desktop não são elegíveis para este estudo. Use o QR code abaixo para abrir o estudo no celular.",
  "app.index.returntopanel": "OU, clique no botão abaixo para retornar ao painel",
  "app.instructions.title": "Instruções",
  "app.instructions.instruction1": "Se possível, não use óculos durante a pesquisa.",
  "app.instructions.instruction2": "Sente-se e mantenha a cabeça e o seu aparelho estáticos de forma confortável.",
  "app.instructions.instruction3": "Mova seus olhos, não sua cabeça.",
  "app.instructions.instruction4": "Pronto para começar?",
  "app.instructions.instruction5": "Por favor, use wifi para evitar cobranças de dados.",
  "app.instructions.instruction6": "Por favor, leia atentamente as instruções abaixo.",
  "app.instructions.instruction7": "Coloque seu telefone em uma superfície estável.",
  "app.instructions.instruction7nav": "Use o dedo para mover em qualquer direção na tela.",
  "app.instructions.instruction8": "Por favor, certifique-se de que seu quarto esteja bem iluminado - de preferência de cima (não de trás).",
  "app.setup.title": "Configuração",
  "app.setup.instruction0": "Mantenha seu aparelho no modo retrato.",
  "app.setup.instruction0a": "Por favor, gire seu aparelho para que fique na posição retrato.",
  "app.setup.instruction0b": "Por favor, gire seu aparelho de forma que a câmera fique à esquerda.",
  "app.setup.instruction0c": "Por favor, gire seu dispositivo para que sua câmera fique à direita.",
  "app.setup.instruction0d": "Por favor, gire seu dispositivo para a posição horizontal.",
  "app.setup.instructioncamera": "Favor clicar no botão abaixo para solicitar acesso à câmera. Para completar a pesquisa, você precisa conceder permissões de câmeras durante todo o estudo.",
  "app.setup.cameraText": "Solicitar acesso",
  "app.setup.instruction1": "Inicialização...",
  "app.setup.instruction2": "Quando você for detectado, posicione sua cabeça dentro da estrutura.",
  "app.setup.instruction3": "Mantenha sua cabeça e seu aparelho em posição até que a pesquisa seja completada.",
  "app.setup.instruction3audio": "Ouça atentamente e siga as instruções que aparecem na tela.",
  "app.setup.instruction4": "Agora só precisamos validar.",
  "app.setup.instruction5": "Obrigado! Lembre-se de manter sua cabeça e seu aparelho na mesma posição até que a pesquisa seja completada.",
  "app.setup.instruction6": "Agora deslize para cima para esconder a barra de endereço.",
  "app.setup.retry": "Erro detectado. Mantenha sua cabeça e seu aparelho estáticos e não cubra a câmera, por exemplo, com os dedos. Use o botão abaixo para tentar novamente.",
  "app.setup.retryPositioning": "Não conseguimos determinar a posição da sua cabeça. Mantenha sua cabeça e seu aparelho estáticos e não cubra a câmera, por exemplo, com os dedos. Use o botão abaixo para tentar novamente.",
  "app.setup.retryInit": "Erro detectado. Certifique-se de que o navegador possa acessar sua câmera. Se você rejeitou anteriormente as permissões de acesso à câmera, precisará aceitar isso nas configurações de privacidade do navegador. Use o botão abaixo para tentar novamente.",
  "app.setup.retryBroker": "Não foi possível concluir o processo de calibração. Tente fechar todos os aplicativos e abas não utilizados para liberar recursos",
  "app.setup.retryButton": "Tentar novamente",
  "app.setup.calibrationProp.watch": "Siga o ponto quando ele aparecer na tela",
  "app.setup.calibrationProp.watch.george": "A seguir, quando você vir um rosto, continue olhando para ele enquanto ele reflete seus movimentos da cabeça",
  "app.setup.calibrationProp.click": "Clique em qualquer local para continuar",
  "app.setup.calibrationProp.tap": "Toque em qualquer local para continuar",
  "app.study.validationProp.watch": "Siga o ponto quando ele aparecer na tela",
  "app.study.validationProp.restart": "Por favor, tente novamente",
  "app.study.validationProp.click": "Clique em qualquer local para continuar",
  "app.study.validationProp.tap": "Toque em qualquer local para continuar",
  "app.study.render.error": "O domínio não é válido para a etapa de renderização.",
  "app.error.title": "Erro",
  "app.error.generic": "Opa! Algo deu errado.",
  "app.error.initialise": "Não foi possível inicializar!",
  "app.error.findCamera": "Não foi possível encontrar a câmera!",
  "app.error.headPosition": "Não foi possível determinar a posição de sua cabeça.",
  "app.error.calibrate": "Não foi possível calibrar.",
  "app.error.validate": "Não foi possível validar.",
  "app.error.start": "Falha ao iniciar.",
  "app.error.assign": "Falha ao atribuir.",
  "app.error.osnotallowed": "Screenout: Seu sistema operacional não tem permissão, verifique qual dispositivo você pode usar para esta pesquisa e tente novamente.\r\n",
  "app.error.demoquotafullnoactivecell": "Cota completa: Obrigado por seu interesse. Este estudo está agora direcionado para sua região de origem.",
  "app.error.cellquotafullnoactivecell": "Nenhum celular ativo foi encontrado para este estudo. As cotas de celulares estão cheios.",
  "app.error.missingpanelparameter": "Parâmetro do painel ausente.",
  "app.error.browsernotallowed": "Navegador não permitido.",
  "app.invalid.title": "Inválido",
  "app.invalid.generic": "Desculpe, você não está qualificado para este estudo.",
  "app.completion.title": "Conclusão",
  "app.completion.congratulations": "Parabéns! Você completou o estudo. O acesso dessa pesquisa à sua câmera será encerrado agora.",
  "app.completion.congratulations-noet": "Parabéns! Você completou o estudo.",
  "app.questions.required": "Esta pergunta é obrigatória.",
  "app.faq.gdpr.intro1": "Neste estudo, usaremos a câmera frontal de um dispositivo móvel para obter imagens e gravar vídeos de um usuário enquanto ele é solicitado a realizar vários exercícios envolvendo a observação de estímulos sob uma variedade de condições ambientais (por exemplo, iluminação e posicionamento). A participação deve durar cerca de 5 minutos. Os dados serão usados ​para auxiliar o desenvolvimento contínuo de software que possa estimar onde os usuários estão olhando em uma tela.",
  "app.faq.gdpr.intro2": "Esta pesquisa está sendo conduzida de acordo com o Código de Conduta da MRS e a Lei de Proteção de Dados de 2018. Sua privacidade é importante para nós. Antes de participar deste estudo, você deve confirmar que concorda que, ao participar deste estudo, você entende os dados que estamos coletando e como eles serão usados, conforme descrito nesta Política de Privacidade, e consente que esses dados sejam usados ​​dessa maneira. Esta política descreve com mais detalhes precisamente quais informações são coletadas e como elas são usadas e compartilhadas.",
  "app.faq.gdpr.question1": "Quais dados serão coletados?",
  "app.faq.gdpr.answer1.line1": "Este estudo envolverá a coleta dos seguintes tipos de dados:",
  "app.faq.gdpr.answer1.line2": "“Características da sessão” – Uma ID anônima usada para identificar uma coleta de dados específica ‘Situação’ e dados sobre as condições de teste ambientais predominantes durante a sessão.",
  "app.faq.gdpr.answer1.line3": "“Características do participante” – Dados sobre um respondente que participa de uma determinada Sessão, incluindo informações sobre os atributos demográficos (por exemplo, idade, sexo), étnicos (por exemplo, tipo de pele) e biológicos (por exemplo, cor dos olhos) do participante.",
  "app.faq.gdpr.answer1.line4": "“Arquivo de vídeo” – Imagens e/ou filmagens tiradas usando a câmera frontal do dispositivo para capturar imagens de participantes enquanto eles estão olhando e potencialmente interagindo com estímulos exibidos em uma tela ou telas. Serão capturadas imagens dos olhos e do rosto do participante e de qualquer outra coisa que possa ser capturada involuntariamente pela câmera frontal enquanto a tarefa estiver sendo concluída. ",
  "app.faq.gdpr.answer1.line5": "“Metadados” - Dados sobre o momento em que os indivíduos interagiram com os estímulos, vinculados ao registro de data e hora de cada imagem coletada.",
  "app.faq.gdpr.answer1.line6": "“Dados do dispositivo físico” - Dados físicos sobre o dispositivo, incluindo o nome do dispositivo, o tamanho da tela, a rotação da tela e a rotação do sensor da câmera.",
  "app.faq.gdpr.answer1.line7": "Ao participar desta pesquisa, você entende e concorda que o Conteúdo mostrado a você neste estudo pode ser confidencial, propriedade de terceiros e/ou sujeito a leis de marca registrada ou direitos autorais e que você não fará capturas de tela, gravará ou copiará qualquer Conteúdo ou redistribuirá qualquer Conteúdo do estudo de nenhuma forma.",
  "app.faq.gdpr.question2": "Como os dados serão usados/processados?",
  "app.faq.gdpr.answer2": "Os dados serão usados ​​para o desenvolvimento de software que pode estimar se os usuários estão presentes na frente de um dispositivo, se estão olhando para estímulos e para onde nos estímulos eles podem estar olhando. Ele também pode ser usado para fins de demonstração ou para explicar ou ajudar a avaliar para terceiros (clientes, fornecedores, parceiros) como nossa tecnologia foi desenvolvida e seu desempenho. Os dados podem ser revisados ​​por qualquer número de \"anotadores\" de dados - funcionários, subcontratados, parceiros ou qualquer outro terceiro que será solicitado a assistir novamente às filmagens/imagens de vídeo para avaliar os \"estados\" de atenção dos participantes durante a pesquisa (por exemplo, eles estão presentes no campo de visão da câmera? eles estão olhando para a tela?) As bases legais para o processamento dos dados serão o consentimento informado e os interesses legítimos. ",
  "app.faq.gdpr.question3": "Por quanto tempo os dados serão mantidos?",
  "app.faq.gdpr.answer3": "Os dados serão retidos por nós para uso pelo tempo que for razoavelmente necessário para os propósitos descritos acima. O software que está sendo desenvolvido é atualizado e avaliado periodicamente e esses dados serão usados ​​para avaliar cada interação, portanto, espera-se que sejam retidos e mantidos para essa finalidade por vários anos, se não indefinidamente. Os dados serão excluídos se forem considerados não mais úteis para o desenvolvimento ou avaliação do software de teste de estímulos. ",
  "app.faq.gdpr.question4": "Quem receberá e processará os dados?",
  "app.faq.gdpr.answer4": "Os dados serão mantidos e retidos pela Lumen Research Ltd. A Lumen Research Ltd é uma empresa registrada na Inglaterra, número da empresa 8682432. Sua sede registrada fica em LABS house, 15-19 Bloomsbury Way, Holborn, Londres WC1A 2TH. Sua referência de registro de proteção de dados é ZA130772. Seu Diretor de Proteção de Dados é Matt Robinson (matt.robinson@lumen-research.com). Você tem o direito de registrar uma reclamação sobre o manuseio e processamento de seus dados a qualquer momento, entrando em contato com a Autoridade de Proteção de Dados do Reino Unido, o Information Commissioner’s Office. Os detalhes de contato do ICO estão disponíveis em seu site em https://ico.org.uk/global/contact-us/. Ao participar do estudo, você concede à Lumen uma licença mundial, livre de royalties, sublicenciável e transferível para hospedar, armazenar, usar, exibir, reproduzir, modificar, adaptar, editar, publicar e distribuir esses dados.",
  "app.faq.gdpr.question5": "Eu tenho o direito de acessar meus dados pessoais?",
  "app.faq.gdpr.answer5": "Sim, você tem o direito de acessar seus dados pessoais, porém é importante estar ciente de que seu nome não será armazenado com os dados coletados durante este exercício. Para identificar seus registros de dados, você precisará nos fornecer a hora e a data precisas em que realizou a pesquisa, e precisaremos solicitar identificação visual para garantir que os registros de dados correspondam corretamente.",
  "app.faq.gdpr.question7": "Tenho o direito de retirar meu consentimento para o processamento dos meus dados pessoais ou para a eliminação dos meus dados pessoais?",
  "app.faq.gdpr.answer7": "Sim, você tem o direito de retirar seu consentimento para o processamento de seus dados pessoais e para a eliminação desses dados. No entanto, é importante estar ciente de que seu nome não será armazenado com os dados coletados durante este exercício. Para identificar seu registro de dados, você precisará nos fornecer a hora e a data precisas em que realizou a pesquisa, e solicitaremos identificação visual para garantir que os registros de dados correspondam corretamente. ",
  "app.faq.gdpr.question8": "Como sei que minhas informações pessoais estão seguras?",
  "app.faq.gdpr.answer8": "Os dados coletados pela Lumen Research são criptografados usando a tecnologia Secure Sockets Layer (SSL). Este é um alto padrão de tecnologia de criptografia comumente usado. Essa tecnologia é usada tanto para proteger os canais de transferência quanto para o armazenamento final.",
  "app.faq.gdpr.question9": "Como usaremos e divulgaremos as informações que coletamos?",
  "app.faq.gdpr.answer9": "Podemos divulgar as informações que coletamos a outros provedores de serviços terceirizados, para permitir que eles nos forneçam serviços. Também podemos divulgar informações a terceiros no caso de qualquer reorganização, fusão, venda, joint venture, cessão, transferência ou outra alienação de todo ou qualquer parte de nossos negócios, ativos ou ações (inclusive em conexão com qualquer falência ou processo semelhante). Também podemos usar ou divulgar as informações que coletamos de e sobre você conforme julgarmos necessário ou apropriado: (a) sob a lei aplicável, incluindo leis fora do seu país de residência; (b) manter conformidade com o processo legal; (c) responder a solicitações de autoridades públicas e governamentais, incluindo autoridades públicas e governamentais fora do seu país de residência; (d) para fazer cumprir nosso contrato de licença de usuário final; (e) para proteger nossas operações ou aquelas de qualquer uma de nossas afiliadas; (f) para proteger nossos direitos, privacidade, segurança ou propriedade e/ou de nossos afiliados, você ou outros; (g) para nos permitir buscar as soluções disponíveis ou limitar os danos que podemos sofrer; e (h) para detectar e resolver quaisquer fraudes ou problemas de segurança. ",
  "app.faq.gdpr.question10": "Quais medidas são tomadas para manter os dados com segurança?",
  "app.faq.gdpr.answer10": "Tomamos medidas razoáveis ​​para proteger suas informações usando medidas de segurança físicas, eletrônicas ou processuais apropriadas à sensibilidade das informações sob nossa custódia ou controle, que incluem salvaguardas para proteção contra perda ou roubo, bem como acesso, divulgação, cópia, uso ou modificação não autorizados. ",
  "app.faq.question1": "P: O que é a Plataforma de Estudo Lumen e o que representa participar de uma pesquisa de rastreamento ocular?",
  "app.faq.answer1": "R: Essa pesquisa envolve o uso da tecnologia de rastreamento ocular. Você deverá sentar na frente da webcam ou da câmera frontal de seu aparelho e realizar uma calibração para que possa ser feita uma avaliação de onde você está olhando em sua tela. Durante a pesquisa, você deverá ver alguns itens (por exemplo, imagens, websites ou vídeos) enquanto a ferramenta de pesquisa avalia para onde você está olhando. Os dados que são capturados deste \"rastreamento ocular\" são as coordenadas dos pontos de observação previstos em sua tela ao longo do tempo. O software usa imagens de sua webcam para fazer essas previsões, mas não captura suas fotos ou vídeos. Também podem ser feitas algumas perguntas sobre você, sua experiência e os itens que você viu durante a pesquisa. Os dados são enviados à Lumen Research Ltd. A Lumen Research irá analisar os resultados e compartilhar as conclusões com seus clientes.",
  "app.faq.question2": "P: Por que o software necessita de uma câmera web/e como ela é usada?",
  "app.faq.answer2": "R: O software usa imagens de sua webcam ou da câmera do dispositivo para avaliar para onde você está olhando em uma tela. Ele faz isso utilizando uma combinação de software de rastreamento facial e de rastreamento ocular que analisa as imagens no local em sua máquina em tempo real. Essas imagens são processadas e mostradas a você durante a calibração para que você possa se posicionar corretamente, mas as imagens não são gravadas, dessa forma sua privacidade permanece protegida. Os dados do rastreamento ocular que são registrados e coletados não são imagens, mas dados numéricos sobre os pontos de observação avaliados sobre o estímulo ao longo do tempo.",
  "app.faq.question3": "P: Você tira fotos de mim enquanto faz o rastreamento ocular?",
  "app.faq.answer3": "R: O único momento em que as imagens são usadas é para permitir que você se posicione corretamente durante a calibração. Essas imagens não são salvas, e só são vistas por você. Os dados do rastreamento ocular que são registrados e coletados não são imagens, mas dados numéricos sobre os pontos de vista previstos sobre o estímulo ao longo do tempo.",
  "app.faq.question4": "P: Que dados são coletados durante a pesquisa?",
  "app.faq.answer4.line1": "R: O software vai coletar alguns ou todos os cinco tipos de dados abaixo:",
  "app.faq.answer4.line2": "\"Identificação do usuário\" - A identidade do usuário é usada para identificar os participantes (com a finalidade de gerenciar os prêmios de participação);",
  "app.faq.answer4.line3": "\"Dados do olhar\" - Projeções de onde os usuários estão olhando em sua tela quando são mostrados estímulos na tela",
  "app.faq.answer4.line4": "\"Dados da pesquisa\" - Respostas às perguntas respondidas pelos usuários",
  "app.faq.answer4.line5": "\"Metadados\" - Dados sobre o momento em que os indivíduos participaram com sucesso da Pesquisa.",
  "app.faq.answer4.line6": "\"Dados do dispositivo físico\" - Dados sobre o computador no qual o usuário participa, tais como o nível de memória RAM, o tipo de CPU na máquina e o agente do usuário (sistema operacional e versão do navegador), bem como informações sobre a tela do computador (tamanho e resolução), webcam (marca e resolução), e programas antivírus.",
  "app.faq.question5": "P: Como serão utilizados os dados coletados?",
  "app.faq.answer5.line1": "R: Os dados coletados serão usados para explorar como os indivíduos vêem e interagem com vários tipos de imagens visuais e mídias e para avaliar a opinião das pessoas sobre as imagens que são mostradas.",
  "app.faq.answer5.line2": "A Lumen pode compartilhar os dados da pesquisa com empresas afiliadas e com parceiros comerciais e clientes. Embora os dados da pesquisa possam ser compartilhados com afiliados, parceiros e clientes, isso certamente será feito de forma anônima. Tais afiliados, parceiros e clientes podem usar esses dados para criar descrições resumidas de uma ampla gama de comportamentos e segmentos de consumidores para desenvolver produtos ou fornecer soluções de marketing a seus clientes ou consumidores.",
  "app.faq.answer5.line3": "A Lumen coleta \"Dados do Dispositivo Físico\" e \"Metadados\" para ajudar a gerenciar a coleta e o processamento de dados, incluindo controle de qualidade e gerenciamento de volume.",
  "app.faq.answer5.line4": "Os dados coletados serão retidos pelo período necessário para cumprir as finalidades estabelecidas.",
  "app.faq.question6": "P: Até que ponto meus dados estão seguros?\r\n",
  "app.faq.answer6": "R: Os dados coletados pela Lumen são criptografados usando a tecnologia SSL. Esse é o alto padrão mais comumente usado de tecnologia de criptografia e é usado pelos bancos para proteger transações bancárias e outras comunicações altamente confidenciais. Também é utilizado pela Amazon, PayPal, eBay e outros websites que necessitam de sistemas de segurança a nível bancário. Os pesquisadores avaliam que quebrar a criptografia de 256 bits usando a tecnologia atual exigiria muito mais tempo do que a idade do universo. Os dados coletados são armazenados em servidores web seguros gerenciados pela Amazon Web Services (AWS).\r\n AWS é uma das plataformas de serviços de computação em nuvem mais comumente usadas, seguras e confiáveis e é usada pelos principais serviços comerciais baseados em nuvem, incluindo Netflix e Expedia.",
  "app.faq.question7": "P: Quais são as responsabilidades que você precisa levar em consideração em relação ao conteúdo que lhe é mostrado?",
  "app.faq.answer7": "R: Ao participar, você também entende e concorda que o Conteúdo mostrado neste estudo pode ser confidencial, de propriedade de terceiros e/ou sujeito às leis de marcas registradas ou de direitos autorais e que você não fará capturas de tela, não gravará ou de outra forma não copiará qualquer Conteúdo nem redistribuirá qualquer Material do estudo seja qual for a circusntância.",
  "app.faq.question8": "P: Não tenho certeza se minha webcam é boa. Ela funciona para rastreamento ocular?",
  "app.faq.answer8": "R: O sistema funciona com uma grande variedade de webcams - dessa forma, isso não deve ser um problema.",
  "app.faq.question9": "P: Concluí o estudo, preciso fazer mais alguma coisa?",
  "app.faq.answer9": "R: Não, não é necessário fazer mais nada. O software fechará automaticamente.",
  "app.faq.question10": "P: Preciso estar conectado à internet para participar?",
  "app.faq.answer10": "R: Sim, você precisará de uma conexão de Internet para baixar o programa e concluir a tarefa.",
  "app.faq.question11": "P: Preciso concluir a tarefa em uma superfície estável?",
  "app.faq.answer11": "R: Sim, para obter os melhores resultados, você deve se sentar de forma confortável na frente da tela de seu computador e, após a calibração, manter sua cabeça estática enquanto você é apresentado com o estímulo, até ser avisado. Aconselhamos não participar se você estiver no interior de um trem ou equilibrando seu laptop no colo, etc.",
  "app.faq.question12": "P: As seções de perguntas frequentes não resolveram meu problema, o que faço agora?",
  "app.faq.answer12": "R: Envie um e-mail para study-feedback@lumen-research.com, sem seguinda entraremos em contato com você o mais rápido possível.",
  "app.faq.patent": "O teste do conteúdo da web pela Lumen é desenvolvido pela nossa própria tecnologia exclusiva de teste em questão (Número do pedido de patente do Reino Unido 1816158.8)",
  "app.terms.line1": "Esses termos de uso (\"Termos de Uso\") regem o acordo entre Você e a Lumen Research Ltd (\"Lumen\" ou \"Nós\"), uma empresa registrada na Inglaterra, número da empresa 8682432 com sede registrada na Unidade 215, 22 Highbury Grove, Londres N5 2EF e referência de registro de proteção de dados ZA130772, referente ao seu uso do software de rastreamento ocular \"ViewPoints\" (o \"Software\") em seu aparelho.",
  "app.terms.line2": "1. Acordo de termos",
  "app.terms.line3": "Ao utilizar o Software, você reconhece que concordou em estar vinculado a este Contrato. Se você não concordar com os termos e condições deste Acordo, não preencha a pesquisa ViewPoints.",
  "app.terms.line4": "2. Informações Confidenciais",
  "app.terms.line5": "Ao instalar, usar ou de qualquer forma se envolver com ViewPoints, você poderá ter acesso a informações e/ou conteúdos de propriedade ou pertencentes à Lumen, incluindo informações que são identificadas ou tratadas pela Lumen ou por qualquer cliente ou clientes da Lumen como confidenciais ou que, em razão de seu caráter ou das circunstâncias ou maneira de sua divulgação, são evidentemente confidenciais, incluindo (sem prejuízo da generalidade precedente) quaisquer informações sobre planos de negócios, novas oportunidades de negócios, projetos de pesquisa e desenvolvimento, projetos, processos secretos, códigos, projetos de software, segredos de negócios, desenvolvimento de produtos ou serviços e fórmulas, know-how, invenções, estatísticas e previsões de vendas, estratégias e planos de marketing, custos, lucros e perdas e outras informações financeiras (exceto na medida em que publicadas em contas auditadas), preços e estruturas de desconto (tudo registrado ou não por escrito ou em formato eletrônico ou outro formato) (\"Informações Confidenciais\"). Tais Informações Confidenciais permanecerão de propriedade única e exclusiva da Lumen e/ou de seus clientes, e em nenhum caso você deverá obter ou receber qualquer direito, título e/ou interesse em tais Informações Confidenciais.",
  "app.terms.line6": "Você não deve nem durante sua participação nos ViewPoints nem a qualquer momento (sem limite) após o término de tal participação: divulgar ou comunicar a qualquer pessoa; usar para seus próprios fins; ou através de qualquer falha em exercer o devido cuidado e diligência, efetuar qualquer divulgação não autorizada de qualquer Informação Confidencial. Você deve sempre se empenhar ao máximo para evitar a publicação ou divulgação de qualquer Informação Confidencial. Essas restrições deixarão de se aplicar a qualquer informação que se torne disponível para o público em geral, exceto caso você cometa negligência.",
  "app.terms.line7": "3. Propriedade da Lumen",
  "app.terms.line8": "O Software ViewPoints e todos os dados, códigos, chaves, senhas, modelos, criações, registros e outros documentos ou materiais relacionados ao Software serão e permanecerão de propriedade da Lumen durante toda a duração de sua participação na pesquisa ViewPoints. A Lumen detém todos e quaisquer direitos intelectuais ou de propriedade sobre todos e quaisquer documentos, materiais, modelos, projetos, esboços, processos, invenções, fórmulas, programação computadorizada, dados, interfaces de sistemas, metodologias, know-how, informações confidenciais ou outro trabalho, realizado, criado, concebido, desenvolvido, melhorado, desconstruído ou descoberto por você sozinho ou com qualquer outra pessoa em conexão ou relacionado com o Software e/ou Dispositivo ou capaz de ser usado ou adaptado para uso nele ou em conexão (seja registrado ou não registrado), incluindo todas as patentes existentes e futuras, direitos autorais, direitos de projetos, direitos de banco de dados, marcas registradas, direitos de topografia de semicondutores, direitos de variedades vegetais, direitos de internet/nomes de domínio, know-how e todas e quaisquer aplicações para qualquer uma das finalidades anteriores e todos e quaisquer direitos de aplicação para qualquer uma das finalidades anteriores (\"Direitos de Propriedade\") Nenhum direito de propriedade lhe será transmitido em nenhum momento.\r\n",
  "app.terms.line9": "4. Termos e restrições de uso",
  "app.terms.line10": "O Software ViewPoints é licenciado aos usuários finais. Conforme este Contrato, concordamos em permitir que você, de forma não exclusiva, revogável, não transferível, não publicável, limitada, utilize o Software ViewPoints em um dispositivo que você possui ou controla ou em um computador pessoal considerando que você representa e garante que possui todos os direitos e permissões necessários para tal instalação. A fim de evitar incertezas, você não pode utilizar para nenhum fim que não seja o ViewPoints, traduzir, copiar, reproduzir, transmitir por telecomunicações, adaptar, integrar, alterar ou modificar o Software de nenhum modo.",
  "app.terms.line11": "Você concorda em não: (i) copiar, modificar, adaptar, disponibilizar, alugar, ceder, utilizar timeshare, criar um trabalho derivado de, ou tentar vender ou de outra forma transferir qualquer direito no Software (ii) desmontar, descompilar ou fazer engenharia reversa de qualquer parte do Software por qualquer meio; nem permitir ou ajudar qualquer parte a derivar ou tentar derivar o código fonte do Software, desmontar, descriptografar, descompilar ou fazer engenharia reversa do Software; nem tomar quaisquer outras medidas para obter informações sobre o projeto do Software; (iii) usar o Software para qualquer outro propósito que não seja o propósito para o qual ele foi originalmente fornecido a você; (iv) usar rastreadores, robôs ou outras técnicas automatizadas de mineração de dados para catalogar, baixar, armazenar ou reproduzir ou distribuir o Software, ou para manipular os resultados de qualquer pesquisa ou qualquer outra atividade; (v) adotar qualquer ação para interferir no Software ou no uso do Software por um indivíduo, incluindo, sem limitação, sobrecarregar, \"invadir\", \"bombardear\" ou \"travar\" o Software e o sistema de armazenamento de dados que o Software usa através do Amazon Web Services (vi) enviar-nos qualquer código ilegal, enganoso ou prejudicial, incluindo, sem limitação, qualquer vírus, spyware, adware ou qualquer outro código prejudicial.",
  "app.terms.line12": "Todos os direitos não expressamente concedidos a você neste documento são reservados a nós e a nossos licenciantes. Você concorda que não temos nenhuma obrigação sob este Contrato ou de outra forma para corrigir quaisquer falhas, defeitos ou erros no Software, ou de outra forma apoiar, manter, melhorar, modificar, atualizar, aperfeiçoar ou otimizar o Software. Não seremos responsáveis por fornecer assistência a você ou a seus representantes quanto ao uso do Software de forma alguma.",
  "app.terms.line13": "Não somos responsáveis por qualquer dano ao seu PC, incluindo, mas não se limitando a, como resultado do uso do Software. Não nos responsabilizamos por quaisquer encargos adicionais de dados incorridos por você como resultado dos dados coletados pelo Software através de sua participação na Pesquisa.",
  "app.terms.line14": "5. Isenção de responsabilidade",
  "app.terms.line15": "Nós e nossos respectivos diretores, funcionários, colaboradores, agentes, representantes, licenciadores e fornecedores não fazemos nenhuma representação ou garantia ou condição (a)de que o software estará disponível em tempo hábil, ou que o acesso ao software será ininterrupto ou seguro; (b)que os defeitos ou erros serão corrigidos; ou (c)que o software ou os servidores ou redes através dos quais o software e o dispositivo são disponibilizados são seguros ou estejam livres de vírus ou outros componentes prejudiciais. Não teremos nenhuma obrigação de fornecer qualquer manutenção e/ou serviços de suporte com relação ao software.",
  "app.terms.line16": "6. Limitação de responsabilidade",
  "app.terms.line17": "(a) Até o limite máximo permitido pela lei aplicável, você concorda que nós e nossos respectivos diretores, administradores, funcionários, agentes, representantes, licenciadores e fornecedores não teremos nenhuma responsabilidade por qualquer ou todas as perdas, danos ou custos de qualquer tipo resultantes de sua instalação, uso ou acesso ao software, seu uso ou incapacidade de usar o software, quaisquer informações ou materiais disponibilizados no ou através do software e/ou de outra forma relacionados ao software ou de outra forma a este contrato, independentemente da natureza do direito legal, legítimo ou estatutário que se alega ter sido violado.",
  "app.terms.line18": "(b) Até o limite máximo permitido pela lei aplicável, seu único e exclusivo recurso por insatisfação com o software é deixar de usá-lo.",
  "app.terms.line19": "(c) Em nenhuma hipótese qualquer de nossas entidades, diretores, executivos, funcionários, agentes, representantes será responsável perante você por quaisquer incidentes, indiretos, consequentes, legais, exemplares, punitivos ou especiais, perda de receita, perda de oportunidade, perda de reputação, perda de uso, perda de dados, perda de outros intangíveis, ou interceptação não autorizada de dados coletados pelo software por terceiros, sejam eles previsíveis ou não, seja qual for a causa, e sob qualquer contrato, delito civil (incluindo, sem limitação, negligência), responsabilidade estrita ou outra teoria, mesmo que avisada com antecedência da possibilidade de tais danos poderem surgir.",
  "demo.index.content.line1": "Durante esta demonstração, usaremos sua câmera para avaliar onde você está olhando. Não se preocupe, não vamos tirar fotos nem gravar vídeos.",
  "app.error.previousfail": "Você foi reprovado neste estudo anteriormente. Use o link original para começar do início.",
  "app.error.previoussuccess": "Você já completou este estudo anteriormente.",
  "app.error.route": "Você tentou iniciar um estudo já em andamento. Use o link original para começar do início.",
  "app.error.accesscode": "Você tentou iniciar um estudo que não existe, verifique seu link.",
  "app.error.webcam": "Este estudo requer acesso a uma webcam",
  "app.error.adblocker": "Este estudo requer que o Adblock esteja desabilitado.",
  "app.error.startsession": "Não é possível iniciar a sessão",
  "app.error.fullscreen": "Este estudo requer uma visualização em tela cheia.",
  "app.error.zoom": "Este estudo requer que o zoom do navegador esteja definido como 100%.",
  "app.index.webcamInstructions": "Estamos prestes a solicitar acesso à sua webcam. Se você receber um pop-up, clique em permitir.",
  "app.setup.retryBack": "Evite pressionar o botão Voltar do navegador durante este estudo, pois isso pode resultar na perda da recompensa. Use o botão abaixo para tentar novamente.",
  "app.setup.retryaudible": "Verifique se o seu dispositivo não está no modo silenciado e tente novamente.",
  "app.restore.retry": "Você não gastou tempo suficiente na etapa anterior, ",
  "app.restore.timeremaining": " minutos restantes",
  "app.error.postexposure": "Sinto muito, você usou todas as suas tentativas e agora, infelizmente, não pode continuar no estudo."
}