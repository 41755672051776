import { history } from "@history/";
import { Action } from "history";
import { store } from "../../store";
import { initParams } from "../../components/lumenController/actions";
import LumenEyeTracking from "../../components/lumenTracker";
import studyHistory from "../../components/util/studyHistory";
import { getStudyUrl, routeType } from "../../components/util/util";
import routeHistory from "../../components/util/routeHistory";
import Trashman from "../helpers/Trashman";
import LoggerController from "../../controllers/Logger/LoggerController";
import ErrorController from "../../controllers/Error/ErrorController";

class BackListener {
  constructor() {
    this.isListening = false;
    this.isWaiting = false;
    this.history = history;
    this.listener = null;
    this.backFrom = "";
    this.routeTo = null;
  }

  getRequestedRoute() {
    let requestedRoute = routeType();

    if (requestedRoute === "study") {
      requestedRoute = parseInt(
        window.location.pathname
          .split("/")
          .filter((item) => item)
          .slice(-1)[0],
        10
      );
    }

    return requestedRoute;
  }

  backPressed() {
    if (backListener.getRequestedRoute() === backListener.backFrom) {
      backListener.backFrom = "";
      return true;
    }

    return false;
  }

  getNextStep() {
    let { stepData } = store.getState().stepReducer;
    let nextSteps = stepData.filter((step) => !step.completed);

    return nextSteps.sort((a, b) => a.order - b.order)[0];
  }

  isLatestStep() {
    let urlParams = window.location.pathname.split("/").filter((n) => n);
    let requestedStep = parseInt(urlParams[3]);
    let nextStep = backListener.getNextStep();

    return requestedStep === nextStep.id;
  }

  subscribeCleanupTask(func) {
    return Trashman.addTask(func);
  }

  async handleBackPress() {
    if (backListener.history.action === Action.Pop) {
      backListener.backFrom = routeHistory
        .getEntries()
        .visitedSections.slice(-1)[0];
      LoggerController.events.logBackButton.panelistAction();
      backListener.isWaiting = true;

      Trashman.cleanUp();

      if (LumenEyeTracking.runningStep) {
        await LumenEyeTracking.runningStep.cancel();
      }

      let requestedRoute = routeType();
      let { stepData, studyId, participant } = store.getState().studyReducer;

      let requestStudyId = parseInt(window.location.pathname.split("/")[2]);
      let urlParams = new URLSearchParams(window.location.search);

      let loadedHistory = studyHistory.loadHistory(
        studyId || requestStudyId,
        participant || null
      );

      if (loadedHistory && loadedHistory.result === "fail") {
        store.dispatch(
          initParams(loadedHistory.studyId, loadedHistory.accessCode)
        );
        ErrorController.warnings.PreviousFail.raise();
        backListener.routeTo = `/${loadedHistory.studyId}/error/`;
        backListener.isWaiting = false;
        return;
      } else if (loadedHistory && loadedHistory.result === "success") {
        store.dispatch(
          initParams(loadedHistory.studyId, loadedHistory.accessCode)
        );
        ErrorController.warnings.PreviousSuccess.raise();
        backListener.routeTo = `/${loadedHistory.studyId}/error/`;
        backListener.isWaiting = false;
        return;
      }

      switch (requestedRoute) {
        case "setup":
        case "study":
          let nextStep = backListener.getNextStep();
          let nextStepIndex = stepData.length - 1;

          if (nextStep) {
            nextStepIndex = stepData.findIndex(
              (step) => step.id === nextStep.id
            );
          }

          if (!backListener.isLatestStep()) {
            backListener.routeTo = getStudyUrl(
              studyId,
              store.getState().studyReducer,
              nextStepIndex
            );
            backListener.isWaiting = false;
          }
          return;
        default:
          backListener.isWaiting = false;
          return;
      }
    } else if (backListener.history.action === Action.Push) {
      if (
        !!backListener.routeTo &&
        backListener.routeTo === window.location.pathname.split("/study")[1]
      ) {
        backListener.routeTo = null;
      }
    }
  }

  register = () => {
    LoggerController.events.logSubscribe.log("BackListener")
    if (!backListener.isListening) {
      backListener.listener = backListener.history.listen(
        backListener.handleBackPress
      );
      backListener.isListening = true;
    }
  };

  unRegister = () => {
    if (backListener.isListening && backListener.listener) {
      LoggerController.events.logRevoke.log("BackListener")
      backListener.listener();
      backListener.isListening = false;
    }
  };

  wait = () => {
    if (backListener.routeTo === window.location.pathname.split("/study")[1]) {
      backListener.routeTo = null;
    }

    return new Promise((resolve) => {
      let checkStatus = () => {
        if (!backListener.isWaiting) {
          if (!!backListener.routeTo) {
            LoggerController.events.logNavigate.log(
              `Routing back to ${backListener.routeTo}`,
              "BackListener"
            );

            resolve({ redirect: true, routeTo: backListener.routeTo });
          } else {
            resolve({ redirect: false });
          }
        } else {
          LoggerController.events.logWait.log("BackListener")
          setTimeout(checkStatus, 250);
        }
      };

      checkStatus();
    });
  };
}

let backListener = new BackListener();

export default backListener;
