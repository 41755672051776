import ErrorController from "../controllers/Error/ErrorController";

const handleError = () => {
  ErrorController.errors.RequestTimeout.raise()
};

const requestTimer = async (request) => {
  let timeOut;

  let timeOutPromise = new Promise(async (resolve, reject) => {
    timeOut = setTimeout(() => {
      handleError();
      resolve();
    }, 30000);
  });

  let requestPromise = async () => {
    let response = await request();
    clearTimeout(timeOut);
    return response
  };

  let result = await Promise.race([timeOutPromise, requestPromise()]);

  return result;
};

export default requestTimer;
