{
    "app.thankyou": "謝謝",
    "app.button.faqs": "常見問答集",
    "app.button.terms": "使用條款",
    "app.button.privacy": "隱私權政策",
    "app.button.feedback": "意見回饋",
    "app.button.selectLanguage": "選擇語言",
    "app.button.help": "輔助說明",
    "app.button.start": "開始",
    "app.button.next": "下一步",
    "app.button.submit": "提交",
    "app.button.close": "關閉",
    "app.button.return": "返回",
    "app.button.takepart": "好的 - 我要參加",
    "app.menu.feedback.content": "如果您在研究中遇到問題，或者只想留下評論，請隨時在這裡分享。",
    "app.contactModal.feedback": "感謝您的意見回饋！",
    "app.takePartModal.line1": "如果您現在離開，將無法獲得任何參與獎勵。",
    "app.takePartModal.line2": "您只需要一台網路攝影機！",
    "app.takePartModal.reason1": "我沒有網路攝影機。",
    "app.takePartModal.reason2": "我不希望我的網路攝影機用於此項調查。",
    "app.takePartModal.reason3": "我現在沒有時間。",
    "app.takePartModal.reason4": "其他原因。",
    "app.generic.redirect": "您將很快被重新導向。",
    "app.init.welcome": "歡迎來到 Lumen 研究平台",
    "app.init.intro": "此研究平台讓我們為特定的廣告研究招募和設置用戶。",
    "app.init.message1": "根據研究類型，用戶將被引導完成一系列任務。",
    "app.init.message2": "這些任務包眼動追蹤括：完成調查、閱讀一系列說明以及進行眼動追蹤校準。",
    "app.init.message3": "現在，我們將引導您進行演示研究。",
    "app.init.message4": "在本研究過程中，我們可能啟用您的網路攝影機進行眼動追蹤，但不會記錄任何您的數據。",
    "app.init.message5": "當前的研究設置使用以下連結進行篩選／完成。",
    "app.prestart.loading": "正在加載，請稍候。",
    "app.index.title": "歡迎",
    "app.index.content.line1": "在此調查中，我們將使用您的相機來估計您的視線方向。請放心，我們不會拍攝任何照片或影片。",
    "app.index.content.line1gpdrwarning": "在本調查中，我們將使用您裝置的前置相機，在您進行與觀看刺激信號相關的各種練習時拍攝您的圖像和影片。",
    "app.index.content.line1alt": "請確保您坐得舒適，然後點擊開始以開展調查。",
    "app.index.content.line2": "您的裝置有相機嗎？如果有，請按下面的開始按鈕。",
    "app.index.rejection": "抱歉，似乎您點擊了一個格式錯誤的連結。",
    "app.index.webview": "抱歉，您似乎從內嵌網頁瀏覽我們的頁面。請在您的瀏覽器內開啟此連結。您可能可以直接從內嵌網頁的功能表進行操作。",
    "app.index.webview.chrome.1": "點擊上面的功能表按鈕，然後選擇「使用 Chrome 開啟」。",
    "app.index.webview.chrome.2": "這將在瀏覽器應用程式中開啟本調查，以便它可以正常運作。",
    "app.index.webview.android.1": "請點擊下面的「開始」按鈕，以在瀏覽器應用程式中開展本調查，以便它可以正常運作。",
    "app.index.webview.android.2": "如果失敗，請手動在瀏覽器應用程式中開啟此頁面的連結（例如，在 Chrome 的網址列中複製並貼上連結，或者使用「使用 Chrome 開啟」等選項）。",
    "app.index.webview.safari.1": "點擊屏幕底部的 Safari 圖標繼續。",
    "app.index.webview.safari.2": "它看起來像這樣：",
    "app.index.webview.safari.3": "這將在 Safari 中開啟調查，以便它可以正常運作。",
    "app.index.wifi": "您的互聯網連接速度似乎太慢了。您是否連接到 WiFi？",
    "app.index.tellmemore": "告訴我更多。",
    "app.index.takepart": "我不想參加。",
    "app.index.webcamRetry": "我們無法偵測網路攝影機，這在本研究中是必要的。請提供網路攝影機的使用權限，然後重試。",
    "app.index.adblockRetry": "糟了！我們偵測到廣告攔截器。為了獲得最佳的研究體驗，請關閉它並重試。",
    "app.index.zoomRetry": "我們需要瀏覽器設定為 100% 縮放，請調整縮放比例然後重試。",
    "app.index.fullscreendesktopretry": "本研究需要使用全螢幕模式，請確保您的瀏覽器視窗已最大化。",
    "app.index.fullscreendesktoptest": "本研究需要您的瀏覽器視窗處於全螢幕模式，我們需要進行測試以確保您的瀏覽器視窗已最大化。",
    "app.index.fullscreendesktoptest2": "有兩個按鈕即將出現，請點擊它們以繼續。",
    "app.index.fullscreendesktopmaximised": "我的螢幕已經最大化",
    "app.index.fullscreendesktopskip": "謝謝，我們已記錄此情況並將改進我們的偵測流程。",
    "app.index.fullscreendesktopreset": "我們偵測到視窗大小變化，正在重置測試。",
    "app.index.gotomobile": "桌上型電腦的用戶不符合參加本研究的條件，請使用下方的 QR 碼在行動裝置上開啟研究。",
    "app.index.returntopanel": "或者，點擊下方按鈕返回面板",
    "app.instructions.title": "操作說明",
    "app.instructions.instruction1": "如果可能，請在調查期間不要戴眼鏡。",
    "app.instructions.instruction2": "坐姿應該讓您舒適地保持頭部和裝置靜止不動。",
    "app.instructions.instruction3": "移動您的眼睛，而不是頭部。",
    "app.instructions.instruction4": "準備好開始了嗎？",
    "app.instructions.instruction5": "請使用 WiFi 以避免產生數據費用。",
    "app.instructions.instruction6": "請仔細閱讀以下說明。",
    "app.instructions.instruction7": "將手機放在穩定的表面上。",
    "app.instructions.instruction8": "請確保您的房間光線充足 —— 最好從上方（而不是後方）。",
    "app.setup.title": "設置",
    "app.setup.instruction0": "將您的設備保持為直立模式。",
    "app.setup.instruction0a": "請將您的設備旋轉至直立模式。",
    "app.setup.instruction0b": "請將您的設備旋轉，使其相機鏡頭位於左側。",
    "app.setup.instruction0c": "請將您的設備旋轉，使其相機鏡頭位於右側。",
    "app.setup.instruction0d": "請將您的設備旋轉至橫向模式。",
    "app.setup.instructioncamera": "請點擊下方的按鈕以請求相機的取用權限。完成調查需要在整個研究過程中允許相機的取用權限。",
    "app.setup.cameraText": "請求取用權限",
    "app.setup.instruction1": "初始化中……",
    "app.setup.instruction2": "偵測到後，請將您的頭部放入方框內。",
    "app.setup.instruction3": "在調查完成之前，請保持頭部和裝置的位置不變。",
    "app.setup.instruction3audio": "請仔細聆聽並按照屏幕上即將出現的指示進行。",
    "app.setup.instruction4": "現在我們只需要驗證。",
    "app.setup.instruction5": "謝謝！請記住在調查完成之前保持頭部和裝置在同一位置。",
    "app.setup.instruction6": "請向上滑動以隱藏網址列。",
    "app.setup.retry": "偵測到錯誤。請保持頭部和裝置靜止不動，不要遮擋攝像頭，例如用手指。使用下方的按鈕重試。",
    "app.setup.retryPositioning": "我們無法確定您的頭部位置。請保持頭部和裝置靜止不動，不要遮擋攝像頭，例如用手指。使用下方的按鈕重試。",
    "app.setup.retryInit": "偵測到錯誤。請確保瀏覽器可以取用您的相機。如果您之前拒絕了相機取用權限，您需要在瀏覽器的隱私設定中允許此權限。使用下方的按鈕重試。",
    "app.setup.retryBroker": "我們無法完成校準過程。請嘗試關閉所有未使用的應用程序和分頁以釋放資源。",
    "app.setup.retryButton": "重試",
    "app.setup.calibrationProp.watch": "當在屏幕上出現小圓點時，跟隨此小圓點。",
    "app.setup.calibrationProp.watch.george": "接下來，當您看到一張臉時，請繼續看著它，同時模仿它的頭部動作。",
    "app.setup.calibrationProp.click": "點擊任意處繼續",
    "app.setup.calibrationProp.tap": "輕觸任意處繼續",
    "app.study.validationProp.watch": "當在屏幕上出現小圓點時，跟隨此小圓點。",
    "app.study.validationProp.restart": "請再試一次",
    "app.study.validationProp.click": "點擊任意處繼續",
    "app.study.validationProp.tap": "輕觸任意處繼續",
    "app.study.render.error": "域名無效以進行渲染步驟。",
    "app.error.title": "錯誤",
    "app.error.generic": "糟了！出了點問題。",
    "app.error.initialise": "無法初始化！",
    "app.error.findCamera": "無法偵測相機！",
    "app.error.headPosition": "無法確定您的頭部位置。",
    "app.error.calibrate": "無法進行校準。",
    "app.error.validate": "無法驗證。",
    "app.error.start": "啟動失敗。",
    "app.error.assign": "分配失敗。",
    "app.error.osnotallowed": "篩選淘汰：您的作業系統不被允許，請檢查您能用於本調查的裝置，然後重試。",
    "app.error.demoquotafullnoactivecell": "配額已滿：感謝您的興趣！本研究已對您的人口群組關閉。",
    "app.error.cellquotafullnoactivecell": "在本研究未找到可用的儲存格。儲存格配額已滿。",
    "app.error.missingpanelparameter": "缺少面板參數。",
    "app.invalid.title": "無效",
    "app.invalid.generic": "抱歉，您不符合本研究的資格條件。",
    "app.completion.title": "完成",
    "app.completion.congratulations": "恭喜！您已完成此研究。本調查對您的相機取用權限將被終止。",
    "app.completion.congratulations-noet": "恭喜！您已完成此研究。",
    "app.questions.required": "此問題為必填。",
    "app.faq.gdpr.intro1": "在本研究中，我們將使用行動裝置的前置相機，當用戶被要求在各種環境條件下（例如照明和位置）進行與觀看刺激信號相關的各種練習時，拍攝用戶的圖像和影片。  參與預計需要約5分鐘。  這些數據將用於輔助持續開發能夠估算用戶在屏幕上看向何處的軟體。",
    "app.faq.gdpr.intro2": "本研究遵循 MRS 行為準則和2018年資料保護法進行。  您的隱私對我們來說相當重要。  在參與本研究之前，您必須確認您同意通過參與本研究，您了解我們正在收集的數據及其用途，正如隱私權政策中所述，並同意將這些數據用於此目的。  本政策更詳細地描述研究收集了哪些資料以及如何使用和共享這些資料。",
    "app.faq.gdpr.question1": "研究將收集哪些數據？",
    "app.faq.gdpr.answer1.line1": "本研究將涉及收集以下類型的數據：",
    "app.faq.gdpr.answer1.line2": "「受訪期間特徵」 —— 用於識別特定數據收集「受訪期間」的匿名辨認 ID，以及收集期間的環境測試條件的數據。",
    "app.faq.gdpr.answer1.line3": "「參與者特徵」 —— 有關參與特定「受訪期間」的受訪者的數據，包括有關參與者人口群組（例如年齡、性別），族裔（例如皮膚類型）和生物（例如眼睛顏色）特徵的資料。",
    "app.faq.gdpr.answer1.line4": "「影片檔案」 —— 在參與者觀看一個或多個屏幕上顯示的刺激信號和可能與其互動時，使用裝置的前置相機拍攝的圖像和／或影片。在完成任務時，前置相機將會拍攝到參與者的眼睛、面部並可能會意外捕捉任何其他東西的圖像。 ",
    "app.faq.gdpr.answer1.line5": "「後設資料」 —— 有關個人與刺激信號進行互動的時間的數據，與收集到的每張圖像的時間戳記相關聯。",
    "app.faq.gdpr.answer1.line6": "「物理裝置數據」 —— 關於裝置的物理數據，包括裝置名稱、屏幕尺寸、屏幕旋轉和相機傳感器旋轉。",
    "app.faq.gdpr.answer1.line7": "通過參與此研究，您理解並同意您在本研究中看到的內容可能是機密的，是第三方的財產和／或受商標或版權法保護，您不會截圖、錄製或以其他方式複製任何研究內容，也不會以任何方式重新分發研究內容。",
    "app.faq.gdpr.question2": "數據將如何使用／處理？",
    "app.faq.gdpr.answer2": "數據將用於開發能夠估計用戶是否在裝置前方、是否在觀看刺激信號以及可能正在觀看刺激信號那部分的軟體。  它也可能用於演示目的或向第三方（客戶、供應商、合作夥伴）解釋或幫助評估我們的技術的開發方式以及其表現。  數據可能由任何數量的數據「註釋者」審查 —— 員工、分包商或合作夥伴或任何其他任何其他第三方，他們將被要求觀看影片／圖像以評估研究期間參與者的注意「狀態」（例如，他們是否在相機的攝影範圍內？他們是否在看屏幕？）。  處理數據的法律依據將是知情同意和合法利益。 ",
    "app.faq.gdpr.question3": "數據將保留多久？",
    "app.faq.gdpr.answer3": "只要為上述目的合理必要，我們將保留數據供我們使用。  正在開發的軟體會定期更新和評估，而這些數據將用於評估每一次迭代，因此預計將保存並保留用於此目的數年，甚至永久保留。  如果數據被認為不再對開發或評估刺激信號測試軟體的目的有用，數據將被刪除。 ",
    "app.faq.gdpr.question4": "誰將收到和處理數據？",
    "app.faq.gdpr.answer4": "數據將由 Lumen Research Ltd 保留和保存。Lumen Research Ltd 是在英格蘭註冊的公司，註冊號碼為8682432。  其註冊地址為 LABS house, 15-19 Bloomsbury Way, Holborn, London（英國倫敦），郵政編碼 WC1A 2TH。其數據保護註冊參考為 ZA130772。  其數據保護專員是 Matt Robinson (matt.robinson@lumen-research.com)。  您隨時可以聯繫英國的數據保護當局 —— 資訊專員辦公室 (ICO) —— 投訴有關您的數據處理方式。  資訊專員辦公室的聯繫詳情可在其網站 https://ico.org.uk/global/contact-us/ 上獲得。參與研究即表示您授予 Lumen 全球、免版稅、可再授權且可轉移的許可權，以托管、存儲、使用、顯示、重製、修改、改編、編輯、發布和分發此數據。",
    "app.faq.gdpr.question5": "我有權訪問我的個人數據嗎？",
    "app.faq.gdpr.answer5": "是的，您有權訪問您的個人數據，但重要的是要知道，您的名稱將不會與此次練習期間收集的數據一起儲存。  為了識別您的數據記錄，您將需要向我們提供您進行研究的確切時間和日期，我們將需要請求視覺識別來確保數據記錄被正確匹配。",
    "app.faq.gdpr.question7": "我有權撤回對處理個人數據的同意，或者要求刪除我的個人數據嗎？",
    "app.faq.gdpr.answer7": "有，您有權撤回對處理您個人數據的同意，以及要求刪除這些數據。  但重要的是要知道，您的名稱將不會與此次練習期間收集的數據一起儲存。  為了識別您的數據記錄，您將需要向我們提供您進行研究的確切時間和日期，我們將請求視覺識別來確保數據記錄被正確匹配。  ",
    "app.faq.gdpr.question8": "如何確保我的個人資訊安全？",
    "app.faq.gdpr.answer8": "Lumen Research 收集的數據採用安全通訊端層 (SSL) 技術進行加密。  這是一種常用的高標準加密技術。這技術用於保護傳輸通道和最終存儲。",
    "app.faq.gdpr.question9": "我們將如何使用和披露我們收集的資料？",
    "app.faq.gdpr.answer9": "我們可能將我們收集的資料披露給其他第三方服務提供商，以使他們能夠為我們提供服務。在我們業務、資產或股份的全部或任何部分進行任何重組、合併、出售、合資、轉讓、轉移或其他處置（包括任何破產或類似法律程序）的情況下，我們也可能向第三方披露資料。此外，根據以下我們認為必要或適當的情況，我們可能使用或披露從您收集及已收集關於您的資料：(a) 根據適用法律，包括您居住國家／地區之外的法律；(b) 遵守法律程序；(c) 回應來自公眾和政府機構的請求，包括您居住國家／地區之外的公眾和政府機構的請求；(d) 執行我們的最終用戶許可協議；(e) 保護我們或任何我們的關係企業的業務運作；(f) 保護我們和／或我們的關係企業、您或其他個體的權利、隱私、安全、財產；(g) 使我們能夠追求可用的賠償方法或限制我們可能遭受的損害賠償；(h) 偵測和解決任何欺詐或安全問題。 ",
    "app.faq.gdpr.question10": "我們採取哪些步驟來安全保留數據？",
    "app.faq.gdpr.answer10": "我們採取合理的步驟來保護您的資料，包括物理、電子或程序安全措施，適合於我們保管或控制的資料的敏感性，這些措施包括防止丟失或被竊、未經授權的存取、披露、複製、使用或修改。 ",
    "app.faq.question1": "問：Lumen 研究平台是什麼，以及參與眼動追蹤調查包括什麼？",
    "app.faq.answer1": "答：本調查涉及眼動追蹤技術的使用。您將被要求坐在您裝置的網路攝影機或前置相機前進行校準，以便估計您在屏幕上的注視位置。在調查期間，您將被要求查看一些東西（例如：圖像、網站或影片），同時調查工具將估計您的注視位置。從「眼動追蹤」中捕獲的數據是螢幕上隨著時間的推移估計的注視點的座標。軟體使用您網路攝影機中的影像來進行這些估計，但不會拍攝您的照片或影片。您可能還會被問到一些有關您自己、您的經歷以及您在調查期間看到的物品的問題。數據會傳送至 Lumen Research Ltd。Lumen Research 將分析數據並與客戶分享結果。",
    "app.faq.question2": "問：為什麼此軟體需要網路攝影機／相機以及如何使用它？",
    "app.faq.answer2": "答：此軟體使用您的網路攝影機或裝置相機中的影像來估計您在螢幕上看的位置。它使用臉部追蹤和眼動追蹤軟體的組合來實現這一點，在您的機器上對本機影像進行即時分析。這些影像在校準過程中經過處理並向您顯示，以便您可以正確定位自己，但影像不會被記錄，因此您的隱私受到保護。從眼動追蹤記錄和收集的數據不是影像，而是有關隨著時間的推移刺激信號上的估計注視點的數值數據。",
    "app.faq.question3": "問：在進行眼動追蹤時會拍攝我的影像嗎？",
    "app.faq.answer3": "答：唯一會使用影像的時候是讓您在校準過程中正確定位自己。這些影像不會保存，並只有您可以看到。從眼動追蹤記錄和收集的數據不是圖像，而是有關隨著時間的推移刺激信號上的估計注視點的數值數據。",
    "app.faq.question4": "問：調查期間會收集哪些數據？",
    "app.faq.answer4.line1": "答：軟體將收集以下五種類型的部分或全部數據：",
    "app.faq.answer4.line2": "「使用者 ID」—— 用於識別受訪者的使用者 ID（用於管理參與獎勵）；",
    "app.faq.answer4.line3": "「注視數據」—— 當螢幕上顯示刺激信號時，對使用者在螢幕上注視位置的估計",
    "app.faq.answer4.line4": "「調查數據」—— 使用者回答問題的回應",
    "app.faq.answer4.line5": "「後設資料」 —— 有關個人成功參與研究的時間的資料。",
    "app.faq.answer4.line6": "「實體設備資料」—— 有關使用者參與研究所用電腦的數據，例如：RAM 等級、機器中的 CPU 類型和使用者代理程式（作業系統和瀏覽器版本），以及有關電腦螢幕的資訊（大小和解析度）、網路攝影機（品牌和解析度）和防毒程式。",
    "app.faq.question5": "問：收集的數據將如何使用？",
    "app.faq.answer5.line1": "答：收集的數據將用於探索個人如何觀看各種類型的視覺圖像和媒體並與之互動，並評估人們對所顯示圖像的看法。",
    "app.faq.answer5.line2": "Lumen 可能會與關係企業以及業務合作夥伴和客戶分享研究數據。雖然研究數據可能會與關係企業、合作夥伴和客戶共享，但這一般是匿名共享。此類關係企業、合作夥伴和客戶可以使用這些數據來創建各種消費者行為和消費者群體的摘要描述，以開發產品或為其客戶或消費者提供行銷解決方案。",
    "app.faq.answer5.line3": "Lumen 收集「實體設備資料」和「後設資料」以協助管理數據的收集和處理，包括品質控制和數量管理。",
    "app.faq.answer5.line4": "所收集的數據將保留一段必要的期限，以實現概述的目的。",
    "app.faq.question6": "問：我的數據有多安全？",
    "app.faq.answer6": "答：Lumen 收集的數據採用 SSL 技術進行加密。這是最常用的高標準加密技術，銀行使用它來保護銀行交易和其他高度敏感的通訊。亞馬遜、PayPal、eBay 和其他需要銀行級安全系統的網站也使用它。科學家估計，使用目前技術破解 256 位元加密所需的時間比宇宙年齡還要長得多。收集的數據儲存在由 Amazon Web Services (AWS) 管理的安全網路伺服器上。AWS 是最常用的安全可靠雲端運算服務平台之一，被包括 Netflix 和 Expedia 在內的主要基於雲端的商業服務所使用。",
    "app.faq.question7": "問：對於向您展示的內容，您有哪些義務？",
    "app.faq.answer7": "答：通過參與，您也理解並同意您在本研究中看到的內容可能是機密的，是第三方的財產和／或受商標或版權法保護，您不會截圖、錄製或以其他方式複製任何研究內容，也不會以任何方式重新分發研究內容。",
    "app.faq.question8": "問：我不確定我的網路攝影機有多好，它能用於眼動追蹤嗎？",
    "app.faq.answer8": "答：系統可與多種網路攝影機配合使用，這應該不是問題。",
    "app.faq.question9": "問：我已經完成了研究，還需要做其他事情嗎？",
    "app.faq.answer9": "答：不，您不需要做任何其他事情。軟體將自動關閉。",
    "app.faq.question10": "問：我需要連接到網路才能參與嗎？",
    "app.faq.answer10": "答：是的，您將需要網路連線來下載程序並完成任務。",
    "app.faq.question11": "問：我需要在穩定的表面上完成任務嗎？",
    "app.faq.answer11": "答：是的，為了獲得最佳結果，您應該舒適地坐在電腦螢幕前，並在校準後，在接受刺激信號時保持頭部靜止，直到收到通知。如果您在火車上或將筆記型電腦放在腿上等，我們建議您不要參加。",
    "app.faq.question12": "問：常見問題集沒有解決我的問題，我現在該怎麼辦？",
    "app.faq.answer12": "答：請發送電子郵件至 study-feedback@lumen-research.com，我們會盡快回覆您。",
    "app.faq.patent": "Lumen 的網頁內容測試由我們自己專有的情境測試技術提供支援（英國專利申請號碼1816158.8）",
    "app.terms.line1": "本使用條款（「使用條款」）管轄您與 Lumen Research Ltd（「Lumen」或「我們」）之間的協議，該公司是在英格蘭註冊的公司，註冊號碼為8682432，註冊地址為 Unit 215, 22 Highbury Grove, London N5 2EF，以及數據保護註冊參考為 ZA130772，涉及您在您的裝置上使用「ViewPoints」眼動追蹤軟體（「軟體」）。",
    "app.terms.line2": "1.同意條款",
    "app.terms.line3": "使用軟體即表示您承認您已同意受本協議的約束。如果您不同意本協議的條款和條件，請不要完成 ViewPoints 調查。",
    "app.terms.line4": "2.機密資訊",
    "app.terms.line5": "在安裝、使用或以任何方式參與 ViewPoints 時，您可能取得屬於或與 Lumen 相關的資訊和／或內容，包括由 Lumen 或Lumen 的任何客戶或顧客識別或視為機密的資訊，或因其性質或其揭露的情況或方式而顯然是機密的資訊，包括（在不損害上述一般性的情況下）有關商業計劃、成熟的新商業機會、研發項目、設計、秘密流程、程式碼、軟體設計、營業秘密、產品或服務開發和配方、專有技術、發明、銷售統計和預測、行銷策略和計劃、成本、損益和其他財務資訊（經審計帳目中公佈的除外）、價格和折扣結構（所有無論是否以書面、電子或其他格式記錄）的資訊（「機密資訊」）。此類機密資訊是 Lumen 和／或其客戶的唯一和專有財產，在任何情況下，您均不得獲得或接受此類機密資訊的任何權利、所有權和／或權益。",
    "app.terms.line6": "您在參與 ViewPoints 期間或在該參與結束後的任何時間（無時間限制）均不得：向任何人透露或傳達；為您自己的目的使用；或因未能採取應有的謹慎並盡應有的努力，而導致任何機密資訊的未經授權的揭露。您必須始終盡最大努力防止任何機密資訊的發布或揭露。這些限制不適用於通過您的過失以外的方式而成為普遍可供公眾使用的任何資訊。",
    "app.terms.line7": "3.Lumen 財產",
    "app.terms.line8": "ViewPoints 軟體以及與本軟體相關的所有數據、程式碼、金鑰、密碼、設計、圖紙、記錄和其他文件或材料在您參與 ViewPoints 調查的整個期間均屬於 Lumen 的財產。Lumen 擁有在與軟體和／或裝置有關的或與之相關的或能夠用於或適用於其中或與之相關的的任何文檔、材料、模型、設計、圖紙、流程、發明、配方、電腦編碼、數據、系統介面、方法、專有技術、機密訊息或其他作品中，由您單獨或與他人合作所執行製作、創建、設計、開發、增強、解構或發現的任何和所有現有及未來的智慧財產權或工業財產權中的所有智慧財產權或專有權利（不論是否已註冊），包括所有現有和未來的專利、著作權、設計權、數據庫權、商標、半導體拓樸圖權、植物品種權、互聯網權／域名、專有技術，以及上述任何事項的任何和所有申請，以及申請上述任何事項的任何和所有權利（「專有權利」）。任何時候任何專有權利均不得轉讓給您。",
    "app.terms.line9": "4.使用條款和限制",
    "app.terms.line10": "ViewPoints 軟體已授權給終端使用者。根據本協議，我們特此同意允許您在非獨佔、可撤銷、不可轉讓、不可再授權的有限基礎上，在您擁有或控制的裝置或個人電腦上使用 ViewPoints 軟體，您聲明並保證您擁有此類安裝的所有必要權利和許可。為避免疑義，您不得將軟體用於 ViewPoints 以外的任何目的，也不得以任何方式翻譯、拷貝、複製、透過電信傳輸、改編、合併、變更或修改本軟體。",
    "app.terms.line11": "您同意不得：(i) 複製、修改、改編、提供、出租、租賃、分時共享、創建衍生作品、或試圖出售或以其他方式轉讓軟體中的任何權利；(ii) 以任何方式對軟體的任何部分進行拆解、反編譯或逆向工程；或允許或協助任何方衍生或試圖衍生軟體源代碼、拆解、解密、反編譯或逆向工程軟體；或採取任何其他步驟以獲得有關軟體設計的資訊；(iii) 將軟體用於其他於最初分發給您的目的以外的任何目的；(iv) 使用蜘蛛、機器人或其他自動化數據挖掘技術來編目、下載、儲存或以其他方式複製或分發軟體，或操縱任何調查或其他活動的結果；(v) 採取任何行動干擾軟體或個人使用軟體的行為，包括但不限於通過 Amazon Web Services 使軟體和軟體使用的數據儲存系統過載、受「洪水攻擊」、「郵件炸彈」或「崩潰」；(vi) 向我們發送任何非法、欺騙性或有害的代碼，包括但不限於任何病毒、間諜軟體、廣告軟體或任何其他有害代碼。    ",
    "app.terms.line12": "所有未在此明確授予您的權利均保留給我們及我們的授權人。您同意我們在本協議下或在其他方面沒有義務修正軟體中的任何程式錯誤、缺陷或錯誤，或以其他方式支持、維護、改進、修改、升級、更新或增強軟體。我們不負責以任何方式向您或您的指定人員為使用軟體提供協助。",
    "app.terms.line13": "我們對您的個人電腦的任何損壞不承擔任何責任，包括但不限於由於您使用軟體而導致的損壞。因軟體在您參與研究過程中收集的數據而產生的任何額外數據費用，我們概不負責。",
    "app.terms.line14": "5.免責聲明",
    "app.terms.line15": "我們及我們各自的董事、管理人員、員工、代理、代表、授權人和供應商不就下列任何事項作出任何聲明或保證或條件：(a) 軟體將及時提供，或軟體的存取將不間斷或安全；(b) 缺陷或錯誤將被糾正；或 (c) 軟體或通過其提供軟體和裝置的伺服器或網絡是安全的，或無病毒或其他有害組件。我們沒有義務提供與軟體相關的任何維護和／或支援服務。",
    "app.terms.line16": "6.責任限制",
    "app.terms.line17": "(a) 在適用法律允許的最大範圍內，您同意對於您安裝、使用或存取軟體、使用或無法使用軟體、在軟體上或透過軟體提供的任何資訊或材料及／或與軟體有關或與本協議有關而造成的任何或所有損失、損害或任何類型的費用，無論聲稱違反的法律、衡平法或法定權利的性質如何，我們和我們各自的董事、管理人員、員工、代理、代表、授權人和供應商均不承擔任何責任。",
    "app.terms.line18": "(b) 在適用法律允許的最大範圍內，您對軟體不滿意的唯一且專屬補救措施是停止使用本軟體。",
    "app.terms.line19": "(c) 在任何情況下，我們的任何實體、董事、管理人員、員工、代理、代表均不對您的任何附帶、間接、後果性、法定、懲戒性、懲罰性或特殊損害、收入損失、機會損失、聲譽損失、使用損失、數據損失、其他無形資產損失或第三方未經授權攔截軟體收集的數據負責，無論是否可預見，無論是如何造成，以及無論是否根據任何合約、侵權行為（包括但不限於疏忽）、嚴格責任或其他理論，即使事先已被告知可能發生此類損害。",
    "demo.index.content.line1": "在此示範中，我們將使用您的相機來估計您的視線方向。請放心，我們不會拍攝任何照片或影片。",
    "app.error.previousfail": "您之前曾參與本研究失敗，請使用原始連結從頭開始。",
    "app.error.previoussuccess": "您之前已經完成了本研究。",
    "app.error.route": "您嘗試開始正在進行的研究，請使用原始連結從頭開始。",
    "app.error.accesscode": "您嘗試開始不存在的研究，請檢查您的連結。",
    "app.error.webcam": "本研究需要存取網路攝影機",
    "app.error.adblocker": "本研究需要禁用 adblock。",
    "app.error.startsession": "無法啟動工作階段",
    "app.error.fullscreen": "本研究需要使用全螢幕模式。",
    "app.error.zoom": "本研究要求瀏覽器縮放設定為100%。",
    "app.index.webcamInstructions": "我們即將要求取用您的網路攝像頭權限，如果您收到彈出窗口，請按「允許」。",
    "app.setup.retryBack": "在整個研究過程中請不要按下瀏覽器的上一頁按鈕，這可能會導致損失獎勵。使用下方的按鈕重試。",
    "app.setup.retryaudible": "請確保您的裝置未靜音，然後再試一次。",
    "app.restore.retry": "您在上一步沒有花費足夠的時間， ",
    "app.restore.timeremaining": " 剩下分鐘",
    "app.error.postexposure": "抱歉，您現在已經用完了所有的嘗試，遺憾的是，您無法繼續進行研究。"
  }