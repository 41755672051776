{
    "app.button.faqs": "Najczęściej zadawane pytania",
    "app.button.terms": "Warunki użytkowania",
    "app.button.privacy": "Polityka Prywatności",
    "app.button.feedback": "Informacje zwrotne",
    "app.button.selectLanguage": "Wybierz język",
    "app.button.help": "Pomoc",
    "app.button.start": "Rozpocznij",
    "app.button.next": "Dalej",
    "app.button.submit": "Prześlij",
    "app.button.close": "Zamknij",
    "app.button.takepart": "OK - wezmę udział",
    "app.menu.feedback.content": "Jeśli doświadczyłeś jakiegoś problemu związanego z badaniem lub po prostu chciałbyś zostawić komentarz, możesz to zrobić tutaj",
    "app.contactModal.feedback": "Dziękujemy za Twoją opinię!",
    "app.takePartModal.line1": "Jeśli wyjdziesz teraz, nie będziesz się kwalifikował do otrzymania nagrody za uczestnictwo.",
    "app.takePartModal.line2": "Wystarczy, że masz kamerę internetową!",
    "app.takePartModal.reason1": "Nie mam kamery internetowej.",
    "app.takePartModal.reason2": "Nie czuję się komfortowo z tym, że podczas tej ankiety mam korzystać z mojej kamery internetowej.",
    "app.takePartModal.reason3": "Nie mam teraz na to czasu.",
    "app.takePartModal.reason4": "Inny powód.",
    "app.generic.redirect": "Wkrótce zostaniesz przekierowany.",
    "app.init.welcome": "Witamy na platformie badawczej Lumen Research",    
    "app.init.intro": "Platforma badawcza umożliwia wprowadzenie i przygotowanie użytkowników do danego badania reklamowego.",
    "app.init.message1": "W zależności od rodzaju badania użytkownik zostanie przeprowadzony przez zestaw zadań.",
	"app.init.message2": "Zadania te obejmują wypełnienie ankiety, zapoznanie się z zestawem instrukcji oraz przeprowadzenie kalibracji śledzenia wzroku",
    "app.init.message3":"Teraz przedstawimy Ci badanie demonstracyjne.",
    "app.init.message4": "W trakcie tego badania możemy włączyć Twoją kamerę internetową do śledzenia ruchów gałek ocznych, ale żadne Twoje dane nie będą rejestrowane.",
	"app.init.message5": "Obecne badanie jest skonfigurowane tak, aby po zakończeniu/wyłączeniu ekranu korzystać z następujących linków.",
    "app.prestart.loading": "Ładowanie, proszę czekać.",
    "app.index.title": "Witamy",
    "app.index.content.line1": "Podczas tego badania wykorzystamy Twoją kamerę, by ocenić, gdzie patrzysz. Nie martw się, nie będziemy robić żadnych zdjęć ani nagrywać filmów.",
    "app.index.content.line1alt": "Upewnij się, że siedzisz wygodnie, a następnie naciśnij rozpocznij, aby rozpocząć badanie.",
    "app.index.content.line2": "Czy Twoje urządzenie ma kamerę? Jeśli tak, naciśnij przycisk rozpocznij u dołu.",
    "app.index.rejection": "Przepraszamy, wygląda na to, że skorzystałeś z nieprawidłowo utworzonego linku.",
    "app.index.webview": "Przepraszamy, wygląda na to, że wszedłeś na naszą stronę z poziomu podglądu. Otwórz ten link w swojej przeglądarce. Możesz to zrobić bezpośrednio z menu przeglądarki.",
    "app.index.webview.chrome.1": "Naciśnij przycisk menu powyżej, a następnie wybierz 'Otwórz w Chrome'.",
    "app.index.webview.chrome.2": "Spowoduje to otwarcie ankiety w aplikacji przeglądarki, dzięki czemu będzie ona działać prawidłowo.",
    "app.index.webview.android.1": "Naciśnij Rozpocznij, aby otworzyć tę ankietę w przeglądarce, dzięki czemu będzie ona działać poprawnie.",
    "app.index.webview.android.2": "Jeśli się to nie powiedzie, otwórz link do tej strony w przeglądarce ręcznie (np. skopiuj i wklej link do paska adresu w Chrome lub użyj opcji takiej jak 'Otwórz w Chrome' na pasku menu powyżej).",
    "app.index.webview.safari.1": "Stuknij ikonę Safari na dole ekranu, aby kontynuować.",
    "app.index.webview.safari.2": "Wygląda to tak:",
    "app.index.webview.safari.3": "To otworzy ankietę w Safari, dzięki czemu będzie ona działać poprawnie.",
    "app.index.wifi": "Wygląda na to, że Twoje połączenie internetowe jest zbyt wolne. Czy jesteś podłączony do wifi?",
    "app.index.tellmemore": "Powiedz mi więcej na ten temat.",
    "app.index.takepart": "Nie chcę brać udziału.",
    "app.index.webcamRetry": "Nie udało nam się wykryć kamery internetowej, która jest wymagana w tym badaniu. Prosimy o zapewnienie dostępu do kamery internetowej i ponowienie próby.",
    "app.index.adblockRetry": "Ups! Wykryliśmy blokowanie reklam. Aby uzyskać najlepsze wyniki badania, wyłącz blokadę i spróbuj ponownie.",
    "app.instructions.title": "Instrukcje",
    "app.instructions.instruction1": "Jeśli to możliwe, nie zakładaj okularów podczas badania.",
    "app.instructions.instruction2": "Usiądź tak, byś mógł wygodnie trzymać głowę i urządzenie w bezruchu.",
    "app.instructions.instruction3": "Poruszaj oczami, a nie głową.",
    "app.instructions.instruction4": "Gotowy do startu?",
    "app.instructions.instruction5": "Prosimy korzystać z WiFi, aby uniknąć opłat za przesyłanie danych.",
    "app.instructions.instruction6": "Przeczytaj uważnie poniższe instrukcje.",
    "app.instructions.instruction7": "Oprzyj telefon na płaskiej, stabilnej powierzchni.",
    "app.instructions.instruction8": "Upewnij się, że pomieszczenie, w którym przebywasz, jest dobrze oświetlone - najlepiej od góry (nie od tyłu).",
    "app.setup.title": "Ustawienia",
    "app.setup.instruction0": "Ustaw urządzenie w trybie portretu.",
    "app.setup.instruction0a": "Obróć urządzenie, aby było w trybie portretu.",
    "app.setup.instruction0b": "Obróć urządzenie tak, aby aparat był po lewej stronie.",
    "app.setup.instruction0c": "Obróć urządzenie tak, aby aparat był po prawej stronie.",
    "app.setup.instruction0d": "Obróć urządzenie tak, aby aparat był ustawiony horyzontalnie.",
    "app.setup.instructioncamera": "Kliknij poniższy przycisk, aby poprosić o dostęp do kamery. Aby wypełnić ankietę, musisz zezwolić na dostęp do kamery podczas badania.",
    "app.setup.cameraText": "Poproś o dostęp",
    "app.setup.instruction1": "Inicjowanie...",
    "app.setup.instruction2": "Gdy zostaniesz wykryty, umieść głowę wewnątrz pola.",
    "app.setup.instruction3": "Utrzymuj głowę i urządzenie w tej pozycji aż do zakończenia badania.",
    "app.setup.instruction4": "Teraz wystarczy tylko zatwierdzić.",
    "app.setup.instruction5": "Dzięki! Pamiętaj, aby głowa i urządzenie pozostawały w tej samej pozycji aż do zakończenia badania.",
    "app.setup.instruction6": "Prosimy o przesunięcie palcem w górę, aby ukryć pasek adresu.",
    "app.setup.retry": "Wykryto błąd. Trzymaj głowę i urządzenie nieruchomo i nie zasłaniaj kamery palcami. Użyj przycisku poniżej, aby ponowić próbę.",
    "app.setup.retryInit": "Wykryto błąd. Upewnij się, że przeglądarka ma dostęp do twojej kamery. Jeśli wcześniej odrzuciłeś uprawnienia dostępu do kamery, będziesz musiał je zaakceptować w ustawieniach prywatności przeglądarki. Użyj przycisku poniżej, aby ponowić próbę.",
    "app.setup.retryButton": "Spróbuj ponownie",
    "app.setup.calibrationProp.watch": "Podążaj za kropką, gdy pojawi się ona na ekranie",
    "app.setup.calibrationProp.watch.george": "Następnie, gdy zobaczysz twarz, patrz na nią, odtwarzając jej ruchy głową",
    "app.setup.calibrationProp.click": "Kliknij w dowolnym miejscu, aby kontynuować",
    "app.setup.calibrationProp.tap": "Stuknij w dowolne miejsce, aby kontynuować",
    "app.study.validationProp.watch": "Podążaj za kropką, gdy pojawi się na ekranie",
    "app.study.validationProp.restart": "Prosimy spróbować ponownie",
    "app.study.validationProp.click": "Kliknij w dowolnym miejscu, aby kontynuować",
    "app.study.validationProp.tap": "Stuknij w dowolne miejsce, aby kontynuować",
    "app.study.render.error": "Domena nie odpowiada wymogom renderowania",
    "app.error.title": "Błąd",
    "app.error.generic": "Ups! Coś poszło nie tak.",
    "app.error.initialise": "Nie można rozpocząć!",
    "app.error.findCamera": "Nie można znaleźć kamery!",
    "app.error.headPosition": "Nie można określić pozycji głowy.",
    "app.error.calibrate": "Nie można przeprowadzić kalibracji.",
    "app.error.validate": "Nie można przeprowadzić weryfikacji.",
    "app.error.start": "Nie udało się uruchomić.",
    "app.error.assign": "Nie udało się przypisać.",
    "app.error.osnotallowed": "Screenout: Twój system operacyjny nie jest dozwolony. Sprawdź, z jakiego urządzenia możesz korzystać w tej ankiecie i spróbuj ponownie.",
    "app.error.demoquotafullnoactivecell": "Quotafull: Dziękujemy za zainteresowanie. To badanie jest teraz nieaktywne dla Twoich danych demograficznych.",
    "app.error.missingpanelparameter": "Brak parametru panelu.",
    "app.invalid.title": "Nieprawidłowy",
    "app.invalid.generic": "Przepraszamy, nie kwalifikujesz się do tego badania.",
    "app.completion.title": "Koniec",
    "app.completion.congratulations": "Gratulacje! Ukończyłeś badanie. Dostęp tej ankiety do Twojego aparatu zostanie teraz zakończony.",
    "app.questions.required": "To pytanie jest wymagane.",
	"app.faq.question1": "P: Czym jest Lumen Study Platform i na czym polega udział w badaniu śledzenia wzroku?",
	"app.faq.answer1": "O: To badanie wymaga użycia technologii śledzenia ruchów gałek ocznych. Zostaniesz poproszony, aby usiąść przed kamerą internetową lub przednim aparatem Twojego urządzenia i wykonać kalibrację, dzięki której będzie można określić, gdzie się patrzysz na ekranie. Podczas badania zostaniesz poproszony o obejrzenie niektórych elementów (np. zdjęć, stron internetowych lub filmów), podczas gdy narzędzie badawcze będzie oceniać, gdzie patrzysz. Dane uzyskane w wyniku śledzenia ruchów gałek ocznych to współrzędne szacowanych punktów twojego spojrzenia na ekranie w czasie. Oprogramowanie wykorzystuje do tych szacunków obraz z Twojej kamery internetowej, ale nie robi zdjęć ani nie nagrywa filmów. Możesz też otrzymać kilka pytań na swój temat, swoich doświadczeń i przedmiotów, które widziałeś podczas badania. Dane są przesyłane do Lumen Research Ltd. Lumen Research przeanalizuje te dane i podzieli się wynikami ze swoimi klientami.",
    "app.faq.question2": "P: Dlaczego oprogramowanie wymaga kamery internetowej / aparatu fotograficznego i jak jest ona używana?",
	"app.faq.answer2": "O: Oprogramowanie wykorzystuje obraz z twojej kamery internetowej lub aparatu w urządzeniu, aby oszacować, gdzie się patrzysz na ekranie. Wykorzystuje do tego kombinację oprogramowania do śledzenia twarzy i oczu, które analizuje obraz lokalnie na twoim komputerze w czasie rzeczywistym. Obrazy te są przetwarzane i pokazywane Ci podczas kalibracji, byś mógł prawidłowo ustawić się na ekranie, ale nie są one nagrywane, więc Twoja prywatność jest chroniona. Dane, które są rejestrowane i zbierane podczas śledzenia wzroku, to nie obrazy, ale dane liczbowe dotyczące szacowanych punktów spojrzenia na bodziec w czasie.",
    "app.faq.question3": "P: Czy podczas śledzenia wzroku robicie mi zdjęcia?",
    "app.faq.answer3": "O: Zdjęcia są wykorzystywane tylko po to, byś mógł prawidłowo ustawić się podczas kalibracji. Obrazy te nie są zapisywane i są widoczne tylko dla ciebie. Dane, które są rejestrowane i zbierane podczas śledzenia wzroku, to nie obrazy, ale dane liczbowe dotyczące szacowanych punktów spojrzenia na bodziec w czasie.",
    "app.faq.question4": "P: Jakie dane są zbierane podczas badania?",
    "app.faq.answer4.line1": "O: Oprogramowanie będzie zbierać niektóre lub wszystkie z następujących pięciu typów danych:",
    "app.faq.answer4.line2": "“User ID“ - ID użytkownika używane do identyfikacji respondentów (w celu przyznawania nagród za udział);",
    "app.faq.answer4.line3": "“Gaze data” – Szacunkowa informacja o tym, gdzie użytkownicy patrzą na ekran, gdy wyświetlane są bodźce na ekranie",
    "app.faq.answer4.line4" : "“Survey data” – odpowiedzi na pytania zadane przez użytkowników",
    "app.faq.answer4.line5": "“Metadata” – dane dotyczące czasu, w którym poszczególne osoby z powodzeniem uczestniczyły w badaniu",
    "app.faq.answer4.line6": "“Physical Device data“ - Dane dotyczące komputera, na którym użytkownik bierze udział w badaniu, takie jak ilość pamięci RAM, typ procesora w maszynie oraz interfejs użytkownika (system operacyjny i wersja przeglądarki), a także informacje o ekranie komputera (rozmiar i rozdzielczość), kamerze internetowej (marka i rozdzielczość) oraz programach antywirusowych.",
    "app.faq.question5": "P: Jak będą wykorzystywane zebrane dane?",
    "app.faq.answer5.line1": "A: Zebrane dane zostaną wykorzystane do zbadania, w jaki sposób poszczególne osoby oglądają i wchodzą w interakcje z różnymi rodzajami obrazów wizualnych i mediów oraz do oceny opinii ludzi na temat obrazów, które są im pokazywane.",
	"app.faq.answer5.line2": "Lumen może udostępniać dane badawcze podmiotom stowarzyszonym oraz partnerom biznesowym i klientom. Chociaż dane badawcze mogą być udostępniane podmiotom stowarzyszonym, partnerom i klientom, zazwyczaj odbywa się to na zasadzie anonimowości. Takie podmioty stowarzyszone, partnerzy i klienci mogą wykorzystywać te dane do tworzenia zbiorczych opisów szerokiego zakresu zachowań konsumenckich i segmentów konsumentów w celu opracowywania produktów lub dostarczania rozwiązań marketingowych swoim klientom lub konsumentom.",
    "app.faq.answer5.line3": "Lumen gromadzi 'Dane o urządzeniach fizycznych”oraz Metadane, aby pomóc w zarządzaniu gromadzeniem i przetwarzaniem danych, w tym w kontroli jakości i zarządzaniu objętością.",
    "app.faq.answer5.line4": "Zebrane dane będą przechowywane przez okres niezbędny do realizacji określonych celów.",
    "app.faq.question6" : "P: Jak bezpieczne są moje dane?",
    "app.faq.answer6": "O: Dane zbierane przez Lumen są szyfrowane przy użyciu technologii SSL. Jest to najpowszechniej stosowana technologia szyfrowania o wysokim standardzie, używana przez banki do zabezpieczania transakcji bankowych i innych bardzo wrażliwych komunikatów. Korzystają z niej także Amazon, PayPal, eBay i inne strony internetowe wymagające systemów bezpieczeństwa na poziomie bankowym. Naukowcy szacują, że złamanie 256-bitowego szyfrowania przy użyciu obecnej technologii wymagałoby znacznie więcej czasu niż wynosi wiek wszechświata. Zebrane dane są przechowywane na bezpiecznych serwerach internetowych zarządzanych przez Amazon Web Services (AWS). AWS jest jedną z najpowszechniej stosowanych bezpiecznych i niezawodnych platform usług przetwarzania w chmurze i jest wykorzystywana przez największe komercyjne usługi oparte na chmurze, takie jak Netflix czy Expedia.",
	"app.faq.question7": "P: Jakie mam obowiązki w związku z wyświetlanymi treściami?",
    "app.faq.answer7": "O: Biorąc udział w badaniu, rozumiesz i zgadzasz się, że treści pokazywane Ci w ramach tego badania mogą być poufne, stanowić własność osób trzecich i/lub podlegać prawom dotyczącym znaków towarowych lub praw autorskich oraz że nie będziesz robić zrzutów ekranu, nagrywać ani w inny sposób kopiować żadnych treści, ani też w żaden sposób rozpowszechniać treści badania.",
	"app.faq.question8": "P: Nie jestem pewien, czy moja kamera internetowa jest wystarczająco dobra, czy nadaje się ona do śledzenia wzroku?",
    "app.faq.answer8": "O: System działa z wieloma różnymi kamerami internetowymi - nie powinno to stanowić problemu.",
    "app.faq.question9": "P: Zakończyłem badanie, czy muszę zrobić coś jeszcze?",
    "app.faq.answer9": "O: Nie, nie trzeba robić nic więcej. Program zamknie się automatycznie.",
    "app.faq.question10": "P: Czy muszę być podłączony do internetu, żeby uczestniczyć w programie?",
    "app.faq.answer10": "O: Tak, będziesz potrzebował połączenia z internetem, żeby pobrać program i wykonać zadanie.",
    "app.faq.question11": "P: Czy muszę wykonywać zadanie na stabilnej powierzchni?",
    "app.faq.answer11": "O: Tak, aby uzyskać najlepsze wyniki, powinieneś usiąść wygodnie przed ekranem komputera, a po kalibracji trzymać głowę nieruchomo podczas prezentacji bodźców, aż do momentu otrzymania powiadomienia. Radzimy nie brać udziału w badaniu, jeśli jedziesz pociągiem lub trzymasz laptopa na kolanach itp.",
	"app.faq.question12": "P: FAQ nie pomogło w rozwiązaniu mojego problemu, co mam teraz zrobić?",
    "app.faq.answer12" : "O: Napisz do nas na adres study-feedback@lumen-research.com, a my odpowiemy Ci tak szybko, jak tylko będziemy mogli.",
    "app.faq.patent": "Testowanie zawartości stron internetowych przez Lumen jest oparte na naszej własnej technologii testowania kontekstowego (numer zgłoszenia patentowego w Wielkiej Brytanii 1816158.8)",
    "app.terms.line1": "Niniejsze warunki użytkowania ('Warunki użytkowania') regulują umowę pomiędzy Użytkownikiem a Lumen Research Ltd ('Lumen'lub 'My'), spółką zarejestrowaną w Anglii, o numerze 8682432 z siedzibą pod adresem Unit 215, 22 Highbury Grove, London N5 2EF i numerem rejestracyjnym ochrony danych ZA130772, dotyczącą korzystania przez Użytkownika z oprogramowania do śledzenia ruchu gałek ocznych 'ViewPoints'('Oprogramowanie') na urządzeniu Użytkownika.",
    "app.terms.line2" : "1. Zgoda na warunki",
    "app.terms.line3": "Korzystając z Oprogramowania, potwierdzasz, że zgadzasz się na przestrzeganie warunków niniejszej Umowy. Jeśli nie zgadzasz się na warunki tego Porozumienia, nie wypełniaj ankiety ViewPoints.",
    "app.terms.line4" : "2. Informacje poufne",
    "app.terms. line5": "Instalując, używając lub w jakikolwiek sposób korzystając z ViewPoints, możesz mieć dostęp do informacji i/lub treści należących lub związanych z Lumen, w tym informacji, które są identyfikowane lub traktowane przez Lumen lub któregokolwiek z klientów Lumen jako poufne lub które ze względu na swój charakter, okoliczności lub sposób ich ujawnienia są ewidentnie poufne, w tym (bez uszczerbku dla powyższej ogólności) wszelkie informacje o planach biznesowych, rozwijających się nowych możliwościach biznesowych, projektach badawczo-rozwojowych, projektach, tajnych procesach, kodach, projektach oprogramowania, tajemnicach handlowych, rozwoju i formułach produktów lub usług, know-how, wynalazkach, statystykach i prognozach sprzedaży, strategiach i planach marketingowych, kosztach, zyskach i stratach oraz innych informacjach finansowych (z wyjątkiem informacji opublikowanych w zaudytowanych sprawozdaniach finansowych), cenach i strukturach rabatów (wszystkie bez względu na to, czy zostały sporządzone na piśmie, w formie elektronicznej lub innej) ('Informacje Poufne'). Takie Informacje Poufne pozostaną wyłączną własnością Lumen i/lub jej klientów, a użytkownik w żadnym wypadku nie uzyska ani nie otrzyma żadnych praw, tytułów i/lub udziałów w takich Informacjach Poufnych.",
    "app.terms.line6": "Nie wolno Ci ani w czasie uczestnictwa w programie ViewPoints, ani w żadnym czasie (bez ograniczeń) po zakończeniu uczestnictwa: ujawniać ani przekazywać żadnej osobie; używać do własnych celów; ani poprzez brak należytej dbałości i staranności powodować nieuprawnionego ujawnienia jakichkolwiek Informacji poufnych. Musisz zawsze dołożyć wszelkich starań, aby zapobiec publikacji lub ujawnieniu jakichkolwiek Informacji poufnych. Ograniczenia te przestaną obowiązywać w odniesieniu do wszelkich informacji, które staną się ogólnie dostępne w inny sposób niż w wyniku Twojego zaniedbania.",
    "app.terms.line7" : "3. Własność Lumen",
    "app.terms.line8": "Oprogramowanie ViewPoints oraz wszystkie dane, kody, klucze, hasła, projekty, rysunki, zapisy i inne dokumenty lub materiały związane z Oprogramowaniem będą i pozostaną własnością Lumen przez cały okres Twojego uczestnictwa w ankiecie ViewPoints. Lumen posiada wszelkie prawa własności intelektualnej lub własności przemysłowej do wszelkich istniejących i przyszłych dokumentów, materiałów, modeli, projektów, rysunków, procesów, wynalazków, formuł, kodowania komputerowego, danych, interfejsów systemowych, metodologii, know-how, Informacji Poufnych lub innych prac, wykonanych, stworzonych, opracowanych, rozwiniętych, ulepszonych, zdekonstruowane lub odkryte przez ciebie samodzielnie lub z jakąkolwiek inną osobą w związku z Oprogramowaniem i/lub Urządzeniem lub mogące być użyte lub przystosowane do użycia w nich lub w związku z nimi (zarejestrowane lub niezarejestrowane), w tym wszystkie istniejące i przyszłe patenty, prawa autorskie, prawa do projektów, prawa do baz danych, znaki towarowe, prawa do topografii półprzewodników, prawa do odmian roślin, prawa do Internetu/nazwy domen, know-how oraz wszelkie zgłoszenia do powyższych oraz wszelkie prawa do ubiegania się o powyższe ('Prawa Własne'). Żadne Prawa Własności nie przechodzą na ciebie w dowolnym momencie.",
    "app.terms.line9" : "4. Warunki i ograniczenia dotyczące używania",
    "app.terms.line10": "Oprogramowanie ViewPoints jest licencjonowane dla użytkowników końcowych. Zgodnie z niniejszą Umową, niniejszym wyrażamy zgodę na udzielenie Ci pozwolenia, na niewyłącznej, odwołalnej, nieprzenoszalnej, nie podlegającej sublicencjonowaniu, ograniczonej podstawie, na korzystanie z Oprogramowania ViewPoints na urządzeniu, którego jesteś właścicielem lub które kontrolujesz, lub na komputerze osobistym, w odniesieniu do którego oświadczasz i gwarantujesz, że posiadasz wszystkie niezbędne prawa i pozwolenia do takiej instalacji. Dla uniknięcia wątpliwości, nie możesz używać w żadnym celu poza ViewPoints, tłumaczyć, kopiować, odtwarzać, przesyłać drogą telekomunikacyjną, adaptować, łączyć, zmieniać lub modyfikować Oprogramowania w jakikolwiek sposób.",
	"app.terms.line11": "Wyrażasz zgodę na to, że nie będziesz: (i) kopiować, modyfikować, adaptować, udostępniać, wynajmować, wydzierżawiać, oddawać w użytkowanie czasowe, tworzyć dzieł pochodnych ani próbować sprzedawać lub w inny sposób przekazywać jakichkolwiek praw do Oprogramowania (ii) rozkładać na czynniki pierwsze, dekompilować ani odtwarzać kodu źródłowego jakiejkolwiek części Oprogramowania w jakikolwiek sposób; (iii) używać Oprogramowania do celów innych niż te, do których zostało ono pierwotnie rozpowszechnione; (iv) korzystać z pająków, robotów lub innych zautomatyzowanych technik eksploracji danych w celu katalogowania, pobierania, przechowywania lub powielania lub rozpowszechniania w jakikolwiek inny sposób Oprogramowania lub manipulowania wynikami ankiet lub innych działań; (v) podejmować jakichkolwiek działań mających na celu zakłócenie działania Oprogramowania lub korzystania z niego przez inne osoby, w tym między innymi przeciążać, 'zalewać', 'bombardować pocztą'lub 'zawieszać”Oprogramowania i systemu przechowywania danych, z którego korzysta Oprogramowanie poprzez Amazon Web Services (vi) przesyłać do nas nielegalnego, zwodniczego lub szkodliwego kodu, w tym między innymi wirusów, programów szpiegujących, adware lub innych szkodliwych kodów.",
    "app.terms.line12": "Wszystkie prawa, które nie zostały ci wyraźnie przyznane w niniejszym dokumencie, są zastrzeżone dla nas i naszych licencjodawców. Zgadzasz się, że w ramach niniejszej Umowy ani w żaden inny sposób nie jesteśmy zobowiązani do poprawiania błędów, usterek lub pomyłek w Oprogramowaniu, ani do wspierania, utrzymywania, ulepszania, modyfikowania, uaktualniania lub poprawiania Oprogramowania w inny sposób. Nie będziemy odpowiedzialni za zapewnienie pomocy Tobie lub osobom przez Ciebie wyznaczonym w korzystaniu z Oprogramowania w jakikolwiek sposób.",
    "app.terms.line13": "Nie ponosimy odpowiedzialności za jakiekolwiek uszkodzenie twojego komputera osobistego, w tym między innymi w wyniku korzystania przez ciebie z Oprogramowania. Nie ponosimy odpowiedzialności za jakiekolwiek dodatkowe opłaty za przesyłanie danych poniesione przez Ciebie w wyniku danych zebranych przez Oprogramowanie w związku z Twoim udziałem w badaniach.",
    "app.terms.line14" : "5. Zrzeczenie się odpowiedzialności",
    "app.terms.line15": "My i nasi dyrektorzy, członkowie zarządu, pracownicy, agenci, przedstawiciele, licencjodawcy i dostawcy nie składają żadnych oświadczeń ani gwarancji czy warunków (a) że oprogramowanie będzie dostępne na czas lub że dostęp do oprogramowania będzie nieprzerwany lub bezpieczny; (b) że usterki lub błędy zostaną naprawione; lub (c) że oprogramowanie lub serwery lub sieci, za pośrednictwem których oprogramowanie i urządzenie są udostępniane, są bezpieczne lub wolne od wirusów lub innych szkodliwych składników. Nie jesteśmy zobowiązani do świadczenia jakichkolwiek usług serwisowych i/lub wsparcia technicznego w odniesieniu do oprogramowania.",
    "app.terms.line16" : "6. Ograniczenie odpowiedzialności",
	"app.terms.line17": "(a) W najszerszym zakresie dopuszczalnym przez obowiązujące prawo zgadzasz się, że my i nasi dyrektorzy, członkowie zarządu, pracownicy, agenci, przedstawiciele, licencjodawcy i dostawcy nie będą ponosić odpowiedzialności za jakiekolwiek straty, szkody lub koszty wynikające z instalacji, użytkowania lub dostępu do oprogramowania, korzystania lub niemożności korzystania z oprogramowania, jakichkolwiek informacji lub materiałów udostępnionych na lub za pośrednictwem oprogramowania i/lub w inny sposób związanych z oprogramowaniem lub w inny sposób z niniejszą umową, niezależnie od charakteru prawnego, słusznego lub ustawowego prawa, które rzekomo zostało naruszone.",
    "app.terms.line18": "(b) W najszerszym zakresie dopuszczalnym przez obowiązujące prawo, jedynym i wyłącznym środkiem zaradczym w przypadku niezadowolenia z oprogramowania jest zaprzestanie jego używania.",
    "app.terms. line19": "(c) W żadnym wypadku żaden z naszych podmiotów, dyrektorów, urzędników, pracowników, agentów, przedstawicieli nie będzie ponosił odpowiedzialności wobec ciebie za jakiekolwiek przypadkowe, pośrednie, wtórne, ustawowe, przykładowe, karne lub specjalne szkody, utratę przychodów, utratę możliwości, utratę reputacji, utratę możliwości korzystania, utratę danych, utratę innych wartości niematerialnych i prawnych, lub nieautoryzowanego przechwycenia danych zebranych przez oprogramowanie przez osoby trzecie, niezależnie od tego, czy można je było przewidzieć, czy nie, niezależnie od przyczyny i na podstawie jakiejkolwiek umowy, czynu niedozwolonego (w tym bez ograniczeń zaniedbania), ścisłej odpowiedzialności lub innej teorii, nawet jeśli z góry poinformowano o możliwości wystąpienia takich szkód.",
    "demo.index.content.line1": "Podczas tego demo użyjemy twojej kamery, by oszacować, gdzie się patrzysz. Nie martw się, nie będziemy robić żadnych zdjęć ani nagrywać filmów.",
	"app.error.previousfail": "Ostatnim razem nie przeszedłeś tego badania. Skorzystaj z oryginalnego linku, aby zacząć od początku.",
    "app.error.previoussuccess":"Poprzednio ukończyłeś to badanie.",
    "app.error.route": "Próbowałeś rozpocząć badanie, które już trwa. Użyj oryginalnego linku, aby rozpocząć od początku.",
    "app.error.accesscode": "Próbowałeś rozpocząć badanie, które nie istnieje. Sprawdź swoje łącze.",
    "app.error.webcam": "To badanie wymaga dostępu do kamery internetowej",
    "app.error.adblocker": "To badanie wymaga wyłączenia adblocka.",
    "app.error.startsession": "Nie można rozpocząć sesji",
    "app.error.fullscreen": "To badanie wymaga widoku pełnoekranowego.",
    "app.error.zoom": "To badanie wymaga, aby powiększenie przeglądarki było ustawione na 100%",
    "app.thankyou": "Dziękuję",
    "app.button.return": "Powrót",
    "app.index.content.line1gpdrwarning": "Podczas tego badania będziemy używać przedniej kamery Twojego urządzenia, aby robić zdjęcia i nagrywać filmy, podczas gdy będziesz wykonywać różne ćwiczenia polegające na patrzeniu na bodźce.",
    "app.index.zoomRetry": "Wymagamy, aby przeglądarka była ustawiona na 100% powiększenia, proszę powiększ/pomniejsz i spróbuj ponownie",
    "app.index.fullscreendesktopretry": "To badanie wymaga widoku pełnoekranowego, proszę się upewnić, że okno przeglądarki jest zmaksymalizowane",
    "app.index.fullscreendesktoptest": "Do przeprowadzenia tego badania wymagane jest, aby okno przeglądarki było w trybie pełnoekranowym. Musimy wykonać test, aby upewnić się, że okno przeglądarki jest zmaksymalizowane",
    "app.index.fullscreendesktoptest2": "Wkrótce pojawią się dwa przyciski, prosimy je kliknąć, aby kontynuować",
    "app.index.fullscreendesktopmaximised": "Mój ekran jest już zmaksymalizowany",
    "app.index.fullscreendesktopskip": "Dziękujemy, odnotowaliśmy ten przypadek i udoskonalimy nasz proces wykrywania.",
    "app.index.fullscreendesktopreset": "Wykryliśmy zmianę rozmiaru okna, resetujemy test.",
    "app.index.gotomobile": "Użytkownicy komputerów stacjonarnych nie kwalifikują się do udziału w badaniu. Aby otworzyć badanie na urządzeniu mobilnym, należy użyć poniższego kodu QR.",
    "app.index.returntopanel": "LUB Kliknąć poniższy przycisk, aby powrócić do panelu",
    "app.setup.instruction3audio": "Proszę uważnie słuchać i postępować zgodnie z instrukcjami wyświetlanymi na ekranie.",
    "app.setup.retryPositioning": "Nie udało nam się ustalić pozycji głowy. Proszę trzymać głowę i urządzenie nieruchomo, nie zasłaniać kamery np. palcami. Aby spróbować ponownie, proszę użyć poniższego przycisku.",
    "app.setup.retryBroker": "Nie udało się dokończyć procesu kalibracji. Proszę spróbować zamknąć wszystkie nieużywane aplikacje i karty, aby zwolnić zasoby",
    "app.error.cellquotafullnoactivecell": "Nie znaleziono żadnej aktywnej komórki w tym badaniu. Limity komórek są pełne.",
    "app.completion.congratulations-noet": "Gratulacje! Badanie zostało ukończone. ",
    "app.faq.gdpr.intro1": "W tym badaniu wykorzystamy przednią kamerę urządzenia mobilnego do robienia zdjęć i nagrywania filmów użytkownika, który będzie proszony, aby wykonać różne ćwiczenia polegające na patrzeniu na bodźce w różnych warunkach środowiskowych (np. oświetlenia i pozycji). Udział w badaniu powinien zająć około 5 minut. Dane te mają być wykorzystane do dalszego rozwoju oprogramowania, które będzie w stanie oszacować, w którym miejscu ekranu patrzy użytkownik.",
    "app.faq.gdpr.intro2": "Badania te są prowadzone zgodnie z Kodeksem postępowania MRS i Ustawą o ochronie danych z 2018 r. Twoja prywatność jest dla nas ważna. Zanim weźmiesz udział w badaniu, prosimy potwierdzić, że zgadzasz się, iż biorąc udział w badaniu, rozumiesz, jakie dane gromadzimy i w jaki sposób będą one wykorzystywane zgodnie z opisem w niniejszej Polityce prywatności, a także wyrażasz zgodę na wykorzystywanie tych danych w ten sposób. Niniejsza polityka opisuje szczegółowo, jakie informacje są gromadzone oraz w jaki sposób są wykorzystywane i udostępniane.",
    "app.faq.gdpr.question1": "Jakie dane będą gromadzone?",
    "app.faq.gdpr.answer1.line1": "W ramach badania zbierane będą następujące rodzaje danych:",
    "app.faq.gdpr.answer1.line2": "„Charakterystyka siedzenia” – anonimowy identyfikator służący do identyfikacji konkretnego zbioru danych „Siedzenie” oraz danych dotyczących panujących warunków środowiskowych testu w trakcie sesji.",
    "app.faq.gdpr.answer1.line3": "„Charakterystyka uczestnika” – dane dotyczące respondenta biorącego udział w danym posiedzeniu, w tym informacje o cechach demograficznych (np. wiek, płeć), etnicznych (np. typ skóry) i biologicznych (np. kolor oczu) uczestnika.",
    "app.faq.gdpr.answer1.line4": "„Plik wideo” – obrazy i/lub nagrania wideo wykonane za pomocą przedniej kamery urządzenia w celu uchwycenia obrazów uczestników, gdy patrzą na bodźce wyświetlane na ekranie lub ekranach i potencjalnie wchodzą z nimi w interakcję. Podczas wykonywania zadania wykonywane będą zdjęcia oczu i twarzy uczestnika oraz wszelkich innych obiektów, które mogą zostać przypadkowo uchwycone przez przednią kamerę. ",
    "app.faq.gdpr.answer1.line5": "„Metadane” – dane dotyczące czasu, w którym poszczególne osoby weszły w interakcję z bodźcami, powiązane ze znacznikiem czasu każdego zebranego obrazu.",
    "app.faq.gdpr.answer1.line6": "„Dane urządzenia fizycznego” – dane fizyczne dotyczące urządzenia, w tym nazwa urządzenia, rozmiar ekranu, obrót ekranu oraz obrót czujnika aparatu.",
    "app.faq.gdpr.answer1.line7": "Biorąc udział w badaniu, rozumiesz i zgadzasz się, że Treść, którą zobaczysz w badaniu, może być poufna, stanowić własność osoby trzeciej i/lub podlegać prawom znaku towarowego lub prawu autorskiemu. Nie będziesz wykonywać zrzutów ekranu, nagrywać ani w żaden inny sposób kopiować żadnej Treści, ani też rozpowszechniać żadnej Treści badania w żaden sposób.",
    "app.faq.gdpr.question2": "W jaki sposób dane będą wykorzystywane/przetwarzane?",
    "app.faq.gdpr.answer2": "Dane te zostaną wykorzystane do opracowania oprogramowania, które będzie w stanie oszacować, czy użytkownicy znajdują się przed urządzeniem, czy patrzą na bodźce, a także w którym miejscu bodźca patrzą. Mogą być również wykorzystane w celach demonstracyjnych lub w celu wyjaśnienia osobom trzecim (klientom, dostawcom, partnerom) sposobu opracowania naszej technologii i jej wydajności bądź ułatwienia im dokonania oceny. Dane mogą być przeglądane przez dowolną liczbę „adnotatorów” danych – pracowników, podwykonawców, partnerów lub inne strony trzecie, które zostaną poproszone o ponowne obejrzenie materiału wideo/obrazów w celu oceny „stanu” uwagi uczestników podczas badania (np. czy znajdują się w polu widzenia kamery? Czy patrzą na ekran? Podstawą prawną przetwarzania danych będzie świadoma zgoda i uzasadnione interesy. ",
    "app.faq.gdpr.question3": "Jak długo dane będą przechowywane?",
    "app.faq.gdpr.answer3": "Dane będą przechowywane do wykorzystania tak długo, jak będzie to zasadnie konieczne do realizacji celów opisanych powyżej. Oprogramowanie, które jest opracowywane, jest okresowo aktualizowane i oceniane, a uzyskane dane będą wykorzystywane do oceny każdej iteracji. Dlatego też oczekuje się, że dane te będą przechowywane w tym celu przez kilka lat, jeśli nie bezterminowo. Dane zostaną usunięte, jeśli zostanie uznane, że nie są już przydatne do celów opracowywania lub oceny oprogramowania do testowania bodźców. ",
    "app.faq.gdpr.question4": "Kto będzie odbierał i przetwarzał dane?",
    "app.faq.gdpr.answer4": "Dane będą przechowywane przez Lumen Research Ltd. Lumen Research Ltd jest spółką zarejestrowaną w Anglii pod numerem 8682432. Siedziba spółki mieści się w LABS house, 15-19 Bloomsbury Way, Holborn, London WC1A 2TH. Numer rejestracyjny ochrony danych to ZA130772. Inspektorem ochrony danych jest Matt Robinson (matt.robinson@lumen-research.com). Masz prawo w dowolnym momencie złożyć skargę dotyczącą przetwarzania Twoich danych, kontaktując się z brytyjskim organem ochrony danych, Biuro Komisarza ds. Informacji. Dane kontaktowe ICO są dostępne na stronie internetowej pod adresem https://ico.org.uk/global/contact-us/. Biorąc udział w badaniu, udzielasz firmie Lumen ogólnoświatowej, wolnej od opłat licencyjnych, podlegającej sublicencjonowaniu i przenoszalnej licencji na przechowywanie, wykorzystywanie, wyświetlanie, powielanie, modyfikowanie, adaptowanie, edytowanie, publikowanie i dystrybucję tych danych.",
    "app.faq.gdpr.question5": "Czy mam prawo dostępu do moich danych osobowych?",
    "app.faq.gdpr.answer5": "Tak, masz prawo dostępu do swoich danych osobowych. Proszę jednak pamiętać, że Twoje nazwisko nie zostanie zapisane razem z danymi zebranymi w trakcie tego badania. Aby zidentyfikować rekordy danych, prosimy podać dokładną datę i godzinę przeprowadzenia badania. Poprosimy także o identyfikację wizualną, aby mieć pewność, że rekordy danych zostaną prawidłowo dopasowane.",
    "app.faq.gdpr.question7": "Czy mam prawo cofnąć zgodę na przetwarzanie moich danych osobowych lub ich usunięcie?",
    "app.faq.gdpr.answer7": "Tak, masz prawo do cofnięcia zgody na przetwarzanie Twoich danych osobowych, a także do ich usunięcia. Należy jednak pamiętać, że Twoje imię i nazwisko nie zostanie zapisane razem z danymi zebranymi w trakcie tego badania. Aby zidentyfikować rekord danych, prosimy podać dokładną datę i godzinę przeprowadzenia badania. Poprosimy także o identyfikację wizualną, aby mieć pewność, że rekordy danych zostaną prawidłowo dopasowane. ",
    "app.faq.gdpr.question8": "Skąd mogę mieć pewność, że moje dane osobowe są bezpieczne?",
    "app.faq.gdpr.answer8": "Dane zbierane przez Lumen Research są szyfrowane przy użyciu technologii Secure Sockets Layer (SSL). Jest to powszechnie stosowany, wysoki standard technologii szyfrowania. Technologia ta jest wykorzystywana zarówno do zabezpieczania kanałów przesyłowych, jak i końcowego składowania.",
    "app.faq.gdpr.question9": "W jaki sposób będziemy wykorzystywać i ujawniać gromadzone przez nas informacje?",
    "app.faq.gdpr.answer9": "Możemy udostępniać zebrane przez nas informacje innym zewnętrznym dostawcom usług w celu umożliwienia im świadczenia usług na naszą rzecz. Możemy również ujawnić informacje osobom trzecim w przypadku reorganizacji, fuzji, sprzedaży, utworzenia spółki joint venture, cesji, przeniesienia lub innej dyspozycji całością lub częścią naszego przedsiębiorstwa, aktywów lub akcji (w tym w związku z upadłością lub podobnym postępowaniem). Możemy również wykorzystywać lub ujawniać informacje, które zbieramy od Ciebie i o Tobie, jeśli uznamy to za konieczne lub właściwe: (a) zgodnie z obowiązującym prawem, w tym prawem obowiązującym poza krajem Twojego zamieszkania; (b) w celu przestrzegania procedury prawnej; (c) w celu odpowiadania na żądania organów publicznych i rządowych, w tym organów publicznych i rządowych spoza kraju Twojego zamieszkania; (d) w celu egzekwowania naszej umowy licencyjnej użytkownika końcowego; (e) w celu ochrony naszych działań lub działań któregokolwiek z naszych podmiotów stowarzyszonych; (f) w celu ochrony naszych praw, prywatności, bezpieczeństwa lub własności i/lub praw, prywatności, bezpieczeństwa lub własności naszych podmiotów stowarzyszonych, Ciebie lub innych osób; (g) aby umożliwić nam skorzystanie z dostępnych środków zaradczych lub ograniczenie szkód, jakie możemy ponieść; oraz (h) aby wykryć i rozwiązać wszelkie problemy związane z oszustwami lub bezpieczeństwem. ",
    "app.faq.gdpr.question10": "Jakie kroki są podejmowane w celu zapewnienia bezpieczeństwa danych?",
    "app.faq.gdpr.answer10": "Podejmujemy rozsądne kroki w celu ochrony Twoich danych, stosując fizyczne, elektroniczne lub proceduralne środki bezpieczeństwa odpowiednie do wrażliwości danych znajdujących się w naszym posiadaniu lub pod naszą kontrolą, w tym zabezpieczenia chroniące przed utratą lub kradzieżą, a także nieautoryzowanym dostępem, ujawnieniem, kopiowaniem, wykorzystaniem lub modyfikacją. ",
    "app.terms.line5": "Podczas instalowania, używania lub w jakikolwiek sposób angażowania się w ViewPoints, możesz mieć dostęp do informacji i/lub treści należących do lub odnoszących się do Lumen, w tym informacji, które są identyfikowane lub traktowane przez Lumen lub któregokolwiek z klientów lub odbiorców Lumen jako poufne lub które ze względu na swój charakter lub okoliczności lub sposób ich ujawnienia są ewidentnie poufne, w tym (bez uszczerbku dla powyższej ogólności) wszelkie informacje o planach biznesowych, dojrzewających nowych możliwościach biznesowych, projektach badawczo-rozwojowych, projektach, tajnych procesach, kodach, projektach oprogramowania, tajemnicach handlowych, rozwoju i formułach produktów lub usług, know-how, wynalazkach, statystykach i prognozach sprzedaży, strategiach i planach marketingowych, kosztach, zyskach i stratach oraz innych informacjach finansowych (z wyjątkiem danych opublikowanych w sprawozdaniach finansowych poddanych audytowi), cenach i strukturach rabatowych (wszystkie zarejestrowane lub nie w formie pisemnej, elektronicznej lub innej) („Informacje poufne”). Te Poufne Informacje pozostaną wyłączną własnością Lumen i/lub jej klientów. W żadnym wypadku nie uzyskasz ani nie otrzymasz żadnych praw, tytułów i/lub interesów w tych Poufnych Informacjach.",
    "app.terms.line19": "(c) W żadnym wypadku żadna z naszych jednostek, dyrektorów, kadry kierowniczej, pracowników, agentów ani przedstawicieli nie ponosi odpowiedzialności wobec Ciebie za jakiekolwiek przypadkowe, pośrednie, wynikowe, ustawowe, przykładowe, karne lub szczególne szkody, utratę dochodów, utratę możliwości, utratę reputacji, utratę użytkowania, utratę danych, utratę innych wartości niematerialnych lub nieautoryzowane przechwycenie danych zebranych przez oprogramowanie przez osoby trzecie, przewidywalne lub nie, bez względu na przyczynę i na podstawie jakiejkolwiek umowy, czynu niedozwolonego (w tym, bez ograniczeń, zaniedbania), odpowiedzialności ścisłej lub innej teorii, nawet jeśli poinformowano Cię z wyprzedzeniem o możliwości wystąpienia takich szkód.",
    "app.index.webcamInstructions": "Zamierzamy poprosić o dostęp do Twojej kamery internetowej. Jeśli pojawi się powiadomienie pop-up, kliknij Zezwól.",
    "app.setup.retryBack": "Prosimy o powstrzymanie się od naciskania przycisku Wstecz w przeglądarce podczas trwania badania, gdyż może to skutkować utratą nagrody. Aby spróbować ponownie, proszę użyć poniższego przycisku.",
    "app.setup.retryaudible": "Proszę upewnić się, że urządzenie nie jest wyciszone i spróbuj ponownie.",
    "app.restore.retry": "Nie poświęciłeś wystarczająco dużo czasu na poprzedni krok, ",
    "app.restore.timeremaining": " pozostało minut",
    "app.error.postexposure": "Przepraszamy, wykorzystałeś już wszystkie próby i niestety nie możesz kontynuować badania."
}
