import React, { useEffect, useRef } from "react";
import { LSIntlFormat } from "../../translations/controller";
import { store } from "../../store/index";
import tabChangeListener from "../../utils/listeners/TabChangeListener";
import LoggerController from "../../controllers/Logger/LoggerController";
import { routeType } from "../util/util";

const Loading = ({ useTimer, text }) => {
  let loadingCounter = useRef(0);
  let loadingTimeout = useRef();

  useEffect(() => {
    if (!loadingTimeout.current && useTimer) {
      tabChangeListener.setIsLoading(true);
      loadingTimeout.current = setTimeout(handleLongLoad, 10000);
    }

    return () => {
      tabChangeListener.setIsLoading(false);
      if (loadingTimeout.current) {
        clearTimeout(loadingTimeout.current);
      }
    };
  }, []);

  const handleLongLoad = () => {
    if (loadingCounter && typeof loadingCounter.hasOwnProperty("current")) {
      if (loadingCounter.current === 0) {
        loadingCounter.current = 10000;
        loadingTimeout.current = setTimeout(handleLongLoad, 30000);
      } else if (loadingCounter.current === 10000) {
        loadingCounter.current = 40000;
        loadingTimeout.current = setTimeout(handleLongLoad, 30000);
      } else {
        loadingCounter.current = 70000;
      }
      LoggerController.panelistAction("LS:LongLoad", {
        timeTaken: loadingCounter.current,
      });
      LoggerController.events.logLongLoad.log(`Waiting for loading for ${loadingCounter.current}ms`)
      LoggerController.sendToExtendedLogger(routeType(), "action", undefined, undefined, "longload", `Time taken: ${loadingCounter.current}`)
    }
  };

  return (
    <div className="ls-loading">
      <div className="lds-css">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      {store.getState()._persist.rehydrated ? (
        <h1 style={{ textAlign: "center" }}>
          {text
            ? text
            : LSIntlFormat.IntlMessageFormat(
                "app.prestart.loading",
                "Loading, please wait."
              )}
        </h1>
      ) : null}
    </div>
  );
};

export default Loading;
