import ErrorController from "../../controllers/Error/ErrorController";
import Trashman from "./Trashman";

export default class RevokableAsync {
  constructor(funcIdentifier, platformError, func) {
    this.state = "notstarted";
    this.rejected = false;
    this.errored = false;
    this.result = null;
    this.rejector = null;
    this.resolver = null;
    this.promise = new Promise(async (resolve, reject) => {
      this.resolver = (args) => {
        this.state = "resolved";
        this.result = args;
        resolve(args);
      };

      this.rejector = (args) => {
        this.state = "rejected";
        this.rejected = true;
        this.result = args;
        resolve(args);
      };

      let cleanUp = Trashman.addTask(this.rejector);

      this.state = "pending";

      try {
        let result = await func();
        cleanUp();
        this.resolver(result);
      } catch (e) {
        this.errored = true;
        this.state = "errored";
        if (platformError.raise) {
          platformError.raise({
            error_name: e.name,
            error_message: e.message,
            identifier: funcIdentifier,
          });
        } else {
          ErrorController.unhandledError(
            `UnhandledError: ${funcIdentifier}: ${e.name} - ${e.message}`
          );
        }
        this.result = { success: false };
        resolve({ success: false });
      }
    });
  }
}
