{
  "title": "﻿Lumen Çalışma Platformu - Gizlilik Politikası",
  "date": "Temmuz 2022",
  "contactDetails": { "body": [] },
  "sections": [
    { "body": [], "heading": "İletişim bilgilerimiz" },
    { "body": [], "heading": "​ " },
    { "body": [], "heading": "İsim: Lumen Research Ltd (\"Lumen\")" },
    {
      "body": [],
      "heading": "Adres: Screenworks, Unit 215, 22 Highbury Grove, Londra N5 2ER"
    },
    {
      "body": ["E-posta: dataprotection@lumen-research.com"],
      "heading": "Telefon Numarası: +44(0)2037355199"
    },
    { "body": [], "heading": "ICO Kayıt Referansı: ZA130772" },
    {
      "body": [
        "Bu Gizlilik Politikası, Lumen Çalışma Platformunda bir göz takip anketine katılan katılımcılardan topladığımız bilgilerin niteliğini ve toplanabilecek kişisel bilgileri düzenleyen politikamızı açıklamaktadır."
      ],
      "heading": "Giriş"
    },
    { "body": [], "heading": "​ " },
    {
      "body": [
        "Tipik bir ankette Lumen, Birleşik Krallık Genel Veri Koruma Yönetmeliği kapsamında tanımlanan kişisel bilgileri toplamaz, ancak bakış noktası tahminlerini, anket yanıtlarını, veri toplamanın ne zaman ve ne tür bir cihazda gerçekleştiğine ilişkin meta verileri ve ödülleri yönetmek amacıyla katılımcı kimliklerini toplar.",
        "Tipik bir ankette, cihazınızın web kamerasının veya ön kamerasının önüne oturmanız ve ekranınızda nereye baktığınıza dair bir tahmin yapılabilmesi için bir kalibrasyon gerçekleştirmeniz istenecektir. Anket sırasında anket aracı, nereye baktığınızı tahmin ederken bazı ögeleri (ör. resimler, web siteleri veya videolar) görüntülemeniz istenecektir. Bu 'göz takibinden' elde edilen veriler, zaman içinde oluşan ekranınızdaki tahmini bakış noktalarının koordinatlarıdır. Yazılım, bu tahminleri yapmak için web kameranızdan alınan görüntülerinizi kullanır, ancak fotoğraflarınızı veya videolarınızı çekmez. ",
        "Ayrıca kendiniz, deneyiminiz ve anket sırasında gördüğünüz ögeler hakkında bazı sorular sorulabilir. ",
        "Bu anketlerden elde edilen veriler, Lumen Research Ltd'ye (\"Lumen\") gönderilmektedir. Lumen, verileri analiz edecek ve sonuçları müşterileriyle paylaşacaktır. ",
        "Bu anketlerde alfanümerik bir katılımcı kimliği de üçüncü bir tarafça Lumen'e iletilecektir, böylece Lumen hangi katılımcıların anketi tamamladığını takip edebilecektir. Lumen, anketi tamamlayanlara ödüller vermek amacıyla bu bilgileri üçüncü taraflarla paylaşacaktır. "
      ],
      "heading": "Topladığımız bilgi türleri"
    },
    {
      "body": [
        "Bazen, katılımcılardan birden fazla veri toplama dönemi gerektiren bir araştırma projesinde yer almaları istenebilir. Bu durumda katılımcı kimliği, daha sonraki herhangi bir veri toplama çalışmasına katılımlarını başlatmak amacıyla benzersiz bir katılımcıyla yeniden iletişime geçmek için kalıcı bir tanımlayıcı olarak kullanılacaktır. Bu senaryoda katılımcı kimliği, kişisel bilgi olarak kabul edilecektir.  "
      ],
      "heading": "Topladığımız kişisel bilgilerin türü"
    },
    {
      "body": [
        "Tipik bir ankette olduğu gibi, kalıcı bir katılımcı kimliği, araştırmamız için bir katılımcıyı işe alan üçüncü tarafça bize iletilecektir. Araştırmaya daha sonraki katılımlarını başlatmak üzere bireylerle yeniden iletişime geçmek için bu kimliği kullanacağız ve bu sonraki katılımı kolaylaştırmak için bu kimliği üçüncü taraflarla paylaşabiliriz.",
        "Birleşik Krallık Genel Veri Koruma Yönetmeliği (UK GDPR) kapsamında bu bilgileri işlemek için dayandığımız yasal dayanaklar sizin rızanızdır. Rızanızı istediğiniz zaman geri çekebilirsiniz. Bunu bizimle şu adresten iletişime geçerek yapabilirsiniz dataprotection@lumen-research.com"
      ],
      "heading": "Kişisel bilgileri nasıl elde ediyoruz ve neden elimizde bulunduruyoruz?"
    },
    {
      "body": [
        "Bilgileriniz, SSL teknolojisi kullanılarak güvenli bir şekilde saklanır ve şifrelenir. Bu, en yaygın kullanılan yüksek standartlı şifreleme teknolojisidir ve bankalar tarafından bankacılık işlemlerini ve diğer son derece hassas iletişimleri güvence altına almak için kullanılır. Bu teknoloji ayrıca Amazon, PayPal, eBay ve banka düzeyinde güvenlik sistemleri gerektiren diğer web siteleri tarafından da kullanılmaktadır. Bilim insanları, 256 bit şifrelemenin mevcut teknoloji kullanılarak kırılmasının evrenin yaşından çok daha fazla zaman gerektireceğini tahmin etmektedir. Toplanan veriler, Amazon Web Services (AWS) tarafından yönetilen güvenli web sunucularında saklanır. AWS, en yaygın kullanılan güvenli ve güvenilir bulut bilişim hizmetleri platformlarından biridir ve Netflix ve Expedia gibi büyük bulut tabanlı ticari hizmetler tarafından kullanılmaktadır. ",
        "Kalıcı tanımlayıcıları, herhangi birçok aşamalı araştırma için katılımcılarla yeniden iletişime geçmek için gereken süre boyunca saklarız. Bu, araştırmanın niteliğine bağlı olarak değişebilir ve katılım izninizi verdiğinizde size bildirilecektir. Böyle bir proje için veri toplama işlemi tamamlandığında tanımlayıcılar, yalnızca verilerin analiz edilmesi ve raporlanması amacıyla gereken şekilde saklanacaktır. Bir projenin analizi ve raporlanması tamamlandığında, bu kişisel bilgileri veri tabanlarımızdan silerek imha edeceğiz."
      ],
      "heading": "Kişisel bilgilerinizi nasıl saklıyoruz? "
    }
  ],
  "dataProtection": {
    "body": ["Veri koruma yasası kapsamında, şu haklara sahipsiniz:"],
    "bullets": [
      {
        "title": "Erişim hakkınız",
        "text": [
          "Bizden kişisel bilgilerinizin kopyalarını isteme hakkına sahipsiniz. "
        ]
      },
      {
        "title": "Düzeltme hakkınız",
        "text": [
          "Yanlış olduğunu düşündüğünüz kişisel bilgileri düzeltmemizi isteme hakkına sahipsiniz. Ayrıca, eksik olduğunu düşündüğünüz bilgileri tamamlamamızı isteme hakkına da sahipsiniz. "
        ]
      },
      {
        "title": "Silme hakkınız",
        "text": [
          "Belirli durumlarda kişisel bilgilerinizi silmemizi isteme hakkına sahipsiniz. "
        ]
      },
      {
        "title": "İşlemeyi kısıtlama hakkınız",
        "text": [
          "Belirli durumlarda bizden kişisel bilgilerinizin işlenmesini kısıtlamamızı isteme hakkına sahipsiniz. "
        ]
      },
      {
        "title": "Rızanızı geri çekme hakkınız",
        "text": [
          "Kişisel bilgilerinizin işlenmesine ilişkin rızanızı geri çekme hakkına sahipsiniz."
        ]
      },
      {
        "title": "Veri taşınabilirliği hakkınız",
        "text": [
          "Bize verdiğiniz kişisel bilgileri belirli durumlarda başka bir kuruluşa veya kendinize aktarmamızı isteme hakkına sahipsiniz.",
          "Haklarınızı kullanmak için herhangi bir ücret ödemenize gerek yoktur. Bir talepte bulunduğunuz takdirde size yanıt vermek için bir ay süremiz vardır.",
          "Talepte bulunmak isterseniz lütfen dataprotection@lumen-research.com adresinden bizimle iletişime geçin."
        ]
      }
    ],
    "title": "Veri koruma haklarınız"
  },
  "complaints": {
    "body": [
      "Kişisel bilgilerinizi kullanmamızla ilgili herhangi bir endişeniz varsa, dataprotection@lumen-research.com adresinden bize şikâyette bulunabilirsiniz..",
      "Verilerinizi kullanma şeklimizden memnun değilseniz ICO'ya da şikâyette bulunabilirsiniz."
    ],
    "address": {
      "body": [
        "Bilgi Komiserliği Ofisi",
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ],
      "title": "ICO'nun adresi:            "
    },
    "title": "Nasıl şikâyette bulunulur",
    "number": "Yardım hattı numarası: 0303 123 1113",
    "website": "ICO web sitesi: https://www.ico.org.uk"
  }
}
