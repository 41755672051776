import * as actions from "./action-types";

export const initStepData = (stepData) => {
  return {type: actions.INIT_STEPDATA, payload: stepData}
}

export const showNextStep = () => {
  return { type: actions.STEP_SHOW_NEXT };
};

export const startStep = (step) => {
  return {
    type: actions.START_STEP,
    payload: step,
  };
};

export const completeStep = (step, routeOut) => {
  return {
    type: actions.COMPLETE_STEP,
    payload: { step, routeOut },
  };
};

export const incrementStep = (step) => {
  return {
    type: actions.INCREMENT_STEP,
    payload: step,
  };
};
