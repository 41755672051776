import sendDataToCrawler from "../../../../components/util/sendDataToCrawler";
import { store } from "../../../../store";
import DeviceController from "../../../../utils/controllers/DeviceController";
import Endpoint from "../../Endpoint";

const getPayload = () => {
  let {
    participant,
    accessCode,
    test,
    routedRegion,
    fastestRegion,
    panel,
  } = store.getState().studyReducer;

  let deviceInfo = DeviceController.getDeviceInfo();
  let deviceDimensions = DeviceController.getDimensions();

  let startPanelPayload = {
    access_code: accessCode,
    panel: panel,
    participant: participant,
    test: test,
    device: deviceInfo.device,
    os: deviceInfo.osString.toLowerCase(),
    os_version: deviceInfo.os.version,
    browser: deviceInfo.name.toLowerCase(),
    browser_version: deviceInfo.version,
    is_mobile: deviceInfo.isMobile,
    is_touch_capable: deviceInfo.isMobile,
    device_model: deviceInfo.device,
    routed_region: routedRegion,
    fastest_region: fastestRegion,
    graphics: deviceInfo.graphics,
    screen_resolution_width: deviceDimensions.screen_resolution_width,
    screen_resolution_height: deviceDimensions.screen_resolution_height,
    browser_width: deviceDimensions.browser_width,
    browser_height: deviceDimensions.browser_height,
    browser_left: deviceDimensions.browser_left,
    browser_top: deviceDimensions.browser_top,
    viewport_width: deviceDimensions.viewport_width,
    viewport_height: deviceDimensions.viewport_height,
    browser_zoom: deviceDimensions.browser_zoom,
  };

  return startPanelPayload;
};

const handleResponse = async (response) => {
  if (response.success) {
    let { panelExtra1, testType } = store.getState().studyReducer;
    let urlParams = new URLSearchParams(window.location.search);
    let isMobile = DeviceController.getIsMobile()

    // setup panel redirects
    let successRedirectUrl = response.success_redirect_url;
    if (!!panelExtra1) {
      successRedirectUrl = successRedirectUrl.replace(
        "redirect_url_extra1_insert_parameter",
        panelExtra1
      );
    }
    let screenoutRedirectUrl = response.screenout_redirect_url;
    if (!!panelExtra1) {
      screenoutRedirectUrl = screenoutRedirectUrl.replace(
        "redirect_url_extra1_insert_parameter",
        panelExtra1
      );
    }
    let quotaFullRedirectUrl = response.quota_full_redirect_url;
    if (!!panelExtra1) {
      quotaFullRedirectUrl = quotaFullRedirectUrl.replace(
        "redirect_url_extra1_insert_parameter",
        panelExtra1
      );
    }

    let passThroughVariables = {};
    response.pass_through_variables.forEach((v) => {
      passThroughVariables[v] = urlParams.get(v);
    });

    var panelPayload = {
      panelCompanyCode: response.panel_company_code,
      successRedirectUrl: successRedirectUrl,
      screenoutRedirectUrl: screenoutRedirectUrl,
      quotaFullRedirectUrl: quotaFullRedirectUrl,
      redirectUrlMemberInsertParameter:
        response.redirect_url_member_insert_parameter,
      passThroughVariables: passThroughVariables,
      studyId: response.study_id,
      studyName: response.study_name,
      secondsBeforeMoveNext: response.seconds_before_move_next,
      eyetrackingEnabled: response.eyetracking_enabled,
      adblockcheckEnabled: response.adblockcheck_enabled,
      cameraCheckEnabled: response.cameracheck_enabled,
      screenAdblock: response.screen_adblock_users,
      screenNoCamera: response.screen_nocamera_users,
      showSplashPage: response.show_splash_page,
      showInstructionPage: response.show_instructions_page,
      screenLowConnectionSpeed: response.screen_low_connection_speed,
      lowConnectionSpeed: response.low_connection_speed,
      screenLowCalibrationFrameRate: response.screen_low_calibration_frame_rate,
      lowCalibrationFrameRate: response.low_calibration_frame_rate,
      screenLowViewportWidth: response.screen_low_viewport_width,
      lowViewportWidth: response.low_viewport_width,
      screenLowViewportHeight: response.screen_low_viewport_height,
      lowViewportHeight: response.low_viewport_height,
      require_fullscreen: response.require_fullscreen,
      screenLowScreenWidth: response.screen_low_screen_resolution_width,
      lowScreenWidth: response.low_screen_resolution_width,
      screenLowScreenHeight: response.screen_low_screen_resolution_height,
      lowScreenHeight: response.low_screen_resolution_height,
      newTrackingSystem: response.new_tracking_system,
      showEyetrackingInstructionsOverride:
        response.show_eyetracking_instructions_override,
    };

    if (testType === "4" && response.eyetracking_enabled) {
      store.dispatch({ type: "SET_DUMMY_EYETRACKING", payload: true });
    }

    if (testType === "3" || testType === "4") {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      sendDataToCrawler("StudySetup", {
        eyetrackingEnabled: response.eyetracking_enabled,
        showSplashPage: response.show_splash_page,
        showInstructionPage: response.show_instructions_page,
        checks: [
          {
            name: "Connection Speed Check",
            enabled: response.low_connection_speed > 0,
            page: "start",
          },
          {
            name: "Webcam Check",
            enabled: response.cameracheck_enabled,
            page: "start",
          },
          {
            name: "Adblock Check",
            enabled: response.adblockcheck_enabled,
            page: "start",
          },
          {
            name: "Desktop Fullscreen Check",
            enabled: response.require_fullscreen && !isMobile,
            page: "start",
          },
          {
            name: "Mobile Fullscreen Check",
            enabled: response.require_fullscreen && isMobile,
            page: "start",
          },
          {
            name: "Mobile Zoom Check",
            enabled: response.require_fullscreen && isMobile,
            page: "start",
          },
          {
            name: "Viewport Width Check",
            enabled: response.low_viewport_width > 0,
            page: "start",
          },
          {
            name: "Viewport Height Check",
            enabled: response.low_viewport_height > 0,
            page: "start",
          },
          {
            name: "Screen Width Check",
            enabled: response.low_screen_resolution_width > 0,
            page: "prestart",
          },
          {
            name: "Screen Height Check",
            enabled: response.low_screen_resolution_height > 0,
            page: "start",
          },
        ],
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    store.dispatch({ type: "START_PANEL", payload: panelPayload });
  }

  return response;
};

export default (study_id) => {
  return new Endpoint(
    "start-panel",
    `${process.env.REACT_APP_PARTICIPATION_URL}/participation/studies/${study_id}/start-panel`,
    "POST",
    getPayload,
    handleResponse,
    true
  );
};
