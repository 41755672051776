{
  "title": "﻿Lumenova platforma za studije - Politika privatnosti",
  "date": "U julu 2022. godine",
  "contactDetails": {
    "title": "Naši kontakt podaci ",
    "body": [
      "Naziv: Lumen Research Ltd („Lumen“)",
      "Adresa: Screenworks, Unit 215, 22 Highbury Grove, London N5 2ER",
      "Broj Telefona: +44(0)2037355199 ",
      "Elektronska pošta: dataprotection@lumen-research.com",
      "Ref. br. upisa u registar Poverenika za informacije UK: ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "Ova Politika privatnosti objašnjava prirodu informacija koje prikupljamo od učesnika ankete sa praćenjem očnih pokreta na Lumenovoj platformi za studije, kao i našu politiku koja reguliše sve lične podatke koji se mogu prikupiti. "
      ],
      "heading": "Uvod"
    },
    {
      "body": [
        "Prilikom tipične ankete, Lumen ne prikuplja lične podatke, u skladu sa definicijom prema Opštoj uredbi o zaštiti podataka UK, već će prikupljati procene tačaka pogleda; anketne odgovore; metapodatke o tome kada i na kom tipu uređaja je došlo do prikupljanja podataka; i identifikatore ispitanika radi dodele nagrada.",
        "U tipičnoj anketi od vas će se tražiti da sednete ispred veb kamere ili prednje kamere vašeg uređaja i izvršite kalibraciju tako da se može proceniti gde na ekranu gledate. Tokom ankete, od vas će se tražiti da pogledate neke stavke (npr. slike, internet lokacije ili video zapise) dok anketna alatka procenjuje gde gledate. Podaci koji se dobijaju ovim „praćenjem očnih pokreta“ su koordinate procenjenih tačaka pogleda na vašem ekranu tokom vremena. Softver koristi vaše slike sa vaše veb kamere za te procene, ali vas ne fotografiše niti snima. ",
        "Mogu vam biti postavljana i neka pitanja o vama, vašem iskustvu i elementima koje ste videli tokom ankete. ",
        "Podaci iz ovih istraživanja šalju se Lumen Research Ltd („Lumen“). Lumen će analizirati podatke i podeliti rezultate sa svojim klijentima. ",
        "U ovim anketama, alfanumerički identifikator ispitanika biće takođe prosleđen Lumenu od strane trećeg lica tako da Lumen može pratiti koji ispitanici završavaju anketu. Lumen će ove informacije o završecima podeliti sa trećim licima u svrhu dodele nagrada onima koji su završili anketu. "
      ],
      "heading": "Vrsta informacija koja prikupljamo"
    },
    {
      "body": [
        "Od ispitanika se povremeno može tražiti da učestvuju istraživačkom projektu koji zahteva više od jednog perioda prikupljanja podataka. U tom slučaju, identifikator ispitanika koristiće se kao trajni identifikator za ponovni kontakt sa jedinstvenim učesnikom radi pokretanja učešća istog u bilo kom naknadnom vršenju prikupljanja podataka. U tom slučaju, identifikator ispitanika smatraće se ličnim podatkom.  "
      ],
      "heading": "Vrsta ličnih podataka koje prikupljamo "
    },
    {
      "body": [
        "Kao i kod tipične ankete, identifikator upornog ispitanika proslediće nam treće lice koje je učesnika zavrbovalo za naše istraživanje. Koristićemo ga da ponovo kontaktiramo pojedince kako bi smo započeli njihovo naknadno učešće u istraživanju i možemo ga podeliti sa trećim licima kako bismo olakšali to naknadno učešće.",
        "Prema Opštoj uredbi o zaštiti podataka UK (OUZP UK), zakonski osnov za vršenje obrade ovih podataka je vaša saglasnost. Svoju saglasnost možete povući u bilo kom trenutku. To možete učiniti tako što ćete nas kontaktirati na dataprotection@lumen-research.com"
      ],
      "heading": "Kako dobijamo lične podatke i zašto ih imamo"
    },
    {
      "body": [
        "Vaše informacije bezbedno se skladište i šifruju pomoću SSL tehnologije. Ovo je najčešče korišćeni visoki standard tehnologije šifrovanja i banke ga koriste radi obezbeđenja bankarskih transakcija i drugih veoma osetljivih komunikacija. Koriste ga i Amazon, PayPal, eBay i druge internet lokacije koje zahtevaju bezbednosne sisteme na bankarskom nivou. Naučnici procenjuju da bi razbijanje 256-obitne šifre korišćenjem trenutne tehnologije zahtevalo znatno više vremena od starosti univerzuma. Prikupljeni podaci skaldište se na bezbednim veb serverima kojima upravlja Amazon Web Services (AWS) AWS je jedna od najčešće korišćenih bezbednih i pouzdanih platformi za usluge računurstva u oblaku i koristi se od strane većih komercijalnih usluga u oblaku, uklučujući Netflix i Expedia. ",
        "Uporne identifikatore skladištimo na period potreban za ponovni kontakt sa ispitanicama za bilo koje višefazno iztraživanje. To može varirati u zavisnosti od prirode istraživanje i o tome će se obavestiti kada date saglasnost za učešće. Po okončanju prikupljanja podataka za takav projekat, identifikatori će se čuvati samo onoliko koliko je potrebno za analiziranje podataka i izveštavanje o istima. Nakon analize projekta i izveštavanja o istom, te lične podatke ćemo ukloniti brisanjem istih iz naših baza podataka."
      ],
      "heading": "Kako skladištimo vaše lične podatke "
    }
  ],
  "dataProtection": {
    "body": ["Prema zakonu o zaštita podataka, imate sledeća prava:"],
    "bullets": [
      {
        "title": "Pravo na pristup",
        "text": [
          "Imate pravo da od nas tražite umnožene primerke vaših ličnih podataka. "
        ]
      },
      {
        "title": "Pravo na ispravku",
        "text": [
          "Imate pravo da od nas tražite ispravku ličnih podataka koje smatrate netačnim. Imate takođe pravo i da od nas tražite dopunu informacija koje smatrate nepotpunim. "
        ]
      },
      {
        "title": "Pravo na brisanje",
        "text": [
          "Imate pravo da od nas tražite brisanje vaših ličnih podatka pod određenim okolnostima. "
        ]
      },
      {
        "title": "Pravo na ograničenje obrade",
        "text": [
          "Imate pravo da od nas tražite ograničenje obrade vaših ličnih podataka pod određenim okolnostima. "
        ]
      },
      {
        "title": "Pravo na povlačenje saglasnosti",
        "text": [
          "Imate pravo da povučete saglasnost za obradu vaših ličnih podataka."
        ]
      },
      {
        "title": "Pravo na prenosivost podataka",
        "text": [
          "Imate pravo da tražite prenos ličnih podataka, koje ste nam dali, drugoj organiziciji ili vama, pod određenim okolnostima.",
          "Od vas se ne traži da plaćate bilo kakvu naknadu za vršenje svojih prava. Ukoliko podnesete zahtev, imamo mesec dana da vam odgovorimo.",
          "Molimo da nas kontaktirate na dataprotection@lumen-research.com ako želite da podnesete zahtev."
        ]
      }
    ],
    "title": "Vaša prava na zaštitu podataka"
  },
  "complaints": {
    "body": [
      "Ukoliko ste na bilo koji način zabrinuti u vezi našeg korišćenja vaših ličnih podataka, možete nam podneti žalbu na dataprotection@lumen-research.com.",
      "Možete se takođe i žaliti Kancelariji Poverenika za informacije (ICO) u UK, ako niste zadovoljni načinom na koji smo koristili vaše podatke."
    ],
    "address": {
      "body": [
        "Information Commissioner’s Office",
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ],
      "title": "Adresa Kancelarije Poverenika za informacije (ICO) u UK:            "
    },
    "title": "Kako se žaliti",
    "number": "Broj SOS linije: 0303 123 1113",
    "website": "Int. lokacija Kanc. pov. za inf. u UK (ICO): https://www.ico.org.uk"
  }
}
