import LoggerController from "../../controllers/Logger/LoggerController";
import MouseMoveListener from "./MouseMoveListener";

class ResizeListener {
  constructor() {
    this.isListening = false;

    this.subscribe = this.subscribe.bind(this);
    this.revoke = this.revoke.bind(this)
    this.clearClickData = this.clearClickData.bind(this);
  }

  subscribe() {
    if (!this.isListening) {
      LoggerController.events.logSubscribe.log("ResizeListener")
      window.addEventListener("resize", this.clearClickData);
    }
  }

  revoke() {
    if (this.isListening) {
      LoggerController.events.logRevoke.log("ResizeListener");
      window.removeEventListener("resize", this.clearClickData);
    }
  }

  clearClickData(){
    MouseMoveListener.clearData()
  }
}

export default new ResizeListener()
