import { store } from "../../../../store";
import Endpoint from "../../Endpoint";

const getPayload = (answers) => {
  let { accessCode, participantId } = store.getState().studyReducer;

  let body = {
    access_code: accessCode,
    participant_id: participantId,
    question_answer_list: Object.values(answers),
  };

  return body;
};

export default (studyId, answers) => {
  return new Endpoint(
    "question-response",
    `${process.env.REACT_APP_PARTICIPATION_URL}/participation/studies/${studyId}/question-response`,
    "POST",
    () => getPayload(answers),
    undefined,
    true
  );
};
