
export const cellIdFromURL = () => {
    return parseInt(window.location.pathname.split("/").filter((item) => item)[2])
}

export const stepIdFromURL = () => {
    return parseInt(window.location.pathname.split("/").filter((item) => item)[3])
}

export const stepIdFrom = (pathname) => {
    return parseInt(pathname.split("/").filter((item) => item)[3])
}

export const studyIdFrom = (pathname) => {
    return parseInt(pathname.split("/")[2])
}

export const passOnSearchParams = (searchParams, ignoreList, addList) => {
    let strOut = ''
    
    if(!!searchParams){
        Array.from(searchParams.entries()).forEach(([key, value]) => {
            if(!ignoreList.includes(key)){
                if(strOut.includes('?')){
                    strOut += `&${key}=${value}`
                } else {
                    strOut += `?${key}=${value}`
                }
            }
        })
    }

    if(!!addList && addList.length > 0){
    addList.forEach(({key, value}) => {
        if(key && value){
            if(strOut.includes('?')){
                strOut += `&${key}=${value}`
            } else {
                strOut += `?${key}=${value}`
            }
        }
    })
    }


    return strOut
}