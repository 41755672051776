export const INIT_PARAMS = "INIT_PARAMS";
export const INIT_URL_PARAMS = "INIT_URL_PARAMS";
export const INCREMENT_STUDY_STEP = "INCREMENT_STUDY_STEP";
export const SESSION_ID_UPDATE = 'SESSION_ID_UPDATE';
export const NO_PANEL_STUDY_UPDATE = "NO_PANEL_STUDY_UPDATE";
export const PANEL_RESPONSE_UPDATE = "PANEL_RESPONSE_UPDATE";
export const PANEL_DETAILS_UPDATE = "PANEL_DETAILS_UPDATE";
export const START_RESPONSE_UPDATE = "START_RESPONSE_UPDATE";
export const ASSIGN_RESPONSE_UPDATE = "ASSIGN_RESPONSE_UPDATE";
export const TRACKING_START_TIME = "TRACKING_START_TIME";
export const SHOW_STIMULUS_QUESTION = "SHOW_STIMULUS_QUESTION";
export const HIDE_STIMULUS_QUESTION = "HIDE_STIMULUS_QUESTION";
export const UPDATE_INTERNAL_DEMOGRAPHICS_QUESTIONS = "UPDATE_INTERNAL_DEMOGRAPHICS_QUESTIONS";
export const UPDATE_INTERNAL_DEMOGRAPHICS_RESPONSES = "UPDATE_INTERNAL_DEMOGRAPHICS_RESPONSES";
export const CALIBRATION_INFO = "CALIBRATION_INFO";
export const UPDATE_STEP_DATA = "UPDATE_STEP_DATA";
export const REGION_CHECK = "REGION_CHECK"
export const START_PANEL = "START_PANEL"
export const SET_ADBLOCK = "SET_ADBLOCK"
export const SET_HASWEBCAM = "SET_HASWEBCAM"
export const UPDATE_EXTRA_DATA = "UPDATE_EXTRA_DATA";
export const PRESTART_CHECKS_COMPLETE = "PRESTART_CHECKS_COMPLETE"
export const START_CHECKS_COMPLETE = "START_CHECKS_COMPLETE"
export const INSTRUCTIONS_CHECKS_COMPLETE = "INSTRUCTIONS_CHECKS_COMPLETE"
export const COMPLETE_STUDY_STEP = "COMPLETE_STUDY_STEP"
export const SET_DUMMY_EYETRACKING = "SET_DUMMY_EYETRACKING"
export const SET_SURVEY_COMPLETE = "SET_SURVEY_COMPLETE"
export const SET_REQUIRED_ORIENTATION = "SET_REQUIRED_ORIENTATION"
export const UPDATE_SOCIAL_SESSION_DATA = "UPDATE_SOCIAL_SESSION_DATA"
export const UPDATE_SURVEY_URL_PARAMS = "UPDATE_SURVEY_URL_PARAMS"
export const TOGGLE_EXTENDED_LOGGER = "TOGGLE_EXTENDED_LOGGER"
export const TOGGLE_SKIP_SURVEY = "TOGGLE_SKIP_SURVEY"