import Endpoint from "../../Endpoint"

const getPayload = (step_id, cell_id) => {
    return {step_id, cell_id}
}

export default (step_id, cell_id) => {
    return new Endpoint(
        "step",
        `${process.env.REACT_APP_PARTICIPATION_URL}/demo/step`,
        "POST",
        () => getPayload(step_id, cell_id),
        undefined,
        true
    )
}