import startPanel from "./endpoints/startPanel";
import assign from "./endpoints/assign";
import start from "./endpoints/start";
import saveCalibration from './endpoints/saveCalibration'
import complete from "./endpoints/complete";
import fail from "./endpoints/fail";
import completeCalibration from "./endpoints/completeCalibration";
import preStudyQuestionResponse from './endpoints/preStudyQuestionResponse'
import completeStep from "./endpoints/completeStep";
import questionResponse from "./endpoints/questionResponse";
import feedback from "./endpoints/feedback";
import taskPage from "./endpoints/taskPage";
import getPanelDetails from "./endpoints/getPanelDetails";
import updateLastSectionCompleted from "./endpoints/updateLastSectionCompleted";
import shortName from "./endpoints/shortName";

export default {startPanel, assign, start, saveCalibration, complete, fail, completeCalibration, preStudyQuestionResponse, completeStep, questionResponse, feedback, taskPage, getPanelDetails, updateLastSectionCompleted, shortName}