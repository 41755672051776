import RevokableAsync from "../../utils/helpers/revokableAsync";
import ErrorController from "../Error/ErrorController";

export default class {
  constructor(
    identifier,
    url,
    method,
    getPayload,
    handleResponse,
    handleError
  ) {
    this.identifier = identifier;
    this.url = url;
    this.method = method;
    this.getPayload = getPayload;
    this.handleResponse = handleResponse;
    this.handleError = handleError;
  }

  fetch = async () => {
    let response = await fetch(this.url, {
      method: this.method,
      body:
        this.method === "GET" ? undefined : JSON.stringify(this.getPayload()),
    });

    if (response.ok) {
      let responseBody = await response.json();
      return responseBody;
    } else {
      let e = new Error(response.statusText);
      e.name = response.status || "";
      throw e;
    }
  };

  call = async () => {
    let request = new RevokableAsync(
      this.identifier,
      ErrorController.errors.RequestError,
      this.fetch
    );

    let response = await request.promise;

    if (this.handleResponse) {
      response = await this.handleResponse(response);
    }

    if (!request.rejected && !request.errored && response.success) {
      return response;
    } else if (this.handleError && (request.rejected || request.errored) && !response.success) {
      this.onError(response);
      return response;
    } else if (this.handleError && !response.success) {
      this.onError(response);
      return response;
    } else {
      return { success: false, ...response};
    }
  };

  onError = (response) => {
    if (response.error_code && response.error_code in ErrorController.errors) {
      if(response.detail){
        ErrorController.errors[response.error_code].raise({
          error_name: response.error_name,
          error_message: response.error_message,
          extra: {...response.detail},
        });
      } else {
        ErrorController.errors[response.error_code].raise({
          error_name: response.error_name,
          error_message: response.error_message,
        });
      }

    } else if (response.endpoint && response.error_message) {
      ErrorController.errors.RequestError.raise({
        error_message: response.error_message,
        identifier: this.identifier,
      });
    } else {
      ErrorController.errors.RequestError.raise({
        error_message: "Request failed",
        identifier: this.identifier,
      });
    }
  };
}
