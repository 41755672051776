{
    "app.button.faqs": "Preguntas frecuentes",
    "app.button.terms": "Condiciones de uso",
    "app.button.privacy": "Política de privacidad",
    "app.button.feedback": "Comentario",
    "app.button.selectLanguage": "Seleccione idioma",
    "app.button.help": "Ayuda",
    "app.button.start": "Iniciar",
    "app.button.next": "Siguiente",
    "app.button.submit": "Enviar",
    "app.button.takepart": "OK, participaré",
    "app.menu.feedback.content": "Si ha tenido algún problema con el estudio o simplemente desea dejar un comentario, no dude en hacerlo aquí.",
    "app.contactModal.feedback": "Gracias por tus comentarios!",
    "app.takePartModal.line1": "Si se va ahora, no será elegible para ninguna recompensa por participación.",
    "app.takePartModal.line2": "Todo lo que necesitas es una cámara web!",
    "app.takePartModal.reason1": "No tengo cámara web.",
    "app.takePartModal.reason2": "No me siento cómodo con el uso de mi cámara web para esta encuesta.",
    "app.takePartModal.reason3": "No tengo tiempo ahora para esto.",
    "app.takePartModal.reason4": "Otra razón/ motivo.",
    "app.generic.redirect": "Pronto serás redirigido.",
    "app.prestart.loading": "Cargando, por favor espere.",
    "app.index.title": "Bienvenido",
    "app.index.content.line1": "Durante esta encuesta usaremos su cámara para saber hacia dónde mira. No se preocupe, no haremos fotos ni vídeos.",
    "app.index.content.line1alt": "Asegúrese de estar sentado cómodamente y luego toque comenzar.",
    "app.index.content.line2": "¿Tu dispositivo tiene cámara? Si es así, presione el botón de inicio a continuación.",
    "app.index.rejection": "Lo siento, parece que ha seguido un enlace con formato incorrecto.",
    "app.index.webview": "Lo sentimos, parece que ha accedido a nuestra página desde dentro de una vista web. Por favor abra este enlace dentro de su navegador. Esto es posible hacerlo desde el menú de vista web.",
    "app.index.webview.chrome.1": "Toca el botón de menú de arriba y luego selecciona 'Abrir en Chrome'.",
    "app.index.webview.chrome.2": "Esto abrirá esta encuesta en una aplicación de navegador, de manera que pueda funcionar correctamente.",
    "app.index.webview.android.1": "Toque comenzar a continuación para abrir esta encuesta en una aplicación de navegador, de manera que pueda funcionar correctamente.",
    "app.index.webview.android.2": "Si esto no funciona, abra el enlace a esta página en una aplicación de navegador manualmente (por ejemplo, copia y pega el enlace en la barra de direcciones en Chrome o use una opción como 'Abrir en Chrome' en la barra de menú de arriba).",
    "app.index.webview.safari.1": "Toque el icono de Safari en la parte inferior de la pantalla para continuar.",
    "app.index.webview.safari.2": "Se ve como esto:",
    "app.index.webview.safari.3": "Esto abrirá la encuesta en Safari de manera que funcione correctamente.",
    "app.index.wifi": "Parece que su conexión a Internet es muy lenta. ¿Estás conectado a wifi??",
    "app.index.tellmemore": "Dime más.",
    "app.index.takepart": "No quiero participar.",
    "app.instructions.title": "Instrucciones",
    "app.instructions.instruction1": "Si es posible, no ocupe anteojos en la encuesta.",
    "app.instructions.instruction2": "Siéntese cómodamente de manera que pueda mantener la cabeza y el dispositivo sin movimientos.",
    "app.instructions.instruction3": "Mueva sus ojos, no su cabeza.",
    "app.instructions.instruction4": "Listo para comenzar?",
    "app.instructions.instruction5": "Ocupe wifi para evitar cargos por datos.",
    "app.instructions.instruction6": "Por favor lea cuidadosamente las siguientes instrucciones.",
    "app.instructions.instruction7": "Apoye su teléfono sobre una superficie estable.",
    "app.instructions.instruction8": "Asegúrese de que su habitación se encuentra bien iluminada, preferiblemente desde arriba (no desde atrás).",
    "app.setup.title": "Configuración",
    "app.setup.instruction0": "Mantenga su dispositivo en modo vertical.",
    "app.setup.instruction0a": "Por favor gira tu dispositivo para que sea vertical.",
    "app.setup.instruction0b": "Por favor gire su dispositivo para que su cámara esté a la izquierda.",
    "app.setup.instruction0c": "Por favor gire su dispositivo para que su cámara esté a la derecha.",
    "app.setup.instruction1": "Inicializando...",
    "app.setup.instruction2": "Cuando sea detectado, coloca tu cabeza dentro del cuadrado.",
    "app.setup.instruction3": "Mantenga la cabeza y el dispositivo en posición hasta que se complete la encuesta.",
    "app.setup.instruction4": "Ahora solo necesitamos validar.",
    "app.setup.instruction5": "¡Gracias! Recuerde mantener la cabeza y el dispositivo en la misma posición hasta que se complete la encuesta.",
    "app.setup.instruction6": "Desliza el dedo hacia arriba para ocultar la barra de direcciones.",
    "app.setup.retry": "Error detectado. Mantenga la cabeza y el dispositivo quieto y no cubra la cámara, por ejemplo, con los dedos. Ocupe el botón de abajo para volver a intentarlo.",
    "app.setup.retryInit": "Error detectado. Por favor asegúrese de que el navegador pueda acceder a su cámara. Si ha rechazado previamente los permisos de acceso a la cámara, se deberá aceptar en la configuración de privacidad del navegador. Ocupe el botón de abajo para volver a intentarlo.",
    "app.setup.retryButton": "Reintente",
    "app.setup.calibrationProp.watch": "Siga el punto cuando aparezca en la pantalla",
    "app.setup.calibrationProp.watch.george": "A continuación, cuando vea una cara, siga mirándola mientras refleja los movimientos de su cabeza.",
    "app.setup.calibrationProp.click": "Haga clic en cualquier lugar para continuar",
    "app.setup.calibrationProp.tap": "Toque en cualquier lugar para continuar",
    "app.study.validationProp.watch": "Siga el punto cuando aparezca en la pantalla",
    "app.study.validationProp.restart": "Por favor intente de nuevo",
    "app.study.validationProp.click": "Haga clic en cualquier lugar para continuar",
    "app.study.validationProp.tap": "Toque en cualquier lugar para continuar",
    "app.study.render.error": "Dominio no válido para el paso de procesamiento.",
    "app.error.title": "Error",
    "app.error.generic": "Oh, algo salió mal.",
    "app.error.initialise": "Imposible inicializar!",
    "app.error.findCamera": "Imposible encontrar una cámara!",
    "app.error.headPosition": "Imposible determinar la posición de su cabeza.",
    "app.error.calibrate": "Imposible de calibrar.",
    "app.error.validate": "Imposible de validar.",
    "app.error.start": "Error al iniciar.",
    "app.error.assign": "Error para asignar.",
    "app.error.osnotallowed": "Su sistema operativo no está permitido, por favor verifique qué dispositivo puede usar para esta encuesta y vuelva a intentarlo.",
    "app.error.demoquotafullnoactivecell": "Gracias por su interés. Este estudio ahora está cerrado para su grupo demográfico..",
    "app.invalid.title": "Inválido",
    "app.invalid.generic": "Lo sentimos, no eres elegible para este estudio.",
    "app.completion.title": "Terminación",
    "app.completion.congratulations": "¡Felicidades! Ha completado el estudio. El acceso de esta encuesta a su cámara finalizará automaticamente.",
    "app.questions.required": "Esta pregunta es obligatoria.",
    "app.faq.question1": "P: ¿Qué es Lumen Study Platform y qué implica participar en una encuesta de seguimiento ocular?",
    "app.faq.answer1": "R: Esta encuesta implica el uso de tecnología de seguimiento ocular. Se le pedirá que se siente frente a la cámara web o la cámara frontal de su dispositivo y realice una calibración para poder hacer una estimación de dónde hacia dónde está mirando en la pantalla. Durante la encuesta, se le pedirá que vea algunos elementos (por ejemplo, imágenes, sitios web o vídeos) mientras que la herramienta de la encuesta calcula dónde está mirando. Los datos que se capturan de este 'seguimiento ocular' son las coordenadas de los puntos de mirada estimados en su pantalla a lo largo del tiempo. El software ocupa imágenes suyas de su cámara web para hacer estas estimaciones, pero no captura fotos ni videos de Usted. También es posible que le hagan algunas preguntas sobre Usted, su experiencia y los elementos que ha visto durante la encuesta. Los datos se envían a Lumen Research Ltd. Lumen Research analizará los datos y compartirá los resultados con sus clientes.",
    "app.faq.question2": "P: ¿Por qué el software requiere una cámara web / cámara y cómo es ocupada?",
    "app.faq.answer2": "R: El software ocupa imágenes de su cámara web o la cámara del dispositivo, para estimar hacia dónde está mirando en su pantalla. Para ello, utiliza una combinación de software de seguimiento facial y seguimiento ocular que analiza en tiempo real las imágenes en su máquina. Estas imágenes se procesan y se le muestra durante la calibración para que pueda ubicarse correctamente, pero las imágenes no se graban, de manera que su privacidad está protegida. Los datos que se registran y recopilan del seguimiento ocular no son imágenes, sino datos numéricos sobre los puntos de mirada estimados en el estímulo a lo largo del tiempo..",
    "app.faq.question3": "P: ¿Se toma imágenes mientras se hace el seguimiento ocular?",
    "app.faq.answer3": "R: La única vez que se ocupan imágenes es para permitirle posicionarse correctamente durante la calibración. Estas imágenes no se guardan y sólo Usted puede verlas. Los datos que se registran y recopilan del seguimiento ocular no son imágenes, sino datos numéricos sobre los puntos de mirada estimados en el estímulo a lo largo del tiempo.",
    "app.faq.question4": "P: Qué datos se recopilan durante la encuesta?",
    "app.faq.answer4.line1": "R: El software recopila algunos o todos cinco tipos de datos siguientes :",
    "app.faq.answer4.line2": "ID de usuario: el ID de usuario es utilizado para identificar a los encuestados (con el fin de administrar las recompensas de participación);",
    "app.faq.answer4.line3": "Datos de mirada: estimaciones de dónde miran los usuarios en su pantalla cuando se muestran estímulos en la pantalla.",
    "app.faq.answer4.line4": "Datos de la encuesta: respuestas a las preguntas respondidas por los usuarios",
    "app.faq.answer4.line5": "Metadatos”: datos sobre el momento en el que las personas participaron con éxito en la investigación.",
    "app.faq.answer4.line6": "Datos del dispositivo físico”: datos sobre la computadora en la que participa un usuario, como el nivel de RAM, el tipo de CPU y el agente de usuario (sistema operativo y versión del navegador), así como información sobre la pantalla de la computadora (tamaño y resolución), cámara web (marca y resolución) y programas de antivirus.",
    "app.faq.question5": "P: ¿Cómo se ocuparán los datos recopilados?",
    "app.faq.answer5.line1": "R: Los datos que se recopilen se ocuparan para explorar cómo las personas ven e interactúan con varios tipos de imágenes visuales y medios, y para evaluar las opiniones de las personas sobre las imágenes que se muestran.",
    "app.faq.answer5.line2": "Lumen puede compartir los datos de la investigación con empresas afiliadas y con socios comerciales y clientes. Si bien los datos de la investigación pueden compartirse con afiliados, socios y clientes, esto generalmente se hace de forma anónima. Dichos afiliados, socios y clientes pueden utilizar estos datos para crear descripciones resumidas de una amplia gama de comportamientos y segmentos de consumidores para desarrollar productos o para proporcionar soluciones de marketing a sus clientes o consumidores..",
    "app.faq.answer5.line3": "Lumen recopila 'datos de dispositivos físicos' y 'metadatos' para ayudar a gestionar la recopilación y el procesamiento de datos, incluido el control de calidad y la gestión del volumen.",
    "app.faq.answer5.line4": "Los datos recopilados se conservarán durante el período necesario para cumplir con los fines descritos.",
    "app.faq.question6": "P: ¿Qué tan seguros están mis datos?",
    "app.faq.answer6": "R: Los datos recopilados por Lumen están encriptados mediante tecnología SSL. Este es una alta tecnología de encriptación estándar comúnmente utilizado y es ocupado por bancos para asegurar transacciones bancarias y otras comunicaciones muy confidenciales. También es ocupado por Amazon, PayPal, eBay y otros sitios web que requieren sistemas de seguridad a nivel bancario. Los científicos estiman que para romper el cifrado de 256 bits utilizando la tecnología actual requeriría mucho más tiempo que la edad del universo. Los datos recopilados se almacenan en servidores web muy seguros administrados por Amazon Web Services (AWS). AWS es una de las plataformas de servicios de computación más seguras y confiables utilizadas y es ocupada por los principales servicios comerciales basados en la nube, incluidos Netflix y Expedia.",
    "app.faq.question7": "P: ¿Qué obligaciones tengo con respecto al contenido que se le muestra?",
    "app.faq.answer7": "R: Al participar, comprende y acepta que el contenido que se le muestra en este estudio puede ser confidencial, propiedad de un tercero y/o sujeto a leyes de marcas registradas o derechos de autor y que no realizará capturas de pantalla, grabará ni copiará de ninguna manera el contenido o se redistribuirá cualquiera de los contenidos del estudio.",
    "app.faq.question8": "P: No estoy seguro de qué tan buena es mi cámara web, ¿funcionará para el seguimiento ocular?",
    "app.faq.answer8": "R: El sistema funciona con una amplia gama de cámaras web, de manera que no debería ser un problema.",
    "app.faq.question9": "P: Terminé el estudio, ¿necesito hacer algo más?",
    "app.faq.answer9": "R: No, no es necesario hacer algo más. El software se cerrará automáticamente.",
    "app.faq.question10": "P: ¿Necesito estar conectado a internet para participar?",
    "app.faq.answer10": "R: Sí, necesita una conexión a Internet para descargar el programa y completar la tarea.",
    "app.faq.question11": "P: ¿Necesito completar la tarea en una superficie estable?",
    "app.faq.answer11": "R: Sí, para obtener los mejores resultados, debe sentarse cómodamente frente a la pantalla de su computadora y, después de la calibración, mantener la cabeza quieta mientras se le presenta el estímulo, hasta que Usted sea avisado. Recomendamos no participar si está en un tren o balanceando su Laptop sobre su regazo, etc.",
    "app.faq.question12": "P: Las preguntas frecuentes no han resuelto mi problema, ¿qué debo hacer ahora?",
    "app.faq.answer12": "R: Por favor envíenos un correo electrónico a study-feedback@lumen-research.com y nos comunicaremos con Usted a la brevedad.",
    "app.faq.patent": "Las pruebas de contenido web de Lumen están monitorizadas por nuestra propia tecnología patentada de pruebas en contexto. (UK Patent Application Number 1816158.8)",
    "app.terms.line1": "Estos términos de uso ('Términos de uso') rigen el acuerdo entre Usted y Lumen Research Ltd ('Lumen' o 'nosotros'), una empresa registrada en Inglaterra, número de empresa 8682432 con domicilio social en Unit 215, 22 Highbury Grove, London N5 2EF y referencia de registro de protección de datos ZA130772, con respecto al uso del software de seguimiento ocular 'ViewPoints' (el 'Software') en su dispositivo.",
    "app.terms.line2": "1. Acuerdo de términos",
    "app.terms.line3": "Al utilizar el software, Usted reconoce que ha aceptado regirse por este acuerdo. Si no está de acuerdo con los términos y condiciones de este acuerdo, no complete la encuesta de ViewPoints.",
    "app.terms.line4": "2. Información confidencial",
    "app.terms.line5": "Al instalar, usar o interactuar de alguna manera con ViewPoints, puede tener acceso a información y/o contenido perteneciente o que pertenezca a Lumen, incluida información que sea identificada o tratada por Lumen o cualquiera de los clientes de Lumen o clientes confidenciales o que por razón de su carácter o las circunstancias o la forma de su divulgación es confidencial, incluída (sin perjuicio de la generalidad anterior) cualquier información sobre planes de negocios, maduración de nuevas oportunidades comerciales, proyectos de investigación y desarrollo, diseños, procesos secretos, códigos, diseños de software, secretos comerciales , desarrollo y fórmulas de productos o servicios, know-how (saber como), invenciones, estadísticas y pronósticos de ventas, estrategias y planes de marketing, costos, pérdidas y ganancias y otra información financiera (salvo en la medida en que se publiquen en cuentas auditadas), precios y estructuras de descuento (todos ya sea registrado o no por escrito o en formato electrónico o de otro tipo) ('Información confidencial'). Dicha información confidencial seguirá siendo propiedad única y exclusiva de Lumen y/o sus clientes, y en ningún caso obtendrá o recibirá ningún derecho, título y/o interés sobre dicha información confidencial.",
    "app.terms.line6": "Durante su participación en ViewPoints o en cualquier momento (sin límite) después de la terminación de dicha participación, no debe: divulgar o comunicar a ninguna persona; utilizar para sus propios fines; o por no ejercer el debido cuidado y diligencia, causar una divulgación no autorizada de cualquier información confidencial. En todo momento debe hacer todo lo posible para evitar la publicación o divulgación de cualquier información confidencial. Estas restricciones dejarán de aplicarse a cualquier información que estará disponible para el público en general de otra manera que no sea a través de su incumplimiento.",
    "app.terms.line7": "3. Propiedad de Lumen",
    "app.terms.line8": "El Software ViewPoints y todos los datos, códigos, claves, contraseñas, diseños, dibujos, registros y otros documentos o materiales relacionados con el Software serán y seguirán siendo propiedad de Lumen durante la duración de su participación en la encuesta de ViewPoints. Lumen posee todos los derechos intelectuales o de propiedad sobre todos y cada uno de los derechos de propiedad intelectual o industrial existentes y futuros en y sobre cualquier documento, material, modelo, diseño, dibujo, proceso, invenciones, fórmulas, codificación informática, datos, interfaces del sistema, metodologías, know-how (saber como), la información confidencial u otro trabajo, realizado, creado, diseñado, desarrollado, mejorado, reconstruido o descubierto por Usted solo o con cualquier otra persona en relación con el Software y/o dispositivo o que pueda ser utilizado o descubierto por Usted adaptado para su uso en el mismo o en conexión con él (ya sea registrado o no registrado), incluidas todas las patentes, derechos de autor, derechos de diseño, derechos de bases de datos, marcas comerciales, derechos de topografía de semiconductores, derechos de obtenciones vegetales, derechos de Internet / nombres de dominio, conocimientos técnicos existentes y futuros y todas y cada una de las solicitudes para cualquiera de los anteriores y todos y cada uno de los derechos para solicitar cualquiera de los anteriores ('Derechos de propiedad'). No se le transferirán derechos de propiedad en ningún momento.",
    "app.terms.line9": "4. Condiciones y restricciones de uso",
    "app.terms.line10": "El software ViewPoints tiene licencia para los usuarios finales. Sujeto a este acuerdo, por la presente aceptamos permitirle, de forma no exclusiva, revocable, intransferible, no sublicenciable y limitada, utilizar el Software ViewPoints en un dispositivo que Usted tenga o controle o en una Laptop con respeto a lo que declara y garantiza que tiene todos los derechos y permisos necesarios para dicha instalación. Para evitar dudas, no puede  de ninguna manera utilizar para ningún propósito fuera de ViewPoints, traducir, copiar, reproducir, transmitir por telecomunicaciones, adaptar, fusionar, cambiar o modificar el Software.",
    "app.terms.line11": "Usted acepta no: (i) copiar, modificar, adaptar, poner a disposición, alquilar, arrendar a tiempo compartido, crear un trabajo derivado o intentar vender o transferir cualquier derecho del Software (ii) desmontar, descompilar o revertir, diseñar cualquier parte del Software por cualquier medio; ni permitir ni ayudar a ninguna parte a derivar o intentar derivar el código fuente de desamblar, descifrar, descompilar o realizar ingeniería inversa del Software; ni tomar ningún otro paso para derivar información de diseño con respecto al Software; (iii) usar el Software para cualquier propósito que no sea para el que se le distribuyó originalmente; (iv) utilizar arañas, robots u otras técnicas de extracción de datos automatizadas para catalogar, descargar, almacenar o reproducir o distribuir el Software, o para manipular los resultados de cualquier encuesta o cualquier otra actividad; (v) tomar cualquier acción para interferir con el Software o el uso del Software por parte de un individuo, incluyendo, sin limitación, sobrecargar, 'inundar', 'bombardear el correo' o 'bloquear' el Software y el sistema de almacenamiento de datos que el Software ocupa a través de Amazon Web Services (vi) enviarnos cualquier código ilegal, engañoso o dañino, incluídos, entre otros, cualquier virus, spyware, adware o cualquier otro código dañino.",
    "app.terms.line12": "Todos los derechos que no se le otorguen expresamente a continuación están reservados para nosotros y quienes tengan la licencia. Usted acepta que no tenemos ninguna obligación en virtud de este acuerdo o de cualquier otro modo, de corregir errores, defectos o errores en el Software, o de respaldar, mantener, mejorar, modificar, actualizar o mejorar el Software de otra manera. No seremos de ninguna forma responsables de brindar a Usted o sus designados asistencia en el uso del Software.",
    "app.terms.line13": "No somos responsables de ningún daño ocasionado a su computadora como resultado de su uso del Software. No somos responsables de ningún cargo adicional por datos incurridos por Usted como resultado de los datos recopilados por el Software a través de su participación en la Investigación.",
    "app.terms.line14": "5. Descargo de responsabilidad",
    "app.terms.line15": "Nosotros y nuestros respectivos directores, funcionarios, empleados, agentes, representantes, otorgantes de licencias y proveedores no hacemos ninguna representación, garantía o condición de que el software estará disponible de manera oportuna, o que el acceso al software será ininterrumpido o seguro; (b) que se corregirán los defectos o errores; o (c) que el software o los servidores o redes a través de los cuales el software y el dispositivo están disponibles son seguros o están libres de virus u otros componentes dañinos. No tendremos ninguna obligación de proporcionar ningún servicio de mantenimiento y/o soporte con respecto al software.",
    "app.terms.line16": "6. Limitación de responsabilidad",
    "app.terms.line17": "(a) En la medida permitida por la aplicación de la ley, Usted acepta que nosotros y nuestros respectivos directores, funcionarios, empleados, agentes, representantes, otorgantes de licencias y proveedores no tendremos responsabilidad por ninguna o todas las pérdidas, daños o costos de cualquier tipo que resulten de su instalación, uso o acceso al software, su uso o incapacidad para usar el software, cualquier información o material disponible en o a través del software y/o en relación con el software o de otro modo con este acuerdo, independientemente de la naturaleza de la ley, derecho equitativo o legal que ha sido violado.",
    "app.terms.line18": "(b) En la mayor medida permitida por la aplicación de la ley, su único y exclusivo recurso en caso de insatisfacción con el software es dejar de ocuparlo.",
    "app.terms.line19": "(c) En ningún caso ninguna de nuestras entidades, directores, funcionarios, empleados, agentes o representantes será responsable ante usted por daños incidentales, indirectos, consecuentes, estatutarios, ejemplares, punitivos o especiales, pérdida de ingresos, pérdida de oportunidades, pérdida de reputación. , pérdida de uso, pérdida de datos, pérdida de otros intangibles o interceptación no autorizada de datos recopilados por el software por parte de terceros, ya sean previsibles o no, cualquiera que sea su causa, y bajo cualquier contrato, agravio (incluyendo, sin limitación, negligencia), responsabilidad estricta u otra teoría, incluso si se advierte con anticipación de la posibilidad de que tales daños puedan surgir.",
    "demo.index.content.line1": "Durante esta demostración, usaremos su cámara para estimar hacia dónde está mirando. No te preocupes, no haremos fotos ni vídeos.",
    "app.thankyou": "Gracias",
    "app.button.close": "Cerrar",
    "app.button.return": "Regresar",
    "app.init.welcome": "Bienvenido a la plataforma de estudio de Lumen Research",
    "app.init.intro": "La plataforma de estudio nos permite incorporar y configurar usuarios para un estudio de anuncios determinado.",
    "app.init.message1": "Dependiendo del tipo de estudio, se guiará al usuario a través de un conjunto de tareas.",
    "app.init.message2": "Estas tareas incluyen: realizar una encuesta, leer un conjunto de instrucciones y realizar una calibración de seguimiento ocular",
    "app.init.message3": "Ahora lo guiaremos a través de un estudio de demostración.",
    "app.init.message4": "A lo largo de este estudio, es posible que habilitemos su cámara web para realizar seguimiento ocular, pero no se registrará ninguno de sus datos.",
    "app.init.message5": "El estudio actual está configurado para usar los siguientes enlaces en filtrar/completar.",
    "app.index.webcamRetry": "No pudimos detectar una cámara web, que es necesaria para este estudio. Proporcione acceso a su cámara web y vuelva a intentarlo.",
    "app.index.adblockRetry": "¡Vaya! Hemos detectado un bloqueador de anuncios. Para obtener la mejor experiencia de estudio, desactívelo y vuelva a intentarlo.",
    "app.index.zoomRetry": "Requerimos que el navegador esté configurado al 100% de zoom, acerque/aleje e intente nuevamente",
    "app.index.fullscreendesktopretry": "Este estudio requiere una vista de pantalla completa, asegúrese de que la ventana de su navegador esté maximizada",
    "app.index.fullscreendesktoptest": "Este estudio requiere que la ventana de su navegador sea de pantalla completa, necesitamos realizar una prueba para asegurarnos de que la ventana de su navegador está maximizada",
    "app.index.fullscreendesktoptest2": "Aparecerán dos botones en breve, haga clic en ellos para continuar",
    "app.index.fullscreendesktopmaximised": "Mi pantalla ya está maximizada",
    "app.index.fullscreendesktopskip": "Gracias, hemos registrado esta instancia y mejoraremos nuestros procesos de detección.",
    "app.index.fullscreendesktopreset": "Hemos detectado un cambio en el tamaño de la pantalla, reiniciando la prueba.",
    "app.index.gotomobile": "Los usuarios de computadoras de escritorio no son elegibles para este estudio, use el código QR a continuación para abrir el estudio en el dispositivo móvil.",
    "app.index.returntopanel": "O, haga clic en el botón de abajo para volver al panel",
    "app.setup.instruction0d": "Por favor, gire su dispositivo para que quede horizontal.",
    "app.setup.instructioncamera": "Haga clic en el botón a continuación para solicitar acceso a la cámara. Para completar la encuesta, debe permitir los permisos de la cámara durante todo el estudio.",
    "app.setup.cameraText": "Solicitar acceso",
    "app.setup.instruction3audio": "Escuche atentamente y siga las instrucciones que aparecen en la pantalla.",
    "app.setup.retryPositioning": "No pudimos determinar la posición de su cabeza. Por favor mantenga la cabeza y el dispositivo quietos y no cubra la cámara, por ejemplo, con los dedos. Use el botón de abajo para volver a intentarlo.",
    "app.setup.retryBroker": "No pudimos completar el proceso de calibración. Por favor intente cerrar las aplicaciones y pestañas no utilizadas para liberar recursos",
    "app.error.missingpanelparameter": "Parámetro del panel faltante.",
    "app.completion.congratulations-noet": "¡Felicitaciones! Ha completado el estudio.",
    "app.error.previousfail": "Anteriormente reprobó este estudio, por favor utilice el enlace original para comenzar desde el principio.",
    "app.error.previoussuccess": "Ha completado este estudio anteriormente.",
    "app.error.route": "Intentó iniciar un estudio que ya estaba en progreso, utilice el enlace original para comenzar desde el principio.",
    "app.error.accesscode": "Ha intentado iniciar un estudio que no existe, verifique su enlace.",
    "app.error.webcam": "Este estudio requiere acceso a una cámara web",
    "app.error.adblocker": "Este estudio requiere que el bloqueo de anuncios esté deshabilitado.",
    "app.error.startsession": "No se pudo iniciar la sesión",
    "app.error.fullscreen": "Este estudio requiere una vista de pantalla completa.",
    "app.error.zoom": "Este estudio requiere que el zoom del navegador esté configurado al 100 %.",
    "app.index.webcamInstructions": "Estamos a punto de solicitar acceso a su cámara web, si recibe una ventana emergente, haga clic en permitir.",
    "app.setup.retryBack": "Por favor, absténgase de presionar el botón atrás del navegador durante este estudio, puede resultar en la pérdida de la recompensa. Use el botón a continuación para volver a intentarlo.",
    "app.setup.retryaudible": "Asegurese de que su dispositivo no esté silenciado e inténtelo de nuevo.",
    "app.restore.retry": "No ha pasado suficiente tiempo en el paso anterior, ",
    "app.restore.timeremaining": " minutos restantes"
}