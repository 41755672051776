import React, { useEffect, useState } from "react";

const TimerBar = ({ active, time }) => {
  let [currentTime, setCurrentTime] = useState(time);

  useEffect(() => {
    if (active) {
        setTimeout(() => handleUpdateTime(currentTime - 0.25), 250)
    }
  }, [active]);

  const handleUpdateTime = (newCurrentTime) => {
    if(newCurrentTime > 0) {
        setCurrentTime(newCurrentTime)
        setTimeout(() => handleUpdateTime(newCurrentTime - 0.25), 250)
    } else {
        setCurrentTime(0)
    }

  }

  return (
    <div className="ls-preview-timer">
      <div style={{width:`${currentTime/time * 100}%`}} className="ls-preview-timer-prog"></div>
      <div className="ls-preview-timer-bar"></div>
    </div>
  );
};

export default TimerBar;
