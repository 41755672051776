import { store } from "../../../../store";
import Endpoint from "../../Endpoint";

const getPayload = () => {
  let {accessCode, participantId} = store.getState().studyReducer

  let body = { access_code: accessCode, participant_id: participantId };

  return body
};

export default (study_id) => {
  return new Endpoint(
    "complete",
    `${process.env.REACT_APP_PARTICIPATION_URL}/participation/studies/${study_id}/complete`,
    "POST",
    getPayload,
    undefined,
    true
  );
};
