import sendDataToCrawler from "../../../../components/util/sendDataToCrawler";
import { dedupeByKey } from "../../../../components/util/util";
import { store } from "../../../../store";
import DeviceController from "../../../../utils/controllers/DeviceController";
import LoggerController from "../../../Logger/LoggerController";
import Endpoint from "../../Endpoint";

const getPayload = () => {
  let {
    accessCode,
    participant,
    panelCompanyCode,
    test,
    cellId,
    demographicsSurveyResponsesInternal,
    hasWebcam,
    hasAdBlock,
    skipSurvey,
  } = store.getState().studyReducer;

  const urlParams = new URLSearchParams(window.location.search);
  let deviceInfo = DeviceController.getDeviceInfo();
  let deviceDimensions = DeviceController.getDimensions();

  let body = {
    access_code: accessCode,
    participant: participant,
    panel: panelCompanyCode,
    test: test,
    requested_cell_id: cellId,
    gender: demographicsSurveyResponsesInternal["gender"] || urlParams.get("g"),
    age: demographicsSurveyResponsesInternal["age"] || urlParams.get("a"),
    social_grade:
      demographicsSurveyResponsesInternal["social_grade"] || urlParams.get("s"),
    family: demographicsSurveyResponsesInternal["family"] || urlParams.get("f"),
    earnings:
      demographicsSurveyResponsesInternal["earnings"] || urlParams.get("hi"),
    ethnicity:
      demographicsSurveyResponsesInternal["ethnicity"] || urlParams.get("et"),
    extra1: demographicsSurveyResponsesInternal["e1"] || urlParams.get("e1"),
    extra2: demographicsSurveyResponsesInternal["e2"] || urlParams.get("e2"),
    extra3: demographicsSurveyResponsesInternal["e3"] || urlParams.get("e3"),
    device: deviceInfo.device,
    os: deviceInfo.osString.toLowerCase(),
    os_version: deviceInfo.os.version,
    browser: deviceInfo.name.toLowerCase(),
    browser_version: deviceInfo.version,
    connection_speed: deviceInfo.connectionType,
    connection_speed_absolute: deviceInfo.connectionSpeed,
    is_mobile: deviceInfo.isMobile,
    is_touch_capable: deviceInfo.isMobile,
    device_model: deviceInfo.device,
    browser_language: window.navigator.language,
    hasWebcam: hasWebcam,
    hasAdBlock: hasAdBlock,
    screen_resolution_width: deviceDimensions.screen_resolution_width,
    screen_resolution_height: deviceDimensions.screen_resolution_height,
    browser_width: deviceDimensions.browser_width,
    browser_height: deviceDimensions.browser_height,
    browser_left: deviceDimensions.browser_left,
    browser_top: deviceDimensions.browser_top,
    viewport_width: deviceDimensions.viewport_width,
    viewport_height: deviceDimensions.viewport_height,
    browser_zoom: deviceDimensions.browser_zoom,
    skip_survey: skipSurvey,
  };

  return body;
};

const handleResponse = async (response) => {
  if (response && response.success) {
    let { testType } = store.getState().studyReducer;

    let stepData = dedupeByKey(
      response.step_data.filter((step) => !step.optional_step),
      "order"
    );

    store.dispatch({ type: "INIT_STEPDATA", payload: stepData });

    if (testType === "3" || testType === "4") {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      sendDataToCrawler("StepData", { stepData, cellId: response.cell_id });
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    LoggerController.setCellId(response.cell_id);

    let payload = {
      cellStatus: response.cell_status,
      cellId: response.cell_id,
      participantStatus: response.cell_id,
      participantId: response.participant_id,
      stepData: stepData,
      optionalStepData: response.step_data.filter((step) => step.optional_step),
      fullScreenRequired: response.full_screen_required,
      hasExternalDomain: response.has_external_domain,
    };

    store.dispatch({ type: "ASSIGN_RESPONSE_UPDATE", payload });
  }

  return response;
};

export default (study_id) => {
  return new Endpoint(
    "assign",
    `${process.env.REACT_APP_PARTICIPATION_URL}/participation/studies/${study_id}/assign`,
    "POST",
    getPayload,
    handleResponse,
    true
  );
};
