import { store } from "../../../../store";
import Endpoint from "../../Endpoint";

const getPayload = (panel) => {
  let { accessCode } = store.getState().studyReducer;

  let body = { access_code: accessCode, panel: panel };

  return body;
};

const handleResponse = (response) => {
  let {participant} = store.getState().studyReducer
  if (response.success) {
    let successRedirectUrl = response.success_redirect_url;
    if (!!participant) {
      successRedirectUrl = successRedirectUrl.replace(
        response.redirect_url_member_insert_parameter,
        participant
      );
    }
    let screenoutRedirectUrl = response.screenout_redirect_url;
    if (!!participant) {
      screenoutRedirectUrl = screenoutRedirectUrl.replace(
        response.redirect_url_member_insert_parameter,
        participant
      );
    }
    let quotaFullRedirectUrl = response.quota_full_redirect_url;
    if (!!participant) {
      quotaFullRedirectUrl = quotaFullRedirectUrl.replace(
        response.redirect_url_member_insert_parameter,
        participant
      );
    }

    let getPanelDetailsPayload = {
      successRedirectUrl,
      screenoutRedirectUrl,
      quotaFullRedirectUrl,
      panelCompanyCode: response.panel_company_code,
      studyId: response.study_id,
      studyName: response.study_name,
      secondsBeforeMoveNext: response.seconds_before_move_next,
      eyetrackingEnabled: response.eyetracking_enabled,
      showGdprWarning: response.show_gdpr_warning,
    };

    store.dispatch({
      type: "PANEL_DETAILS_UPDATE",
      payload: getPanelDetailsPayload,
    });

    return {
      success: true,
      links: {
        successRedirectUrl,
        screenoutRedirectUrl,
        quotaFullRedirectUrl,
      },
    };
  } else return { success: false };
};

export default (studyId, panel) => {
  return new Endpoint(
    "get-panel-details",
    `${process.env.REACT_APP_PARTICIPATION_URL}/participation/studies/${studyId}/get-panel-details`,
    "POST",
    () => getPayload(panel),
    handleResponse,
    true
  );
};
