import * as DEBUGLOG from "../../../components/util/debugLog";
import requestTimer from "../../requestTimer";
import tabChangeListener from "../../../utils/listeners/TabChangeListener";
import participationUrl from "../participationUrl";

const startPanel = async (studyId, body) => {
  tabChangeListener.setWaitingForApi("startPanel");
  let startPanelRequestDate = DEBUGLOG.debugLogRequestStart("start-panel");
  let response = await fetch(
    participationUrl() + "/participation/studies/" + studyId + "/start-panel",
    { method: "POST", body: JSON.stringify(body) }
  );
  DEBUGLOG.debugLogRequestFinish(startPanelRequestDate);

  if (response.ok) {
    let responseBody = await response.json();
    tabChangeListener.setWaitingForApi("");
    return responseBody;
  } else {
    let e = new Error(response.statusText);
    e.name = response.status || "";
    throw e;
  }
};

export default (...args) => requestTimer(() => startPanel(...args));
