import routeHistory from "../../components/util/routeHistory";
import studyHistory from "../../components/util/studyHistory";
import { routeType } from "../../components/util/util";
import { navigateWithOutParams } from "../../history";
import { store } from "../../store";
import ApiController from "../Api/ApiController";
import LoggerController from "../Logger/LoggerController";
import ErrorController from "./ErrorController";

class PlatformError {
  constructor(
    type,
    publicMessage,
    reportType,
    reportMessage,
    translationId,
    toTracker
  ) {
    this.type = type;
    this.publicMessage = publicMessage;
    this.reportType = reportType;
    this.reportMessage = reportMessage;
    this.translationId = translationId;
    this.toTracker = toTracker;
  }

  cleanMessage = (detail) => {
    let cleanPublicMessage = this.publicMessage;
    let cleanReportBody = this.reportMessage;
    let cleanReportType = this.reportType

    if (detail) {
      Object.entries(detail).forEach((entry, index) => {
        cleanPublicMessage = cleanPublicMessage.replace(
          `$${entry[0]}$`,
          entry[1]
        );
        cleanReportBody = cleanReportBody.replace(`$${entry[0]}$`, entry[1]);
        cleanReportType = cleanReportType.replace(`$${entry[0]}$`, entry[1])
      });
    }

    let cleanReportMessage = `${cleanReportType}: ${cleanReportBody}`;

    return { cleanPublicMessage, cleanReportMessage, cleanReportBody };
  };

  goToErrorPage = (cleanPublicMessage, cleanReportMessage, extra) => {
    let { studyId } = store.getState().studyReducer;
    let fromUrl = parseInt(window.location.pathname.split("/"))[2];

    store.dispatch({
      type: "ERROR_STATE",
      payload: {
        publicMessage: cleanPublicMessage,
        fullMessage: cleanReportMessage,
        translationId: this.translationId,
        extra: extra ? extra : undefined
      },
    });

    navigateWithOutParams(`/study/${studyId || fromUrl}/error/`);
  };

  error = async (cleanPublicMessage, cleanReportMessage, cleanReportBody) => {
    let { studyId, participant, participantId, accessCode } =
      store.getState().studyReducer;

    if (studyId && (participant || participantId)) {
      await ApiController.request.participation
        .fail(studyId, cleanReportMessage)
        .call();
    }

    LoggerController.sendToExtendedLogger(
      routeType(),
      "error",
      this.reportType,
      cleanReportBody,
      undefined,
      undefined
    );

    studyHistory.saveHistory(
      studyId,
      participant,
      accessCode,
      "fail",
      cleanReportMessage
    );

    if (this.toTracker) {
      ErrorController.sendTrackerError(cleanPublicMessage);
    }
  };

  warn = async (cleanPublicMessage, cleanReportMessage, cleanReportBody) => {
    ErrorController.sendTrackerWarning(cleanReportMessage);

    routeHistory.clearEntries();
  };

  raise = async (detail) => {
    let { cleanPublicMessage, cleanReportMessage, cleanReportBody } =
      this.cleanMessage(detail);

    if (this.type === "WARN") {
      await this.warn(cleanPublicMessage, cleanReportMessage, cleanReportBody);
    } else {
      await this.error(cleanPublicMessage, cleanReportMessage, cleanReportBody);
    }

    this.goToErrorPage(cleanPublicMessage, cleanReportMessage, detail ? detail.extra : undefined);
  };
}

export default PlatformError;
