
import BaseStep from "./BaseStep";
import LumenEyeTracking from "..";
import { BROKER_TYPES } from "../../../types/types";
import config from "../config";
import LogUserActionInternal from "@lumen-developer/lumen-common-js/loguseractioninternal.bundle";

class Validate extends BaseStep {
  constructor() {
    super("validate", "Validate");

    this.startHandler = null;
    this.element = null;
    this.timeout = 0;
    this.extraArgs = [];

    this.cleanUp = this.cleanUp.bind(this);
    this.onError = this.onError.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.continueValidation = this.continueValidation.bind(this);
    this.runProcess = this.runProcess.bind(this);
  }

  cleanUp() {
    this.removeErrorListener();

    if (this.startHandler) {
      this.startHandler.remove();
    }

    if (LumenEyeTracking.pointListener) {
      LumenEyeTracking.pointListener.remove();
    }

    LumenEyeTracking.broker.trackersController.stopGazeValidator();

    LumenEyeTracking.removeInstructions();

    let calibrationCanvas = document.getElementById("lr_calibrator");
    if (calibrationCanvas) {
      calibrationCanvas.remove();
    }

    let validationElement = document.getElementById("validationElement");
    if (validationElement) {
      validationElement.style.display = "none";
    }
  }

  onError(error) {
    if (this.state !== "cancelled") {
      super.onError();

      //event from errPassthrough
      if (error && error.detail) {
        LumenEyeTracking.lastError = new Error(error.detail.error.message);
        LumenEyeTracking.lastError.name = "ValidateError";
        LumenEyeTracking.lastError.retryCode = error.detail.error.retryCode;
      } else if (error && error.message) {
        LumenEyeTracking.lastError = new Error(error.message);
        LumenEyeTracking.lastError.name = "ValidateError";
      } else {
        LumenEyeTracking.lastError = new Error("ValidateError");
        LumenEyeTracking.lastError.name = "ValidateError";
      }

      LumenEyeTracking.errorHandler(
        this.resolver,
        this.cleanUp,
        "validate",
        this.startTime
      );
    }
  }

  async onComplete(startTime, validationDetails) {
    if (this.state !== "cancelled") {
      super.onComplete();

      this.removeErrorListener();

      let [
        participant,
        userId,
        studyId,
        sessionId,
        cellId,
        stepId,
        stepOrderId,
        panelCode,
      ] = this.extraArgs;

      let valData = validationDetails
        ? validationDetails.validatedErrorCorrection
        : null;

      let response = await LumenEyeTracking.sendValidationData(
        stepId,
        stepOrderId,
        startTime,
        valData
      );

      this.element.style.display = "none";
      this.element.style.background = "rgba(0, 0, 0, 0.5)";
      while (this.element.firstChild) {
        this.element.removeChild(this.element.firstChild);
      }

      if (response.success) {
        this.resolver({ success: true });
      } else {
        LogUserActionInternal(
          participant,
          studyId,
          `LS:validateFail`,
          {
            log: "Validation failed.",
          },
          panelCode
        );

        let validateError = new Error("Failed to send validation data");
        validateError.name = "ValidateError";
        this.resolver({ success: false, error: validateError });
      }
    }
  }

  continueValidation() {
    this.element.removeEventListener("click", this.continueValidation);
    this.element.removeEventListener("touchstart", this.continueValidation);
    this.element.style.background = "white";
    LumenEyeTracking.removeInstructions();

    let startTime = Date.now();

    LumenEyeTracking.broker.validate(
      this.element,
      this.timeout
    ).then(async(validationDetails) => {
      await this.onComplete(startTime, validationDetails);
    }).catch(async () => {
      await this.onError();
    });
  }

  async runProcess(
    element,
    participant,
    userId,
    studyId,
    sessionId,
    cellId,
    stepId,
    stepOrderId,
    timeout = 120000,
    panelCode
  ) {
    let validationStart = new Date().getTime();

    this.element = element;
    this.timeout = timeout;
    this.extraArgs = [
      participant,
      userId,
      studyId,
      sessionId,
      cellId,
      stepId,
      stepOrderId,
      panelCode,
    ];

    LumenEyeTracking.validationPoint = 0;
    LumenEyeTracking.validationTotal = 0;
    LumenEyeTracking.startPointListener("validation");

    LumenEyeTracking.initialiseErrorPassthrough();

    if (
      LumenEyeTracking.brokerType !== BROKER_TYPES.MOUSE &&
      LumenEyeTracking.brokerType !== BROKER_TYPES.MOBILEMOUSE &&
      LumenEyeTracking.brokerType !== BROKER_TYPES.NO_EYETRACKING &&
      LumenEyeTracking.brokerType !== BROKER_TYPES.AUDIBLE
    ) {
      this.startHandler = LumenEyeTracking.broker.trackersController
        .eventManager()
        .subscribe("onValidationStarted", LumenEyeTracking.startPointTimeout);
    }

    this.setupErrorListener(this.onError);

    if (
      LumenEyeTracking.brokerType === BROKER_TYPES.MOUSE ||
      LumenEyeTracking.brokerType === BROKER_TYPES.MOBILEMOUSE ||
      LumenEyeTracking.brokerType === BROKER_TYPES.NO_EYETRACKING ||
      LumenEyeTracking.brokerType === BROKER_TYPES.AUDIBLE
    ) {
      await this.onComplete();
    } else {
      this.element.style.height =
        this.element.parentElement.offsetHeight + "px";
      this.element.style.display = "block";

      LumenEyeTracking.createInstructions(
        this.element,
        "top",
        config.instructions.validate.top()
      );
      LumenEyeTracking.createInstructions(
        this.element,
        "bottom",
        config.instructions.validate.bottom()
      );

      this.element.addEventListener("click", this.continueValidation);
      this.element.addEventListener("touchstart", this.continueValidation);
      window.LRSkipStep = () => {
        this.continueValidation();
        window.LRSkipStep = () => {};
      };
    }
  }
}

export default Validate;
