"use strict";

var countries = require("./data/countries");

var flagUrls = require("./data/flagUrlByIso3");

var _require = require("./lib/api"),
    findFlagUrlByCountryName = _require.findFlagUrlByCountryName,
    findFlagUrlByNationality = _require.findFlagUrlByNationality,
    findFlagUrlByIso2Code = _require.findFlagUrlByIso2Code,
    findFlagUrlByIso3Code = _require.findFlagUrlByIso3Code;

module.exports = {
  countries: countries,
  flagUrls: flagUrls,
  findFlagUrlByCountryName: findFlagUrlByCountryName,
  findFlagUrlByNationality: findFlagUrlByNationality,
  findFlagUrlByIso2Code: findFlagUrlByIso2Code,
  findFlagUrlByIso3Code: findFlagUrlByIso3Code
};