{
    "app.button.faqs": "Vanliga frågor",
    "app.button.terms": "Användarvillkor",
    "app.button.privacy": "Sekretesspolicy",
    "app.button.feedback": "Feedback",
    "app.button.selectLanguage": "Välj språk",
    "app.button.help": "Hjälp",
    "app.button.start": "Start",
    "app.button.next": "Nästa",
    "app.button.submit": "Skicka",
    "app.button.takepart": "OK – jag medverkar",
    "app.menu.feedback.content": "Om du har haft något problem med undersökningen eller bara vill lämna en kommentar kan du göra det här.",
    "app.contactModal.feedback": "Tack för din feedback!",
    "app.takePartModal.line1": "Om du avbryter nu har du inte rätt att få någon belöning för din medverkan.",
    "app.takePartModal.line2": "Det enda du behöver är en webbkamera!",
    "app.takePartModal.reason1": "Jag har ingen webbkamera.",
    "app.takePartModal.reason2": "Jag tycker inte att det känns bra att min webbkamera används för den här undersökningen.",
    "app.takePartModal.reason3": "Jag har tid till det här nu.",
    "app.takePartModal.reason4": "Annan orsak.",
    "app.generic.redirect": "Du kommer snart att omdirigeras.",
    "app.prestart.loading": "Hämtar, vänta.",
    "app.index.title": "Välkommen",
    "app.index.content.line1": "Under den här undersökningen kommer vi att använda din kamera för att försöka beräkna var du tittar. Oroa dig inte, vi tar inga bilder eller videor.",
    "app.index.content.line1alt": "Se till att du sitter bekvämt och tryck sedan på start för att börja.",
    "app.index.content.line2": "Har din enhet en kamera? Tryck i så fall på startknappen nedan.",
    "app.index.rejection": "Tyvärr, det verkar som att du har följt en felaktig länk.",
    "app.index.webview": "Tyvärr, det verkar som att du kommit till vår sida inifrån en webbvy. Öppna den här länken i din webbläsare. Det kan hända att du kan göra det direkt från menyn för webbvy.",
    "app.index.webview.chrome.1": "Tryck på menyknappen ovan och välj sedan ”Öppna i Chrome”.",
    "app.index.webview.chrome.2": "Då öppnas undersökningen i en webbläsarapp, så att den fungerar ordentligt.",
    "app.index.webview.android.1": "Tryck på Start nedan för att öppna undersökningen i en webbläsarapp så att den fungerar ordentligt.",
    "app.index.webview.android.2": "Om det inte fungerar kan du öppna länken till den här sidan i en webbläsarapp manuellt (t.ex. genom att kopiera och klistra in länken i adressfältet i Chrome eller använda alternativet ”Öppna i Chrome” i menyfältet ovan).",
    "app.index.webview.safari.1": "Tryck på Safari-ikonen längst ned på skärmen för att fortsätta.",
    "app.index.webview.safari.2": "Så här ser det ut:",
    "app.index.webview.safari.3": "Då öppnas undersökningen i Safari, så att den fungerar ordentligt.",
    "app.index.wifi": "Det verkar som att din internetanslutning är för långsam. Är du ansluten till ett trådlöst nätverk?",
    "app.index.tellmemore": "Berätta mer.",
    "app.index.takepart": "Jag vill inte medverka.",
    "app.instructions.title": "Instruktioner",
    "app.instructions.instruction1": "Om det är möjligt är det bäst att du inte bär glasögon under undersökningen.",
    "app.instructions.instruction2": "Sitt så att du bekvämt kan hålla huvudet och din enhet stilla.",
    "app.instructions.instruction3": "Rör på ögonen men inte huvudet.",
    "app.instructions.instruction4": "Redo att börja?",
    "app.instructions.instruction5": "Använd wi-fi för att undvika dataavgifter.",
    "app.instructions.instruction6": "Läs följande instruktioner noggrant.",
    "app.instructions.instruction7": "Ställ telefonen på en stabil yta.",
    "app.instructions.instruction8": "Se till att rummet har god belysning – helst uppifrån (inte bakifrån).",
    "app.setup.title": "Förberedelser",
    "app.setup.instruction0": "Ställ enheten i stående läge.",
    "app.setup.instruction0a": "Vrid på enheten så att den är i stående läge.",
    "app.setup.instruction0b": "Vrid på enheten så att kameran är till vänster.",
    "app.setup.instruction0c": "Vrid på enheten så att kameran är till höger.",
    "app.setup.instruction1": "Initierar ...",
    "app.setup.instruction2": "När du ser dig själv på skärmen ska du se till att ditt huvud är inuti rutan.",
    "app.setup.instruction3": "Håll huvudet och enheten i samma läge tills undersökningen är klar.",
    "app.setup.instruction4": "Nu ska vi bara bekräfta.",
    "app.setup.instruction5": "Tack! Kom ihåg att hålla huvudet och enheten i samma läge tills undersökningen är klar.",
    "app.setup.instruction6": "Svep uppåt för att dölja adressfältet.",
    "app.setup.retry": "Ett fel upptäcktes. Håll huvudet och enheten still och håll inte för kameralinsen med exempelvis fingrarna. Använd knappen nedan för att försöka igen.",
    "app.setup.retryInit": "Ett fel upptäcktes. Kontrollera att webbläsaren har åtkomst till din kamera. Om du tidigare har nekat åtkomst för kameran måste du godkänna detta i webbläsarens sekretessinställningar. Använd knappen nedan för att försöka igen.",
    "app.setup.retryButton": "Försök igen",
    "app.setup.calibrationProp.watch": "Följ punkten när den visas på skärmen",
    "app.setup.calibrationProp.watch.george": "När du sedan ser ett ansikte ska du fortsätta att titta på det samtidigt som du imiterar huvudrörelserna",
    "app.setup.calibrationProp.click": "Klicka var som helst för att fortsätta",
    "app.setup.calibrationProp.tap": "Tryck var som helst för att fortsätta",
    "app.study.validationProp.watch": "Följ punkten när den visas på skärmen",
    "app.study.validationProp.restart": "Försök igen",
    "app.study.validationProp.click": "Klicka var som helst för att fortsätta",
    "app.study.validationProp.tap": "Tryck var som helst för att fortsätta",
    "app.study.render.error": "Domänen är ogiltig för återgivningssteget.",
    "app.error.title": "Fel",
    "app.error.generic": "Oj då! Något gick fel.",
    "app.error.initialise": "Det gick inte att initiera!",
    "app.error.findCamera": "Det gick inte att hitta kameran!",
    "app.error.headPosition": "Det gick inte att bestämma huvudets position.",
    "app.error.calibrate": "Det gick inte att kalibrera.",
    "app.error.validate": "Det gick inte att validera.",
    "app.error.start": "Det gick inte att starta.",
    "app.error.assign": "Kunde inte tilldela.",
    "app.error.osnotallowed": "Ditt operativsystem är inte tillåtet. Kontrollera vilken enhet du kan använda för den här undersökningen och försök igen.",
    "app.error.demoquotafullnoactivecell": "Tack för visat intresse. Den här undersökningen är nu stängd för din befolkningsgrupp.",
    "app.invalid.title": "Ogiltig",
    "app.invalid.generic": "Tyvärr, du kan inte delta i den här undersökningen.",
    "app.completion.title": "Slutfört",
    "app.completion.congratulations": "Grattis! Du har genomfört undersökningen. Undersökningens åtkomst till din kamera kommer nu att upphöra.",
    "app.questions.required": "Den här frågan är obligatorisk.",
    "app.faq.question1": "F: Vad är Lumen Study Platform och vad innebär det att medverka i en undersökning för ögonrörelsespårning?",
    "app.faq.answer1": "S: Den här undersökningen innebär bl.a. att vi använder teknik för ögonrörelsespårning. Du blir ombedd att sitta framför enhetens webbkamera eller framåtvända kamera och utföra en kalibrering så att det går att göra en bedömning av var på skärmen du tittar. Under undersökningens gång blir du ombedd att titta på ett antal saker (t.ex. bilder, webbsidor eller videor) medan undersökningsverktyget bedömer var du tittar någonstans. Informationen som samlas in från denna ”ögonrörelsespårning” är koordinaterna för de uppskattade blickpunkterna på skärmen över tid. Programvaran använder bilder av dig från webbkameran för att göra dessa bedömningar men tar inga bilder eller videor av dig. Du kan också bli ombedd att besvara några frågor om dig själv, din erfarenhet och om de saker du har sett under undersökningens gång. Informationen skickas till Lumen Research Ltd. som sedan analyserar dem och delar resultatet med sina kunder.",
    "app.faq.question2": "F: Varför kräver programvaran användning av en webbkamera/kamera och hur används den?",
    "app.faq.answer2": "S: Programvaran använder bilder från webbkameran eller enhetens kamera för att bedöma var på skärmen du tittar. Det gör den genom att använda en kombination av programvara för ansiktsspårning och ögonrörelsespårning som analyserar bilderna lokalt på din enhet i realtid. Bilderna bearbetas och visas för dig under kalibreringen så att du kan sätta dig i rätt ställning, men bilderna registreras inte för att skydda din integritet. Informationen som registreras och samlas in från ögonrörelsespårningen är inte bilder utan numeriska data om de beräknade blickpunkterna i förhållande till de stimuli som visas över tid.",
    "app.faq.question3": "F: Tar ni bilder av mig under ögonrörelsespårningen?",
    "app.faq.answer3": "S: Den enda gång som bilder används är när vi ser till att du sätter dig i rätt ställning under kalibreringen. Bilderna sparas inte och det är bara du som kan se dem. Informationen som registreras och samlas in från ögonrörelsespårningen är inte bilder utan numeriska data om de beräknade blickpunkterna i förhållande till de stimuli som visas över tid.",
    "app.faq.question4": "F: Vilken information samlas in i samband med undersökningen?",
    "app.faq.answer4.line1": "S: Programvaran samlar in en del eller alla av följande fem informationstyper:",
    "app.faq.answer4.line2": "”Användar-ID” – Det användar-ID som används för att identifiera svarspersonen (i syfte att kunna dela ut belöningen till de medverkande)",
    "app.faq.answer4.line3": "”Blickdata” – Beräkningar av var användarna tittar på sina skärmar när de visas stimuli på skärmen",
    "app.faq.answer4.line4": "”Undersökningsdata” – Svar på frågor som besvaras av användarna",
    "app.faq.answer4.line5": "”Metadata” – Information om vid vilken tid personerna medverkade i studien.",
    "app.faq.answer4.line6": "”Information om fysisk enhet” – Information om den dator som en användare använde för att medverka, t.ex. om RAM-minne, processortyp i enheten och användaragenten (operativsystem och webbläsarversion), samt information om datorskärmen (storlek och upplösning), webbkameran (märke och upplösning) och antivirusprogram.",
    "app.faq.question5": "F: Hur används den insamlade informationen?",
    "app.faq.answer5.line1": "S: Den insamlade informationen kommer att användas för att utforska hur personer tittar på och interagerar med olika typer av bilder och media och för att mäta deras åsikter om de bilder de ser.",
    "app.faq.answer5.line2": "Lumen kan dela informationen från undersökningen med närstående företag, affärspartner och kunder. Även om informationen från studien kan delas med närstående företag, affärspartner och kunder sker det anonymt. Sådana närstående företag, affärspartner och kunder får använda informationen för att skapa sammanfattande beskrivningar av ett brett spektrum av konsumentbeteenden och konsumentsegment för att utveckla produkter eller erbjuda marknadsföringslösningar till sina kunder eller konsumenter.",
    "app.faq.answer5.line3": "Lumen samlar in ”Information om fysisk enhet” och ”Metadata” för att lättare hantera insamlingen och bearbetningen av informationen, däribland kvalitetskontroll och volymhantering.",
    "app.faq.answer5.line4": "Informationen som samlas in sparas så länge som krävs för att uppfylla de angivna ändamålen.",
    "app.faq.question6": "F: Hur säker är min information?",
    "app.faq.answer6": "S: Informationen som samlas in av Lumen krypteras via SSL-teknik. Det är den vanligaste höga standarden för krypteringsteknik som används av banker för att garantera säkra banktransaktioner och annan mycket känslig kommunikation. Den används också av Amazon, PayPal, eBay och andra webbplatser som kräver säkerhetssystem på banknivå. Forskare beräknar att det skulle krävas betydligt mycket mer tid än universums ålder för att knäcka 256-bitarskrypteringen med dagens teknik. Den insamlade informationen lagras på säkra webbservrar som hanteras av Amazon Web Services (AWS). AWS är en av de vanligaste och mest pålitliga molntjänstplattformarna och används av stora molnbaserade kommersiella tjänster, såsom Netflix och Expedia.",
    "app.faq.question7": "F: Vilka skyldigheter har du i förhållande till det innehåll som du får se?",
    "app.faq.answer7": "S: Genom att du medverkar förstår och samtycker du också till att det innehåll som visas i den här undersökningen kan vara konfidentiellt, egendom som tillhör en tredje part och/eller föremål för varumärkes- eller upphovsrättslagar. Du får inte heller göra skärmdumpar, registrera eller på något annat sätt kopiera eller distribuera delar av innehållet i undersökningen på något sätt.",
    "app.faq.question8": "F: Jag är osäker på hur bra min webbkamera är. Kommer den att fungera för ögonrörelsespårning?",
    "app.faq.answer8": "S: Systemet fungerar med en mängd olika webbkameror, så det borde inte vara några problem.",
    "app.faq.question9": "F: Jag har genomfört undersökningen, är det något annat jag ska göra?",
    "app.faq.answer9": "S: Nej, du behöver inte göra något annat. Programvaran stängs av automatiskt.",
    "app.faq.question10": "F: Måste jag vara ansluten till internet för att kunna medverka?",
    "app.faq.answer10": "S: Ja, du måste ha en internetanslutning för att kunna hämta programmet och genomföra uppgiften.",
    "app.faq.question11": "F: Måste jag genomföra uppgiften på en stabil yta?",
    "app.faq.answer11": "S: Ja, för bästa resultat bör du sitta bekvämt framför datorskärmen och, när kalibreringen väl är gjord, ska du hålla huvudet stilla medan de olika stimuli visas för dig, tills vi säger till. Vi rekommenderar att du inte medverkar om du sitter på ett tåg eller har din laptop i knät osv.",
    "app.faq.question12": "F: Svaren i Vanliga frågor har inte löst mitt problem, vad gör jag nu?",
    "app.faq.answer12": "S: Skicka ett mejl till oss på study-feedback@lumen-research.com, så återkommer vi så snabbt vi kan.",
    "app.faq.patent": "Lumens testning av webbinnehåll genomförs med hjälp av vår egenutvecklade teknik för sammanhangsbaserad testning (brittiskt patentansökningsnummer 1816158.8)",
    "app.terms.line1": "Dessa villkor (”villkor”) styr avtalet mellan dig och Lumen Research Ltd (”Lumen” eller ”vi”), ett företag som är registrerat i England, med företagsnummer 8682432, med registrerat kontor på Unit 215, 22 Highbury Grove, London N5 2EF, Storbritannien, och registreringsreferens för dataskydd ZA130772, beträffande din användning av programvaran för ögonrörelsespårning ”ViewPoints” (”programvaran”) på din enhet.",
    "app.terms.line2": "1. Godkännande av villkoren",
    "app.terms.line3": "Genom att använda programvaran medger du att du har godkänt att vara bunden av detta avtal. Om du inte godkänner villkoren i detta avtal ska du inte genomföra ViewPoints-undersökningen.",
    "app.terms.line4": "2. Konfidentiell information",
    "app.terms.line5": "När du installerar, använder eller på något annat sätt interagerar med ViewPoints kan du få åtkomst till information och/eller innehåll som tillhör eller är relaterat till Lumen, däribland information som identifieras eller behandlas som konfidentiell av Lumen eller någon av Lumens klienter eller kunder eller som, till följd av sin karaktär eller omständigheterna eller sättet som den delas på uppenbarligen är konfidentiell, såsom (utan att det påverkar föregående allmänna påstående), all eventuell information om affärsplaner, mognande nya affärsmöjligheter, forsknings- och utvecklingsprojekt, design, hemliga processer, koder, programvarudesign, affärshemligheter, utveckling av produkter eller tjänster och formler, know-how, uppfinningar, statistik och prognoser om försäljning, strategier och planer för marknadsföring, kostnader, vinst och förlust och annan finansiell information (förutom i den utsträckning som sådan information har publicerats i reviderade årsredovisningar), pris- och rabattstrukturer (beroende på om det finns angivet i skrift, antingen elektroniskt eller i något annat format) (”konfidentiell information”). Sådan konfidentiell information ska förbli Lumens och/eller dess kunders egendom, och du får under alla omständigheter ingen rätt till och/eller något intresse i sådan konfidentiell information.",
    "app.terms.line6": "Du får inte, antingen medan du medverkar i ViewPoints eller vid någon annan tidpunkt (tidsmässigt obegränsad) efter att en sådan medverkan har avslutats: avslöja eller förmedla till någon, använda för dina egna syften eller, p.g.a. bristande vederbörlig omsorg och noggrannhet, orsaka något obehörigt avslöjande av konfidentiell information. Du måste alltid anstränga dig till ditt yttersta för att förhindra publicering eller avslöjande av all eventuell konfidentiell information. Dessa begränsningar ska upphöra att gälla för all information som ska bli tillgänglig för allmänheten på annat sätt än genom ditt fel.",
    "app.terms.line7": "3. Lumens egendom",
    "app.terms.line8": "Programvaran ViewPoints och alla data, koder, nycklar, lösenord, design, ritningar, poster och andra dokument eller material som har att göra med programvaran ska vara och förbli Lumens egendom så länge du medverkar i en ViewPoints-undersökning. Lumen äger all immateriell eller annan äganderätt till alla befintliga och framtida immateriella eller industriella äganderättigheter i och till alla dokument, material, modeller, design, ritningar, processer, uppfinningar, formler, datakoder, data, systemgränssnitt, metoder, know-how, konfidentiell information eller annat arbete, som har utförts, tillverkats, skapats, utarbetats, utvecklats, dekonstruerats eller upptäckts av dig, antingen själv eller tillsammans med någon annan person, i samband med eller avseende på programvaran och/enheten eller som kan användas eller anpassas för att användas däri eller i samband med den (såväl registrerade som oregistrerade), däribland alla befintliga och framtida patent, upphovsrättigheter, designrättigheter, databasrättigheter, varumärken, topografirättigheter till halvledare, växtförädlarrätt, interneträttigheter/domännamn, know-how och alla eventuella tillämpningar av allt som tidigare har räknats upp och alla eventuella rättigheter att ansöka om något av allt det som tidigare har räknats upp (”äganderätt”). Ingen äganderätt ska överföras till dig vid någon tidpunkt.",
    "app.terms.line9": "4. Villkor och begränsningar för användning",
    "app.terms.line10": "Programvaran ViewPoints får användas av slutanvändarna på licens. I enlighet med detta avtal godkänner vi härmed att du tillåts, på en icke-exklusiv, återkallelig, icke-överförbar, icke-underlicensierbar, begränsad basis använda programvaran ViewPoints på en enhet som du äger eller kontrollerar eller en dator, beträffande vilken du garanterar att du har alla nödvändiga rättigheter och tillstånd att använda för en sådan installation. För att undvika missförstånd får du inte, utanför ViewPoints, i något syfte använda, översätta, kopiera, reproducera, överföra via telekommunikation, anpassa, slå samman eller ändra programvaran på något sätt.",
    "app.terms.line11": "Du godkänner att inte: (i) kopiera, ändra, anpassa, ställa till förfogande, hyra, leasa, tidsdela, skapa ett härlett verk av eller försöka sälja eller på annat sätt överföra någon rättighet till programvaran (ii) dekonstruera, dekompilera eller baklängesutveckla några delar av programvara på något sätt; inte heller tillåta eller hjälpa någon annan part att härleda eller försöka härleda källkoden för, dekonstruera, dekryptera, dekompilera eller baklängesutveckla programvaran; inte heller vidta några andra åtgärder för att få designinformation om programvaran; (iii) använda programvaran för något annat ändamål än det som den ursprungligen distribuerades till dig för; (iv) använda spindlar, robotar eller andra automatiska datautvinningstekniker för att katalogisera, ladda ner, spara eller på annat sätt reproducera eller distribuera programvaran, eller för att manipulera resultaten från en undersökning eller annan aktivitet; (v) vidta eventuella åtgärder för att störa programvaran eller en persons användning av programvaran inklusive, utan begränsning, genom överbelastning, ”översvämning”, ”mejlbombning” eller att ”krascha” programvaran och datalagringssystemet som programvaran använder via Amazon Web Services (vi) skicka eventuella olagliga, vilseledande eller skadliga koder till oss, inklusive, utan begränsning, virus, spionprogram, annonsprogram eller annan skadlig kod.",
    "app.terms.line12": "Alla rättigheter som inte uttryckligen beviljas dig nedan är förbehållna oss och våra licensgivare. Du samtycker till att vi inte har någon skyldighet enligt detta avtal eller på annat sätt att rätta till buggar, defekter eller fel i programvaran eller på annat sätt ge support för, underhålla, förbättra, modifiera, uppgradera, uppdatera eller förbättra programvaran. Vi är inte ansvariga för att ge dig eller dina utsedda användare någon hjälp vid användningen av programvaran på något sätt.",
    "app.terms.line13": "Vi ansvarar inte för skador på din dator, inklusive men inte begränsat till, till följd av din användning av programvaran. Vi ansvarar inte för eventuella ytterligare datakostnader som du ådrar dig till följd av data som samlats in av programvaran genom din medverkan i studien.",
    "app.terms.line14": "5. Ansvarsfriskrivning",
    "app.terms.line15": "Vi och våra respektive direktörer, tjänstepersoner, anställda, ombud, representanter, licensgivare och leverantörer utfärdar ingen garanti eller villkor (a) att programvaran kommer att vara tillgänglig i tid, eller att åtkomsten till programvaran kommer att vara oavbruten eller säker (b) att defekter eller fel kommer att korrigeras eller (c) att programvaran eller servrarna eller nätverken genom vilka programvaran och enheten görs tillgängliga är säkra eller fria från virus eller andra skadliga komponenter. Vi har ingen skyldighet att tillhandahålla underhålls- och/eller supporttjänster med avseende på programvaran.",
    "app.terms.line16": "6. Ansvarsbegränsning",
    "app.terms.line17": "(a) I den utsträckning som tillåts enligt tillämplig lag godkänner du att vi och våra respektive direktörer, tjänstepersoner, anställda, ombud, representanter, licensgivare och leverantörer inte har något ansvar för några som helst förluster, skador eller kostnader av något slag som härrör från din installation, användning eller åtkomst till programvaran, din användning av eller oförmåga att använda programvaran, all information eller material som görs tillgängligt på eller via programvaran och/eller som på annat sätt hänför sig till programvaran eller på annat sätt till detta avtal, oavsett art av den lagliga, verkliga eller lagstadgade rättigheten som påstås ha kränkts.",
    "app.terms.line18": "(b) I den utsträckning som tillåts enligt tillämplig lag är det enda du kan göra vid missnöje med programvaran att sluta använda den.",
    "app.terms.line19": "(c) Under inga omständigheter ska något av våra företag, direktörer, tjänstepersoner, anställda, ombud eller representanter ställas till svars av dig för eventuella tillfälliga, indirekta skador, följdskador, lagstadgade, exemplariska, straffskadestånd eller särskilda skador, inkomstbortfall, förlust av möjligheter, förlust av rykte, förlust av användning, förlust av data, förlust av andra immateriella tillgångar eller obehörig avlyssning av data som samlats in av programvaran av tredje part, oavsett om det är förutsebart eller inte, oavsett hur de har uppstått, och oavsett om de är avtalsrättsliga, icke-avtalsrättsliga (inklusive för eventuell vårdslöshet), objektivt ansvar eller någon annan doktrin, även om de har informerats i förväg om möjligheten att sådana skador kan uppstå.",
    "demo.index.content.line1": "Under den här demonstrationen kommer vi att använda din kamera för att försöka beräkna var du tittar. Oroa dig inte, vi tar inga bilder eller videor.",
    "app.thankyou": "Tack",
  "app.button.close": "Stäng",
  "app.button.return": "Tillbaka",
  "app.init.welcome": "Välkommen till Lumen Research studieplattform",
  "app.init.intro": "Studieplattformen introducerar och tilldelar användare till en annons studie.", 
  "app.init.message1": "Beroende på vilken typ av studie kommer användaren ledas genom olika uppgifter.",
  "app.init.message2": "Dessa uppgifter inkluderar; göra en undersökning, läsa igenom instruktioner och kalibrering av ögonläsaren",
  "app.init.message3": "Vi kommer nu gå igenom en testundersökning.",
  "app.init.message4": "Genom hela undersökningen kan vi använda din webbkamera för att spåra dina ögon, men ingen data kommer att lagras.",
  "app.init.message5": "Den aktuella undersökningen är inställd för att använda följande länkar vid slutförandet. ",
  "app.index.webcamRetry": "Vi kunde inte hitta en webbkamera, vilket krävs för att fullfölja undersökningen. Var god ge tillgång till din webbkamera och försök igen.",
  "app.index.adblockRetry": "Ops! Vi har upptäckt en adblocker. För bästa resultat var god stäng av den och försök igen.",
  "app.index.zoomRetry": "Vi kräver att webbläsaren är inställd på 100% zoom, var god zooma in/ut och försök igen",
  "app.index.fullscreendesktopretry": "Denna undersökning kräver att webbläsarens fönster är på helskärm, var god säkerställ att fönstret är maximerat",
  "app.index.fullscreendesktoptest": "Denna undersökningen kräver att webbläsarens fönster är på helskärm, vi måste genomföra ett test för att säkerställa att fönstret är maximerat",
  "app.index.fullscreendesktoptest2": "Två knappar dyker snart upp, var god tryck på dem för att fortsätta",
  "app.index.fullscreendesktopmaximised": "Min skärm är redan maximerad",
  "app.index.fullscreendesktopskip": "Tack, vi har sparat denna händelse och kommer förbättra vårt program för att läsa av",
  "app.index.fullscreendesktopreset": "Vi har upptäckt en förändring i storlek på fönstret, återställer testet.",
  "app.index.gotomobile": "Datoranvändare är inte kvalificerade för att genomföra studien, var god använda QR-koden nedan för att öppna studien på en mobil.",
  "app.index.returntopanel": "ELLER, tryck på knappen nedan för att återvända till panelen",
  "app.setup.instruction0d": "Var god rotera din enhet till landskapsvy.",
  "app.setup.instructioncamera": "Var god tryck på knappen nedan för att begära åtkomst till kameran. För att genomföra undersökningen krävs att vi har tillgång till kameran genom hela undersökningen.",
  "app.setup.cameraText": "Begär Tillgång",
  "app.setup.retryPositioning": "Vi kunde inte hitta positionen av ditt huvud. Var god håll ditt huvud och enhet stilla och täck inte kameran med t.ex. fingrar. Använd knappen nedan för att försöka igen.",
  "app.setup.retryBroker": "Vi kunde inte genomföra kalibrerings processen. Var god försök stäng andra appar som du inte använder",
  "app.error.missingpanelparameter": "Saknar panel parameter.",
  "app.completion.congratulations-noet": "Grattis! Du har genomfört studien.",
  "app.error.previousfail": "Du har tidigare misslyckats med denna studien, var god använda original länken för att börja om.",
  "app.error.previoussuccess": "Du har redan genomfört denna studien.",
  "app.error.route": "Du har försökt starta en studie som redan pågår, var god använda original länken för att börja om.",
  "app.error.accesscode": "Du har försökt starta en studie som inte existerar, var god kontrollera länken.",
  "app.error.webcam": "Denna studie kräver tillgång till en webbkamera",
  "app.error.adblocker": "Denna studie kräver att adblock är avstängd.",
  "app.error.startsession": "Kan inte starta sessionen",
  "app.error.fullscreen": "Denna studie kräver att ditt fönster är maximerat.",
  "app.error.zoom": "Denna studie kräver att webbläsarens zoom är inställd på 100%.",
  "app.index.webcamInstructions": "Vi kommer begära tillgång till din webbkamera, om du får en notifikation var god tryck på tillåt.",
  "app.setup.retryBack": "Var god tryck inte på tillbaka knappen i din webbläsare under studien, det kan resultera i förlust av belöning. Använd knappen nedan för att börja om.",
  "app.setup.retryaudible": "Var god kontrollera att din enhet inte är tystad och försök igen."
  }