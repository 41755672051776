import logInfo from "./logInfo";
import logUserInteraction from "./logUserInteraction";
import logWait from "./logWait";
import logNavigate from "./logNavigate";
import logSubscribe from "./logSubscribe";
import logRevoke from "./logRevoke";
import logActionCancelled from "./logActionCancelled";
import logRouteError from "./logRouteError";
import logPageVisit from "./logPageVisit";
import logReturnedToPlatform from "./logReturnedToPlatform";
import logPreviousFail from "./logPreviousFail";
import logLongLoad from "./logLongLoad";
import logBackButton from "./logBackButton";
import logSetupProgress from "./logSetupProgress";
import logCheckStarted from "./logCheckStarted";
import logSurveyLoaded from "./logSurveyLoaded";
import logStepComplete from "./logStepComplete";
import logStepVisit from "./logStepVisit";
import logCheckFail from "./logCheckFail";
import logStepChain from "./logStepChain";

export default {
  logInfo,
  logUserInteraction,
  logWait,
  logNavigate,
  logSubscribe,
  logRevoke,
  logActionCancelled,
  logRouteError,
  logPageVisit,
  logReturnedToPlatform,
  logPreviousFail,
  logLongLoad,
  logBackButton, 
  logCheckStarted,
  logSetupProgress,
  logSurveyLoaded,
  logStepComplete,
  logStepVisit,
  logCheckFail,
  logStepChain
};
