import { routeType } from "../components/util/util";
import {
  changeHeaderFooterState,
  ChangeLoadingOverlayState,
  completePage,
  showNextPage,
  startPage,
} from "../reducers/PageState/actions";
import { store } from "../store";
import LoggerController from "./Logger/LoggerController";

class PageController {
  constructor() {
    this.beforeEnd = null;
    this.next = null;

    this.start = this.start.bind(this);
    this.showNext = this.showNext.bind(this);
    this.complete = this.complete.bind(this);
    this.bindBeforeEnd = this.bindBeforeEnd.bind(this);
    this.clearBeforeEnd = this.clearBeforeEnd.bind(this);
    this.loadingOverlay = this.loadingOverlay.bind(this);
    this.hideHeaderFooter = this.hideHeaderFooter.bind(this);
    this.dropOverlay = this.dropOverlay.bind(this);
    this.showOverlay = this.showOverlay.bind(this);
  }

  start = () => {
    this.next = null;
    let page = routeType();
    LoggerController.events.logPageVisit.panelistAction(page, { page });
    store.dispatch(startPage(routeType()));
  };

  showNext = (func) => {
    if (!!func) {
      this.next = func;
      store.dispatch(showNextPage());
    }
  };

  complete = async (routeOut, ...args) => {
    if (!!this.beforeEnd) {
      await this.beforeEnd(...args);
    }

    this.clearBeforeEnd();

    store.dispatch(completePage(routeType(), routeOut));

    return routeOut;
  };

  moveNext = async () => {
    if (!this.next) {
      return await this.complete();
    } else {
      this.next();
      return false;
    }
  };

  bindBeforeEnd = (func) => {
    this.beforeEnd = func;
  };

  clearBeforeEnd = () => {
    this.beforeEnd = null;
  };

  loadingOverlay = (state, hideLoading) => {
    store.dispatch(ChangeLoadingOverlayState(state, hideLoading));
    let count = 0;

    return new Promise((resolve) => {
      const checkOverlayState = () => {
        let { overlayState } = store.getState().pageReducer;

        if (count === 4) {
          return resolve();
        } else if(state === "transitionOut" && overlayState === "hide") {
          return resolve();
        } else if (state === "transitionIn" && overlayState === "show") {
          return resolve();
        } else {
          count+=1
          setTimeout(checkOverlayState, 100);
        }
      };

      checkOverlayState();
    });
  };

  hideHeaderFooter = (which) => {
    let { headerState } = store.getState().pageReducer;

    if (
      (which === true && headerState === "show") ||
      (which === false && headerState === "hide")
    ) {
      if (which === true && headerState === "show") {
        store.dispatch(changeHeaderFooterState("transitionOut"));
      } else if (which === false && headerState === "hide") {
        store.dispatch(changeHeaderFooterState("transitionIn"));
      }

      return new Promise((resolve) => {
        const checkHeaderState = () => {
          let { headerState } = store.getState().pageReducer;

          if (which === true && headerState === "hide") {
            resolve();
          } else if (which === false && headerState === "show") {
            resolve();
          }

          setTimeout(checkHeaderState, 100);
        };

        checkHeaderState();
      });
    }

    return true;
  };

  dropOverlay = async (fullscreen) => {

    let {overlayState, headerState} = store.getState().pageReducer

    if(fullscreen && headerState === 'show' && overlayState === 'hide'){
      await this.loadingOverlay('transitionIn')
    }

    await this.hideHeaderFooter(fullscreen)

    await this.loadingOverlay('transitionOut')
    
  }

  showOverlay = async (fullscreen, hideLoading) => {

    await this.loadingOverlay('transitionIn', hideLoading)

    await this.hideHeaderFooter(fullscreen)

  }
}

export default new PageController();
