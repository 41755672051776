import * as DEBUGLOG from "../../../components/util/debugLog";
import requestTimer from "../../requestTimer";
import tabChangeListener from "../../../utils/listeners/TabChangeListener";
import participationUrl from "../participationUrl";

const start = async (studyId, body) => {
    tabChangeListener.setWaitingForApi("start");
    let startRequestDate = DEBUGLOG.debugLogRequestStart("start");
    let response = await fetch(participationUrl() + "/participation/studies/" + studyId + "/start", {
        method: 'POST',
        body: JSON.stringify(body)
    })
    DEBUGLOG.debugLogRequestFinish(startRequestDate);

    if (response.ok) {
        let responseBody = await response.json()
        tabChangeListener.setWaitingForApi("");
        return responseBody
    } else {
      let e = new Error(response.statusText);
      e.name = response.status || "";
      throw e;
    }
}

export default (...args) => requestTimer(() => start(...args))