import * as actions from "./action-types";

export const updateDimensions = (payload) => {
    return {
        type: actions.UPDATE_DEVICE_DIMENSIONS,
        payload,
      };
}

export const updateDetails = (payload) => {
    return {
        type: actions.UPDATE_DEVICE_DETAILS,
        payload
    }
}

export const updateConnectionSpeed = (payload) => {
    return {
        type: actions.UPDATE_CONNECTION_SPEED,
        payload
    }
}