import { store } from "../../../../store";
import Endpoint from "../../Endpoint";

const getPayload = (stepId, stepOrderId, startTime, validationData) => {
    let {participant, participantId, studyId, cellId, sessionId, newTrackingSystem} = store.getState().studyReducer

    let body = {
        panelist: participant,
        panelist_id: participantId,
        study_id: studyId,
        cell_id: cellId,
        step_id: stepId,
        steporder_id: stepOrderId,
        timestamp: startTime,
        zoom: Math.round(window.devicePixelRatio * 100),
        browser_width: window.outerWidth,
        browser_height: window.outerHeight,
        browser_left: window.screenX,
        browser_top: window.screenY,
        viewport_width:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
        viewport_height:
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight,
        screen_resolution_width: window.screen.width,
        screen_resolution_height: window.screen.height,
        ...validationData
      };

      if (newTrackingSystem) {
        body.tmp_session_id = sessionId;
      } else {
        body.session = sessionId;
      }

      return body
}

const handleResponse = (response) => {
  if("id" in response){
    response.success = true
  } 

  return response
}

export default (stepId, stepOrderId, startTime, validationData) => {
    if (store.getState().studyReducer.newTrackingSystem) {
      return new Endpoint(
        "validation-result",
        `${process.env.REACT_APP_NEW_TRACKING_URL}/ctt-validation-result/`,
        "POST",
        () => getPayload(stepId, stepOrderId, startTime, validationData),
        handleResponse,
        true
      );
    }
  
    return new Endpoint(
      "validation-result",
      `${process.env.REACT_APP_NEW_TRACKING_URL}/new-validation-result/`,
      "POST",
      () => getPayload(stepId, stepOrderId, startTime, validationData),
      handleResponse,
      true
    );
  };
  