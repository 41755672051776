const initialState = {
  headerState: "show",
  footerState: "show",
  footerType: "index",
  overlayState: "show",
  hideLoading: false,
  pageShowNext:false,
  pageCompleted: false,
  pageRouteOut: "",
  currentPage: "",
  pageCompletes: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "START_PAGE":
      return { ...state, currentPage: action.payload.page, footerType: action.payload.footerType, pageCompleted: false, pageShowNext:false};
    case "PAGE_SHOW_NEXT":
      return {...state, pageShowNext: true}
    case "COMPLETE_PAGE":
      return {
        ...state,
        pageCompletes: {
          ...state.pageCompletes,
          [action.payload.page]: new Date().getTime(),
        },
        pageCompleted: true,
        pageRouteOut: action.payload.routeOut,
        pageShowNext:false
      };
    case "CHANGE_HEADER_FOOTER_STATE":
      if (state.headerState === "hide" && action.payload === "transitionIn") {
        return {
          ...state,
          headerState: action.payload,
          footerState: action.payload,
          // overlayState: "transitionIn", 
        };
      } else if (
        state.headerState === "show" &&
        action.payload === "transitionOut"
      ) {
        return {
          ...state,
          headerState: action.payload,
          footerState: action.payload,
          // overlayState: "transitionIn",
        };
      } else if (
        state.headerState === "transitionIn" &&
        action.payload === "show"
      ) {
        return {
          ...state,
          headerState: action.payload,
          footerState: action.payload,
          // overlayState: "transitionOut",
        };
      } else if (
        state.headerState === "transitionOut" &&
        action.payload === "hide"
      ) {
        return {
          ...state,
          headerState: action.payload,
          footerState: action.payload,
          // overlayState: "transitionOut",
        };
      } else {
        return state
      }
    case "CHANGE_LOADING_OVERLAY_STATE":
      if(state.overlayState === "hide" && action.payload.state === "transitionIn" ){
        return {
          ...state,
          overlayState: "transitionIn",
          hideLoading: action.payload.hideLoading
        }
      } else if (state.overlayState === "show" && action.payload.state === "transitionOut"){
        return {
          ...state,
          overlayState: "transitionOut",
        }
      } else if (state.overlayState === "transitionIn" && action.payload.state === "show"){
        return {
          ...state,
          overlayState: "show",
        }
      } else if (state.overlayState === "transitionOut" && action.payload.state === "hide"){
        return {
          ...state,
          overlayState: "hide",
        }
      } else {
        return {
          ...state
        }
      }
    default:
      return state;
  }
}
