import api from "../../api";
import LoggerController from "../../controllers/Logger/LoggerController";
import { INIT_STEPDATA } from "../../reducers/StepState/action-types";
import DeviceController from "../../utils/controllers/DeviceController";
import sendDataToCrawler from "../util/sendDataToCrawler";
import { dedupeByKey } from "../util/util";
import * as actions from "./action-types";

export function initParams(studyId, accessCode) {
  let payload = { studyId, accessCode, originalLink: window.location.href };

  return {
    type: actions.INIT_PARAMS,
    payload,
  };
}

export function initURLParams() {
  let payload = {};
  let search = window.location.search;

  if (search.includes("redirect_override=")) {
    let searches = [];
    let redirectUrl = "";

    if (search.includes("?redirect_override=")) {
      searches = search.split("?redirect_override=");
      search = "";
      redirectUrl = searches[1];
    } else {
      searches = search.split("&redirect_override=");
      search = searches[0];
      redirectUrl = searches[1];
    }

    payload["redirectOverride"] = redirectUrl;
  }

  const urlParams = new URLSearchParams(search);

  payload["participant"] = urlParams.get("participant");
  payload["showGaze"] = urlParams.get("show_gaze") === "1" ? true : false;
  payload["panelExtra1"] = urlParams.get("panel_extra1");
  payload["cellId"] = urlParams.get("cell_id");
  payload["panel"] = urlParams.get("panel");

  //when test=2 we skip the splash pages but still count the study as a test (exclude data)
  payload["testType"] = urlParams.get("test");
  payload["test"] =
    urlParams.get("test") === "1" ||
    urlParams.get("test") === "2" ||
    urlParams.get("test") === "3" ||
    urlParams.get("test") === "4"
      ? true
      : false;
  // Show "skip survey" for test types 1 & 3
  payload["showTestUserPage"] = (
    urlParams.get("test") === "1" || 
    urlParams.get("test") === "3"
  ) ? 1 : 0;

  return {
    type: actions.INIT_URL_PARAMS,
    payload,
  };
}

export function incrementStudyStep() {
  return {
    type: actions.INCREMENT_STUDY_STEP,
  };
}

export function sessionIdUpdate(payload) {
  return {
    type: actions.SESSION_ID_UPDATE,
    payload: payload,
  };
}

export function noPanelStudyUpdate(payload) {
  return {
    type: actions.NO_PANEL_STUDY_UPDATE,
    payload: payload,
  };
}

export function trackingStartTime(payload) {
  return {
    type: actions.TRACKING_START_TIME,
    payload: payload,
  };
}

export function showStimulusQuestion() {
  return {
    type: actions.SHOW_STIMULUS_QUESTION,
  };
}

export function hideStimulusQuestion() {
  return {
    type: actions.HIDE_STIMULUS_QUESTION,
  };
}

export function updateInternalDemographicsQuestions(payload) {
  return {
    type: actions.UPDATE_INTERNAL_DEMOGRAPHICS_QUESTIONS,
    payload: payload,
  };
}

export function updateInternalDemographicsResponses(payload) {
  return {
    type: actions.UPDATE_INTERNAL_DEMOGRAPHICS_RESPONSES,
    payload: payload,
  };
}

export function calibrationInfo(payload) {
  return {
    type: actions.CALIBRATION_INFO,
    payload: payload,
  };
}

export function updateStepData(payload) {
  return {
    type: actions.UPDATE_STEP_DATA,
    payload: payload,
  };
}

export function regionCheck(testType) {
  return async function startRegionCheck(dispatch, getState) {
    let regionList = [
      { code: "ap", name: "ap-northeast-1" },
      { code: "sa", name: "sa-east-1" },
      { code: "sp", name: "ap-southeast-1" },
      { code: "us", name: "us-east-1" },
      { code: "us2", name: "us-west-2" },
      { code: "eu", name: "eu-west-2" },
    ];

    let promises = regionList.map((region) => api.misc.regionCheck(region));

    let actual_region = await api.misc.actualRegion();

    if (testType === "all") {
      let results = await Promise.all(promises);
      let min_response = Math.min(
        ...results.map((result) => result.timeTaken_ms)
      );
      let min_region = results.filter(
        (result) => result.timeTaken_ms === min_response
      )[0];
      let payload = {
        routedRegion: actual_region.region,
        fastestRegion: min_region.name,
        data: results,
      };
      dispatch({ type: actions.REGION_CHECK, payload });
      return payload;
    } else {
      let result = await Promise.race(promises);
      let payload = {
        routedRegion: actual_region.region,
        fastestRegion: result.name,
        data: result,
      };
      dispatch({ type: actions.REGION_CHECK, payload });
      return payload;
    }
  };
}

export function startPanel() {
  return async function startPanelThunk(dispatch, getState) {
    let urlParams = new URLSearchParams(window.location.search);
    let {
      studyId,
      participant,
      panelExtra1,
      cellId,
      accessCode,
      showGaze,
      testUserBool,
      routedRegion,
      fastestRegion,
      panel,
      testType,
    } = getState().studyReducer;

    let deviceInfo = DeviceController.getDeviceInfo();
    let deviceDimensions = DeviceController.getDimensions();

    let body = {
      access_code: accessCode,
      panel: panel,
      participant: participant,
      test: testUserBool,
      device: deviceInfo.device,
      os: deviceInfo.osString.toLowerCase(),
      os_version: deviceInfo.os.version,
      browser: deviceInfo.name.toLowerCase(),
      browser_version: deviceInfo.version,
      is_mobile: deviceInfo.isMobile,
      is_touch_capable: deviceInfo.isMobile,
      device_model: deviceInfo.device,
      routedRegion: routedRegion,
      fastestRegion: fastestRegion,
      graphics: deviceInfo.graphics,
      screen_resolution_width: deviceDimensions.screen_resolution_width,
      screen_resolution_height: deviceDimensions.screen_resolution_height,
      browser_width: deviceDimensions.browser_width,
      browser_height: deviceDimensions.browser_height,
      browser_left: deviceDimensions.browser_left,
      browser_top: deviceDimensions.browser_top,
      viewport_width: deviceDimensions.viewport_width,
      viewport_height: deviceDimensions.viewport_height,
      browser_zoom: deviceDimensions.browser_zoom,
      connection_speed: deviceInfo.connectionType,
      connection_speed_absolute: deviceInfo.connectionSpeed,
    };

    let response = await api.admin.startPanel(studyId, body);

    if (response.success && participant != "") {
      // setup panel redirects
      let successRedirectUrl = response.success_redirect_url;
      if (!!panelExtra1) {
        successRedirectUrl = successRedirectUrl.replace(
          "redirect_url_extra1_insert_parameter",
          panelExtra1
        );
      }
      let screenoutRedirectUrl = response.screenout_redirect_url;
      if (!!panelExtra1) {
        screenoutRedirectUrl = screenoutRedirectUrl.replace(
          "redirect_url_extra1_insert_parameter",
          panelExtra1
        );
      }
      let quotaFullRedirectUrl = response.quota_full_redirect_url;
      if (!!panelExtra1) {
        quotaFullRedirectUrl = quotaFullRedirectUrl.replace(
          "redirect_url_extra1_insert_parameter",
          panelExtra1
        );
      }

      let passThroughVariables = {};
      response.pass_through_variables.forEach((v) => {
        passThroughVariables[v] = urlParams.get(v);
      });

      var panelPayload = {
        panelCompanyCode: response.panel_company_code,
        successRedirectUrl: successRedirectUrl,
        screenoutRedirectUrl: screenoutRedirectUrl,
        quotaFullRedirectUrl: quotaFullRedirectUrl,
        redirectUrlMemberInsertParameter:
          response.redirect_url_member_insert_parameter,
        passThroughVariables: passThroughVariables,
        studyId: response.study_id,
        studyName: response.study_name,
        secondsBeforeMoveNext: response.seconds_before_move_next,
        eyetrackingEnabled: response.eyetracking_enabled,
        adblockcheckEnabled: response.adblockcheck_enabled,
        accessCode,
        cellId,
        participant,
        showGaze: !!showGaze,
        test: !!testUserBool,
        routedRegion: routedRegion,
        fastestRegion: fastestRegion,
        cameraCheckEnabled: response.cameracheck_enabled,
        screenAdblock: response.screen_adblock_users,
        screenNoCamera: response.screen_nocamera_users,
        showSplashPage: response.show_splash_page,
        showInstructionPage: response.show_instructions_page,
        screenLowConnectionSpeed: response.screen_low_connection_speed,
        lowConnectionSpeed: response.low_connection_speed,
        screenLowCalibrationFrameRate:
          response.screen_low_calibration_frame_rate,
        lowCalibrationFrameRate: response.low_calibration_frame_rate,
        screenLowViewportWidth: response.screen_low_viewport_width,
        lowViewportWidth: response.low_viewport_width,
        screenLowViewportHeight: response.screen_low_viewport_height,
        lowViewportHeight: response.low_viewport_height,
        require_fullscreen: response.require_fullscreen,
        screenLowScreenWidth: response.screen_low_screen_resolution_width,
        lowScreenWidth: response.low_screen_resolution_width,
        screenLowScreenHeight: response.screen_low_screen_resolution_height,
        lowScreenHeight: response.low_screen_resolution_height,
        newTrackingSystem: response.new_tracking_system,
        showEyetrackingInstructionsOverride:
          response.show_eyetracking_instructions_override,
      };

      if (response.enable_extended_logger) {
        LoggerController.initExtendedLogger(
          participant,
          deviceInfo.isMobile ? "mobile" : "desktop",
          deviceInfo.os,
          deviceInfo.name.toLowerCase(),
          studyId
        );
      }

      if (testType === "4" && response.eyetracking_enabled) {
        dispatch({ type: actions.SET_DUMMY_EYETRACKING, payload: true });
      }

      if (testType === "3" || testType === "4") {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        sendDataToCrawler("StudySetup", {
          eyetrackingEnabled: response.eyetracking_enabled,
          showSplashPage: response.show_splash_page,
          showInstructionPage: response.show_instructions_page,
          checks: [
            {
              name: "Connection Speed Check",
              enabled: response.low_connection_speed > 0,
              page: "start",
            },
            {
              name: "Webcam Check",
              enabled: response.cameracheck_enabled,
              page: "start",
            },
            {
              name: "Adblock Check",
              enabled: response.adblockcheck_enabled,
              page: "start",
            },
            {
              name: "Desktop Fullscreen Check",
              enabled: response.require_fullscreen && !deviceInfo.isMobile,
              page: "start",
            },
            {
              name: "Mobile Fullscreen Check",
              enabled: response.require_fullscreen && deviceInfo.isMobile,
              page: "start",
            },
            {
              name: "Mobile Zoom Check",
              enabled: response.require_fullscreen && deviceInfo.isMobile,
              page: "start",
            },
            {
              name: "Viewport Width Check",
              enabled: response.low_viewport_width > 0,
              page: "start",
            },
            {
              name: "Viewport Height Check",
              enabled: response.low_viewport_height > 0,
              page: "start",
            },
            {
              name: "Screen Width Check",
              enabled: response.low_screen_resolution_width > 0,
              page: "prestart",
            },
            {
              name: "Screen Height Check",
              enabled: response.low_screen_resolution_height > 0,
              page: "start",
            },
          ],
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      dispatch({ type: actions.START_PANEL, payload: panelPayload });
    }

    return response;
  };
}

export const webcamResponse = (result) => {
  return { type: actions.SET_HASWEBCAM, payload: result };
};

export const adblockResponse = (result) => {
  return { type: actions.SET_ADBLOCK, payload: result };
};

export function startStudy() {
  return async function startStudyThunk(dispatch, getState) {
    let {
      studyId,
      accessCode,
      participant,
      panelCompanyCode,
      test,
      cellId,
      demographicsSurveyResponsesInternal,
      testType,
    } = getState().studyReducer;
    const urlParams = new URLSearchParams(window.location.search);

    let deviceInfo = DeviceController.getDeviceInfo();

    let body = {
      access_code: accessCode,
      participant: participant,
      panel: panelCompanyCode,
      test: test,
      requested_cell_id: cellId,
      gender:
        demographicsSurveyResponsesInternal["gender"] || urlParams.get("g"),
      age: demographicsSurveyResponsesInternal["age"] || urlParams.get("a"),
      social_grade:
        demographicsSurveyResponsesInternal["social_grade"] ||
        urlParams.get("s"),
      family:
        demographicsSurveyResponsesInternal["family"] || urlParams.get("f"),
      earnings:
        demographicsSurveyResponsesInternal["earnings"] || urlParams.get("hi"),
      ethnicity:
        demographicsSurveyResponsesInternal["ethnicity"] || urlParams.get("et"),
      extra1: demographicsSurveyResponsesInternal["e1"] || urlParams.get("e1"),
      extra2: demographicsSurveyResponsesInternal["e2"] || urlParams.get("e2"),
      extra3: demographicsSurveyResponsesInternal["e3"] || urlParams.get("e3"),
      device: deviceInfo.device,
      os: deviceInfo.osString.toLowerCase(),
      os_version: deviceInfo.os.version,
      browser: deviceInfo.name.toLowerCase(),
      browser_version: deviceInfo.version,
      is_mobile: deviceInfo.isMobile,
      is_touch_capable: deviceInfo.isMobile,
      device_model: deviceInfo.device,
      browser_language: window.navigator.language,
      connection_speed: deviceInfo.connectionType,
      connection_speed_absolute: deviceInfo.connectionSpeed,
    };

    let response = await api.admin.start(studyId, body);

    if (testType === "3" || testType === "4") {
      if (testType === "3") {
        response.broker_details = {
          name: "No Eye Tracking Broker",
          url: "https://lr-webcam-tracker.viewpoints.fyi/no_eyetracking_broker/",
          model_name: "NoModel",
          config: null,
          eyetracking_enabled: false,
          js_enabled: true,
          ir_enabled: false,
          min_resolution_width: 0,
          min_resolution_height: 0,
          min_frame_rate: 0,
        };
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      sendDataToCrawler("AssignResponse", {
        redirectSurvey: response.redirect_survey,
        url: response.redirect_url,
        internalQuestions: response.study_questions,
        checks: [
          {
            name: "Orientation Check",
            enabled: deviceInfo.isMobile,
            page: "instructions",
          },
        ],
      });
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }

    if (response.success) {
      let surveyRequired =
        (response.redirect_url && response.redirect_url !== "") ||
        (response.study_questions && response.study_questions !== []);

      let payload = {
        brokerDetails: response.broker_details,
        cleanedParticipantReference: response.cleaned_participant_reference,
        demographicSurveyUrl: response.redirect_url,
        demographicSurveyUrlMemberInsertParameter:
          response.redirect_url_member_insert_parameter,
        demographicSurveyUrlShouldRedirect: response.redirect_survey,
        demographicsSurveyQuestionsInternal: response.study_questions,
        orientation: response.orientation,
        requireChromeExtension: response.require_chrome_extension,
        screenLowConnectionSpeed: response.screen_low_connection_speed,
        lowConnectionSpeed: response.low_connection_speed,
        screenLowCalibrationFrameRate:
          response.screen_low_calibration_frame_rate,
        lowCalibrationFrameRate: response.low_calibration_frame_rate,
        screenLowViewportWidth: response.screen_low_viewport_width,
        lowViewportWidth: response.low_viewport_width,
        screenLowViewportHeight: response.screen_low_viewport_height,
        lowViewportHeight: response.low_viewport_height,
        requireFullscreen: response.require_fullscreen,
        surveyComplete: !surveyRequired,
      };

      dispatch({
        type: actions.START_RESPONSE_UPDATE,
        payload: payload,
      });
    }

    return response;
  };
}

export function assignStudy() {
  return async function assignStudyThunk(dispatch, getState) {
    let {
      studyId,
      accessCode,
      participant,
      panelCompanyCode,
      test,
      cellId,
      demographicsSurveyResponsesInternal,
      hasWebcam,
      hasAdBlock,
      testType,
      assignCalled,
      skipSurvey,
    } = getState().studyReducer;

    if (!assignCalled) {
      const urlParams = new URLSearchParams(window.location.search);
      let deviceInfo = DeviceController.getDeviceInfo();
      let deviceDimensions = DeviceController.getDimensions();

      let body = {
        access_code: accessCode,
        participant: participant,
        panel: panelCompanyCode,
        test: test,
        requested_cell_id: cellId,
        gender:
          demographicsSurveyResponsesInternal["gender"] || urlParams.get("g"),
        age: demographicsSurveyResponsesInternal["age"] || urlParams.get("a"),
        social_grade:
          demographicsSurveyResponsesInternal["social_grade"] ||
          urlParams.get("s"),
        family:
          demographicsSurveyResponsesInternal["family"] || urlParams.get("f"),
        earnings:
          demographicsSurveyResponsesInternal["earnings"] ||
          urlParams.get("hi"),
        ethnicity:
          demographicsSurveyResponsesInternal["ethnicity"] ||
          urlParams.get("et"),
        extra1:
          demographicsSurveyResponsesInternal["e1"] || urlParams.get("e1"),
        extra2:
          demographicsSurveyResponsesInternal["e2"] || urlParams.get("e2"),
        extra3:
          demographicsSurveyResponsesInternal["e3"] || urlParams.get("e3"),
        device: deviceInfo.device,
        os: deviceInfo.osString.toLowerCase(),
        os_version: deviceInfo.os.version,
        browser: deviceInfo.name.toLowerCase(),
        browser_version: deviceInfo.version,
        connection_speed: deviceInfo.connectionType,
        connection_speed_absolute: deviceInfo.connectionSpeed,
        is_mobile: deviceInfo.isMobile,
        is_touch_capable: deviceInfo.isMobile,
        device_model: deviceInfo.device,
        browser_language: window.navigator.language,
        hasWebcam: hasWebcam,
        hasAdBlock: hasAdBlock,
        screen_resolution_width: deviceDimensions.screen_resolution_width,
        screen_resolution_height: deviceDimensions.screen_resolution_height,
        browser_width: deviceDimensions.browser_width,
        browser_height: deviceDimensions.browser_height,
        browser_left: deviceDimensions.browser_left,
        browser_top: deviceDimensions.browser_top,
        viewport_width: deviceDimensions.viewport_width,
        viewport_height: deviceDimensions.viewport_height,
        browser_zoom: deviceDimensions.browser_zoom,
        skip_survey: skipSurvey,
      };

      let response = await api.admin.assign(studyId, body);

      if (response && response.success) {
        let stepData = dedupeByKey(
          response.step_data.filter((step) => !step.optional_step),
          "order"
        );

        dispatch({ type: INIT_STEPDATA, payload: stepData });

        if (testType === "3" || testType === "4") {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          sendDataToCrawler("StepData", { stepData, cellId: response.cell_id });
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        LoggerController.setCellId(cellId);

        let payload = {
          cellStatus: response.cell_status,
          cellId: response.cell_id,
          participantStatus: response.cell_id,
          participantId: response.participant_id,
          stepData: stepData,
          optionalStepData: response.step_data.filter(
            (step) => step.optional_step
          ),
          fullScreenRequired: response.full_screen_required,
          hasExternalDomain: response.has_external_domain,
        };

        dispatch({ type: actions.ASSIGN_RESPONSE_UPDATE, payload });
      }

      return response;
    } else {
      let response = { success: true };

      return response;
    }
  };
}

export function startSession() {
  return async function startSessionThunk(dispatch, getState) {
    let { sessionCalled, sessionId } = getState().studyReducer;

    if (!sessionCalled) {
      let sessionResponse = await api.tracking.session();

      if (sessionResponse && sessionResponse.id) {
        dispatch({
          type: actions.SESSION_ID_UPDATE,
          payload: { sessionId: sessionResponse.id },
        });
      } else if (sessionResponse && sessionResponse.tmp_session_id) {
        dispatch({
          type: actions.SESSION_ID_UPDATE,
          payload: { tmpSessionId: sessionResponse.tmp_session_id },
        });
      }

      return sessionResponse;
    } else if (!!sessionId) {
      let sessionResponse = {
        id: sessionId,
      };
      return sessionResponse;
    } else {
      return false;
    }
  };
}

export function updateExtraData(payload) {
  return {
    type: actions.UPDATE_EXTRA_DATA,
    payload: payload,
  };
}

export function getPanelDetails(panel, participant) {
  return async function getPanelDetailsThunk(dispatch, getState) {
    let { studyId, accessCode } = getState().studyReducer;

    let body = { access_code: accessCode, panel: panel };

    let response = await api.admin.getPanelDetails(studyId, body);
    if (response.success) {
      let successRedirectUrl = response.success_redirect_url;
      if (!!participant) {
        successRedirectUrl = successRedirectUrl.replace(
          response.redirect_url_member_insert_parameter,
          participant
        );
      }
      let screenoutRedirectUrl = response.screenout_redirect_url;
      if (!!participant) {
        screenoutRedirectUrl = screenoutRedirectUrl.replace(
          response.redirect_url_member_insert_parameter,
          participant
        );
      }
      let quotaFullRedirectUrl = response.quota_full_redirect_url;
      if (!!participant) {
        quotaFullRedirectUrl = quotaFullRedirectUrl.replace(
          response.redirect_url_member_insert_parameter,
          participant
        );
      }

      let getPanelDetailsPayload = {
        successRedirectUrl,
        screenoutRedirectUrl,
        quotaFullRedirectUrl,
        panelCompanyCode: response.panel_company_code,
        studyId: response.study_id,
        studyName: response.study_name,
        secondsBeforeMoveNext: response.seconds_before_move_next,
        eyetrackingEnabled: response.eyetracking_enabled,
        showGdprWarning: response.show_gdpr_warning,
      };

      dispatch({
        type: actions.PANEL_DETAILS_UPDATE,
        payload: getPanelDetailsPayload,
      });

      return {
        success: true,
        links: {
          successRedirectUrl,
          screenoutRedirectUrl,
          quotaFullRedirectUrl,
        },
      };
    } else return { success: false };
  };
}

export function completePrestartChecks() {
  return { type: actions.PRESTART_CHECKS_COMPLETE };
}

export function completeStartChecks() {
  return { type: actions.START_CHECKS_COMPLETE };
}

export function completeInstructionsChecks() {
  return { type: actions.INSTRUCTIONS_CHECKS_COMPLETE };
}

export function oldCompleteStep(stepIndex) {
  return { type: actions.COMPLETE_STUDY_STEP, payload: stepIndex };
}

export function surveyCompleted() {
  return { type: actions.SET_SURVEY_COMPLETE, payload: true };
}

export function updateSocialSessionData(payload) {
  return { type: actions.UPDATE_SOCIAL_SESSION_DATA, payload: payload };
}

export function updateSurveyUrlParams(payload) {
  return { type: actions.UPDATE_SURVEY_URL_PARAMS, payload: payload };
}
export function toggleExtendedLogger() {
  return { type: actions.TOGGLE_EXTENDED_LOGGER };
}

export function toggleSkipSurvey() {
  return { type: actions.TOGGLE_SKIP_SURVEY };
}
