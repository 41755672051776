import React from "react";
import { connect } from "react-redux";
import LSModal from "../modal";
import { toggleContactModal } from "../actions";
import Button from "react-bootstrap/Button";
import { FormattedMessage } from "react-intl";
import api from "../../../api";
import ErrorController from "../../../controllers/Error/ErrorController";

class ContactContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasSubmit: false,
    };

    this.formTextArea = React.createRef();
    this.formSubject = React.createRef();

    this.submitContactForm = this.submitContactForm.bind(this);
  }

  /**
   * TODO: Hookup to contact api endpoint.
   */
  submitContactForm() {
    var feedbackPayload;
    var componentRef = this;

    if (!!!this.props.studyContext.studyId) {
      componentRef.setState((state) => {
        return { hasSubmit: true };
      });
      ErrorController.sendTrackerInfo(
        "Failed to submit feedback - feedback in extra data",
        this.formTextArea.current.value
      );
      return;
    }

    if (!!!this.props.studyContext.participantId) {
      feedbackPayload = {
        access_code: componentRef.props.studyContext.accessCode,
        participant: componentRef.props.studyContext.participant,
        reason: this.formSubject.current.value,
        feedback_text: this.formTextArea.current.value,
      };
    } else {
      feedbackPayload = {
        access_code: componentRef.props.studyContext.accessCode,
        participant_id: componentRef.props.studyContext.participantId,
        reason: this.formSubject.current.value,
        feedback_text: this.formTextArea.current.value,
      };
    }
    api.admin
      .feedback(this.props.studyContext.studyId, feedbackPayload)
      .then(function (response) {
        componentRef.setState((state) => {
          return { hasSubmit: true };
        });
        if (!response.success) {
          let e = new Error(response.error);
          e.name = response.error_code || "";
          throw e;
        }
      })
      .catch(function (error) {
        ErrorController.errors.RequestError.raise({
          identifier: "feedback",
          error_message: error.message,
        });
      });
  }

  render() {
    return (
      <div>
        <p>
          <FormattedMessage
            id="app.menu.feedback.content"
            defaultMessage="If you've experienced an issue with the study or would just like to leave a comment, please feel free to do so here."
            description="Feedback instructions"
          />
        </p>
        {!this.state.hasSubmit ? (
          <div>
            <p
              style={{
                margin: 0,
              }}
            >
              Subject
            </p>
            <input
              ref={this.formSubject}
              defaultValue="Something has gone wrong during my Study Session..."
              style={{
                width: "100%",
              }}
            />
            <p
              style={{
                margin: 0,
              }}
            >
              Content
            </p>
            <textarea
              ref={this.formTextArea}
              rows="4"
              placeholder="Please enter enough details to describe your issue / enable us to investigate thoroughly..."
              style={{
                width: "100%",
              }}
            ></textarea>
            <br />
            <Button
              onClick={this.submitContactForm}
              style={{
                marginTop: "10px",
              }}
            >
              <FormattedMessage
                id="app.button.submit"
                defaultMessage="Submit"
                description="Submit"
              />
            </Button>
          </div>
        ) : (
          <p>
            <FormattedMessage
              id="app.contactModal.feedback"
              defaultMessage="Thank you for your feedback!"
              description="Default feedback message."
            />
          </p>
        )}
      </div>
    );
  }
}

function LSContactModalCont(props) {
  return props.displayContactModal ? (
    <LSModal
      title="Feedback"
      titleId="app.button.feedback"
      content={<ContactContent studyContext={props.studyContext} />}
      modalToggle={props.toggleContactModal}
    />
  ) : null;
}

const mapStateToProps = function (state) {
  return {
    displayContactModal: state.modalReducer.displayContactModal,
    studyContext: state.studyReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    toggleContactModal: () => dispatch(toggleContactModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LSContactModalCont);
