const initialState = {
  currentStepId: 0,
  stepData: [],
  stepShowNext: false,
  stepCompleted: false,
  stepCompletes: {},
  stepRouteOut: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "INIT_STEPDATA":
      return {...state, stepData: action.payload}
    case "STEP_SHOW_NEXT":
      return { ...state, stepShowNext: true };
    case "START_STEP":
      return {
        ...state,
        currentStepId: action.payload,
        stepCompleted: false,
        stepShowNext: false,
      };
    case "SHOW_NEXT":
      return {
        ...state,
        stepShowNext:true
      }
    case "COMPLETE_STEP":
      let newStepData = [...state.stepData];
      let stepIndex = newStepData.findIndex(
        (step) => step.id === action.payload.step
      );
      newStepData[stepIndex].completed = true;
      return {
        ...state,
        stepCompletes: {
          ...state.stepCompletes,
          [action.payload.step]: new Date().getTime(),
        },
        stepCompleted: true,
        stepShowNext:false,
        stepRouteOut: action.payload.routeOut,
        stepData: newStepData,
      };
    default:
      return state;
  }
}
