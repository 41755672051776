{
  "title": "﻿Plateforme d'étude Lumen - Politique de confidentialité",
  "date": "Juillet 2022",
  "contactDetails": {
    "title": "Nos coordonnées ",
    "body": [
      "Nom : Lumen Research Ltd (\"Lumen\")",
      "Adresse: Screenworks, Unit 215, 22 Highbury Grove, Londres N5 2ER",
      "Numéro de téléphone: +44(0)2037355199 ",
      "Courriel: dataprotection@lumen-research.com",
      "Référence d'enregistrement ICO : ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "La présente politique de confidentialité explique la nature des informations que nous recueillons auprès des participants qui prennent part à une enquête oculométrique sur la plate-forme d'étude Lumen, ainsi que notre politique régissant toute information personnelle susceptible d'être recueillie. "
      ],
      "heading": "Introduction"
    },
    {
      "body": [
        "Dans une enquête typique, Lumen ne collecte pas d'informations personnelles telles que définies dans le Règlement général sur la protection des données du Royaume-Uni, mais recueille une estimation des points d’attention, des réponses d’enquêtes, des métadonnées sur le type d'appareil et le moment auxquels lequel la collecte de données a eu lieu et les identifiants des répondants pour l’administration de leur rémunération.",
        "Dans le cadre d'une enquête typique, il vous sera demandé de vous asseoir devant la webcam ou la caméra frontale de votre appareil et d'effectuer un calibrage afin de pouvoir estimer l'endroit où vous regardez sur votre écran. Au cours de l'enquête, il vous sera demandé de visualiser certains éléments (par exemple des images, des sites web ou des vidéos) pendant que l'outil utilisé pour l’enquête estime où se porte votre regard. Les données saisies à partir de ce suivi oculaire sont les coordonnées des points  où l'on estime que se porte votre regard sur l'écran pendant la durée de l'enquête. Le logiciel utilise des images de vous venant de votre webcam pour effectuer ces estimations, mais il ne saisit aucune photo ou vidéo de vous.",
        "Il se peut également que l'on vous pose des questions sur vous-même, votre expérience et les éléments que vous avez vus pendant l'enquête. ",
        "Les données de ces enquêtes sont envoyées à Lumen Research Ltd (\"Lumen\"). Lumen analysera les données et partagera les résultats avec ses clients. ",
        "Dans ces enquêtes, un identifiant alphanumérique du répondant sera également transmis par un tiers à Lumen afin que Lumen puisse identifier les répondants ayant complété l'enquête. Lumen partagera cette information sur les complétions avec des tiers dans le but de procéder à la remuneration de ceux qui ont complété l'enquête. "
      ],
      "heading": "Le type d'informations que nous recueillons"
    },
    {
      "body": [
        "Il peut arriver que l'on demande aux répondants de participer à un projet de recherche qui nécessite plus d'une période de collecte de données.  Dans ce cas, l'identifiant du répondant sera utilisé comme un identifiant permanent pour recontacter un participant unique, afin d'initier sa participation à tout exercice de collecte de données ultérieur.  Dans ce scénario, l'identifiant du répondant sera considéré comme une information personnelle.  "
      ],
      "heading": "Le type de renseignements personnels que nous recueillons "
    },
    {
      "body": [
        "Comme dans le cas d'une enquête classique, un identifiant permanent du répondant nous sera transmis par un tiers qui l'a recruté pour notre recherche.  Nous l'utiliserons pour recontacter les participants afin d'initier leur participation future à la recherche et pourrons partager cet identifiant avec des tiers afin de faciliter cette nouvelle participation.",
        "En vertu du règlement général sur la protection des données du Royaume-Uni (RGPD), les bases légales sur lesquelles nous nous appuyons pour traiter ces informations sont votre consentement.  Vous avez la possibilité de retirer votre consentement à tout moment.  Vous pouvez le faire en nous contactant à l'adresse dataprotection@lumen-research.com."
      ],
      "heading": "Comment nous obtenons les informations personnelles et pourquoi nous les avons"
    },
    {
      "body": [
        "Vos informations sont stockées en toute sécurité et cryptées à l'aide de la technologie SSL. Il s'agit de la technologie de cryptage de haut niveau la plus couramment utilisée. Elle est utilisée par les banques pour sécuriser les transactions bancaires et autres communications très sensibles. Elle est également utilisée par Amazon, PayPal, eBay et d'autres sites web nécessitant des systèmes de sécurité de niveau bancaire. Les scientifiques estiment qu'il faudrait beaucoup plus de temps que l'âge de l'univers pour casser un cryptage de 256 bits à l'aide de la technologie actuelle. Les données collectées sont stockées sur des serveurs web sécurisés gérés par Amazon Web Services (AWS). AWS est l'une des plateformes de services informatiques dématérialisés les plus couramment utilisées, sûre et fiable. Elle est utilisée par les principaux services commerciaux dématérialisés, notamment Netflix et Expedia.",
        "Nous conservons les identifiants permanents pendant la période nécessaire pour recontacter les répondants dans le cadre d'une recherche en plusieurs phases.  Cette durée peut varier en fonction de la nature de la recherche et sera notifiée lorsque vous donnerez votre consentement pour participer.  Lorsque la collecte de données pour un tel projet est terminée, les identifiants sont conservés uniquement dans la mesure où ils sont nécessaires à l'analyse et à la présentation des données.  Lorsque l'analyse et le rapport d'un projet sont terminés, nous détruisons ces informations personnelles en les supprimant de nos bases de données."
      ],
      "heading": "Comment nous stockons vos informations personnelles "
    }
  ],
  "dataProtection": {
    "body": [
      "En vertu de la loi sur la protection des données, vous avez des droits, notamment :"
    ],
    "bullets": [
      {
        "title": "Votre droit d'accès",
        "text": [
          "Vous avez le droit de nous demander une copie de vos informations personnelles. "
        ]
      },
      {
        "title": "Votre droit de rectification",
        "text": [
          "Vous avez le droit de nous demander de rectifier les informations personnelles que vous jugez inexactes. Vous avez également le droit de nous demander de compléter des informations que vous jugez incomplètes. "
        ]
      },
      {
        "title": "Votre droit à l'effacement",
        "text": [
          "Vous avez le droit de nous demander d'effacer vos informations personnelles dans certaines circonstances. "
        ]
      },
      {
        "title": "Votre droit à la restriction du traitement des informations",
        "text": [
          "Vous avez le droit de nous demander de restreindre le traitement de vos informations personnelles dans certaines circonstances. "
        ]
      },
      {
        "title": "Votre droit de retrait de votre consentement",
        "text": [
          "Vous avez le droit de retirer votre consentement au traitement de vos informations personnelles."
        ]
      },
      {
        "title": "Votre droit à la portabilité des données",
        "text": [
          "Vous avez le droit de nous demander de transférer les informations personnelles que vous nous avez fournies à une autre organisation, ou à vous-même, dans certaines circonstances.",
          "Vous n'êtes pas tenu de payer des frais pour l'exercice de vos droits. Si vous faites une demande, nous avons un mois pour vous répondre.",
          "Veuillez nous contacter à l'adresse dataprotection@lumen-research.com si vous souhaitez faire une demande."
        ]
      }
    ],
    "title": "Vos droits en matière de protection des données"
  },
  "complaints": {
    "body": [
      "Si vous avez des doutes sur l'utilisation que nous faisons de vos informations personnelles, vous pouvez déposer une plainte à l'adresse dataprotection@lumen-research.com.",
      "Vous pouvez également déposer une plainte auprès de l'ICO si vous n'êtes pas satisfait de la manière dont nous avons utilisé vos données."
    ],
    "address": {
      "body": [
        "Information Commissioner’s Office",
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ],
      "title": "Adresse de l’ICO :            "
    },
    "title": "Comment deposer une plainte",
    "number": "Numéro d'assistance téléphonique : 0303 123 1113",
    "website": "Site web de l'ICO : https://www.ico.org.uk"
  }
}
