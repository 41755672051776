{
    "app.button.faqs": "veel voorkomende vragen",
    "app.button.terms": "Gebruiksvoorwaarden",
    "app.button.privacy": "Privacybeleid",
    "app.button.feedback": "Feedback",
    "app.button.selectLanguage": "Selecteer een taal",
    "app.button.help": "Help",
    "app.button.start": "Starten",
    "app.button.next": "Volgende",
    "app.button.submit": "Verzenden",
    "app.button.takepart": "Ja - Ik doe mee",
    "app.menu.feedback.content": "Als u een probleem ondervindt bij dit onderzoek en alleen een opmerking wilt achterlaten, dan kunt u dat hier gerust doen.",
    "app.contactModal.feedback": "Bedankt voor uw mening!",
    "app.takePartModal.line1": "Als u nu stopt, komt u niet in aanmerking voor een beloning voor uw deelname.",
    "app.takePartModal.line2": "U heeft alleen maar een webcam nodig!",
    "app.takePartModal.reason1": "Ik heb geen webcam.",
    "app.takePartModal.reason2": "Ik vind het niet prettig om mijn webcam voor deze enquête te gebruiken.",
    "app.takePartModal.reason3": "Ik heb hier nu geen tijd voor.",
    "app.takePartModal.reason4": "Andere reden.",
    "app.generic.redirect": "U wordt zo meteen doorgestuurd.",
    "app.prestart.loading": "Laden, een ogenblik.",
    "app.index.title": "Welkom",
    "app.index.content.line1": "Tijdens deze enquête gebruiken we uw camera om een idee te krijgen waar u naar kijkt. Maak u geen zorgen, we maken geen foto's of video's.",
    "app.index.content.line1alt": "Zorg dat u lekker zit en tik dan op het scherm om te beginnen.",
    "app.index.content.line2": "Heeft uw toestel een camera? Als dat het geval is, druk dan op de startknop hieronder.",
    "app.index.rejection": "Sorry, het lijkt of u een vervormde link heeft gebruikt.",
    "app.index.webview": "Sorry, het lijkt of u onze pagina vanuit een webview heeft geopend. Open deze link in uw browser. Dit kan mogelijk rechtstreeks via het webview-menu.",
    "app.index.webview.chrome.1": "Tik op de bovenstaande menuknop en kies 'Openen in Chrome'.",
    "app.index.webview.chrome.2": "Dit opent de enquête in een browser-app, zodat hij naar behoren werkt.",
    "app.index.webview.android.1": "Tik hieronder op Start om de enquête in een browser-app te openen, zodat hij naar behoren werkt.",
    "app.index.webview.android.2": "Als dit niet lukt, open de link dan handmatig in een browser-app (d.w.z. door de link te kopiëren en in de adresbalk van Chrome te plakken, of via een optie zoals 'Openen in Chrome' in het menu hierboven).",
    "app.index.webview.safari.1": "Tik onderaan het scherm op het Safari-pictogram om door te gaan.",
    "app.index.webview.safari.2": "Dit ziet er zo uit:",
    "app.index.webview.safari.3": "Dit opent de enquête in Safari, zodat hij naar behoren werkt.",
    "app.index.wifi": "Het lijkt of uw internetverbinding te langzaam is. Heeft u verbinding met een wifi?",
    "app.index.tellmemore": "Kunt u iets meer vertellen?",
    "app.index.takepart": "Ik wil niet meedoen.",
    "app.instructions.title": "Instructies",
    "app.instructions.instruction1": "Draag als het kan geen bril tijdens de enquête.",
    "app.instructions.instruction2": "Ga zo zitten dat u uw hoofd en uw toestel gemakkelijk stil kunt houden.",
    "app.instructions.instruction3": "Beweeg alleen uw ogen, niet uw hoofd.",
    "app.instructions.instruction4": "Klaar voor de start?",
    "app.instructions.instruction5": "Gebruik wifi om data-kosten te voorkomen.",
    "app.instructions.instruction6": "Lees de volgende instructies aandachtig.",
    "app.instructions.instruction7": "Laat uw telefoon op een stabiele ondergrond rusten.",
    "app.instructions.instruction8": "Zorgt u er alstublieft voor dat uw kamer voldoende is verlicht - bij voorkeur van bovenaf (niet achter u).",
    "app.setup.title": "Opstelling",
    "app.setup.instruction0": "Houd uw toestel in de staande stand.",
    "app.setup.instruction0a": "Draai uw scherm naar de staande positie.",
    "app.setup.instruction0b": "Draai uw toestel zo, dat de camera aan de linkerkant zit.",
    "app.setup.instruction0c": "Draai uw toestel zo, dat de camera aan de rechterkant zit.",
    "app.setup.instruction1": "Initialiseren...",
    "app.setup.instruction2": "Plaats, wanneer u gedetecteerd bent, uw hoofd binnen het vak.",
    "app.setup.instruction3": "Houd uw hoofd en toestel op zijn plaats tot de enquête is afgelopen.",
    "app.setup.instruction4": "Nu moeten we alleen nog even valideren.",
    "app.setup.instruction5": "Bedankt! Vergeet niet om uw hoofd en toestel in dezelfde positie te houden tot de enquête is afgelopen.",
    "app.setup.instruction6": "Veeg omhoog om de adresbalk te verbergen.",
    "app.setup.retry": "Fout gedetecteerd. Houd uw hoofd en het toestel stil en bedek de camera niet - ook niet met uw vingers. Gebruik de knop hieronder om het nogmaals te proberen.",
    "app.setup.retryInit": "Fout gedetecteerd. Zorg ervoor dat de browser toegang heeft tot uw camera. Als u eerder de cameratoegang heeft uitgeschakeld, moet u deze opnieuw inschakelen in de privacyinstellingen van uw browser. Gebruik de knop hieronder om het nogmaals te proberen.",
    "app.setup.retryButton": "Nogmaals proberen",
    "app.setup.calibrationProp.watch": "Volg de stip wanneer die op het scherm verschijnt",
    "app.setup.calibrationProp.click": "Klik ergens op het scherm om verder te gaan.",
    "app.setup.calibrationProp.tap": "Tik ergens op het scherm om verder te gaan.",
    "app.study.validationProp.watch": "Volg de stip wanneer die op het scherm verschijnt",
    "app.study.validationProp.restart": "Probeer het nogmaals",
    "app.study.validationProp.click": "Klik ergens op het scherm om verder te gaan.",
    "app.study.validationProp.tap": "Tik ergens op het scherm om verder te gaan.",
    "app.error.title": "Fout",
    "app.error.generic": "Oeps! Er ging iets fout.",
    "app.error.initialise": "Kan niet initialiseren!",
    "app.error.findCamera": "Kan camera niet vinden!",
    "app.error.headPosition": "Kan de positie van uw hoofd niet bepalen.",
    "app.error.calibrate": "Kan niet kalibreren.",
    "app.error.validate": "Kan niet valideren.",
    "app.error.start": "Starten is mislukt.",
    "app.error.assign": "Toekennen is mislukt.",
    "app.error.osnotallowed": "Uw besturingssysteem is niet toegestaan. Controleer welk apparaat u voor deze enquête kunt gebruiken en probeer het opnieuw.",
    "app.error.demoquotafullnoactivecell": "Dank u wel voor uw interesse. Het onderzoek wordt nu afgesloten.",
    "app.invalid.title": "Ongeldig",
    "app.invalid.generic": "U komt helaas niet in aanmerking voor dit onderzoek.",
    "app.completion.title": "Afronding",
    "app.completion.congratulations": "Hartelijk gefeliciteerd! U bent klaar met het onderzoek. De cameratoegang van deze enquête wordt nu uitgeschakeld.",
    "app.questions.required": "Deze vraag is verplicht.",
    "app.faq.question1": "V: Wat is het Lumen onderzoeksplatform en wat brengt deelname aan een eye-tracking enquête met zich mee?",
    "app.faq.answer1": "A: Bij deze enquête wordt een technologie gebruikt die de bewegingen van de ogen volgt. U wordt gevraagd om voor de webcam van uw toestel te gaan zitten en een kalibratie uit te voeren zodat we kunnen inschatten waarnaar u kijkt op uw scherm. Tijdens de enquête wordt u gevraagd om naar enkele artikelen te kijken (zoals afbeeldingen, websites of video's) terwijl het enquête-tool inschat waarnaar u kijkt. De gegevens die door dit ‘eye-tracking’ tool worden vastgelegd, zijn de coördinaten van de ingeschatte blikpunten op uw scherm over een bepaalde tijdsduur. De software gebruikt afbeeldingen van u van uw webcam om deze schattingen te doen, maar legt geen foto's of video's van u vast. Mogelijk stellen we u ook enkele vragen over uzelf, over uw ervaring en over de artikelen die u tijdens de enquête heeft gezien. De gegevens worden verzonden naar Lumen Research Ltd. Lumen Research analyseert de gegevens en delen de resultaten met hun cliënten.",
    "app.faq.question2": "V: Waarom heeft de software een webcam / camera nodig en hoe wordt deze gebruikt?",
    "app.faq.answer2": "A: De software gebruikt afbeeldingen van uw webcam of de camera van uw toestel om in te schatten waar u naar kijkt op een scherm. Dit gebeurt met behulp van een combinatie van face-tracking en eye-tracking software die in real-time lokaal op uw toestel afbeeldingen analyseert. Deze afbeeldingen worden verwerkt en tijdens de kalibratie aan u getoond zodat u in de goede positie kunt gaan zitten, maar de afbeeldingen worden niet vastgelegd, dus uw privacy is beschermd. De gegevens die via eye-tracking worden geregistreerd en verzameld, zijn geen afbeeldingen maar numerieke gegevens over ingeschatte blikpunten op de stimulus over een bepaalde tijdsduur.",
    "app.faq.question3": "V: Maakt u afbeeldingen van mij terwijl u de eye-tracking uitvoert?",
    "app.faq.answer3": "A: De enige keer dat afbeeldingen worden gebruikt, is om het u mogelijk te maken om in de goede positie te gaan zitten tijdens de kalibratie. Deze afbeeldingen worden niet opgeslagen, en u bent de enige die ze ziet. De gegevens die via eye-tracking worden geregistreerd en verzameld, zijn geen afbeeldingen maar numerieke gegevens over ingeschatte blikpunten op de stimuli over een bepaalde tijdsduur.",
    "app.faq.question4": "V: Welke gegevens worden tijdens de enquête verzameld?",
    "app.faq.answer4.line1": "A: De software verzamelt sommige van of alle volgende soorten gegevens:",
    "app.faq.answer4.line2": "“Gebruikersnaam” - De gebruikersnaam die wordt gebruikt voor het identificeren van deelnemers (om de beloning voor deelname te kunnen toekennen);",
    "app.faq.answer4.line3": "“Blikgegevens” – Schattingen van de plaats waar gebruikers op hun scherm naar kijken wanneer er stimuli op het scherm worden afgebeeld.",
    "app.faq.answer4.line4": "“Enquêtegegevens” – De antwoorden van de gebruikers op vragen.",
    "app.faq.answer4.line5": "“Metadata” - De gegevens over het tijdstip waarop mensen met succes aan het onderzoek deelnamen.",
    "app.faq.answer4.line6": "“Fysieke toestelgegevens” – De gegevens over de computer waarop een gebruiker meedoet, zoals de grootte van het RAM, het type CPU in de machine en de User Agent (besturingssysteem en browserversie) evenals informatie over het scherm van de computer (maat en resolutie), webcam (fabrikant en resolutie), en antivirusprogramma's.",
    "app.faq.question5": "V: Hoe worden de verzamelde gegevens gebruikt?",
    "app.faq.answer5.line1": "A: De verzamelde gegevens worden gebruikt om uit te werken hoe mensen diverse soorten visuele afbeeldingen en media bekijken en erop reageren, en om de mening van mensen te meten over de afbeeldingen die hen getoond worden.",
    "app.faq.answer5.line2": "Lumen mag de onderzoeksgegevens delen met gelieerde bedrijven en met zakenpartners en cliënten. Hoewel de onderzoeksgegevens met geaffilieerde bedrijven, partners en cliënten mogen worden gedeeld, gebeurt dit meestal op anonieme basis. Zodanige geaffilieerde bedrijven, partners en cliënten kunnen deze gegevens gebruiken voor het opstellen van samenvattende beschrijvingen van een grote verscheidenheid aan consumentengedrag, en consumentensegmenten voor het ontwikkelen van producten of voor het verschaffen van marketing oplossingen aan hun klanten of de consument.",
    "app.faq.answer5.line3": "Lumen verzamelt “fysieke toestelgegevens” en “metadata” voor een beter beheer van het verzamelen en verwerken van gegevens, waaronder kwaliteitscontrole en volumemanagement.",
    "app.faq.answer5.line4": "De verzamelde gegevens worden bewaard gedurende de periode die noodzakelijk is om de uiteengezette doelstellingen te bereiken.",
    "app.faq.question6": "V: Hoe veilig zijn mijn gegevens?",
    "app.faq.answer6": "A: De door Lumen verzamelde gegevens worden versleuteld met behulp van SSL-technologie. Dit is de meest gebruikte uitstekende versleutelingstechnologie en deze wordt gebruikt door banken voor beveiliging van banktransacties en andere zeer gevoelige communicatie. Het wordt ook gebruikt door Amazon, Paypal, eBay, en andere websites die het zelfde beveiligingsniveau vereisen als banken. Wetenschappers schatten dat het breken van 256-bitsversleuteling met behulp van de tegenwoordige technologie langer zou duren dan het heelal oud is. Verzamelde gegevens worden opgeslagen op beveiligde online servers onder beheer van Amazon Web Services (AWS). AWS is een van de meest algemeen gebruikte veilige en betrouwbare cloud-platforms voor computerservices en wordt gebruikt door de grootste op de cloud gebaseerde commerciële diensten zoals Netflix en Expedia.",
    "app.faq.question7": "V: Welke verplichtingen heeft u in verband met de content die u wordt getoond?",
    "app.faq.answer7": "A: Wanneer u meedoet, begrijpt u en gaat u ermee akkoord dat de content die u in dit onderzoek wordt getoond vertrouwelijk kan zijn of het eigendom van derden en/of onderworpen aan de wetten op handelsmerken of auteursrechten en dat u geen screenshots zult maken of op andere wijze iets van de content zult registreren of kopiëren of op enige wijze iets van de content van het onderzoek verder zult distribueren.",
    "app.faq.question8": "V: Ik weet niet zeker hoe goed mijn webcam is, werkt hij wel voor eye-tracking?",
    "app.faq.answer8": "A: Het systeem werkt met allerlei webcams – dat zal vast geen probleem zijn.",
    "app.faq.question9": "V: Ik ben klaar met het onderzoek, moet ik verder nog iets doen?",
    "app.faq.answer9": "A: Nee, het is niet nodig om verder nog iets te doen. De software wordt automatisch afgesloten. U kunt nu de software verwijderen evenals het gedownloade bestand.",
    "app.faq.question10": "V: Moet ik online zijn om mee te kunnen doen?",
    "app.faq.answer10": "A: Ja, u heeft een internetverbinding nodig om het programma te downloaden en de opdracht af te ronden.",
    "app.faq.question11": "V: Moet ik de opdracht uitvoeren op een stabiele ondergrond?",
    "app.faq.answer11": "A: Ja, voor het beste resultaat moet u lekker voor het computerscherm zitten en, na de kalibratie uw hoofd stil houden terwijl u de stimuli te zien krijgt, tot het niet meer hoeft. We adviseren u dit niet te doen terwijl u in de trein zit met een laptop op uw schoot enz.",
    "app.faq.question12": "V: De veel voorkomende vragen hebben mijn probleem niet opgelost, wat doe ik nu?",
    "app.faq.answer12": "A: Stuur ons een e-mail via study-feedback@lumen-research.com, dan hoort u zo snel mogelijk van ons.",
    "app.faq.patent": "Lumens testen van online content wordt aangestuurd door onze eigen in-context testtechnologie (in GB aangevraagd octrooinr. 1816158.8)",
    "app.terms.line1": "Deze gebruiksvoorwaarden (“Gebruiksvoorwaarden”) regelen de overeenkomst tussen u en Lumen Research Ltd (“Lumen” of “wij/we”), een in Engeland onder nummer 8682432 geregistreerd bedrijf, gevestigd op het adres Unit 215, 22 Highbury Grove, Londen N5 2EF en met registratiereferentie ZA130772 voor gegevensbescherming, met betrekking tot uw gebruik van de “ViewPoints” eye-tracking software (de “software”) op uw apparaat.",
    "app.terms.line2": "1. Akkoord met de voorwaarden",
    "app.terms.line3": "Door de software te gebruiken, bevestigt u dat u akkoord gaat met en gebonden bent aan deze overeenkomst. Als u niet akkoord gaat met de voorwaarden en bepalingen van deze overeenkomst, moet u de ViewPoints-enquête niet invullen.",
    "app.terms.line4": "2. Vertrouwelijke informatie",
    "app.terms.line5": "Wanneer u ViewPoints installeert, gebruikt of zich er op enige wijze mee bezighoudt, heeft u mogelijk toegang tot informatie en/of content die eigendom is van of betrekking heeft op Lumen waaronder informatie die door Lumen of iemand van Lumens cliënten of klanten als vertrouwelijk wordt geïdentificeerd of behandeld of die wegens het karakter of de omstandigheden of wijze van bekendmaking duidelijk vertrouwelijk is, inclusief (ongeacht de hiervoor vermelde algemeenheid) alle informatie over bedrijfsplannen, verwezenlijking van nieuwe omzetmogelijkheden, onderzoeks- en ontwikkelingsprojecten, ontwerpen, geheime processen, codes, software-ontwerpen, handelsgeheimen, ontwikkeling van producten of diensten en formules, know-how, uitvindingen, verkoopstatistieken en voorspellingen, marketing strategieën en plannen, kosten, winst en verlies en andere financiële informatie (behalve voor zover gepubliceerd in gecontroleerde rekeningen), prijsstellings- en kortingsconstructies (al dan niet schriftelijk of elektronisch of in een andere indeling geregistreerd) (“vertrouwelijke informatie”). Zodanige vertrouwelijke informatie blijft het enige en exclusieve eigendom van Lumen en/of zijn klanten, en u verkrijgt of ontvangt in geen geval enige rechten, functietitel en/of belangen in zodanige vertrouwelijke informatie.",
    "app.terms.line6": "U mag noch tijdens uw deelname aan ViewPoints noch op enig moment (onbeperkt) na de beëindiging van zodanige deelname; aan wie dan ook vertrouwelijke informatie bekend maken of communiceren, voor uw eigen doelstellingen gebruiken; of door middel van nalatigheid met betrekking tot verschuldigde zorg en aandacht, onbevoegde, niet-geautoriseerde, onrechtmatige bekendmaking daarvan veroorzaken. U moet te allen tijde alles in het werk stellen ter voorkoming van publicatie of bekendmaking van alle vertrouwelijke informatie. Deze beperkingen zijn van toepassing op alle informatie tot deze beschikbaar komt in het publieke domein anders dan door uw verzuim.",
    "app.terms.line7": "3. Eigendom van Lumen",
    "app.terms.line8": "De ViewPoints-software en alle gegevens, codes, toegangssleutels, wachtwoorden, ontwerpen, tekeningen, documenten en andere documenten of materialen met betrekking tot de software zijn en blijven tijdens de gehele duur van uw deelname aan een ViewPoints-enquête het eigendom van Lumen. Lumen bezit alle intellectuele of eigendomsrechten op alle bestaande en toekomstige intellectuele of industriële eigendomsrechten op alle documenten, materialen, modellen, ontwerpen, tekeningen, processen, uitvindingen, formules, computercodering, data, systeeminterfaces, methodologieën, know-how, vertrouwelijke informatie of ander werk, uitgevoerd, gemaakt, opgesteld, bedacht, ontwikkeld, verbeterd, gedeconstructueerd of ontdekt door u alleen of met anderen in verband met of met betrekking tot de software en/of het toestel of die kunnen worden gebruikt of aangepast voor gebruik daarin of samen daarmee (al of niet geregistreerd), waaronder alle bestaande en toekomstige octrooien, auteursrechten, ontwerprechten, databaserechten, handelsmerken, halfgeleidertopografierechten, rechten op plantvariëteiten, internetrechten/domeinnamen, know-how en alle toepassingen voor al het bovenstaande en alle rechten die gelden voor al het bovenstaande (“eigendomsrechten”). In geen geval worden eigendomsrechten aan u overgedragen.",
    "app.terms.line9": "4. Gebruiksvoorwaarden en -beperkingen",
    "app.terms.line10": "Er wordt aan eindgebruikers een licentie voor de ViewPoints-software verleend. Voor zo ver gedekt door deze overeenkomst, komen wij hiermede overeen dat wij u, op niet-exclusieve, herroepbare, niet overdraagbare, niet-sublicentieerbare, beperkte grondslag, toestaan om de ViewPoints-software te gebruiken op een toestel dat u bezit of beheert of een persoonlijke computer, en in verband hiermee bevestigt en waarborgt u dat u alle noodzakelijke rechten en toestemming heeft voor een zodanige installatie. Om twijfel uit te sluiten: u mag de software op geen enkele wijze voor enig ander doel dan ViewPoints gebruiken, interpreteren, kopiëren, reproduceren, via telecommunicatie door, aanpassen, samenvoegen, veranderen of wijzigen.",
    "app.terms.line11": "U bevestigt dat u het volgende niet zult doen: (i) de software kopiëren, wijzigen, aanpassen, beschikbaar stellen, verhuren, leasen, time-sharen, afgeleide werken ervan maken, of deze proberen te verkopen of op andere wijze rechten erop over te dragen; (ii) delen van de software met welke middelen ook te disassembleren, decompileren, of onderwerpen aan reverse-engineering; en ook niemand toestemming geven of hulp verlenen voor het afleiden of te proberen afgeleid werk te maken van de broncode van de software, of deze te disassembleren, ontsleutelen, decompileren of onderwerpen aan reverse-engineering; en ook geen andere stappen te nemen teneinde ontwerpinformatie in verband met de software af te leiden; (iii) de software te gebruiken voor een ander doel dan het doel waarvoor deze oorspronkelijk aan u is gedistribueerd; (iv) spinnen, robots of andere geautomatiseerde datamining technieken te gebruiken voor het catalogiseren, downloaden, opslaan, of op andere wijze reproduceren of distribueren van de software, of voor het manipuleren van de resultaten van een enquête of andere activiteiten; (v) actie ondernemen om de software of iemands gebruik van de software te verhinderen inclusief, zonder beperking, door overbelasting, “flooding”, “mail-bombing” of “crashen” van de software en het gegevensopslagsysteem dat de software gebruikt via Amazon Web Services (vi) onwettige, misleidende of schadelijke code aan ons te sturen inclusief, zonder beperking, virussen, spyware, adware of andere schadelijke code.",
    "app.terms.line12": "Alle rechten die niet uitdrukkelijk aan u zijn toegekend krachtens deze overeenkomst zijn voorbehouden aan ons en onze licentieverstrekkers. U verklaart dat u ervan op de hoogte bent dat wij krachtens deze overeenkomst of overigens niet verplicht zijn om bugs, tekortkomingen of fouten in de software te corrigeren of om op andere wijze de software te ondersteunen, onderhouden, verbeteren, wijzigen, upgraden, bij te werken of te verbeteren. We zijn op geen enkele wijze verantwoordelijk voor het verlenen van assistentie aan u of door u aangewezen personen bij het gebruik van de software.",
    "app.terms.line13": "We zijn niet aansprakelijk voor schade aan uw pc, inclusief, maar niet beperkt tot resultaten van uw gebruik van de software. We zijn niet aansprakelijk voor eventuele bijkomende door u gemaakte kosten als resultaat van de bij uw deelname aan het onderzoek door de software verzamelde gegevens.",
    "app.terms.line14": "5. Disclaimer",
    "app.terms.line15": "Wij en onze respectievelijke directeuren, functionarissen, werknemers, tussenpersonen, vertegenwoordigers, licentieverstrekkers en leveranciers geven geen bevestiging of garantie of voorwaarde (a) dat de software op tijdsbasis beschikbaar blijft, of dat de toegang tot de software nooit wordt onderbroken of beveiligd is; (b) dat tekortkomingen of fouten worden gecorrigeerd; of (c.) dat de software of de servers of het netwerk door middel waarvan de software en het toestel beschikbaar zijn gesteld, beveiligd zijn tegen of vrij zijn van virussen of andere schadelijke bestanddelen. We zijn niet verplicht om in verband met de software onderhoud en/of support te verlenen.",
    "app.terms.line16": "6. Beperking van de aansprakelijkheid",
    "app.terms.line17": "(a) Voor zover toelaatbaar onder de geldende wet verklaart u dat u ervan op de hoogte bent dat wij en onze respectievelijke directeuren, functionarissen, werknemers, tussenpersonen, vertegenwoordigers, licentieverstrekkers en leveranciers geen aansprakelijkheid dragen voor verliezen, schade of kosten in alle vormen ten gevolge van uw installatie, gebruik of toegang van de software, uw gebruik van of de onmogelijkheid van gebruik van de software, informatie of materialen die op of door middel van de software beschikbaar komen en/of zoals overigens verband houden met de software of deze overeenkomst, onafhankelijk van de aard van het wettige, billijke of statutaire recht waarvan wordt beweerd dat het is geschonden.",
    "app.terms.line18": "(b) Voor zover toelaatbaar onder de geldende wet is uw enige en exclusieve verweer tegen ontevredenheid met de software, het verwijderen van de software en te stoppen met het gebruik ervan.",
    "app.terms.line19": "(c) In geen geval zal een van onze entiteiten, directeuren, functionarissen, werknemers, tussenpersonen of vertegenwoordigers jegens u aansprakelijk zijn voor incidentele, indirecte, gevolg-, statutaire, bovenmatige, bijzondere of speciale schade, of voor verlies van opbrengsten, verlies van verkoopkansen, verlies van reputatie, verlies van gebruik, verlies van data, verlies van andere immateriële activa, of onbevoegde onderschepping van via de software door derden verzamelde gegevens, al of niet voorspelbaar, op welke wijze dit ook is veroorzaakt, en krachtens enig contract, onrechtmatige daad (inclusief, zonder beperking, nalatigheid), burgerlijke aansprakelijkheid of andere theorie, ook indien vooraf de mogelijkheid is gecommuniceerd dat zodanige schade kan voorkomen.",
    "demo.index.content.line1": "Tijdens deze demonstratie gebruiken we uw camera om in te schatten waar u naar kijkt. Maak u geen zorgen, we maken geen foto's of video's."
  }
  