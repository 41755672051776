export default () => {
    return new Promise((resolve, reject) => {
        if (!!!navigator || !!!navigator.mediaDevices || !!!navigator.mediaDevices.enumerateDevices) {
          resolve({
            success: "false",
            message: "Enumerate devices not supported",
          });
        } else {
          navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
              resolve({
                success: true,
                devices: devices.filter((device) => device.kind === "videoinput"),
              });
            })
            .catch((err) => {
              resolve({
                success: false,
                message: "Error when enumerating devices",
              });
            });
        }
      });
};
