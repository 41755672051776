import LumenEyeTracking from "..";
import tabChangeListener from "../../../utils/listeners/TabChangeListener";
import revocablePromise from "../helpers/revocablePromise";
import LoggerController from "../../../controllers/Logger/LoggerController";
import { routeType } from "../../util/util";

class BaseStep {
  constructor(stepName, sectionName) {
    this.state = "notstarted";
    this.rejector = null;
    this.resolver = null;
    this.promise = null;
    this.stepName = stepName;
    this.sectionName = sectionName;
    this.startTime = undefined;
    this.completeTime = undefined;
    this.resolved = false;
    this.result = null;

    this.setupErrorListener = this.setupErrorListener.bind(this);
    this.removeErrorListener = () => {};
  }

  async cancel() {
    this.state = "cancelled";
    LoggerController.events.logSetupProgress.panelistAction(
      `${this.stepName} - cancelled`
    );
    this.removeErrorListener();
    LumenEyeTracking.lastError = new Error(
      "User hit back button during eyetracking setup"
    );
    LumenEyeTracking.lastError.name = "BackButtonError";
    LumenEyeTracking.lastError.retryCode = "backButton";

    LumenEyeTracking.errorHandler(
      this.resolver,
      this.cleanUp,
      "cancel",
      this.startTime
    );

    await this.promise;
  }

  async runProcess() {}

  async onComplete() {
    let logData = await LumenEyeTracking.getLogData(false);
    LoggerController.events.logSetupProgress.panelistAction(`${this.stepName} - completed`)
    LoggerController.sendToExtendedLogger(
      routeType(),
      "action",
      undefined,
      undefined,
      `${this.stepName}_complete`,
      this.stepName
    );
    LumenEyeTracking.runningStep = null;
    this.state = "completed";
    this.completeTime = new Date().getTime();
    this.removeErrorListener();
  }

  async onError() {
    this.state = "errored";
    this.removeErrorListener();
  }

  async cleanUp() {}

  setupErrorListener(callback) {
    document.addEventListener(LumenEyeTracking.onErrorEvent, callback);
    this.removeErrorListener = () => {
      document.removeEventListener(LumenEyeTracking.onErrorEvent, callback);
    };
  }

  start(...args) {
    LoggerController.events.logSetupProgress.log(`${this.stepName} - started`)
    LoggerController.sendToExtendedLogger(
      routeType(),
      "action",
      undefined,
      undefined,
      `${this.stepName}_start`,
      this.stepName
    );

    LumenEyeTracking.runningStep = this;
    LumenEyeTracking.currentStep = this.stepName;
    tabChangeListener.setLastUserAction(`broker ${this.stepName} started`);

    this.state = "started";
    this.startTime = new Date().getTime();

    let [resolver, rejector, promise] = revocablePromise(this.runProcess, args);
    this.rejector = rejector;
    this.resolver = (payload) => {
      this.resolved = true;
      this.result = payload;
      resolver(payload);
    };
    this.promise = promise;

    return promise;
  }
}

export default BaseStep;
