import urlWithRegion from "../urlWithRegion";
import * as DEBUGLOG from "../../../components/util/debugLog";
import requestTimer from "../../requestTimer";
import { store } from "../../../store";
import { setWaitingForApi } from "../../../reducers/actions";
import tabChangeListener from "../../../utils/listeners/TabChangeListener";

const taskPage = async (pageId, selectedLanguage) => {
  tabChangeListener.setWaitingForApi("taskPage");
  let instructionRequestDate = DEBUGLOG.debugLogRequestStart("instructions");
  let response = await fetch(
    urlWithRegion(true) +
      "/task_pages/page_json/" +
      pageId +
      "?language=" +
      selectedLanguage
  );

  DEBUGLOG.debugLogRequestFinish(instructionRequestDate);

  if (response.ok) {
    let responseBody = await response.json();
    tabChangeListener.setWaitingForApi("");
    return responseBody;
  } else {
    let e = new Error(response.statusText);
    e.name = response.status || "";
    throw e;
  }
};

export default (...args) => requestTimer(() => taskPage(...args));
