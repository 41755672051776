import { applyMiddleware, createStore } from "redux";
import rootReducer from "../reducers/index";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {encryptTransform} from 'redux-persist-transform-encrypt';
import thunk from "redux-thunk";

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_STORE_KEY
});

const lumenStorage = !!localStorage ? storage : storageSession;

const persistConfig = {
 key: 'root',
 storage: lumenStorage,
 stateReconciler: autoMergeLevel2,
 transforms: [encryptor],
 blacklist:['previewReducer']
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
