import * as DEBUGLOG from "../../../components/util/debugLog";
import requestTimer from "../../requestTimer";
import tabChangeListener from "../../../utils/listeners/TabChangeListener";
import participationUrl from "../participationUrl";

const preStudyQuestionResponse = async (studyId, body) => {
    tabChangeListener.setWaitingForApi("preStudyQuestionResponse");
    let preStudyQuestionRequestDate = DEBUGLOG.debugLogRequestStart("pre-study-question-response");
    let response = await fetch(participationUrl() + "/participation/studies/" + studyId + "/pre-study-question-response", {
      method: 'POST',
      body: JSON.stringify(body) 
    })

    DEBUGLOG.debugLogRequestFinish(preStudyQuestionRequestDate);

    if (response.ok) {
        let responseBody = await response.json()
        tabChangeListener.setWaitingForApi("");
        return responseBody
    } else {
      let e = new Error(response.statusText);
      e.name = response.status || "";
      throw e
    }
}

export default (...args) => requestTimer(() => preStudyQuestionResponse(...args))