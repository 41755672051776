export default class {
  constructor(identifier, url, getPayload) {
    this.identifier = identifier;
    this.url = url;
    this.getPayload = getPayload;
  }

  call = () => {
    navigator.sendBeacon(this.url, JSON.stringify(this.getPayload()));
  };
}
