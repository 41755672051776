import { initParams } from "../lumenController/actions";
import {
  debugState,
  changeStateTo,
  resetLoadedAt,
} from "../../reducers/actions";
import { store } from "../../store/index";
import { routeType, reformatParams } from "./util";
import {
  navigateWithParams,
  navigateFromLastKnownPath,
} from "@history/";
import studyHistory from "./studyHistory";
import backListener from "../../utils/listeners/backListener";
import TabChangeListener from "../../utils/listeners/TabChangeListener";
import gestureListener from "../../utils/listeners/gestureListener";
import ResizeListener from "../../utils/listeners/ResizeListener";
import MouseMoveListener from "../../utils/listeners/MouseMoveListener";
import StepController from "../../controllers/StepController";
import LoggerController from "../../controllers/Logger/LoggerController";
import DeviceController from "../../utils/controllers/DeviceController";
import ErrorController from "../../controllers/Error/ErrorController";

export const onBeforeLift = () => {
  return new Promise((resolve, reject) => {
    backListener.register();
    TabChangeListener.register();
    gestureListener.register();
    MouseMoveListener.subscribe();
    ResizeListener.subscribe();
    LoggerController.events.logReturnedToPlatform.panelistAction(window.location.pathname)

    let stateRefreshed = false;
    let staleState = false;
    let requestedRoute = routeType();

    let {
      studyId,
      participant,
      cellId,
      accessCode,
      brokerDetails,
      prestartChecksComplete,
      startChecksComplete,
      instructionsChecksComplete,
      sessionId,
      testType,
      surveyComplete,
      useExtendedLogger
    } = store.getState().studyReducer;

    if(requestedRoute === "preview"){
      resolve()
      return
    } else if (requestedRoute === "restore"){
      store.dispatch(resetLoadedAt())
    }
    
    if(studyId && participant && useExtendedLogger){
      let {isMoblie, os, name} = DeviceController.getDeviceInfo()
      // LoggerController.initExtendedLogger(participant, isMoblie ? 'mobile' : 'desktop', os, name.toLowerCase(), studyId)
      // LoggerController.setCellId(cellId)
    }

    LoggerController.sendToExtendedLogger(requestedRoute ? requestedRoute : "preload", "action", undefined, undefined, "return", window.location.pathname)

    const urlParams = new URLSearchParams(window.location.search);
    var urlParticipant = urlParams.get("participant");
    var returned = urlParams.get("return");
    var invalidSuccess = urlParams.get("success");
    var debug = urlParams.get("debug");
    var incrementSteps = urlParams.get("incrementSteps");
    let isTest = urlParams.get("test");
    let reset = urlParams.get("reset");

    let requestStudyId = parseInt(window.location.pathname.split("/")[2]);

    let loadedHistory = studyHistory.loadHistory(
      studyId || requestStudyId,
      participant || null
    );

    if (
      requestedRoute !== "init" &&
      requestedRoute !== "restore" &&
      loadedHistory &&
      loadedHistory.result === "fail"
    ) {
      store.dispatch(
        initParams(loadedHistory.studyId, loadedHistory.accessCode)
      );
      LoggerController.events.logPreviousFail.log(`User has previously failed this study ${JSON.stringify(loadedHistory)}`)
      ErrorController.warnings.PreviousFail.raise().then(() => {
        resolve();
      });
      return;
    } else if (
      requestedRoute !== "init" &&
      requestedRoute !== "restore" &&
      loadedHistory &&
      loadedHistory.result === "success"
    ) {
      store.dispatch(
        initParams(loadedHistory.studyId, loadedHistory.accessCode)
      );
      ErrorController.warnings.PreviousSuccess.raise().then(() => {
        resolve();
      });
      return;
    }

    //Refresh state
    let ONE_HOUR = 60 * 60 * 1000;
    let currDate = new Date();
    let loadedDate = new Date(store.getState().baseReducer.loadedAt);
    if (isTest === "1" || isTest === "2" || isTest === "3" || isTest === "4") {
      store.dispatch({ type: "HARD_RESET", payload: "test user" });
      stateRefreshed = true;
    } else if (requestedRoute !== "restore" && currDate - loadedDate > ONE_HOUR) {
      store.dispatch({
        type: "HARD_RESET",
        payload: `stale state {currTime: "${currDate.toLocaleDateString()} ${currDate.getHours()}:${currDate.getMinutes()}", startTime: "${loadedDate.toLocaleDateString()} ${loadedDate.getHours()}:${loadedDate.getMinutes()}"}`,
      });
      stateRefreshed = true;
      staleState = true;
    } else if (
      !!urlParticipant &&
      !!participant &&
      urlParticipant !== participant
    ) {
      store.dispatch({
        type: "HARD_RESET",
        payload: `participant changed {url: ${urlParticipant}, state:${participant}}`,
      });
      stateRefreshed = true;
    } else if (
      !!requestStudyId &&
      !!studyId &&
      parseInt(studyId) !== parseInt(requestStudyId)
    ) {
      store.dispatch({
        type: "HARD_RESET",
        payload: `studyId changed {url: ${requestStudyId}, state: ${studyId}}`,
      });
      stateRefreshed = true;
    } else if (reset === "1") {
      store.dispatch({ type: "HARD_RESET", payload: "manual reset" });
      stateRefreshed = true;
    }

    let routeExceptions = [
      "init",
      "prestart",
      "error",
      "invalidation",
      "preview",
      "restore",
    ];

    if (staleState && !routeExceptions.includes(requestedRoute)) {
      LoggerController.events.logRouteError.log(`Stale State (${requestedRoute})`)
      ErrorController.warnings.StaleState.raise().then(() => {
        resolve();
      });
      return;
    } else if (stateRefreshed && !routeExceptions.includes(requestedRoute)) {
      LoggerController.events.logRouteError.log(`Refreshed State (${requestedRoute})`)
      ErrorController.warnings.RouteError.raise().then(() => {
        resolve();
      });
      return;
    } else if (
      !accessCode &&
      !studyId &&
      !routeExceptions.includes(requestedRoute)
    ) {
      LoggerController.events.logRouteError.log(`Missing Params (${requestedRoute})`)
      ErrorController.warnings.RouteError.raise().then(() => {
        resolve();
      });
      return;
    } else if (stateRefreshed && requestedRoute === "init") {
      return resolve();
    }

    if (
      (window.location.pathname == "/study" ||
        window.location.pathname == "/study/") &&
      !!store.getState().baseReducer.lastKnownPath
    ) {
      if (typeof returned !== undefined && invalidSuccess === "false") {
        let newParams = reformatParams(urlParams, []);
        navigateFromLastKnownPath(
          store.getState().baseReducer.lastKnownPath,
          "landing",
          newParams
        );
        resolve();
      } else {
        let newParams = reformatParams(urlParams, []);
        navigateFromLastKnownPath(
          store.getState().baseReducer.lastKnownPath,
          "landing",
          newParams
        );
        resolve();
      }
    }

    store.dispatch({ type: "PROGRESS_BAR_TIMING", payload: 0 });

    if (
      debug === "true" &&
      process.env.REACT_APP_LUMEN_ENV_VALUE !== "release"
    ) {
      store.dispatch(debugState());
      return resolve();
    }

    let routeDict = {
      init: 1,
      prestart: 2,
      index: 3,
      instructions: 4,
      setup: 5,
      survey: 10,
      study: 20,
      error: 30,
      invalidation: 40,
      demographics: 50,
      restore: 60,
      landing: 70,
    };

    let routeEnum = routeDict[requestedRoute] || 999;

    console.log(`routeType: ${requestedRoute} routeEnum: ${routeEnum}`);

    if (routeEnum === 70) {
      let newParams = reformatParams(urlParams, []);
      navigateWithParams("landing", newParams);
    } else if (routeEnum === 50) {
      if (testType === "3" || testType === "4") {
        store.dispatch(changeStateTo("instructions"));
        let newParams = reformatParams(urlParams, []);
        navigateWithParams("instructions", newParams);
        return resolve();
      } else {
        return resolve();
      }
    } else if (routeEnum > 20) {
      return resolve();
    } else if (routeEnum === 20) {
      let currentStep = StepController.getCurrentStep()
      urlParams.set('reinit', 'true')
      let newParams = reformatParams(urlParams, []);
      navigateWithParams(`${cellId}/${currentStep.id}/`, newParams);
      resolve()
    } else if (routeEnum === 10) {
      return resolve();
    } else if (routeEnum <= 5 && routeEnum > 0) {
      if (instructionsChecksComplete && sessionId && brokerDetails) {
        // go to setup
        LoggerController.events.logNavigate.log("Setup", "PreloadController")
        store.dispatch(changeStateTo("setup"));
        let newParams = reformatParams(urlParams, []);
        navigateWithParams("setup", newParams);
        return resolve();
      } else if (brokerDetails && startChecksComplete && surveyComplete) {
        // go to instructions
        LoggerController.events.logNavigate.log("Instructions", "PreloadController")
        store.dispatch(changeStateTo("instructions"));
        let newParams = reformatParams(urlParams, []);
        navigateWithParams("instructions", newParams);
        return resolve();
      } else if (prestartChecksComplete) {
        // go to start
        LoggerController.events.logNavigate.log("Start", "PreloadController")
        store.dispatch(changeStateTo("index"));
        let newParams = reformatParams(urlParams, []);
        navigateWithParams("start", newParams);
        return resolve();
      } else if (studyId && accessCode && participant) {
        // go to prestart
        LoggerController.events.logNavigate.log("Prestart", "PreloadController")
        store.dispatch(changeStateTo("prestart"));
        let newParams = reformatParams(urlParams, []);
        navigateWithParams("prestart", newParams);
        return resolve();
      } else {
        return resolve();
      }
    } else {
      ErrorController.warnings.RouteError.raise().then(() => {
        return resolve();
      });
    }
  });
};
