{
  "app.thankyou":"Merci",
  "app.button.faqs": "FAQ",
  "app.button.terms": "Conditions d'utilisation",
  "app.button.privacy": "Politique de confidentialité",
  "app.button.feedback": "Commentaires",
  "app.button.selectLanguage": "Sélection de la langue",
  "app.button.help": "Aide",
  "app.button.start": "Démarrer",
  "app.button.next": "Suivant",
  "app.button.submit": "Envoyer",
  "app.button.close": "Fermer",
  "app.button.takepart": "D’accord, je vais participer.",
  "app.menu.feedback.content": "Si vous avez rencontré un problème au cours de cette enquête, ou si vous souhaitez simplement laisser un commentaire, n'hésitez pas à le faire ici. ",
  "app.contactModal.feedback": "Merci pour vos commentaires !",
  "app.takePartModal.line1": "Si vous quittez l’enquête maintenant, vous ne serez pas récompensé(e) pour votre participation.",
  "app.takePartModal.line2": "Il vous suffit d’avoir une webcam !",
  "app.takePartModal.reason1": "Je n’ai pas de webcam.",
  "app.takePartModal.reason2": "Je ne suis pas à l’aise à l’idée d’utiliser ma webcam pour cette enquête.",
  "app.takePartModal.reason3": "Je n’ai pas le temps maintenant.",
  "app.takePartModal.reason4": "Autre raison",
  "app.generic.redirect": "Vous allez être redirigé(e) dans 3 secondes.",
  "app.init.welcome": "Bienvenue sur la plateforme d’étude Lumen Research",
  "app.init.intro": "La plateforme d’étude nous permet d’intégrer et d’inscrire des utilisateurs à une étude publicitaire donnée.",
  "app.init.message1": "Selon le type d’étude, un utilisateur sera guidé à travers une série d’exercices.",
  "app.init.message2": "Ces exercices comprennent : répondre à un questionnaire, lire une série d’instructions et effectuer un étalonnage de suivi oculaire",
  "app.init.message3": "Nous allons à présent vous guider à travers une étude de démonstration.",
  "app.init.message4": "Tout au long de cette étude, nous pourrons activer votre webcam pour qu’elle effectue un suivi oculaire, mais aucune de vos données ne sera enregistrée.",
  "app.init.message5": "L’étude actuelle est réglée pour utiliser les liens suivants lorsque la personne interrogée est écartée de l’enquête/a achevé l’enquête.",
  "app.prestart.loading": "Chargement en cours. Veuillez patienter.",
  "app.index.title": "Bienvenue",
  "app.index.content.line1": "Au cours de cette enquête, nous utiliserons une caméra pour estimer où vous portez votre regard. Ne vous inquiétez pas, nous ne prendrons ni photos ni vidéos.",
  "app.index.content.line1alt": "Veuillez vous installer confortablement et appuyer sur « Envoyer » pour débuter l’enquête.",
  "app.index.content.line2": "Votre appareil est-il équipé d'une caméra ? Dans l'affirmative, veuillez appuyer sur le bouton démarrer ci-dessous.",
  "app.index.rejection": "Nous sommes désolés mais il semble que vous ayez suivi un lien mal formé.",
  "app.index.webview": "Nous sommes désolés mais il semble que vous ayez accédé à notre page depuis un navigateur web non valide. Veuillez ouvrir ce lien dans votre navigateur. Il se peut que vous puissiez le faire directement depuis le menu web.",
  "app.index.webview.chrome.1": "Tapez sur le bouton menu ci-dessus puis sélectionnez « Ouvrir dans Chrome ». ",
  "app.index.webview.chrome.2": "Cela ouvrira l'enquête dans une appli du navigateur, elle pourra ainsi fonctionner correctement.",
  "app.index.webview.android.1": "Tapez sur Démarrer ci-dessous pour ouvrir l'enquête dans une appli du navigateur afin qu'elle puisse fonctionner correctement.",
  "app.index.webview.android.2": "Si cela échoue, ouvrez le lien d'accès à cette page manuellement dans une appli de navigateur (par ex. faites un copier-coller du lien dans la barre d'adresse dans Chrome ou bien utilisez une option comme « Ouvrir dans Chrome » dans la barre de menu ci-dessus).",
  "app.index.webview.safari.1": "Tapez sur l'icône Safari au bas de l'écran pour continuer.",
  "app.index.webview.safari.2": "Elle apparait comme ceci :",
  "app.index.webview.safari.3": "Cela ouvrira l'enquête dans Safari, elle pourra ainsi fonctionner correctement.",
  "app.index.wifi": "Votre connexion internet semble être trop lente. Êtes-vous connecté(e) au WiFi ?",
  "app.index.tellmemore": "Dites m'en plus.",
  "app.index.takepart": "Je ne veux pas participer.",
  "app.index.webcamRetry": "Nous n’avons pas été en mesure de détecter une webcam, qui est nécessaire pour cette étude. Veuillez autoriser l’accès à votre webcam et réessayer.",
  "app.index.adblockRetry": "Tous les logiciels anti-publicité doivent être désactivés pendant toute la durée de cette étude. Veuillez désactiver votre bloqueur de publicité et réessayer.",
  "app.instructions.title": "Instructions",
  "app.instructions.instruction1": "Si possible, évitez de porter des lunettes pendant l’enquête.",
  "app.instructions.instruction2": "Asseyez-vous de façon à pouvoir garder votre tête et l’appareil immobiles.",
  "app.instructions.instruction3": "Bougez vos yeux, pas votre tête.",
  "app.instructions.instruction4": "Vous êtes prêt(e) à commencer ?",
  "app.instructions.instruction5": "Nous vous conseillons d'utiliser le Wi-Fi pour éviter les frais de données mobiles.",
  "app.instructions.instruction6": "Merci de lire attentivement les instructions suivantes.",
  "app.instructions.instruction7": "Posez votre téléphone sur une surface stable.",
  "app.instructions.instruction8": "Assurez-vous d’être dans une pièce bien éclairée, de préférence par une source de lumière au-dessus de vous et non pas derrière vous.",
  "app.setup.title": "Installation",
  "app.setup.instruction0": "Assurez-vous que votre appareil est en mode portrait.",
  "app.setup.instruction0a": "Veuillez incliner votre appareil pour le mettre en mode portrait.",
  "app.setup.instruction0b": "Veuillez incliner votre appareil pour que la caméra soit sur la gauche.",
  "app.setup.instruction0c": "Veuillez incliner votre appareil pour que la caméra soit sur la droite.",
  "app.setup.instruction0d": "Veuillez incliner votre appareil pour le mettre en mode paysage.",
  "app.setup.instructioncamera": "Veuillez cliquer sur le bouton ci-dessous pour demander l’accès à la caméra. Pour répondre à ce questionnaire vous devez autoriser l’accès à la caméra tout au long de l’étude.",
  "app.setup.cameraText": "Demande d’accès",
  "app.setup.instruction1": "Initialisation en cours...",
  "app.setup.instruction2": "Lorsque votre visage est détecté, gardez votre tête à l’intérieur de l’encadré.",
  "app.setup.instruction3": "Maintenez votre tête et votre appareil en position jusqu’à ce que l’enquête soit terminée.",
  "app.setup.instruction4": "Maintenant il ne nous reste plus qu'à valider.",
  "app.setup.instruction5": "Merci ! N’oubliez pas de maintenir votre tête et votre appareil dans la même position jusqu’à ce que l’enquête soit terminée.",
  "app.setup.instruction6": "Veuillez balayer l’écran vers le haut pour dissimuler la barre d’adresse.",
  "app.setup.retry": "Désolés, il y a eu un petit problème. Utilisez le bouton ci-dessous pour réessayer.",
  "app.setup.retryInit": "Une erreur a été détectée. Veuillez vous assurer que le navigateur a accès à votre caméra. Si vous avez précédemment refusé les autorisations d'accès à la caméra, il faudra que vous les acceptiez dans les paramètres de confidentialité de votre navigateur. Utilisez le bouton ci-dessous pour réessayer.",
  "app.setup.retryButton": "Réessayer",
  "app.setup.calibrationProp.watch": "Suivez le point quand il apparait sur l'écran",
  "app.setup.calibrationProp.watch.george": "Ensuite, lorsque vous verrez un visage, continuez à le regarder tout en imitant les mouvements de sa tête",
  "app.setup.calibrationProp.click": "Cliquez n'importe où pour continuer",
  "app.setup.calibrationProp.tap": "Tapez n'importe où pour continuer",
  "app.study.validationProp.watch": "Suivez le point quand il apparait sur l'écran",
  "app.study.validationProp.restart": "Veuillez réessayer.",
  "app.study.validationProp.click": "Cliquez n'importe où pour continuer",
  "app.study.validationProp.tap": "Tapez n'importe où pour continuer",
  "app.study.render.error": "Domaine non valide pour l’étape de rendu.",
  "app.error.title": "Erreur",
  "app.error.generic": "Oups ! Il y a eu un petit problème.",
  "app.error.initialise": "Initialisation impossible !",
  "app.error.findCamera": "Détection de la caméra impossible !",
  "app.error.headPosition": "Identification de la position de votre tête impossible.",
  "app.error.calibrate": "Calibrage impossible.",
  "app.error.validate": "Validation impossible.",
  "app.error.start": "Le démarrage a échoué.",
  "app.error.assign": "L'attribution a échoué.",
  "app.error.osnotallowed": "Votre système d'exploitation n'est pas autorisé, veuillez vérifier quel appareil vous pouvez utiliser pour cette enquête puis réessayez.",
  "app.error.demoquotafullnoactivecell": "Merci pour l'intérêt que vous portez à cette enquête. Cette étude est maintenant terminée pour le profil démographique qui vous correspond.",
  "app.invalid.title": "Non valide",
  "app.invalid.generic": "Nous sommes désolés mais vous ne répondez pas aux critères de cette enquête.",
  "app.completion.title": "Achevé(e)",
  "app.completion.congratulations": "Félicitations ! Vous avez terminé l'enquête. L’accès de l'enquête à votre caméra va maintenant être supprimé.",
  "app.questions.required": "Cette question est obligatoire.",
  "app.faq.question1": "Q : Qu'est-ce que la Plateforme d'étude Lumen et en quoi consiste la participation à une étude oculométrique ?",
  "app.faq.answer1": "R : Cette étude utilise la technologie de l'oculométrie, aussi appelée « suivi oculaire » ou « eye-tracking ». Il vous sera demandé de vous asseoir en face de la webcam de votre appareil ou de sa caméra frontale et de vous soumettre à un calibrage afin que l'on puisse estimer quelle partie de l'écran vous regardez. Au cours de l'enquête, il vous sera demandé de regarder certains éléments (par ex. images, sites web ou vidéos) tandis que l'outil utilisé pour l'enquête estime où se porte votre regard. Les données saisies à partir de ce suivi oculaire sont les coordonnées des points où l'on estime que se porte votre regard sur l'écran pendant la durée de l'enquête. Le logiciel utilise des images de vous venant de votre webcam pour effectuer ces estimations, mais il ne saisit aucune photo ou vidéo de vous. Il est possible également que l'on vous pose des questions sur vous-même, votre expérience ou ce que vous avez vu au cours de l'enquête. Les données sont envoyées à Lumen Research Ltd. Lumen Research analysera ces données et partagera les résultats avec ses clients.",
  "app.faq.question2": "Q : Pourquoi le logiciel requiert-il une webcam/caméra et comment est-elle utilisée ?",
  "app.faq.answer2": "R : Le logiciel utilise les images en provenance de votre webcam ou de la caméra de votre appareil pour estimer où se porte votre regard sur l'écran. Pour ce faire, on utilise une combinaison de logiciels de suivi facial et de suivi oculaire qui analysent les images localement sur votre machine, en temps réel. Ces images sont traitées et vous sont montrées au cours du calibrage afin que vous puissiez vous positionner correctement mais les images ne sont pas enregistrées, votre vie privée est donc protégée. Les données qui sont enregistrées et collectées à partir du suivi oculaire ne sont pas des images mais des données numériques relatives aux points des stimulus sur lesquels on estime que se porte votre regard pendant la durée de l'enquête.",
  "app.faq.question3": "Q : Prenez-vous des photos de moi au cours du suivi oculaire ?",
  "app.faq.answer3": "R : La seule fois où des images sont utilisées c'est pour vous permettre de vous positionner correctement lors du calibrage. Ces images ne sont pas sauvegardées et vous êtes le/la seul(e) à les voir. Les données qui sont enregistrées et collectées à partir du suivi oculaire ne sont pas des images mais des données numériques relatives aux points des stimulus sur lesquels on estime que se porte votre regard.",
  "app.faq.question4": "Q : Quelles données sont collectées au cours de l'enquête ?",
  "app.faq.answer4.line1": "R : Le logiciel collectera une partie, ou la totalité, des cinq types de données suivants :",
  "app.faq.answer4.line2": "« Identifiant » - L'identifiant est utilisé pour identifier les répondants (afin de gérer les récompenses accordées pour la participation)",
  "app.faq.answer4.line3": "« Données relatives au regard » – Estimations de l'endroit où les utilisateurs regardent sur leur écran quand des stimulus sont affichés",
  "app.faq.answer4.line4": "« Données relatives à l'enquête » – Réponses aux questions données par les utilisateurs",
  "app.faq.answer4.line5": "« Métadonnées » - Données concernant le moment auquel les personnes ont participé avec succès à l'enquête",
  "app.faq.answer4.line6": "« Données relatives à l'appareil physique » – Données concernant l'ordinateur sur lequel un utilisateur participe, par ex. le niveau de mémoire vive, le type d'unité centrale dans l'appareil et l'agent utilisateur (système d'exploitation et version du navigateur) ainsi que des informations concernant l'écran de l'ordinateur (taille et résolution), la webcam (marque et résolution), et programme antivirus.",
  "app.faq.question5": "Q : Comment seront utilisées les données qui auront été collectées ?",
  "app.faq.answer5.line1": "R : Les données collectées seront utilisées pour étudier comment les gens regardent et interagissent avec différents types d'images et supports visuels et pour jauger leur avis sur les images qui leur ont été montrées.",
  "app.faq.answer5.line2": "Il est possible que Lumen partage les données issues de l'enquête avec des sociétés affiliées ainsi qu'avec des associés et des clients. Bien que les données issues de l'enquête puissent être partagées avec des sociétés affiliées, associés et clients cela se fait normalement sous le sceau de l'anonymat. Il est possible que ces sociétés affiliées, associés et clients utilisent ces données pour élaborer des descriptions sommaires d'un vaste éventail de comportements de consommation et de segments de consommateurs afin de développer des produits ou de fournir des solutions marketing à leurs clients et consommateurs.",
  "app.faq.answer5.line3": "Lumen recueille des « Données relatives à l'appareil physique » et des « Métadonnées » pour l'aider dans la gestion de la collecte et du traitement des données, notamment la gestion du contrôle qualité et du volume.",
  "app.faq.answer5.line4": "Les données collectées seront conservées pendant la période nécessaire à la réalisation des objectifs exposés.",
  "app.faq.question6": "Q : Quel est le degré de sécurité de mes données ?",
  "app.faq.answer6": "R : Les données collectées par Lumen sont chiffrées grâce à la technologie SSL. Il s'agit du protocole de chiffrement de haut niveau le plus communément utilisé, notamment par les banques pour sécuriser les transactions bancaires et autres communications très sensibles. Il est aussi utilisé par Amazon, PayPal, eBay et d'autres sites web exigeant des systèmes de sécurisation semblables à ceux des banques. Les scientifiques estiment que pour briser le chiffrement 256 bits avec la technologie actuelle il faudrait considérablement plus de temps que l'âge de l'univers. Les données collectées sont stockées sur des serveurs gérés par Amazon Web Services (AWS). AWS est l'une des plateformes des services informatiques en cloud les plus sûres et les plus fiables, elle est utilisée par les plus grands services commerciaux basés sur le cloud comme Netflix et Expedia.",
  "app.faq.question7": "Q : Quelles sont mes obligations à l'égard du contenu qui m'est révélé ?",
  "app.faq.answer7": "R : Lorsque vous participez, il est entendu que vous comprenez également et acceptez la possibilité que le contenu qui vous est révélé dans cette étude soit confidentiel, la propriété d'un tiers et/ou soumis aux lois protégeant les marques déposées et droits d'auteur, et que vous ne ferez pas de captures d'écran, n'enregistrerez pas, ne copierez pas de toute autre façon que ce soit ou ne rediffuserez pas tout ou partie du contenu de l'étude.",
  "app.faq.question8": "Q : Je ne suis pas sûr(e) de la qualité de ma webcam, fonctionnera-t-elle pour le suivi du regard ?",
  "app.faq.answer8": "R : Le système fonctionne avec une grande variété de webcams, cela ne devrait pas être un problème.",
  "app.faq.question9": "Q : J'ai terminé l'enquête, ai-je autre chose à faire ?",
  "app.faq.answer9": "R : Non, vous n'avez rien d'autre à faire. Le logiciel va se fermer automatiquement. Vous pouvez maintenant désinstaller le logiciel et supprimer le fichier que vous avez téléchargé.",
  "app.faq.question10": "Q : Faut-il que je sois connecté(e) à l'Internet pour participer ?",
  "app.faq.answer10": "R : Oui, vous aurez besoin d'une connexion Internet pour télécharger le programme et accomplir la tâche.",
  "app.faq.question11": "Q : Faut-il que j'accomplisse la tâche sur une surface stable ?",
  "app.faq.answer11": "R : Oui, pour garantir les meilleurs résultats possibles il faut que vous soyez confortablement assis(e) devant votre écran d'ordinateur et, après le calibrage, vous devrez garder votre tête immobile pendant que l'on vous présentera les stimulus, et cela jusqu'à instruction contraire. Nous vous conseillons de ne pas participer si vous êtes dans un train ou tenez votre ordinateur portable en équilibre sur vos genoux, etc.",
  "app.faq.question12": "Q : La FAQ n'a pas résolu mon problème, qu'est-ce que je fais maintenant ?",
  "app.faq.answer12": "R : Veuillez nous envoyer un e-mail à study-feedback@lumen-research.com et nous vous répondrons dès que possible.",
  "app.faq.patent": "Les tests de contenu web réalisés par Lumen s'appuient sur notre propre technologie propriétaire de tests en contexte (Dépôt de brevet au Roy-Uni Numéro 1816158.8)",
  "app.terms.line1": "Les présentes conditions d’utilisation (les « Conditions d’utilisation ») régissent l’accord entre Vous et Lumen Research Ltd (« Lumen » ou « nous ») – une société enregistrée en Angleterre, dont le numéro d’immatriculation est 8682432, le siège social est sis Unit 215, 22 Highbury Grove, Londres N5 2EF et le numéro d’inscription pour la protection des données est ZA130772 – en ce qui concerne votre utilisation du logiciel d’oculométrie « ViewPoints » (le « Logiciel ») sur votre appareil.",
  "app.terms.line2": "1. Acceptation des conditions générales",
  "app.terms.line3": "En utilisant le Logiciel, vous reconnaissez avoir accepté d’être lié(e) par le présent Contrat. Si vous n’acceptez pas les conditions générales de ce Contrat, il vous est demandé de ne pas répondre à l’enquête ViewPoints.",
  "app.terms.line4": "2. Informations confidentielles",
  "app.terms.line5": "En cas d’installation, d’utilisation ou de toute autre forme de recours à ViewPoints, vous pouvez avoir accès à des informations et/ou à du contenu appartenant ou se rapportant à Lumen, y compris des informations identifiées ou traitées comme confidentielles par Lumen ou par l’un de ses clients, ou qui, en raison de leur nature, des circonstances ou de la manière dont elles sont divulguées, sont manifestement confidentielles, y compris (sans préjudice du caractère général des dispositions qui précèdent) toute information relative aux plans d’affaires, au développement de nouvelles opportunités d’affaires, aux projets de recherche et de développement, aux concepts, aux procédés confidentiels, aux codes, aux conceptions de logiciels, aux secrets commerciaux, au développement et aux formules de produits ou de services, au savoir-faire, aux inventions, aux statistiques et prévisions de ventes, aux stratégies et plans de marketing, aux coûts, aux profits et pertes et aux autres informations financières (sauf dans la mesure où elles sont publiées dans des comptes vérifiés), aux prix et aux structures de remise (tous ces éléments étant ou non consignés par écrit ou sous forme électronique ou dans un autre format) (les « Informations confidentielles »). De telles Informations confidentielles demeurent la propriété unique et exclusive de Lumen et/ou de ses clients et, en aucun cas, vous n’obtiendrez ni ne recevrez de droits, de titres et/ou d’intérêts sur ces Informations confidentielles.",
  "app.terms.line6": "Il vous est interdit, pendant votre participation à l’enquête ViewPoints, ainsi qu’à tout moment (sans limite) après la fin de cette participation, de divulguer ou de communiquer à qui que ce soit ; d’utiliser à des fins personnelles ; ou par manque de soin et de diligence, d’être à l’origine d’une divulgation non autorisée de toute Information confidentielle. Vous devez en tout temps faire tout votre possible pour empêcher la publication ou la divulgation de toute Information confidentielle. Ces restrictions ne s’appliqueront pas aux informations qui deviendraient accessibles au grand public, de manière générale, autrement que par votre faute.",
  "app.terms.line7": "3. Propriété de Lumen",
  "app.terms.line8": "Le Logiciel ViewPoints, ainsi que tous les codes, données, clés, mots de passe, concepts, dessins, enregistrements et autres documents ou matériaux en lien avec le Logiciel, sont et demeureront la propriété de Lumen pendant toute la durée de votre participation à l’enquête ViewPoints. Lumen détient tous les droits de propriété intellectuelle, droits de propriété industrielle et droits exclusifs existants et futurs sur tous les documents, matériaux, modèles, concepts, dessins, procédés, inventions, formules, codage informatique, données, interfaces de systèmes, méthodologies, savoir-faire, Informations confidentielles ou autres travaux, réalisés, fabriqués, créés, élaborés, développés, améliorés, décomposés ou découverts par vous, seul(e) ou avec toute autre personne, en lien avec le Logiciel et/ou l’Appareil ou pouvant être utilisés ou adaptés pour être utilisés sur celui-ci ou en lien avec celui-ci (qu’il soit enregistré ou non), y compris tous les brevets, droits d’auteur, droits de conception, droits de base de données, marques commerciales, droits de topographie des semi-conducteurs, droits d’obtention végétale, droits internet/noms de domaine, savoir-faire futurs et existants, ainsi que toutes les applications de ce qui précède et tous les droits de demander ce qui précède (les « Droits de propriété »). Aucun Droit de propriété ne saurait vous être transféré.",
  "app.terms.line9": "4. Conditions et restrictions d’utilisation",
  "app.terms.line10": "Le Logiciel ViewPoints est concédé sous licence aux utilisateurs finaux. Sous réserve du présent Contrat, nous acceptons par les présentes de vous permettre, à titre non exclusif, révocable, non transférable, non cessible et limité, d’utiliser le Logiciel ViewPoints sur un appareil que vous possédez ou contrôlez ou sur un ordinateur personnel pour lequel vous déclarez et garantissez avoir tous les droits et autorisations nécessaires pour une telle installation. Afin de lever toute ambigüité, vous ne pouvez pas utiliser à des fins autres que celles prévues par ViewPoints, traduire, copier, reproduire, transmettre par télécommunications, adapter, fusionner, changer ou modifier le Logiciel de quelque manière que ce soit.",
  "app.terms.line11": "Vous acceptez de ne pas : (i) copier, modifier, adapter, mettre à disposition, louer, donner à bail, louer en temps partagé, créer une œuvre dérivée ou tenter de vendre ou de transférer de toute autre manière tout droit sur le Logiciel ; (ii) désassembler, décompiler ou faire de l’ingénierie inverse sur toute partie du Logiciel par quelque moyen que ce soit ; ni permettre ou aider une partie quelconque à dériver ou tenter de dériver le code source du Logiciel, désassembler, décrypter, décompiler ou faire de l’ingénierie inverse sur le Logiciel ; ni prendre d’autres mesures afin de trouver des informations de conception concernant le Logiciel ; (iii) utiliser le Logiciel dans un but autre que celui pour lequel il vous a été distribué à l’origine ; (iv) utiliser des spiders, des robots ou d’autres techniques d’exploration de données automatisées pour cataloguer, télécharger, stocker ou autrement reproduire ou distribuer le Logiciel, ou pour manipuler les résultats de toute enquête ou de toute autre activité ; (v) prendre toute mesure visant à interférer avec le Logiciel ou l’utilisation du Logiciel par un individu, y compris, sans toutefois s’y limiter, par la surcharge, l’inondation, le « mail bombing » ou en faisant « crasher » le Logiciel et le système de stockage de données que le Logiciel utilise par le biais d’Amazon Web Services ; (vi) nous envoyer tout code illégal, trompeur ou nuisible, y compris, sans toutefois s’y limiter, tout virus, spyware, adware ou tout autre code nuisible.",
  "app.terms.line12": "Tous les droits non expressément accordés dans ce Contrat nous sont réservés, à nous et à nos concédants de licence. Vous acceptez que nous n’avons aucune obligation, en vertu du présent Contrat ou autre, de corriger les bogues, défauts ou erreurs du Logiciel, ou de prendre en charge, maintenir, améliorer, modifier, actualiser, mettre à jour ou améliorer le Logiciel de quelque autre manière. Nous ne serons pas tenus de vous aider, vous ou les personnes que vous aurez désignées, à utiliser le Logiciel de quelque manière que ce soit.",
  "app.terms.line13": "Nous ne sommes pas responsables des dommages causés à votre ordinateur, y compris, sans toutefois s’y limiter, les dommages survenant suite à votre utilisation du Logiciel. Nous ne sommes pas responsables des frais de traitement des données supplémentaires que vous encourrez en conséquence du recueil des données par le Logiciel dans le cadre de votre participation à la Recherche.",
  "app.terms.line14": "5. Clause de non-responsabilité",
  "app.terms.line15": "Nous et nos directeurs, responsables, employés, agents, représentants, concédants de licence et fournisseurs respectifs ne faisons aucune déclaration, ne donnons aucune garantie et ne fixons aucune condition quant au fait (a) que le Logiciel sera disponible en temps voulu, ou que l’accès au Logiciel sera ininterrompu ou sécurisé ; (b) que les défauts ou erreurs seront corrigés ; ou (c) que le logiciel ou les serveurs ou réseaux par lesquels le Logiciel et l’Appareil sont mis à disposition sont sécurisés ou exempts de virus ou autres composants nuisibles. Nous ne sommes en aucun cas obligés de fournir des services de maintenance et/ou d’assistance par rapport au Logiciel.",
  "app.terms.line16": "6. Limitation de responsabilité",
  "app.terms.line17": "(a) Dans la mesure permise par la loi, vous acceptez que nous et nos directeurs, responsables, employés, agents, représentants, concédants de licence et fournisseurs respectifs ne saurions être tenus responsables des pertes, dommages ou frais de quelque nature que ce soit résultant de votre installation, utilisation ou accès au Logiciel, de votre utilisation ou incapacité à utiliser le Logiciel, de toute information ou tout matériel mis à disposition sur ou par le biais du Logiciel et/ou en rapport avec ce dernier ou autrement avec le présent Contrat, quelle que soit la nature du droit légal, équitable ou statutaire prétendument violé.",
  "app.terms.line18": "(b) Dans la mesure permise par la loi, votre seul et unique recours en cas d’insatisfaction concernant le Logiciel est de le désinstaller et de cesser de l’utiliser.",
  "app.terms.line19": "(c) En aucun cas, nos entités, directeurs, responsables, employés, agents et représentants ne sauront être tenus responsables envers vous de dommages accessoires, indirects, consécutifs, statutaires, exemplaires, punitifs ou spéciaux, de pertes de revenus, d’opportunités, de réputation, d’utilisation, de données, d’autres biens incorporels ou d’interception non autorisée de données recueillies par le Logiciel par des tiers, qu’ils soient prévisibles ou non, quelle qu’en soit la cause et en vertu de tout contrat, délit (y compris, sans toutefois s’y limiter, en cas de négligence), responsabilité stricte ou autre, même s’ils ont été informés à l’avance de l’éventualité selon laquelle de tels dommages pourraient survenir.",
  "demo.index.content.line1": "Au cours de cette démonstration, nous utiliserons une caméra pour estimer où vous portez votre regard. Ne vous inquiétez pas, nous ne prendrons ni photos ni vidéos.",
  "app.index.webcamInstructions": "Nous allons maintenant vous demander l'accès à votre webcam. Si vous recevez un popup, veuillez cliquer sur \"Autoriser\".`",
  "app.index.fullscreendesktopretry": "Cette étude nécessite un affichage en plein écran, veuillez vous assurer que la fenêtre de votre navigateur est ouverte en grand",
  "app.index.fullscreendesktoptest":"Cette étude nécessite que la fenêtre de votre navigateur soit en plein écran. Nous devons effectuer un test pour nous assurer que la fenêtre de votre navigateur est bien au maximum",
  "app.index.fullscreendesktoptest2":"Deux boutons vont apparaître dans un instant, cliquez dessus pour continuer",
  "app.index.fullscreendesktopmaximised":"Je suis déjà en plein écran",
  "app.index.fullscreendesktopskip":"Merci, nous avons enregistré ce problème et allons améliorer notre processus de détection.",
  "app.index.fullscreendesktopreset":"Nous avons détecté un changement dans la taille de la fenêtre, réinitialisant ainsi le test.",
  "app.button.return": "Retour",
  "app.index.content.line1gpdrwarning": "Pendant cette étude nous utiliserons la caméra avant de votre appareil pour prendre des photos et vidéos de vous pendant que vous effectuez divers exercices vous demandant d’examiner des stimuli.",
  "app.index.zoomRetry": "Votre navigateur doit être réglé sur un zoom de 100 %, veuillez donc faire un zoom avant / arrière et réessayer",
  "app.index.gotomobile": "Les utilisateurs d’ordinateurs de bureau ne peuvent pas participer à cette étude ; veuillez utiliser le QR code ci-dessous pour ouvrir l’étude sur un appareil mobile.",
  "app.index.returntopanel": "OU BIEN, cliquez sur le bouton ci-dessous pour retourner vers le panel.",
  "app.setup.instruction3audio": "Veuillez écouter attentivement et suivre les instructions qui s’affichent à l’écran.",
  "app.setup.retryPositioning": "Nous n’avons pas été en mesure d’identifier la position de votre tête. Veuillez garder votre tête et votre appareil immobiles et ne pas couvrir la caméra, par exemple avec vos doigts. Utilisez le bouton ci-dessous pour réessayer.",
  "app.setup.retryBroker": "Nous n’avons pas été en mesure de réaliser le processus de calibrage. Veuillez essayer de fermer les applis et les onglets non utilisés afin de libérer des ressources.",
  "app.error.cellquotafullnoactivecell": "Aucune cellule active n’a été trouvée pour cette étude. Les quotas de cellules sont pleins.",
  "app.error.missingpanelparameter": "Paramètre de panel manquant.",
  "app.completion.congratulations-noet": "Félicitations ! Vous avez terminé l'enquête.",
  "app.faq.gdpr.intro1": "Dans cette étude, nous allons utiliser la caméra avant d’un appareil mobile pour prendre des photos et des vidéos d’un utilisateur pendant qu’on demande à celui-ci de réaliser divers exercices consistant à examiner divers stimuli dans une série de conditions environnementales (par ex., éclairage et positionnement).  Le temps de participation devrait être de 5 minutes.  Les données seront utilisées pour faciliter le développement d’un logiciel capable d’estimer les endroits d’un écran que les utilisateurs regardent.",
  "app.faq.gdpr.intro2": "Cette étude est menée conformément au Code de déontologie de la MRS et à la loi de 2018 sur la protection des données.  Nous attachons beaucoup d’importance à votre confidentialité.  Avant de participer à cette étude, vous devez confirmer que vous acceptez d’y participer, que vous comprenez quelles sont les données que nous recueillons et comment elles seront utilisées, conformément à ce qui est décrit dans la présente Politique de confidentialité, et que vous consentez à ce que ces données soient utilisées de cette façon.  Cette politique décrit précisément et en détails les informations qui sont recueillies et la manière dont elles sont utilisées et partagées.",
  "app.faq.gdpr.question1": "Quelles données seront recueillies ?",
  "app.faq.gdpr.answer1.line1": "Cette étude permettra de recueillir les types de données suivants :",
  "app.faq.gdpr.answer1.line2": "« Caractéristiques de la séance » – Un identifiant anonyme utilisé pour identifier une collecte de données particulière relative à la « séance » et les données relatives aux conditions d’essai liées à l’environnement du répondant pendant la séance.",
  "app.faq.gdpr.answer1.line3": "« Caractéristiques du participant » – Données au sujet d’une personne interrogée participant à une Séance donnée, notamment des informations sur les caractéristiques démographiques du participant (par ex., âge, genre), ethniques (par ex., type de peau) et biologiques (par ex., couleur des yeux).",
  "app.faq.gdpr.answer1.line4": "« Fichier vidéo » – Photos et/ou vidéos prises au moyen de la caméra avant de l’appareil pour capturer des images des participants pendant qu’ils examinent, ou interagissent potentiellement avec des stimuli qui s’affichent sur un ou plusieurs écrans. Des images seront prises des yeux et du visage d’un participant et de toute autre chose susceptible d’être capturée par la caméra avant pendant que celui-ci réalise l’exercice. ",
  "app.faq.gdpr.answer1.line5": "« Métadonnées » - Données relatives à l’heure à laquelle les participants ont interagi avec les stimuli, liées à l’horodatage de chaque image recueillie.",
  "app.faq.gdpr.answer1.line6": "« Données physiques de l’appareil » - Données physiques relatives à l’appareil, notamment le nom de l’appareil, la taille de l’écran, l’orientation de l’écran et l’orientation du capteur de la caméra.",
  "app.faq.gdpr.answer1.line7": "Lorsque vous participez à cette étude, il est entendu que vous comprenez également et acceptez la possibilité que le contenu qui vous est révélé dans cette étude soit confidentiel, la propriété d'un tiers et/ou soumis aux lois protégeant les marques déposées et droits d’auteur, et que vous ne ferez pas de captures d’écran, n’enregistrerez pas, ne copierez pas de toute autre façon que ce soit ou ne rediffuserez pas tout ou partie du contenu de l’étude.",
  "app.faq.gdpr.question2": "Comment les données seront-elles utilisées / traitées ?",
  "app.faq.gdpr.answer2": "Les données seront utilisées pour le développement d’un logiciel capable d’estimer si les utilisateurs sont présents devant un appareil, s’ils examinent des stimuli, et l’endroit de ces stimuli qu’ils sont susceptibles de regarder.  Elles peuvent également être utilisées aux fins de démonstration ou pour expliquer à des tierces parties (clients, fournisseurs, partenaires) ou contribuer à évaluer pour celles-ci la manière dont notre technologie a été développée, et ses performances.  Ces données pourront être examinées par un nombre quelconque d’« annotateurs » de données, à savoir des employés ou des sous-traitants ou des partenaires ou toute autre tierce partie à qui il sera demandé de visionner les vidéos / images afin d’évaluer les « états » d’attention des participants pendant l’étude (par ex., sont-ils présents dans le champ de vision de la caméra ? regardent-ils l’écran ?)  Les bases juridiques pour le traitement des données seront le consentement éclairé et les intérêts légitimes. ",
  "app.faq.gdpr.question3": "Pendant combien de temps les données seront-elles conservées ?",
  "app.faq.gdpr.answer3": "Nous conserverons les données pendant aussi longtemps que raisonnablement nécessaire pour les fins décrites plus haut.  Le logiciel en cours de développement est régulièrement mis à jour et évalué et ces données seront utilisées pour évaluer chaque itération. On s’attend donc à ce que les données soient conservées et utilisées à cette fin pendant plusieurs années, voire, indéfiniment.  Les données seront supprimées s’il est jugé qu’elles ne sont plus utiles aux fins du développement ou de l’évaluation du logiciel de test de stimuli. ",
  "app.faq.gdpr.question4": "Qui recevra et traitera les données ?",
  "app.faq.gdpr.answer4": "Les données seront détenues et conservées par Lumen Research Ltd. Lumen Research Ltd est une société enregistrée en Angleterre, sous le numéro 8682432.  Son siège social est situé à LABS house, 15-19 Bloomsbury Way, Holborn, London WC1A 2TH, en Angleterre. La référence de son immatriculation en matière de protection des données est ZA130772.  Son responsable de la protection des données est Matt Robinson (matt.robinson@lumen-research.com).  Vous avez le droit de déposer à tout moment une plainte concernant la manipulation et le traitement de vos données en contactant l’autorité de protection des données du Royaume-Uni, l’ICO (Information Commissioner’s Office).  Les coordonnées de l’ICO figurent sur son site web, à l’adresse : https://ico.org.uk/global/contact-us/. En participant à cette étude, vous accordez à Lumen une licence mondiale, libre de droits, cessible et transférable d’héberger, conserver, utiliser, afficher, reproduire, modifier, adapter, éditer, publier et distribuer ces données.",
  "app.faq.gdpr.question5": "Ai-je le droit d’accéder à mes données à caractère personnel ?",
  "app.faq.gdpr.answer5": "Oui, vous avez le droit d’accéder à vos données à caractère personnel. Toutefois, il est important de savoir que votre nom ne sera pas conservé avec les données recueillies pendant cet exercice.  Afin d’identifier vos registres de données, vous devrez nous fournir l’heure précise et la date auxquelles vous avez complété l’étude, et nous devrons demander une identification visuelle afin de vérifier que les registres de données sont bien les vôtres.",
  "app.faq.gdpr.question7": "Ai-je le droit de retirer mon consentement au traitement de mes données à caractère personnel, ou à la suppression de mes données à caractère personnel ?",
  "app.faq.gdpr.answer7": "Oui, vous avez le droit de retirer votre consentement au traitement de vos données à caractère personnel, et à la suppression de ces données.  Toutefois, il est important de savoir que votre nom ne sera pas conservé avec les données recueillies pendant cet exercice.  Afin d’identifier votre registre de données, vous devrez nous fournir l’heure précise et la date auxquelles vous avez complété l’étude, et nous demanderons une identification visuelle afin de vérifier que les registres de données sont bien les vôtres.",
  "app.faq.gdpr.question8": "Comment puis-je savoir que mes données à caractère personnel sont en sécurité ?",
  "app.faq.gdpr.answer8": "Les données collectées par Lumen Research sont chiffrées grâce à la technologie SSL (Secure Sockets Layer).  Il s’agit d’une technologie de chiffrage de grande qualité qui est couramment utilisée. Cette technologie est utilisée à la fois pour sécuriser les canaux de transfert et pour le stockage final.",
  "app.faq.gdpr.question9": "Comment utiliserons-nous et communiquerons-nous les informations que nous recueillons ?",
  "app.faq.gdpr.answer9": "Nous pourrons communiquer les informations que nous recueillons à d’autres prestataires de services tiers, afin de leur permettre de nous fournir leurs services. Nous pourrons également communiquer ces informations à un tiers dans le cas de toute réorganisation, fusion, vente, projet commercial commun, affectation, transfert ou toute autre disposition concernant toute portion de nos activités, biens ou actions (y compris en relation avec toute faillite ou procédure similaire). Nous pourrons également utiliser ou communiquer les informations que nous recueillons auprès de vous ou à votre propos lorsque nous le jugeons nécessaire ou approprié : (a) en vertu des lois applicables, y compris les lois en vigueur à l’extérieur de votre pays de résidence ; (b) pour nous conformer aux procédures légales ; (c) pour répondre aux demandes des autorités publiques et gouvernementales, y compris les autorités publiques et gouvernementales à l’extérieur de votre pays de résidence ; (d) pour faire respecter notre contrat de licence utilisateur final ; (e) pour protéger nos opérations ou celles de nos filiales ; (f) pour protéger nos droits, notre confidentialité, notre sécurité ou notre propriété, et/ou ceux de nos filiales, les vôtres, ou ceux d’autres entités ; (g) pour nous permettre d’utiliser les recours disponibles ou de limiter tout préjudice que nous pourrions éventuellement subir ; et (h) pour détecter et résoudre tout problème de fraude ou de sécurité. ",
  "app.faq.gdpr.question10": "Quelles mesures sont prises pour conserver les données de manière sécurisée ?",
  "app.faq.gdpr.answer10": "Nous mettons en œuvre toutes les mesures raisonnables pour protéger vos informations à l’aide de mesures de sécurité physiques, électroniques ou relatives aux procédures, appropriées pour la sensibilité des renseignements dont nous avons la garde ou le contrôle. Cela peut inclure l’utilisation de mesures de protection contre la perte ou le vol, ainsi que les accès, les divulgations, les copies, l’utilisation ou la modification non autorisés. ",
  "app.error.previousfail": "Votre participation précédente à cette étude a échoué, veuillez utiliser le lien original pour commencer à partir du début.",
  "app.error.previoussuccess": "Vous avez précédemment complété cette étude.",
  "app.error.route": "Vous avez essayé de commencer une étude déjà en cours. Veuillez utiliser le lien original pour commencer à partir du début.",
  "app.error.accesscode": "Vous avez essayé de commencer une étude qui n’existe pas. Veuillez vérifier votre lien.",
  "app.error.webcam": "Cette Étude nécessite d’avoir accès à une webcam.",
  "app.error.adblocker": "Cette étude nécessite de désactiver l’adblock.",
  "app.error.startsession": "La séance ne peut pas être démarrée.",
  "app.error.fullscreen": "Cette étude nécessite une vue plein écran.",
  "app.error.zoom": "Cette étude nécessite de régler le zoom du navigateur sur 100 %.",
  "app.setup.retryBack": "Tout au long de cette étude, veuillez éviter d’appuyer sur le bouton retour du navigateur, sinon vous pourriez ne pas obtenir votre récompense. Utilisez le bouton ci-dessous pour réessayer.",
  "app.setup.retryaudible": "Veuillez vérifier que votre appareil n’est pas en mode silencieux et réessayer.",
  "app.restore.retry": "Vous n’avez pas consacré assez de temps à l’étape précédente, ",
  "app.restore.timeremaining": " nombre de minutes restant",
  "app.error.postexposure": "Désolés, vous avez à présent utilisé toutes vos tentatives et vous ne pouvez malheureusement pas poursuivre cette étude."
}
