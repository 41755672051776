import LumenEyeTracking from "..";
import { BROKER_TYPES } from "../../../types/types";
import config from "../config";
import BaseStep from "./BaseStep";

class HeadPositioning extends BaseStep {
  constructor() {
    super("headPositioning", "HeadPositioning");

    this.continueFlag = false;
    this.timeout = 0;
    this.currTime = 0;
    this.element = null;

    this.cleanUp = this.cleanUp.bind(this);
    this.onError = this.onError.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.checkIfReady = this.checkIfReady.bind(this);
    this.continue = this.continue.bind(this);
    this.runProcess = this.runProcess.bind(this);
  }

  cleanUp() {
    this.removeErrorListener();

    if (LumenEyeTracking.broker.landmarksEventHandler) {
      LumenEyeTracking.broker.stopEyePos();
    }

    if (document.getElementById("lr_show_video")) {
      this.element.appendChild(document.getElementById("lr_show_video"));
    }

    if (!!this.element) {
      this.element.style.display = "none";

      if (LumenEyeTracking.canvasCont) {
        try {
          this.element.removeChild(LumenEyeTracking.canvasCont);
        } catch {}
      }

      if (this.element.parentElement) {
        LumenEyeTracking.broker.releaseVideoDiv();
      }
    }

    LumenEyeTracking.removeInstructions();
  }

  onError(error) {
    if (this.state !== "cancelled") {
      super.onError();
      //event from errPassthrough
      if (error && error.detail) {
        LumenEyeTracking.lastError = new Error(error.detail.error.message);
        LumenEyeTracking.lastError.name = "HeadPositioningError";
        LumenEyeTracking.lastError.retryCode = error.detail.error.retry;
      } else if (error && error.message) {
        LumenEyeTracking.lastError = new Error(error.message);
        LumenEyeTracking.lastError.name = "HeadPositioningError";
      } else {
        LumenEyeTracking.lastError = new Error(
          "Head positioning took too long."
        );
        LumenEyeTracking.lastError.name = "HeadPositioningError";
      }

      LumenEyeTracking.errorHandler(
        this.resolver,
        this.cleanUp,
        "headPositioning",
        LumenEyeTracking.setupStart
      );
    }
  }

  onComplete() {
    if (this.state !== "cancelled") {
      super.onComplete();

      let fps = 30;
      if (LumenEyeTracking.brokerType == BROKER_TYPES.STANDARD) {
        fps = LumenEyeTracking.broker.endCalculateFrameRate();
      }
      this.resolver({ success: true });
    }
  }

  checkIfReady() {
    if (this.continueFlag) {
      this.removeErrorListener();

      this.cleanUp();
      this.onComplete();
    } else if (this.currTime > this.timeout) {
      this.onError();
    } else if (this.state !== "errored" || !this.state !== "cancelled") {
      this.currTime += config.tickRate;
      setTimeout(this.checkIfReady, config.tickRate);
    }
  }

  continue() {
    if (!LumenEyeTracking.headPositionBool) {
      if (LumenEyeTracking.faceOverlay) {
        LumenEyeTracking.faceOverlay.style.background = "red";
        setTimeout(() => {
          LumenEyeTracking.faceOverlay.style.background = "none";
        }, 200);
      }
    } else {
      this.continueFlag = true;
          window.LRSkipStep = () => {};
    }
  }

  async runProcess(timeout, element, userId, studyId, panelCode) {
    if (timeout < 15000) {
      timeout = 15000;
    }

    this.timeout = timeout;
    this.element = element;

    if (
      LumenEyeTracking.brokerType == BROKER_TYPES.MOUSE ||
      LumenEyeTracking.brokerType == BROKER_TYPES.MOBILEMOUSE
    ) {
      return this.resolver({ success: true });
    }

    this.setupErrorListener(this.onError);

    if (LumenEyeTracking.brokerType == BROKER_TYPES.STANDARD) {
      LumenEyeTracking.broker.startCalculateFrameRate();
    }

    if (LumenEyeTracking.videoElement) {
      LumenEyeTracking.videoElement.style.display = "block";
      LumenEyeTracking.videoElement.style.width = "100%";
    }

    if (this.element) {
      LumenEyeTracking.prepareCanvas(this.element);

      LumenEyeTracking.createInstructions(
        this.element,
        "bottom",
        config.instructions.calibrate.bottom()
      );

      this.element.addEventListener("click", this.continue);
      this.element.addEventListener("touchstart", this.continue);
      window.LRSkipStep = () => {
        this.continue();
      };
    }

    LumenEyeTracking.broker.getEyePos(LumenEyeTracking.updateEyePosition);

    setTimeout(this.checkIfReady, LumenEyeTracking.tickRate);
  }
}

export default HeadPositioning;
