import LumenEyeTracking from "..";
import BaseStep from "./BaseStep";
import {StorageHandler} from "@lumen-developer/lumen-common-js/storageHandler.bundle"
import { BROKER_TYPES } from "../../../types/types";

class Initialise extends BaseStep {
  constructor() {
    super("init", "BrokerInit");

    this.cleanUp = this.cleanUp.bind(this);
    this.onError = this.onError.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.runProcess = this.runProcess.bind(this);
  }

  cleanUp() {
    this.removeErrorListener();
    LumenEyeTracking.brokerSubEvents = [];
  }

  onError(error) {
    if (this.state !== "cancelled") {
      super.onError();

      //event from errPassthrough
      if (error && error.detail) {
        LumenEyeTracking.lastError = new Error(error.detail.error.message);
        LumenEyeTracking.lastError.name = "InitError";
        LumenEyeTracking.lastError.retryCode = error.detail.error.retryCode;
      } else if (error && error.message) {
        LumenEyeTracking.lastError = new Error(error.message);
        LumenEyeTracking.lastError.name = "InitError";
      } else {
        LumenEyeTracking.lastError = new Error("Unable to initialise!");
        LumenEyeTracking.lastError.name = "InitError";
      }

      if(!!LumenEyeTracking.broker.trackersController && !LumenEyeTracking.broker.trackersController.frameReader().running()) {
          LumenEyeTracking.lastError = new Error("Failed to turn on camera.");
          LumenEyeTracking.lastError.name = "InitError";
      }

      LumenEyeTracking.errorHandler(
        this.resolver,
        this.cleanUp,
        "init",
        LumenEyeTracking.setupStart
      );
    }
  }

  onComplete(userId, studyId, sessionId, cellId) {
    if (this.state !== "cancelled") {
      super.onComplete();

      LumenEyeTracking.getTrackingBundle(userId, studyId, sessionId, cellId);

      //ios workaround beforeunload is not called on back button press
      var environmentVars = {
        sessionId: sessionId,
        is_tracking: LumenEyeTracking.is_tracking,
        validation_step_id: LumenEyeTracking.validation_step_id,
        step_order_id: LumenEyeTracking.stepOrderId,
        startCalibrationTime: LumenEyeTracking.startCalibrationTime,
      };
      StorageHandler.setItem(
        "environmentVars",
        JSON.stringify(environmentVars)
      );

      window.addEventListener("beforeunload", () => {
        var environmentVars = {
          sessionId: sessionId,
          is_tracking: LumenEyeTracking.is_tracking,
          validation_step_id: LumenEyeTracking.validation_step_id,
          step_order_id: LumenEyeTracking.stepOrderId,
          startCalibrationTime: LumenEyeTracking.startCalibrationTime,
        };
        StorageHandler.setItem(
          "environmentVars",
          JSON.stringify(environmentVars)
        );
      });
      
      //if the function is completed before the resolver is registered we need to wait for it to exist
      if(this.resolver){
        this.resolver({ success: true });
      } else {
        setTimeout(() => this.resolver({ success: true }), 250)
      }
    }
  }

  async runProcess(
    brokerUrl,
    userId,
    studyId,
    sessionId,
    cellId,
    trackingElement,
    isMobile,
    isCrawler,
    newTrackingTables = false
  ) {
    LumenEyeTracking.newTrackingTables = newTrackingTables
    LumenEyeTracking.isCrawler = isCrawler
    LumenEyeTracking.setupStart = new Date().getTime();
    LumenEyeTracking.isCrawler = isCrawler
    LumenEyeTracking.initBrokerType(brokerUrl);
    LumenEyeTracking.getBroker();

    if (!!trackingElement) {
      trackingElement.style.display = "unset";
    }

    let dynConfig = {};
    dynConfig.isMobile = isMobile;
    if (brokerUrl.includes("/models/george/")) {
      dynConfig.configV2 = true;
      //LumenEyeTracking.multiPartCalib = true;
    }

    if (isCrawler) {
      dynConfig.testEyetracking = true;
      dynConfig.videoSrc =
        "https://content.lumen-research.com/Platform_Resources/eyetrackingMov.mp4";
    }

    if(studyId === 14894){
      dynConfig.silhouette = true
    }

    if(studyId === 15164){
      dynConfig.useGroundTruth = true
    }


    LumenEyeTracking.initialiseErrorPassthrough();

    this.setupErrorListener(this.onError);

    let customEventHandlers = LumenEyeTracking.initialiseCustomEventHandlers();

    //initialise broker
    if (
      LumenEyeTracking.brokerType == BROKER_TYPES.MOUSE ||
      LumenEyeTracking.brokerType == BROKER_TYPES.MOBILEMOUSE ||
      LumenEyeTracking.brokerType == BROKER_TYPES.AUDIBLE ||
      LumenEyeTracking.brokerType == BROKER_TYPES.NO_EYETRACKING ||
      LumenEyeTracking.brokerType == BROKER_TYPES.AUTOMATED_TESTING_BROKER
    ) {
      this.onComplete(userId, studyId, sessionId, cellId);
    } else {
      LumenEyeTracking.broker.init(
        120000,
        trackingElement,
        dynConfig,
        customEventHandlers
      ).then(async () => {
        this.onComplete(userId, studyId, sessionId, cellId);
      }).catch(async (error) => {
        if(!!LumenEyeTracking.broker.trackersController && !LumenEyeTracking.broker.trackersController.frameReader().running()) {
          this.onError({message:"Failed to turn on camera."})
        } else {
          this.onError(error);
        }
      });
    }
  }
}

export default Initialise;
