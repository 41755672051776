{
  "title": "﻿Lumen Study Platform - Polityka Prywatności",
  "date": "Lipiec 2022",
  "contactDetails": {
    "title": "Nasze dane kontaktowe ",
    "body": [
      "Nazwa: Lumen Research Ltd („Lumen”)",
      "Adres: Screenworks, Unit 215, 22 Highbury Grove, Londyn N5 2ER",
      "Numer telefonu: +44(0)2037355199 ",
      "E-mail: dataprotection@lumen-research.com",
      "Numer rejestracyjny ICO:ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "Niniejsza Polityka Prywatności wyjaśnia charakter informacji, które zbieramy od uczestników, biorących udział w ankiecie śledzenia wzroku na Lumen Study Platform, oraz naszą politykę regulującą wszelkie dane osobowe, które mogą być gromadzone. "
      ],
      "heading": "Informacje wstępne"
    },
    {
      "body": [
        "W typowej ankiecie firma Lumen nie gromadzi danych osobowych zgodnie z definicją zawartą w Brytyjskim Ogólnym Rozporządzeniu o Ochronie Danych, jednak będzie gromadzić szacunkowe dane punktowe; odpowiedzi na ankietę; metadane dotyczące czasu i rodzaju urządzenia, na którym miało miejsce gromadzenie danych; oraz identyfikatory respondentów w celu administrowania nagrodami.",
        "W typowej ankiecie zostaną Państwo poproszeni o zajęcia miejsca przed kamerą internetową urządzenia lub kamerą skierowaną przodem do kierunku jazdy i wykonanie kalibracji, aby można było określić, gdzie patrzą Państwo na ekran. Podczas ankiety zostaną Państwo poproszeni o wyświetlenie niektórych elementów (np. obrazów, stron internetowych lub filmów), podczas gdy narzędzie ankiety określi, gdzie Państwo patrzą. Dane przechwytywane z tego „śledzenia wzroku” to współrzędne szacunkowych punktów patrzenia na ekranie w czasie. Oprogramowanie wykorzystuje Państwa zdjęcia z kamery internetowej do dokonania tych szacunków, ale nie przechwytuje Państwa zdjęć ani filmów. ",
        "Możemy również zadać Państwu kilka pytań dotyczących bezpośrednio Państwa oraz Państwa doznań i elementów, które widzieli Państwo podczas ankiety. ",
        "Dane z tych badań przesyłane są do Lumen Research Ltd („Lumen”). Firma Lumen dokona analizy danych i podzieli się wynikami ze swoimi klientami. ",
        "W tych ankietach osoba trzecia przekaże firmie Lumen alfanumeryczny identyfikator respondenta, aby firma mogła śledzić, którzy respondenci wypełniają ankietę. Firma Lumen udostępni te informacje osobom trzecim w celu administrowania nagrodami dla osób, które wypełniły ankietę. "
      ],
      "heading": "Jakiego typu dane osobowe gromadzimy?"
    },
    {
      "body": [
        "Czasami respondenci mogą zostać poproszeni o wzięcie udziału w projekcie badawczym, który wymaga więcej niż jednego okresu gromadzenia danych. W takim przypadku identyfikator respondenta będzie wykorzystywany jako trwały identyfikator do ponownego kontaktu z konkretnym uczestnikiem w celu zapoczątkowania jego zaangażowania w kolejne działania związane z gromadzeniem danych. W takim przypadku identyfikator respondenta będzie traktowany jako dane osobowe.  "
      ],
      "heading": "Jakiego typu dane osobowe gromadzimy? "
    },
    {
      "body": [
        "Podobnie jak w przypadku typowej ankiety, stały identyfikator respondenta zostanie nam przekazany przez stronę trzecią, która dokonała rekrutacji uczestnika do naszych badań. Wykorzystamy je do ponownego kontaktu z osobami, aby zapoczątkować ich późniejsze zaangażowanie w badanie i możemy udostępnić je stronom trzecim w celu ułatwienia dalszego uczestnictwa.",
        "Zgodnie z brytyjskim ogólnym rozporządzeniem o ochronie danych (RODO) Państwa zgoda jest podstawą prawną przetwarzania tych informacji. W każdej chwili mogą Państwo wycofać swoją zgodę. Można to zrobić, kontaktując się z nami pod adresem dataprotection@lumen-research.com"
      ],
      "heading": "W jaki sposób pozyskujemy dane osobowe i dlaczego je posiadamy"
    },
    {
      "body": [
        "Państwa dane są bezpiecznie przechowywane i szyfrowane przy użyciu protokołu SSL. Jest to najczęściej stosowany wysoki standard technologii szyfrowania i jest używany przez banki do zabezpieczania transakcji bankowych i innych bardzo wrażliwych komunikatów. Jest również używany przez Amazon, PayPal, eBay i inne strony internetowe wymagające systemów bezpieczeństwa na poziomie banku. Naukowcy szacują, że złamanie 256-bitowego szyfrowania przy użyciu obecnej technologii wymagałoby znacznie więcej czasu niż obecny wiek wszechświata. Zebrane dane są przechowywane na zabezpieczonych serwerach internetowych zarządzanych przez Amazon Web Services (AWS). AWS jest jedną z najczęściej używanych oraz bezpiecznych i niezawodnych platform usług przetwarzania w chmurze i jest wykorzystywana przez główne komercyjne usługi w chmurze, w tym Netflix i Expedia. ",
        "Przechowujemy trwałe identyfikatory przez okres wymagany do ponownego kontaktu z respondentami w celu przeprowadzenia wielostopniowych badań. W zależności od charakteru badania ten proces może przebiegać w różny sposób. Zostanie to zgłoszone, gdy wyrażą Państwo zgodę na udział. Po zakończeniu zbierania danych dla takiego projektu identyfikatory będą przechowywane wyłącznie w zakresie wymaganym do celów analizy i raportowania danych. Po zakończeniu analizy i raportowania projektu usuniemy te dane osobowe, usuwając je z naszych baz danych."
      ],
      "heading": "Jak przechowujemy Państwa dane osobowe "
    }
  ],
  "dataProtection": {
    "body": [
      "Zgodnie z przepisami o ochronie danych osobowych przysługują Państwu prawa, w tym:"
    ],
    "bullets": [
      {
        "title": "Prawo dostępu",
        "text": [
          "mają Państwo prawo żądać od nas dostarczenia kopii Państwa danych osobowych."
        ]
      },
      {
        "title": "Prawo do sprostowania",
        "text": [
          "Mają Państwo prawo zażądać sprostowania danych osobowych, które Państwa zdaniem są nieprawidłowe. Mają Państwo również prawo zażądać od nas uzupełnienia informacji, które Państwa zdaniem są niepełne."
        ]
      },
      {
        "title": "Prawo do usunięcia",
        "text": [
          "Mają Państwo prawo zażądać usunięcia Państwa danych osobowych w określonych okolicznościach."
        ]
      },
      {
        "title": "Prawo do ograniczenia przetwarzania",
        "text": [
          "Mają Państwo prawo zażądać ograniczenia przetwarzania Państwa danych osobowych w określonych okolicznościach."
        ]
      },
      {
        "title": "Prawo do cofnięcia zgody",
        "text": [
          "Przysługuje Państwu prawo do wycofania wyrażonej zgody na przetwarzanie danych osobowych."
        ]
      },
      {
        "title": "Prawo do przenoszenia danych",
        "text": [
          "mają Państwo prawo zażądać, abyśmy pod pewnymi warunkami przekazali zgromadzone przez nas Państwa dane osobowe innej organizacji lub bezpośrednio Państwu.",
          "Nie są Państwo zobowiązani do uiszczania żadnych opłat za korzystanie ze swoich praw. Jeśli złożą Państwo wniosek, mamy miesiąc na udzielenie odpowiedzi.",
          "Jeśli chcą Państwo złożyć wniosek, prosimy o kontakt pod adresem dataprotection@lumen-research.com"
        ]
      }
    ],
    "title": "Państwa Prawo do Ochrony Danych"
  },
  "complaints": {
    "body": [
      "Jeśli mają Państwo jakiekolwiek wątpliwości dotyczące wykorzystania przez nas Państwa danych osobowych, mogą Państwo złożyć do nas skargę na adres dataprotection@lumen-research.com.",
      "Mogą Państwo również złożyć skargę do ICO, jeśli nie są Państwo zadowoleni ze sposobu, w jaki wykorzystaliśmy Państwa dane.",
      "Biuro Komisarza ds. Informacji"
    ],
    "address": {
      "body": [
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ],
      "title": "Adres ICO:            "
    },
    "title": "Jak złożyć skargę",
    "number": "Numer infolinii: 0303 123 1113",
    "website": "Strona internetowa ICO: https://www.ico.org.uk"
  }
}
