import { routeType } from "./util";

let storageDest = localStorage ? localStorage : sessionStorage;
let storageKey = "lr_route_history";

const saveEntry = () => {
  let currTime = new Date().getTime();
  let name = routeType();

  if (name === "study") {
    let urlParams = window.location.pathname.split("/").filter((n) => n);
    name = parseInt(urlParams[3]);
  }

  let currEntries = getEntries();

  if (!currEntries.startTime) {
    currEntries.startTime = currTime;
  }

  if (!currEntries.visitedSections.includes(name)) {
    currEntries.visitedSections = [...currEntries.visitedSections, name];
    currEntries.lastEntryTime = currTime;
  }

  storageDest[storageKey] = JSON.stringify(currEntries);
};

const getEntries = () => {
  if (storageDest[storageKey]) {
    return JSON.parse(storageDest[storageKey]);
  } else {
    return { visitedSections: [] };
  }
};

const checkEntry = (name) => {
  let currEntries = getEntries();

  let currTime = new Date().getTime();
  let ONE_HOUR = 60 * 60 * 1000;

  if (
    currEntries.lastEntryTime - currTime < ONE_HOUR &&
    currEntries.visitedSections.includes(name)
  ) {
    return true;
  }

  return false;
};

const clearEntries = () => {
  if (storageDest[storageKey]) {
    delete storageDest[storageKey];
  }
};

export default { saveEntry, getEntries, checkEntry, clearEntries };
