import fail from "./fail";
import start from "./start";
import startpanel from "./startpanel";
import assign from "./assign";
import complete from "./complete";
import completecalibration from "./completecalibration";
import shortname from "./shortname";
import prestudyquestionreponse from "./prestudyquestionreponse";
import questionresponse from "./questionresponse";
import savecalibration from "./savecalibration";
import completestep from "./completestep"
import updatelastsectioncompleted from "./updatelastsectioncompleted";
import getpaneldetails from "./getpaneldetails";

export default {
  fail,
  start,
  startpanel,
  assign,
  complete,
  completecalibration,
  shortname,
  prestudyquestionreponse,
  questionresponse,
  savecalibration,
  completestep,
  updatelastsectioncompleted,
  getpaneldetails
};
