import { REGIONS } from "../types/types";
import * as actions from "./action-types";

export function progressState() {
  return {
    type: actions.PROGRESS_STATE
  };
}

export function regressState() {
  return {
    type: actions.REGRESS_STATE
  };
}

export function changeStateTo(payload) {
  return {
    type: actions.CHANGE_STATE_TO,
    payload
  };
}

export function errorState(payload = null) {
  return {
    type: actions.ERROR_STATE,
    payload: payload
  }
}

export function invalidateUser() {
  return {
    type: actions.INVALIDATE_USER
  }
}

export function progressAvailableTrue() {
  return {
    type: actions.PROGRESS_AVAILABLE_TRUE
  };
}

export function progressAvailableFalse() {
  return {
    type: actions.PROGRESS_AVAILABLE_FALSE
  };
}

export function isLoadingTrue() {
  return {
    type: actions.IS_LOADING_TRUE
  };
}

export function isLoadingFalse() {
  return {
    type: actions.IS_LOADING_FALSE
  };
}

export function debugState() {
  return {
    type: actions.DEBUG_STATE
  }
}

export function storeDeviceInfo(payload) {
  return {
    type: actions.STORE_DEVICE_INFO,
    payload: payload
  }
}

export function submissionInProgressTrue() {
  return {
    type: actions.SUBMISSION_IN_PROGRESS_TRUE
  }
}

export function submissionInProgressFalse() {
  return {
    type: actions.SUBMISSION_IN_PROGRESS_FALSE
  }
}

export function progressBarFinished() {
  return {
    type: actions.PROGRESS_BAR_FINISHED
  }
}

export function progressBarTiming(payload) {
  return {
    type: actions.PROGRESS_BAR_TIMING,
    payload: payload
  }
}

export function setRegion(payload) {
  let region = payload
  let newRegion=-1
  switch (region) {
      case "ap":
        newRegion = REGIONS.AP;
        break;
      case "us":
        newRegion = REGIONS.US;
        break;
      case "us2":
        newRegion = REGIONS.US2;
        break;
      case "sa":
        newRegion = REGIONS.SA
        break;
      case "sp":
        newRegion = REGIONS.SP
        break;
      default:
        break;
    }
  return {
    type: actions.SET_REGION,
    payload: newRegion
  }
}

export function setDebugLog() {
  return {
    type: actions.SET_DEBUG_LOG
  }
}

export function setPath(payload) {
  return {
    type: actions.SET_PATH,
    payload: payload
  }
}

export function runSpeedTest(){

  return async function speedTestThunk(dispatch, getState){
    let connectionSpeed = await new Promise(function (resolve, reject) {
      var startTime, endTime;
      var imageAddr = "https://content.lumen-research.com/uploader/1492.jpg";
      var downloadSize = 3311564;
      var download = new Image();
      download.onload = function () {
        endTime = new Date().getTime();
        var duration = (endTime - startTime) / 1000;
        var bitsLoaded = downloadSize * 8;
        var speedBps = (bitsLoaded / duration).toFixed(2);
        var speedKbps = (speedBps / 1024).toFixed(2);
        var speedMbps = (speedKbps / 1024).toFixed(2);
        resolve({
          speedBps: speedBps,
          speedKbps: speedKbps,
          speedMbps: speedMbps,
        });
      };
  
      startTime = new Date().getTime();
      var cacheBuster = "?nnn=" + startTime;
      download.src = imageAddr + cacheBuster;
    });

    dispatch({type:actions.SET_SPEEDTEST, payload: connectionSpeed})
  }
}

export function setSpeedTest(speedKbps) {
  return {type:actions.SET_SPEEDTEST, payload: speedKbps}
}

export function storeClickData(clickData) {
  return {type:actions.STORE_CLICK_DATA, payload: clickData}
}

export function resetClickData() {
  return {type:actions.RESET_CLICK_DATA}
}

export function stopClickListener () {
  return {type:actions.STOP_CLICK_LISTENER}
}

export function setLastUserAction (args) {
  return {type:actions.SET_LAST_USER_ACTION, payload:args}
}

export function setWaitingForApi (args) {
  return {type:actions.SET_WAITING_FOR_API, payload:args}
}

export function resetLoadedAt () {
  return {type:actions.RESET_LOADED_AT}
}