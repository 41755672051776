import React from "react";
import { connect } from "react-redux";
import LSModal from "../modal";
import { togglePrivacyModal } from "../actions";
import PrivacyPolicy from "../../../translations/PrivacyPolicy";

const modalContent = (selectedLanguage) => {
  const policyJson = Object.keys(PrivacyPolicy).includes(selectedLanguage)
    ? PrivacyPolicy[selectedLanguage]
    : PrivacyPolicy["en"];

  return (
    <React.Fragment>
      <div className="ls-privacy-policy">
        <div className="ls-privacy-policy-header">
          <h1 className="ls-privacy-policy-title">{policyJson.title}</h1>
          <p className="ls-privacy-policy-date">{policyJson.date}</p>
        </div>
        <div className="ls-privacy-policy-contact-details">
          <h3>{policyJson.contactDetails.title}</h3>
          {policyJson.contactDetails.body.map((item) => {
            return <p>{item}</p>;
          })}
        </div>
        <div className="ls-privacy-policy-sections">
          {policyJson.sections.map((section) => {
            return (
              <div className="ls-privacy-policy-section">
                <h3>{section.heading}</h3>
                <div className="ls-privacy-policy-section-body">
                  {section.body.map((item) => {
                    return <p>{item}</p>;
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="ls-privacy-policy-data-protection">
          <h2>{policyJson.dataProtection.title}</h2>
          <div className="ls-privacy-policy-data-protection-body">
            {policyJson.dataProtection.body.map((item) => {
              return <p>{item}</p>;
            })}
          </div>
          <div className="ls-privacy-policy-data-protection-bullets">
            {policyJson.dataProtection.bullets.map((bullet) => {
              return (
                <div className="ls-privacy-policy-data-protection-bullet">
                  <p>
                    <strong>{bullet.title}</strong>
                    {` - ${bullet.text[0]}`}
                  </p>
                  {bullet.text.length > 1 && (
                    <>
                      {bullet.text.slice(1).map((item) => {
                        return <p>{item}</p>;
                      })}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="ls-privacy-policy-complaints">
          <h2>{policyJson.complaints.title}</h2>
          <div className="ls-privacy-policy-complaints-body">
            {policyJson.complaints.body.map((item) => {
              return <p>{item}</p>;
            })}
          </div>
          <div className="ls-privacy-policy-complaints-address">
            <h3>{policyJson.complaints.address.title}</h3>
            <>
              {policyJson.complaints.address.body.map((item) => {
                return <p>{item}</p>;
              })}
            </>
          </div>
          <p>{policyJson.complaints.number}</p>
          <p>{policyJson.complaints.website}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

function LSPrivacyPolicyModalCont(props) {
  return props.displayPrivacyModal ? (
    <LSModal
      content={modalContent(props.selectedLanguage)}
      modalToggle={props.togglePrivacyModal}
    />
  ) : null;
}

const mapStateToProps = function (state) {
  return {
    displayPrivacyModal: state.modalReducer.displayPrivacyModal,
    selectedLanguage: state.modalReducer.selectedLanguage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    togglePrivacyModal: () => dispatch(togglePrivacyModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LSPrivacyPolicyModalCont);
