{
  "app.thankyou":"Thank You",
  "app.button.faqs": "FAQs",
  "app.button.terms": "Terms of Use",
  "app.button.privacy": "Privacy Policy",
  "app.button.feedback": "Feedback",
  "app.button.selectLanguage": "Select Language",
  "app.button.help": "Help",
  "app.button.start": "Start",
  "app.button.next": "Next",
  "app.button.submit": "Submit",
  "app.button.close": "Close",
  "app.button.return": "Return",
  "app.button.takepart": "OK - I'll Take Part",
  "app.menu.feedback.content": "If you've experienced an issue with the study or would just like to leave a comment, please feel free to do so here.",
  "app.contactModal.feedback": "Thank you for your feedback!",
  "app.takePartModal.line1": "If you leave now you will not be eligible for any participation reward.",
  "app.takePartModal.line2": "All you need is a webcam!",
  "app.takePartModal.reason1": "I do not have a webcam.",
  "app.takePartModal.reason2": "I'm not comfortable with my webcam being used for this survey.",
  "app.takePartModal.reason3": "I don't have time for this now.",
  "app.takePartModal.reason4": "Other reason.",
  "app.generic.redirect": "You will be redirected soon.",
  "app.generic.screenshotfail": "Please take a screenshot of this page as a proof of failed entry. You will be redirected soon.",
  "app.generic.screenshotsuccess": "Please take a screenshot of this page as a proof of completion. You will be redirected soon.",
  "app.init.welcome": "Welcome to the Lumen Research study platform",
  "app.init.intro": "The study platform allows us to onboard and setup users to a given ad study.",
  "app.init.message1": "Depending on the type of study, a user will be guided through a set of tasks.",
  "app.init.message2": "These tasks include; undertaking a survey, reading though a set of instructions and performing eye tracking calibration",
  "app.init.message3": "We will now guide you through a demo study.",
  "app.init.message4": "Throughout this study we may enable your webcam to perform eyetracking, but none of your data will be recorded.",
  "app.init.message5": "The current study is setup to use the following links on screenout/complete.",
  "app.prestart.loading": "Loading, please wait.",
  "app.index.title": "Welcome",
  "app.index.content.line1": "During this survey we will use your camera to estimate where you are looking. Don't worry, we won't take any photos or videos.",
  "app.index.content.line1gpdrwarning": "During this survey, we will use the front-facing camera on your device to take your images and video footage whilst you undertake various exercises involving looking at stimuli.",
  "app.index.content.line1alt": "Please make sure you are sat comfortably and then tap start to begin.",
  "app.index.content.line2": "Does your device have a camera? If so, please press the start button below.",
  "app.index.rejection": "Sorry, it appears you have followed a malformed link.",
  "app.index.webview": "Sorry, it appears you have accessed our page from inside a webview. Please open this link inside your browser. This may be possible to do directly from the webview menu.",
  "app.index.webview.chrome.1": "Tap the menu button above then select ‘Open in Chrome’.",
  "app.index.webview.chrome.2": "This will open this survey in a browser app, so it can work properly.",
  "app.index.webview.android.1": "Tap Start below to open this survey in a browser app, so it can work properly.",
  "app.index.webview.android.2": "If this fails, open the link to this page in a browser app manually (eg, copy and paste the link into the address bar in Chrome or use an option like ‘Open in Chrome’ in the menu bar above).",
  "app.index.webview.safari.1": "Tap the Safari icon at the bottom of the screen to continue.",
  "app.index.webview.safari.2": "It looks like this:",
  "app.index.webview.safari.3": "This will open the survey in Safari so it can work properly.",
  "app.index.wifi": "It appears your internet connectivity is too slow. Are you connected to wifi?",
  "app.index.tellmemore": "Tell me more.",
  "app.index.takepart": "I don't want to take part.",
  "app.index.webcamRetry": "We were unable to detect a webcam, which is required for this study. Please provide access to your webcam and try again.",
  "app.index.adblockRetry": "Oops! We have detected an ad blocker. For the best study experience please turn this off and try again.",
  "app.index.zoomRetry": "We require the browser to be set at 100% zoom, please zoom in/out and try again",
  "app.index.fullscreendesktopretry": "This study requires a fullscreen view, please ensure your browser window is maximised",
  "app.index.fullscreendesktoptest":"This study requires your browser window to be fullscreen, we need to perform a test to ensure your browser window is maximised",
  "app.index.fullscreendesktoptest2":"Two buttons will appear shortly, please click them to continue",
  "app.index.fullscreendesktopmaximised":"My Screen is already maximised",
  "app.index.fullscreendesktopskip":"Thank you, we have recorded this instance and will improve our detection processes.",
  "app.index.fullscreendesktopreset":"We have detected a change in window size, resetting the test.",
  "app.index.gotomobile":"Desktop users are not eligible for this study, please use the QR code below to open the study on mobile.",
  "app.index.returntopanel":"OR, Click the button below to return to the panel",
  "app.instructions.title": "Instructions",
  "app.instructions.instruction1": "If practical, do not wear glasses during the survey.",
  "app.instructions.instruction2": "Sit so you can comfortably keep your head and device still.",
  "app.instructions.instruction3": "Move your eyes not your head.",
  "app.instructions.instruction4": "Ready to start?",
  "app.instructions.instruction5": "Please use wifi to avoid data charges.",
  "app.instructions.instruction6": "Please read the following instructions carefully.",
  "app.instructions.instruction7": "Rest your phone on a stable surface.",
  "app.instructions.instruction7nav": "Use your finger to move in any direction on the screen.",
  "app.instructions.instruction8": "Please make sure your room is well lit - preferably from above (not behind).",
  "app.setup.title": "Setup",
  "app.setup.instruction0": "Keep your device in portrait mode.",
  "app.setup.instruction0a": "Please rotate your device to be portrait.",
  "app.setup.instruction0b": "Please rotate your device so its camera is on the left.",
  "app.setup.instruction0c": "Please rotate your device so its camera is on the right.",
  "app.setup.instruction0d": "Please rotate your device to be landscape.",
  "app.setup.instructioncamera": "Please click the button below to request camera access. To complete the survey you need to allow camera permissions throughout the study.",
  "app.setup.cameraText": "Request Access",
  "app.setup.instruction1": "Initialising...",
  "app.setup.instruction2": "When you are detected, position your head inside the box.",
  "app.setup.instruction3": "Keep your head and device in position until the survey is complete.",
  "app.setup.instruction3audio":"Please listen carefully and follow the instructions coming up on the screen.",
  "app.setup.instruction4": "Now we just need to validate.",
  "app.setup.instruction5": "Thanks! Remember to keep your head and device in the same position until the survey is complete.",
  "app.setup.instruction6": "Please swipe up to hide the address bar.",
  "app.setup.retry": "Error detected. Please keep your head and device still and do not cover the camera eg with fingers. Use the button below to retry.",
  "app.setup.retryPositioning": "We were unable to determine your head position. Please keep your head and device still and do not cover the camera eg with fingers. Use the button below to retry.",
  "app.setup.retryInit": "Error detected. Please make sure that the browser can access your camera. If you have previously rejected camera access permissions, you will need to accept this in the browser's privacy settings. Use the button below to retry.",
  "app.setup.retryBroker": "We were unable to complete the calibration process. Please try to close any unused apps and tabs to free up resources",
  "app.setup.retryButton": "Retry",
  "app.setup.calibrationProp.watch": "Follow the dot when it appears on screen",
  "app.setup.calibrationProp.watch.george": "Next, when you see a face, please keep looking at it whilst mirroring its head movements",
  "app.setup.calibrationProp.click": "Click anywhere to continue",
  "app.setup.calibrationProp.tap": "Tap anywhere to continue",
  "app.study.validationProp.watch": "Follow the dot when it appears on screen",
  "app.study.validationProp.restart": "Please try again",
  "app.study.validationProp.click": "Click anywhere to continue",
  "app.study.validationProp.tap": "Tap anywhere to continue",
  "app.study.render.error": "Domain not valid for render step.",
  "app.error.title": "Error",
  "app.error.generic": "Oops! Something went wrong.",
  "app.error.initialise": "Unable to initialise!",
  "app.error.findCamera": "Unable to find camera!",
  "app.error.headPosition": "Unable to determine your head position.",
  "app.error.calibrate": "Unable to calibrate.",
  "app.error.validate": "Unable to validate.",
  "app.error.start": "Failed to start.",
  "app.error.assign": "Failed to assign.",
  "app.error.osnotallowed": "Screenout: Your OS not allowed, please check which device you can use for this survey and try again.",
  "app.error.demoquotafullnoactivecell": "Quotafull: Thank you for your interest. This study is now closed to your demographic.",
  "app.error.cellquotafullnoactivecell":"No active Cell has been found for this Study. Cell Quotas are full.",
  "app.error.missingpanelparameter": "Missing panel parameter.",
  "app.error.browsernotallowed": "Browser not allowed.",
  "app.invalid.title": "Invalid",
  "app.invalid.generic": "Sorry, you are not eligible for this study.",
  "app.completion.title": "Completion",
  "app.completion.congratulations": "Congratulations! You have completed the study. This survey's access to your camera will now be terminated.",
  "app.completion.congratulations-noet": "Congratulations! You have completed the study.",
  "app.questions.required": "This question is required.",
  "app.faq.gdpr.intro1": "In this study we will use the front-facing camera on a mobile device to take images and video footage of a user whilst they are asked to undertake various exercises involving looking at stimuli under a range of environmental (e.g., lighting and positioning) conditions.  Participation is expected to take around 5 minutes.  The data is to be used to aid the continued development of software that can estimate where users are looking on a screen.",
  "app.faq.gdpr.intro2": "This research is being conducted in accordance with the MRS Code of Conduct and the Data Protection Act 2018.  Your privacy is important to us.  Before taking part in this study, you must confirm that you agree that by taking part in this study, you understand the data we are collecting and how it will be used, as described in this Privacy Policy, and consent to this data being used in this way.  This policy describes in more detail precisely what information is collected and how it is used and shared.",
  "app.faq.gdpr.question1": "What data will be collected?",
  "app.faq.gdpr.answer1.line1": "This study will involve the collection of the following types of data:",
  "app.faq.gdpr.answer1.line2": "“Sitting characteristics” – An anonymous ID used to identify a particular data collection ‘Sitting’ and data about the prevailing environmental test conditions during the session.",
  "app.faq.gdpr.answer1.line3": "“Participant characteristics” – Data about a respondent taking part in a given Sitting, including information about the participant’s demographic (e.g., age, gender), ethnic (e.g., skin type) and biological (e.g., eye colour) attributes.",
  "app.faq.gdpr.answer1.line4": "“Video file” – Images and/or video footage taken using the device’s front-facing camera to capture images of participants whilst they are looking at, and potentially interacting with, stimuli displayed on a screen or screens. Images will be captured of a participant’s eyes and face and anything else that unintentionally may be captured by the front-facing camera whilst the task is being completed. ",
  "app.faq.gdpr.answer1.line5": "“Metadata” - Data about the time at which individuals interacted with the stimuli, linked to the timestamp of each collected image.",
  "app.faq.gdpr.answer1.line6": "“Physical Device data” - Physical data about the device, including the device name, the screen size, screen rotation and camera sensor rotation.",
  "app.faq.gdpr.answer1.line7": "By taking part in this research, you understand and agree that the Content you are shown in this study may be confidential, the property of a third party and/or subject to trademark or copyright laws and that you will not make screenshots, record or otherwise copy any of the Content or redistribute any of the Content of the study in any way.",
  "app.faq.gdpr.question2": "How will the data be used / processed?",
  "app.faq.gdpr.answer2": "The data will be used for the development of software that can estimate whether users are present in front of a device, whether they are looking at stimuli, and where on the stimuli they may be looking.  It may also be used for demonstration purposes or for explaining to, or helping to evaluate for, third parties (clients, suppliers, partners) how our technology has been developed, and its performance.  The data may be reviewed by any number of data ‘annotators’ – employees or subcontractors or partners or any other third party that will be asked to watch back the video footage / images to assess the attention ‘states’ of participants during the research (e.g., are they present in the field of view of the camera? are they looking at the screen?)  The legal bases for processing the data will be informed consent and legitimate interests. ",
  "app.faq.gdpr.question3": "How long will the data be kept?",
  "app.faq.gdpr.answer3": "The data will be retained by us for use for as long as is reasonably necessary for the purposes described above.  The software being developed is periodically updated and evaluated and this data will be used to evaluate each iteration, so is expected to be retained and held for this purpose for several years, if not indefinitely.  The data will be deleted if it is deemed to be no longer useful for the purpose of developing or evaluating the stimuli-testing software. ",
  "app.faq.gdpr.question4": "Who will receive and process the data?",
  "app.faq.gdpr.answer4": "The data will be held and retained by Lumen Research Ltd.  Lumen Research Ltd is a company registered in England, company number 8682432.  Its registered office is at LABS house, 15-19 Bloomsbury Way, Holborn, London WC1A 2TH. Its data protection registration reference is ZA130772.  Its Data Protection Officer is Matt Robinson (matt.robinson@lumen-research.com).  You have the right to lodge a complaint about the handling and processing of your data at any time by contacting the UK’s Data Protection Authority, the Information Commissioner’s Office.  The ICO’s contact details are available on its website at https://ico.org.uk/global/contact-us/. By taking part in the study, you grant Lumen a worldwide, royalty-free, sublicensable, and transferable license to host, store, use, display, reproduce, modify, adapt, edit, publish, and distribute this data.",
  "app.faq.gdpr.question5": "Do I have a right to access my personal data?",
  "app.faq.gdpr.answer5": "Yes, you have the right to access your personal data, however it is important to be aware that your name will not be stored with the data collected during this exercise.  To identify your data records, you will need to provide us with the precise time and date at which you undertook the research, and we will need to ask for visual identification to ensure that the data records are correctly matched.",
  "app.faq.gdpr.question7": "Do I have a right to withdraw my consent to the processing of my personal data, or to the erasure of my personal data?",
  "app.faq.gdpr.answer7": "Yes, you have the right to withdraw your consent to the processing of your personal data, and to the erasure of this data.  However, it is important to be aware that your name will not be stored with the data collected during this exercise.  To identify your data record, you will need to provide us with the precise time and date at which you undertook the research, and we will ask for visual identification to ensure that the data records are correctly matched.  ",
  "app.faq.gdpr.question8": "How do I know my personal information is safe?",
  "app.faq.gdpr.answer8": "The data collected by Lumen Research is encrypted using Secure Sockets Layer (SSL) technology.  This is a commonly used high standard of encryption technology. This technology is used for both securing the transfer channels and the final storage.",
  "app.faq.gdpr.question9": "How will we use and disclose information we collect?",
  "app.faq.gdpr.answer9": "We may disclose the information we collect to other third-party service providers, to enable them to provide services to us. We may also disclose information to a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings). We may also use or disclose the information we collect from and about you as we believe to be necessary or appropriate: (a) under applicable law, including laws outside your country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities, including public and government authorities outside your country of residence; (d) to enforce our end user license agreement; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or others; (g) to allow us to pursue available remedies or limit the damages that we may sustain; and (h) to detect and resolve any fraud or security concerns. ",
  "app.faq.gdpr.question10": "What steps are taken to hold the data securely?",
  "app.faq.gdpr.answer10": "We take reasonable steps to protect your information using physical, electronic, or procedural security measures appropriate to the sensitivity of the information in our custody or control, which include safeguards to protect against loss or theft, as well as unauthorized access, disclosure, copying, use or modification. ",
  "app.faq.question1": "Q: What is Lumen Study Platform and what does taking part in an eye tracking survey involve?",
  "app.faq.answer1": "A: This survey involves the use of eye tracking technology. You will be asked to sit in front of your device’s webcam or front-facing camera and perform a calibration so that an estimate can be made of where you are looking on your screen. During the survey you will be asked to view some items (eg images, websites or videos) whilst the survey tool estimates where you are looking. The data that is captured from this ‘eye tracking’ are the coordinates of the estimated gaze points on your screen over time. The software uses images of you from your webcam to make these estimates but does not capture photos or videos of you. You may also be asked some questions about yourself, your experience and the items you have seen during the survey. The data is sent to Lumen Research Ltd. Lumen Research will analyse the data and share results with its clients.",
  "app.faq.question2": "Q: Why does the software require a webcam / camera and how is this used?",
  "app.faq.answer2": "A: The software uses images from your webcam or device’s camera to estimate where you are looking on a screen. It does this using a combination of facial tracking and eye tracking software that analyses images locally on your machine in real time. These images are processed and shown to you during calibration so that you can correctly position yourself but the images are not recorded so your privacy is protected. The data that is recorded and collected from the eye tracking is not images but numeric data about estimated gaze points on the stimulus over time.",
  "app.faq.question3": "Q: Do you take images of me whilst doing the eye tracking?",
  "app.faq.answer3": "A: The only time images are used is to allow you to correctly position yourself during calibration. These images are not saved, and only seen by you. The data that is recorded and collected from the eye tracking is not images but numerical data about estimated gaze points on the stimulus over time.",
  "app.faq.question4": "Q: What data is collected during the survey?",
  "app.faq.answer4.line1": "A: The software will collect some or all of the following five types of data:",
  "app.faq.answer4.line2": "“User ID” - The User ID used to identify respondents (for the purpose of administering participation rewards);",
  "app.faq.answer4.line3": "“Gaze data” – Estimates of where users are looking on their screen when shown stimuli on the screen",
  "app.faq.answer4.line4": "“Survey data” – Responses to questions answered by users",
  "app.faq.answer4.line5": "“Metadata” - Data about the time at which individuals successfully participated in the Research.",
  "app.faq.answer4.line6": "“Physical Device data” – Data about the computer on which a user participates such as the level of RAM, the type of CPU in the machine and the user agent (operating system and browser version) as well as information about the computer’s screen (size and resolution), webcam (make and resolution), and antivirus programs.",
  "app.faq.question5": "Q: How will the data collected be used?",
  "app.faq.answer5.line1": "A: The data collected will be used to explore how individuals view and interact with various types of visual images and media and to gauge people’s opinions of the images they are shown.",
  "app.faq.answer5.line2": "Lumen may share the research data with affiliated companies and with business partners and clients. While the research data might be shared with affiliates, partners and clients, this will typically be on an anonymous basis. Such affiliates, partners and clients may use this data to create summary descriptions of a wide range of consumer behaviours and consumer segments to develop products or to provide marketing solutions to their customers or consumers.",
  "app.faq.answer5.line3": "Lumen collect “Physical Device data” and “Metadata” to help manage the collection and processing of data, including quality control and volume management.",
  "app.faq.answer5.line4": "The data collected will be retained for the period necessary to fulfill the purposes outlined.",
  "app.faq.question6": "Q: How secure is my data?",
  "app.faq.answer6": "A: The data collected by Lumen is encrypted using SSL technology. This is the most commonly used high standard of encryption technology and is used by banks to secure banking transactions and other highly sensitive communications. It is also used by Amazon, PayPal, eBay, and other websites requiring bank-level security systems. Scientists estimate that breaking 256 bit encryption using current technology would require substantially more time than the age of the universe. Collected data is stored on secure web servers managed by Amazon Web Services (AWS). AWS is one of the most commonly used safe and dependable cloud computing services platforms and is used by major cloud-based commercial services including Netflix and Expedia.",
  "app.faq.question7": "Q: What obligations do you have regarding the Content you are shown?",
  "app.faq.answer7": "A: By participating, you also understand and agree that the Content you are shown in this study may be confidential, the property of a third party and/or subject to trademark or copyright laws and that you will not make screenshots, record or otherwise copy any of the Content or redistribute any of the Content of the study in any way.",
  "app.faq.question8": "Q: I'm not sure how good my webcam is, will it work for eye tracking?",
  "app.faq.answer8": "A: The system works with a large range of webcams – it should not be a problem.",
  "app.faq.question9": "Q: I've completed the study, do I need to do anything else?",
  "app.faq.answer9": "A: No it is not necessary to do anything else. The software will close down automatically.",
  "app.faq.question10": "Q: Do I need to be connected to the internet to participate?",
  "app.faq.answer10": "A: Yes, you will need an internet connection to download the program and to complete the task.",
  "app.faq.question11": "Q: Do I need to complete the task on a stable surface?",
  "app.faq.answer11": "A: Yes, for the best results, you should sit comfortably in front of your computer screen and, after the calibration, keep your head still whilst you are presented with the stimulus, until notified. We advise not taking part if you are on a train or balancing your laptop on your lap etc.",
  "app.faq.question12": "Q: The FAQs haven't solved my problem, what do I do now?",
  "app.faq.answer12": "A: Please email us on study-feedback@lumen-research.com and we'll get back to you as quickly as we can.",
  "app.faq.patent": "Lumen's testing of web content is powered by our own proprietary in-context testing technology (UK Patent Application Number 1816158.8)",
  "app.terms.line1": "These terms of use (“Terms of Use”) govern the agreement between You and Lumen Research Ltd (“Lumen” or “We”), a company registered in England, company number 8682432 with registered office at Unit 215, 22 Highbury Grove, London N5 2EF and data protection registration reference ZA130772, regarding your use of the “ViewPoints” eye tracking software (the “Software”) on your device.",
  "app.terms.line2": "1. Agreement to terms",
  "app.terms.line3": "By using the Software, you acknowledge that you have agreed to be bound by this Agreement. If you do not agree to the terms and conditions of this Agreement, do not complete the ViewPoints survey.",
  "app.terms.line4": "2. Confidential Information",
  "app.terms.line5": "When installing, using or in any way engaging with ViewPoints, you may have access to information and/or content belonging or pertaining to Lumen including information which is identified or treated by Lumen or any of Lumen’s clients or customers as confidential or which by reason of its character or the circumstances or manner of its disclosure is evidently confidential including (without prejudice to the foregoing generality) any information about business plans, maturing new business opportunities, research and development projects, designs, secret processes, codes, software designs, trade secrets, product or services development and formulae, know-how, inventions, sales statistics and forecasts, marketing strategies and plans, costs, profit and loss and other financial information (save to the extent published in audited accounts), prices and discount structures (all whether or not recorded in writing or in electronic or other format) (“Confidential Information”). Such Confidential Information shall remain the sole and exclusive property of Lumen and/or its customers, and in no event shall you obtain or receive any right, title and/or interest in such Confidential Information.",
  "app.terms.line6": "You must not either during your participation in ViewPoints or at any time (without limit) after the termination of such participation: divulge or communicate to any person; use for your own purposes; or through any failure to exercise due care and diligence, cause any unauthorised disclosure of any Confidential Information. You must at all times use your best endeavours to prevent publication or disclosure of any Confidential Information. These restrictions shall cease to apply to any information which shall become available to the public generally otherwise than through your default.",
  "app.terms.line7": "3. Lumen Property",
  "app.terms.line8": "The ViewPoints Software and all data, codes, keys, passwords, designs, drawings, records and other documents or materials relating to the Software shall be and remain the property of Lumen throughout the duration of your participation in a ViewPoints survey. Lumen owns all intellectual or proprietary rights in any and all existing and future intellectual or industrial property rights in and to any documents, materials, models, designs, drawings, processes, inventions, formulae, computer coding, data, system interfaces, methodologies, know-how, Confidential Information or other work, performed made, created, devised, developed, enhanced, deconstructed or discovered by you either alone or with any other person in connection with or relating to the Software and/or Device or capable of being used or adapted for use therein or in connection therewith (whether registered or unregistered), including all existing and future patents, copyrights, design rights, database rights, trade marks, semiconductor topography rights, plant varieties rights, internet rights/domain names, know-how and any and all applications for any of the foregoing and any and all rights to apply for any of the foregoing (“Proprietary Rights”). No Proprietary Rights shall pass to you at any time.",
  "app.terms.line9": "4. Terms of and restrictions on use",
  "app.terms.line10": "The ViewPoints Software is licensed to end users. Subject to this Agreement, we hereby agree to permit you, on a non-exclusive, revocable, non-transferable, non-sublicensable, limited basis, to use the ViewPoints Software on a device that you own or control or a personal computer with respect to which you represent and warrant that you have all necessary rights and permissions for such installation. For the avoidance of doubt, you may not use for any purpose outside of ViewPoints, translate, copy, reproduce, transmit by telecommunications, adapt, merge, change or modify the Software in any way.",
  "app.terms.line11": "You agree not to: (i) copy, modify, adapt, make available, rent, lease, timeshare, create a derivative work of, or attempt to sell or otherwise transfer any right in the Software (ii) disassemble, decompile, or reverse engineer any parts of the Software by any means; nor permit or assist any party to derive or attempt to derive the source code of, disassemble, decrypt, decompile or reverse engineer the Software; nor take any other steps in order to derive design information regarding the Software; (iii) use the Software for any purpose other than the purpose it was originally distributed to you for; (iv) use spiders, robots or other automated data mining techniques to catalogue, download, store, or otherwise reproduce or distribute the Software, or to manipulate the results of any survey or any other activity; (v) take any action to interfere with the Software or an individual’s use of the Software including, without limitation, by overloading, “flooding”, “mail bombing” or “crashing” the Software and the data storage system the Software uses through Amazon Web Services (vi) send, to us any illegal, deceptive, or harmful code, including, without limitation, any virus, spyware, adware, or any other harmful code.",
  "app.terms.line12": "All rights not expressly granted to you hereunder are reserved to us and our licensors. You agree that we have no obligation under this Agreement or otherwise to correct any bugs, defects or errors in the Software, or to otherwise support, maintain, improve, modify, upgrade, update or enhance the Software. We will not be responsible for providing assistance to you or your designees in the use of the Software in any manner.",
  "app.terms.line13": "We are not liable for any damage to your P.C. including but not limited to as a result of your use of the Software. We are not liable for any additional data charges incurred by you as a result of the data collected by the Software through your participation in the Research.",
  "app.terms.line14": "5. Disclaimer",
  "app.terms.line15": "We and our respective directors, officers, employees, agents, representatives, licensors and providers make no representation or warranty or condition (a)that the software will be available on a timely basis, or that access to the software will be uninterrupted or secure; (b)that defects or errors will be corrected; or (c)that the software or the servers or networks through which the software and the device are made available are secure or free of viruses or other harmful components. We shall have no obligation to furnish any maintenance and/or support services with respect to the software.",
  "app.terms.line16": "6. Limitation of liability",
  "app.terms.line17": "(a) To the fullest extent permissible under applicable law, you agree that we and our respective directors, officers, employees, agents, representatives, licensors and providers shall have no liability for any or all losses, damages or costs of any kind resulting from your installation, use or access of the software, your use of or inability to use the software, any information or materials made available on or through the software and/or as otherwise relating to the software or otherwise to this agreement, regardless of the nature of the legal, equitable or statutory right claimed to have been violated.",
  "app.terms.line18": "(b) To the fullest extent permissible under applicable law, your sole and exclusive remedy for dissatisfaction with the software is to stop using it.",
  "app.terms.line19": "(c) In no event shall any of our entities, directors, officers, employees, agents, representatives be liable to you for any incidental, indirect, consequential, statutory, exemplary, punitive or special damages, loss of revenue, loss of opportunity, loss of reputation, loss of use, loss of data, loss of other intangibles, or unauthorized interception of data collected by the software by third parties, whether foreseeable or not, however caused, and under any contract, tort (including without limitation negligence), strict liability or other theory, even if advised in advance of the possibility that such damages might arise.",
  "demo.index.content.line1": "During this demo we will use your camera to estimate where you are looking. Don't worry, we won't take any photos or videos.",
  "app.error.previousfail": "You have previously failed this study, please use the original link to start from the beginning.",
  "app.error.previoussuccess": "You have previously completed this study.",
  "app.error.route": "You have tried to start a study already in progress, please use the original link to start from the beginning.",
  "app.error.accesscode": "You have tried to start a study that does not exist, please check your link.",
  "app.error.webcam": "This Study requires access to a webcam",
  "app.error.adblocker": "This study requires adblock to be disabled.",
  "app.error.startsession": "Unable to start session",
  "app.error.fullscreen": "This study requires a fullscreen view.",
  "app.error.zoom": "This study requires the browser zoom to be set to 100%.",
  "app.index.webcamInstructions": "We are about to request access to your webcam, if you receive a popup, please click allow.",
  "app.setup.retryBack": "Please refrain from pressing the browser back button throughout this study, it may result in a loss of reward. Use the button below to retry.",
  "app.setup.retryaudible": "Please ensure your device is not muted and try again.",
  "app.restore.retry":"You have not spent enough time on the previous step, ",
  "app.restore.timeremaining":" minutes left",
  "app.error.postexposure":"Sorry, you have now used up all your attempts and unfortunately, you cannot proceed in the study."
}
