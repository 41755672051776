{
    "app.button.faqs": "Najčešća pitanja",
    "app.button.terms": "Uslovi Korišćenja",
    "app.button.privacy": "Politika privatnosti",
    "app.button.feedback": "Povratna informacija",
    "app.button.selectLanguage": "Izaberite jezik",
    "app.button.help": "Pomoć",
    "app.button.start": "Pokretanje",
    "app.button.next": "Dalje",
    "app.button.submit": "Podnesite",
    "app.button.close": "Zatvorite",
    "app.button.takepart": "U redu - uzeću učešće",
    "app.menu.feedback.content": "U koliko ste iskusili nekakav problem u vezi studije ili biste samo želeli da ostavite komentar, molimo da to slobodno učinite ovde.",
    "app.contactModal.feedback": "Hvala vam na povratnim informacijama!",
    "app.takePartModal.line1": "Ako sada odete nećete da imate pravo na bilo kakvu nagradu za učešće.",
    "app.takePartModal.line2": "Sve što vam je potrebno je veb kamera!",
    "app.takePartModal.reason1": "Nemam veb kameru.",
    "app.takePartModal.reason2": "Nelagodno mi je da se moja veb kamera koristi za ovo ispitivanje.",
    "app.takePartModal.reason3": "Nemam sada vremena za ovo.",
    "app.takePartModal.reason4": "Drugi razlog.",
    "app.generic.redirect": "Uskoro ćete biti preusmereni.",
    "app.init.welcome": "Dobrodošli na platformu za studije Lumen Research",
    "app.init.intro": "Platforma za studije omogućava nam da uvedemo i instaliramo korisnike za datu studiju oglasa.",
    "app.init.message1": "U zavisnosti od tipa studije, korisnik će biti proveden kroz skup zadataka.",
    "app.init.message2": "Ovi zadaci obuhvataju; sprovođenje ispitivanja, iščitavanje skupa uputstava i vršenje kalibracije praćenja očnih pokreta",
    "app.init.message3": "Sada ćemo vas provesti kroz pokaznu studiju.",
    "app.init.message4": "Tokom ove studije, vašu veb kameru možemo osposobiti za vršenje praćenja očnih pokreta, ali se ništa od vaših podataka neće beležiti.",
    "app.init.message5": "Trenutna studija je postavljena tako da koristi sledeće veze prilikom eliminacije/završetka.",
    "app.prestart.loading": "Učitavanje, molimo sačekajte.",
    "app.index.title": "Dobrodošli",
    "app.index.content.line1": "Tokom ovog ispitivanja koristićemo vašu kameru da procenimo u šta gledate. Ne brinete, nećemo fotografisati niti snimati.",
    "app.index.content.line1alt": "Molimo vas, postarajte se da sedite udobno, a zatim tapnite pokretanje da počnete.",
    "app.index.content.line2": "Da li vaš uređaj ima kameru? Ukoliko ima, molimo pritisnite dugme za početak ispod.",
    "app.index.rejection": "Izvinite, izgleda da ste pratili pogrešno uobličenu vezu.",
    "app.index.webview": "Izvinite, izgleda da ste našoj stranici pristupili iz pregleda veba. Molimo otvorite ovu vezu unutar svog pregledača. Ovo se može učiniti neposredno iz menija veb pregleda.",
    "app.index.webview.chrome.1": "Tapnite dugme menija iznad, a zatim izaberite „Otvori u Chromeu“.",
    "app.index.webview.chrome.2": "Ovim će ovaj upitnik biti otvoren u aplikaciji pregledača da bi mogao raditi pravilno.",
    "app.index.webview.android.1": "Tapnite dugme Početak ispod da biste otvorili ovaj upitnik u aplikaciji pregledača kako bi isti radio pravilno.",
    "app.index.webview.android.2": "Ukoliko ovo ne uspe, ručno otvorite vezu ka ovoj stranici u aplikaciji pregledača (npr. kopirajte i nalepite vezu na traku adresa u Chromeu ili koristitio opciju kao „Otvori u Chromeu“ na traci menija iznad).",
    "app.index.webview.safari.1": "Tapnite ikonu Safarija na dnu ekrana da biste nastavili.",
    "app.index.webview.safari.2": "Izgleda ovako:",
    "app.index.webview.safari.3": "Ovo će otvoriti upitnik u Safariju kako bi isti radio pravilno.",
    "app.index.wifi": "Izgleda da je vaša internet veza prespora. Da li ste povezani na bežičnu mrežu?",
    "app.index.tellmemore": "Dajte mi još podataka.",
    "app.index.takepart": "Ne želim da učestvujem.",
    "app.index.webcamRetry": "Nismo uspeli prepoznati veb kameru potrebnu za ovu studiju. Molimo da obezbedite pristup veb kameri i pokušate ponovo.",
    "app.index.adblockRetry": "Opa! Otkrili smo blokator oglasa. Za najbolje iskustvo sa studijom, molimo vas da isključite ovo i pokušate ponovo.",
    "app.index.zoomRetry": "Potrebno nam je da se pregledač podesi na uvećanje od 100%, molimo vas da uvećate/umanjite i pokušate ponovo",
    "app.index.fullscreendesktopretry": "Ova studija zahteva prikaz celog ekrana, uverite se da je prozor pregledača potpuno uvećan",
    "app.index.fullscreendesktoptest": "Ova studija zahteva prikaz celog ekrana u prozoru vašeg pregledača, moramo ozvršiti proveru radi obezbeđenja potpunog uvećanja prozora",
    "app.instructions.title": "Uputstva",
    "app.instructions.instruction1": "Ukoliko je to izvodljivo, nemojte nositi naočare tokom ispitivanja.",
    "app.instructions.instruction2": "Sedite tako da vam glava i uređaj ostanu u stabilnom položaju.",
    "app.instructions.instruction3": "Pomerajte oči, a ne glavu.",
    "app.instructions.instruction4": "Jeste li spremni da počnete?",
    "app.instructions.instruction5": "Molimo da koristite bežični pristup kako biste izbegli troškove saobraćaja podataka.",
    "app.instructions.instruction6": "Molimo da pažljivo pročitate sledeća uputstva.",
    "app.instructions.instruction7": "Odložite telefon na stabilnu površinu.",
    "app.instructions.instruction8": "Molimo vas da se postarate da vam soba bude dobro osvetljena - po mogućstvu odozgo (a ne iza).",
    "app.setup.title": "Podešavanje",
    "app.setup.instruction0": "Držite uređaj u uspravnom režimu.",
    "app.setup.instruction0a": "Molimo vas da uređaj okrenete tako da bude uspravno.",
    "app.setup.instruction0b": "Molimo vas da uređaj okrenete tako da kamera bude sa leve strane.",
    "app.setup.instruction0c": "Molimo vas da uređaj okrenete tako da kamera bude sa desno strane.",
    "app.setup.instruction0d": "Molimo da uređaj okrenete tako da bude vodoravno.",
    "app.setup.instructioncamera": "Molimo kliknite na dugme ispod da biste zatražili pristup kameri. Da biste završili upitnik potrebno je da dati dozvole za kameru tokom studije.",
    "app.setup.cameraText": "Zahtev za pristup",
    "app.setup.instruction1": "Pokretanje…",
    "app.setup.instruction2": "Kada vas prepozna, namestite svoju glavu unutar polja.",
    "app.setup.instruction3": "Zadržite glavu i uređaj u tom položaju dok se ispitivanje ne završi.",
    "app.setup.instruction4": "Sada samo treba da potvrdimo.",
    "app.setup.instruction5": "Hvala! Ne zaboravite da glavu i uređaj držite u istom položaju dok se ispitivanje ne završi.",
    "app.setup.instruction6": "Molimo vas da prstom prevučete nagore kako biste sakrili adresnu traku.",
    "app.setup.retry": "Prepoznata greška. Molimo vas da glavu i uređaj držite mirno i ne prekrivate kameru prstima. Koristite dugme ispod da pokušate ponovo.",
    "app.setup.retryPositioning": "Nismo mogli utvrditi položaj vaše glave. Molimo vas da glavu i uređaj držite mirno i ne prekrivate kameru prstima. Koristite dugme ispod da pokušate ponovo.",
    "app.setup.retryInit": "Prepoznata greška. Molimo da se postarate da pregledač može pristupiti vašoj kameri. Ako ste prethodno odbili dozvole za pristup kameri, moraćete to da prihvatite u podešavanjima privatnosti pregledača. Koristite dugme ispod da pokušate ponovo.",
    "app.setup.retryBroker": "Nismo mogli da završimo proces kalibracije. Molimo pokušajte zatvoriti sve neiskorišćene aplikacije i kartice da biste oslobodili resurse",
    "app.setup.retryButton": "Ponovite",
    "app.setup.calibrationProp.watch": "Pratite tačku kada se pojavi na ekranu",
    "app.setup.calibrationProp.watch.george": "Dalje, kada vidite lice, molimo vas nastavite da ga gledate oponašajući pokrete glave istog",
    "app.setup.calibrationProp.click": "Kliknite bilo gde da nastavite",
    "app.setup.calibrationProp.tap": "Tapnite bilo gde da nastavite",
    "app.study.validationProp.watch": "Pratite tačku kada se pojavi na ekranu",
    "app.study.validationProp.restart": "Molimo, pokušajte ponovo",
    "app.study.validationProp.click": "Kliknite bilo gde da nastavite",
    "app.study.validationProp.tap": "Tapnite bilo gde da nastavite",
    "app.study.render.error": "Pogrešan domen za korak vizuelizacije.",
    "app.error.title": "Greška",
    "app.error.generic": "Opa! Nešto je pošlo naopako.",
    "app.error.initialise": "Nije moguće pokrenuti!",
    "app.error.findCamera": "Nije moguće pronaći kameru!",
    "app.error.headPosition": "Nije moguće utvrditi položaj glave.",
    "app.error.calibrate": "Nije moguće iskalibrisati.",
    "app.error.validate": "Nije moguće proveriti.",
    "app.error.start": "Pokretanje nije uspelo.",
    "app.error.assign": "Dodeljivanje nije uspelo.",
    "app.error.osnotallowed": "Snimak ekrana: Vaš OS nije dozvoljen, molimo vas da proverite koji uređaj možete koristiti za ovo ispitivanje i pokušate ponovo.",
    "app.error.demoquotafullnoactivecell": "Kvota ispunjena: Hvala vam na interesovanju. Ova studija je sada završena za vašu demografsku grupu.",
    "app.error.missingpanelparameter": "Nedostaje panel parametar.",
    "app.invalid.title": "Neispravno",
    "app.invalid.generic": "Žao nam je, ali ne ispunjavate uslove za ovu studiju.",
    "app.completion.title": "Završetak",
    "app.completion.congratulations": "Čestitamo! Završili ste studiju. Pristup ovog ispitivanja vašoj kameri se sada ukida.",
    "app.completion.congratulations-noet": "Čestitamo! Završili ste studiju.",
    "app.questions.required": "Ovo pitanje je obavezno.",
    "app.faq.question1": "P: Šta je Platforma za istraživanje „Lumen“ i šta obuhvata učešće u ispitivanju praćenja očnih pokreta?",
    "app.faq.answer1": "O: Ovo ispitivanje obuhvata upotrebu tehnologije za praćanje očnih pokreta. Od vas će se tražiti da sednete pred veb kameru ili prednju kameru vašeg uređaja i izvršite kalibraciju tako da se može proceniti u koji deo ekrana gledate. Tokom ispitivanja, od vas će se tražiti da pogledate neke elemente (npr. slike, internet lokacije ili videe) dok ispitni alat procenjuje u šta gledate. Podaci prikupljeni ovim „praćenjem očnih pokreta“ su koordinate procenjenih tačaka pogleda na ekranu tokom vremena. Softver koristi vaše slike sa veb kamere radi izrade ovih procena, ali ne prikuplja vaše fotografije niti video zapise. Možda vam budu postavljana i neka pitanja o vama, vašem iskustvu i elementima koje ste videli tokom ispitivanja. Podaci se šalju Lumen Research Ltd., a Lumen Research će analizirati podatke i rezultate istih podeliti sa svojim klijentima.",
    "app.faq.question2": "P: Zašto softver zahteva veb kameru / kameru i kako se ista koristi?",
    "app.faq.answer2": "O: Softver koristi slike sa vaše veb kamere ili kamere uređaja radi procene dela ekrana u koji gledate. To čini korišćenjem kombinacije softvera za praćenje lica i praćenje očnih pokreta koji slike analizira lokalno na vašem uređaju u realnom vremenu. Ove slike se obrađuju i prikazuju vam se tokom kalibracije tako da možete zauzeti pravi položaj, ali se slike ne snimaju tako da je vaša privatnost zaštićena. Podaci koji se beleže i prikupljaju praćanjem očnih pokreta nisu slike već brojčani podaci o procenjenim tačkama pogleda na nadražaj tokom vremena.",
    "app.faq.question3": "P: Da li me snimate tokom praćenja očnih pokreta?",
    "app.faq.answer3": "O: Jedino vreme kada se slike koriste isto se čini kako bi vam se omogućilo da zauzmete pravi položaj tokom kalibracije. Ove slike se ne čuvaju, a vidite ih samo vi. Podaci koji se beleže i prikupljaju od praćenja očnih pokreta nisu slike već brojčani podaci o procenjenim tačkama pogleda na nadražaj tokom vremena.",
    "app.faq.question4": "P: Koji se podaci prikupljaju tokom ispitivanja?",
    "app.faq.answer4.line1": "O: Softver će prikupiti neke od sledećih pet tipova podataka ili sve njih:",
    "app.faq.answer4.line2": "„Identifikacioni dokument korisnika“ - Identifikacioni dokument korisnika koji se koristiti za prepoznavanje ispitanika (u svrhu dodele nagrada za učešće);",
    "app.faq.answer4.line3": "„Podaci o pogledu“ - Procene u koji deo ekrana gledaju prilikom prikazivanja nadražaja na istom",
    "app.faq.answer4.line4": "„Podaci o ispitivanju“ – Odgovori na pitanja od strane korisnika",
    "app.faq.answer4.line5": "„Metapodaci“ - Podaci o vremenu u kojem su pojedinci uspešno učestvovali u Istaživanju.",
    "app.faq.answer4.line6": "„Podaci o fizičkom uređaju“ - Podaci o računaru preko kojega korisnik učestvuje kao što su nivo RAM memorije, tip centralne procesorske jedinice uređaja i korisnički agent (operativni sistem i verzija pregledača) kao i informacije o ekranu računara (veličina i rezolucija), veb kamera (robna marka i rezolucija) i antivirusni programi.",
    "app.faq.question5": "P: Kako će se prikupljeni podaci koristiti?",
    "app.faq.answer5.line1": "O: Prikupljeni podaci će se koristiti za istraživanje načina na koji pojedinci gledaju i uspostavljaju interakciju sa različitim tipovima vizuelnih slika i medija, a da bi se odmerilo mišljenje ljudi o slikama koje su im prikazane.",
    "app.faq.answer5.line2": "Lumen podatke o istraživanju može deliti sa povezanim društvima i sa poslovnim partnerima i klijentima. Iako se podaci o istraživanju mogu deliti sa filijalama, partnerima i klijentima, to će se obično činiti anonimno. Takvi povezani subjekti, partneri i klijenti ove podatke mogu koristiti da bi kreirali rezime opisa širokog spektra ponašanja potrošača i segmenata potrošača za razvoj proizvoda ili pružanje marketiških rešenja svojim klijentima ili potrošačima.",
    "app.faq.answer5.line3": "Lumen „podatke fizičkih uređaja“ i „metapodatke“ prikuplja radi pomoći upravljanju prikupljanjem i obradom podataka, uključujući kontrolu kvaliteta i upravljanje obimom.",
    "app.faq.answer5.line4": "Prikupljeni podaci biće zadržani u periodu neophodnom za zadovoljenje navedenih potreba.",
    "app.faq.question6": "P: Koliko su moji podaci na sigurnom?",
    "app.faq.answer6": "O: Podaci koje je prikupio Lumen šifrovani su pomoću SSL tehnologije. Ovo je najčešće korišćeni visoki standard tehnologije šifrovanja i banke ga koriste za obezbeđivanje bankarskih transakcija i drugih veoma osetljivih komunukacija. Takođe ga koriste Amazon, Paypal, eBay i drugi veb sajtovi koji zahtevaju bezbednosne sisteme na nivou banke. Naučnici procenjuju da bi razbijanje 256-obitne enkripcije upotrebom trenutne tehnologije zahtevalo znatno više vremena od starosti univerzuma. Prikupljeni podaci čuvaju se na bezbednim veb serverima kojima upravljaju Amazon Web Services (AWS). AWS je jedna od najčešće korišćeni bezbednih i pouzdanih platformi za računarstvo u oblaku i koristi je vodeće komercijalne usluge zasnovane na oblaku uključujuči Netflix i Expediju.",
    "app.faq.question7": "P: Koje obaveze imate u pogledu Sadržaja koji vam se prikazuje?",
    "app.faq.answer7": "O: Učešćem takođe razumete i saglasni ste da Sadržaj koji vam se prikazuje u ovoj studiji može biti poverljiv, vlasništvo trećeg lica i/ili podložan zakonima o robnim markama ili autorskim pravima, te da nećete snimati ekran, beležiti ili na drugi način kopirati bilo šta od Sadržaja niti ikako redistribuirati išta od sadržaja studije.",
    "app.faq.question8": "P: Nisam siguran koliko je dobra moja veb kamera, te da li će raditi na praćenju očnih pokreta?",
    "app.faq.answer8": "O: Sistem fukcioniše sa širokim spektrom veb kamera - to ne bi trebalo da bude problem.",
    "app.faq.question9": "P: Završio sam studiju, da li bi trebalo da uradim još nešto?",
    "app.faq.answer9": "O: Ne, nije neophodno da radite išta drugo. Softver će se automatski zatvoriti.",
    "app.faq.question10": "P: Da li je potrebno da budem povezan na internet da bih učestvovao?",
    "app.faq.answer10": "O: Da, biće vam potrebna internet veza da biste preuzeli program i dovršili zadatak.",
    "app.faq.question11": "P: Da li je potrebno da zadatak obavim na stabilnoj površini?",
    "app.faq.answer11": "O: Da, za najbolje rezultate trebalo bi da sedite udobno ispred ekrana računara i da, nakon kalibracije, držite glavu mirno prilikom prikazivanja nadražaja, dok ne budete obavešteni. Savetujemo da ne učestvujete, ako ste u vozu ili balansirate svoj laptop u krilu, itd.",
    "app.faq.question12": "P: Najčešća pitanja nisu rešila moj problem, šta mi je sada činiti?",
    "app.faq.answer12": "O: Molimo vas da nam pošaljete elektronsku poruku na study-feedback@lumen-research.com i javićemo vam se što je pre moguće.",
    "app.faq.patent": "Lumenovu proveru veb sadržaja pokreće naša vlasnička tehnologija za testiranje u kontekstu (UK Patent Application Number 1816158.8)",
    "app.terms.line1": "Ovi uslovi korišćenja („Uslovi Korišćenja“) regulišu sporazum između Vas i Lumen Research Ltd („Lumen“ ili „Mi“), društva registrovanog u Engleskoj, matični broj: 8682432, sa sedištem u Unit 215, 22 Highbury Grove, London N5 2EF, i registrovanim referentnim brojem za zaštitu podataka: ZA130772, u vezi sa vašom upotrebom softvera za praćenje očnih pokreta „ViewPoints“ („Softer“).",
    "app.terms.line2": "1. Saglasnost sa uslovima",
    "app.terms.line3": "Korišćenjem Softvera potvrđujete svoju saglasnost da se obavežete ovim Ugovorom. Ako se ne slažete sa opštim uslovima ovog Ugovora, nemojte završiti ViewPoints ispitivanje.",
    "app.terms.line4": "2. Poverljive informacije",
    "app.terms.line5": "Prilikom instaliranja, korišćenja ili interakcije sa programom ViewPoints na bilo koji način, može vam biti omogućen pristup informacijama i/ili sadržaju koji pripada ili se odnosi na Lumen, uključujući informacije koje Lumen ili bilo ko od Lumenuvih klijenata ili potrošača tretira kao poverljive ili koje su iz razloga karaktera istog ili okolnosti ili načina njegovog objavljivanja očigledno poverljive, uključujući (bez dovođenja u pitanje opštosti navedenog) bilo koje informacije o poslovnim planovima, sazrevanje novih poslovnih mogućnosti, istraživački i razvojni projekti, dizajni, tajni postupci, kodovi, softverski dizajni, poslovne tajne, razvoj proizvoda ili usluga i formula, znanja i veštine, izumi, statistika prodaje i prognoze, marketinške strategije i planovi, troškovi, dobit i gubitak i druge finansijske informacije (ušteda u meri objavljenoj na revidiranim računima), cene i strukture popusta (sve bez obzira da li su zapisane u pisanoj formi ili u elektronskom ili drugom formatu) („Poverljive Informacije“). Takve Poverljive informacije ostaju u jedinom i isključivom vlasništvu Lumena i/ili korisnika istog i ni pod kojim okolnostima ne pribavljate niti dobijate bilo koje pravo, pravni osnov i/ili interes od takvih Poverljivih informacija.",
    "app.terms.line6": "Ne smete ni tokom učešća u programu ViewPoints ni i bilo kom trenutku (neograničeno) po prestanka takvog učešća: otkrivati ili saopštavati bilo kome; koristite u svoje svrhe; ili kroz kakav propust u vršenju dužne pažnje i marljivosti, izazvati bilo kakvo neovlašćeno objavljivanje bilo kakvi Poverljivih Informacija. Morate u svakom trenutnom koristiti svoje najbolje napore da biste sprečili objavljivanje ili otkrivanje bilo kojih Poverljivih Informacija. Ova ograničenja prestaju da se primenjuju na sve informacije koje će postati dostupne široj javnosti mimo vašeg propusta.",
    "app.terms.line7": "3. Vlasništvo Lumena",
    "app.terms.line8": "ViewPoints Softver i svi podaci, kodovi, ključevi, lozinke, dizajni, crteži, zapisi i drugi dokumenti ili materijali koje se odnose na Softver biće i ostaju vlasništvo Lumena tokom trajanja vašeg učešće u ViewPoints ispitivanju. Lumen poseduje sva intelektualna ili vlasnička prava u bilo kom i svim postojećem i budućim pravima intelektualne ili industrijske svojine u bilo kom u i na bilo kom dokumentu, materijalu, modelu, dizajnu, crtežu, procesu, pronalasku, formuli, računarskom kodiranju, podacima, sistemskim interfejsima, metodologijama, znanjima i veštinama, Poverljivim Informacijama ili drugim poslovima, izvršenim, kreiranim, osmišljenim, razvijenim, poboljšanim, dekonstruisanim ili otkrivenim od strane vas samih ili sa bilom kojom drugom osobom u vezi sa Softverom i/ili Uređajem ili upotrebljivim ili prilagodljivim za upotrebu u toj zemlji ili u vezi sa tim (bilo da je registovan ili neregistrovan), uključujuči sve postojeće i buduće patente, autorska prava, prava na dizajn, prava baze podataka, trgovinske oznake, prava na topografiju, poluprovodnike, prava na biljne sorte, internet prava/imena domena, znanja i veštine i sve aplikacije za bilo šta od navedenog i sva prava da ponesu zahtev za bilo šta od navedenog (\"Vlasnička Prava\"). Nijedno Vlasničko Pravo vam se ne prosleđuje u bilo kom trenutku.",
    "app.terms.line9": "4. Uslovi korišćenja i ograničenja upotrebe",
    "app.terms.line10": "ViewPoints Softver je licenciran krajnim korisnicima. Podležemo ovom Ugovorom, ovim se slažemo da vam dozvolimo, na neekskluzivnom, opozivnom, neprenosivom, nepristupljivom, ogragničenom osnovu, da koristite ViewPoints Softver na uređaju koji posedujete ili kontrolišete ili lični računar u odnosu na koji predstavljate i garantujete da imate sva potrebna prava i dozvole za takvu instalaciju. Da biste izbegli sumnju, ne smete da koristite u bilo koje svrhe izvan programa ViewPoints, prevodite, kopirate, reprodukujete, prenosite telekomunikacije, prilagođavate se, objedinjujete, menjate ili modifikujete Softver na bilo koji način.",
    "app.terms.line11": "Slažete se da nećete: (i) kopirati, izmeniti, prilagoditi, učiniti dostupnim, iznajmiti, zakupiti, vremenski deliti, kreirati derivatni rad ili pokušate prodati ili na drugi način preneti bilo koje pravo na Softver (ii) rastavljanje, dekompilacije ili obrnutog inžinjeringa bilo koga dela Softvera na bilo koji način; niti dozvolite ili pomoći bilo kojoj stranci da izvede ili pokuša da izvede izvorni kod, rastavi, dešifruje, dekompiluje, ili izvede obrnuti inženjering Softvera; niti preduzimaju bilo kakve druge korake da bi se dobile informacije o dizajnu u vezi sa Softverom; (iii) korisiti Softver u koje druge svrhe osim u svrhu za koju vam je distrubuiran; (iv) koristiti paukove, robote ili druge automatizovane tehnike rudarenja podataka za katalogisanje, preuzimanje, skladištenje ili na drugi način reprodukovanje ili distribuciju Softvera ili za manipulisanje rezultatima bilo kog ispitivanja ili bilo koje druge aktivnosti; (v) preuzmite bilo kakvo radnju da biste ometali Softver ili upotrebu Softver od strane pojedinca, uključujuči, bez ograničenja, preopterećenje, „poplave“, „bombardovanje poštom“ ili „obaranje“ Softvera i sistema za skladištenje podataka koje Softver koristi putem Amazon Web Services (vi) slanje, nama bilo kog nezakonitog, obmanjujućem ili štetnom kodu, uključujući, bez ograničenja, bilo koji virus, špijunski softver, adver ili bilo koji drugi štetan kod.",
    "app.terms.line12": "Sva prava koja vam nisu izričito dodeljana ovim su rezervisna za nas i naše izdavaoce licenci. Slažete se da nemamo obavezu prema ovom Ugovorom ili na drugi način da ispravimo greške, nedostatke ili greške u Softveru ili da na drugi način podržimo, održimo, poboljšamo, izmenimo, nadogradimo, ažuriramo ili poboljšamo Softver. Nećemo biti odgovorni za pružanje pomoći vama ili vašim dizajnima u korišćenju Softvera na bilo koji način.",
    "app.terms.line13": "Ne snosimo odgovornosti za bilo kakvu štetu na vašem personalnom računaru, uključujući, ali ne ograničavajuči se na situaciju usled vašeg korišćenja Softvera. Ne snosimo odgovornost za bilo kakve dodatne troškove protoka podataka koji su vam pričinjeni usled podataka koje je prikupio Softver kroz vaše učešće u Istraživanju.",
    "app.terms.line14": "5. Odricanje od odgovornosti",
    "app.terms.line15": "Mi i naši direktori, službenici, zaposleni, agenti, predstavnici, izdavaoci licenci i dobavljači ne dajemo nikakve garancije ili uslove(a) da će softver biti dostupan na vreme ili da će pristup softveru biti neometan ili bezbedan; (b) da će nedostaci ili greške biti ispravljeni; ili (c) da su softver ili serveri ili mreže preko kojih su softver i uređaj dostupne bezbedni ili oslobođeni virusa ili drugi štetnih komponenti. Nećemo biti u obavezi da opremimo usluge održavanje i/ili podrške u odnosu na softver.",
    "app.terms.line16": "6. Ograničenje odgovornosti",
    "app.terms.line17": "(a) U punoj meri dozvoljenoj po važećem zakonu, slažete se da mi i naši direktori, službenici, zaposleni, agenti, predstavnici, izdavaoci licenci i dobavljači nećemo snositi nikakvu odgovornost za bilo kakve ili sve gubitke, štetu ili troškove bilo koje vrste nastale usled vaše instalacije, korišćenja ili pristupa softvera, vaše upotrebe ili nemogućnosti korišćenja softvera, bilo koja informacija ili materijale koji su dostupni na softveru ili putem njega/ili inače u vezi sa softverom ili inače sa ovim ugovorom, bez obzira na prirodu, zakonsko, pravedeno ili statutarno pravo za koje se tvrdi da je prekršeno.",
    "app.terms.line18": "(b) U najmanjoj mogućoj dopuštenoj meri po važećem zakonu, vaš jedini i isključivi lek za nezadovoljstvo softverom je da prestanete da ga koristite.",
    "app.terms.line19": "(c) Ni u kom slučaju niko od naših subjekata, direktora, službenika, zaposlenih, agenata, predstavnka neće biti odgovoran za bilo kakvo incidentnu, indirektnu, posledničnu, statutarnu, uzornu, kaznenu ili posebnu štetu, gubitak prihoda, gubitak mogućnosti, gubitak ugleda, gubitak upotrebe, gubitak podataka, gubitak drugih nematerijalnih podataka ili neovlašćeno presretanje podataka prikupljenih od strane softvera, bez obzira da li je to predvidivo ili ne, kako god prouzorkovano, i po bilo kom ugovoru, deliktu (uključujući i bez ograničenja i namer), strogoj odgovornosti ili nekoj drugoj teroiji, čak i iako se unapred ukazuje na mogućnost da se takva šteta pojavi.",
    "demo.index.content.line1": "Tokom ove demonstracije koristićemo vašu kameru da procenimo u šta gledate. Ne brinete, nećemo fotografisati niti snimati.",
    "app.error.previousfail": "Prethodno niste uspeli u ovoj studiji, molimo koristite izvornu vezu za početak.",
    "app.error.previoussuccess": "Prethodno ste završili ovu studiju.",
    "app.error.route": "Pokušali ste da započnete studiju koja je već u toku, korisiteizvornu vezu za početak.",
    "app.error.accesscode": "Pokušali ste da započnete studiju koja ne postoji, proverite svoju vezu.",
    "app.error.webcam": "Ova Studija zahteva pristup veb kameri",
    "app.error.adblocker": "Ova studija zahteva obemogućavanje blokatora oglasa.",
    "app.error.startsession": "Nije moguće započeti seansu",
    "app.error.fullscreen": "Ova studija zahteva prikaz celog ekrana.",
    "app.error.zoom": "Ova studija zahteva da uvećanje pregledača bude podešeno na 100%.",
    "app.index.webcamInstructions": "Upravo ćemo zatražiti pristup vašoj veb kameri, ako dobijete iskačući prozor, kliknite da dozvoljavate.",
    "app.setup.retryBack": "Molimo vas da se uzdržite od pritiskanje dugmeta za povratak u pretraživaču tokom ove studije, jer to može dovesti do gubitka nagrade. Koristite dugme ispod da pokušate ponovo."
  }