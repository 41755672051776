{
  "title": "﻿Lumen Study Platform - Politik om beskyttelse af personlige oplysninger",
  "date": "July 2022",
  "contactDetails": {
    "title": "Vores kontaktoplysninger",
    "body": [
      "Navn: Lumen Research Ltd (“Lumen”)",
      "Adresse: Screenworks, Unit 215, 22 Highbury Grove, London N5 2ER",
      "Telefonnummer: +44(0)2037355199 ",
      "E-mail: dataprotection@lumen-research.com",
      "ICO registreringsnummer: ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "Denne fortrolighedspolitik forklarer arten af de oplysninger, vi indsamler fra deltagere, der deltager i en eye tracking-undersøgelse på Lumen Study Platform, og vores politik vedrørende eventuelle personlige oplysninger, der måtte blive indsamlet. "
      ],
      "heading": "Indledning"
    },
    {
      "body": [
        "I en typisk undersøgelse indsamler Lumen ikke personlige oplysninger som defineret i henhold til den britiske generelle databeskyttelsesforordning, men indsamler estimater af blikpunktsskøn, undersøgelsessvar, metadata om, hvornår og på hvilken type enhed dataindsamlingen fandt sted, og respondent-id'er med henblik på administration af belønninger.",
        "I en typisk undersøgelse vil du blive bedt om at sidde foran din enheds webcam eller frontkamera og foretage en kalibrering, så der kan foretages en vurdering af, hvor du kigger hen på skærmen. Under undersøgelsen vil du blive bedt om at se nogle elementer (f.eks. billeder, websteder eller videoer), mens undersøgelsesværktøjet vurderer, hvor du kigger hen. De data, der indsamles fra denne \"eye tracking\", er koordinaterne for de anslåede blikpunkter på din skærm over tid. Softwaren bruger billeder af dig fra dit webkamera til at foretage disse skøn, men tager ikke billeder eller videoer af dig. ",
        "Du kan også blive stillet nogle spørgsmål om dig selv, din oplevelse og de ting, du har set i løbet af undersøgelsen. ",
        "Dataene fra disse undersøgelser sendes til Lumen Research Ltd (\"Lumen\"). Lumen vil analysere dataene og dele resultaterne med sine kunder. ",
        "I disse undersøgelser vil et alfanumerisk respondent-id også blive videregivet af en tredjepart til Lumen, så Lumen kan spore, hvilke respondenter der gennemfører undersøgelsen.  Lumen vil dele disse oplysninger om gennemførte undersøgelser med tredjeparter med henblik på at give belønninger til dem, der har gennemført undersøgelsen. "
      ],
      "heading": "Den type oplysninger, vi indsamler"
    },
    {
      "body": [
        "Indimellem kan respondenterne blive bedt om at deltage i et forskningsprojekt, der kræver mere end én periode med dataindsamling.  I dette tilfælde vil respondent-id'et blive brugt som en vedvarende identifikator til at kontakte en deltager igen for at få vedkommende til at deltage i en eventuel efterfølgende dataindsamling.  I dette scenario vil respondent-ID'et blive betragtet som en personlig oplysning.  "
      ],
      "heading": "Den type personlige oplysninger, vi indsamler"
    },
    {
      "body": [
        "Som det er tilfældet med en typisk undersøgelse, vil et vedvarende respondent-id blive videregivet til os af en tredjepart, der har rekrutteret en deltager til vores forskning.  Vi vil bruge det til at kontakte enkeltpersoner igen for at indlede deres efterfølgende deltagelse i undersøgelsen og kan dele det med tredjeparter for at lette denne efterfølgende deltagelse.",
        "I henhold til den britiske generelle databeskyttelsesforordning (UK GDPR) er det lovlige grundlag, som vi baserer os på for behandling af disse oplysninger, dit samtykke.  Du kan til enhver tid trække dit samtykke tilbage.  Det kan du gøre ved at kontakte os på dataprotection@lumen-research.com"
      ],
      "heading": "Hvordan vi får de personlige oplysninger og hvorfor vi har dem"
    },
    {
      "body": [
        "Dine oplysninger opbevares sikkert og krypteres ved hjælp af SSL-teknologi. Dette er den mest almindeligt anvendte høje standard for krypteringsteknologi og bruges af banker til at sikre banktransaktioner og anden meget følsom kommunikation. Den bruges også af Amazon, PayPal, eBay og andre websteder, der kræver sikkerhedssystemer på bankniveau. Forskere anslår, at det vil kræve betydeligt mere tid end universets alder at bryde 256 bit-krypteringen med den nuværende teknologi. De indsamlede data lagres på sikre webservere, der forvaltes af Amazon Web Services (AWS). AWS er en af de mest almindeligt anvendte sikre og pålidelige platforme for cloud computing-tjenester og anvendes af store cloud-baserede kommercielle tjenester, herunder Netflix og Expedia. ",
        "Vi opbevarer vedvarende identifikatorer i den periode, der er nødvendig for at kontakte respondenterne igen i forbindelse med en undersøgelse i flere faser.  Dette kan variere afhængigt af forskningens art og vil blive meddelt, når du giver dit samtykke til at deltage.  Når dataindsamlingen til et sådant projekt er afsluttet, vil identifikatorerne kun blive opbevaret i det omfang, det er nødvendigt for at analysere og rapportere dataene.  Når analysen og rapporteringen af et projekt er afsluttet, vil vi bortskaffe disse personlige oplysninger ved at slette dem fra vores databaser."
      ],
      "heading": "Hvordan vi opbevarer dine personlige oplysninger"
    }
  ],
  "dataProtection": {
    "body": [
      "I henhold til databeskyttelseslovgivningen har du rettigheder, herunder:"
    ],
    "bullets": [
      {
        "title": "Din ret til adgang",
        "text": [
          "Du har ret til at bede os om at få kopier af dine personlige oplysninger. "
        ]
      },
      {
        "title": "Din ret til berigtigelse",
        "text": [
          "Du har ret til at bede os om at berigtige personlige oplysninger, som du mener er unøjagtige. Du har også ret til at bede os om at supplere oplysninger, som du mener er ufuldstændige."
        ]
      },
      {
        "title": "Din ret til sletning",
        "text": [
          "Du har ret til at bede os om at slette dine personlige oplysninger under visse omstændigheder."
        ]
      },
      {
        "title": "Din ret til begrænsning af behandlingen",
        "text": [
          "Du har ret til at bede os om at begrænse behandlingen af dine personlige oplysninger under visse omstændigheder."
        ]
      },
      {
        "title": "Din ret til at trække dit samtykke tilbage",
        "text": [
          "Du har ret til at trække dit samtykke til behandling af dine personlige oplysninger tilbage."
        ]
      },
      {
        "title": "Din ret til dataportabilitet",
        "text": [
          "Du har ret til at anmode om, at vi overfører de personlige oplysninger, du har givet os, til en anden organisation eller til dig selv under visse omstændigheder.",
          "Du er ikke forpligtet til at betale noget gebyr for at udøve dine rettigheder. Hvis du fremsætter en anmodning, har vi en måned til at svare dig.",
          "Du bedes kontakte os på dataprotection@lumen-research.com, hvis du ønsker at fremsætte en anmodning."
        ]
      }
    ],
    "title": "Dine databeskyttelsesrettigheder"
  },
  "complaints": {
    "body": [
      "Hvis du er bekymret over vores brug af dine personlige oplysninger, kan du indgive en klage til os på dataprotection@lumen-research.com.",
      "Du kan også klage til ICO, hvis du er utilfreds med den måde, vi har brugt dine oplysninger på."
    ],
    "address": {
      "body": [
        "ICO's adresse ",
        "Information Commissioner’s Office",
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ]
    },
    "title": "Sådan klager du",
    "number": "Hjælpelinjenummer: 0303 123 1113",
    "website": "ICO hjemmeside: https://www.ico.org.uk"
  }
}
