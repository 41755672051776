{
  "app.button.faqs": "Spørgsmål og svar",
  "app.button.terms": "Betingelser for anvendelse",
  "app.button.privacy": "Politik for beskyttelse af personoplysninger",
  "app.button.feedback": "Kommentarer",
  "app.button.selectLanguage": "Vælg sprog",
  "app.button.help": "Hjælp",
  "app.button.start": "Start",
  "app.button.next": "Næste",
  "app.button.submit": "Send",
  "app.button.takepart": "Ja tak, jeg vil gerne deltage",
  "app.menu.feedback.content": "Hvis du har haft problemer med spørgeundersøgelsen eller har kommentarer, kan du gøre os opmærksom på det her.",
  "app.contactModal.feedback": "Tak for din kommentar!",
  "app.takePartModal.line1": "Hvis du stopper nu og ikke vil fortsætte, får du ingen belønning for at deltage.",
  "app.takePartModal.line2": "Det eneste, du skal bruge, er et webcam!",
  "app.takePartModal.reason1": "Jeg har ikke et webcam.",
  "app.takePartModal.reason2": "Jeg bryder mig ikke om at bruge mit webcam til denne spørgeundersøgelse.",
  "app.takePartModal.reason3": "Jeg har ikke tid til det nu.",
  "app.takePartModal.reason4": "Anden årsag.",
  "app.generic.redirect": "Du bliver videresendt om lidt.",
  "app.prestart.loading": "Indlæser ... vent et øjeblik.",
  "app.index.title": "Velkommen",
  "app.index.content.line1": "Til denne spørgeundersøgelse bruger vi dit webcam til at vurdere, hvad du kigger på. Og bare rolig, vi tager hverken billeder eller optager video.",
  "app.index.content.line1alt": "Sæt dig godt til rette, og tryk på Start for at begynde.",
  "app.index.content.line2": "Er der et kamera på din enhed? Hvis der er, skal du trykke på knappen Start nedenfor.",
  "app.index.rejection": "Beklager. Det ser ud til, at du har valgt et link i et forkert format.",
  "app.index.webview": "Beklager. Det ser ud til, at du er gået til vores side fra en webvisning. Åbn dette link i din browser. Det kan gøres direkte fra webvisningsmenuen.",
  "app.index.webview.chrome.1": "Tryk på menuknappen ovenfor, og vælg \"Åbn i Chrome\".",
  "app.index.webview.chrome.2": "Derved åbnes spørgeundersøgelsen i en browserapp, hvor den fungerer rigtigt.",
  "app.index.webview.android.1": "Tryk på Start nedenfor for at åbne spørgeundersøgelsen i en browserapp, så den kan fungere rigtigt.",
  "app.index.webview.android.2": "Hvis ikke, skal du åbne linket til denne side manuelt i en browserapp (f.eks. ved at kopiere og indsætte linket i adresselinjen i Chrome eller ved at vælge et menupunkt, f.eks. \"Åbn i Chrome\", på menulinjen ovenfor).",
  "app.index.webview.safari.1": "Tryk på Safari-ikonet nederst på skærmen for at fortsætte.",
  "app.index.webview.safari.2": "Det ser sådan ud:",
  "app.index.webview.safari.3": "Derved åbnes spørgeundersøgelsen i Safari, hvor den fungerer rigtigt.",
  "app.index.wifi": "Det ser ud til, at din internetforbindelse er for langsom. Har du trådløs internetforbindelse?",
  "app.index.tellmemore": "Jeg vil gerne vide mere.",
  "app.index.takepart": "Jeg ønsker ikke at deltage.",
  "app.index.webcamRetry": "Vi kunne ikke finde et webcam, som er nødvendigt for denne undersøgelse. Giv venligst adgang til dit webcam og prøv igen.",
  "app.index.adblockRetry": "Ups! Vi har opdaget en adblocker. For at få den bedste undersøgelsesoplevelse bedes du slå denne fra og prøve igen.",
  "app.instructions.title": "Vejledning",
  "app.instructions.instruction1": "Undgå så vidt muligt at bruge briller, når du svarer på spørgsmålene.",
  "app.instructions.instruction2": "Sid behageligt, og hold hovedet og enheden stille.",
  "app.instructions.instruction3": "Flyt blikket uden at bevæge hovedet.",
  "app.instructions.instruction4": "Klar til at gå i gang?",
  "app.instructions.instruction5": "Brug wifi, så du undgår at bruge data, hvis det er relevant.",
  "app.instructions.instruction6": "Læs følgende vejledning grundigt.",
  "app.instructions.instruction7": "Læg mobiltelefonen på et stabilt underlag.",
  "app.instructions.instruction8": "Sørg for god belysning i lokalet, helst ovenfra (ikke bag dig).",
  "app.setup.title": "Placering",
  "app.setup.instruction0": "Enheden skal holdes lodret.",
  "app.setup.instruction0a": "Vend din enhed lodret.",
  "app.setup.instruction0b": "Vend din enhed, så kameraet er til venstre.",
  "app.setup.instruction0c": "Vend din enhed, så kameraet er til højre.",
  "app.setup.instruction1": "Starter ...",
  "app.setup.instruction2": "Når du er registreret af kameraet, skal du holde hovedet inden for rammen.",
  "app.setup.instruction3": "Hold hovedet og enheden i den samme stilling, indtil du har gennemført spørgeundersøgelsen.",
  "app.setup.instruction4": "Nu skal vi bare have det bekræftet.",
  "app.setup.instruction5": "Tak for det! Husk at holde hovedet og enheden i den samme stilling, indtil du har gennemført spørgeundersøgelsen.",
  "app.setup.instruction6": "Stryg opad på skærmen for at skjule adresselinjen.",
  "app.setup.retry": "Der er sket en fejl. Du skal holde dit hoved og enheden stille og ikke dække kameralinsen f.eks. med fingrene. Brug knappen nedenfor for at prøve igen.",
  "app.setup.retryInit": "Der er sket en fejl. Kontroller, at browseren har adgang til dit kamera. Hvis du tidligere har afvist adgangstilladelse til kameraet, skal du ændre dette til at acceptere adgang i browserens privatlivsindstillinger. Brug knappen nedenfor for at prøve igen.",
  "app.setup.retryButton": "Prøv igen",
  "app.setup.calibrationProp.watch": "Følg prikken med øjnene, når den bliver vist på skærmen",
  "app.setup.calibrationProp.watch.george": "Når der dernæst bliver vist et ansigt, skal du blive ved at kigge på det, mens du efterligner dets bevægelse med hovedet",
  "app.setup.calibrationProp.click": "Klik et vilkårligt sted for at fortsætte",
  "app.setup.calibrationProp.tap": "Tryk et vilkårligt sted for at fortsætte",
  "app.study.validationProp.watch": "Følg prikken med øjnene, når den bliver vist på skærmen",
  "app.study.validationProp.restart": "Prøv igen",
  "app.study.validationProp.click": "Klik et vilkårligt sted for at fortsætte",
  "app.study.validationProp.tap": "Tryk et vilkårligt sted for at fortsætte",
  "app.study.render.error": "Dette domæne kan ikke anvendes til spørgeundersøgelsen.",
  "app.error.title": "Fejl",
  "app.error.generic": "Ups! Der er opstået et problem.",
  "app.error.initialise": "Det er ikke muligt at starte!",
  "app.error.findCamera": "Kameraet kan ikke findes!",
  "app.error.headPosition": "Placeringen af dit hoved kan ikke registreres.",
  "app.error.calibrate": "Det er ikke muligt at kalibrere.",
  "app.error.validate": "Det er ikke muligt at bekræfte.",
  "app.error.start": "Kan ikke starte.",
  "app.error.assign": "Kan ikke fortsætte.",
  "app.error.osnotallowed": "Dette operativsystem er ikke kompatibelt. Kontroller, hvilken enhed du kan bruge til at deltage i denne spørgeundersøgelse, og prøv igen.",
  "app.error.demoquotafullnoactivecell": "Tak for din interesse. Der er ikke brug for flere deltagere med din demografiske baggrund til denne spørgeundersøgelse.",
  "app.invalid.title": "Ugyldig",
  "app.invalid.generic": "Beklager. Du opfylder ikke betingelserne for at deltage i denne spørgeundersøgelse.",
  "app.completion.title": "Gennemført",
  "app.completion.congratulations": "Sådan! Nu har du gennemført spørgeundersøgelsen. Nu afbrydes spørgeundersøgelsens forbindelse til dit kamera.",
  "app.questions.required": "Dette spørgsmål skal besvares.",
  "app.faq.question1": "Sp: Hvad er Lumen Study Platform, og hvad indebærer det at deltage i en spørgeundersøgelse med eye-tracking?",
  "app.faq.answer1": "Sv: Deltagelse i denne spørgeundersøgelse er baseret på eye-tracking, som er en teknologi, der registrerer øjenbevægelser. Du bliver bedt om at sidde foran din enheds webcam eller fremadvendte kamera og foretage en kalibrering, så eye-trackingsoftwaren kan vurdere, hvor du kigger på skærmen. I spørgeundersøgelsen bliver du bedt om at kigge på forskellige ting, såkaldte \"stimuli\" (f.eks. billeder, websteder eller videoer), mens softwaren vurderer, hvor du kigger på skærmen. De data, der registreres via denne \"øjentracking\", er de koordinater, der anvendes som de anslåede fikseringspunkter for dit blik på skærmen undervejs. Softwaren anvender billeder af dig fra dit webcam til at foretage disse vurderinger, men der tages hverken billeder eller optages video af dig. Der kan også være spørgsmål om dig selv, din brugeroplevelse og de ting, du har set ved denne spørgeundersøgelse. Disse data bliver sendt til Lumen Research Ltd, som analyserer dem og videregiver resultaterne til sine erhvervskunder.",
  "app.faq.question2": "Sp: Hvorfor skal softwaren bruge et webcam/kamera, og hvordan bruges den?",
  "app.faq.answer2": "Sv: Softwaren bruger billeder fra dit webcam eller din enheds kamera til at vurdere, hvor du kigger på skærmen. Det gør den ved at kombinere ansigtstracking med øjentracking for at analysere billeder lokalt på din enhed i realtid. Disse billeder bliver behandlet og vist, når du skal kalibrere softwaren, så du sidder rigtigt under spørgeundersøgelsen. Men billederne optages ikke, dvs. at disse personlige data ikke bliver opbevaret. De data, der registreres og indhentes via øjentracking, er ikke billeder, men taldata for fikseringspunkter for dit blik på skærmen undervejs i spørgeundersøgelsen.",
  "app.faq.question3": "Sp: Tager I billeder af mig ved øjentrackingen?",
  "app.faq.answer3": "Sv: De eneste billeder, der anvendes, er til at placere dig rigtigt i forhold til skærmen ved kalibreringen. Disse billeder bliver ikke gemt, og de bliver kun set af dig. De data, der registreres og indhentes via øjentracking, er ikke billeder, men taldata for anslåede fikseringspunkter for dit blik på skærmen undervejs i spørgeundersøgelsen.",
  "app.faq.question4": "Sp: Hvilke data bliver indsamlet ved spørgeundersøgelsen?",
  "app.faq.answer4.line1": "Sv: Softwaren indsamler nogle eller alle af følgende 5 typer af data:",
  "app.faq.answer4.line2": "\"Brugernavn\" – Brugernavnet anvendes til at identificere respondenter (til at uddele belønninger for deltagelse)",
  "app.faq.answer4.line3": "\"Fikseringsdata\" – Vurderinger af, hvor brugeren kigger på sin skærm, når der bliver vist stimuli",
  "app.faq.answer4.line4": "\"Spørgeundersøgelsesdata\" – Brugerens svar på spørgsmålene",
  "app.faq.answer4.line5": "\"Metadata\" – Data om de tidsrum, hvor den enkelte bruger har deltaget i spørgeundersøgelsen.",
  "app.faq.answer4.line6": "\"Data om fysisk enhed\" – Data om den computer, som en bruger anvender til at deltage, f.eks. RAM, maskinens CPU-type og brugeragent (operativsystem og browserversion) samt information om computerens skærm (størrelse og opløsning), webcam (mærke og opløsning) og antivirusprogrammer.",
  "app.faq.question5": "Sp: Hvad bliver de indsamlede data anvendt til?",
  "app.faq.answer5.line1": "Sv: De indsamlede data bliver anvendt til at analysere, hvordan den enkelte bruger kigger på og interagerer med forskellige typer af billeder og visuelle medier, og til at måle deltagernes mening om de billeder, de får vist.",
  "app.faq.answer5.line2": "Lumen kan vælge at videregive data fra spørgeundersøgelsen til tilknyttede virksomheder og til forretningspartnere og erhvervskunder. Ved videregivelse af spørgeundersøgelsens data til tilknyttede virksomheder, forretningspartnere og erhvervskunder sker det typisk på anonym basis. Disse tilknyttede virksomheder, forretningspartnere og erhvervskunder vil eventuelt bruge disse data til at udarbejde sammenfattende beskrivelser af et bredt spektrum af forbrugermønstre og -segmenter til at udvikle produkter og til at levere markedsføringsløsninger til deres kunder eller forbrugere.",
  "app.faq.answer5.line3": "Lumen indsamler \"data om fysiske enheder\" og \"metadata\" som hjælp til at styre indsamlingen og behandlingen af data, herunder til kvalitetskontrol og omfangsstyring.",
  "app.faq.answer5.line4": "De indsamlede data bliver opbevaret, så længe det er nødvendigt for at opfylde de beskrevne formål.",
  "app.faq.question6": "Sp: Hvordan beskyttes mine data?",
  "app.faq.answer6": "Sv: De data, som Lumen indsamler, krypteres med SSL-teknologi. Det er den hyppigst anvendte høje krypteringsstandard, som anvendes af banker til at beskytte banktransaktioner og anden følsom kommunikation. Den anvendes også af Amazon, PayPal, eBay og andre websteder, som har brug for sikkerhedssystemer på samme høje niveau som banker. Forskere vurderer, at det vil tage betydeligt længere end universets alder at bryde 256-bit kryptering ved hjælp af nuværende teknologi. Indsamlede data opbevares på sikre webservere, som styres af Amazon Web Services (AWS). AWS er en af de hyppigst anvendte, beskyttede og driftsikre platforme til cloud-baserede tjenester, og den anvendes af store kommercielle, cloud-baserede tjenester som f.eks. Netflix og Expedia.",
  "app.faq.question7": "Sp: Hvad er dine forpligtelser med hensyn til det indhold, du får vist?",
  "app.faq.answer7": "Sv: Ved at deltage er du desuden bekendt med og accepterer, at det indhold, du får vist i spørgeundersøgelsen, kan være fortroligt, tilhørende en tredjepart og/eller med forbehold af varemærke- eller ophavsretslovgivning, og at du ikke tager skærmbilleder, optager eller på anden vis kopierer indholdet i spørgeundersøgelsen eller videredistribuerer det på nogen måde.",
  "app.faq.question8": "Sp: Jeg ved ikke, hvor godt mit webcam er, og om det fungerer til eye-tracking?",
  "app.faq.answer8": "Sv: Softwaren kan fungere med mange forskellige webcams, så det bør ikke være noget problem.",
  "app.faq.question9": "Sp: Jeg har gennemført spørgeundersøgelsen; er der andet, jeg skal gøre?",
  "app.faq.answer9": "Sv: Nej, der er ikke andet, du skal gøre. Softwaren nedlukker automatisk.",
  "app.faq.question10": "Sp: Skal jeg have internetforbindelse for at deltage?",
  "app.faq.answer10": "Sv: Ja. Du skal have internetforbindelse for at downloade programmet og gennemføre spørgeundersøgelsen.",
  "app.faq.question11": "Sp: Kræver det et stabilt underlag til min enhed?",
  "app.faq.answer11": "Sv: Ja. Du får det bedste resultat ved at sidde i en behagelig stilling foran din computerskærm. Efter kalibreringen skal du holde hovedet stille, mens du får vist de forskellige stimuli på skærmen, og indtil du får besked om andet. Vi fraråder at deltage, hvis du kører i tog eller sidder med din bærbare computer i skødet mv.",
  "app.faq.question12": "Sp: Jeg kan ikke finde svaret i disse spørgsmål og svar. Hvad gør jeg?",
  "app.faq.answer12": "Sv: Send en e-mail til os på study-feedback@lumen-research.com, så vender vi tilbage med et svar så hurtigt som muligt.",
  "app.faq.patent": "Lumens testning af webindhold er baseret på vores egen patentbeskyttede teknologi til situationsregistrering af øjenbevægelser (britisk patentansøgning nr. 1816158.8)",
  "app.terms.line1": "Disse betingelser for anvendelse (\"Betingelser for anvendelse\") gælder for aftalen mellem dig og Lumen Research Ltd (\"Lumen\" eller \"vi\"), som er en virksomhed registreret i England med selskabsnummer 8682432 og hjemsted på adressen Unit 215, 22 Highbury Grove, London N5 2EF og med databeskyttelsesregistreringsnummer ZA130772, vedrørende din anvendelse af \"ViewPoints\" eye-trackingsoftwaren (\"softwaren\") på din enhed.",
  "app.terms.line2": "1. Accept af vilkårene",
  "app.terms.line3": "Ved at bruge softwaren accepterer du at være bundet af denne aftale. Hvis du ikke accepterer vilkårene og betingelserne for denne aftale, må du ikke deltage i ViewPoints-spørgeundersøgelsen.",
  "app.terms.line4": "2. Fortrolig information",
  "app.terms.line5": "Ved al installation eller brug af eller interaktion med ViewPoints vil du muligvis få adgang til information og/eller indhold, som tilhører eller vedrører Lumen, herunder information, der identificeres eller behandles fortroligt af Lumen eller af Lumens erhvervsklienter eller kunder, eller som på grund af dens karakter eller de omstændigheder eller den måde, hvorpå den udleveres, åbenlyst er fortrolig, herunder (uden at berøre førnævnte generelt gældende) information om forretningsplaner, modning af nye forretningsmuligheder, forsknings- og udviklingsprojekter, design, fortrolige processer, koder, softwaredesign, forretningshemmeligheder, udvikling af produkter eller serviceydelser/tjenester samt formler, viden, opfindelser, salgsstatistik og -prognoser, markedsføringsstrategier og -planer, omkostninger, overskud og tab af anden økonomisk information (bortset fra som offentliggjort i reviderede regnskaber), priser og rabatstrukturer (hvad enten på skrift eller i elektronisk eller andet format eller på anden vis) (\"fortrolig information\"). Sådan fortrolig information tilhører ene og alene Lumen og/eller virksomhedens kunder, og du må under ingen omstændigheder indhente eller tildeles ret eller adkomst til og/eller interesse i sådan fortrolig information.",
  "app.terms.line6": "Ved din deltagelse i ViewPoints eller på noget andet tidspunkt (uden begrænsning) efter ophør af sådan deltagelse må du heller ikke: Afsløre eller fortælle andre om, anvende til dine egne formål eller ved manglende overholdelse af behørig omhu forårsage uvedkommende videregivelse af fortrolig information. Du skal til enhver tid gøre dit bedste for at hindre offentliggørelse eller videregivelse af fortrolig information. Disse betingelser vil ikke længere gælde information, som bliver offentligt tilgængelig generelt, bortset fra i tilfælde af din forsømmelse.",
  "app.terms.line7": "3. Lumens ejendom",
  "app.terms.line8": "ViewPoints-softwaren og alle data, koder, nøgler, adgangskoder, design, tegninger, registrerede oplysninger og andre dokumenter eller materialer i relation til softwaren tilhører Lumen under hele forløbet, hvor du deltager i ViewPoints-spørgeundersøgelse. Lumen ejer alle intellektuelle rettigheder eller ejendomsrettigheder til alle nuværende og fremtidige intellektuelle rettigheder eller enerettigheder til alle former for dokumenter, materialer, modeller, design, tegninger, processer, opfindelser, formler, computerkodning, data, systemgrænseflader, metoder, viden, fortrolig information eller andre værker udført, fremstillet, oprettet, udarbejdet, udviklet, forbedret, dekonstrueret eller udledt af dig enten alene eller sammen med andre i forbindelse med eller i relation til softwaren og/eller enheden, som kan anvendes eller tilpasses til anvendelse heri eller i forbindelse hermed (uanset om det er registreret eller ej), herunder alle nuværende og fremtidige patenter, ophavsrettigheder, designrettigheder, databaserettigheder, varemærker, rettigheder til halvledertopografi, rettigheder til plantesorter, internetrettigheder/-domænenavne, viden og al anvendelse af ovennævnte og alle rettigheder, der gælder for ovennævnte (\"ejendomsrettigheder\"). Der overgår under ingen omstændigheder ejendomsrettigheder til dig.",
  "app.terms.line9": "4. Vilkår og begrænsninger for anvendelse",
  "app.terms.line10": "ViewPoints-softwaren gives i licens til slutbrugere. I henhold til denne aftale accepterer vi at give dig en ikke-eksklusiv, genkaldelig, ikke-overdragelig, ikke-underlicenserbar, begrænset ret til at bruge ViewPoints-softwaren på en enhed, som du ejer eller har kontrol over, eller en pc, til hvilken du erklærer og garanterer, at du har alle nødvendige rettigheder og tilladelser til sådan installation. For at undgå tvivl må du ikke bruge ViewPoints til andre end denne softwares formål og ikke oversætte, kopiere, gengive, sende via telekommunikation, tilpasse, flette eller ændre softwaren på nogen måde.",
  "app.terms.line11": "Du accepterer, at du ikke må: (i) kopiere, ændre, tilpasse, tilgængeliggøre, udleje, lease, overføre som timeshare, udarbejde afledte værker af eller forsøge at sælge eller på anden vis overføre rettigheder til softwaren (ii) disassemble, dekompilere eller reverse engineer softwaren på nogen måde, og heller ikke tillade eller bistå andre med udlede eller forsøge at udlede kildekoden til, disassemble, dekryptere, dekompilere eller reverse engineer softwaren, og heller ikke tage skridt til at udlede designinformation vedrørende softwaren (iii) anvende softwaren til andre formål end dem, som du oprindeligt har fået den udleveret til (iv) anvende spiders, robotter eller andre automatiserede datamining-metoder til at katalogisere, downloade, opbevare eller på anden vis gengive eller distribuere softwaren eller til at manipulere resultaterne af spørgeundersøgelser eller anden aktivitet (v) tage skridt til at gribe ind i softwaren eller en persons brug af softwaren, herunder (uden begrænsning) ved at overbelaste, \"oversvømme\", \"mailbombe\" eller \"crashe\" softwaren og det datalagringssystem, som softwaren anvender igennem Amazon Web Services (vi) sende os ulovligt, vildledende eller skadelig kode, herunder (uden begrænsning) virusser, spyware, adware eller anden skadelig kode.",
  "app.terms.line12": "Alle rettigheder, som du ikke udtrykkeligt tildeles i denne aftale, tilhører os og vores licensgivere. Du accepterer, at vi i henhold til denne aftale eller på anden vis ikke er forpligtet til at rette softwarefejl, mangler eller andre fejl i softwaren eller til på anden vis at yde support til, vedligeholde, forbedre, ændre, opgradere, opdatere eller forbedre softwaren. Vi påtager os intet ansvar for at bistå dig eller dem, du udpeger, ved anvendelse af softwaren på nogen måde.",
  "app.terms.line13": "Vi påtager os intet erstatningsansvar for eventuel beskadigelse af din pc, herunder (men ikke begrænset til) som et resultat af din anvendelse af softwaren. Vi påtager os intet ansvar for eventuelle ekstra dataomkostninger, du måtte pådrage dig som et resultat af de data, som indsamles af softwaren i forbindelse med din deltagelse i spørgeundersøgelsen.",
  "app.terms.line14": "5. Ansvarsfraskrivelse",
  "app.terms.line15": "Vi og vores respektive bestyrelsesmedlemmer, direktører, medarbejdere, agenter, repræsentanter, licensgivere og udbydere fremsætter ingen tilsikringer eller garanti eller forudsætning for, (a) at softwaren vil være tilgængelig rettidigt, eller at adgang til softwaren vil være uforstyrret eller sikker, (b) at fejl vil blive rettet, eller (c) at softwaren eller de server eller netværk, via hvilke softwaren og enheden stilles til rådighed, er beskyttet eller uden virusser eller andre skadelige elementer. Vi er ikke forpligtet til at foretage vedligeholdelse og/eller yde support med hensyn til softwaren.",
  "app.terms.line16": "6. Begrænsning af ansvar",
  "app.terms.line17": "(a) I det omfang, det er tilladt i henhold til gældende lov, accepterer du, at vi og vores respektive bestyrelsesmedlemmer, direktører, medarbejdere, agenter, repræsentanter, licensgivere og udbydere ikke påtager os ansvar for tab, skader eller omkostninger af nogen art på grund af din installation eller brug af eller adgang til softwaren, din anvendelse, eller manglende mulighed for anvendelse, af softwaren, information eller materialer, der stilles til rådighed i eller igennem softwaren, og/eller på anden vis er relateret til softwaren eller på anden vis til denne aftale, uanset karakteren af den juridiske, rimelige eller lovbestemte ret, der hævdes krænket.",
  "app.terms.line18": "(b) I det omfang, det er tilladt i henhold til gældende lov, er dit eneste retsmiddel i tilfælde af utilfredshed med softwaren at ophøre med at bruge den.",
  "app.terms.line19": "(c) Vores enheder, bestyrelsesmedlemmer, direktører, medarbejdere, agenter og repræsentanter påtager sig under ingen omstændigheder ansvar over for dig for afledte, indirekte, resulterende, lovbestemte, pønale eller dokumenterede erstatningskrav, indtjeningstab, tab af forretningsmuligheder, tab af omdømme, tab af anvendelsesmulighed, datatab, tab af andet af immateriel karakter eller uretmæssig opsnapning af data, som indsamles af softwaren, foretaget af tredjeparter, uanset om dette måtte være påregneligt eller ej, uanset hvad det skyldes, og hverken i henhold til kontrakt eller uden for kontrakt (herunder (uden begrænsning)), objektivt ansvar eller andre principper, heller ikke selvom der forinden underrettes om risikoen for sådanne erstatningskrav.",
  "demo.index.content.line1": "Ved denne demonstration bruger vi dit webcam til at vurdere, hvad du ser på skærmen. Og bare rolig, vi tager hverken billeder eller optager video.",
  "app.index.webcamInstructions": "Vi er ved at bede om adgang til dit webcam. Hvis der dukker et vindue op, klik venligst på allow/tillad",
  "app.thankyou": "Tak",
  "app.button.close": "Luk ned",
  "app.button.return": "Gå tilbage",
  "app.init.welcome": "Velkommen til Lumen Research's undersøgelsesplatform",
  "app.init.intro": "Undersøgelsesplatformen giver os mulighed for at opstarte og registrere brugere tilknyttet bestemte reklameundersøgelser.",
  "app.init.message1": "Afhængigt af undersøgelsens art, vil en bruger blive guidet gennem en række opgaver.",
  "app.init.message2": "Disse opgaver inkluderer; gennemførelse af en spørgeskema, læsning af en række instruktioner samt udførelse af kalibrering til øjen sporing",
  "app.init.message3": "Vi vil nu guide dig gennem en demo undersøgelse.",
  "app.init.message4": "Under udførelsen af undersøgelsen er det muligt at vi slår din webcam til for at udføre øjen sporing, men ingen af dine data vil blive optaget.",
  "app.init.message5": "Det nuværende undersøgelse er sat op til at benytte følgende links ved screenout/complete.",
  "app.index.zoomRetry": "Vi kræver at browseren er indstillet på 100% zoom, zoom venligst ind/ud og prøv igen",
  "app.index.fullscreendesktopretry": "Denne undersøgelse kræver fuldskærmsvisning, tjek venligst efter om browser vinduet er maksimeret",
  "app.index.fullscreendesktoptest": "Denne undersøgelse kræver at dit browservindue vises ved fuld skærm, vi vil udøve en test for at forsikre os at dit browservindue er maksimeret",
  "app.index.fullscreendesktoptest2": "Kort efter vil der dukke to knapper frem, klik venligst på dem for at fortsætte",
  "app.index.fullscreendesktopmaximised": "Mit skærm er allerede maksimeret",
  "app.index.fullscreendesktopskip": "Tak, vi har optaget dette forløb og vil forbedre vores registrerings processer.",
  "app.index.fullscreendesktopreset": "Vi har registreret en ændring i vinduets størrelse, testen nulstilles.",
  "app.index.gotomobile": "Desktop brugere er ikke egnede til denne undersøgelse, anvend venligst QR koden nedenunder for at åbne undersøgelsen på en mobiltelefon.",
  "app.index.returntopanel": "ELLER, klik på knappen nedenunder for at vende tilbage til panelet",
  "app.setup.instruction0d": "Rotér venligst dit udstyr til landskab visning.",
  "app.setup.instructioncamera": "Klik venligst på knappen nedenunder for at anmode om adgang til kameraet. For at gennemføre undersøgelsen er det vigtigt at du giver tilladelse til brug af kameraet under hele undersøgelsen.",
  "app.setup.cameraText": "Anmod om adgang",
  "app.setup.instruction3audio": "Lyt venligst opmærksomt og følg instruktionerne på skærmen.",
  "app.setup.retryPositioning": "Vi kunne ikke registrere dit hoveds positionering. Hold venligst hovedet samt udstyret stille og dæk venligst ikke over kameraet med eksempelvis dine fingre. Klik på knappen nedenunder for at prøve igen.",
  "app.setup.retryBroker": "Vi kunne ikke gennemføre kalibreringsforløbet. Prøv venligst at lukke ned for applikationer og faner der ikke er i brug for at frigøre ressourcer",
  "app.error.cellquotafullnoactivecell": "Aktive celler kunne ikke findes til undersøgelsen. Celle kvoten er fyldt.",
  "app.error.missingpanelparameter": "Mangler panel parameter.",
  "app.completion.congratulations-noet": "Tillykke! Du har gennemført undersøgelsen.",
  "app.error.previousfail": "Du har tidligere fejlet i denne undersøgelse, brug venligst det originale link for at starte forfra.",
  "app.error.previoussuccess": "Du har tidligere gennemført denne undersøgelse.",
  "app.error.route": "Du har forsøgt at starte en igangværende undersøgelse, brug venligt det originale link for at starte forfra.",
  "app.error.accesscode": "Du har forsøgt at starte en undersøgelse der ikke eksisterer, kontroller venligst dit link.",
  "app.error.webcam": "Denne undersøgelse kræver adgang til et webcam",
  "app.error.adblocker": "Denne undersøgelse kræver at adblock slås fra.",
  "app.error.startsession": "Kan ikke starte sessionen",
  "app.error.fullscreen": "Denne undersøgelse kræver fuldskærmsvisning.",
  "app.error.zoom": "Denne undersøgelse kræver at browser zoom indstilles til 100%.",
  "app.setup.retryBack": "Undgå venligst at klikke på tilbage knappen for browseren under hele undersøgelsen, det kan medføre tab af gevinster. Anvend knappen nedenunder for at prøve igen.",
  "app.setup.retryaudible": "Kontroller venligst at dit udstyr ikke er på lydløs og prøv igen.",
  "app.restore.retry": "Du har ikke anvendt rimelig tid i det forrige trin, ",
  "app.restore.timeremaining": " minutter tilbage",
  "app.error.postexposure": "Beklager, du har nu opbrugt samtlige forsøg og kan desværre ikke fortsætte med undersøgelsen.",
  "homepage.recommended": "Anbefales",
  "homepage.prompt": "Du skal vælge en video blandt ovenstående og se den, før du kan gå videre.",
  "homepage.next": "Næste",
  "you-cannot-take-part": "Du kan ikke deltage",
  "inactivity-check-message": "Vi har ikke registreret aktivitet i de seneste {} sekunder. For at gennemføre denne spørgeundersøgelse, og undgå at miste din belønning, skal du vælge en video og se den.",
  "not-selecting-check-message": "Du skal vælge en af videoerne blandt de mulige på skærmen. Du skal se en video for at gå videre til næste trin i denne spørgeundersøgelse."
}
