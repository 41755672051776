import config from "../config";
import PlatformError from "../PlatformError";

export default new PlatformError(
  "ERROR",
  "Thank you for your interest. This study is now closed to your demographic.",
  "Screenout",
  "External:Screenout",
  "app.error.demoquotafullnoactivecell"
);
