import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { IntlProviderWrapper } from "../translations/controller";
import { history } from "@history/";
import HeaderFooter from "../layouts/HeaderFooter";
import { Route, Routes } from "react-router-dom";
import { CustomRouter as Router } from "../components/router";
import BasePage from "../layouts/BasePage";
import FlexStart from "../layouts/FlexStart";
import { FormattedMessage } from "react-intl";
import StyleController from "./styleController";

import "../themes/currentTheme";
import "./default.css";

const InitContent = lazy(() => import("../components/pages/01_InitContent"));
const PrestartContent = lazy(() =>
  import("../components/pages/02_PrestartContent")
);
const StartContent = lazy(() => import("../components/pages/03_StartContent"));
const DemographicsContent = lazy(() =>
  import("../components/pages/04_DemographicsContent")
);
const InstructionsContent = lazy(() =>
  import("../components/pages/05_InstructionsContent")
);
const SetupContent = lazy(() => import("../components/pages/06_SetupContent"));
const StudyContent = lazy(() => import("../components/pages/StudyContent"));
const CompletionContent = lazy(() =>
  import("../components/pages/CompletionContent")
);
const ErrorContent = lazy(() => import("../components/pages/ErrorContent"));
const InvalidContent = lazy(() => import("../components/pages/InvalidContent"));
const PreviewContent = lazy(() => import("../components/pages/PreviewContent"));
const RestoreContent = lazy(() => import("../components/pages/RestoreContent"));
const LandingContent = lazy(() => import("../components/pages/LandingContent"));

var WebFont = require("webfontloader");

WebFont.load({
  google: {
    families: ["Source Sans Pro:400,600,700,900"],
  },
});

StyleController.run(true);
StyleController.setVh();
StyleController.addHeightListener();

const App = () => {
  let { selectedLanguage } = useSelector((state) => state.modalReducer);

  let urlParams = new URLSearchParams(window.location.search);
  let retryCount = parseInt(urlParams.get("retry"));

  document.getElementById("root").className = "lang-" + selectedLanguage;

  return (
    <IntlProviderWrapper selectedLanguage={selectedLanguage}>
      <Router history={history} basename="/study">
        <Suspense
          fallback={
            <HeaderFooter>
            </HeaderFooter>
          }
        >
          <Routes>
            <Route
              path="/"
              element={
                <HeaderFooter>
                  <BasePage>
                    <FlexStart>
                      <p>
                        <FormattedMessage
                          id="app.index.rejection"
                          defaultMessage="Sorry, it appears you have followed a malformed link."
                          description="Start page malformed link"
                        />
                      </p>
                    </FlexStart>
                  </BasePage>
                </HeaderFooter>
              }
            />
            <Route
              path="/:studyId/:accessCode/"
              key="d"
              element={
                <HeaderFooter>
                  <InitContent />
                </HeaderFooter>
              }
            />
            <Route
              path="/:studyId/prestart"
              key="prestart"
              element={
                <HeaderFooter>
                  <PrestartContent />
                </HeaderFooter>
              }
            />
            <Route
              path="/:studyId/start/"
              key="start"
              element={
                <HeaderFooter>
                  <StartContent />
                </HeaderFooter>
              }
            />
            <Route
              path="/:studyId/survey/"
              key="demographics"
              element={
                <HeaderFooter>
                  <DemographicsContent />
                </HeaderFooter>
              }
            />
            <Route
              path="/:studyId/instructions/"
              key="instructions"
              element={
                <HeaderFooter>
                  <InstructionsContent />
                </HeaderFooter>
              }
            />
            <Route
              path="/:studyId/setup/"
              key="setup"
              element={
                <HeaderFooter>
                  <SetupContent retryCount={retryCount} />
                </HeaderFooter>
              }
            />
            <Route
              path="/:studyId/completion/"
              key="completion"
              element={
                <HeaderFooter>
                  <CompletionContent />
                </HeaderFooter>
              }
            />
            <Route
              path="/:studyId/error/"
              key="error"
              element={
                <HeaderFooter>
                  <ErrorContent />
                </HeaderFooter>
              }
            />
            <Route
              path="/:studyId/invalidation/"
              key="invalidation"
              element={
                <HeaderFooter>
                  <InvalidContent />
                </HeaderFooter>
              }
            />
            <Route
              path="/:studyId/landing/"
              key="landing"
              element={
                <HeaderFooter>
                  <LandingContent />
                </HeaderFooter>
              }
            />
            <Route
              path="/:studyId/restore/:shortName"
              key="restore"
              element={
                <HeaderFooter>
                  <RestoreContent />
                </HeaderFooter>
              }
            />
            <Route
              path="/:studyId/:cellId/:stepId/"
              key="study"
              element={
                <HeaderFooter>
                  <StudyContent />
                </HeaderFooter>
              }
            />
            <Route
              path="/preview/:studyId/:cellId/:stepId/"
              key="preview"
              element={
                <HeaderFooter>
                  <PreviewContent />
                </HeaderFooter>
              }
            />
          </Routes>
        </Suspense>
      </Router>
    </IntlProviderWrapper>
  );
};

export default App;
