import * as DEBUGLOG from "../../../components/util/debugLog";
import requestTimer from "../../requestTimer";
import tabChangeListener from "../../../utils/listeners/TabChangeListener";
import participationUrl from "../participationUrl";

const complete = async (studyId, body) => {
  tabChangeListener.setWaitingForApi("complete");
  let completeRequestDate = DEBUGLOG.debugLogRequestStart("complete");
  let response = await fetch(
    participationUrl() + "/participation/studies/" + studyId + "/complete",
    {
      method: "POST",
      body: JSON.stringify(body),
    }
  );

  DEBUGLOG.debugLogRequestFinish(completeRequestDate);

  if (response.ok) {
    let responseBody = await response.json();
    tabChangeListener.setWaitingForApi("");
    return responseBody;
  } else {
    let e = new Error(response.statusText);
    e.name = response.status || "";
    throw e;
  }
};

export default (...args) => requestTimer(() => complete(...args));
