import React from 'react';
import './polyfill.js'
import './index.css';
import {Provider} from "react-redux";
import {persistor, store} from "./store/index";
import {PersistGate} from 'redux-persist/lib/integration/react';
import { onBeforeLift } from '@components/util/preloadController';
import LSLoading from './components/util/loading';
//import * as serviceWorker from './serviceWorker';
import LumenEyeTracking from "./components/lumenTracker/";
import App from './app/App.js';
import { datadogRum } from '@datadog/browser-rum';
import LoggerController from './controllers/Logger/LoggerController.js';
import ErrorController from './controllers/Error/ErrorController.js';
import DeviceController from './utils/controllers/DeviceController.js';
import { createRoot } from 'react-dom/client';

if (process.env.REACT_APP_LUMEN_ENV_VALUE !== 'release') {
  window.store = store;
}

if (process.env.REACT_APP_LUMEN_ENV_VALUE === 'release') {
  // TODO: needs better security
  window.LSSTORE = (pw) => {
    if (btoa(pw) === "bHNfc3RvcmU=") {
      return store
    } else {
      return null
    }
  }

  datadogRum.init({
      applicationId: 'cd95b5e3-855a-46d8-9f9a-0b41284777b7',
      clientToken: 'pub8ab631d89216a8c888b8fb8807b88201',
      site: 'datadoghq.eu',
      service:'lrsp',
      env:process.env.REACT_APP_LUMEN_ENV_VALUE,
      sampleRate: 20,
      sessionReplaySampleRate: 20,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel:'mask-user-input',
  });
      
  datadogRum.startSessionReplayRecording();
} else if (process.env.REACT_APP_LUMEN_ENV_VALUE === 'alpha') {
  datadogRum.init({
    applicationId: 'cd95b5e3-855a-46d8-9f9a-0b41284777b7',
    clientToken: 'pub8ab631d89216a8c888b8fb8807b88201',
    site: 'datadoghq.eu',
    service:'lrsp',
    env:process.env.REACT_APP_LUMEN_ENV_VALUE,
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input'
});
}

window.LumenEyeTracking = LumenEyeTracking;

window.addEventListener('beforeunload', function(event) {
  LoggerController.panelistAction("LS:PageUnload");
});

ErrorController.initTracker()

DeviceController.injectStore(store);

const root = createRoot(document.getElementById('root'))

/**
 * Highest level of the app. It is here where it is rendered to the DOM.
 *
 */
root.render(
<Provider store={store}>
  <PersistGate loading={<LSLoading />} persistor={persistor} onBeforeLift={onBeforeLift}>
    <App></App>
  </PersistGate>
</Provider>);

// Register the service worker
/*
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('service-worker.js').then(function(registration) {
    console.log('ServiceWorker registration successful with scope: ', registration.scope);
  }).catch(function(err) {
    console.log('ServiceWorker registration failed: ', err);
  });
}
*/
