{
    "app.thankyou":"Takk skal du ha",
    "app.button.faqs": "Vanlige spørsmål",
    "app.button.terms": "Vilkår for bruk",
    "app.button.privacy": "Retningslinjer for personvern",
    "app.button.feedback": "Tilbakemelding",
    "app.button.selectLanguage": "Velg språk",
    "app.button.help": "Hjelp",
    "app.button.start": "Start",
    "app.button.next": "Neste",
    "app.button.submit": "Send inn",
    "app.button.takepart": "OK – jeg vil delta",
    "app.menu.feedback.content": "Hvis du har støtt på et problem med studien eller bare vil legge igjen en kommentar, kan du gjøre det her.",
    "app.contactModal.feedback": "Takk for tilbakemeldingen!",
    "app.takePartModal.line1": "Hvis du avslutter nå, kommer du ikke til å kvalifisere for en deltakerbelønning.",
    "app.takePartModal.line2": "Alt du trenger er et webkamera!",
    "app.takePartModal.reason1": "Jeg har ikke et webkamera.",
    "app.takePartModal.reason2": "Jeg føler meg ikke tilpass med at webkameraet mitt skal brukes i denne undersøkelsen.",
    "app.takePartModal.reason3": "Jeg har ikke tid til dette nå.",
    "app.takePartModal.reason4": "Annen grunn.",
    "app.generic.redirect": "Du vil bli omdirigert snart.",
    "app.prestart.loading": "Laster inn – vent litt.",
    "app.index.title": "Velkommen",
    "app.index.content.line1": "Under denne undersøkelsen bruker vi kameraet ditt til å estimere hvor du retter blikket. Ta det med ro, vi kommer ikke til å ta bilder eller opptak.",
    "app.index.content.line1alt": "Påse at du sitter komfortabelt, og trykk på Start for å begynne.",
    "app.index.content.line2": "Har enheten din et kamera? Trykk i så fall på startknappen nedenfor.",
    "app.index.rejection": "Beklager, det ser ut til at du har fulgt en misdannet link.",
    "app.index.webview": "Beklager, det ser ut til at du har åpnet siden vår inne i en webvisning. Du må åpne denne linken i nettleseren din. Dette kan være mulig å gjøre direkte fra webvisningsmenyen.",
    "app.index.webview.chrome.1": "Trykk på menyknappen ovenfor og velg “Åpne i Chrome”.",
    "app.index.webview.chrome.2": "Da åpnes undersøkelsen i en nettleserapp, slik at den fungerer som den skal.",
    "app.index.webview.android.1": "Trykk på Start nedenfor for å åpne denne undersøkelsen i en nettleserapp, slik at den fungerer som den skal.",
    "app.index.webview.android.2": "Hvis dette mislykkes, åpner du linken til denne siden i en nettleserapp manuelt (dvs. kopier og lim inn linken i adressefeltet i Chrome, eller bruk et alternativ som “Åpne i Chrome” i menylinjen ovenfor).",
    "app.index.webview.safari.1": "Trykk på Safari-ikonet nederst på skjermen for å fortsette.",
    "app.index.webview.safari.2": "Det ser slik ut:",
    "app.index.webview.safari.3": "Dette åpner undersøkelsen i Safari, slik at den fungerer som den skal.",
    "app.index.wifi": "Det ser ut til at internettforbindelsen din er for langsom. Er du tilkoblet Wi-Fi?",
    "app.index.tellmemore": "Fortell meg mer.",
    "app.index.takepart": "Jeg vil ikke delta.",
    "app.instructions.title": "Instruksjoner",
    "app.instructions.instruction1": "Hvis det lar seg gjøre, er det fint om du lar være å bruke briller under undersøkelsen.",
    "app.instructions.instruction2": "Sitt slik at du kan holde hodet og enheten i ro uten problemer.",
    "app.instructions.instruction3": "Beveg øynene, ikke hodet.",
    "app.instructions.instruction4": "Er du klar til å begynne?",
    "app.instructions.instruction5": "Bruk Wi-Fi for å unngå datakostnader.",
    "app.instructions.instruction6": "Les følgende instruksjoner nøye.",
    "app.instructions.instruction7": "Plasser telefonen på en stabil overflate.",
    "app.instructions.instruction8": "Pass på at rommet er godt opplyst – helst ovenfra (ikke bakfra).",
    "app.setup.title": "Oppsett",
    "app.setup.instruction0": "Hold enheten din i portrettmodus.",
    "app.setup.instruction0a": "Roter enheten din til portrettmodus.",
    "app.setup.instruction0b": "Roter enheten din slik at kameraet er på venstre side.",
    "app.setup.instruction0c": "Roter enheten din slik at kameraet er på høyre side.",
    "app.setup.instruction1": "Initialiserer ...",
    "app.setup.instruction2": "Når du blir oppdaget, plasserer du hodet inne i rammen.",
    "app.setup.instruction3": "Hold hodet og enheten slik inntil undersøkelsen er fullført.",
    "app.setup.instruction4": "Nå må vi bare validere.",
    "app.setup.instruction5": "Takk skal du ha! Husk å holde hodet og enheten i samme stilling inntil undersøkelsen er fullført.",
    "app.setup.instruction6": "Sveip opp for å skjule adresselinjen.",
    "app.setup.retry": "Feil påvist. Hold hodet og enheten i ro og ikke dekk til kameraet med f.eks. fingrene. Bruk knappen nedenfor for å prøve på nytt.",
    "app.setup.retryInit": "Feil påvist. Påse at nettleseren har tilgang til kameraet ditt. Hvis du har avvist kameratilgang tidligere, må du nå godta dette i nettleserens personverninnstillinger. Bruk knappen nedenfor for å prøve på nytt.",
    "app.setup.retryButton": "Prøv på nytt",
    "app.setup.calibrationProp.watch": "Følg prikken når den vises på skjermen",
    "app.setup.calibrationProp.watch.george": "Så, når du ser et ansikt, holder du blikket på det mens du speiler hodebevegelsene",
    "app.setup.calibrationProp.click": "Klikk hvor som helst for å fortsette",
    "app.setup.calibrationProp.tap": "Trykk hvor som helst for å fortsette",
    "app.study.validationProp.watch": "Følg prikken når den vises på skjermen",
    "app.study.validationProp.restart": "Prøv på nytt",
    "app.study.validationProp.click": "Klikk hvor som helst for å fortsette",
    "app.study.validationProp.tap": "Trykk hvor som helst for å fortsette",
    "app.study.render.error": "Domenet er ikke gyldig for gjengivelsestrinnet.",
    "app.error.title": "Feil",
    "app.error.generic": "Oi da! Noe gikk galt.",
    "app.error.initialise": "Kan ikke initialisere!",
    "app.error.findCamera": "Finner ikke kameraet!",
    "app.error.headPosition": "Kan ikke fastslå hodeposisjonen din.",
    "app.error.calibrate": "Kan ikke kalibrere.",
    "app.error.validate": "Kan ikke validere.",
    "app.error.start": "Kunne ikke starte.",
    "app.error.assign": "Kunne ikke tilordne.",
    "app.error.osnotallowed": "Operativsystemet ditt er ikke tillatt. Sjekk hvilken enhet du kan bruke til denne undersøkelsen, og prøv på nytt.",
    "app.error.demoquotafullnoactivecell": "Takk for interessen. Denne studien er nå avsluttet for din demografiske gruppe.",
    "app.invalid.title": "Ugyldig",
    "app.invalid.generic": "Beklager, du kvalifiserer ikke for denne studien.",
    "app.completion.title": "Fullføring",
    "app.completion.congratulations": "Gratulerer! Du har fullført studien. Denne undersøkelsens tilgang til kameraet ditt blir nå avsluttet.",
    "app.questions.required": "Dette spørsmålet er obligatorisk.",
    "app.faq.question1": "Spm.: Hva er Lumen Study Platform, og hva innebærer det å ta del i en øyesporingsstudie?",
    "app.faq.answer1": "Sv.: Denne undersøkelsen innebærer bruk av teknologi for øyesporing. Du vil bli bedt om å sitte foran webkameraet eller det forovervendte kameraet på enheten din og utføre en kalibrering, slik at det er mulig å beregne hvor på skjermen du ser. I løpet av undersøkelsen vil du bli bedt om å se på diverse elementer (f.eks. bilder, nettsteder eller videoer) mens undersøkelsesverktøyet beregner hvor blikket ditt er. Dataene som samles inn fra denne “øyesporingen”, er koordinatene for estimerte blikkpunkter på skjermen din over tid. Programvaren bruker bilder av deg fra webkameraet ditt for å foreta disse beregningene, men tar ikke bilder eller opptak av deg. Du kan også få noen spørsmål om deg selv, din erfaring og elementene du har sett under undersøkelsen. Dataene sendes til Lumen Research Ltd. Lumen Research analyserer dataene og deler resultatene med klientene sine.",
    "app.faq.question2": "Spm.: Hvorfor krever programvaren et webkamera/kamera, og hvordan brukes det?",
    "app.faq.answer2": "Sv.: Programvaren bruker bilder fra webkameraet eller kameraet på enheten din for å beregne hvor du ser på skjermen. Dette gjøres med en kombinasjon av ansiktssporings- og øyesporingsprogramvare som analyserer bilder lokalt på maskinen din i sanntid. Disse bildene behandles og vises til deg under kalibrering slik at du kan posisjonere deg riktig, men bildene spilles ikke inn, så personvernet ditt beskyttes. Dataene som spilles inn og samles inn fra øyesporingen, er ikke bilder, men numeriske data om estimerte blikkpunkter under stimulusen over tid.",
    "app.faq.question3": "Spm.: Tar dere bilder av meg under øyesporingen?",
    "app.faq.answer3": "Sv.: Den eneste gangen bilder brukes, er når du skal plassere deg riktig under kalibrering. Disse bildene blir ikke lagret, og kan kun ses av deg. Dataene som spilles inn og samles inn fra øyesporingen, er ikke bilder, men numeriske data om estimerte blikkpunkter under stimulusen over tid.",
    "app.faq.question4": "Spm.: Hvilke data samles inn under undersøkelsen?",
    "app.faq.answer4.line1": "Sv.: Programvaren samler inn noen av eller alle følgende fem typer av data:",
    "app.faq.answer4.line2": "“Bruker-ID” – bruker-ID-en som brukes til å identifisere respondenter (i den hensikt å administrere deltakerbelønninger);",
    "app.faq.answer4.line3": "“Blikkdata” – beregninger av hvor brukerne ser på skjermen når de presenteres for stimuli på skjermen",
    "app.faq.answer4.line4": "“Undersøkelsesdata” – svar på spørsmål besvart av brukerne",
    "app.faq.answer4.line5": "“Metadata” – data om tidspunktene enkeltpersoner har deltatt i undersøkelsen.",
    "app.faq.answer4.line6": "“Data om fysisk enhet” – data om datamaskinen som en bruker deltar med, slik som nivået av RAM, typen CPU i maskinen og brukeragenten (operativsystemet og nettleserversjonen) samt informasjon om dataskjermen (størrelse og oppløsning), webkamera (modell og oppløsning) og antivirusprogrammer",
    "app.faq.question5": "Spm.: Hvordan blir de innsamlede dataene brukt?",
    "app.faq.answer5.line1": "Sv.: De innsamlede dataene vil bli brukt til å utforske hvordan enkeltpersoner ser på og samhandler med diverse typer visuelle bilder og medier, samt å anslå folks meninger om bildene de får se.",
    "app.faq.answer5.line2": "Lumen kan dele forskningsdataene med tilknyttede selskaper og med forretningspartnere og klienter. Forskningsdataene kan deles med tilknyttede selskaper, partnere og klienter, men dette gjøres vanligvis på anonymt grunnlag. Disse tilknyttede selskapene, partnerne og klientene kan bruke disse dataene til å skape oppsummerende beskrivelser av en lang rekke forbrukeratferdstyper, for å utvikle produkter eller tilby markedsføringsløsninger til kundene eller forbrukerne sine.",
    "app.faq.answer5.line3": "Lumen samler inn “data om fysisk enhet” og “metadata” for å bidra til å administrere innsamlingen og behandlingen av data, inkludert kvalitetskontroll og volumadministrasjon.",
    "app.faq.answer5.line4": "Dataene som samles inn, bevares så lenge det er nødvendig for å oppfylle formålene som er angitt.",
    "app.faq.question6": "Spm.: Hvor sikre er dataene mine?",
    "app.faq.answer6": "Sv.: Dataene som samles inn av Lumen, krypteres med SSL-teknologi. Dette er den mest brukte høye standarden av krypteringsteknologi og brukes av banker for å sikre bankoverføringer og annen svært sensitiv kommunikasjon. Den brukes også av Amazon, PayPal, eBay og andre nettsteder som krever sikkerhetssystemer på banknivå. Forskerne anslår at det å bryte inn i 256-biters kryptering med nåværende teknologi, vil kreve betraktelig mer tid enn universets alder. Innsamlede data lagres på sikre webservere administrert av Amazon Web Services (AWS). AWS er en av de mest brukte trygge og pålitelige skyløsnings-tjenesteplattformene og brukes av store skybaserte kommersielle tjenester som Netflix og Expedia.",
    "app.faq.question7": "Spm.: Hvilke forpliktelser har dere med tanke på innholdet dere får se?",
    "app.faq.answer7": "Sv.: Ved å delta forstår og samtykker du også til at innholdet du får se i denne studien, kan være konfidensielt, tilhøre en tredjepart og/eller være underlagt varemerke- eller opphavsrettslover, og at du ikke kan ta skjermbilder, spille inn eller på annen måte kopiere noe av innholdet eller omdistribuere noe av innholdet i studien på noen måte.",
    "app.faq.question8": "Spm.: Jeg er usikker på hvor bra webkameraet mitt er. Kommer det til å fungere for øyesporing?",
    "app.faq.answer8": "Sv.: Systemene fungerer med en lang rekke webkameraer – det bør ikke være noe problem.",
    "app.faq.question9": "Spm.: Jeg har fullført studien. Må jeg gjøre noe mer?",
    "app.faq.answer9": "Sv.: Nei, det er ikke nødvendig å gjøre noe mer. Programvaren lukkes automatisk.",
    "app.faq.question10": "Spm.: Må jeg være koblet til internett for å delta?",
    "app.faq.answer10": "Sv.: Ja, du trenger en internettforbindelse for å laste ned programmet og fullføre oppgaven.",
    "app.faq.question11": "Spm.: Må jeg utføre oppgaven på en stabil overflate?",
    "app.faq.answer11": "Sv.: Ja, for best resultat bør du sitte komfortabelt foran dataskjermen, og etter kalibreringen må du holde hodet i ro mens du presenteres for stimuli, frem til du får beskjed om noe annet. Vi råder deg ikke til å delta hvis du sitter på et tog, balanserer en bærbar datamaskin på fanget eller lignende.",
    "app.faq.question12": "Spm.: De vanlige spørsmålene ga ikke svar på mitt spørsmål. Hva skal jeg gjøre?",
    "app.faq.answer12": "Sv.: Send oss en e-post på study-feedback@lumen-research.com, så kommer vi tilbake til deg så fort vi kan.",
    "app.faq.patent": "Lumens testing av webinnhold drives av vår egen patenterte kontekstrelevante testteknologi (britisk patentsøknadsnummer 1816158.8)",
    "app.terms.line1": "Disse vilkårene for bruk (“vilkår for bruk”) styrer avtalen mellom deg og Lumen Research Ltd (“Lumen” eller “vi”), et selskap registrert i England, selskapsnummer 8682432 med den registrerte adressen Unit 215, 22 Highbury Grove, London N5 2EF og datavernregistreringsreferanse ZA130772, angående din bruk av programvaren “ViewPoints” for øyesporing (“programvaren) på enheten din.",
    "app.terms.line2": "1. Samtykke til vilkår",
    "app.terms.line3": "Ved å bruke programvaren erkjenner du at du har samtykket til å være underlagt denne avtalen. Hvis du ikke godtar vilkårene i denne avtalen, skal du ikke fylle ut ViewPoints-undersøkelsen.",
    "app.terms.line4": "2. Konfidensiell informasjon",
    "app.terms.line5": "Når du installerer, bruker eller på noen måte samhandler med ViewPoints, kan du få tilgang til informasjon og/eller innhold som tilhører eller vedrører Lumen, inkludert informasjon som identifiseres eller behandles av Lumen eller noen av Lumens klienter eller kunder som konfidensiell, eller som på grunn av arten eller omstendighetene eller måten den avsløres på, tilsynelatende er konfidensiell, inkludert (uten prejudise for det foregående generelt) all informasjon om forretningsplaner, modning av nye forretningsmuligheter, forsknings- og utviklingsprosjekter, design, hemmelige prosesser, koder, programvaredesign, forretningshemmeligheter, produkt- eller tjenesteutvikling og formler, kunnskap, oppfinnelser, salgsstatistikk og prognoser, markedsføringsstrategier og -planer, kostnader, fortjeneste og tap og annen finansiell informasjon (unntatt i den utstrekning som er publisert i reviderte kontoer), priser og rabattstrukturer (hvorvidt eller ei de er registrert skriftlig eller i elektronisk eller annet format) (“konfidensiell informasjon”). Slik konfidensiell informasjon skal forbli den eksklusive eneeiendommen til Lumen og/eller dets kunder, og du skal under ingen omstendigheter oppnå eller motta noen rett, eiendomsrett og/eller interesse i slik konfidensiell informasjon.",
    "app.terms.line6": "Du skal ikke, enten under deltakelsen i ViewPoints eller på noe annet tidspunkt (uten begrensninger) etter opphør av slik deltakelse: avsløre eller kommunisere til noen person; bruke til dine egne formål; eller gjennom manglende forsiktighet og aktsomhet forårsake uautorisert avsløring av konfidensiell informasjon. Du må til enhver tid gjøre ditt beste for å forhindre publisering eller avsløring av konfidensiell informasjon. Disse begrensningene skal slutte å gjelde for all informasjon som blir tilgjengelig for allmennheten generelt på annen måte enn gjennom din forsømmelse.",
    "app.terms.line7": "3. Lumens eiendom",
    "app.terms.line8": "ViewPoints-programvaren og alle data, koder, nøkler, passord, design, tegninger, registre og andre dokumenter eller materialer knyttet til programvaren, skal være og forbli Lumens eiendom gjennom hele varigheten av deltakelsen din i en ViewPoints-undersøkelse. Lumen eier alle intellektuelle eller eiendomsrettigheter til alle eksisterende og fremtidige intellektuelle eller industrielle eiendomsrettigheter i og til dokumenter, materialer, modeller, design, tegninger, prosesser, oppfinnelser, formler, datakoding, data, systemgrensesnitt, metodikk, kunnskap, konfidensiell informasjon eller annet arbeid som er utført, laget, skapt, tenkt ut, utviklet, forbedret, dekonstruert eller oppdaget av deg enten alene eller sammen med en annen person i forbindelse med eller relatert til programvaren og/eller enheten eller som kan brukes eller tilpasset for bruk deri eller i forbindelse med den (enten registrert eller uregistrert), inkludert alle eksisterende og fremtidige patenter, opphavsretter, designrettigheter, databaserettigheter, varemerker, halvledertopografi-rettigheter, plantesort-rettigheter, internettrettigheter/domenenavn, kunnskap og alle søknader om noe av det foregående og alle rettigheter til å søke om noe av det foregående (“eiendomsrettigheter”). Ingen eiendomsrettigheter skal overgis til deg på noe tidspunkt.",
    "app.terms.line9": "4. Vilkår og begrensninger for bruk",
    "app.terms.line10": "ViewPoints-programvaren er lisensiert til sluttbrukerne. Underlagt denne avtalen samtykker vi herved til å gi deg, på en ikke-eksklusiv, tilbakekallelig, ikke-overførbar, ikke-underlisensierbar, begrenset basis, å bruke ViewPoints-programvaren på en enhet du eier eller kontrollerer eller en personlig datamaskin, og du fremholder og garanterer at du har alle nødvendige rettigheter og tillatelser til å installere den. For å unngå tvil: Du kan ikke bruke programvaren til noe formål utenfor ViewPoints, ei heller oversette, kopiere eller reprodusere den, overføre den via telekommunikasjon, tilpasse, forene, endre eller modifisere den på noen måte.",
    "app.terms.line11": "Du godtar å ikke: (i) kopiere, endre, tilpasse, gjøre tilgjengelig, leie, lease, tidsdele, lage et avledet verk av eller forsøke å selge eller på annen måte overføre noen rettigheter til programvaren (ii) demontere, dekompilere eller reversere noen deler av programvare på noen måte; heller ikke tillate eller hjelpe noen part til å utlede eller forsøke å utlede kildekoden til, demontere, dekryptere, dekompilere eller reversere programvaren; heller ikke ta noen andre skritt for å få designinformasjon om programvaren; (iii) bruke programvaren til andre formål enn det den opprinnelig ble distribuert til deg for; (iv) bruke “edderkopper”, roboter eller andre automatiserte datautvinningsteknikker for å katalogisere, laste ned, lagre eller på annen måte reprodusere eller distribuere programvaren, eller for å manipulere resultatene av en undersøkelse eller annen aktivitet; (v) iverksette tiltak for å forstyrre programvaren eller en persons bruk av programvaren, inkludert, uten begrensning, ved overbelastning, “oversvømmelse”, “mailbombing” eller “krasj” av programvaren og datalagringssystemet som programvaren bruker gjennom Amazon Web Services; (vi) sende oss ulovlig, villedende eller skadelig kode, inkludert, uten begrensning, virus, spionprogrammer, reklameprogrammer eller annen skadelig kode.",
    "app.terms.line12": "Alle rettigheter du ikke er uttrykkelig tildelt nedenfor, er forbeholdt oss og våre lisensgivere. Du godtar at vi ikke har noen forpliktelse i henhold til denne avtalen eller på annen måte til å korrigere mangler, defekter eller feil i programvaren, eller på annen måte støtte, vedlikeholde, forbedre, endre, oppgradere, oppdatere eller forbedre programvaren. Vi er ikke ansvarlige for å gi deg eller dine designerte personer bistand til bruk av programvaren på noen måte.",
    "app.terms.line13": "Vi er ikke ansvarlig for skader på PC-en din, inkludert, men ikke begrenset til som et resultat av din bruk av programvaren. Vi er ikke ansvarlig for ytterligere datakostnader som du pådrar deg som følge av dataene som samles inn av programvaren gjennom din deltakelse i forskningen.",
    "app.terms.line14": "5. Ansvarsfraskrivelse",
    "app.terms.line15": "Vi og våre respektive direktører, styremedlemmer, ansatte, agenter, representanter, lisensgivere og leverandører gir ingen erklæring eller garanti eller betingelse med tanke på (a) at programvaren er tilgjengelig i tide, eller at tilgangen til programvaren er uavbrutt eller sikker; (b) at feil eller mangler vil bli rettet; eller (c) at programvaren eller serverne eller nettverkene som programvaren og enheten er gjort tilgjengelig på, er sikre eller fri for virus eller andre skadelige komponenter. Vi har ingen forpliktelse til å tilby vedlikeholds- og/eller støttetjenester med hensyn til programvaren.",
    "app.terms.line16": "6. Ansvarsbegrensning",
    "app.terms.line17": "(a) I den utstrekning som er tillatt i henhold til gjeldende lov, godtar du at vi og våre respektive direktører, styremedlemmer, ansatte, agenter, representanter, lisensgivere og leverandører ikke skal ha noe ansvar for eventuelle tap, skader eller kostnader av noe slag som følge av din installasjon, bruk av eller tilgang til programvaren, din bruk av eller manglende evne til å bruke programvaren, all informasjon eller materiale som er gjort tilgjengelig i eller gjennom programvaren og/eller på annen måte knyttet til programvaren eller på annen måte til denne avtalen, uavhengig av arten av den juridiske, rimelige eller lovfestede retten som hevdes å ha blitt krenket.",
    "app.terms.line18": "(b) I den grad det er tillatt i henhold til gjeldende lov, er ditt eneste rettsmiddel ved misnøye med programvaren, å slutte å bruke den.",
    "app.terms.line19": "(c) Under ingen omstendigheter skal noen av våre enheter, direktører, styremedlemmer, ansatte, agenter eller representanter være ansvarlige overfor deg for tilfeldige, indirekte, følge-, lovbestemte, eksemplariske, straffe- eller spesielle skader, tap av inntekt, tap av muligheter, tap av omdømme, tap av bruk, tap av data, tap av andre immaterielle eiendeler eller uautorisert avlytting av data som er samlet inn av programvaren av tredjeparter, uansett om det er forutsigbart eller ikke, uansett hvordan det forårsakes, og i henhold til enhver kontrakt, tort (inkludert, men ikke begrenset til uaktsomhet), strengt ansvar eller annen teori, selv om det er informert på forhånd om at slike skader kan oppstå.",
    "demo.index.content.line1": "Under denne demonstrasjonen bruker vi kameraet ditt til å estimere hvor du retter blikket. Ta det med ro, vi kommer ikke til å ta bilder eller opptak.",
    "app.index.zoomRetry": "Vi krever at nettleseren er satt til 100% zoom. Vennligst zoom inn/ut og prøv igjen",
    "app.index.fullscreendesktopretry": "Dette studiet krever fullskjermsvisning, vennligst sørg for at nettleservinduet er maksimert",
    "app.index.fullscreendesktoptest": "Dette studiet krever fullskjermsvisning, vi må underta en test for å sørge for at nettleservinduet et maksimert",
    "app.index.fullscreendesktoptest2":"To knapper vises snart, vennligst klikk på dem for å fortsette",
    "app.index.fullscreendesktopmaximised":"Skjermen min er allerede maksimert",
    "app.index.fullscreendesktopskip":"Takk, vi har registrert denne forekomsten og vil forbedre gjenkjenningsprosessene våre.",
    "app.setup.instruction0d": "Vennligst roter enheten din for å være liggende",
    "app.setup.instructioncamera": "Vennligst klikk knappen nedenfor for å be om kameratilgang. For å fullføre undersøkelsen må du tillate kameratillatelser gjennom hele studiet.",
    "app.setup.cameraText": "Be om tilgang",
    "app.setup.retryPositioning": "Vi kunne ikke bestemme hodeposisjonen din. Vennligst hold hodet og enheten i ro og ikke dekk til kameraet f.eks. med fingrene. Bruk knappen nedenfor for å prøve på nytt",
    "app.setup.retryBroker": "Vi kunne ikke fullføre kalibreringsprosessen. Prøv å lukke alle ubrukte apper og faner for å frigjøre ressurser",
    "app.error.missingpanelparameter": "Manglende panelparameter.",
    "app.completion.congratulations-noet": "Gratulerer! Du har fullført studiet.",
    "app.error.previousfail": "Du har tidligere mislyktes i dette studiet, vennligst bruk den originale lenken for å starte fra begynnelsen.",
    "app.error.previoussuccess": "Du har tidligere fullført dette studiet",
    "app.error.route": "Du har prøvd å starte et studies om allerede er i gang. Vennligst bruk den originale lenken for å starte fra begynnelsen",
    "app.error.accesscode": "Du har prøvd å starte et studies om ikke eksisterer. Vennligst sjekk lenken din.",
    "app.error.webcam": "Dette studiet krever adgang til et webkamera",
    "app.error.adblocker": "Dette studiet krever at adblock er deaktivert",
    "app.error.startsession": "Kan ikke starte sessionen",
    "app.error.fullscreen": "Dette studiet krever fullskjermsvisning",
    "app.error.zoom": "Dette studiet krever at nettleservinduet er satt til 100% zoom",
    "app.index.webcamInstructions": "Vi er i ferd med å be om adgang til webkameraet ditt. Hvis du får en popup, vennligst klikk tillat",
    "app.setup.retryBack": "Vennligst avstå fra å trykke på tilbakeknappen i nettleseren gjennom hele studiet. Det kan føre til tap av belønning. Bruk knappen nedenfor for å prøve på nytt",
    "app.button.close": "Lukk",
    "app.button.return": "Returnere",
    "app.init.welcome": "Velkommen til studieplattformen Lumen Research",
    "app.init.intro": "Studie plattformen lar oss gå ombord og konfigurere brukere til en gitt annonse undersøkelse.",
    "app.init.message1": "Avhengig av type studie, vil en bruker bli guidet gjennom et sett med oppgaver.",
    "app.init.message2": "Disse oppgavene inkluderer; å gjennomføre en undersøkelse, lese gjennom et sett med instruksjoner og utføre øye sporingkalibrering",
    "app.init.message3": "Vi vil nå veilede deg gjennom en demo studie.",
    "app.init.message4": "I løpet av denne studien kan vi aktivere webkameraet ditt til å utføre øyesporing, men ingen av dataene dine vil bli registrert.",
    "app.init.message5": "Den nåværende studien er satt opp til å bruke følgende lenker på screen out/fullfør.",
    "app.index.webcamRetry": "Vi kunne ikke oppdage et webkamera, som kreves for denne studien. Gi tilgang til webkameraet ditt og prøv igjen.",
    "app.index.adblockRetry": "Beklager! Vi har oppdaget en annonse blokkering. For den beste studie opplevelsen, slå dette av og prøv igjen.",
    "app.index.fullscreendesktopreset": "Vi har oppdaget en endring i vindus størrelse, og nullstiller testen.",
    "app.index.gotomobile": "Desktop-brukere er ikke kvalifisert for denne studien, vennligst bruk QR-koden nedenfor for å åpne studien på mobil.",
    "app.index.returntopanel": "ELLER, klikk på knappen nedenfor for å gå tilbake til panelet",
    "app.setup.retryaudible": "Sørg for at enheten ikke er dempet og prøv igjen.",
    "app.generic.screenshotfail": "Vennligst ta et skjermbilde av denne siden som bevis på mislykket tilgang. Du blir omdirigert om kort tid.",
    "app.generic.screenshotsuccess": "Vennligst ta et skjermbilde av denne siden som bevis på fullført handling. Du blir omdirigert om kort tid.",
    "app.index.content.line1gpdrwarning": "Under denne undersøkelsen vil vi bruke frontkameraet på enheten din for å ta bilder og videoer mens du utfører ulike øvelser som involverer å se på stimulus.",
    "app.instructions.instruction7nav": "Bruk fingrene til å bevege i ulike retninger på skjermen.",
    "app.setup.instruction3audio": "Vennligst lytt nøye og følg instruksjonene som vises på skjermen.",
    "app.error.cellquotafullnoactivecell": "Ingen aktive Celle har blitt funnet for denne undersøkelsen. Cellekvoten er full. ",
    "app.error.browsernotallowed": "Nettleser ikke tillatt.",
    "app.faq.gdpr.intro1": "I denne undersøkelsen vil vi bruke frontkameraet på mobilenheten til å ta bilder eller videoer av bruker mens de blir bedt om å utføre forskjellige oppgaver som involverer å se på stimulus under ulike forhold (f.eks., belysning og posisjon). Deltakelsen forventes å ta omtrent 5 minutter. Dataene vil bli brukt til å utvikle programvare som kan beregne hvor brukere ser på skjermen.",
    "app.faq.gdpr.intro2": "I denne undersøkelsen vil vi følge retningslinjene i MRS Code of Conduct og personopplysningsloven (Data Protection Act 2018). Ditt personvern er viktig for oss. Før du deltar i studien, må du bekrefte at du er villig til å delta, at du forstår hvordan vi samler inn og bruker dataene, slik det er beskrevet i vår personvernerklæring, og gi ditt samtykke til at dataene kan brukes på denne måten. Personvernerklæringen gir en mer detaljert beskrivelse av hvilken informasjon som samles inn, og hvordan den blir brukt og delt.",
    "app.faq.gdpr.question1": "Hvilke data vil bli samlet inn?",
    "app.faq.gdpr.answer1.line1": "Denne undersøkelsen vil samle inn følgende typer data:",
    "app.faq.gdpr.answer1.line2": "'Sittende egenskaper' – En anonym ID brukt for å identifisere en bestemt datasamling relatert til 'Sittende' og data om de aktuelle miljøtestforholdene under økten.",
    "app.faq.gdpr.answer1.line3": "«Deltakeregenskaper» – Data om en respondent som deltar i et spesifikt Sittende, inkludert informasjon om deltakerens demografiske (f.eks. alder, kjønn), etniske (f.eks. hudfarge) og biologiske (f.eks. øyenfarge) egenskaper.",
    "app.faq.gdpr.answer1.line4": "«Videofil» – Bilder og videoer tatt med enhetens frontkamera for å fange deltakerens ansikt og øyne, mens de ser på og eventuelt samhandler med stimuli vist på én eller flere skjermer. Kameraet kan også fange andre elementer utilsiktet, basert på hva som vises i deltakerens synsfelt, under gjennomføringen av oppgaven.",
    "app.faq.gdpr.answer1.line5": "«Metadata» – Informasjon om tidspunktet når deltakerne interagerte med stimuli, koblet til tidsstempelet for hvert innsamlede bilde.",
    "app.faq.gdpr.answer1.line6": "«Fysisk enhetsdata» – Fysiske data om enheten, som inkluderer enhetsnavn, skjermstørrelse, skjermrotasjon og rotasjon av kamerasensoren.",
    "app.faq.gdpr.answer1.line7": "Ved å delta i denne forskningen bekrefter og godtar du at innholdet du blir vist i studien kan være konfidensielt, tilhøre en tredjepart og/eller være beskyttet av varemerker eller opphavsrett. Du forplikter deg til ikke å ta skjermbilder, gjøre opptak, kopiere eller på noen måte videreformidle innholdet fra studien.",
    "app.faq.gdpr.question2": "Hvordan vil dataene bli brukt/behandlet?",
    "app.faq.gdpr.answer2": "Dataene vil bli brukt til å utvikle programvare som kan vurdere om brukere er tilstede foran en enhet, om de ser på stimuli, og hvilke deler av stimuli de ser på. De kan også benyttes til demonstrasjoner eller for å forklare, samt evaluere, teknologiens utvikling og ytelse overfor tredjepartsaktører (klienter, leverandører, partnere). Dataene kan gjennomgås av flere 'annotatorer' – ansatte, underleverandører, partnere eller andre tredjepartsaktører – som vil bli bedt om å se gjennom videoopptakene og bildene for å vurdere deltakerens oppmerksomhet under forskningen (f.eks. er de tilstede i kameraets synsfelt? Ser de på skjermen?). Behandlingen av dataene er basert på samtykke, og de legitime interessene vil bli klart informert om i samtykkedokumentet.",
    "app.faq.gdpr.question3": "Hvor lenge vil dataene bli lagret?",
    "app.faq.gdpr.answer3": "Dataene vil bli oppbevart av oss så lenge det er rimelig nødvendig for formålene beskrevet tidligere. Programvaren som utvikles vil jevnlig bli oppdatert og evaluert, og disse dataene vil bli brukt til å vurdere hver ny iterasjon. Derfor kan dataene bli lagret i flere år, om ikke på ubestemt tid. Dataene vil bli slettet dersom de ikke lenger anses som nødvendige for utvikling eller evaluering av programvaren for stimulitesting.",
    "app.faq.gdpr.question4": "Hvem vil få tilgang til og behandle dataene?",
    "app.faq.gdpr.answer4": "Dataene vil bli oppbevart av Lumen Research Ltd., et selskap registrert i England med firmanummer 8682432. Selskapets registrerte kontor er på LABS House, 15-19 Bloomsbury Way, Holborn, London WC1A 2TH. Registreringsreferansen for databeskyttelse er ZA130772. Selskapets databeskyttelsesansvarlige er Matt Robinson (matt.robinson@lumen-research.com). Du har rett til å sende inn en klage på behandlingen av dine data ved å kontakte Storbritannias databeskyttingsmyndighet, Information Commissioner's Office (ICO). ICOs kontaktinformasjon finner du på deres nettsted: https://ico.org.uk/global/contact-us/. Ved å delta i studien gir du Lumen Research Ltd. en verdensomspennende, royaltyfri, underlisensierbar og overførbar lisens til å lagre, bruke, vise, reprodusere, modifisere, tilpasse, redigere, publisere og distribuere disse dataene.",
    "app.faq.gdpr.question5": "Har jeg rett til å få tilgang til mine personlige data?",
    "app.faq.gdpr.answer5": "Ja, du har rett til å få tilgang til dine personopplysninger. Det er imidlertid viktig å merke seg at navnet ditt ikke vil bli lagret sammen med de innsamlede dataene fra denne øvelsen. For å identifisere dine dataposter, vennligst oppgi nøyaktig dato og klokkeslett for når du deltok i undersøkelsen. I tillegg kan vi be om visuell identifikasjon for å sikre at de riktige datapostene blir hentet.",
    "app.faq.gdpr.question7": "Har jeg rett til å trekke tilbake min tillatelse til å behandle mine personlige data, eller å få slettet dem?",
    "app.faq.gdpr.answer7": "Ja, du har rett til å trekke tilbake samtykket ditt til behandlingen av personopplysninger og be om at disse opplysningene blir slettet. Det er imidlertid viktig å merke seg at navnet ditt ikke vil bli lagret sammen med dataene som samles inn under denne øvelsen. For å identifisere dataposten din, må du oppgi nøyaktig dato og klokkeslett for når du deltok i undersøkelsen, og vi vil be om visuell identifikasjon for å sikre at datapostene samsvarer korrekt.",
    "app.faq.gdpr.question8": "Hvordan kan jeg være sikker på at mine personlige opplysninger er trygge?",
    "app.faq.gdpr.answer8": "Dataene som samles inn av Lumen Research er kryptert ved hjelp av Secure Sockets Layer (SSL)-teknologi, en velprøvd og pålitelig krypteringsstandard. Denne teknologien brukes både for å sikre overføringskanalene og for lagring av dataene.",
    "app.faq.gdpr.question9": "Hvordan vil vi bruke og dele informasjonen vi samler inn?",
    "app.faq.gdpr.answer9": "Vi kan dele informasjonen vi samler inn med tredjeparts tjenesteleverandører for å kunne tilby deres tjenester til oss. Videre kan vi avsløre informasjon til en tredjepart i forbindelse med omorganisering, fusjon, salg, fellesforetak, overdragelse, overføring eller annen disponering av hele eller deler av vår virksomhet, eiendeler eller aksjer (inkludert ved konkurs eller lignende prosesser). Vi kan også bruke eller dele informasjonen vi samler inn fra og om deg når vi anser det som nødvendig eller hensiktsmessig: (a) for å overholde gjeldende lovgivning, inkludert lover utenfor ditt bostedsland; (b) for å etterkomme juridisk prosess; (c) for å svare på forespørsler fra offentlige myndigheter, inkludert myndigheter utenfor ditt bostedsland; (d) for å håndheve vår sluttbrukerlisensavtale; (e) for å beskytte vår virksomhet eller virksomheten til våre tilknyttede selskaper; (f) for å beskytte våre rettigheter, personvern, sikkerhet eller eiendom, samt rettighetene til våre tilknyttede selskaper, deg eller andre; (g) for å forfølge tilgjengelige rettsmidler eller begrense skade; og (h) for å oppdage og løse svindel- eller sikkerhetsproblemer.",
    "app.faq.gdpr.question10": "Hvilke tiltak er blitt tatt for å beskytte dataene?",
    "app.faq.gdpr.answer10": "Vi tar nødvendige skritt for å beskytte informasjonen din ved å bruke passende fysiske, elektroniske og prosedyrerelaterte sikkerhetstiltak, tilpasset sensitiviteten av informasjonen vi har ansvar for. Dette inkluderer beskyttelse mot tap, tyveri, samt uautorisert tilgang, avsløring, kopiering, bruk eller modifikasjon.",
    "app.restore.retry": "Du har ikke brukt tilstrekkelig tid på forrige steg.",
    "app.restore.timeremaining": " Minutter igjen",
    "app.error.postexposure": "Beklager, du har nå brukt opp alle forsøkene dine, og dessverre kan du ikke fortsette i studiet."
  }