{
    "app.thankyou": "Gracias",
    "app.button.faqs": "Preguntas Frecuentes",
    "app.button.terms": "Condiciones de uso",
    "app.button.privacy": "Política de privacidad",
    "app.button.feedback": "Comentarios/Retroalimentación",
    "app.button.selectLanguage": "Seleccione idioma",
    "app.button.help": "Ayuda",
    "app.button.start": "Iniciar",
    "app.button.next": "Siguiente",
    "app.button.submit": "Enviar",
    "app.button.close": "Cerrar",
    "app.button.return": "Regresar",
    "app.button.takepart": "Ok – Participaré",
    "app.menu.feedback.content": "Si has experimentado algún problema con el estudio o desea dejar un comentario, no dudes en hacerlo aquí.",
    "app.contactModal.feedback": "¡Gracias por tus comentarios!",
    "app.takePartModal.line1": " Si te vas ahora no serás elegible para ninguna recompensa por participar.",
    "app.takePartModal.line2": "¡Solo necesitas una cámara web!",
    "app.takePartModal.reason1": "No tengo una cámara web.",
    "app.takePartModal.reason2": "No me siento cómodo con que mi cámara web se utilice para esta encuesta.",
    "app.takePartModal.reason3": "No tengo tiempo para hacer esto ahorita.",
    "app.takePartModal.reason4": "Otras razones.",
    "app.generic.redirect": "Serás redirigido pronto.",
    "app.generic.screenshotfail": "Por favor, toma una captura de pantalla de esta página como prueba de su error de entrada. Será redirigido pronto.",
    "app.generic.screenshotsuccess": "Por favor, tome una captura de pantalla de esta página como comprobante de finalización. Será redirigido pronto.",
    "app.init.welcome": "Bienvenido a la plataforma de estudios de Lumen Research",
    "app.init.intro": "La plataforma de estudio nos permite incorporar y configurar a los usuarios para un estudio publicitario determinado.",
    "app.init.message1": " Dependiendo del tipo de estudio, el usuario será guiado a través de un conjunto de tareas.",
    "app.init.message2": "Estas tareas incluyen: realizar una encuesta, leer las instrucciones y realizar la calibración del seguimiento ocular.",
    "app.init.message3": "Ahora lo guiaremos a través de un estudio de demostración.",
    "app.init.message4": "Durante este estudio podríamos habilitar su cámara web para realizar seguimiento ocular, pero no se registrará ninguno de sus datos.",
    "app.init.message5": "El estudio actual está configurado para utilizar los siguientes enlaces en salir/completar.",
    "app.prestart.loading": "Cargando, por favor espere",
    "app.index.title": "Bienvenido",
    "app.index.content.line1": "Durante esta encuesta, usaremos tu cámara para estimar dónde estás mirando. No te preocupes, no tomaremos fotos ni videos.",
    "app.index.content.line1gpdrwarning": "Durante esta encuesta, utilizaremos la cámara frontal de su dispositivo para tomar imágenes y video mientras realizas varios ejercicios que implican mirar estímulos.",
    "app.index.content.line1alt": "Asegurate de estar sentado cómodamente y luego presiona 'Iniciar' para comenzar.",
    "app.index.content.line2": "¿Su dispositivo tiene cámara? Si es así, presione a continuación el botón de inicio.",
    "app.index.rejection": "Lo sentimos, parece que este enlace es invalido",
    "app.index.webview": "Lo sentimos, parece que accediste a tu página desde una vista web. Abre este enlace en tu navegador. Es posible que puedas hacerlo directamente desde el menú de la vista web.",
    "app.index.webview.chrome.1": "Toque el botón de menú de arriba y luego seleccione 'Abrir en Chrome'.",
    "app.index.webview.chrome.2": "Esto abrirá esta encuesta en una aplicación del navegador, para que pueda funcionar correctamente.",
    "app.index.webview.android.1": "Pulsa a continuación Iniciar para abrir esta encuesta en una aplicación de navegador y funcione correctamente..",
    "app.index.webview.android.2": "Si esto falla, abre el enlace en una aplicación del navegador manualmente (por ejemplo, copia y pega el enlace en la barra de direcciones de Chrome o usa una opción como 'Abrir en Chrome' en la barra de menú de arriba).",
    "app.index.webview.safari.1": "Toca el ícono de Safari en la parte inferior de la pantalla para continuar.",
    "app.index.webview.safari.2": "Se ve así:",
    "app.index.webview.safari.3": "Esto abrirá la encuesta en Safari para que pueda funcionar correctamente.",
    "app.index.wifi": " Parece que tu conexión a internet es demasiado lenta. ¿Estás conectado a wifi?",
    "app.index.tellmemore": "Cuéntame más.",
    "app.index.takepart": "No me gusta esta parte.",
    "app.index.webcamRetry": "No pudimos detectar ninguna cámara web. Esta es necesaria para este estudio. Por favor, proporciona acceso a su cámara web e inténtelo de nuevo.",
    "app.index.adblockRetry": "¡Uy! Hemos detectado un bloqueador de anuncios. Para disfrutar mejor la experiencia de este estudio, desactívalo y vuelve a intentarlo.",
    "app.index.zoomRetry": "Requerimos que el navegador esté configurado con un zoom del 100%. Por favor, acerque o aleje el zoom y vuelva a intentarlo.",
    "app.index.fullscreendesktopretry": "Este estudio requiere una vista de pantalla completa. Asegúrese de que la ventana de su navegador esté maximizada",
    "app.index.fullscreendesktoptest": "Este estudio requiere que la ventana de su navegador esté en pantalla completa. Necesitamos realizar una prueba para asegurarnos de que la ventana de su navegador esté maximizada",
    "app.index.fullscreendesktoptest2": "En breve aparecerán dos botones, haz clic en ellos para continuar. ",
    "app.index.fullscreendesktopmaximised": "Mi pantalla ya está maximizada",
    "app.index.fullscreendesktopskip": " Gracias. Hemos registrado esta instancia y mejoraremos nuestros procesos de detección.",
    "app.index.fullscreendesktopreset": "Hemos detectado un cambio en el tamaño de la ventana, reiniciando la prueba.",
    "app.index.gotomobile": "Los usuarios de computadoras de escritorio no son elegibles para este estudio, utilice el código QR a continuación para abrir el estudio en el móvil.",
    "app.index.returntopanel": "O bien, haga clic en el botón de abajo para volver al panel",
    "app.instructions.title": "Instrucciones",
    "app.instructions.instruction1": "Si es posible, no use gafas durante la encuesta. ",
    "app.instructions.instruction2": "Sientate de manera que puedas mantener la cabeza cómodamente y el dispositivo quieto.",
    "app.instructions.instruction3": " Mueve los ojos, no la cabeza.",
    "app.instructions.instruction4": "¿Listo para comenzar?",
    "app.instructions.instruction5": "Por favor, utilice wifi para evitar cargos por datos..",
    "app.instructions.instruction6": "Por favor, lee atentamente las siguientes instrucciones.",
    "app.instructions.instruction7": "Apoya tu teléfono sobre una superficie estable.",
    "app.instructions.instruction7nav": "Utiliza tu dedo para moverte en cualquier dirección en la pantalla..",
    "app.instructions.instruction8": "Asegurate de que tu habitación esté bien iluminada, preferiblemente desde arriba (no desde atrás).",
    "app.setup.title": "Setup",
    "app.setup.instruction0": "Mantenga su dispositivo en modo vertical.",
    "app.setup.instruction0a": " Por favor, gira tu dispositivo en posición vertical.",
    "app.setup.instruction0b": "Gira tu dispositivo para que la cámara quede a la izquierda.",
    "app.setup.instruction0c": " Gira tu dispositivo para que la cámara quede a la derecha.",
    "app.setup.instruction0d": "Gira tu dispositivo horizontalmente.",
    "app.setup.instructioncamera": "Haga clic en el botón de abajo para solicitar acceso a la cámara. Para completar la encuesta, debe habilitar los permisos de la cámara durante todo el estudio.",
    "app.setup.cameraText": "Solicitar acceso",
    "app.setup.instruction1": "Inicializando...",
    "app.setup.instruction2": "Cuando te detecten, coloca tu cabeza dentro de la caja.",
    "app.setup.instruction3": "Manten la cabeza y el dispositivo en la posición que te pedimos hasta que se complete la encuesta.",
    "app.setup.instruction3audio": "Por favor escucha atentamente y sigue las instrucciones que aparecen en la pantalla..",
    "app.setup.instruction4": " Ahora solo nos falta validar.",
    "app.setup.instruction5": "¡Gracias! Recuerda mantener la cabeza y el dispositivo en la misma posición hasta que finalice la encuesta.",
    "app.setup.instruction6": "Por favor, desliza hacia arriba para ocultar la barra de direcciones.",
    "app.setup.retry": "Error detectado. Manten la cabeza y el dispositivo quietos y no cubrad la cámara con los dedos, por ejemplo. Utiliza el botón de abajo para volver a intentarlo.",
    "app.setup.retryPositioning": "No pudimos determinar la posición de tu cabeza. Manten la cabeza y el dispositivo quietos y no cubras la cámara, con los dedos, por ejemplo. Utiliza el botón de abajo para volver a intentarlo.",
    "app.setup.retryInit": "Error detectado. Asegúrate de que el navegador pueda acceder a tu cámara. Si anteriormente rechazaste los permisos de acceso a la cámara, deberás aceptarlos en la configuración de privacidad del navegador. Usa el botón de abajo para volver a intentarlo.",
    "app.setup.retryBroker": "No pudimos completar el proceso de calibración. Intenta cerrar las aplicaciones y pestañas que no uses para liberar recursos",
    "app.setup.retryButton": "Reintentar",
    "app.setup.calibrationProp.watch": "Sigue el punto cuando aparezca en la pantalla",
    "app.setup.calibrationProp.watch.george": "A continuación, cuando veas una cara, sigue mirándola mientras imitas los movimientos de su cabeza",
    "app.setup.calibrationProp.click": "Haz clic en cualquier lugar para continuar",
    "app.setup.calibrationProp.tap": "Pulsa en cualquier lugar para continuar.",
    "app.study.validationProp.watch": "Sigua el punto cuando aparezca en la pantalla",
    "app.study.validationProp.restart": "Inténtalo de nuevo",
    "app.study.validationProp.click": "Haz clic en cualquier lugar para continuar",
    "app.study.validationProp.tap": "Toca en cualquier lugar para continuar",
    "app.study.render.error": "Dominio no válido para el paso de renderizado",
    "app.error.title": "Error",
    "app.error.generic": "¡Uy! Algo salió mal..",
    "app.error.initialise": "No se puede inicializar!",
    "app.error.findCamera": "¡No se puede encontrar la cámara!",
    "app.error.headPosition": " No se puede determinar la posición de la cabeza.",
    "app.error.calibrate": "No se puede calibrar.",
    "app.error.validate": "No se puede validar.",
    "app.error.start": " No se pudo iniciar.",
    "app.error.assign": "No se pudo asignar.",
    "app.error.osnotallowed": "Screenout: Su sistema operativo no está permitido, verifique qué dispositivo puede usarse para esta encuesta y vuelva a intentarlo.",
    "app.error.demoquotafullnoactivecell": "Quotafull: Gracias por su interés. Este estudio ya no está disponible para su grupo demográfico.",
    "app.error.cellquotafullnoactivecell": "No se ha encontrado ninguna celda activa para este estudio. Las cuotas de celdas están completas.",
    "app.error.missingpanelparameter": "Parámetro del panel faltante.",
    "app.error.browsernotallowed": "Navegador no permitido.",
    "app.invalid.title": "Invalido",
    "app.invalid.generic": "Lo sentimos, usted no es elegible para este estudio..",
    "app.completion.title": "Terminación",
    "app.completion.congratulations": " ¡Felicitaciones! Has completado el estudio. Se cancelará el acceso a tu cámara para esta encuesta.",
    "app.questions.required": "Esta pregunta es obligatoria.",
    "app.faq.gdpr.intro1": "En este estudio, utilizaremos la cámara frontal de un dispositivo móvil para tomar imágenes y grabar vídeos del usuario mientras realiza diversos ejercicios que implican observar estímulos en diversas condiciones ambientales (iluminación y posicionamiento). Se espera que la participación dure unos 5 minutos. Los datos se utilizarán para impulsar el desarrollo continuo de software que pueda estimar dónde miran los usuarios en la pantalla.",
    "app.faq.gdpr.intro2": "Esta investigación se lleva a cabo en conformidad con el Código de Conducta de MRS y la Ley de Protección de Datos de 2018. Tu privacidad es importante para nosotros. Antes de participar en este estudio, debes confirmar que, al participar, comprendes los datos que recopilamos y cómo se utilizarán, tal como se describe en esta Política de Privacidad, y qué consiente dicho uso. Esta política describe con más detalle qué información se recopila y cómo se utiliza y comparte..",
    "app.faq.gdpr.question1": "¿Qué datos se recopilarán?",
    "app.faq.gdpr.answer1.line1": "Este estudio implicará la recopilación de los siguientes tipos de datos:",
    "app.faq.gdpr.answer1.line2": "'Características de la posición sentada': una identificación anónima utilizada para identificar una recopilación de datos en particular 'Sentado' y datos sobre las condiciones de prueba ambientales predominantes durante la sesión.",
    "app.faq.gdpr.answer1.line3": "'Características del participante': datos sobre un encuestado que participa en una sesión determinada, incluida información sobre los atributos demográficos (edad, género), étnicos (tipo de piel) y biológicos (color de ojos) del participante..",
    "app.faq.gdpr.answer1.line4": "'Archivo de vídeo': Imágenes o grabaciones de vídeo tomadas con la cámara frontal del dispositivo para capturar imágenes de los participantes mientras observan e incluso interactúan con los estímulos mostrados en una o varias pantallas. Se capturarán imágenes de los ojos y el rostro del participante, así como de cualquier otra cosa que la cámara frontal pueda captar involuntariamente mientras se realiza la tarea.d.",
    "app.faq.gdpr.answer1.line5": "'Metadatos': datos sobre el momento en que los individuos interactuaron con los estímulos, vinculados a la marca de tiempo de cada imagen recopilada.",
    "app.faq.gdpr.answer1.line6": "'Datos físicos del dispositivo': datos físicos sobre el dispositivo, incluido el nombre del dispositivo, el tamaño de la pantalla, la rotación de la pantalla y la rotación del sensor de la cámara.",
    "app.faq.gdpr.answer1.line7": "Al participar en esta investigación, usted comprende y acepta que el Contenido que se le muestra en este estudio puede ser confidencial, propiedad de un tercero y/o estar sujeto a leyes de marca registrada o derechos de autor y que no realizará capturas de pantalla, grabará ni copiará de otro modo ninguno de los Contenidos ni redistribuirá ninguno de los Contenidos del estudio de ninguna manera.",
    "app.faq.gdpr.question2": " ¿Cómo se utilizarán/tratarán los datos?",
    "app.faq.gdpr.answer2": "Los datos se utilizarán para el desarrollo de software que permita estimar si los usuarios están presentes frente a un dispositivo, si están mirando estímulos y en qué parte de los estímulos podrían estar mirando. También podrán utilizarse con fines demostrativos o para explicar o ayudar a evaluar para terceros (clientes, proveedores, socios) el desarrollo y el rendimiento de nuestra tecnología. Los datos podrán ser revisados ​​por cualquier número de 'anotadores' de datos: empleados, subcontratistas, socios o cualquier otro tercero a quien se le pedirá que visualice las grabaciones/imágenes para evaluar los estados de atención de los participantes durante la investigación (¿están presentes en el campo de visión de la cámara? ¿Están mirando la pantalla?). Las bases legales para el tratamiento de los datos serán el consentimiento informado y el interés legítimo.. ",
    "app.faq.gdpr.question3": "¿Durante cuánto tiempo se conservarán los datos?",
    "app.faq.gdpr.answer3": "Conservaremos los datos para su uso durante el tiempo que sea razonablemente necesario para los fines descritos anteriormente. El software en desarrollo se actualiza y evalúa periódicamente, y estos datos se utilizarán para evaluar cada iteración, por lo que se prevé que se conserven para este fin durante varios años, o incluso indefinidamente. Los datos se eliminarán si se considera que ya no son útiles para el desarrollo o la evaluación del software de prueba de estímulos.",
    "app.faq.gdpr.question4": "¿Quién recibirá y tratará los datos?",
    "app.faq.gdpr.answer4": "Los datos serán conservados por Lumen Research Ltd. Lumen Research Ltd. es una empresa registrada en Inglaterra con el número 8682432. Su domicilio social se encuentra en LABS House, 15-19 Bloomsbury Way, Holborn, Londres WC1A 2TH. Su referencia de registro de protección de datos es ZA130772. Su responsable de protección de datos es Matt Robinson (matt.robinson@lumen-research.com). Tiene derecho a presentar una reclamación sobre el tratamiento y procesamiento de sus datos en cualquier momento contactando con la Autoridad de Protección de Datos del Reino Unido, la Oficina del Comisionado de Información. Los datos de contacto de la ICO están disponibles en su sitio web: https://ico.org.uk/global/contact-us/. Al participar en el estudio, otorga a Lumen una licencia mundial, libre de regalías, sublicenciable y transferible para alojar, almacenar, usar, mostrar, reproducir, modificar, adaptar, editar, publicar y distribuir estos datos..",
    "app.faq.gdpr.question5": "¿Tengo derecho a acceder a mis datos personales?",
    "app.faq.gdpr.answer5": " Sí, tiene derecho a acceder a sus datos personales; sin embargo, es importante que tenga en cuenta que su nombre no se almacenará junto con los datos recopilados durante este proceso. Para identificar sus registros de datos, deberá proporcionarnos la fecha y hora exactas en que realizó la investigación, y le solicitaremos una identificación visual para garantizar que los registros coincidan correctamente.",
    "app.faq.gdpr.question7": "¿Tengo derecho a retirar mi consentimiento para el tratamiento de mis datos personales o a su supresión?",
    "app.faq.gdpr.answer7": "Sí, tiene derecho a retirar su consentimiento para el tratamiento de sus datos personales y a su supresión. Sin embargo, es importante que tenga en cuenta que su nombre no se almacenará junto con los datos recopilados durante este proceso. Para identificar su registro de datos, deberá proporcionarnos la fecha y hora exactas en que realizó la investigación, y le solicitaremos una identificación visual para garantizar que los registros de datos coincidan correctamente.",
    "app.faq.gdpr.question8": "¿Cómo sé que mi información personal está segura?",
    "app.faq.gdpr.answer8": "Los datos recopilados por Lumen Research se cifran mediante tecnología Secure Sockets Layer (SSL). Se trata de un estándar de cifrado de alto nivel y de uso común. Esta tecnología se utiliza tanto para proteger los canales de transferencia como para el almacenamiento final.",
    "app.faq.gdpr.question9": "How will we use and disclose information we collect?",
    "app.faq.gdpr.answer9": "Podemos divulgar la información que recopilamos a otros proveedores de servicios externos para que puedan prestarnos servicios. También podemos divulgar información a un tercero en caso de reorganización, fusión, venta, empresa conjunta, cesión, transferencia u otra disposición de la totalidad o parte de nuestro negocio, activos o acciones (incluso en relación con cualquier quiebra o procedimiento similar). También podemos usar o divulgar la información que recopilamos de usted y sobre usted según lo consideremos necesario o apropiado: (a) según la legislación aplicable, incluidas las leyes fuera de su país de residencia; (b) para cumplir con el proceso legal; (c) para responder a solicitudes de autoridades públicas y gubernamentales, incluidas las autoridades públicas y gubernamentales fuera de su país de residencia; (d) para hacer cumplir nuestro acuerdo de licencia de usuario final; (e) para proteger nuestras operaciones o las de cualquiera de nuestras filiales; (f) para proteger nuestros derechos, privacidad, seguridad o propiedad, y/o los de nuestras filiales, usted u otros; (g) para permitirnos buscar los recursos disponibles o limitar los daños que podamos sufrir; y (h) detectar y resolver cualquier fraude o problema de seguridad.",
    "app.faq.gdpr.question10": "¿Qué medidas se toman para mantener los datos seguros?",
    "app.faq.gdpr.answer10": "Tomamos medidas razonables para proteger su información mediante medidas de seguridad físicas, electrónicas o procedimentales adecuadas a la sensibilidad de la información bajo nuestra custodia o control, que incluyen medidas de protección contra pérdida o robo, así como acceso, divulgación, copia, uso o modificación no autorizados.",
    "app.faq.question1": "P: ¿Qué es la Plataforma de Estudio Lumen y qué implica participar en una encuesta de seguimiento ocular?",
    "app.faq.answer1": " R: Esta encuesta utiliza tecnología de seguimiento ocular. Se le pedirá que se siente frente a la cámara web o la cámara frontal de su dispositivo y realice una calibración para estimar dónde mira en la pantalla. Durante la encuesta, se le pedirá que visualice algunos elementos (por ejemplo, imágenes, sitios web o videos) mientras la herramienta de encuesta calcula su mirada. Los datos obtenidos mediante este seguimiento ocular son las coordenadas de los puntos de mirada estimados en su pantalla a lo largo del tiempo. El software utiliza imágenes suyas de su cámara web para realizar estas estimaciones, pero no captura fotos ni videos. También es posible que se le hagan preguntas sobre usted, su experiencia y los elementos que ha visto durante la encuesta. Los datos se envían a Lumen Research Ltd. Lumen Research analizará los datos y compartirá los resultados con sus clientes.",
    "app.faq.question2": "P: ¿Por qué el software requiere una cámara web y cómo se utiliza?",
    "app.faq.answer2": " R: El software utiliza imágenes de su cámara web o dispositivo para estimar dónde mira en la pantalla. Esto se logra mediante una combinación de software de seguimiento facial y ocular que analiza las imágenes localmente en su dispositivo en tiempo real. Estas imágenes se procesan y se muestran durante la calibración para que pueda posicionarse correctamente, pero no se graban, protegiendo así su privacidad. Los datos que se registran y recopilan mediante el seguimiento ocular no son imágenes, sino datos numéricos sobre los puntos de mirada estimados en el estímulo a lo largo del tiempo.",
    "app.faq.question3": "P: ¿Me toman imágenes mientras realizan el seguimiento ocular?",
    "app.faq.answer3": "R: Las imágenes solo se utilizan para que pueda posicionarse correctamente durante la calibración. Estas imágenes no se guardan y solo usted las ve. Los datos que se registran y recopilan del seguimiento ocular no son imágenes, sino datos numéricos sobre los puntos de mirada estimados en el estímulo a lo largo del tiempo.",
    "app.faq.question4": "P: ¿Qué datos se recopilan durante la encuesta?",
    "app.faq.answer4.line1": "R: El software recopilará algunos o todos los siguientes cinco tipos de datos:",
    "app.faq.answer4.line2": "'ID de usuario': El ID de usuario utilizado para identificar a los encuestados (para administrar las recompensas por participación);",
    "app.faq.answer4.line3": "Datos de mirada: Estimaciones de dónde miran los usuarios en la pantalla cuando se les muestran estímulos.",
    "app.faq.answer4.line4": "'Datos de la encuesta': respuestas a preguntas respondidas por los usuarios ",
    "app.faq.answer4.line5": "Metadatos: datos sobre el momento en que las personas participaron con éxito en la investigación.",
    "app.faq.answer4.line6": "'Datos del dispositivo físico': datos sobre la computadora en la que participa un usuario, como el nivel de RAM, el tipo de CPU en la máquina y el agente de usuario (sistema operativo y versión del navegador), así como información sobre la pantalla de la computadora (tamaño y resolución), la cámara web (marca y resolución) y los programas antivirus.",
    "app.faq.question5": "P: ¿Cómo se utilizarán los datos recopilados?",
    "app.faq.answer5.line1": " A: Los datos recopilados se utilizarán para explorar cómo las personas ven e interactúan con varios tipos de imágenes y medios visuales y para evaluar las opiniones de las personas sobre las imágenes que se les muestran.",
    "app.faq.answer5.line2": "Lumen podrá compartir los datos de investigación con empresas afiliadas, socios comerciales y clientes. Si bien los datos de investigación podrían compartirse con afiliados, socios y clientes, normalmente se hará de forma anónima. Dichos afiliados, socios y clientes podrán utilizar estos datos para crear descripciones resumidas de una amplia gama de comportamientos y segmentos de consumidores con el fin de desarrollar productos o proporcionar soluciones de marketing a sus clientes o consumidores.",
    "app.faq.answer5.line3": "Lumen recopila 'datos del dispositivo físico' y 'metadatos' para ayudar a administrar la recopilación y el procesamiento de datos, incluido el control de calidad y la gestión del volumen.",
    "app.faq.answer5.line4": "Los datos recogidos se conservarán durante el tiempo necesario para cumplir con las finalidades señaladas.",
    "app.faq.question6": " P: ¿Qué tan seguras estan mis datos?",
    "app.faq.answer6": "R: Los datos recopilados por Lumen se cifran mediante tecnología SSL. Este es el estándar de cifrado más común y lo emplean los bancos para proteger las transacciones bancarias y otras comunicaciones altamente sensibles. También lo utilizan Amazon, PayPal, eBay y otros sitios web que requieren sistemas de seguridad similares a los de un banco. Los científicos estiman que descifrar el cifrado de 256 bits con la tecnología actual requeriría mucho más tiempo que la edad del universo. Los datos recopilados se almacenan en servidores web seguros gestionados por Amazon Web Services (AWS). AWS es una de las plataformas de servicios de computación en la nube más seguras y fiables, y la utilizan importantes servicios comerciales basados ​​en la nube, como Netflix y Expedia.",
    "app.faq.question7": "P: ¿Qué obligaciones tienes respecto del Contenido que se te muestra?",
    "app.faq.answer7": "R: Al participar, usted también comprende y acepta que el Contenido que se le muestra en este estudio puede ser confidencial, propiedad de un tercero y/o estar sujeto a leyes de marca registrada o derechos de autor y que no realizará capturas de pantalla, grabará ni copiará de otro modo ninguno de los Contenidos ni redistribuirá ninguno de los Contenidos del estudio de ninguna manera.",
    "app.faq.question8": "Q: No estoy seguro de qué tan buena es mi cámara web, ¿funcionará para el seguimiento ocular?",
    "app.faq.answer8": "R:El sistema funciona con una amplia gama de cámaras web: no debería ser un problema.",
    "app.faq.question9": "P: He completado el estudio, ¿necesito hacer algo más?",
    "app.faq.answer9": "R: No, no es necesario hacer nada más. El software se cerrará automáticamente..",
    "app.faq.question10": "P: ¿Necesito estar conectado a Internet para participar?",
    "app.faq.answer10": "R: Sí, necesitarás una conexión a Internet para descargar el programa y completar la tarea..",
    "app.faq.question11": "P: ¿Necesito completar la tarea en una superficie estable?",
    "app.faq.answer11": "R: Sí, para obtener los mejores resultados, sientate cómodamente frente a la pantalla de tu computadora y, después de la calibración, manten la cabeza quieta mientras se presenta el estímulo, hasta que se te notifique. Le recomendamos no participar si está en un tren o con su computadora portátil sobre el regazo, etc.",
    "app.faq.question12": "P: Las preguntas frecuentes no han resuelto mi problema, ¿qué hago ahora?",
    "app.faq.answer12": " R: Envianos un correo electrónico a study-feedback@lumen-research.com y nos comunicaremos contigo lo antes posible.",
    "app.faq.patent": "Las pruebas de contenido web de Lumen se basan en nuestra propia tecnología patentada de pruebas en contexto (número de solicitud de patente del Reino Unido 1816158.8).",
    "app.terms.line1": "Estos términos de uso ('Términos de uso') rigen el acuerdo entre usted y Lumen Research Ltd ('Lumen' o 'Nosotros'), una empresa registrada en Inglaterra, número de empresa 8682432 con domicilio social en Unit 215, 22 Highbury Grove, Londres N5 2EF y referencia de registro de protección de datos ZA130772, con respecto a su uso del software de seguimiento ocular 'ViewPoints' (el 'Software') en su dispositivo.",
    "app.terms.line2": "1. Acuerdo sobre los términos",
    "app.terms.line3": " Al usar el Software, usted reconoce que acepta este Acuerdo. Si no acepta los términos y condiciones de este Acuerdo, no complete la encuesta ViewPoints.",
    "app.terms.line4": "2. Información confidencial",
    "app.terms.line5": " Al instalar, usar o interactuar de cualquier manera con ViewPoints, usted podría tener acceso a información o contenido perteneciente a Lumen, incluyendo información que Lumen o cualquiera de sus clientes considere confidencial o que, por su naturaleza, circunstancias o forma de divulgación, sea evidentemente confidencial. Esto incluye (sin perjuicio de lo anterior) cualquier información sobre planes de negocio, nuevas oportunidades de negocio en desarrollo, proyectos de investigación y desarrollo, diseños, procesos secretos, códigos, diseños de software, secretos comerciales, desarrollo y fórmulas de productos o servicios, conocimientos técnicos, invenciones, estadísticas y pronósticos de ventas, estrategias y planes de marketing, costos, resultados y otra información financiera (salvo que se publique en cuentas auditadas), precios y estructuras de descuentos (ya sea que se registre por escrito, en formato electrónico o de otro tipo) ('Información Confidencial'). Dicha Información Confidencial será propiedad única y exclusiva de Lumen o sus clientes, y en ningún caso usted obtendrá ni recibirá ningún derecho, título o interés sobre dicha Información Confidencial.",
    "app.terms.line6": "Durante su participación en ViewPoints o en cualquier momento (sin límite) tras la finalización de dicha participación, no deberá divulgar ni comunicar a ninguna persona; utilizar para sus propios fines; ni, por falta de diligencia debida, causar la divulgación no autorizada de Información Confidencial. Deberá en todo momento hacer todo lo posible para evitar la publicación o divulgación de cualquier Información Confidencial. Estas restricciones dejarán de aplicarse a cualquier información que se haga pública de forma general, salvo por su incumplimiento..",
    "app.terms.line7": "3. Propiedad del Lumen ",
    "app.terms.line8": "El Software ViewPoints y todos los datos, códigos, claves, contraseñas, diseños, dibujos, registros y otros documentos o materiales relacionados con el Software serán y seguirán siendo propiedad de Lumen durante toda su participación en una encuesta de ViewPoints. Lumen posee todos los derechos intelectuales o de propiedad sobre todos los derechos de propiedad intelectual o industrial, existentes y futuros, relacionados con cualquier documento, material, modelo, diseño, dibujo, proceso, invención, fórmula, código informático, datos, interfaces de sistema, metodologías, conocimientos técnicos, Información Confidencial u otro trabajo realizado, creado, ideado, desarrollado, mejorado, deconstruido o descubierto por usted, ya sea solo o en colaboración con cualquier otra persona, en relación con el Software o el Dispositivo, o que pueda utilizarse o adaptarse para su uso en ellos o en relación con ellos (ya estén registrados o no), incluyendo todas las patentes, derechos de autor, derechos de diseño, derechos de bases de datos, marcas comerciales, derechos de topografía de semiconductores, derechos de obtenciones vegetales, derechos de internet/nombres de dominio, conocimientos técnicos y cualquier solicitud de cualquiera de los anteriores, así como cualquier derecho a solicitar cualquiera de los anteriores ('Derechos de Propiedad'). Ningún Derecho de Propiedad se le transferirá en ningún momento..",
    "app.terms.line9": "4. Términos y restricciones de uso ",
    "app.terms.line10": "El Software ViewPoints se licencia a usuarios finales. Sujeto a este Acuerdo, por la presente acordamos permitirle, de forma no exclusiva, revocable, intransferible, no sublicenciable y limitada, utilizar el Software ViewPoints en un dispositivo de su propiedad o bajo su control, o en un ordenador personal respecto del cual declara y garantiza tener todos los derechos y permisos necesarios para dicha instalación. Para evitar cualquier duda, no podrá utilizar el Software para ningún fin ajeno a ViewPoints, traducir, copiar, reproducir, transmitir por telecomunicaciones, adaptar, fusionar, cambiar ni modificar de ninguna manera.",
    "app.terms.line11": "Usted acepta no: (i) copiar, modificar, adaptar, poner a disposición, alquilar, arrendar, compartir tiempo, crear un trabajo derivado de, o intentar vender o de otra manera transferir cualquier derecho en el Software (ii) desensamblar, descompilar o aplicar ingeniería inversa a cualquier parte del Software por cualquier medio; ni permitir o ayudar a ninguna parte a derivar o intentar derivar el código fuente de, desensamblar, descifrar, descompilar o aplicar ingeniería inversa al Software; ni tomar ninguna otra medida para derivar información de diseño con respecto al Software; (iii) usar el Software para cualquier propósito que no sea el propósito para el que se le distribuyó originalmente; (iv) usar arañas, robots u otras técnicas automatizadas de minería de datos para catalogar, descargar, almacenar o de otra manera reproducir o distribuir el Software, o para manipular los resultados de cualquier encuesta o cualquier otra actividad; (v) realizar cualquier acción que interfiera con el Software o el uso individual del Software, incluyendo, sin limitación, sobrecargar, 'inundar', 'bombardear con correo' o 'bloquear' el Software y el sistema de almacenamiento de datos que el Software utiliza a través de Amazon Web Services (vi) enviarnos cualquier código ilegal, engañoso o dañino, incluyendo, sin limitación, cualquier virus, spyware, adware o cualquier otro código dañino.",
    "app.terms.line12": "Todos los derechos no expresamente otorgados a usted en virtud del presente documento quedan reservados para nosotros y nuestros licenciantes. Usted acepta que no tenemos ninguna obligación, en virtud del presente Acuerdo, de corregir errores, defectos o fallos del Software, ni de brindar soporte, mantenimiento, mejora, modificación, actualización o perfeccionamiento del Software. No seremos responsables de brindarle asistencia a usted ni a las personas que usted designe en el uso del Software de ninguna manera.",
    "app.terms.line13": "No nos hacemos responsables de ningún daño a su PC, incluyendo, entre otros, los resultantes del uso del Software. No nos hacemos responsables de ningún cargo adicional por datos en el que incurra como resultado de los datos recopilados por el Software a través de su participación en la investigación",
    "app.terms.line14": "5. Descargo de responsabilidad ",
    "app.terms.line15": "Nosotros y nuestros respectivos directores, funcionarios, empleados, agentes, representantes, licenciatarios y proveedores no realizamos ninguna declaración, garantía ni condición respecto a (a) que el software esté disponible de forma oportuna ni que el acceso al software sea ininterrumpido o seguro; (b) que los defectos o errores se corrijan; ni (c) que el software, los servidores o las redes a través de los cuales se proporciona el software y el dispositivo sean seguros o estén libres de virus u otros componentes dañinos. No tendremos obligación de proporcionar ningún servicio de mantenimiento o soporte técnico con respecto al software.",
    "app.terms.line16": "6. Limitación de responsabilidad ",
    "app.terms.line17": "(a) En la máxima medida permitida por la ley aplicable, usted acepta que nosotros y nuestros respectivos directores, funcionarios, empleados, agentes, representantes, licenciatarios y proveedores no tendremos ninguna responsabilidad por ninguna o todas las pérdidas, daños o costos de ningún tipo que resulten de su instalación, uso o acceso al software, su uso o incapacidad de usar el software, cualquier información o materiales disponibles en o a través del software y/o de otra manera relacionados con el software o de otra manera con este acuerdo, independientemente de la naturaleza del derecho legal, equitativo o estatutario que se reclama como violado.",
    "app.terms.line18": "(b) En la máxima medida permitida por la ley aplicable, su único y exclusivo recurso en caso de insatisfacción con el software es dejar de usarlo.",
    "app.terms.line19": "(c) En ningún caso, ninguna de nuestras entidades, directores, funcionarios, empleados, agentes o representantes serán responsables ante usted por daños incidentales, indirectos, consecuentes, legales, ejemplares, punitivos o especiales, pérdida de ingresos, pérdida de oportunidades, pérdida de reputación, pérdida de uso, pérdida de datos, pérdida de otros intangibles o interceptación no autorizada de datos recopilados por el software por parte de terceros, ya sean previsibles o no, independientemente de la causa y bajo cualquier contrato, agravio (incluida, entre otras, negligencia), responsabilidad estricta u otra teoría, incluso si se le advierte de antemano de la posibilidad de que surjan dichos daños.",
    "demo.index.content.line1": "Durante esta demostración, usaremos tu cámara para calcular tu ubicación. No te preocupes, no tomaremos fotos ni videos..",
    "app.error.previousfail": " Has reprobado este estudio anteriormente, por favor utiliza el enlace original para comenzar desde el principio..",
    "app.error.previoussuccess": "Ya ha completado este estudio.",
    "app.error.route": " Has intentado iniciar un estudio ya en curso, por favor utiliza el enlace original para empezar desde el principio.",
    "app.error.accesscode": "Has intentado iniciar un estudio que no existe, por favor revisa tu enlace.",
    "app.error.webcam": "Este estudio requiere acceso a una cámara web. ",
    "app.error.adblocker": "Este estudio requiere que el bloqueador de anuncios esté deshabilitado.",
    "app.error.startsession": "No se puede iniciar sesión ",
    "app.error.fullscreen": "Este estudio requiere una vista de pantalla completa.",
    "app.error.zoom": "Este estudio requiere que el zoom del navegador esté configurado al 100%.",
    "app.index.webcamInstructions": "Estamos a punto de solicitar acceso a su cámara web, si recibe una ventana emergente, haga clic en permitir.",
    "app.setup.retryBack": " No presione el botón de retroceso del navegador durante este estudio, ya que podría perder su recompensa. Use el botón de abajo para reintentar.",
    "app.setup.retryaudible": "Asegúrese de que su dispositivo no esté silenciado y vuelva a intentarlo.",
    "app.restore.retry": "No has dedicado suficiente tiempo al paso anterior, ",
    "app.restore.timeremaining": " minutos restantes",
    "app.error.postexposure": " Lo sentimos, ya has agotado todos tus intentos y, lamentablemente, no puedes continuar con el estudio."
}