import { store } from "../../../../store";
import Endpoint from "../../Endpoint";

const getPayload = () => {
  return { panelist: store.getState().studyReducer.participant };
};

const handleResponse = (response) => {

  if (response && response.id) {
    store.dispatch({
      type: "SESSION_ID_UPDATE",
      payload: { sessionId: response.id },
    });
    response.success  = true
  } else if (response && response.tmp_session_id) {
    store.dispatch({
      type: "SESSION_ID_UPDATE",
      payload: { tmpSessionId: response.tmp_session_id },
    });
    response.success = true
  }

  return response
}

export default () => {
  if (store.getState().studyReducer.newTrackingSystem) {
    return new Endpoint(
      "session",
      `${process.env.REACT_APP_NEW_TRACKING_URL}/ctt-session`,
      "POST",
      getPayload,
      handleResponse,
      true
    );
  }

  return new Endpoint(
    "session",
    `${process.env.REACT_APP_NEW_TRACKING_URL}/new-session`,
    "POST",
    getPayload,
    handleResponse,
    true
  );
};
