{
  "title": "﻿Lumen Study Platform - Personvernerklæring",
  "date": "Juli 2022",
  "contactDetails": {
    "title": "Vår kontaktinformasjon",
    "body": [
      "Navn: Lumen Research Ltd (“Lumen”)",
      "Adresse: Screenworks, Unit 215, 22 Highbury Grove, London N5 2ER",
      "Telefonnummer: +44(0)2037355199 ",
      "E-mail: dataprotection@lumen-research.com",
      "ICO registreringsnummer: ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "Denne personvernerklæringen forklarer hvilken informasjon vi samler inn fra deltakere i eye tracking-undersøkelser på Lumen Study Platform, og våre retningslinjer for personlig informasjon som samles inn. "
      ],
      "heading": "Innledning"
    },
    {
      "body": [
        "I en typisk undersøkelse samler ikke Lumen inn personlig informasjon som er definert i henhold til den britiske generelle databeskyttelsesforordningen, men samler inn estimater av blikkpunktsestimater, undersøkelsessvar, metadata om, når og på hvilken type enhet datainnsamlingen fant sted, og respondent-id'er for administrasjon av belønninger.",
        "I en typisk undersøkelse vil du bli bedt om å sitte foran din enhets webcam eller frontkamera og foreta en kalibrering, slik at det kan foretas en vurdering av hvor du kikker på skjermen. Under undersøkelsen vil du bli bedt om å se på noen elementer (f.eks. bilder, websteder eller videoer), mens systemet kartlegger hvor du ser. Dataen som innsamles fra denne \"eye tracking\" er koordinatene for de estimerte blikkpunktene på din skjerm over tid. Systemet bruker bilder av deg fra ditt webkamera for å foreta disse estimatene, men tar ikke opp bilder eller videoer av deg. ",
        "Du kan også bli stilt noen spørsmål om deg selv, din opplevelse og hva du har sett i løpet av undersøkelsen. ",
        "Dataene sendes til Lumen Research Ltd (\"Lumen\"). Lumen vil analysere dataene og dele resultatene med sine kunder. ",
        "I disse undersøkelsene vil et alfanumerisk respondent-id også bli gitt videre til en tredjepart til Lumen, slik at Lumen kan spore hvilke respondenter som gjennomførte undersøkelsen. Lumen vil dele denne informasjonen med tredjeparter med henblikk på å belønne deltakerne. "
      ],
      "heading": "Typen informasjon vi samler inn"
    },
    {
      "body": [
        "Respondentene kan bli bedt om å delta i forskningsprosjekter som krever mer enn én periode med datainnsamling.  I så fall vil respondent-id'et bli brukt som en vedvarende identifikator for å kontakte en deltaker igjen for at få vedkommende til å delta igjen. Respondent-ID'et bli da betraktet som personlig informasjon.  "
      ],
      "heading": "Typen av personlig informasjon som vi samler inn"
    },
    {
      "body": [
        "I en typisk undersøkelse vil det vedvarende respondent-id bli gitt videre til oss fra den tredjepart som har rekruttert en deltager til vår forskning.  Vi vil bruke dette til å kontakte enkeltpersoner igjen for ny deltagelse, og vi kan dele dette med tredjeparter for at gjøre en slik deltakelse lettere.",
        "I henhold til den britiske generelle databeskyttelsesforordning (UK GDPR) er ditt samtykke det lovlige grunnlag vi baserer oss på for behandling av denne informasjonen.  Du kan til enhver tid trekke ditt samtykke tilbake.  Det kan du gjøre ved å kontakte oss på dataprotection@lumen-research.com"
      ],
      "heading": "Hvordan vi samler personlig informasjon og hvorfor vi har den"
    },
    {
      "body": [
        "Din informasjon oppbevares sikkert og krypteres ved hjelp av SSL-teknologi. Dette er den mest alminnelig anvendte standard for krypteringsteknologi og brukes av banker for å sikre banktransaksjoner og annen sensitive kommunikasjon. Den brukes også av Amazon, PayPal, eBay og andre websteder som krever sikkerhetssystemer på banknivå. Forskere anslår at det vil kreve betydelig mere tid enn universets alder for å bryte 256 bit-krypteringen med den nåværende teknologi. De innsamlede data lagres på sikre webservere som forvaltes av Amazon Web Services (AWS). AWS er en av de mest anvendte sikre og pålitelige plattformer for cloud computing-tjenester og anvendes av store cloud-baserte kommersielle tjenester, som for eksempel Netflix og Expedia. ",
        "Vi oppbevarer vedvarende identifikatorer i den periode som er nødvendig for å kontakte respondentene igjen i forbindelse med en undersøkelse i flere faser.  Dette kan variere avhengig av forskningens art og vil bli meddelt når du gir ditt samtykke til å delta.  Når datainnsamlingen til et slikt prosjekt er avsluttet, vil identifikatorene kun bli oppbevart i det omfang som er nødvendig for at analysere og rapportere dataene.  Når analysen og rapporteringen av et prosjekt er avsluttet, vil vi slette denne informasjonen fra våre databaser."
      ],
      "heading": "Hvordan vi oppbevarer din personlig informasjon"
    }
  ],
  "dataProtection": {
    "body": [
      "I henhold til databeskyttelseslovgivningen har du følgende rettigheter:"
    ],
    "bullets": [
      {
        "title": "Din rett til adgang",
        "text": [
          "Du har rett til å be oss om å få kopier av din personlige informasjon. "
        ]
      },
      {
        "title": "Din rett til beriktigelse",
        "text": [
          "Du har rett til å be oss om å beriktige personlig informasjon som du mener er unøyaktig. Du har også rett til at be oss om å supplere informasjon som du mener er ufullstendige."
        ]
      },
      {
        "title": "Din rett til sletning",
        "text": [
          "Du har rett til å be oss om å slette din personlige informasjon under visse omstendigheter."
        ]
      },
      {
        "title": "Din rett til begrensning av behandlingen",
        "text": [
          "Du har rett til å be oss om å begrense behandlingen av din personlige informasjon under visse omstendigheter."
        ]
      },
      {
        "title": "Din rett til å trekke ditt samtykke tilbake",
        "text": [
          "Du har rett til å trekke tilbake ditt samtykke til behandling av din personlige informasjon."
        ]
      },
      {
        "title": "Din rett til dataportabilitet",
        "text": [
          "Du har rett til å anmode om at vi overfører den personlige informasjonen du har gitt oss til en annen organisasjon eller til deg selv under visse omstendigheter.",
          "Du er ikke forpliktet til å betale for å utøve dine rettigheter. Hvis du fremsetter en anmodning har vi en måned på å svare deg.",
          "Du bes kontakte oss på dataprotection@lumen-research.com hvis du ønsker å fremsette en anmodning."
        ]
      }
    ],
    "title": "Dine databeskyttelsesrettigheter"
  },
  "complaints": {
    "body": [
      "Hvis du er bekymret over vår bruk av din personlige informasjon kan du sende en klage til oss på dataprotection@lumen-research.com.",
      "Du kan også klage til Information Commissioner’s Office (ICO) hvis du er utilfreds med måten vi har brukt dine opplysninger på."
    ],
    "address": {
      "body": [
        "Information Commissioner’s Office",
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF",
        "United Kingdom"
      ],
      "title": "ICO's adresse:"
    },
    "title": "Slik klager du",
    "number": "Hjelpelinjenummer: 0303 123 1113",
    "website": "ICO hjemmeside: https://www.ico.org.uk"
  }
}
