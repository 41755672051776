{
    "app.thankyou":"Cảm ơn",
    "app.button.faqs": "Chính sách bảo mật / FAQs",
    "app.button.terms": "Điều khoản sử dụng",
    "app.button.privacy": " Chính sách bảo mật ",
    "app.button.feedback": "Phản hồi",
    "app.button.selectLanguage": "Ngôn ngữ",
    "app.button.help": "Hỗ trợ",
    "app.button.start": "Bắt đầu",
    "app.button.next": "Tiếp",
    "app.button.submit": "Nộp",
    "app.button.close": "Tắt",
    "app.button.return": "Quay lại",
    "app.button.takepart": "OK – Tôi sẽ tham gia",
    "app.menu.feedback.content": " Nếu bạn gặp sự cố với nghiên cứu hoặc chỉ muốn để lại nhận xét, vui lòng gửi tại đây.",
    "app.contactModal.feedback": "Cảm ơn phản hồi của bạn!",
    "app.takePartModal.line1": " Nếu bạn rời đi bây giờ, bạn sẽ không đủ điều kiện nhận bất kỳ phần thưởng tham gia nào.",
    "app.takePartModal.line2": "Những gì bạn cần là một chiếc webcam!",
    "app.takePartModal.reason1": "Tôi không có webcam.",
    "app.takePartModal.reason2": " Tôi không thoải mái với việc webcam của mình được sử dụng cho cuộc khảo sát này.",
    "app.takePartModal.reason3": " Tôi không có thời gian cho việc này.",
    "app.takePartModal.reason4": "Lí do khác.",
    "app.generic.redirect": " Bạn sẽ sớm được chuyển hướng.",
    "app.init.welcome": " Chào mừng bạn đến với nền tảng nghiên cứu Lumen Research ",
    "app.init.intro": " Nền tảng nghiên cứu cho phép chúng tôi giới thiệu và thiết lập người dùng cho một nghiên cứu quảng cáo nhất định.",
    "app.init.message1": " Tùy thuộc vào loại nghiên cứu, người dùng sẽ được hướng dẫn thực hiện một loạt nhiệm vụ.",
    "app.init.message2": " Những nhiệm vụ này bao gồm; thực hiện một cuộc khảo sát, đọc qua một bộ hướng dẫn và thực hiện hiệu chuẩn theo dõi mắt ",
    "app.init.message3": " Chúng tôi sẽ hướng dẫn bạn qua một nghiên cứu demo.",
    "app.init.message4": " Trong suốt quá trình nghiên cứu này, chúng tôi có thể cho phép webcam của bạn thực hiện theo dõi ánh mắt, nhưng sẽ không có dữ liệu nào của bạn được ghi lại.",
    "app.init.message5": " Nghiên cứu hiện tại được thiết lập để sử dụng các liên kết sau khi sàng lọc/hoàn thành.",
    "app.prestart.loading": "Đang tải, vui lòng chờ.",
    "app.index.title": "Chào mừng",
    "app.index.content.line1": " Trong cuộc khảo sát này, chúng tôi sẽ sử dụng máy ảnh của bạn để ước tính nơi bạn đang tìm kiếm. Đừng lo, chúng tôi sẽ không chụp ảnh hay quay video đâu.",
    "app.index.content.line1alt": " Vui lòng đảm bảo rằng bạn đang ngồi thoải mái và sau đó nhấn Bắt đầu.",
    "app.index.content.line2": " Thiết bị của bạn có camera không? Nếu vậy, xin vui lòng nhấn nút Bắt đầu bên dưới.",
    "app.index.rejection": " Xin lỗi, có vẻ như bạn đã mở một liên kết không đúng định dạng.",
    "app.index.webview": " Xin lỗi, có vẻ như bạn đã truy cập trang của chúng tôi từ bên trong chế độ webview(chế độ xem web) . Vui lòng mở liên kết này bên trong trình duyệt của bạn. Điều này có thể được thực hiện trực tiếp từ menu webview(chế độ xem web).",
    "app.index.webview.chrome.1": " Nhấn vào nút menu ở trên, sau đó chọn 'Mở trong Chrome'.",
    "app.index.webview.chrome.2": " Thao tác này sẽ mở khảo sát này trong ứng dụng trình duyệt để khảo sát có thể hoạt động bình thường.",
    "app.index.webview.android.1": " Nhấn vào Bắt đầu bên dưới để mở khảo sát này trong ứng dụng trình duyệt để khảo sát có thể hoạt động bình thường.",
    "app.index.webview.android.2": " Nếu không thành công, hãy mở liên kết tới trang này trong ứng dụng trình duyệt theo cách thủ công (ví dụ: sao chép và dán liên kết vào thanh địa chỉ trong Chrome hoặc sử dụng tùy chọn như 'Mở trong Chrome' trong thanh menu ở trên).",
    "app.index.webview.safari.1": " Nhấn vào biểu tượng Safari ở cuối màn hình để tiếp tục.",
    "app.index.webview.safari.2": " Nó trông như thế này:",
    "app.index.webview.safari.3": " Thao tác này sẽ mở khảo sát trong Safari để khảo sát có thể hoạt động bình thường.",
    "app.index.wifi": "Có vẻ như kết nối internet của bạn quá chậm. Bạn có kết nối với wifi không?",
    "app.index.tellmemore": "Cho tôi biết thêm.",
    "app.index.takepart": "Tôi không muốn tham gia.",
    "app.index.webcamRetry": "Chúng tôi không thể phát hiện webcam, thứ cần thiết cho nghiên cứu này. Vui lòng cung cấp quyền truy cập vào webcam của bạn và thử lại.",
    "app.index.adblockRetry": "Chúng tôi không thể phát hiện webcam, thứ cần thiết cho nghiên cứu này. Vui lòng cung cấp quyền truy cập vào webcam của bạn và thử lại.",
    "app.index.zoomRetry": "Chúng tôi yêu cầu trình duyệt được đặt ở mức thu phóng 100%, vui lòng phóng to/thu nhỏ và thử lại",
    "app.index.fullscreendesktopretry": "Nghiên cứu này yêu cầu chế độ xem toàn màn hình, vui lòng đảm bảo cửa sổ trình duyệt của bạn được phóng to",
    "app.index.fullscreendesktoptest":"Nghiên cứu này yêu cầu cửa sổ trình duyệt của bạn ở chế độ toàn màn hình, chúng tôi cần thực hiện kiểm tra để đảm bảo cửa sổ trình duyệt của bạn được mở rộng tối đa",
    "app.index.fullscreendesktoptest2":"Hai nút sẽ xuất hiện trong thời gian ngắn, vui lòng nhấp vào chúng để tiếp tục",
    "app.index.fullscreendesktopmaximised":"Màn hình của tôi đã được phóng to",
    "app.index.fullscreendesktopskip":"Cảm ơn bạn, chúng tôi đã ghi lại trường hợp này và sẽ cải thiện quy trình phát hiện của chúng tôi.",
    "app.index.fullscreendesktopreset":"Chúng tôi đã phát hiện sự thay đổi về kích thước cửa sổ, đặt lại kiểm tra.",
    "app.index.gotomobile":"Người dùng máy tính để bàn không đủ điều kiện cho nghiên cứu này, vui lòng sử dụng mã QR bên dưới để mở nghiên cứu trên thiết bị di động.",
    "app.index.returntopanel":"Hoặc, Nhấp vào nút bên dưới để quay lại bảng điều khiển",
    "app.instructions.title": "Hướng dẫn",
    "app.instructions.instruction1": "Nếu có thể, vui lòng không đeo kính trong quá trình khảo sát.",
    "app.instructions.instruction2": "Xin hãy ngồi sao cho bạn có thể giữ yên đầu và thiết bị một cách thoải mái.",
    "app.instructions.instruction3": "Xin hãy di chuyển mắt của bạn, không phải đầu.",
    "app.instructions.instruction4": "Bạn đã sẵn sàng để bắt đầu chưa?",
    "app.instructions.instruction5": "Vui lòng sử dụng wifi để tránh tốn phí dữ liệu.",
    "app.instructions.instruction6": "Xin vui lòng đọc các hướng dẫn sau đây một cách cẩn thận.",
    "app.instructions.instruction7": "Đặt điện thoại của bạn trên một bề mặt ổn định.",
    "app.instructions.instruction8": "Hãy chắc chắn rằng căn phòng của bạn đủ độ sáng - tốt nhất là từ phía trên (không phải phía sau).",
    "app.setup.title": "Cài đặt",
    "app.setup.instruction0": "Giữ thiết bị của bạn ở chế độ dọc.",
    "app.setup.instruction0a": "Vui lòng xoay thiết bị của bạn thành dọc.",
    "app.setup.instruction0b": "Vui lòng xoay thiết bị của bạn để camera ở bên trái.",
    "app.setup.instruction0c": "Vui lòng xoay thiết bị của bạn để camera ở bên phải.",
    "app.setup.instruction0d": "Vui lòng xoay thiết bị của bạn để được nằm ngang.",
    "app.setup.instructioncamera": "Vui lòng nhấp vào nút bên dưới để yêu cầu truy cập máy ảnh. Để hoàn thành khảo sát, bạn cần cấp quyền truy cập máy ảnh trong suốt quá trình nghiên cứu.",
    "app.setup.cameraText": "Yêu cầu quyền truy cập",
    "app.setup.instruction1": "Đang khởi tạo...",
    "app.setup.instruction2": "Khi bạn bị phát hiện, hãy đặt đầu của bạn vào trong hộp.",
    "app.setup.instruction3": "Giữ nguyên vị trí đầu và thiết bị của bạn cho đến khi khảo sát hoàn tất.",
    "app.setup.instruction3audio":"Vui lòng lắng nghe cẩn thận và làm theo hướng dẫn hiện ra trên màn hình.",
    "app.setup.instruction4": "Bây giờ chúng ta chỉ cần xác nhận.",
    "app.setup.instruction5": "Xin cảm ơn! Hãy nhớ giữ nguyên đầu và thiết bị của bạn ở cùng một vị trí cho đến khi khảo sát hoàn tất.",
    "app.setup.instruction6": "Vui lòng vuốt lên để ẩn thanh địa chỉ.",
    "app.setup.retry": "Đã phát hiện lỗi. Vui lòng giữ cố định đầu và thiết bị của bạn và không dùng ngón tay che máy ảnh. Sử dụng nút bên dưới để thử lại.",
    "app.setup.retryPositioning": "Chúng tôi không thể xác định vị trí đầu của bạn. Vui lòng giữ cố định đầu và thiết bị của bạn và không dùng ngón tay che máy ảnh. Sử dụng nút bên dưới để thử lại.",
    "app.setup.retryInit": "Đã phát hiện lỗi. Vui lòng đảm bảo rằng trình duyệt có thể truy cập máy ảnh của bạn. Nếu trước đây bạn đã từ chối quyền truy cập máy ảnh, bạn sẽ cần phải chấp nhận điều này trong cài đặt quyền riêng tư của trình duyệt. Sử dụng nút bên dưới để thử lại.",
    "app.setup.retryBroker": "Chúng tôi không thể hoàn thành quá trình hiệu chuẩn. Vui lòng thử đóng mọi ứng dụng và tab không sử dụng để giải phóng tài nguyên",
    "app.setup.retryButton": "Thử lại",
    "app.setup.calibrationProp.watch": "Theo dõi dấu chấm khi nó xuất hiện trên màn hình",
    "app.setup.calibrationProp.watch.george": "Tiếp theo, khi bạn nhìn thấy một khuôn mặt, hãy tiếp tục nhìn vào khuôn mặt đó trong khi phản chiếu chuyển động đầu của nó",
    "app.setup.calibrationProp.click": "Nhấp vào bất cứ đâu để tiếp tục",
    "app.setup.calibrationProp.tap": "Nhấn vào bất cứ đâu để tiếp tục",
    "app.study.validationProp.watch": "Theo dõi dấu chấm khi nó xuất hiện trên màn hình",
    "app.study.validationProp.restart": "Vui lòng thử lại",
    "app.study.validationProp.click": "Nhấp vào bất cứ đâu để tiếp tục",
    "app.study.validationProp.tap": "Nhấn vào bất cứ đâu để tiếp tục",
    "app.study.render.error": "Tên miền không hợp lệ cho bước kết xuất.",
    "app.error.title": "Lỗi",
    "app.error.generic": "Ối! Đã xảy ra sự cố.",
    "app.error.initialise": "Ối! Đã xảy ra sự cố!",
    "app.error.findCamera": "Không thể tìm thấy máy ảnh!",
    "app.error.headPosition": "Không thể xác định vị trí đầu của bạn.",
    "app.error.calibrate": "Không thể hiệu chỉnh.",
    "app.error.validate": "Không thể xác thực.",
    "app.error.start": "Không thể khởi động.",
    "app.error.assign": "Không thể chỉ định.",
    "app.error.osnotallowed": "Sàng lọc: Hệ điều hành của bạn không được phép, vui lòng kiểm tra xem bạn có thể sử dụng thiết bị nào cho khảo sát này và thử lại.",
    "app.error.demoquotafullnoactivecell": "Hạn ngạch đầy đủ: Cám ơn sự quan tâm của bạn. Nghiên cứu này hiện đã đóng đối với nhân khẩu học của bạn.",
    "app.error.missingpanelparameter": "Thiếu tham số bảng điều khiển.",
    "app.invalid.title": "Không hợp lệ",
    "app.invalid.generic": "Xin lỗi, bạn không đủ điều kiện cho nghiên cứu này.",
    "app.completion.title": "Hoàn thành",
    "app.completion.congratulations": "Chúc mừng! Bạn đã hoàn thành nghiên cứu. Quyền truy cập của cuộc khảo sát này vào máy ảnh của bạn bây giờ sẽ bị chấm dứt.",
    "app.completion.congratulations-noet": "Chúc mừng! Bạn đã hoàn thành nghiên cứu.",
    "app.questions.required": "Câu hỏi này là bắt buộc.",
    "app.faq.question1": "Câu hỏi: Nền tảng nghiên cứu Lumen là gì và việc tham gia cuộc khảo sát theo dõi mắt liên quan đến điều gì?",
    "app.faq.answer1": "Trả lời: Cuộc khảo sát này liên quan đến việc sử dụng công nghệ theo dõi ánh mắt. Bạn sẽ được yêu cầu ngồi trước webcam hoặc máy ảnh mặt trước của thiết bị và thực hiện hiệu chỉnh để có thể ước tính vị trí bạn đang nhìn trên màn hình. Trong quá trình khảo sát, bạn sẽ được yêu cầu xem một số mục (ví dụ: hình ảnh, trang web hoặc video) trong khi công cụ khảo sát ước tính nơi bạn đang tìm kiếm. Dữ liệu thu được từ tính năng 'theo dõi ánh mắt' này là tọa độ của các điểm nhìn ước tính trên màn hình của bạn theo thời gian. Phần mềm sử dụng hình ảnh của bạn từ webcam để thực hiện các ước tính này nhưng không chụp ảnh hoặc quay video về bạn. Bạn cũng có thể được hỏi một số câu hỏi về bản thân, kinh nghiệm của bạn và những mục bạn đã thấy trong cuộc khảo sát. Dữ liệu được gửi đến Lumen Research Ltd. Lumen Research sẽ phân tích dữ liệu và chia sẻ kết quả với khách hàng của mình.",
    "app.faq.question2": "Câu hỏi: Tại sao phần mềm yêu cầu phải có webcam/máy ảnh và nó được sử dụng như thế nào?",
    "app.faq.answer2": "Trả lời: Phần mềm sử dụng hình ảnh từ webcam của bạn hoặc camera của thiết bị để ước tính vị trí bạn đang nhìn trên màn hình. Nó thực hiện điều này bằng cách sử dụng kết hợp phần mềm theo dõi khuôn mặt và theo dõi mắt để phân tích hình ảnh cục bộ trên máy của bạn trong thời gian thực. Những hình ảnh này được xử lý và hiển thị cho bạn trong quá trình hiệu chỉnh để bạn có thể định vị chính xác nhưng hình ảnh không được ghi lại để bảo vệ quyền riêng tư của bạn. Dữ liệu được ghi lại và thu thập từ theo dõi mắt không phải là hình ảnh mà là dữ liệu số về các điểm nhìn ước tính trên kích thích theo thời gian.",
    "app.faq.question3": "Câu hỏi: Tôi có bị chụp ảnh trong khi theo dõi mắt không?",
    "app.faq.answer3": "Trả lời: Hình ảnh chỉ được sử dụng khi cho phép bạn định vị chính xác bản thân trong quá trình hiệu chuẩn. Những hình ảnh này không được lưu và chỉ bạn nhìn thấy. Dữ liệu được ghi lại và thu thập từ theo dõi mắt không phải là hình ảnh mà là dữ liệu số về các điểm nhìn ước tính trên kích thích theo thời gian.",
    "app.faq.question4": "Câu hỏi: Dữ liệu nào được thu thập trong quá trình khảo sát?",
    "app.faq.answer4.line1": "Trả lời: Phần mềm sẽ thu thập một số hoặc tất cả năm loại dữ liệu sau:",
    "app.faq.answer4.line2": "“ID người dùng” - ID người dùng được sử dụng để xác định người trả lời (với mục đích quản lý phần thưởng tham gia);",
    "app.faq.answer4.line3": "“Dữ liệu về cái nhìn” – Ước tính nơi người dùng đang nhìn trên màn hình của họ khi các tác nhân kích thích được hiển thị trên màn hình",
    "app.faq.answer4.line4": "“Dữ liệu khảo sát” – Phản hồi các câu hỏi do người dùng trả lời",
    "app.faq.answer4.line5": "“Siêu dữ liệu” - Dữ liệu về thời gian mà các cá nhân tham gia thành công vào Nghiên cứu.",
    "app.faq.answer4.line6": "“Dữ liệu thiết bị vật lý” – Dữ liệu về máy tính mà người dùng tham gia, chẳng hạn như mức RAM, loại CPU trong máy và tác nhân người dùng (hệ điều hành và phiên bản trình duyệt) cũng như thông tin về màn hình của máy tính (kích thước và độ phân giải), webcam (tạo và độ phân giải) và các chương trình chống vi-rút.",
    "app.faq.question5": "Hỏi: Dữ liệu được thu thập sẽ được sử dụng như thế nào?",
    "app.faq.answer5.line1": "Trả lời: Dữ liệu được thu thập sẽ được sử dụng để khám phá cách các cá nhân xem và tương tác với các loại hình ảnh và phương tiện trực quan khác nhau cũng như để đánh giá ý kiến ​​của mọi người về những hình ảnh mà họ được hiển thị.",
    "app.faq.answer5.line2": "Lumen có thể chia sẻ dữ liệu nghiên cứu với các công ty liên kết cũng như với các đối tác kinh doanh và khách hàng. Mặc dù dữ liệu nghiên cứu có thể được chia sẻ với các chi nhánh, đối tác và khách hàng, nhưng điều này thường sẽ được thực hiện trên cơ sở ẩn danh. Các chi nhánh, đối tác và khách hàng như vậy có thể sử dụng dữ liệu này để tạo các mô tả tóm tắt về một loạt các hành vi của người tiêu dùng và phân khúc người tiêu dùng để phát triển sản phẩm hoặc cung cấp giải pháp tiếp thị cho khách hàng hoặc người tiêu dùng của họ.",
    "app.faq.answer5.line3": "Lumen thu thập \"Dữ liệu thiết bị vật lý\" và \"Siêu dữ liệu\" để giúp quản lý việc thu thập và xử lý dữ liệu, bao gồm kiểm soát chất lượng và quản lý khối lượng.",
    "app.faq.answer5.line4": "The data collected will be retained for the period necessary to fulfill the purposes outlined.",
    "app.faq.question6": "Hỏi: Dữ liệu của tôi an toàn đến mức nào?",
    "app.faq.answer6": "Trả lời: Dữ liệu do Lumen thu thập được mã hóa bằng công nghệ SSL. Đây là tiêu chuẩn công nghệ mã hóa cao được sử dụng phổ biến nhất và được các ngân hàng sử dụng để bảo mật các giao dịch ngân hàng và các thông tin liên lạc có độ nhạy cao khác. Nó cũng được sử dụng bởi Amazon, PayPal, eBay và các trang web khác yêu cầu hệ thống bảo mật cấp ngân hàng. Các nhà khoa học ước tính rằng việc phá mã hóa 256 bit bằng công nghệ hiện tại sẽ cần nhiều thời gian hơn đáng kể so với tuổi của vũ trụ. Dữ liệu đã thu thập được lưu trữ trên các máy chủ web an toàn do Amazon Web Services (AWS) quản lý. AWS là một trong những nền tảng dịch vụ điện toán đám mây an toàn và đáng tin cậy được sử dụng phổ biến nhất và được sử dụng bởi các dịch vụ thương mại dựa trên đám mây lớn bao gồm Netflix và Expedia.",
    "app.faq.question7": "Hỏi: Bạn có những nghĩa vụ gì liên quan đến Nội dung mà bạn được hiển thị?",
    "app.faq.answer7": "Trả lời: Khi tham gia, bạn cũng hiểu và đồng ý rằng Nội dung mà bạn được hiển thị trong nghiên cứu này có thể là bí mật, tài sản của bên thứ ba và/hoặc tuân theo luật nhãn hiệu hoặc bản quyền và rằng bạn sẽ không chụp ảnh màn hình, ghi lại hoặc sao chép theo cách khác bất kỳ Nội dung nào hoặc phân phối lại bất kỳ Nội dung nào của nghiên cứu theo bất kỳ cách nào.",
    "app.faq.question8": "Hỏi: Tôi không chắc webcam của mình tốt đến mức nào, nó có hoạt động để theo dõi bằng mắt không?",
    "app.faq.answer8": "Trả lời: Hệ thống hoạt động với nhiều loại webcam – đó không phải là vấn đề.",
    "app.faq.question9": "Hỏi: Tôi đã học xong, tôi có cần làm gì nữa không?",
    "app.faq.answer9": "Trả lời: Không, không cần thiết phải làm gì khác. Phần mềm sẽ tự động tắt.",
    "app.faq.question10": "Hỏi: Tôi có cần kết nối internet để tham gia không?",
    "app.faq.answer10": "Trả lời: Có, bạn sẽ cần kết nối internet để tải xuống chương trình và hoàn thành nhiệm vụ.",
    "app.faq.question11": "Hỏi: Tôi có cần hoàn thành nhiệm vụ trên một bề mặt ổn định không?",
    "app.faq.answer11": "Trả lời: Có, để có kết quả tốt nhất, bạn nên ngồi thoải mái trước màn hình máy tính và sau khi hiệu chỉnh, hãy giữ yên đầu trong khi tiếp xúc với kích thích, cho đến khi được thông báo. Chúng tôi khuyên bạn không nên tham gia nếu bạn đang đi tàu hoặc đặt máy tính xách tay của bạn trên đùi, v.v.",
    "app.faq.question12": "Câu hỏi: Câu hỏi thường gặp không giải quyết được vấn đề của tôi, tôi phải làm gì bây giờ?",
    "app.faq.answer12": "Trả lời: Vui lòng gửi email cho chúng tôi theo địa chỉ study-feedback@lumen-research.com và chúng tôi sẽ liên hệ lại với bạn nhanh nhất có thể.",
    "app.faq.patent": "Thử nghiệm nội dung web của Lumen được hỗ trợ bởi công nghệ thử nghiệm trong ngữ cảnh độc quyền của riêng chúng tôi (Số đăng ký bằng sáng chế của Vương quốc Anh 1816158.8)",
    "app.terms.line1": "Các điều khoản sử dụng này (“Điều khoản sử dụng”) chi phối thỏa thuận giữa Bạn và Lumen Research Ltd (“Lumen” hoặc “Chúng tôi”), một công ty được đăng ký tại Anh, mã số công ty 8682432 có văn phòng đăng ký tại Unit 215, 22 Highbury Grove, London N5 2EF và tham chiếu đăng ký bảo vệ dữ liệu ZA130772, liên quan đến việc bạn sử dụng phần mềm theo dõi mắt “ViewPoints” (“Phần mềm”) trên thiết bị của bạn.",
    "app.terms.line2": "1. Thỏa thuận các điều khoản",
    "app.terms.line3": "Bằng cách sử dụng Phần mềm, bạn thừa nhận rằng bạn đã đồng ý bị ràng buộc bởi Thỏa thuận này. Nếu bạn không đồng ý với các điều khoản và điều kiện của Thỏa thuận này, xin đừng hoàn thành khảo sát ViewPoints.",
    "app.terms.line4": "2. Thông tin bí mật",
    "app.terms.line5": "Khi cài đặt, sử dụng hoặc tương tác với ViewPoints theo bất kỳ cách nào, bạn có thể có quyền truy cập vào thông tin và/hoặc nội dung thuộc về hoặc liên quan đến Lumen, bao gồm cả thông tin được Lumen hoặc bất kỳ khách hàng nào của Lumen xác định hoặc coi là bí mật hoặc vì lý do đặc điểm hoặc hoàn cảnh hoặc cách thức tiết lộ của nó rõ ràng là bí mật bao gồm (không ảnh hưởng đến tính tổng quát ở trên) bất kỳ thông tin nào về kế hoạch kinh doanh, cơ hội kinh doanh mới, dự án nghiên cứu và phát triển, thiết kế, quy trình bí mật, mã, thiết kế phần mềm, bí mật thương mại , công thức và phát triển sản phẩm hoặc dịch vụ, bí quyết, phát minh, thống kê và dự báo bán hàng, chiến lược và kế hoạch tiếp thị, chi phí, lãi lỗ và thông tin tài chính khác (lưu trong phạm vi được công bố trong tài khoản đã kiểm toán), giá cả và cơ cấu chiết khấu (tất cả có hoặc không được ghi lại bằng văn bản hoặc ở định dạng điện tử hoặc định dạng khác) (“Thông tin mật”). Thông tin Bí mật đó sẽ vẫn là tài sản độc quyền và duy nhất của Lumen và/hoặc khách hàng của Lumen, và trong mọi trường hợp, bạn sẽ không có được hoặc nhận được bất kỳ quyền, tư cách và/hoặc lợi ích nào đối với Thông tin Bí mật đó.",
    "app.terms.line6": "Trong khi tham gia vào ViewPoints hoặc bất kỳ lúc nào (không giới hạn) sau khi chấm dứt sự tham gia đó, bạn không được: tiết lộ hoặc liên lạc với bất kỳ người nào; sử dụng cho mục đích riêng của bạn; hoặc thông qua bất kỳ thất bại nào thực hiện cẩn thận và siêng năng, gây ra bất kỳ tiết lộ trái phép nào đối với bất kỳ Thông tin bí mật nào. Bạn phải luôn cố gắng hết sức để ngăn chặn việc công bố hoặc tiết lộ bất kỳ Thông tin bí mật nào. Những hạn chế này sẽ không còn áp dụng cho bất kỳ thông tin nào sẽ có sẵn cho công khai nói chung khác với thông qua mặc định của bạn.",
    "app.terms.line7": "3. Thuộc tính Lumen",
    "app.terms.line8": "Phần mềm ViewPoints và tất cả dữ liệu, mã, khóa, mật khẩu, thiết kế, bản vẽ, hồ sơ và các tài liệu hoặc tư liệu khác liên quan đến Phần mềm sẽ và vẫn là tài sản của Lumen trong suốt thời gian bạn tham gia khảo sát ViewPoints. Lumen sở hữu tất cả các quyền sở hữu trí tuệ hoặc quyền sở hữu đối với bất kỳ và tất cả các quyền sở hữu trí tuệ hoặc công nghiệp hiện có và trong tương lai đối với và đối với bất kỳ tài liệu, vật liệu, mô hình, thiết kế, bản vẽ, quy trình, phát minh, công thức, mã hóa máy tính, dữ liệu, giao diện hệ thống, phương pháp luận, kiến ​​thức -cách thức, Thông tin bí mật hoặc công việc khác, được thực hiện, tạo ra, phát minh, phát triển, nâng cao, giải cấu trúc hoặc phát hiện bởi bạn hoặc một mình hoặc với bất kỳ người nào khác liên quan đến hoặc liên quan đến Phần mềm và/hoặc Thiết bị hoặc có khả năng được sử dụng hoặc được điều chỉnh để sử dụng trong đó hoặc liên quan đến nó (dù đã đăng ký hay chưa đăng ký), bao gồm tất cả các bằng sáng chế, bản quyền, quyền thiết kế, quyền cơ sở dữ liệu, nhãn hiệu thương mại, quyền địa hình bán dẫn, quyền giống cây trồng, quyền internet/tên miền, bí quyết và bất kỳ và tất cả các ứng dụng cho bất kỳ điều nào ở trên và bất kỳ và tất cả các quyền để áp dụng cho bất kỳ điều nào ở trên (“Quyền sở hữu”). Không có quyền sở hữu nào được chuyển cho bạn bất cứ lúc nào.",
    "app.terms.line9": "4. Điều khoản và hạn chế sử dụng",
    "app.terms.line10": "Phần mềm ViewPoints được cấp phép cho người dùng cuối. Theo Thỏa thuận này, chúng tôi đồng ý cho phép bạn, trên cơ sở không độc quyền, có thể hủy ngang, không thể chuyển nhượng, không thể cấp phép lại, có giới hạn, sử dụng Phần mềm ViewPoints trên thiết bị mà bạn sở hữu hoặc kiểm soát hoặc máy tính cá nhân với sự tôn trọng mà bạn đại diện và đảm bảo rằng bạn có tất cả các quyền và sự cho phép cần thiết đối với việc cài đặt đó. Để tránh nghi ngờ, bạn không được sử dụng cho bất kỳ mục đích nào ngoài ViewPoints, dịch, sao chép, sao chép, truyền tải bằng viễn thông, điều chỉnh, hợp nhất, thay đổi hoặc sửa đổi Phần mềm theo bất kỳ cách nào.",
    "app.terms.line11": "Bạn đồng ý không: (i) sao chép, sửa đổi, điều chỉnh, cung cấp, thuê, cho thuê, chia sẻ thời gian, tạo sản phẩm phái sinh hoặc cố gắng bán hoặc chuyển giao bất kỳ quyền nào trong Phần mềm (ii) tháo rời, biên dịch ngược hoặc đảo ngược thiết kế bất kỳ phần nào của Phần mềm bằng bất kỳ phương tiện nào; cũng không cho phép hoặc hỗ trợ bất kỳ bên nào lấy hoặc cố gắng lấy mã nguồn, phân tách, giải mã, dịch ngược hoặc thiết kế ngược Phần mềm; cũng như không thực hiện bất kỳ bước nào khác để lấy thông tin thiết kế liên quan đến Phần mềm; (iii) sử dụng Phần mềm cho bất kỳ mục đích nào khác với mục đích ban đầu mà Phần mềm được phân phối cho bạn; (iv) sử dụng trình thu thập dữ liệu, rô-bốt hoặc các kỹ thuật khai thác dữ liệu tự động khác để lập danh mục, tải xuống, lưu trữ hoặc sao chép hoặc phân phối Phần mềm hoặc để thao túng kết quả của bất kỳ khảo sát nào hoặc bất kỳ hoạt động nào khác; (v) thực hiện bất kỳ hành động nào để can thiệp vào Phần mềm hoặc việc sử dụng Phần mềm của một cá nhân, bao gồm, nhưng không giới hạn, bằng cách làm quá tải, “ngập lụt”, “đánh bom thư” hoặc “làm sập” Phần mềm và hệ thống lưu trữ dữ liệu mà Phần mềm sử dụng thông qua Amazon Dịch vụ web (vi) gửi cho chúng tôi bất kỳ mã bất hợp pháp, lừa đảo hoặc có hại nào, bao gồm nhưng không giới hạn ở bất kỳ vi rút, phần mềm gián điệp, phần mềm quảng cáo hoặc bất kỳ mã có hại nào khác.",
    "app.terms.line12": "Tất cả các quyền không được cấp rõ ràng cho bạn dưới đây được dành cho chúng tôi và người cấp phép của chúng tôi. Bạn đồng ý rằng chúng tôi không có nghĩa vụ theo Thỏa thuận này hoặc nói cách khác là sửa bất kỳ lỗi, khiếm khuyết hoặc lỗi nào trong Phần mềm hoặc hỗ trợ, duy trì, cải thiện, sửa đổi, nâng cấp, cập nhật hoặc nâng cao Phần mềm. Chúng tôi sẽ không chịu trách nhiệm cung cấp hỗ trợ cho bạn hoặc người được chỉ định của bạn trong việc sử dụng Phần mềm dưới bất kỳ hình thức nào.",
    "app.terms.line13": "Chúng tôi không chịu trách nhiệm cho bất kỳ thiệt hại nào đối với P.C. bao gồm nhưng không giới hạn do việc bạn sử dụng Phần mềm. Chúng tôi không chịu trách nhiệm pháp lý đối với bất kỳ khoản phí dữ liệu bổ sung nào mà bạn phải chịu do dữ liệu được Phần mềm thu thập thông qua việc bạn tham gia Nghiên cứu.",
    "app.terms.line14": "5. Tuyên bố miễn trừ trách nhiệm",
    "app.terms.line15": "Chúng tôi và các giám đốc, cán bộ, nhân viên, đại lý, đại diện, người cấp phép và nhà cung cấp tương ứng của chúng tôi không tuyên bố hoặc bảo đảm hoặc điều kiện (a) rằng phần mềm sẽ có sẵn kịp thời hoặc quyền truy cập vào phần mềm sẽ không bị gián đoạn hoặc an toàn; (b) các khiếm khuyết hoặc sai sót sẽ được sửa chữa; hoặc (c) rằng phần mềm hoặc máy chủ hoặc mạng mà qua đó phần mềm và thiết bị được cung cấp là an toàn hoặc không có vi-rút hoặc các thành phần gây hại khác. Chúng tôi không có nghĩa vụ cung cấp bất kỳ dịch vụ bảo trì và/hoặc hỗ trợ nào đối với phần mềm.",
    "app.terms.line16": "6. Trách nhiệm hữu hạn",
    "app.terms.line17": "(a) Trong phạm vi tối đa cho phép theo luật hiện hành, bạn đồng ý rằng chúng tôi và các giám đốc, cán bộ, nhân viên, đại lý, đại diện, người cấp phép và nhà cung cấp tương ứng của chúng tôi sẽ không chịu trách nhiệm pháp lý đối với bất kỳ hoặc tất cả các tổn thất, thiệt hại hoặc chi phí dưới bất kỳ hình thức nào do việc cài đặt của bạn gây ra, việc sử dụng hoặc truy cập phần mềm, việc bạn sử dụng hoặc không có khả năng sử dụng phần mềm, bất kỳ thông tin hoặc tài liệu nào được cung cấp trên hoặc thông qua phần mềm và/hoặc liên quan đến phần mềm hoặc thỏa thuận này, bất kể bản chất của luật pháp , quyền công bằng hoặc theo luật định được cho là đã bị vi phạm.",
    "app.terms.line18": "(b) Trong phạm vi tối đa cho phép theo luật hiện hành, biện pháp khắc phục duy nhất cho sự không hài lòng của bạn với phần mềm là ngừng sử dụng phần mềm.",
    "app.terms.line19": "(c) Trong mọi trường hợp, bất kỳ tổ chức, giám đốc, cán bộ, nhân viên, đại lý, đại diện nào của chúng tôi sẽ không chịu trách nhiệm pháp lý với bạn về bất kỳ thiệt hại ngẫu nhiên, gián tiếp, do hậu quả, theo luật định, để làm gương, để trừng phạt hoặc thiệt hại đặc biệt, mất doanh thu, mất cơ hội, mất danh tiếng , mất khả năng sử dụng, mất dữ liệu, mất tài sản vô hình khác hoặc việc bên thứ ba chặn trái phép dữ liệu do phần mềm thu thập, cho dù có thể thấy trước hay không, do nguyên nhân nào, và theo bất kỳ hợp đồng nào, sai lầm cá nhân (bao gồm nhưng không giới hạn ở sơ suất), trách nhiệm pháp lý nghiêm ngặt hoặc lý thuyết khác, ngay cả khi được thông báo trước về khả năng những thiệt hại đó có thể phát sinh.",
    "demo.index.content.line1": "Trong bản demo này, chúng tôi sẽ sử dụng máy ảnh của bạn để ước tính nơi bạn đang tìm kiếm. Đừng lo lắng, chúng tôi sẽ không chụp ảnh hoặc quay video.",
    "app.error.previousfail": "Bạn đã từng thất bại trong nghiên cứu này, vui lòng sử dụng liên kết ban đầu để bắt đầu lại từ đầu.",
    "app.error.previoussuccess": "Trước đây bạn đã hoàn thành nghiên cứu này.",
    "app.error.route": "Bạn đã cố gắng bắt đầu một nghiên cứu đang được tiến hành, vui lòng sử dụng liên kết ban đầu để bắt đầu lại từ đầu.",
    "app.error.accesscode": "Bạn đã cố gắng bắt đầu một nghiên cứu không tồn tại, vui lòng kiểm tra liên kết của bạn.",
    "app.error.webcam": "Nghiên cứu này yêu cầu quyền truy cập vào webcam",
    "app.error.adblocker": "Nghiên cứu này yêu cầu tắt quảng cáo.",
    "app.error.startsession": "Không thể bắt đầu phiên",
    "app.error.fullscreen": "Nghiên cứu này yêu cầu chế độ xem toàn màn hình.",
    "app.error.zoom": "Nghiên cứu này yêu cầu phải đặt mức thu phóng của trình duyệt thành 100%.",
    "app.index.webcamInstructions": "Chúng tôi sắp yêu cầu quyền truy cập vào webcam của bạn, nếu bạn nhận được cửa sổ bật lên, vui lòng nhấp vào cho phép.",
    "app.setup.retryBack": "Vui lòng không nhấn nút quay lại của trình duyệt trong suốt nghiên cứu này, điều đó có thể dẫn đến mất phần thưởng. Sử dụng nút bên dưới để thử lại.",
    "app.setup.retryaudible": "Vui lòng đảm bảo thiết bị của bạn không bị tắt tiếng và thử lại.",
    "app.restore.retry":"Bạn chưa dành đủ thời gian cho bước trước đó, ",
    "app.restore.timeremaining":" phút còn lại",
    "app.index.content.line1gpdrwarning": "Trong quá trình khảo sát này, chúng tôi sẽ sử dụng camera trước trên thiết bị của bạn để chụp ảnh và quay video trong khi bạn thực hiện nhiều bài tập khác nhau liên quan đến việc nhìn vào các tác nhân kích thích.",
    "app.error.cellquotafullnoactivecell": "Không tìm thấy Ô hoạt động nào cho Nghiên cứu này. Dung lượng Ô đã đầy.",
    "app.faq.gdpr.intro1": "Trong nghiên cứu này, chúng tôi sẽ sử dụng camera trước trên thiết bị di động để chụp ảnh và quay video người dùng trong khi họ được yêu cầu thực hiện nhiều bài tập khác nhau liên quan đến việc nhìn vào các tác nhân kích thích trong nhiều điều kiện môi trường khác nhau (ví dụ: ánh sáng và vị trí). Thời gian tham gia dự kiến khoảng 5 phút. Dữ liệu sẽ được sử dụng để hỗ trợ tiếp tục phát triển phần mềm có khả năng phỏng đoán vị trí người dùng đang nhìn trên màn hình.",
    "app.faq.gdpr.intro2": "Nghiên cứu này được tiến hành theo Quy tắc ứng xử MRS và Đạo luật Bảo vệ Dữ liệu năm 2018. Quyền riêng tư của bạn rất quan trọng với chúng tôi. Trước khi tham gia nghiên cứu này, bạn phải xác nhận rằng bạn đồng ý rằng khi tham gia nghiên cứu này, bạn hiểu dữ liệu chúng tôi đang thu thập và cách sử dụng dữ liệu đó như mô tả trong Chính sách Quyền riêng tư này, cũng như chấp thuận việc dữ liệu này được sử dụng theo cách này. Chính sách này mô tả chi tiết hơn chính xác những thông tin nào được thu thập và cách chúng được sử dụng và chia sẻ.",
    "app.faq.gdpr.question1": "Dữ liệu nào sẽ được thu thập?",
    "app.faq.gdpr.answer1.line1": "Nghiên cứu này sẽ thu thập các loại dữ liệu sau:",
    "app.faq.gdpr.answer1.line2": "'Đặc điểm phiên làm việc' – Một ID ẩn danh được sử dụng để nhận diện một 'Phiên' thu thập dữ liệu cụ thể và dữ liệu về các điều kiện môi trường thử nghiệm hiện có trong suốt phiên làm việc đó.",
    "app.faq.gdpr.answer1.line3": "'Đặc điểm người tham gia' – Dữ liệu về người trả lời tham gia vào một Phiên cụ thể, bao gồm thông tin về nhân khẩu học của người tham gia (ví dụ: tuổi, giới tính), dân tộc (ví dụ: loại da) và các đặc điểm sinh học (ví dụ: màu mắt).",
    "app.faq.gdpr.answer1.line4": "'Tệp video' – Hình ảnh và/hoặc đoạn phim video được quay bằng camera trước của thiết bị để ghi lại hình ảnh của người tham gia trong khi họ đang nhìn và có thể tương tác với các tác nhân kích thích hiển thị trên một hoặc nhiều màn hình. Hình ảnh của mắt và khuôn mặt của người tham gia sẽ được ghi lại, camera cũng ghi lại bất kỳ thứ gì khác một cách không chủ ý trong khi nhiệm vụ đang được hoàn thành. ",
    "app.faq.gdpr.answer1.line5": "'Siêu dữ liệu' – Dữ liệu về thời điểm mà các cá nhân tương tác với các tác nhân kích thích, gắn với dấu thời gian của mỗi hình ảnh được thu thập.",
    "app.faq.gdpr.answer1.line6": "'Dữ liệu thiết bị vật lý' – Dữ liệu vật lý về thiết bị, bao gồm tên thiết bị, kích thước màn hình, xoay màn hình và xoay cảm biến camera.",
    "app.faq.gdpr.answer1.line7": "Khi tham gia vào nghiên cứu này, bạn hiểu và đồng ý rằng Nội dung mà bạn nhìn thấy trong nghiên cứu này có thể là bí mật, thuộc sở hữu của bên thứ ba và/hoặc thuộc quyền sở hữu trí tuệ hoặc luật bản quyền và rằng bạn sẽ không chụp ảnh màn hình, ghi lại hoặc sao chép bất kỳ Nội dung nào hoặc phân phối lại bất kỳ Nội dung nào của nghiên cứu dưới bất kỳ hình thức nào.",
    "app.faq.gdpr.question2": "Dữ liệu sẽ được sử dụng / xử lý như thế nào?",
    "app.faq.gdpr.answer2": "Dữ liệu sẽ được sử dụng để phát triển phần mềm có thể phỏng đoán xem người dùng có đang hiện diện trước thiết bị hay không, liệu họ có đang nhìn vào các tác nhân kích thích hay không và họ có thể đang nhìn vào đâu trên các tác nhân kích thích. Dữ liệu cũng có thể được sử dụng cho mục đích trình diễn hoặc để giải thích, hoặc giúp đánh giá cho các bên thứ ba (khách hàng, nhà cung cấp, đối tác) về cách chúng tôi phát triển công nghệ này và hiệu quả. Dữ liệu có thể được xem xét bởi bất kỳ số lượng 'người chú thích' dữ liệu nào – nhân viên, các nhà thầu phụ, đối tác hoặc bất kỳ bên thứ ba nào khác sẽ được yêu cầu xem lại video/hình ảnh để đánh giá 'trạng thái' chú ý của những người tham gia trong quá trình nghiên cứu (ví dụ: họ có hiện diện trong tầm nhìn của camera không?  họ có đang nhìn vào màn hình không?) Cơ sở pháp lý để xử lý dữ liệu sẽ là đồng ý sau khi được thông báo và lợi ích hợp pháp. ",
    "app.faq.gdpr.question3": "Dữ liệu sẽ được giữ lại trong bao lâu?",
    "app.faq.gdpr.answer3": "Dữ liệu sẽ được chúng tôi giữ lại để sử dụng miễn là còn cần thiết cho các mục đích đã mô tả ở trên. Phần mềm đang được phát triển sẽ được cập nhật và đánh giá định kỳ, dữ liệu này sẽ được sử dụng để đánh giá mỗi lần cập nhật, vì vậy dự kiến sẽ được giữ lại cho mục đích này trong nhiều năm, nếu không phải là vô thời hạn. Dữ liệu sẽ bị xóa nếu được cho là không còn hữu ích cho mục đích phát triển hoặc đánh giá phần mềm thử nghiệm kích thích. ",
    "app.faq.gdpr.question4": "Ai sẽ nhận và xử lý dữ liệu?",
    "app.faq.gdpr.answer4": "Dữ liệu sẽ được Lumen Research Ltd giữ và lưu trữ. Lumen Research Ltd là một công ty đăng ký tại Anh, số công ty 8682432. Văn phòng đăng ký tại LABS house, 15-19 Bloomsbury Way, Holborn, London WC1A 2TH. Số đăng ký bảo vệ dữ liệu của công ty là ZA130772. Nhân viên bảo vệ dữ liệu là Matt Robinson (matt.robinson@lumen-research.com). Bạn có quyền khiếu nại về việc xử lý và quản lý dữ liệu của bạn bất cứ lúc nào bằng cách liên hệ với Cơ quan Bảo vệ Dữ liệu của Vương quốc Anh là Văn phòng Ủy viên Thông tin. Thông tin liên lạc của ICO có trên trang web tại https://ico.org.uk/global/contact-us/. Bằng việc tham gia nghiên cứu, bạn cấp cho Lumen một giấy phép toàn cầu, miễn phí bản quyền, có thể cấp phép lại và có thể chuyển nhượng để lưu trữ, sử dụng, hiển thị, tái sản xuất, chỉnh sửa, xuất bản và phân phối dữ liệu này.",
    "app.faq.gdpr.question5": "Tôi có quyền truy cập dữ liệu cá nhân của mình không?",
    "app.faq.gdpr.answer5": "Có, bạn có quyền truy cập dữ liệu cá nhân của mình, tuy nhiên, điều quan trọng là phải lưu ý rằng tên của bạn sẽ không được lưu trữ với dữ liệu được thu thập trong quá trình thực hiện bài tập này. Để xác định hồ sơ dữ liệu của bạn, bạn sẽ cần cung cấp cho chúng tôi thời gian, ngày cụ thể khi bạn thực hiện nghiên cứu và chúng tôi sẽ cần yêu cầu xác minh trực quan để đảm bảo rằng hồ sơ dữ liệu được khớp chính xác.",
    "app.faq.gdpr.question7": "Tôi có quyền hủy bỏ đồng ý về việc xử lý dữ liệu cá nhân của mình hoặc yêu cầu xóa dữ liệu cá nhân của mình không?",
    "app.faq.gdpr.answer7": "Có, bạn có quyền hủy bỏ đồng ý về việc xử lý dữ liệu cá nhân của mình và yêu cầu xóa dữ liệu này. Tuy nhiên, cần lưu ý rằng tên của bạn sẽ không được lưu trữ cùng với dữ liệu được thu thập trong suốt bài tập này. Để xác định hồ sơ dữ liệu của bạn, bạn sẽ cần cung cấp cho chúng tôi thời gian, ngày cụ thể khi bạn thực hiện nghiên cứu và chúng tôi sẽ cần yêu cầu xác minh trực quan để đảm bảo rằng hồ sơ dữ liệu được khớp chính xác. ",
    "app.faq.gdpr.question8": "Làm thế nào để tôi biết thông tin cá nhân của mình được an toàn?",
    "app.faq.gdpr.answer8": "Dữ liệu do Lumen Research thu thập được mã hóa bằng công nghệ Lớp Socket Bảo mật (SSL). Đây là công nghệ mã hóa tiêu chuẩn cao được sử dụng phổ biến. Công nghệ này được sử dụng để bảo mật cả các kênh truyền và lưu trữ cuối cùng.",
    "app.faq.gdpr.question9": "Chúng tôi sẽ sử dụng và tiết lộ thông tin thu thập được như thế nào?",
    "app.faq.gdpr.answer9": "Chúng tôi có thể tiết lộ thông tin mà chúng tôi thu thập cho các nhà cung cấp dịch vụ bên thứ ba khác để giúp họ cung cấp dịch vụ cho chúng tôi. Chúng tôi cũng có thể tiết lộ thông tin cho bên thứ ba trong trường hợp có bất hoạt động tổ chức lại, sáp nhập, bán, liên doanh, chuyển nhượng, chuyển giao hoặc định đoạt bất kỳ phần nào của doanh nghiệp, tài sản hoặc cổ phần của chúng tôi (bao gồm cả trong trường hợp phá sản hoặc thủ tục tương tự). Chúng tôi cũng có thể sử dụng hoặc tiết lộ thông tin chúng tôi thu thập về bạn khi chúng tôi cho là cần thiết hoặc phù hợp: (a) theo luật hiện hành, bao gồm luật ngoài quốc gia cư trú của bạn; (b) để tuân thủ quy trình pháp lý; (c) để đáp ứng các yêu cầu từ các cơ quan công quyền và chính phủ, bao gồm cả các cơ quan công quyền và chính phủ ngoài quốc gia bạn đang cư trú; (d) để thực thi thỏa thuận cấp phép người dùng cuối của chúng tôi; (e) để bảo vệ hoạt động của chúng tôi hoặc của đối tác liên kết; (f) để bảo vệ quyền lợi, quyền riêng tư, an toàn hoặc tài sản của chúng tôi và/hoặc của các đối tác liên kết, của bạn hoặc người khác; (g) để cho phép chúng tôi theo đuổi các biện pháp khắc phục hiện có hoặc hạn chế thiệt hại mà chúng tôi có thể chịu; và (h) để phát hiện và giải quyết gian lận hoặc mối lo ngại về bảo mật. ",
    "app.faq.gdpr.question10": "Bước nào được thực hiện để bảo vệ dữ liệu an toàn?",
    "app.faq.gdpr.answer10": "Chúng tôi thực hiện các bước hợp lý để bảo vệ thông tin của bạn bằng cách sử dụng các biện pháp bảo mật vật lý, điện tử hoặc thủ tục phù hợp với độ nhạy của thông tin mà chúng tôi kiểm soát hoặc giám sát, bao gồm cả các biện pháp bảo vệ chống mất mát hoặc trộm cắp, cũng như chống truy cập trái phép, tiết lộ, sao chép, sử dụng hoặc thay đổi. ",
    "app.error.postexposure": "Rất tiếc, bạn đã sử dụng hết số lần thử và thật không may, bạn không thể tiếp tục nghiên cứu."
  }
  