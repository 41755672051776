import Endpoint from "../../Endpoint";

const getPayload = (shortName) => {
  return {
    short_reference: shortName,
    lookup_type: "login-platform",
  };
};

export default (shortName) => {
  return new Endpoint(
    "shortname",
    `${process.env.REACT_APP_PARTICIPATION_URL}/participation/shortname_login`,
    "POST",
    () => getPayload(shortName),
    undefined,
    true
  );
};
