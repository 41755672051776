import { store } from "../../store/index";

export const debugLogRequestStart = (requestType = null) => {
  if (store.getState().baseReducer.debugLog) {
    let requestStart = new Date();
    console.log("DEBUG: REQUEST START " + (!!requestType ? (requestType + " " + requestStart) : requestStart));
    return requestStart
  } else {
    return null
  }
}

export const debugLogRequestFinish = (requestStart = null) => {
  if (store.getState().baseReducer.debugLog) {
    let requestEnd = new Date();
    console.log("DEBUG: REQUEST END " + requestEnd);
    if (requestStart !== null) {
      console.log("DEBUG: REQUEST DIFF MILLI " + (requestEnd - requestStart));
    }
  }
}
