import urlWithRegion from "../urlWithRegion";
import * as DEBUGLOG from "../../../components/util/debugLog";
import requestTimer from "../../requestTimer";

const feedback = async (studyId, body) => {
    let feedbackRequestDate = DEBUGLOG.debugLogRequestStart("feedback");
    let response = await fetch(urlWithRegion() + "/participation/studies/" + studyId + "/feedback/", {
      method: 'POST',
      body: JSON.stringify(body)
    })
    DEBUGLOG.debugLogRequestFinish(feedbackRequestDate);

    if (response.ok) {
        let responseBody = await response.json()
        return responseBody
    } else {
        let e = new Error(response.statusText || "Failure to submit feedback");
        e.name = response.status || "";
        throw e
    }
}

export default (...args) => requestTimer(() => feedback(...args))