import LumenEyeTracking from "../lumenTracker/";

const initialState = {
  et: () => LumenEyeTracking,
  eyetrackingEnabled: true,
  brokerDetails: null,
  studyId: null,
  originalLink: null,
  vendorId: "71de884d-00a5-4caf-8c79-97f3dd0e064b",
  currentStepId: 0,
  lastStep: null,
  currentStepOrderId: 0,
  studyName: "",
  panelCompanyCode: "",
  successRedirectUrl: null,
  screenoutRedirectUrl: null,
  quotaFullRedirectUrl: null,
  redirectUrlMemberInsertParameter: null,
  passThroughVariables: {},
  secondsBeforeMoveNext: 5,
  accessCode: "",
  participant: "",
  cleanedParticipantReference: null,
  test: false,
  sessionId: null,
  external_reference: null,
  demographicSurveyUrl: null,
  demographicSurveyUrlMemberInsertParameter: null,
  demographicSurveyUrlShouldRedirect: true,
  demographicsSurveyQuestionsInternal: [],
  demographicsSurveyResponsesInternal: {},
  cellStatus: null,
  cellId: null,
  participantStatus: null,
  participantId: null,
  showGaze: false,
  stepData: [],
  optionalStepData: [],
  trackingStartTime: null,
  orientation: 1,
  requireChromeExtension: false,
  fullScreenRequired: false,
  hasExternalDomain: false,
  showStimulusQuestion: false,
  calibrationInfo: {},
  hasWebcam: -1,
  hasAdBlock: -1,
  routedRegion: null,
  fastestRegion: null,
  skipChecks: false,
  testType: "0",
  panelExtra1: null,
  panel: null,
  redirectOverride: false,
  adblockcheckEnabled: null,
  cameraCheckEnabled: null,
  screenAdblock: null,
  screenNoCamera: null,
  showSplashPage: true,
  showInstructionPage: true,
  extraData: null,
  screenLowConnectionSpeed: false,
  lowConnectionSpeed: 1,
  screenLowCalibrationFrameRate: false,
  lowCalibrationFrameRate: 1,
  screenLowViewportWidth: false,
  lowViewportWidth: 1,
  screenLowViewportHeight: false,
  lowViewportHeight: 1,
  requireFullscreen: false,
  screenLowScreenWidth: false,
  LowScreenWidth: 0,
  screenLowScreenHeight: false,
  LowScreenHeight: 0,
  prestartChecksComplete: false,
  startChecksComplete: false,
  instructionsChecksComplete: false,
  newTrackingSystem: false,
  surveyComplete: true,
  startPanelCalled: false,
  startCalled: false,
  assignCalled: false,
  sessionCalled: false,
  showEyetrackingInstructionsOverride: false,
  socialSessionData: {},
  surveyUrlParams: {},
  useExtendedLogger: false,
  skipSurvey: false,
  showGdprWarning: null,
};

/**
 * Initial states for the modal reducer. The initial states refer to the
 * display state of each modal in the menu, and what the current selected
 * language is.
 */

/**
 * Initial the toggle cases display and hide the menu modals. The select
 * language case switches the language to the designated payload. Currently
 * available languages are English and Danish.
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "INIT_PARAMS":
      return {
        ...state,
        studyId: action.payload.studyId,
        accessCode: action.payload.accessCode,
        originalLink: action.payload.originalLink,
      };
    case "INIT_URL_PARAMS":
      return {
        ...state,
        participant: action.payload.participant,
        test: action.payload.test,
        testType: action.payload.testType,
        showTestUserPage: action.payload.showTestUserPage,
        showGaze: action.payload.showGaze,
        panelExtra1: action.payload.panelExtra1,
        cellId: action.payload.cellId,
        panel: action.payload.panel,
        successRedirectUrl: action.payload.redirectOverride,
        screenoutRedirectUrl: action.payload.redirectOverride,
        quotaFullRedirectUrl: action.payload.redirectOverride,
        redirectOverride: action.payload.redirectOverride ? true : false,
      };
    case "INCREMENT_STUDY_STEP":
      return {
        ...state,
        currentStepId: state.stepData[state.currentStepOrderId + 1].id,
        currentStepOrderId: state.currentStepOrderId + 1,
      };
    case "DECREMENT_STUDY_STEP":
      return {
        ...state,
        currentStepId: state.stepData[state.currentStepOrderId - 1].id,
        currentStepOrderId: state.currentStepOrderId - 1,
      };
    case "RESET_STUDY_STEP_DEBUG":
      return {
        ...state,
        currentStepId: state.stepData[0].id,
        currentStepOrderId: 0,
      };
    case "SESSION_ID_UPDATE":
      if (action.payload.sessionId) {
        return {
          ...state,
          sessionId: action.payload.sessionId,
          sessionCalled: true,
        };
      } else {
        return {
          ...state,
          sessionId: action.payload.tmpSessionId,
          sessionCalled: true,
        };
      }
    case "NO_PANEL_STUDY_UPDATE":
      return {
        ...state,
        studyId: action.payload.studyId,
        cellId: action.payload.cellId,
        accessCode: action.payload.accessCode,
        participant: action.payload.participant,
        showGaze: action.payload.showGaze,
        test: action.payload.test,
      };
    case "PANEL_RESPONSE_UPDATE":
      return {
        ...state,
        panelCompanyCode: action.payload.panelCompanyCode,
        successRedirectUrl: state.redirectOverride
          ? state.successRedirectUrl
          : action.payload.successRedirectUrl,
        screenoutRedirectUrl: state.redirectOverride
          ? state.screenoutRedirectUrl
          : action.payload.screenoutRedirectUrl,
        quotaFullRedirectUrl: state.redirectOverride
          ? state.quotaFullRedirectUrl
          : action.payload.quotaFullRedirectUrl,
        redirectUrlMemberInsertParameter:
          action.payload.redirectUrlMemberInsertParameter,
        passThroughVariables: action.payload.passThroughVariables,
        studyId: action.payload.studyId,
        cellId: action.payload.cellId,
        studyName: action.payload.studyName,
        secondsBeforeMoveNext: action.payload.secondsBeforeMoveNext,
        accessCode: action.payload.accessCode,
        participant: action.payload.participant,
        showGaze: action.payload.showGaze,
        test: action.payload.test,
        eyetrackingEnabled: action.payload.eyetrackingEnabled,
        hasWebcam: action.payload.hasWebcam,
        hasAdBlock: action.payload.hasAdBlock,
        routedRegion: action.payload.routedRegion,
        fastestRegion: action.payload.fastestRegion,
      };
    case "START_RESPONSE_UPDATE":
      return {
        ...state,
        brokerDetails: action.payload.brokerDetails,
        cleanedParticipantReference: action.payload.cleanedParticipantReference,
        participant: action.payload.cleanedParticipantReference,
        demographicSurveyUrl: action.payload.demographicSurveyUrl,
        demographicSurveyUrlMemberInsertParameter:
          action.payload.demographicSurveyUrlMemberInsertParameter,
        demographicSurveyUrlShouldRedirect:
          action.payload.demographicSurveyUrlShouldRedirect,
        demographicsSurveyQuestionsInternal:
          action.payload.demographicsSurveyQuestionsInternal,
        orientation: action.payload.orientation,
        requireChromeExtension: action.payload.requireChromeExtension,
        screenLowConnectionSpeed: action.payload.screenLowConnectionSpeed,
        lowConnectionSpeed: action.payload.lowConnectionSpeed,
        screenLowCalibrationFrameRate:
          action.payload.screenLowCalibrationFrameRate,
        lowCalibrationFrameRate: action.payload.lowCalibrationFrameRate,
        screenLowViewportWidth: action.payload.screenLowViewportWidth,
        lowViewportWidth: action.payload.lowViewportWidth,
        screenLowViewportHeight: action.payload.screenLowViewportHeight,
        lowViewportHeight: action.payload.lowViewportHeight,
        requireFullscreen: action.payload.requireFullscreen,
        surveyComplete: action.payload.surveyComplete,
        startCalled: true,
      };
    case "ASSIGN_RESPONSE_UPDATE":
      return {
        ...state,
        cellStatus: action.payload.cellStatus,
        cellId: action.payload.cellId,
        participantStatus: action.payload.participantStatus,
        participantId: action.payload.participantId,
        stepData: action.payload.stepData,
        optionalStepData: action.payload.optionalStepData,
        fullScreenRequired: action.payload.fullScreenRequired,
        hasExternalDomain: action.payload.hasExternalDomain,
        assignCalled: true,
      };
    case "TRACKING_START_TIME":
      return {
        ...state,
        trackingStartTime: action.payload,
      };
    case "SHOW_STIMULUS_QUESTION":
      return {
        ...state,
        showStimulusQuestion: true,
      };
    case "HIDE_STIMULUS_QUESTION":
      return {
        ...state,
        showStimulusQuestion: false,
      };
    case "UPDATE_INTERNAL_DEMOGRAPHICS_QUESTIONS":
      return {
        ...state,
        demographicsSurveyQuestionsInternal: action.payload,
      };
    case "UPDATE_INTERNAL_DEMOGRAPHICS_RESPONSES":
      return {
        ...state,
        demographicsSurveyResponsesInternal: action.payload,
      };
    case "CALIBRATION_INFO":
      return {
        ...state,
        calibrationInfo: action.payload,
      };
    case "UPDATE_STEP_DATA":
      return {
        ...state,
        stepData: action.payload,
      };
    case "REGION_CHECK":
      return {
        ...state,
        routedRegion: action.payload.routedRegion,
        fastestRegion: action.payload.fastestRegion,
      };
    case "START_PANEL":
      return {
        ...state,
        panelCompanyCode: action.payload.panelCompanyCode,
        successRedirectUrl: state.redirectOverride
          ? state.successRedirectUrl
          : action.payload.successRedirectUrl,
        screenoutRedirectUrl: state.redirectOverride
          ? state.screenoutRedirectUrl
          : action.payload.screenoutRedirectUrl,
        quotaFullRedirectUrl: state.redirectOverride
          ? state.quotaFullRedirectUrl
          : action.payload.quotaFullRedirectUrl,
        demographicsSurveyQuestionsInternal:
          action.payload.demographicsSurveyQuestionsInternal,
        redirectUrlMemberInsertParameter:
          action.payload.redirectUrlMemberInsertParameter,
        passThroughVariables: action.payload.passThroughVariables,
        studyId: action.payload.studyId,
        studyName: action.payload.studyName,
        secondsBeforeMoveNext: action.payload.secondsBeforeMoveNext,
        eyetrackingEnabled: action.payload.eyetrackingEnabled,
        adblockcheckEnabled: action.payload.adblockcheckEnabled,
        cameraCheckEnabled: action.payload.cameraCheckEnabled,
        screenAdblock: action.payload.screenAdblock,
        screenNoCamera: action.payload.screenNoCamera,
        showSplashPage: action.payload.showSplashPage,
        showInstructionPage: action.payload.showInstructionPage,
        screenLowConnectionSpeed: action.payload.screenLowConnectionSpeed,
        lowConnectionSpeed: action.payload.lowConnectionSpeed,
        screenLowCalibrationFrameRate:
          action.payload.screenLowCalibrationFrameRate,
        lowCalibrationFrameRate: action.payload.lowCalibrationFrameRate,
        screenLowViewportWidth: action.payload.screenLowViewportWidth,
        lowViewportWidth: action.payload.lowViewportWidth,
        screenLowViewportHeight: action.payload.screenLowViewportHeight,
        lowViewportHeight: action.payload.lowViewportHeight,
        requireFullscreen: action.payload.require_fullscreen,
        screenLowScreenWidth: action.payload.screenLowScreenWidth,
        lowScreenWidth: action.payload.lowScreenWidth,
        screenLowScreenHeight: action.payload.screenLowScreenHeight,
        lowScreenHeight: action.payload.lowScreenHeight,
        newTrackingSystem: action.payload.newTrackingSystem,
        showEyetrackingInstructionsOverride:
          action.payload.showEyetrackingInstructionsOverride,
        startPanelCalled: true,
      };
    case "SET_ADBLOCK":
      return {
        ...state,
        hasAdBlock: action.payload,
      };
    case "SET_HASWEBCAM":
      return {
        ...state,
        hasWebcam: action.payload,
      };
    case "UPDATE_EXTRA_DATA":
      return {
        ...state,
        extraData: action.payload,
      };
    case "PANEL_DETAILS_UPDATE":
      return {
        ...state,
        successRedirectUrl: state.redirectOverride
          ? state.successRedirectUrl
          : action.payload.successRedirectUrl,
        screenoutRedirectUrl: state.redirectOverride
          ? state.screenoutRedirectUrl
          : action.payload.screenoutRedirectUrl,
        quotaFullRedirectUrl: state.redirectOverride
          ? state.quotaFullRedirectUrl
          : action.payload.quotaFullRedirectUrl,
        panelCompanyCode: action.payload.panelCompanyCode,
        studyId: action.payload.studyId,
        studyName: action.payload.studyName,
        secondsBeforeMoveNext: action.payload.secondsBeforeMoveNext,
        eyetrackingEnabled: action.payload.eyetrackingEnabled,
        showGdprWarning: action.payload.showGdprWarning,
      };
    case "PRESTART_CHECKS_COMPLETE":
      return {
        ...state,
        prestartChecksComplete: true,
      };
    case "START_CHECKS_COMPLETE":
      return {
        ...state,
        startChecksComplete: true,
      };
    case "INSTRUCTIONS_CHECKS_COMPLETE":
      return {
        ...state,
        instructionsChecksComplete: true,
      };
    case "COMPLETE_STUDY_STEP":
      let newStepData = [...state.stepData];
      newStepData[action.payload].completed = true;
      return {
        ...state,
        stepData: newStepData,
      };
    case "SET_SURVEY_COMPLETE":
      return {
        ...state,
        surveyComplete: true,
      };
    case "SET_REQUIRED_ORIENTATION":
      return {
        ...state,
        orientation: action.payload,
      };
    case "UPDATE_SOCIAL_SESSION_DATA":
      return {
        ...state,
        socialSessionData: { ...state.socialSessionData, ...action.payload },
      };
    case "UPDATE_SURVEY_URL_PARAMS":
      return {
        ...state,
        surveyUrlParams: { ...state.surveyUrlParams, ...action.payload },
      };
    case "TOGGLE_EXTENDED_LOGGER":
      return {
        ...state,
        useExtendedLogger: true,
      };
    case "TOGGLE_SKIP_SURVEY":
      return {
        ...state,
        skipSurvey: true,
      };
    default:
      return state;
  }
}
