import React from "react";
import Button from "react-bootstrap/Button";
import CSSTransition from "react-transition-group/CSSTransition";
import LSFaqModalCont from "../modal/containers/faqModal";
import LSPrivacyPolicyModalCont from "../modal/containers/privacyModal";
import LSTermsModalCont from "../modal/containers/termsModal";
import LSContactModalCont from "../modal/containers/contactModal";
import LSTakePartModalCont from "../modal/containers/takePartModal";
import LSLanguageModalCont from "../modal/containers/languageModal";
import { FormattedMessage } from "react-intl";
import { persistor } from "../../store/index";
import { TransitionGroup } from "react-transition-group";
import LoggerController from "../../controllers/Logger/LoggerController";

// add later
// <Button onClick={() => this.props.toggleHelpModal()} className="mobileOnly"><FormattedMessage id="app.button.help" defaultMessage="Help" description="Help Button"/></Button>

/**
 * Inner menu component. This contains the list of menu items.
 */

class LSMenuInner extends React.Component {
  constructor() {
    super();

    this.handleFaq = this.handleFaq.bind(this);
  }

  handleFaq() {
    LoggerController.events.logUserInteraction.panelistAction(
      "OpenedFAQModal",
      {},
      "OpenedFAQModal"
    );
    this.props.toggleFaqModal();
  }

  render() {
    return (
      <div className="menu-cont">
        <Button onClick={() => this.handleFaq()}>
          <FormattedMessage
            id="app.button.faqs"
            defaultMessage="FAQs"
            description="FAQ Button"
          />
        </Button>
        <Button onClick={() => this.props.togglePrivacyModal()}>
          <FormattedMessage
            id="app.button.privacy"
            defaultMessage="Privacy Policy"
            description="Privacy Policy Button"
          />
        </Button>
        <Button onClick={() => this.props.toggleTermsModal()}>
          <FormattedMessage
            id="app.button.terms"
            defaultMessage="Terms of Use"
            description="Terms of Use Button"
          />
        </Button>
        <Button onClick={() => this.props.toggleLanguageModal()}>
          <FormattedMessage
            id="app.button.selectLanguage"
            defaultMessage="Select Language"
            description="Select Language Button"
          />
        </Button>
        <Button onClick={() => this.props.toggleContactModal()}>
          <FormattedMessage
            id="app.button.feedback"
            defaultMessage="Feedback"
            description="Feedback Button"
          />
        </Button>
        {process.env.REACT_APP_LUMEN_ENV_VALUE == "dev" ? (
          <Button onClick={() => persistor.purge()}>DEBUG - CLEAR STORE</Button>
        ) : null}
        {process.env.REACT_APP_LUMEN_ENV_VALUE == "dev" ? (
          <Button onClick={() => this.props.debugState()}>
            DEBUG - SHOW DEBUG STATE
          </Button>
        ) : null}
      </div>
    );
  }
}

/**
 * Outer menu component. This is used to animate the opening of the menu.
 */
export default class LSMenu extends React.Component {
  constructor(props) {
    super(props);

    this.menuContToggle = this.menuContToggle.bind(this);
  }
  menuContToggle() {
    this.props.toggleMenu();
  }
  render() {
    return (
      <div className={this.props.className}>
        <LSFaqModalCont />
        <LSPrivacyPolicyModalCont />
        <LSContactModalCont />
        <LSLanguageModalCont />
        <LSTakePartModalCont />
        <LSTermsModalCont />
        <i onClick={this.menuContToggle} className="fas fa-bars menu"></i>
        {this.props.displayMenu ? (
          <TransitionGroup>
            <CSSTransition
              timeout={{ enter: 300, exit: 300 }}
              classNames="toggle-exit"
              children={
                <LSMenuInner
                  debugState={this.props.debugState}
                  togglePrivacyModal={this.props.togglePrivacyModal}
                  toggleHelpModal={this.props.toggleHelpModal}
                  displayFaqModal={this.props.displayFaqModal}
                  displayContactModal={this.props.displayContactModal}
                  displayLanguageModal={this.props.displayLanguageModal}
                  toggleFaqModal={this.props.toggleFaqModal}
                  toggleTermsModal={this.props.toggleTermsModal}
                  toggleContactModal={this.props.toggleContactModal}
                  toggleLanguageModal={this.props.toggleLanguageModal}
                />
              }
            ></CSSTransition>
          </TransitionGroup>
        ) : null}
      </div>
    );
  }
}
