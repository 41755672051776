{
  "title": "﻿Lumen研究プラットフォーム- プライバシーポリシー",
  "date": "2022年7月",
  "contactDetails": {
    "title": "当社連絡先 ",
    "body": [
      "名称：Lumen Research Ltd （「Lumen」）",
      "住所： Screenworks, Unit 215, 22 Highbury Grove, London N5 2ER",
      "電話番号：+44(0)2037355199 ",
      "電子メール: dataprotection@lumen-research.com",
      "ICO登録番号: ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "本プライバシーポリシーは、Lumen研究プラットフォーム上での視線トラッキング調査に参加する参加者から当社が取集する情報の性質と、収集される可能性のある個人情報を管理する当社のポリシーを説明するものです。 "
      ],
      "heading": "初めに"
    },
    {
      "body": [
        "一般的な調査では、Lumenは、英国一般データ保護規制の下で定義されている個人情報を収集しませんが、注視点の推定値、調査回答、データ収集が行われた時点とどの種類のデバイス上で行われたか、および報酬を管理する目的で回答者のIDを収集します。",
        "一般的な調査では、参加者は、お使いのデバイスのウェブカメラまたは前面カメラ正面に座り、お使いの画面上でどこを見ているかを推定するために校正を実行するように求められます。調査の間、参加者は、あるアイテム（たとえば、画像、ウェブサイトまたは動画）を見るように求められ、その間に調査ツールが、どこを見ているかを推定します。この「視線トラッキング」からキャプチャされたデータは、お使いの画面上の推定の注視点の座標の時間変化です。ソフトウェアは、お使いのウェブカメラからの参加者の画像を使用して、これらを推定しますが、参加者の写真や動画をキャプチャすることはございません。 ",
        "また、参加者には、自身、自身の経験、および調査の間に見ていたアイテムに関する質問もされます。 ",
        "これらの調査からのデータは、Lumen Research Ltd (「Lumen」)に送信されます。Lumenは、このデータを解析し、結果を当社クライアントと共有します。 ",
        "これらの調査では、アルファベットで構成される回答者IDが、Lumenがどの回答者が調査を完了したか追跡できるように第三者によりLumenに提供されます。Lumenは完了に関するこの情報を、調査を完了した方への報酬を管理する目的で第三者と共有します。 "
      ],
      "heading": "当社が収集する情報の種類"
    },
    {
      "body": [
        "場合により、回答者は、1つ以上のデータ収集期間を必要とする研究プロジェクトへの参加を求められることがあります。この場合、回答者IDは、その後のデータ収集活動への回答者の関与を開始するためにユニーク参加者へ再度連絡を取る上での永続的な識別子として",
        "使用されます。このシナリオでは、回答者IDは個人情報とみなされます。  "
      ],
      "heading": "当社が収集する個人情報の種類 "
    },
    {
      "body": [
        "通常の調査として、永続的な回答者IDは、当社調査向けに参加者を募集した第三者により当社に提供されます。当社はこのIDを研究への回答者のその後の関与を開始するために個人に再度連絡をするために使用し、このその後の参加を容易にするため第三者と共有する場合があります。",
        "英国一般データ保護規制（UK GDPR）の元、この情報を処理するために当社が依拠する法的根拠は、参加者の同意です。参加者は、いつでも同意を撤回できます。同意を撤回するには dataprotection@lumen-research.comにアクセスして当社までご連絡ください。"
      ],
      "heading": "個人情報を入手する方法とその理由"
    },
    {
      "body": [
        "参加者の情報は、安全に保管され、SSL技術を使用して暗号化されます。これは最も広く使用される暗号化技術の高い標準で、銀行取引およびその他の高度に機微な通信をを安全に保護するため銀行により使用されています、また銀行レベルのセキュリティシステムを必要とするAmazon、PayPal、eBayやその他のウェブサイトでも使用されています。科学者は、256ビット暗号を現在の技術を使用して解読するには、天体の年齢を大きく超える時間が必要であると推測しています。収集されたデータは、Amazon Web Services （AWS）が管理する安全なウェブサーバー上に保管されます。AWSは、最も広く使用されている安全かつ信頼性の高いクラウドコンピューティングサービスプラットフォームの1つで、NetflixやExpediaなどのクラウドベースの大手商業サービスで使用されています。 ",
        "当社は永続的な識別子を多段階研究のために回答者に再度連絡を取るために必要な期間保存します。この期間は、研究の性質により変化し、参加への同意を表明した際に通知されます。そのようなプロジェクト向けのデータ収集が完了した場合、識別子は、データの解析と報告を目的として必要な場合に限り保持されます。プロジェクトの解析と報告が完了した場合、当社は、この個人情報を当社データベースから削除することにより処分します。"
      ],
      "heading": "参加者の個人情報の保管方法 "
    }
  ],
  "dataProtection": {
    "body": ["データ保護法の下、参加者には以下の権利があります:"],
    "bullets": [
      {
        "title": "アクセスする権利",
        "text": [
          "参加者には、自身の個人情報のコピーを当社に請求する権利があります。 "
        ]
      },
      {
        "title": "訂正する権利",
        "text": [
          "正確でないと参加者が考える場合、参加者には自身の個人情報の訂正を当社に請求する権利があります。また、不完全と考える情報を完全なものとするように当社に請求する権利もあります。 "
        ]
      },
      {
        "title": "消去する権利",
        "text": [
          "参加者には、特定の条件下で自身の個人情報を消去するよう当社に請求する権利があります。 "
        ]
      },
      {
        "title": "処理を制限する権利",
        "text": [
          "参加者には、特定の条件下で自身の個人情報の処理を制限するよう当社に請求する権利があります。",
          "同意を撤回する権利 -参加者には、自身の個人情報の処理に対する同意を撤回する権利があります。"
        ]
      },
      {
        "title": "データ携行の権利",
        "text": [
          "参加者には、特定の条件下で当社に提供した個人情報をその他の組織、または参加者自身へ移転するように当社に請求する権利があります。",
          "権利行使に際していかなる費用を求められることはございません。請求が行われた場合、当社は1か月以内に回答いたします。",
          "請求を希望される場合はdataprotection@lumen-research.com宛て電子メールで当社にご連絡ください。"
        ]
      }
    ],
    "title": "データ保護に関する参加者の権利"
  },
  "complaints": {
    "body": [
      "ご自身の個人情報の当社での使用に関して懸念がある場合、dataprotection@lumen-research.com宛て電子メールで当社に苦情を申し立てることができます。.",
      "また、当社がご自身の個人データを使用した方法に関してご不満がある場合は、ICOに苦情を申し立てることもできます。"
    ],
    "address": {
      "body": [
        "ICOの住所：            ",
        "英国個人情報保護監督機関",
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ]
    },
    "title": "苦情申し立ての方法",
    "number": "ヘルプライン電話番号： 0303 123 1113",
    "website": "ICOウェブサイト： https://www.ico.org.uk"
  }
}
