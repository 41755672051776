export const MOVENEXT_TYPES = {
  MOVENEXT_TYPE_STANDARD: 1,
  MOVENEXT_TYPE_NOBACK: 2,
  MOVENEXT_TYPE_AUTOMATIC: 3
}

export const BROKER_TYPES = {
  STANDARD: 1,
  NO_EYETRACKING: 2,
  MOUSE: 3,
  X2: 4,
  MOBILEMOUSE: 5,
  AUDIBLE: 6,
  AUTOMATED_TESTING_BROKER: 7,
}

export const REGIONS = {
  DEFAULT: 1,
  AP: 2,
  US: 3,
  US2: 4,
  SA: 5,
  SP: 6,
}
