{
  "app.thankyou": "ありがとうございます",
  "app.button.faqs": "FAQ",
  "app.button.terms": "利用条件",
  "app.button.privacy": "プライバシーポリシー",
  "app.button.feedback": "フィードバック",
  "app.button.selectLanguage": "言語の選択",
  "app.button.help": "ヘルプ",
  "app.button.start": "開始",
  "app.button.next": "次へ",
  "app.button.submit": "送信",
  "app.button.close": "閉じる",
  "app.button.return": "戻る",
  "app.button.takepart": "OK - 調査に参加します",
  "app.menu.feedback.content": "調査に問題がある場合、またコメントを残されたい場合は、こちらにご記入ください。",
  "app.contactModal.feedback": "フィードバックをいただきましてありがとうございます。",
  "app.takePartModal.line1": "ここで終了すると、調査へのご協力に対する謝礼を受け取ることができません。",
  "app.takePartModal.line2": "必要なものは、ウェブカメラのみです！",
  "app.takePartModal.reason1": "ウェブカメラがありません。",
  "app.takePartModal.reason2": "本アンケートでウェブカメラが使用されることに不安があります。",
  "app.takePartModal.reason3": "現在、時間がありません。",
  "app.takePartModal.reason4": "その他の理由。",
  "app.generic.redirect": "まもなくリダイレクトされます。",
  "app.init.welcome": "Lumen Researchの調査プラットフォームへようこそ",
  "app.init.intro": "この調査プラットフォームでは、特定の広告に関する調査にユーザーを登録してセットアップすることができます。",
  "app.init.message1": "調査のタイプによっては、案内に沿っていくつかの作業を行っていただく場合があります。",
  "app.init.message2": "これらの作業には、アンケートへの回答、作業手順の確認、アイトラッキングのキャリブレーション実施などが含まれます。",
  "app.init.message3": "これから、デモ調査の手順をお知らせします。",
  "app.init.message4": "本調査では、ウェブカメラを有効にしてアイトラッキングを行う場合がありますが、皆様のデータが記録されるわけではありません。",
  "app.init.message5": "現在の調査は、スクリーンアウト／完了時に次のリンクを使用するようセットアップされています。",
  "app.prestart.loading": "読み込み中ですので、お待ちください。",
  "app.index.title": "ようこそ",
  "app.index.content.line1": "本アンケートでは、カメラを使用して皆様の注視点を推定します。こちらで写真や動画を撮影することはございませんので、ご安心ください。",
  "app.index.content.line1gpdrwarning": "アンケート中は様々なエクササイズをしていただき（刺激のあるものを見るなど）、その際にお使いのデバイスの全面カメラを使ってご自身の写真やビデオ映像を撮影します。",
  "app.index.content.line1alt": "楽な姿勢で座り、「スタート」をタップして開始してください。",
  "app.index.content.line2": "お使いのデバイスにカメラは搭載されていますか？その場合は、下の開始ボタンを押してください。",
  "app.index.rejection": "あいにくながら、リンクの情報が不完全なようです。",
  "app.index.webview": "あいにくながら、ウェブビューからページにアクセスされたようです。ブラウザーからこのリンクを開いてください。これは、ウェブビューメニューから直接可能な場合があります。",
  "app.index.webview.chrome.1": "上のメニューボタンをタップしてから、「Chromeで開く」を選択してください。",
  "app.index.webview.chrome.2": "これにより本アンケートがブラウザーアプリで開くため、正常に作動させることができます。",
  "app.index.webview.android.1": "下の「スタート」をタップして本アンケートをブラウザーアプリ内で開くと、正常に作動させることができます。",
  "app.index.webview.android.2": "これが正常に機能しない場合は、ブラウザーアプリで手動でこのページへのリンクを開いてください（リンクをコピーしてChromeのアドレスバーに貼り付けるか、上のメニューバーの「Chromeで開く」のようなオプションを使用するなど）",
  "app.index.webview.safari.1": "画面の下にあるSafariアイコンをタップして先にお進みください。",
  "app.index.webview.safari.2": "これは次のようなものです：",
  "app.index.webview.safari.3": "これにより本アンケートがSafariで開くため、正常に作動させることができます。",
  "app.index.wifi": "インターネットの接続速度が遅すぎるようです。Wi-Fiに接続していますか？",
  "app.index.tellmemore": "詳しくお聞かせください。",
  "app.index.takepart": "調査に参加したくありません。",
  "app.index.webcamRetry": "ウェブカメラを検出できませんでした。本調査にはウェブカメラが必要になります。ウェブカメラへのアクセスを許可してからもう一度お試しください。",
  "app.index.adblockRetry": "あいにくながら、広告ブロッカーが検出されました。調査を最善の状態で進めるには、これを無効にしてもう一度お試しください。",
  "app.index.zoomRetry": "ブラウザーの表示倍率を100%にする必要があります。ズームイン／ズームアウトしてからもう一度お試しください。",
  "app.index.fullscreendesktopretry": "本調査は、全画面表示にする必要があります。ブラウザーのウィンドウを最大化していることを確認してください。",
  "app.index.fullscreendesktoptest": "本調査では、ブラウザーのウィンドウを全画面表示にする必要があります。ブラウザーのウィンドウを最大化していることを確認するために、テストを行う必要があります。",
  "app.index.fullscreendesktoptest2": "まもなく2つのボタンが表示されるため、ボタンをクリックして先にお進みください",
  "app.index.fullscreendesktopmaximised": "画面が既に最大化されています",
  "app.index.fullscreendesktopskip": "ありがとうございます。この例を記録し、検出プロセスを改善するようにします。",
  "app.index.fullscreendesktopreset": "ウィンドウサイズの変更を検出したため、テストをリセットします。",
  "app.index.gotomobile": "本調査には、デスクトップコンピューターは使用できません。下のQRコードを使用して、モバイルデバイスで調査を開いてください。",
  "app.index.returntopanel": "もしくは、下のボタンをクリックしてパネルにお戻りください。",
  "app.instructions.title": "手順",
  "app.instructions.instruction1": "できれば、アンケート中は眼鏡の着用をお控えください。",
  "app.instructions.instruction2": "頭とデバイスをできるだけ動かさなくて済むように、楽な姿勢で座ってください。",
  "app.instructions.instruction3": "頭を静止させたまま、目だけを動かしてください。",
  "app.instructions.instruction4": "始める準備はできましたか？",
  "app.instructions.instruction5": "データ料金の課金を避けるため、Wi-Fiをお使いください。",
  "app.instructions.instruction6": "次の手順をしっかりお読みください。",
  "app.instructions.instruction7": "安定した場所にスマートフォンを固定してください。",
  "app.instructions.instruction8": "部屋を明るくしてください。照明は上から当て、後ろから当てないでください。",
  "app.setup.title": "セットアップ",
  "app.setup.instruction0": "デバイスを縦に置いてください。",
  "app.setup.instruction0a": "デバイスを回転して縦にしてください。",
  "app.setup.instruction0b": "カメラが左側になるようにデバイスを回転してください。",
  "app.setup.instruction0c": "カメラが右側になるようにデバイスを回転してください。",
  "app.setup.instruction0d": "デバイスを回転して横にしてください。",
  "app.setup.instructioncamera": "下のボタンをクリックしてカメラへのアクセスをリクエストしてください。アンケートを完了するには、調査を通じてカメラへのアクセスを許可していただくことが必要になります。",
  "app.setup.cameraText": "アクセスをリクエスト",
  "app.setup.instruction1": "初期化しています...",
  "app.setup.instruction2": "検出されたら、頭がボックス内に入るようにしてください。",
  "app.setup.instruction3": "アンケートが完了するまで、頭とデバイスをその位置に保ってください。",
  "app.setup.instruction3audio": "しっかり聞いて、画面に表示される指示に従ってください。",
  "app.setup.instruction4": "後は、検証を行うだけです。",
  "app.setup.instruction5": "ありがとうございます！アンケートが完了するまで、頭とデバイスを同じ位置に保ってください。",
  "app.setup.instruction6": "スワイプアップしてアドレスバーを非表示にしてください。",
  "app.setup.retry": "エラーが発生しました。頭とデバイスを動かさず、カメラを指で覆わないでください。下のボタンを押してもう一度お試しください。",
  "app.setup.retryPositioning": "頭の位置を判断できませんでした。頭とデバイスを動かさず、カメラを指で覆わないでください。下のボタンを押してもう一度お試しください。",
  "app.setup.retryInit": "エラーが発生しました。ブラウザーからカメラにアクセスできることを確認してください。以前にカメラへのアクセス許可を拒否した場合は、このブラウザーのプライバシー設定で許可する必要があります。下のボタンを押してもう一度お試しください。",
  "app.setup.retryBroker": "キャリブレーションプロセスを完了できませんでした。使用していないアプリやタブを閉じて、リソースを解放してください",
  "app.setup.retryButton": "再試行",
  "app.setup.calibrationProp.watch": "点が画面に表示されたら、それを追ってください",
  "app.setup.calibrationProp.watch.george": "次に、顔が見えたら、その頭の動きをまねながら、顔を見続けてください",
  "app.setup.calibrationProp.click": "どこかをクリックして先にお進みください",
  "app.setup.calibrationProp.tap": "どこかをタップして先にお進みください",
  "app.study.validationProp.watch": "点が画面に表示されたら、それを追ってください",
  "app.study.validationProp.restart": "もう一度お試しください",
  "app.study.validationProp.click": "どこかをクリックして先にお進みください",
  "app.study.validationProp.tap": "どこかをタップして先にお進みください",
  "app.study.render.error": "レンダリング手順に対してドメインが無効です。",
  "app.error.title": "エラー",
  "app.error.generic": "あいにくながら、何か問題があったようです。",
  "app.error.initialise": "初期化できません！",
  "app.error.findCamera": "カメラを検出できません！",
  "app.error.headPosition": "頭の位置を判断できません",
  "app.error.calibrate": "キャリブレーションできません。",
  "app.error.validate": "検証できません。",
  "app.error.start": "開始できません。",
  "app.error.assign": "割り当てできません。",
  "app.error.osnotallowed": "スクリーンアウト：このOSは使用できません。本アンケートに使用できるデバイスをチェックして、もう一度お試しください。",
  "app.error.demoquotafullnoactivecell": "クオータ到達：本調査にご関心をお持ちくださり、ありがとうございます。ご自身が対象となる層の調査は終了しました。",
  "app.error.cellquotafullnoactivecell": "本調査のアクティブなセルが見つかりません。セルのクオータに到達しました。",
  "app.error.missingpanelparameter": "パネルパラメーターがありません。",
  "app.invalid.title": "無効",
  "app.invalid.generic": "あいにくですが、本調査の対象者の条件に該当されないようです。",
  "app.completion.title": "完了",
  "app.completion.congratulations": "おめでとうございます！本調査は以上です。本アンケートのあなたのカメラへのアクセスはこれで終了します。",
  "app.completion.congratulations-noet": "おめでとうございます！本調査は以上です。",
  "app.questions.required": "この質問には必ずお答えください。",
  "app.faq.gdpr.intro1": "調査中は様々なエクササイズをしていただき（様々な環境（照明や位置調整など）条件のもとで刺激のあるものを見るなど）、その際にモバイルデバイスの全面カメラを使ってユーザーの写真やビデオ映像を撮影します。  所要時間はおよそ5分間です。  データは、参加者ーがスクリーン上のどこを見るかを判断するソフトウェアの継続的開発に使用されます。",
  "app.faq.gdpr.intro2": "本調査は、MRS行動規範と 2018 年データ保護法に従って実施されています。  皆様のプライバシーは私たちにとって大変重要です。  調査に参加される前に、調査への参加によって、本プライバシーポリシーの説明に従って当社が収集するデータおよびその使用法を理解し、ポリシーで説明されている方法でデータが使用されることに同意することを確認してください。  本ポリシーでは、収集する情報、およびその使用法や共有方法について詳しく説明しています。",
  "app.faq.gdpr.question1": "どのようなデータを収集しますか。",
  "app.faq.gdpr.answer1.line1": "本調査では以下のタイプのデータを収集します。",
  "app.faq.gdpr.answer1.line2": "「一定期間の特性」 – セッション中の特定データの収集「一定期間」、および一般的な環境テスト条件についてのデータを識別する匿名のID",
  "app.faq.gdpr.answer1.line3": "「参加者の特性」 – 定められた一定期間に参加する参加者についてのデータ。ユーザー層（年齢や性別など）、民族性（肌の色など）、および生物学的特性（眼の色など）が含まれる",
  "app.faq.gdpr.answer1.line4": "「ビデオファイル」 – 参加者が画面上に表示された刺激物を見たり、それに対して何かをする際の様子を、デバイスの全面カメラを使って撮影した画像、またはビデオ映像タスク実行中に全面カメラで参加者の目や顔を撮影しますが、他の部位が無意識に撮影されることもあります。 ",
  "app.faq.gdpr.answer1.line5": "「メタデータ」 - 参加者が刺激物に対して行動を起こす際についてのデータで、収集された各画像のタイムスタンプにリンクしている",
  "app.faq.gdpr.answer1.line6": "「物理デバイスのデータ」 - デバイスに関する物理データで、デバイスの名称や画面サイズ、画面の回転、カメラセンサーの回転などが含まれる",
  "app.faq.gdpr.answer1.line7": "皆様は、本調査に参加することにより、本調査でご覧いただくコンテンツが機密であること、およびサードパーティの所有物であること、商標もしくは著作権法の対象となること、さらに、本調査のいかなるコンテンツもスクリーンショットを取ったり、記録したり、その他のいかなる方法でもコピーしたり、再配布しないことを理解して同意していただくことになります。",
  "app.faq.gdpr.question2": "データはどのように使用／処理されますか。",
  "app.faq.gdpr.answer2": "データは、参加者がデバイスの前にいるか、刺激物を見ているか、また刺激物のどの部分を見ているかを判断するソフトウェアの開発に使用されます。  またサードパーティー（クライアント、サプライヤー、パートナー）に対して当社のテクノロジーの開発方法、およびその機能を説明したり、サードパーティーによる評価をサポートする際に使用されることもあります。  データは複数のデータアノテーターによりレビューされることもあります。アノテーターには、調査中に参加者が何に注意を向けているかの「状態」を評価するために（カメラの視野の中にいるか？画面を見ているか？など）ビデオ映像や画像を見直すよう要請された従業員、下請け業者、パートナー企業、およびあらゆるサードパーティーが含まれます  データは、情報提供による同意と正当な利益の法的根拠に基づいて処理されます。 ",
  "app.faq.gdpr.question3": "データはどの程度の期間、保存されますか。",
  "app.faq.gdpr.answer3": "上記に説明された目的に対して合理的に必要であると思われる期間、当社が保存して使用します。  開発されたソフトウェアは定期的にアップデートおよび評価され、このデータはその際の評価に使用されるため、数年間、または無期限に保存されることになります。  刺激テスト用ソフトウェアの開発および評価のためにこれ以上必要ないと見なされた場合、データは削除されます。 ",
  "app.faq.gdpr.question4": "誰がデータを受け取り、処理しますか。",
  "app.faq.gdpr.answer4": "データは、イングランドの登録企業であるLumen Research Ltdが保持し、保存します（会社番号：8682432）  同社の登録オフィスの住所は、 LABS house, 15-19 Bloomsbury Way, Holborn, London WC1A 2THです。同社のデータ保護参照番号は、ZA130772です。  同社のデータ保護責任者は、マット・ロビンソン（Matt Robinson）です (matt.robinson@lumen-research.com)。  皆様は、ご自身のデータの取り扱いや処理について、英国のデータ保護当局の情報コミッショナーオフィス（ICO)にいつでも苦情を申し立てる権利をお持ちです。  ICOの連絡先は、ウェブサイトをご覧ください（https://ico.org.uk/global/contact-us/）。本調査に参加されることで、ご自身はLumen社に対して、このデータをホスト、保存、使用、表示、再現、修正、適合、編集、公開、配布するための、世界全域で有効、著作権使用料無料、サブライセンス可能、譲渡可能なライセンスを付与します。",
  "app.faq.gdpr.question5": "私は自身の個人情報にアクセスできますか。",
  "app.faq.gdpr.answer5": "はい、ご自身の個人情報にアクセスする権利をお持ちですが、ご自身のお名前がこのエクササイズ中に収集されたデータと共に保存されないことを認識されることが重要です。  データ記録を確認するためには、調査に参加した正確な日にちと時間、およびデータ記録が一致していることを確認するために、視覚的に識別できるものを当社に提供していただく必要があります。",
  "app.faq.gdpr.question7": "私は、私の個人情報の使用に対する同意を撤回したり、個人情報を削除する権利を持っていますか。",
  "app.faq.gdpr.answer7": "はい、ご自身の個人情報の処理に対する同意を撤回したり、そのデータを削除する権利をお持ちです。  しかし、ご自身のお名前がこのエクササイズ中に収集されたデータと共に保存されないことを認識されることが重要です。  データ記録を確認するためには、調査に参加した正確な日にちと時間、およびデータ記録が一致していることを確認するために、視覚的に識別できるものを当社に提供していただくようお願いします。  ",
  "app.faq.gdpr.question8": "私の個人情報が安全であることをどのように確認できますか。",
  "app.faq.gdpr.answer8": "Lumen Research社が収集するデータは、Secure Sockets Layer（SSL）テクノロジーを使って暗号化されます。  SSLは高水準の暗号化テクノロジーで、一般的に使用されています。このテクノロジーは、伝送チャネルと最終保存の両方を安全に行うために使用されています。",
  "app.faq.gdpr.question9": "当社が情報を使用および開示する方法",
  "app.faq.gdpr.answer9": "当社へのサービス提供を可能にするために、収集した情報をサードパーティーのサービスプロバイダーに開示することがあります。また当社の業務、資産、株の全部または一部を再組織化、合併、売却、共同ベンチャー、譲渡、移管、その他の処分をする際に（倒産または同様の手続きを踏む際を含む）、サードパーティーに情報を開示する場合があります。また以下の際に必要および適切であると思われる場合は、ご自身から収集した情報、ご自身についての情報を使用または開示する場合があります。(a) 適用法に準拠する。これにはご自身の居住地外の法律も含まれる; (b) 法的手続きに準拠する; (c) 公共機関および政府機関からの要請に応える。これにはご自身の居住地外の公共機関および政府機関も含まれる; (d) 当社のエンドユーザーライセンス契約を執行する; (e) 当社、または関連会社の事業を保護する; (f) 当社、関連会社、ご自身、またはその他の者の権利、プライバシー、安全、またはプロパティを保護する; (g) 当社が被った損害に対して利用できる救済を求める、または損害を制限する; および (h) 詐欺行為やセキュリティに関する懸念を見つけ、解決する ",
  "app.faq.gdpr.question10": "データの安全を守るためにどのような措置を取っていますか。",
  "app.faq.gdpr.answer10": "当社は、当社の保護下および管理下にある情報の秘密性に適切な物理的、電子的、または手順的セキュリティー手段を使い、皆様の情報を保護するための合理的な措置を講じています。これにはデータの紛失や盗難、および不正アクセスや開示、複写、使用、修正から保護するための措置が含まれます。 ",
  "app.faq.question1": "Q：Lumen調査プラットフォームとは何ですか。またアイトラッキングアンケートに参加した場合、何を行うことになりますか。",
  "app.faq.answer1": "A：本アンケートでは、アイトラッキングテクノロジーを使用します。お使いのデバイスのウェブカメラもしくはスマートフォンのフロントカメラの前に座ってキャリブレーションを実施していただくことで、画面上のどこをご覧になっているかを推定できるようにします。アンケート中は、いくつかの項目（イメージ、ウェブサイト、動画など）をご覧いただき、その間、調査ツールが皆様の注視点を推定します。この「アイトラッキング」を通じて収集されるデータは、一定時間にわたる画面上の推定注視点の座標です。このソフトウェアは、ウェブカメラでとらえた皆様のイメージを使用して推定を行いますが、皆様の写真や動画を撮影することはございません。また、ご自身に関する質問に加えて、アンケートのご経験やアンケート中にご覧いただいた項目についてお尋ねする場合もあります。これらのデータはLumen Research Ltdに送信され、Lumen Researchがデータを分析して、その結果を調査のクライアント企業と共有することになります。",
  "app.faq.question2": "Q：ソフトウェアでウェブカメラやカメラが必要になるのはなぜですか？ またカメラはどのように使用されますか？",
  "app.faq.answer2": "A：ソフトウェアは、ウェブカメラまたはデバイスのカメラでとらえたイメージを使用して、皆様が画面上のどこを見ているかを推定します。これには、顔認識ソフトウェアとアイトラッキングソフトウェアを組み合わせて使用することで、イメージをリアルタイムにデバイス上でローカル分析します。キャリブレーション中にこれらのイメージを処理して、皆様に表示することで、身体を正しい位置に配置していただけるようにしますが、録画を行うことはないため、プライバシーが守られます。アイトラッキングにより記録収集されるデータはイメージではなく、一定時間にわたる対象物の推定注視点に関する数値データです。",
  "app.faq.question3": "Q：アイトラッキング中に、私のイメージを撮影することはありますか？",
  "app.faq.answer3": "A：皆様のイメージを使用するのは、キャリブレーション中に身体を正しい位置に配置していただくためのみです。このイメージを保存することはなく、他の人が見ることもありません。アイトラッキングにより記録収集されるデータはイメージではなく、一定時間にわたる対象物の推定注視点に関する数値データです。",
  "app.faq.question4": "Q：アンケート中、どのようなデータが収集されますか？",
  "app.faq.answer4.line1": "A：ソフトウェアにより、次の5種類のデータの一部もしくはすべてが収集されることになります。",
  "app.faq.answer4.line2": "「ユーザーID」 - 回答者を特定するために使用するユーザーID（調査へのご協力に対する謝礼を管理するために使用されます）。",
  "app.faq.answer4.line3": "「注視データ」 – 画面上に対象物を表示した時に、画面のどこを見ているかを推定するもの",
  "app.faq.answer4.line4": "「アンケートデータ」 – ユーザーによる質問への回答",
  "app.faq.answer4.line5": "「メタデータ」 - 調査を正常に完了した時間に関するデータ",
  "app.faq.answer4.line6": "「物理的デバイスデータ」 – 調査に参加するためにユーザーが使用したコンピューターに関するデータ。コンピューターおよびユーザーエージェント（オペレーティングシステムとブラウザーのバージョン）のRAMレベル、CPUのタイプに加え、コンピューター画面（サイズと解像度）、ウェブカメラ（メーカーと解像度）、アンチウイルスソフトに関する情報など。",
  "app.faq.question5": "Q：収集されたデータはどのように使用されますか？",
  "app.faq.answer5.line1": "A：収集されたデータは、個人が各種のビジュアルイメージやメディアをどのように見て対話しているか、および示されたイメージに対する人々の意見を調べるために使用されます。",
  "app.faq.answer5.line2": "Lumenでは、調査データを関連会社およびビジネスパートナーやクライアント企業と共有する場合があります。関連会社、パートナーやクライアント企業と調査データを共有する場合も、通常は匿名形式のデータを共有します。このような関連会社、パートナーやクライアント企業がこのデータを使用して様々な消費者行動および消費者セグメントに関する概要書を作成し、製品開発に利用したり、顧客または消費者にマーケティングソリューションを提供したりする場合があります。",
  "app.faq.answer5.line3": "Lumenは、品質管理およびボリューム管理を含むデータ収集と処理の管理に役立てることを目的として、「物理的デバイスデータ」および「メタデータ」を収集します。",
  "app.faq.answer5.line4": "収集されたデータは、ここに説明した目的を満たすために必要な期間にわたって保持されます。",
  "app.faq.question6": "Q：個人情報にはどのようなセキュリティが講じられていますか？",
  "app.faq.answer6": "A：Lumenにより収集されるデータは、SSLテクノロジーによる暗号化が行われます。これは最もよく使用されている高水準の暗号化テクノロジーで、銀行でも安全な銀行取引やその他の機密性の高い通信のためにこのテクノロジーを使用しています。さらに、Amazon、PayPal、eBay、および銀行レベルのセキュリティシステムを必要とするその他のウェブサイトが使用するのも、このテクノロジーです。科学者達は、現在のテクノロジーを使用して256ビットの暗号化を解読するには、宇宙の年齢よりもはるかに多くの年数がかかるものと推定しています。収集されたデータは、Amazonウェブサービス（AWS）により管理されるセキュアなウェブサーバーに保管されます。AWSは、最も一般的に使用される安全性と信頼性の高いクラウドコンピューターサービスプラットフォームの1つで、NetflixやExpediaをはじめとするクラウドベースの大手商業サービスにより使用されています。",
  "app.faq.question7": "Q：表示されるコンテンツに関して、どのような義務がありますか？",
  "app.faq.answer7": "A：皆様は、本調査に参加することにより、本調査でご覧いただくコンテンツが機密であること、およびサードパーティの所有物であること、商標もしくは著作権法の対象となること、さらに、本調査のいかなるコンテンツもスクリーンショットを取ったり、記録したり、その他のいかなる方法でもコピーしたり、再配布しないことを理解して同意していただくことになります。",
  "app.faq.question8": "Q：自分のウェブカメラの性能がよくわかりませんが、アイトラッキングに使用できますか？",
  "app.faq.answer8": "A：本システムは、ほとんどのウェブカメラに対応しており、ウェブカメラが問題になることはないものと見られます。",
  "app.faq.question9": "Q：調査を完了しました。他に何かすべきことはありますか？",
  "app.faq.answer9": "A：いいえ、他に何もしていただく必要はありません。ソフトウェアは自動的に終了します。",
  "app.faq.question10": "Q：調査に参加するために、インターネットに接続する必要はありますか？",
  "app.faq.answer10": "A：はい、プログラムをダウンロードして、手順を完了するには、インターネットに接続する必要があります。",
  "app.faq.question11": "Q：手順を完了するには、安定した面にデバイスを置く必要がありますか？",
  "app.faq.answer11": "A：はい、最善の結果を得るためには、楽な姿勢でコンピューター画面の前に座っていただき、キャリブレーションを行った後に対象物を表示しますので、指示があるまで頭を動かさないようにしていただく必要があります。電車での移動中や、膝の上にノートPCを置いているような状態で調査に参加することは避けていただくことをお勧めします。",
  "app.faq.question12": "Q：FAQに問題の解決方法が含まれていない場合は、どうしたらいいですか？",
  "app.faq.answer12": "A：できるだけ早急にご連絡を差し上げますので、study-feedback@lumen-research.comまでメールでお問い合わせください。",
  "app.faq.patent": "Lumenによるウェブコンテンツのテストは、自社の専有インコンテキストテストテクノロジー（英国特許出願番号：1816158.8）により実施されています。",
  "app.terms.line1": "本利用条件（「利用条件」）は、デバイス上での「ViewPoints」アイトラッキングソフトウェア（「ソフトウェア」）の使用に関して、ユーザーとLumen Research Ltd（「Lumen」または「弊社」）の間の合意を統括します。Lumen Research Ltdは、イングランドで登録された企業で、企業番号8682432、登記事務所Unit 215, 22 Highbury Grove, London N5 2EF、データ保護参照ZA130772です。",
  "app.terms.line2": "1.条件の合意",
  "app.terms.line3": "ユーザーは、ソフトウェアを使用することにより、本合意に拘束されることに同意することになります。本合意の条件に同意しない場合は、ViewPointsアンケートに参加しないでください。",
  "app.terms.line4": "2.機密情報",
  "app.terms.line5": "ViewPointsをインストールしたり、使用したり、何らかの方法で関わる場合、ユーザーは、（前述の一般性を損なうことなく）ビジネス計画、成熟した新事業機会、研究開発プロジェクト、デザイン、機密プロセス、コード、ソフトウェアデザイン、企業秘密、製品またはサービスの開発または方式、ノウハウ、発明、販売統計および予測、マーケティング戦略と計画、コスト、損益およびその他の財務情報（監査済み会計報告書に発表されている場合を除く）、料金および割引体系（書面に記録されているか、電子形態その他のフォーマットかどうかにかかわらずあらゆる形式のもの）に関する情報をはじめとして、LumenまたはLumenのクライアントか顧客により機密情報と特定される情報または取り扱われる情報、もしくはその性質、状況、またはその開示方法により明らかに機密とみなされる情報を含む、Lumenに属するかLumenに関連する情報および/またはコンテンツ（「機密情報」）にアクセスする場合があります。このような機密情報は、Lumenおよび/またはその顧客の単独かつ排他的な所有物で、ユーザーはこのような機密情報に関するいかなる権利、所有権、および/または利害も得ることはありません。",
  "app.terms.line6": "ユーザーは、ViewPointsへの参加中もこのような参加の終了後のいつ何時でも（制限なく）、いかなる機密情報も他の人に開示または連絡したり、自分自身のために使用したり、しかるべき配慮と相当な注意を怠ることにより許可なく開示してはなりません。ユーザーは常に最善の努力により、あらゆる機密情報の公開または開示を防止する必要があります。これらの制限は、ユーザーの怠慢以外によって一般に公開された情報については、適用されないものとします。",
  "app.terms.line7": "3.Lumenの所有物",
  "app.terms.line8": "ViewPointsソフトウェアおよびソフトウェアに関するすべてのデータ、コード、キー、パスワード、デザイン、図面、レコード、その他の文書および資料は、Lumenの所有物で、ユーザーのViewPointsアンケートへの参加期間を通してLumenの所有物であり続けます。Lumenは、ソフトウェアおよび/またはデバイスと共に、もしくはこれらに関連して、またはこれらに使用または適応するか、これらに関連して使用または適応することができる、ユーザーが単独により、または他の人と一緒に実施、作成、製作、考案、開発、拡張、分解または発見した、あらゆる文書、資料、モデル、デザイン、図面、プロセス、発明、方式、コンピューターコード、データ、システムインターフェイス、方法論、ノウハウ、機密情報、またはその他の作品に関する、あらゆる知的所有権と財産権、および既存ならびに将来のすべての知的所有権または工業所有権を所有します。これには、前述のものの既存および将来のすべての特許、著作権、意匠権、データベース権、商標、半導体回路配置権、植物品種保護権、インターネット権/ドメイン名、ノウハウおよびあらゆるアプリケーション、さらに前述のものに適用されるすべての権利が含まれます（「所有権」）。いつ何時でもユーザーに所有権が譲渡されることはありません。",
  "app.terms.line9": "4.使用に関する条件および制限",
  "app.terms.line10": "ViewPointsソフトウェアはエンドユーザーにライセンス提供されます。弊社は、この合意の条件に準じて、非排他的、取消し可能、譲渡不可能、サブライセンス不可能な限定ベースで、ユーザーが所有または制御するデバイス、またはインストールに関してユーザーが必要なすべての権利と許可を有することを表明または保証するパーソナルコンピューター上で、ViewPointsソフトウェアを使用することをユーザーに許可することをここに同意します。誤解を避けるために、ユーザーがViewPoints以外の目的のためにいかなる方法でもソフトウェアの使用、翻訳、コピー、再現、電気通信による送信、適応、マージ、変更、改変を行うことを禁止します。",
  "app.terms.line11": "ユーザーは次の事項に同意します。(i) ソフトウェアのコピー、改変、適応、提供、貸出、リース、時分割、二次創作物製作、販売の試み、その他あらゆる権利の譲渡を行わないこと (ii) いかなる手段でもソフトウェアのいかなる部分の逆アセンブル、デコンパイル、リバースエンジニアリングも行わないこと、またはいかなる当事者にもソフトウェアのソースコードの抽出または抽出の試み、逆アセンブル、解読、デコンパイル、リバースエンジニアリングを許可したり、支援しないこと、またはソフトウェアに関するデザイン情報を引き出すために他のいかなる手順も取らないこと (iii) ユーザーに配布された当初の目的以外のいかなる目的にもソフトウェアを使用しないこと (iv) ソフトウェアのカタログ作成、ダウンロード、保存、その他の再現、または配布、もしくはいかなるアンケートやその他の活動の結果の操作のためにスパイダー、ロボット、またはその他の自動データマイニング技術を使用しないこと (v) ソフトウェア、およびソフトウェアがAmazonウェブサービスを介して使用するデータストレージシステムのオーバーロード、「フラッド」、メールボム、クラッシュをはじめとして、ソフトウェアに干渉する処置を取ったり、ソフトウェアの個人使用を行わないこと (vi) あらゆるウイルス、スパイウェア、アドウェア、その他の有害コードを含む、あらゆる違法、不正、有害コードを弊社に送信しないこと。",
  "app.terms.line12": "ここでユーザーに明示的に供与されていないすべての権利は、弊社およびライセンス所有者が所有します。ユーザーは、本合意またはその他のものの下で、弊社がソフトウェアのバグ、欠陥、エラーを修正したり、ソフトウェアのサポート、メンテナンス、改善、改変、アップグレード、更新、拡張を行う義務を負わないことに同意します。弊社は、ソフトウェアの使用に関して、いかなる方法でもユーザーまたはその被指名人に支援を提供する責任を負いません、",
  "app.terms.line13": "弊社は、ソフトウェアの使用の結果として発生したものを含むユーザーのPCの損害に責任を負いません。弊社は、調査への参加を通じて、ソフトウェアによるデータ収集により被ったいかなる追加データ料金にも責任を負いません。",
  "app.terms.line14": "5.免責条項",
  "app.terms.line15": "弊社および弊社の各取締役、役員、従業員、エージェント、代理人、ライセンス所有者、プロバイダーは、(a) ソフトウェアがタイムリーに提供されること、またはソフトウェアへのアクセスが中断することなく、安全であること (b) 欠陥またはエラーが修正されること (c) ソフトウェア、またはソフトウェアとサービスを提供するサーバーかネットワークが安全で、ウイルスやその他の有害なコンポーネントを含まないことについて、表明を行ったり、保証したり、条件を定めることはありません。弊社は、ソフトウェアに関連してメンテナンスおよび/またはサポートサービスを提供する義務を負いません。",
  "app.terms.line16": "6.責任の制限",
  "app.terms.line17": "(a) 適用法の下で認められる最大限の範囲において、ユーザーは、違反したものと主張される法的権利、衡平法上の権利、制定法上の権利の性質に関係なく、弊社および弊社の各取締役、役員、従業員、エージェント、代理人、ライセンス所有者、プロバイダーが、ソフトウェアのインストール、使用、アクセス、ソフトウェアの使用または使用できないこと、ソフトウェア上またはソフトウェアにより提供されたあらゆる情報または資料、およびまたはソフトウェアか本合意に関連した、いかなる種類の損失、損害、または費用について、いかなる義務も負わないものとします。",
  "app.terms.line18": "(b) 適用法の下で認められる最大限の範囲において、ソフトウェアへの不満に対するユーザーの唯一かつ排他的な救済は、ソフトウェアの使用を停止することです。",
  "app.terms.line19": "(c) 予期可能であったかどうか、どのような方法で発生したかに関係なく、損害が発生する可能性を事前に知らされていたとしても、弊社の組織、取締役、役員、従業員、エージェント、代理人は、いかなる契約、不正行為（怠慢を含む）、厳格責任、またはその他の法理の下でも、偶発的、間接的、結果的、制定法上、模範的、懲罰的、特別損害、収益の損失、機会の損失、評判の喪失、使用能力の喪失、データ喪失、その他無形資産の損失、サードパーティによりソフトウェアで収集されたデータの無許可の傍受に関してユーザーに責任を負いません。",
  "demo.index.content.line1": "このデモの間、弊社ではカメラを使用して皆様の注視点を推定します。こちらで写真や動画を撮影することはございませんので、ご安心ください。",
  "app.error.previousfail": "本調査で前回エラーが発生しています。元のリンクを使用して、アンケートを最初から始めてください。",
  "app.error.previoussuccess": "以前に本調査を完了しています。",
  "app.error.route": "既に進行中の調査を開始しようとしました。元のリンクを使用して、アンケートを最初から始めてください。",
  "app.error.accesscode": "存在しない調査を開始しようとしました。リンクを確認してください。",
  "app.error.webcam": "本調査では、ウェブカメラへのアクセスが必要になります",
  "app.error.adblocker": "本調査では、広告ブロック機能を無効にする必要があります。",
  "app.error.startsession": "セッションを開始できません",
  "app.error.fullscreen": "本調査は、全画面表示にする必要があります。",
  "app.error.zoom": "本調査では、ブラウザの表示倍率を100%に設定する必要があります。",
  "app.index.webcamInstructions": "これからウェブカメラへのアクセスをリクエストします。ポップアップが表示されたら、許可をクリックしてください。",
  "app.setup.retryBack": "謝礼を受け取ることができなくなる場合があるため、本調査では、ブラウザーの戻るボタンを押さないようにしてください。下のボタンを押してもう一度お試しください。",
  "app.setup.retryaudible": "デバイスがミュートされていないことを確認してからもう一度お試しください。",
  "app.restore.retry": "前の手順にかかった時間が短すぎます。 ",
  "app.restore.timeremaining": " 分残っています",
  "app.error.postexposure": "あいにくながら、試行回数の上限に到達しました。残念ながら、本調査を続行することができません。"
}