import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import LoadingOverlay from "./LoadingOverlay";
import PreviewTab from "../components/PreviewTab";

const HeaderFooter = ({ children }) => {
  return (
    <>
      <Header />
      <div className={"ls-content"}>
          <LoadingOverlay />
          {children}
      </div>
      <PreviewTab></PreviewTab>
      <Footer />
    </>
  );
};

export default HeaderFooter;
