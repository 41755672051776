import LumenEyeTracking from "../components/lumenTracker";
import ErrorController from "./Error/ErrorController";
import { navigateToNewPath } from "../history";
import {
  submissionInProgressFalse,
  submissionInProgressTrue,
} from "../reducers/actions";
import {
  completeStep,
  showNextStep,
  startStep,
} from "../reducers/StepState/actions";
import { store } from "../store";
import { stepIdFromURL } from "../utils/helpers";
import Trashman from "../utils/helpers/Trashman";
import tabChangeListener from "../utils/listeners/TabChangeListener";
import LoggerController from "./Logger/LoggerController";
import PageController from "./PageController";
import ApiController from "./Api/ApiController";
import PreviewController from "./PreviewController";

class StepController {
  constructor() {
    this.showNextTimer = null;
    this.autoNextTimer = null;
    this.beforeEnd = null;
    this.afterEnd = null;
    this.shouldNavigate = true;
    this.pageviewExtraData = {}

    this.startTimers = this.startTimers.bind(this);
    this.clearTimers = this.clearTimers.bind(this);
    this.start = this.start.bind(this);
    this.getNextStep = this.getNextStep.bind(this);
    this.complete = this.complete.bind(this);
    this.navigateTo = this.navigateTo.bind(this);
    this.moveNext = this.moveNext.bind(this);
    this.bindBeforeEnd = this.bindBeforeEnd.bind(this);
    this.clearBeforeEnd = this.clearBeforeEnd.bind(this);
    this.bindAfterEnd = this.bindAfterEnd.bind(this);
    this.clearAfterEnd = this.clearAfterEnd.bind(this);
  }

  startTimers = (timeToShowNext, timeToAutoNext) => {
    PreviewController.startTimers();
    if (
      timeToShowNext > 0 &&
      (timeToShowNext < timeToAutoNext || timeToAutoNext === 0)
    ) {
      this.showNextTimer = setTimeout(this.handleShowNext, timeToShowNext);
    } else if (timeToAutoNext === -1000) {
      this.handleShowNext();
    }

    if (timeToAutoNext > 0) {
      this.autoNextTimer = setTimeout(this.handleMoveNext, timeToAutoNext);
    } else if (timeToAutoNext === -1000) {
      this.handleMoveNext();
    }

    Trashman.addTask(this.clearTimers);
  };

  clearTimers = () => {
    if (this.showNextTimer) {
      clearTimeout(this.showNextTimer);
    }

    if (this.autoNextTimer) {
      clearTimeout(this.autoNextTimer);
    }
  };

  start = (preview, stepIdOverride) => {
    let stepId = stepIdOverride;

    if (!stepId) {
      stepId = stepIdFromURL();
    }

    if(!preview){
      LoggerController.events.logStepVisit.panelistAction(stepId)
    }

    this.pageviewExtraData = {}

    store.dispatch(startStep(stepId));
  };

  chain(stepId) {
    LoggerController.events.logStepChain.panelistAction(stepId, { stepId });
    store.dispatch(startStep(stepId));
  }

  getCurrentStep = () => {
    let { stepData } = store.getState().stepReducer;

    let remainingSteps = stepData.filter((step) => !step.completed);

    if (remainingSteps.length > 0) {
      return remainingSteps.sort((a, b) => a.order - b.order)[0];
    } else {
      return false;
    }
  };

  getNextStep = (stepId) => {
    let { stepData } = store.getState().stepReducer;

    let remainingSteps = stepData.filter(
      (step) => !step.completed && step.id !== stepId
    );

    if (remainingSteps.length > 0) {
      return remainingSteps.sort((a, b) => a.order - b.order)[0];
    } else {
      return false;
    }
  };

  allStepsComplete = () => {
    let { stepData } = store.getState().stepReducer;

    let stepsToComplete = stepData.filter((step) => !step.completed);

    if (stepsToComplete.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  handleShowNext = () => {
    store.dispatch(showNextStep());
    PreviewController.showNext();
  };

  handleMoveNext = () => {
    this.moveNext();
    PreviewController.moveNext();
  };

  complete = async (args) => {
    if (!!this.beforeEnd) {
      await this.beforeEnd(args);
    }

    this.clearBeforeEnd();
    let { currentStepId, stepData } = store.getState().stepReducer;
    let { trackingStartTime, accessCode, participantId, studyId, cellId } =
      store.getState().studyReducer;
    let currentTime = new Date();

    let currentStep = stepData.filter((step) => step.id === currentStepId)[0];

    let routeOut = "";

    let nextStep = this.getNextStep(currentStepId);

    if (!!nextStep) {
      routeOut = `/${studyId}/${cellId}/${nextStep.id}`;
    } else {
      routeOut = `/${studyId}/completion`;
    }

    if (trackingStartTime !== null && currentTime - trackingStartTime > 60000) {
      store.dispatch(submissionInProgressTrue());
    }

    if (LumenEyeTracking.is_tracking) {
      LumenEyeTracking.trackingBundle.logExtraData(this.pageviewExtraData)
      await new Promise((resolve) => setTimeout(resolve, 100))
      await LumenEyeTracking.stopTracking(participantId);
    }

    if (currentStep && !currentStep.completed) {
      let response = await ApiController.request.participation
        .completestep(studyId, currentStepId)
        .call();

      if (response.success) {
        store.dispatch(submissionInProgressFalse());
        store.dispatch(completeStep(currentStepId, routeOut));
        tabChangeListener.setLastUserAction(
          `completed step ${currentStepId} (${studyId})`
        );

        if (!!this.afterEnd) {
          await this.afterEnd(args);
        }

        this.clearAfterEnd();

        return routeOut;
      }
    } else {
      return routeOut;
    }
  };

  navigateTo = (routeOut) => {
    if (this.shouldNavigate) {
      navigateToNewPath(`/study${routeOut}`);
    } else {
      this.shouldNavigate = true;
    }
  };

  moveNext = async (args) => {
    let { participantId } = store.getState().studyReducer;
    let { currentStepId, stepData } = store.getState().stepReducer;
    let { previewActive } = store.getState().previewReducer;
    
    if (!previewActive) {
      let currentStep = stepData.filter((step) => step.id === currentStepId)[0];
      let nextStep = this.getNextStep(currentStepId)

      if (currentStep.type === "QuestionStep") {
        await PageController.showOverlay();
        let routeOut = await this.complete(args);

        this.navigateTo(routeOut);
      } else if (currentStep.type === "SocialStep") {
        if (!!args) {
          await PageController.showOverlay();
          let routeOut = await this.complete(args);

          this.navigateTo(routeOut);
        } else {
          if (LumenEyeTracking.is_tracking) {
            await LumenEyeTracking.stopTracking(participantId);
            await ErrorController.errors.SocialStepFail.raise();
          }
        }
      } else if (currentStep.type === "StimulusStep"){
        await PageController.showOverlay(false, nextStep && nextStep.type == "StimulusStep" ? true : false);
        let routeOut = await this.complete(args);

        this.navigateTo(routeOut);
      } else {
        await PageController.showOverlay();
        let routeOut = await this.complete(args);

        this.navigateTo(routeOut);
      }
    } else {
      PreviewController.stepComplete();
    }
  };

  bindBeforeEnd = (func) => {
    this.beforeEnd = func;
  };

  clearBeforeEnd = () => {
    this.beforeEnd = null;
  };

  bindAfterEnd = (func) => {
    this.afterEnd = func;
  };

  clearAfterEnd = () => {
    this.afterEnd = null;
  };

  storePageviewExtraData = (obj) => {
    this.pageviewExtraData = {...this.pageviewExtraData, ...obj}
  }
}

export default new StepController();
