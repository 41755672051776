{
  "title": "﻿Lumen Study Platform - Privacy Policy",
  "date": "July 2022",
  "contactDetails": {
    "title": "Our contact details ",
    "body": [
      "Name: Lumen Research Ltd (“Lumen”)",
      "Address: Screenworks, Unit 215, 22 Highbury Grove, London N5 2ER",
      "Phone Number: +44(0)2037355199 ",
      "E-mail: dataprotection@lumen-research.com",
      "ICO Registration Reference: ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "This Privacy Policy explains the nature of the information we collect from participants who take part in an eye tracking survey on the Lumen Study Platform, and our policy governing any personal information that may be collected. "
      ],
      "heading": "Introduction"
    },
    {
      "body": [
        "In a typical survey Lumen does not collect personal information as defined under the UK General Data Protection Regulation, but will collect gaze point estimates; survey responses; metadata about when and on what type of device the data collection took place; and respondent IDs for the purpose of administering rewards.",
        "In a typical survey you will be asked to sit in front of your device’s webcam or front-facing camera and perform a calibration so that an estimate can be made of where you are looking on your screen. During the survey you will be asked to view some items (eg images, websites or videos) whilst the survey tool estimates where you are looking. The data that is captured from this ‘eye tracking’ are the coordinates of the estimated gaze points on your screen over time. The software uses images of you from your webcam to make these estimates but does not capture photos or videos of you. ",
        "You may also be asked some questions about yourself, your experience and the items you have seen during the survey. ",
        "The data from these surveys is sent to Lumen Research Ltd (“Lumen”). Lumen will analyse the data and share results with its clients. ",
        "In these surveys an alphanumeric respondent ID will also be passed by a third party to Lumen so that Lumen can track which respondents complete the survey.  Lumen will share this information on completions with third parties for the purpose of administering rewards to those who have completed the survey. "
      ],
      "heading": "The type of information we collect"
    },
    {
      "body": [
        "Occasionally, respondents may be asked to take part in a research project that requires more than one period of data collection.  In this case, the respondent ID will be used as a persistent identifier to re-contact a unique participant in order to initiate their involvement in any subsequent data collection exercise.  In this scenario, the respondent ID will be regarded as personal information.  "
      ],
      "heading": "The type of personal information we collect "
    },
    {
      "body": [
        "As with a typical survey, a persistent respondent ID will be passed to us by a third party that has recruited a participant for our research.  We will use it to re-contact individuals to initiate their subsequent involvement in the research and may share it with third parties to facilitate this subsequent participation.",
        "Under the UK General Data Protection Regulation (UK GDPR), the lawful bases we rely on for processing this information are your consent.  You are able to remove your consent at any time.  You can do this by contacting us at dataprotection@lumen-research.com"
      ],
      "heading": "How we get the personal information and why we have it"
    },
    {
      "body": [
        "Your information is securely stored and encrypted using SSL technology. This is the most commonly used high standard of encryption technology and is used by banks to secure banking transactions and other highly sensitive communications. It is also used by Amazon, PayPal, eBay, and other websites requiring bank-level security systems. Scientists estimate that breaking 256 bit encryption using current technology would require substantially more time than the age of the universe. Collected data is stored on secure web servers managed by Amazon Web Services (AWS). AWS is one of the most commonly used safe and dependable cloud computing services platforms and is used by major cloud-based commercial services including Netflix and Expedia. ",
        "We keep persistent identifiers for the period of time required to re-contact respondents for any multi-stage research.  This could vary depending on the nature of the research and will be notified when you provide your consent to participate.  When the data collection for such a project has been completed the identifiers will be retained solely as required for the purpose of analysing and reporting the data.  When the analysis and reporting of a project has been completed we will dispose of this personal information by deleting it from our databases."
      ],
      "heading": "How we store your personal information "
    }
  ],
  "dataProtection": {
    "body": ["Under data protection law, you have rights including:"],
    "bullets": [
      {
        "title": "Your right of access",
        "text": [
          "You have the right to ask us for copies of your personal information. "
        ]
      },
      {
        "title": "Your right to rectification",
        "text": [
          "You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete. "
        ]
      },
      {
        "title": "Your right to erasure",
        "text": [
          "You have the right to ask us to erase your personal information in certain circumstances. "
        ]
      },
      {
        "title": "Your right to restriction of processing",
        "text": [
          "You have the right to ask us to restrict the processing of your personal information in certain circumstances. "
        ]
      },
      {
        "title": "Your right to withdraw consent",
        "text": [
          "You have the the right to withdraw your consent to the processing of your personal information."
        ]
      },
      {
        "title": "Your right to data portability",
        "text": [
          "You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.",
          "You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.",
          "Please contact us at dataprotection@lumen-research.com if you wish to make a request."
        ]
      }
    ],
    "title": "Your data protection rights"
  },
  "complaints": {
    "body": [
      "If you have any concerns about our use of your personal information, you can make a complaint to us at dataprotection@lumen-research.com.",
      "You can also complain to the ICO if you are unhappy with how we have used your data."
    ],
    "address": {
      "body": [
        "Information Commissioner’s Office",
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ],
      "title": "The ICO’s address:            "
    },
    "title": "How to complain",
    "number": "Helpline number: 0303 123 1113",
    "website": "ICO website: https://www.ico.org.uk"
  }
}
