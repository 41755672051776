import urlWithRegion from "../urlWithRegion"
import { store } from "../../../store"
import requestTimer from "../../requestTimer";

const session = async () => {
    let response 

    if(store.getState().studyReducer.newTrackingSystem){
        response = await fetch(urlWithRegion() + "/ctt-session", {
            method: 'POST',
            body: JSON.stringify({
                panelist: store.getState().studyReducer.participant
            })
        })
    } else {
        response = await fetch(urlWithRegion() + "/new-session", {
            method: 'POST',
            body: JSON.stringify({
                panelist: store.getState().studyReducer.participant
            })
        })
    }

    if (response.ok) {
        return await response.json();
    } else {
        let e = new Error(response.statusText);
        e.name = response.status || "";
        throw e
    }
}

export default (...args) => requestTimer(() => session(...args))


