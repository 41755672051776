{
  "title": "﻿Lumen Studienplattform – Datenschutzrichtlinie",
  "date": "Juli 2022",
  "contactDetails": {
    "title": "Unsere Kontaktinformationen  ",
    "body": [
      "Name: Lumen Research Ltd (\"Lumen\")",
      "Adresse: Screenworks, Einheit 215, 22 Highbury Grove, London N5 2ER",
      "Telefon-Nummer: +44(0)2037355199 ",
      "E-Mail: dataprotection@lumen-research.com",
      "ICO-Registrierungsreferenz: ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "Diese Datenschutzrichtlinie erläutert die Art der Informationen, die wir von Teilnehmern sammeln, die an einer Eye-Tracking-Umfrage auf der Lumen-Studienplattform teilnehmen, sowie unsere Richtlinien für alle persönlichen Daten, die gesammelt werden können. "
      ],
      "heading": "Einleitung"
    },
    {
      "body": [
        "In einer typischen Umfrage sammelt Lumen keine persönlichen Daten im Sinne der britischen Datenschutzverordnung, sondern Blickpunktschätzungen, Umfrageantworten, Metadaten über-wann und mit welchem Gerät die Datenerhebung stattgefunden hat, und Teilnehmer-IDs zum Zweck der Verwaltung von Belohnungen.",
        "Bei einer typischen Umfrage werden Sie gebeten, sich vor die Webcam oder die Frontkamera Ihres Geräts zu setzen und eine Kalibrierung vorzunehmen, damit es geschätzt werden kann, wohin Sie auf Ihrem Bildschirm schauen. Während der Umfrage werden Sie aufgefordert, einige Elemente (z. B. Bilder, Websites oder Videos) anzusehen, während das Umfragetool schätzt, wohin Sie schauen. Die Daten, die bei dieser \"Augenverfolgung\" erfasst werden, sind die Koordinaten der geschätzten Blickpunkte auf Ihrem Bildschirm im Laufe der Zeit. Die Software verwendet für diese Schätzungen Bilder von Ihnen, die von Ihrer Webcam stammen, aber nimmt keine Fotos oder Videos von Ihnen auf. ",
        "Möglicherweise werden Ihnen auch einige Fragen zu Ihrer Person, Ihren Erfahrungen und den Gegenständen gestellt, die Sie während der Umfrage gesehen haben. ",
        "Die Daten aus diesen Umfragen werden an Lumen Research Ltd (\"Lumen\") gesendet. Lumen wird die Daten analysieren und die Ergebnisse an seine Kunden weitergeben. ",
        "Bei diesen Umfragen wird auch eine alphanumerische Teilnehmer-ID von einem Dritten an Lumen weitergegeben, damit Lumen verfolgen kann, welche Teilnehmer die Umfrage abgeschlossen haben.  Lumen wird diese Informationen über die Beendigung der Umfrage an Dritte weitergeben, um Belohnungen an die Teilnehmer zu verteilen, die die Umfrage beendet haben. "
      ],
      "heading": "Die Art der Informationen, die wir sammeln"
    },
    {
      "body": [
        "Gelegentlich werden die Befragten gebeten, an einem Forschungsprojekt teilzunehmen, das mehr als einen Zeitraum der Datenerhebung erfordert. In diesem Fall wird die Respondenten-ID als dauerhafte Kennung verwendet, um einen eindeutigen Teilnehmer erneut zu kontaktieren, um seine Beteiligung an einer nachfolgenden Datenerhebung einzuleiten.  In diesem Szenario wird die Respondenten-ID als personenbezogene Information betrachtet.  "
      ],
      "heading": "Die Art der von uns erhobenen personenbezogenen Daten "
    },
    {
      "body": [
        "Wie bei einer typischen Umfrage wird uns eine dauerhafteTeilnehmer-ID von einer dritten Partei übermittelt, die einen Teilnehmer für unsere Forschung rekrutiert hat.  Wir verwenden sie, um Personen erneut zu kontaktieren, um hre spätere Teilnahme an der Studie zu initiieren und können sie an Dritte weitergeben, um diese spätere Teilnahme zu erleichtern.",
        "Gemäß der britischen Datenschutzgrundverordnung (UK General Data Protection Regulation, GDPR) stützen wir uns bei der Verarbeitung dieser Daten auf Ihre Zustimmung.  Sie haben jederzeit die Möglichkeit, Ihre Zustimmung zu widerrufen.  Dazu können Sie uns unter dataprotection@lumen-research.com kontaktieren."
      ],
      "heading": "Wie wir die personenbezogenen Daten erhalten und warum wir sie haben"
    },
    {
      "body": [
        "Ihre Daten werden sicher gespeichert und mit TLS - Technologie verschlüsselt. Dies ist der am häufigsten verwendete hohe Standard der Verschlüsselungstechnologie und wird von Banken zur Sicherung von Bankgeschäften und anderen hochsensiblen Kommunikationen verwendet. Sie wird auch von Amazon, PayPal, eBay und anderen Websites verwendet, die Sicherheitssysteme auf Bankniveau erfordern. Wissenschaftler schätzen, dass das Brechen einer 256-Bit-Verschlüsselung mit der derzeitigen Technologie wesentlich mehr Zeit in Anspruch nehmen würde als das Alter des Universums. Die gesammelten Daten werden auf sicheren Webservern gespeichert, die von Amazon Web Services (AWS) verwaltet werden. AWS ist eine der am häufigsten genutzten sicheren und zuverlässigen Plattformen für Cloud-Computing-Dienste und wird von großen kommerziellen Cloud-basierten Diensten wie Netflix und Expedia genutzt. ",
        "Wir bewahren dauerhafte Identifikatoren für den Zeitraum auf, der erforderlich ist, um die Teilnehmer für eine mehrstufige Untersuchung erneut zu kontaktieren.  Dies kann je nach Art der Untersuchung variieren und wird Ihnen mitgeteilt, wenn Sie Ihre Zustimmung zur Teilnahme geben.  Wenn die Datenerhebung für ein solches Projekt abgeschlossen ist, werden die Kennungen nur so lange aufbewahrt, wie es für die Analyse und Berichterstattung der Daten erforderlich ist.  Nach Abschluss der Analyse und Berichterstattung über ein Projekt werden wir diese personenbezogenen Daten aus unseren Datenbanken löschen."
      ],
      "heading": "Wie wir Ihre persönlichen Daten speichern "
    }
  ],
  "dataProtection": {
    "body": [
      "Nach dem Datenschutzgesetz haben Sie unter anderem folgende Rechte:"
    ],
    "bullets": [
      {
        "title": "Ihr Recht auf Zugang",
        "text": [
          "Sie haben das Recht, von uns Kopien Ihrer personenbezogenen Daten zu verlangen. "
        ]
      },
      {
        "title": "Ihr Recht auf Berichtigung",
        "text": [
          "Sie haben das Recht, uns aufzufordern, personenbezogene Daten zu berichtigen, die Ihrer Meinung nach unrichtig sind. Sie haben auch das Recht, uns um die Vervollständigung von Informationen zu bitten, die Sie für unvollständig halten. "
        ]
      },
      {
        "title": "Ihr Recht auf Löschung",
        "text": [
          "Sie haben das Recht, uns zu bitten, Ihre personenbezogenen Daten unter bestimmten Umständen zu löschen. "
        ]
      },
      {
        "title": "Ihr Recht auf Einschränkung der Verarbeitung",
        "text": [
          "Sie haben das Recht, uns zu bitten, die Verarbeitung Ihrer personenbezogenen Daten unter bestimmten Umständen einzuschränken. "
        ]
      },
      {
        "title": "Ihr Recht auf Widerruf der Einwilligung",
        "text": [
          "Sie haben das Recht, Ihre Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten zu widerrufen."
        ]
      },
      {
        "title": "Ihr Recht auf Datenübertragbarkeit",
        "text": [
          "Sie haben das Recht zu verlangen, dass wir Ihre personenbezogenen Daten unter bestimmten Umständen an eine andere Organisation oder an Sie weitergeben.",
          "Für die Ausübung Ihrer Rechte müssen Sie keine Gebühren entrichten. Wenn Sie einen Antrag stellen, haben wir ein Monat, Ihnen zu antworten.",
          "Bitte kontaktieren Sie uns unter dataprotection@lumen-research.com, wenn Sie eine Anfrage stellen möchten."
        ]
      }
    ],
    "title": "Ihre Rechte in Bezug auf den Datenschutz"
  },
  "complaints": {
    "body": [
      "Wenn Sie Bedenken hinsichtlich der Verwendung Ihrer persönlichen Daten durch uns haben, können Sie eine Beschwerde an dataprotection@lumen-research.com richten.",
      "Sie können sich auch bei der ICO beschweren, wenn Sie mit der Verwendung Ihrer Daten durch uns unzufrieden sind."
    ],
    "address": {
      "body": [
        "Information Commissioner's Office",
        "Wycliffe-Haus",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ],
      "title": "Die Adresse des ICO:            "
    },
    "title": "Wie Sie sich beschweren können",
    "number": "Hotline-Nummer: 0303 123 1113",
    "website": "ICO-Website: https://www.ico.org.uk"
  }
}
