import Endpoint from "../../Endpoint";

const handleResponse = (response) => {
  if("content" in response){
    response.success = true
  } 

  return response
}

export default (pageId, selectedLanguage) => {
    return new Endpoint(
      "taskpage",
      `${process.env.REACT_APP_API_URL_NO_VER}/task_pages/page_json/${pageId}?language=${selectedLanguage}`,
      "GET",
      undefined,
      handleResponse,
      true
    );
  };
  