const regionCheck = async (region) => {
  return new Promise(async (resolve, reject) => {
    let startTime = new Date().getTime();
    fetch(`https://919.${region.code}.lumen-research.com/speed-check`, {
      method: "GET",
      mode: "no-cors",
    })
      .then((response) => {
        let endTime = new Date().getTime();
        let timeTaken_ms = endTime - startTime;
        resolve({ name: region.name, timeTaken_ms, code: region.code });
      })
      .catch((error) => {
        setTimeout(() => {
          resolve({
            name: region.name,
            timeTaken_ms: 999999,
            code: region.code,
          });
        }, 5000);
      });
  });
};

export default regionCheck
