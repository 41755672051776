import * as actions from "./action-types";

export const changeHeaderFooterState = (state) => {
  return {
    type: actions.CHANGE_HEADER_FOOTER_STATE,
    payload: state,
  };
};

export const startPage = (page) => {
  let footerType = "";

  switch (page) {
    case "study":
      footerType = "study";
      break;
    case "demographics":
      footerType = "question";
      break;
    case "preview":
      footerType = "preview";
      break;
    default:
      footerType = "index";
  }

  return {
    type: actions.START_PAGE,
    payload: {page, footerType},
  };
};

export const showNextPage = () => {
  return {
    type: actions.PAGE_SHOW_NEXT
  }
}

export const completePage = (page, routeOut) => {
  return {
    type: actions.COMPLETE_PAGE,
    payload: { page, routeOut },
  };
};

export const ChangeLoadingOverlayState = (state, hideLoading) => {
  return {
    type: actions.CHANGE_LOADING_OVERLAY_STATE,
    payload: {state, hideLoading: !!hideLoading}
  }
}
