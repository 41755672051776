import sendDataToCrawler from "../../../../components/util/sendDataToCrawler";
import { store } from "../../../../store";
import DeviceController from "../../../../utils/controllers/DeviceController";
import Endpoint from "../../Endpoint";

const getPayload = () => {
  let {
    accessCode,
    participant,
    panelCompanyCode,
    test,
    cellId,
    demographicsSurveyResponsesInternal,
  } = store.getState().studyReducer;
  const urlParams = new URLSearchParams(window.location.search);

  let deviceInfo = DeviceController.getDeviceInfo();

  let startPayload = {
    access_code: accessCode,
    participant: participant,
    panel: panelCompanyCode,
    test: test,
    requested_cell_id: cellId,
    gender: demographicsSurveyResponsesInternal["gender"] || urlParams.get("g"),
    age: demographicsSurveyResponsesInternal["age"] || urlParams.get("a"),
    social_grade:
      demographicsSurveyResponsesInternal["social_grade"] || urlParams.get("s"),
    family: demographicsSurveyResponsesInternal["family"] || urlParams.get("f"),
    earnings:
      demographicsSurveyResponsesInternal["earnings"] || urlParams.get("hi"),
    ethnicity:
      demographicsSurveyResponsesInternal["ethnicity"] || urlParams.get("et"),
    extra1: demographicsSurveyResponsesInternal["e1"] || urlParams.get("e1"),
    extra2: demographicsSurveyResponsesInternal["e2"] || urlParams.get("e2"),
    extra3: demographicsSurveyResponsesInternal["e3"] || urlParams.get("e3"),
    device: deviceInfo.device,
    os: deviceInfo.osString.toLowerCase(),
    os_version: deviceInfo.os.version,
    browser: deviceInfo.name.toLowerCase(),
    browser_version: deviceInfo.version,
    is_mobile: deviceInfo.isMobile,
    is_touch_capable: deviceInfo.isMobile,
    device_model: deviceInfo.device,
    browser_language: window.navigator.language,
  };

  return startPayload;
};

const handleResponse = async (response) => {
  if (response.success) {
    let { testType } = store.getState().studyReducer;
    let deviceInfo = DeviceController.getDeviceInfo();

    if (testType === "3" || testType === "4") {
      if (testType === "3") {
        response.broker_details = {
          name: "No Eye Tracking Broker",
          url: "https://lr-webcam-tracker.viewpoints.fyi/no_eyetracking_broker/",
          model_name: "NoModel",
          config: null,
          eyetracking_enabled: false,
          js_enabled: true,
          ir_enabled: false,
          min_resolution_width: 0,
          min_resolution_height: 0,
          min_frame_rate: 0,
        };
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
      sendDataToCrawler("AssignResponse", {
        redirectSurvey: response.redirect_survey,
        url: response.redirect_url,
        internalQuestions: response.study_questions,
        checks: [
          {
            name: "Orientation Check",
            enabled: deviceInfo.isMobile,
            page: "instructions",
          },
        ],
      });
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }

    let surveyRequired =
      (response.redirect_url && response.redirect_url !== "") ||
      (response.study_questions && response.study_questions !== []);

    let payload = {
      brokerDetails: response.broker_details,
      cleanedParticipantReference: response.cleaned_participant_reference,
      demographicSurveyUrl: response.redirect_url,
      demographicSurveyUrlMemberInsertParameter:
        response.redirect_url_member_insert_parameter,
      demographicSurveyUrlShouldRedirect: response.redirect_survey,
      demographicsSurveyQuestionsInternal: response.study_questions,
      orientation: response.orientation,
      requireChromeExtension: response.require_chrome_extension,
      screenLowConnectionSpeed: response.screen_low_connection_speed,
      lowConnectionSpeed: response.low_connection_speed,
      screenLowCalibrationFrameRate: response.screen_low_calibration_frame_rate,
      lowCalibrationFrameRate: response.low_calibration_frame_rate,
      screenLowViewportWidth: response.screen_low_viewport_width,
      lowViewportWidth: response.low_viewport_width,
      screenLowViewportHeight: response.screen_low_viewport_height,
      lowViewportHeight: response.low_viewport_height,
      requireFullscreen: response.require_fullscreen,
      surveyComplete: !surveyRequired,
    };

    store.dispatch({
      type: "START_RESPONSE_UPDATE",
      payload: payload,
    });
  }

  return response;
};

export default (study_id) => {
  return new Endpoint(
    "start",
    `${process.env.REACT_APP_PARTICIPATION_URL}/participation/studies/${study_id}/start`,
    "POST",
    getPayload,
    handleResponse,
    true
  );
};
