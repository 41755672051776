import requestTimer from "../../requestTimer"
import tabChangeListener from "../../../utils/listeners/TabChangeListener";
import participationUrl from "../participationUrl";

const saveCalibration = async (body) => {
    tabChangeListener.setWaitingForApi("saveCalibration");
    let response = await fetch(participationUrl() + '/ctt_calibration_data/save', {
        body: JSON.stringify(body),
        method: 'POST'
    })

    tabChangeListener.setWaitingForApi("");

    return response
}

export default (...args) => requestTimer(() => saveCalibration(...args))