import * as DEBUGLOG from "../../../components/util/debugLog";
import requestTimer from "../../requestTimer";
import tabChangeListener from "../../../utils/listeners/TabChangeListener";
import participationUrl from "../../admin/participationUrl";

const step = async (step_id, cell_id) => {
    let response = await fetch(participationUrl() + "/demo/step", {
        method: 'POST',
        body: JSON.stringify({step_id, cell_id})
    })
    if (response.ok) {
        let responseBody = await response.json()
        tabChangeListener.setWaitingForApi('');
        return responseBody
    } else {
      let e = new Error(response.statusText);
      e.name = response.status || "";
      throw e;
    }
}

export default (...args) => requestTimer(() => step(...args))