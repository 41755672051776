import { v4 } from "uuid";

class Trashman {
  constructor() {
    this.tasks = {};

    this.removeTask = this.removeTask.bind(this);
    this.addTask = this.addTask.bind(this);
    this.cleanUp = this.cleanUp.bind(this);
  }

  removeTask(taskId) {
    if (taskId in this.tasks) {
      delete this.tasks[taskId];
    }
  }

  addTask(func) {
    let taskId = v4();
    this.tasks[taskId] = func;

    return () => {
      this.removeTask(taskId);
    };
  }

  cleanUp() {
    Object.entries(this.tasks).forEach(([key, func]) => {
      func();
    });

    this.tasks = {};
  }
}

export default new Trashman();
