import LumenEyeTracking from "../../components/lumenTracker";
import DeviceController from "../controllers/DeviceController";
import LoggerController from "../../controllers/Logger/LoggerController";

class TabChangeListener{
  constructor() {
    this.isListening = false;
    this.isIOS = DeviceController.getIsIOS()
    this.isLoading = false
    this.waitingForApi = ''
    this.calibratePointsCompleted = 0
    this.lastCalibrationPoint = 0
    this.lastUserAction = ''
    this.brokerSubEvents = []
  }

  handleFocus = () => {
    LoggerController.events.logUserInteraction.panelistAction("ReturnedToTab", {}, "UserReturnedToTab")
  };

  handleBlur = async () => {
    let payload = {
      waitingForApi: tabChangeListener.waitingForApi,
      isLoading: tabChangeListener.isLoading,
      lastUserAction: tabChangeListener.lastUserAction,
    };

    if (['broker init started', 'head positioning started' , 'calibrate started', 'validate started', 'broker reinit started'].includes(tabChangeListener.lastUserAction)){
      payload.brokerLog = await LumenEyeTracking.getLogData(false)
    }

    LoggerController.events.logUserInteraction.panelistAction("LeftTab", {}, "UserLeftTab")
  };

  handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      tabChangeListener.handleBlur();
    } else if (document.visibilityState === "visible") {
      tabChangeListener.handleFocus();
    }
  };

  register = () => {
    if (!this.isListening) {
      LoggerController.events.logSubscribe.log("TabChangeListener")
      if (tabChangeListener.isIOS) {
        window.addEventListener("focus", tabChangeListener.handleFocus);
        window.addEventListener("blur", tabChangeListener.handleBlur);
      } else {
        document.addEventListener(
          "visibilitychange",
          tabChangeListener.handleVisibilityChange
        );
      }
    }
  };

  unRegister = () => {
    if (this.isListening) {
      LoggerController.events.logRevoke.log("TabChangeListener");
      if (tabChangeListener.isIOS) {
        window.removeEventListener("focus", tabChangeListener.handleFocus);
        window.removeEventListener("blur", tabChangeListener.handleBlur);
      } else {
        document.removeEventListener(
          "visibilitychange",
          tabChangeListener.handleVisibilityChange
        );
      }
    }
  };

  setIsLoading = (bool) => {
    tabChangeListener.isLoading = bool
  }

  setWaitingForApi = (api) => {
    tabChangeListener.waitingForApi = api
  }

  setLastUserAction = (action) => {
    tabChangeListener.lastUserAction = action
    if(action === 'calibrate started' || action === 'validate started'){
      tabChangeListener.calibratePointsCompleted = 0
      tabChangeListener.lastCalibrationPoint = 0
    }
  }
}

let tabChangeListener = new TabChangeListener();

export default tabChangeListener;
