{
  "title": "﻿Lumen Study Platform – Política de privacidade",
  "date": "Julho 2022",
  "contactDetails": {
    "title": "Os nossos detalhes de contacto",
    "body": [
      "Nome: Lumen Research Ltd (“Lumen”)",
      "Morada: Screenworks, Unit 215, 22 Highbury Grove, London N5 2ER",
      "Número de telefone: +44(0)2037355199 ",
      "E-mail: dataprotection@lumen-research.com",
      "Registo de Referência no ICO: ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "Esta política de privacidade explica a natureza da informação que obtemos das dos participantes nos nossos estudos de eye-tracking na Lumen Study Platform, e a nossa política gerindo qualquer informação pessoal que possa ser recolhida. "
      ],
      "heading": "Introdução"
    },
    {
      "body": [
        "Num estudo típico da Lumen, não recolhemos nenhuma informação pessoal como definido na Regulamentação Geral de Proteção de Dados do Reino Unido, mas iremos recolher pontos estimados de observação (gaze point); respostas aos estudos; meta data relacionada com quando e qual o tipo de dispositivo onde foram recolhidos os dados; e os IDs dos respondentes com o propósito de oferecer recompensas.",
        "Num estudo típico, será pedido que se sente em frente à dispositivo com webcam ou de frente para a câmera, para que se proceda à calibragem ocular para posteriormente estimar para onde olha no seu ecrã. Durante o estudo será pedido que observe alguns itens (ex. Imagens, websites ou vídeos) enquanto o software estima para onde esta a olhar. Os dados recolhidos neste estudo de ‘eye tracking’ são combinados para estimar os pontos de observação/foco no seu ecrã ao longo do tempo de duração do estudo. O software usa imagens de si, recolhidas pela sua webcam para fazer estas estimativas, no entanto não captura fotos ou vídeos seus. ",
        "Pode também ser perguntado algumas questões dobre si, a sua experiência ou os itens que viu durante o estudo. ",
        "Os dados resultantes destes estudos são enviados para a Lumen Research Ltd (“Lumen”). A Lumen procedera a análise dos dados e partilhará os resultados com os seus clientes. ",
        "Nestes estudos também será partilhado com a Lumen, por uma entidade externa, uma identificação alfanumérica do respondente para que a Lumen consiga identificar quais os respondents que completam o estudo. A Lumen também partilhará esta informação de sucesso a completer o estudo com a entidade externa com o propósito de aplicar recompensas àqueles que completem os estudos. "
      ],
      "heading": "O tipo de informação que recolhemos"
    },
    {
      "body": [
        "Ocasionalmente, poderá ser pedido aos respondentes que sejam parte de um estudo que requere mais do que uma sessão de recolha de dados. Neste caso, o ID de respondente será usado para fazer o recontacto de um único participante para que se possa iniciar o seu re-envolvimento num qualquer exercício de obtenção de dados.  Neste senário, o ID do respondente será considerado como informação pessoal.  "
      ],
      "heading": "Tipo de informação pessoal que recolhemos"
    },
    {
      "body": [
        "Da mesma forma que num estudo tipico, um ID de respondente recorrente será passado para nós pelos nossos parceiros que recrutam os participantes para os nossos estudos. Nos usá-los-emos para re-contatar os indivíduos para que se inicie o subsequente envolvimento nos estudos e também podem ser partilhados esses dados com os nossos parceiros para participação posterior.",
        "Ao abrigo da Regulamentação Geral de Proteção de Dados do Reino Unido (UK GDPR), a base legal que usamos para processar esta informação é o seu consentimento. Esse consentimento pode ser revertido a qualquer momento. Pode fazê-lo através do contacto dataprotection@lumen-research.com."
      ],
      "heading": "Como recolhemos a informação pessoal e porquê"
    },
    { "body": [], "heading": " " },
    {
      "body": [
        "A sua informação é armazenada em segurança e encriptada usando tecnologia SSL. Isto é elevado nível de tecnologia de encriptação habitualmente utilizada e é também usada por bancos para assegurar transações bancárias seguras e outras comunicações altamente sensíveis. Também é usado pela Amazon, PayPal, eBay, e outros websites que requerem sistemas de segurança ao nível do bancário.",
        "Os cientistas estimam que repartindo 256 bit de encriptação usando a actual tecnologia seria necessário substancialmente mais tempo do que a iade do universo. Os dados recolhidos são armazenados em servidores de web seguros geridos pelos Amazon Web Services (AWS). AWS é uma das plataformas de serviços de computação de cloud mais utilizadas, seguras e fiáveis e é usado pelos maiores comerciantes de serviços “cloud-based” incluindo Netflix e Expedia. ",
        "Mantemos os identificadores permanentes armazenados durante o período de tampo necessário para re-contatar os respondentes em qualquer estudo com várias etapas. Isto pode variar dependendo da natureza do estudo e será notificado aquando aceitar em participar. Quando a coleta de dados para este tipo de projeto for concluída, os identificadores serão mantidos somente com o propósito de analise os dados e elaboração do relatório. Quando a analise e relatório de um projeto estiverem completes, remover-se-ão todos os dados pessoais das nossas bases de dados."
      ],
      "heading": "Como armazenamos a sua informação pessoal "
    }
  ],
  "dataProtection": {
    "body": [
      "De acordo com a lei de proteção de dados, tem direitos que incluem:"
    ],
    "bullets": [
      {
        "title": "Direito ao acesso",
        "text": ["Tem direito a pedir-nos copias da sua informação pessoal. "]
      },
      {
        "title": "Direito de retificação",
        "text": [
          "Tem o direito de pedir-nos que sejam revistos dados pessoais que considere imprecisos. Também tem o direito de pedir-nos que complete informação que pense estar incompleta. ",
          "Direito de exclusão- Tem o direito de pedir que apaguemos a sua informação pessoal em certas circunstâncias. "
        ]
      },
      {
        "title": "Direito à restrição de processamento",
        "text": [
          "Tem o direito de pedir-nos que restrinjamos o processamento da sua informação pessoal em certas circunstâncias. "
        ]
      },
      {
        "title": "Tem o direito de retirar consentimento",
        "text": [
          "Tem o direito de retirar o seu consentimento de processamento a sua informação pessoal."
        ]
      },
      {
        "title": "Direito da portabilidade de dados",
        "text": [
          "Tem o direito de pedir que façamos a transferência da informação pessoal que nos forneceu a outra organização, ou para si, em certas circunstâncias.",
          "Não lhe será pedido que pague nenhuma quantia para que faca exercício dos seus direitos. Caso faça um pedido, temos um mês para que este seja respondido.",
          "Por favor, contacte-nos através do email dataprotection@lumen-research.com caso pretenda fazer um pedido."
        ]
      }
    ],
    "title": "Os seus direitos de proteção de dados"
  },
  "complaints": {
    "body": [
      "Se tiver alguma preocupação de como usamos a sua informação pessoal, pode fazer uma reclamação através do endereço de email dataprotection@lumen-research.com.",
      "Pode também reclamar à ICO se estiver descontente da forma como usamos os seus dados."
    ],
    "address": {
      "body": [
        "Information Commissioner’s Office",
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ],
      "title": "Morada do ICO:            "
    },
    "title": "Como reclamar",
    "number": "Linha de apoio: 0303 123 1113",
    "website": "Website do ICO: https://www.ico.org.uk"
  }
}
