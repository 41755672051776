{
  "app.button.faqs": "Preguntas frecuentes",
  "app.button.terms": "Condiciones de uso",
  "app.button.privacy": "Política de privacidad",
  "app.button.feedback": "Comentarios",
  "app.button.selectLanguage": "Seleccionar idioma",
  "app.button.help": "Ayuda",
  "app.button.start": "Empezar",
  "app.button.next": "Siguiente",
  "app.button.submit": "Enviar",
  "app.button.takepart": "De acuerdo, voy a participar",
  "app.menu.feedback.content": "Si tienes algún problema con el estudio o simplemente quieres dejar algún comentario, puedes hacerlo aquí.",
  "app.contactModal.feedback": "¡Gracias por tus comentarios!",
  "app.takePartModal.line1": "Si te vas ahora, no podrás optar a ningún incentivo por tu participación.",
  "app.takePartModal.line2": "¡Solo necesitas una cámara web!",
  "app.takePartModal.reason1": "No tengo cámara web.",
  "app.takePartModal.reason2": "Me incomoda utilizar mi cámara web para este estudio.",
  "app.takePartModal.reason3": "Ahora no tengo tiempo para esto.",
  "app.takePartModal.reason4": "Otra razón.",
  "app.generic.redirect": "Se te reenviará en breve.",
  "app.prestart.loading": "Cargando, por favor, espera.",
  "app.index.title": "Bienvenido/a",
  "app.index.content.line1": "Durante el estudio, usaremos tu cámara para calcular dónde estás mirando. No te preocupes, no tomaremos ninguna foto ni vídeo.",
  "app.index.content.line1alt": "Siéntate cómodamente y toca en Empezar para comenzar.",
  "app.index.content.line2": "¿Tiene cámara tu dispositivo? De ser así, pulsa el botón Empezar que hay debajo.",
  "app.index.rejection": "Lo sentimos, parece que has seguido un enlace incorrecto.",
  "app.index.webview": "Lo sentimos, parece que has accedido a nuestra página desde una vista web. Abre este enlace dentro de tu navegador. Es posible que puedas hacerlo desde dentro del menú vista web.",
  "app.index.webview.chrome.1": "Toca el botón Menú que hay encima y selecciona «Abrir en Chrome».",
  "app.index.webview.chrome.2": "Se abrirá la encuesta en una aplicación de navegador, de modo que pueda funcionar correctamente.",
  "app.index.webview.android.1": "Toca el botón Empezar que hay debajo para abrir la encuesta en una aplicación de navegador, de modo que pueda funcionar correctamente.",
  "app.index.webview.android.2": "Si esto no funciona, abre el vínculo a esta página en una aplicación de navegador manualmente (es decir: copia y pega el enlace en la barra de direcciones de Chrome o usa una opción como «Abrir en Chrome» en la barra de menús que hay arriba).",
  "app.index.webview.safari.1": "Toca el icono de Safari que hay debajo de la pantalla para continuar.",
  "app.index.webview.safari.2": "Se parece a este:",
  "app.index.webview.safari.3": "Se abrirá la encuesta en Safari, de modo que pueda funcionar correctamente.",
  "app.index.wifi": "Parece que tu conexión a internet es demasiado lenta. ¿Estás conectado/a a la red wifi?",
  "app.index.tellmemore": "Cuéntame más.",
  "app.index.takepart": "No deseo participar.",
  "app.instructions.title": "Instrucciones",
  "app.instructions.instruction1": "De ser posible, no lleves las gafas puestas durante el estudio.",
  "app.instructions.instruction2": "Siéntate de modo que puedas mantener quieta la cabeza y también el dispositivo.",
  "app.instructions.instruction3": "Mueve los ojos, no la cabeza.",
  "app.instructions.instruction4": "¿Empezamos?",
  "app.instructions.instruction5": "Usa la conexión wifi para no gastar datos.",
  "app.instructions.instruction6": "Lee con atención estas instrucciones.",
  "app.instructions.instruction7": "Apoya el teléfono en una superficie estable.",
  "app.instructions.instruction7nav": "Usa tu dedo para moverte en cualquier dirección por la pantalla.",
  "app.instructions.instruction8": "Asegúrate de que la habitación está bien iluminada, preferente desde arriba (no desde atrás).",
  "app.setup.title": "Configuración",
  "app.setup.instruction0": "Mantén tu dispositivo en modo retrato (vertical).",
  "app.setup.instruction0a": "Gira tu dispositivo para que esté en modo retrato (vertical).",
  "app.setup.instruction0b": "Gira tu dispositivo para que la cámara esté a la izquierda.",
  "app.setup.instruction0c": "Gira tu dispositivo para que la cámara esté a la derecha.",
  "app.setup.instruction1": "Inicializando...",
  "app.setup.instruction2": "Cuando te detecte, coloca la cabeza dentro del cuadro.",
  "app.setup.instruction3": "Mantén la cabeza y el dispositivo en posición hasta que hayas completado la encuesta.",
  "app.setup.instruction4": "Ahora solo tenemos que validarlo.",
  "app.setup.instruction5": "¡Gracias! Mantén la cabeza y el dispositivo en la misma posición hasta que hayas completado le encuesta.",
  "app.setup.instruction6": "Desliza el dedo hacia arriba para ocultar la barra de direcciones.",
  "app.setup.retry": "Error detectado. No muevas la cabeza y el dispositivo y no tapes la cámara, por ejemplo, con los dedos. Usa el botón de abajo para volver a intentarlo.",
  "app.setup.retryInit": "Error detectado. Asegúrate de que el navegador pueda acceder a tu cámara. Si has denegado permiso de acceso a la cámara anteriormente, tendrás que aceptarlo en la configuración de privacidad del navegador. Usa el botón de abajo para volver a intentarlo.",
  "app.setup.retryButton": "Reintentar",
  "app.setup.calibrationProp.watch": "Sigue el punto cuando se muestre en la pantalla",
  "app.setup.calibrationProp.click": "Haz clic en cualquier sitio para continuar",
  "app.setup.calibrationProp.tap": "Toca en cualquier sitio para continuar",
  "app.study.validationProp.watch": "Sigue el punto cuando se muestre en la pantalla",
  "app.study.validationProp.restart": "Vuelve a intentarlo",
  "app.study.validationProp.click": "Haz clic en cualquier sitio para continuar",
  "app.study.validationProp.tap": "Toca en cualquier sitio para continuar",
  "app.error.title": "Error",
  "app.error.generic": "¡Vaya! Algo ha salido mal.",
  "app.error.initialise": "No ha sido posible inicializar.",
  "app.error.findCamera": "No ha sido posible encontrar la cámara.",
  "app.error.headPosition": "No ha sido posible determinar la posición de tu cabeza.",
  "app.error.calibrate": "No ha sido posible hacer la calibración.",
  "app.error.validate": "No ha sido posible hacer la validación.",
  "app.error.start": "Error al iniciar.",
  "app.error.assign": "Error al asignar.",
  "app.error.osnotallowed": "Tu sistema operativo no es compatible. Comprueba qué dispositivo puedes usar para esta encuesta e inténtalo de nuevo.",
  "app.error.demoquotafullnoactivecell": "Gracias por el interés que has mostrado. La encuesta está cerrada para tu grupo demográfico.",
  "app.invalid.title": "No válido",
  "app.invalid.generic": "Lo sentimos, no cumples los requisitos necesarios para realizar este estudio.",
  "app.completion.title": "Finalización",
  "app.completion.congratulations": "¡Enhorabuena! Has completado el estudio. Ahora finalizará el acceso a tu cámara por parte de esta encuesta.",
  "app.questions.required": "Esta pregunta es obligatoria.",
  "app.faq.question1": "P: ¿Qué es la Lumen Study Platform y qué implica participar en un estudio de seguimiento ocular?",
  "app.faq.answer1": "R: La encuesta implica utilizar tecnología de seguimiento ocular. Te pediremos que te sientes delante de la cámara web o la cámara frontal de tu dispositivo y realices una calibración para que podamos calcular a qué parte de la pantalla estás mirando. Durante la encuesta, se te pedirá que mires varios elementos (como imágenes, páginas web o vídeos) mientras la herramienta calcula dónde estás mirando. Los datos recogidos en este «seguimiento ocular» son las coordenadas de los puntos de tu pantalla en los que fijas la mirada, calculadas a lo largo del tiempo. El software utiliza imágenes que toma tu cámara web para realizar estos cálculos, pero no hace fotos ni vídeos. Es posible que también se te hagan algunas preguntas relacionadas contigo, con tu experiencia y con los elementos que has visto durante el estudio. Los datos se envían a Lumen Research Ltd. Lumen Research los analizará y compartirá los resultados con sus clientes.",
  "app.faq.question2": "P: ¿Por qué el software requiere una cámara /cámara web y cómo se utiliza esta?",
  "app.faq.answer2": "R: El software utiliza imágenes de tu cámara web o la cámara de tu dispositivo para calcular a qué parte de la pantalla estás mirando. Lo hace mediante una combinación de seguimiento facial y ocular que analiza las imágenes de forma local en tu dispositivo en tiempo real. Estas imágenes son luego procesadas y se te muestran durante la calibración para que puedas situarte correctamente, pero, para proteger tu privacidad, las imágenes no quedan grabadas. Los datos recopilados con el seguimiento ocular no son imágenes, sino datos numéricos sobre los puntos del estímulo en los que fijas la mirada a lo largo de tiempo.",
  "app.faq.question3": "P: ¿Me tomáis imágenes mientras se realiza el seguimiento ocular?",
  "app.faq.answer3": "R: Únicamente se utilizan imágenes para permitirte que te coloques correctamente durante la calibración. Estas imágenes no se guardan, solo las ves tú. Los datos recopilados y grabados con el seguimiento ocular no son imágenes, sino datos numéricos sobre los puntos del estímulo en los que fijas la mirada calculados a lo largo de tiempo.",
  "app.faq.question4": "P: ¿Qué datos se recogen durante la encuesta?",
  "app.faq.answer4.line1": "R: El software recogerá, en todo o en parte, estos cinco tipos de datos:",
  "app.faq.answer4.line2": "«Identificador de usuario»: el identificador de usuario se utiliza para identificar a los encuestados (con el fin de administrar los incentivos a la participación);",
  "app.faq.answer4.line3": "«Datos de fijación de la mirada»: calculan a qué parte de la pantalla está mirando el usuario cuando se le muestran estímulos en la pantalla",
  "app.faq.answer4.line4": "«Datos de la encuesta»: respuestas que dan los encuestados a las preguntas planteadas",
  "app.faq.answer4.line5": "«Metadatos»: Datos sobre la hora a la que cada encuestado ha participado con éxito en la encuesta.",
  "app.faq.answer4.line6": "“Datos físicos del dispositivo»: datos sobre el ordenador con el que participa un usuario; por ejemplo, el nivel de RAM, el tipo de CPU que tiene el dispositivo y el agente de usuario (sistema operativo y versión del navegador), además de información sobre la pantalla del ordenador (tamaño y resolución), cámara web (marca y resolución), y programas antivirus.",
  "app.faq.question5": "P: ¿Cómo se utilizarán los datos recopilados?",
  "app.faq.answer5.line1": "R: Los datos recopilados se utilizarán para explorar cómo ve e interactúa cada persona con varios tipos de imágenes y soportes visuales, y para estimar la opinión que tiene de las imágenes que se le muestran.",
  "app.faq.answer5.line2": "Es posible que Lumen comparta los datos del estudio con empresas afiliadas, socios comerciales y clientes. Aunque los datos del estudio puedan compartirse con empresas afiliadas, socios comerciales y clientes, esto se llevará a cabo por lo general de forma anónima. Dichas empresas afiliadas, socios y clientes podrían utilizar estos datos para crear descripciones resumidas de una amplia gama de comportamientos y segmentos de consumo con el fin de desarrollar productos o de ofrecer soluciones de marketing a sus clientes y consumidores.",
  "app.faq.answer5.line3": "Lumen recopila los «Datos físicos del dispositivo» y los «Metadatos» para ayudar a gestionar la recogida y el tratamiento de los datos, lo que incluye el control de calidad y la gestión de volúmenes.",
  "app.faq.answer5.line4": "Los datos recopilados se conservarán durante el periodo necesario para cumplir los fines indicados.",
  "app.faq.question6": "P: ¿Hasta qué punto están seguros mis datos?",
  "app.faq.answer6": "R: Los datos recopilados por Lumen se cifran mediante tecnología SSL. Es la tecnología avanzada de cifrado más frecuente, y la usan los bancos para proteger las operaciones bancarias y otras comunicaciones altamente confidenciales. También la utilizan Amazon, PayPal, eBay y otros sitios web que requieren sistemas de seguridad de nivel bancario. Los científicos calculan que descifrar el código de 256 bits con la tecnología que existe actualmente llevaría mucho más tiempo que la edad del universo. Los datos recopilados se guardan en servidores web seguros gestionados por Amazon Web Services (AWS). AWS es una de las plataformas de servicios de informática en la nube más seguras, fiables y utilizadas, y la usan grandes servicios comerciales basados en la nube como Netflix y Expedia.",
  "app.faq.question7": "P: ¿Qué obligaciones tienes por lo que respecta al contenido que se te muestra?",
  "app.faq.answer7": "R: Al participar, indicas que entiendes y aceptas que el contenido que se te muestre durante este estudio puede ser confidencial, propiedad de terceros o sujeto a leyes sobre marcas comerciales y propiedad intelectual, y que no harás capturas de pantalla, grabarás ni de otro modo copiarás ninguna parte del contenido, ni distribuirás ninguna parte del contenido del estudio de ninguna otra manera.",
  "app.faq.question8": "P: No sé cómo de buena es mi cámara web. ¿Funcionará para hacer un seguimiento ocular?",
  "app.faq.answer8": "R: El sistema funciona con un gran número de cámaras web. No debería suponer ningún problema.",
  "app.faq.question9": "P: He terminado el estudio. ¿Tengo que hacer algo más?",
  "app.faq.answer9": "R: No es necesario que hagas nada más. El software se cerrará de forma automática.",
  "app.faq.question10": "P: ¿Tengo que estar conectado a internet para participar?",
  "app.faq.answer10": "R: Sí. Necesitarás tener conexión a internet para descargar el programa y completar la tarea.",
  "app.faq.question11": "P: ¿Debo completar la tarea sobre una superficie estable?",
  "app.faq.answer11": "R: Sí. Para obtener los mejores resultados, debes sentarte cómodamente delante de la pantalla de tu ordenador y, tras la calibración, mantener la cabeza quieta mientras se te presenta el estímulo y hasta que se te avise. Aconsejamos no participar si estás en un tren, con tu portátil sobre las rodillas, etc.",
  "app.faq.question12": "P: La sección de preguntas frecuentes no ha resuelto mi problema. ¿Qué hago?",
  "app.faq.answer12": "R: Escríbenos a study-feedback@lumen-research.com y te responderemos lo antes posible.",
  "app.faq.patent": "La prueba de contenidos web de Lumen funciona con nuestra propia tecnología de pruebas en contexto (número de solicitud de patente del Reino Unido 1816158.8)",
  "app.terms.line1": "Estas condiciones de uso («Condiciones de uso»”) regulan el acuerdo entre usted y Lumen Research Ltd («Lumen» o «Nosotros»), sociedad inscrita en Inglaterra con el número de registro empresarial 8682432, con sede social en Unit 215, 22 Highbury Grove, Londres N5 2EF y con el número de referencia del registro de protección de datos ZA130772, en relación con su uso del software de seguimiento ocular «ViewPoints» (el «Software») en su dispositivo.",
  "app.terms.line2": "1. Aceptación de los términos",
  "app.terms.line3": "Al utilizar el Software, confirma que ha aceptado cumplir los términos de este Acuerdo. Si no acepta los términos y condiciones de este Acuerdo, no complete el estudio de ViewPoints.",
  "app.terms.line4": "2. Información confidencial",
  "app.terms.line5": "Cuando instale, utilice o interactúe de cualquier forma con ViewPoints, puede que tenga acceso a información o contenidos que pertenezcan a Lumen o guarden relación con Lumen, incluida información identificada o tratada como confidencial por parte de Lumen o de cualquiera de los clientes de Lumen, o que por su carácter o por las circunstancias o la forma en que sea divulgada tenga carácter evidentemente confidencial, incluida (sin perjuicio del carácter general de lo anterior) cualquier información sobre planes empresariales, nuevas oportunidades de negocio que estén en fase de ejecución, proyectos de investigación y desarrollo, diseños, procesos secretos, códigos, diseños de software, secretos comerciales, desarrollo y formulación de productos y servicios, conocimientos, invenciones, estadísticas y previsiones de ventas, estrategias y planes de marketing, costes, ganancias y pérdidas y otra información de tipo financiero (salvo en la medida en que esté publicada en cuentas auditadas), estructuras de precios y descuentos (ya esté todo ello registrado o no por escrito o en formato electrónico u otros) («Información confidencial»). Dicha Información confidencial seguirá siendo propiedad única y exclusiva de Lumen o de sus clientes, y usted en ningún caso podrá obtener ni recibir ningún derecho, título ni interés sobre dicha Información confidencial.",
  "app.terms.line6": "No deberá, ni durante su participación en el estudio ViewPoints ni en ningún otro momento (sin limitación) tras haber concluido dicha participación: divulgar o comunicar a ninguna persona; utilizar para tus propios fines; o por no haber ejercido el cuidado y la diligencia debidos, hacer que se realice una divulgación no autorizada de ninguna parte de la Información confidencial. En todo momento deberá tomar medidas oportunas para impedir la publicación o divulgación de cualquier parte de la Información confidencial. Estas restricciones dejarán de aplicarse a cualquier información que se haga pública de otra manera que no se deba a su propio incumplimiento.",
  "app.terms.line7": "3. Propiedad de Lumen",
  "app.terms.line8": "El Software ViewPoints y todos los datos, códigos, claves, contraseñas, diseños, dibujos, grabaciones y otros documentos o materiales relacionados con el Software serán y seguirán siendo propiedad de Lumen durante toda su participación en un estudio de ViewPoints. Lumen posee todos los derechos intelectuales y patrimoniales en todos y cada uno de los derechos de propiedad intelectual o industrial actuales y futuros por cualesquiera documentos, materiales, modelos, diseños, dibujos, procesos, invenciones, fórmulas, códigos informáticos, datos, interfaces de sistemas, metodologías, conocimientos, Información confidencial u otros trabajos realizados, creados, ideados, desarrollados, mejorados, deconstruidos o descubiertos por usted, bien por su cuenta o junto con cualquier otra persona en relación con el Software o el Dispositivo, y susceptible de ser utilizado o adaptado para su uso en él o en relación con él (esté registrado o no), incluidas todas las patentes, copyrights, derechos de diseño, derechos de bases de datos, marcas comerciales, derechos de topografía de productos semiconductores, derechos de variedades de plantas, derechos de internet/nombres de dominios, conocimientos y todas y cualesquiera aplicaciones para todo lo anterior, y todos y cualesquiera derechos aplicables a cualquier parte de lo anterior actualmente y en el futuro («Derechos de propiedad»). Ningún Derecho de propiedad pasará a ser suyo en ningún momento.",
  "app.terms.line9": "4. Condiciones y restricciones de uso",
  "app.terms.line10": "El software ViewPoints cuenta con licencia para usuarios finales. Sujeto al presente Acuerdo, acordamos permitirle, de manera limitada y no exclusiva, revocable, no transferible y no susceptible de sublicenciarse, utilizar el Software ViewPoints en un dispositivo que sea de su propiedad o que esté bajo tu control, o un ordenador personal respecto del cual afirma y garantiza que posee todos los derechos y permisos necesarios para dicha instalación. Para evitar toda duda, no podrá utilizar para ningún fin ajeno a ViewPoints, traducir, copiar, reproducir, transmitir por medios de telecomunicación, adaptar, fusionar, cambiar ni modificar el Software de ninguna manera.",
  "app.terms.line11": "Se comprometes a no: (i) copiar, modificar, adaptar, hacer disponible, alquilar, utilizar en tiempo compartido, crear un trabajo derivado del Software, o intentar vender o de otra manera transferir ningún derecho sobre el Software (ii) desmontar, descompilar o realizar ingeniería inversa sobre ninguna parte del Software por ningún medio; ni permitir que se extraiga ni ayudar a que ninguna otra persona ni entidad extraiga o intente extraer el código fuente, desmonte, descifre, descompile o realice ingeniería inversa sobre el Software; ni tomar ninguna otra medida para extraer información sobre el diseño en relación con el Software; (iii) utilizar el Software para ningún fin que no sea aquel para el que se le ha entregado; (iv) utilizar arañas, robots ni otras técnicas de extracción automatizada de datos para catalogar, descargar, guardar o de otro modo reproducir o distribuir el Software, o manipular los resultados de cualquier estudio o cualquier otra actividad; (v) tomar cualquier medida para interferir con el Software o el uso del Software por parte de ninguna persona, incluidos, sin limitación, sobrecargar, «inundar», «bombardear con correo» o «colgar» el Software y el sistema de almacenamiento de datos que utiliza el Software a través de Amazon Web Services (vi) enviarnos ningún código ilegal, malicioso o dañino, incluido, sin limitarse a ello, ningún virus, spyware, adware o ningún otro tipo de código dañino.",
  "app.terms.line12": "Todos los derechos que no se le hayan concedido expresamente en virtud de este Acuerdo quedan reservados a nosotros y a nuestros licenciatarios. Nos exime de toda obligación, en virtud de este Acuerdo o por cualquier otra razón, de corregir cualesquiera problemas, defectos o errores del Software, o de prestar soporte y mantenimiento ni realizar mejoras, modificaciones, actualizaciones o perfeccionamientos al Software. No seremos responsables de proporcionarte asistencia, ni a usted ni a nadie que usted designe, sobre el uso del Software de ninguna manera.",
  "app.terms.line13": "No aceptamos ninguna responsabilidad por ningún daño causado a su PC, incluidos, sin limitarse a ello, los daños que sean resultado de su uso del Software. No aceptamos ninguna responsabilidad por ningún gasto adicional por consumo de datos en el que incurra como resultado de la recopilación de datos por parte del Software durante su participación en el Estudio.",
  "app.terms.line14": "5. Descargo de responsabilidad",
  "app.terms.line15": "Ni nosotros ni nuestros respectivos directores, oficiales, empleados, agentes, representantes, licenciatarios y proveedores afirmamos, garantizamos ni estipulamos (a) que el software estará disponible puntualmente, o que el acceso al software será ininterrumpido o seguro; (b) que se corregirán defectos o errores; o (c) que el software o los servidores o redes a través de los cuales estarán disponibles el software y el dispositivo sean seguros o estén libres de virus y otros componentes dañinos. No estaremos obligados a prestar ningún servicio de mantenimiento ni de soporte en relación con el software.",
  "app.terms.line16": "6. Limitación de responsabilidad",
  "app.terms.line17": "(a) En la medida máxima en que lo permita la legislación aplicable, usted acepta que ni nosotros ni nuestros respectivos directores, oficiales, empleados, agentes, representantes, licenciatarios y proveedores seremos responsables por ninguna pérdida, daño o coste de ningún tipo que se derive de su instalación, uso o acceso al software, su uso o incapacidad para usar el software, ninguna información ni materiales puestos a su disposición con el software o a través del software o que guarden cualquier otra relación con el software o de cualquier otra manera con este acuerdo, independientemente de la naturaleza de los derechos legales, equitativos o reglamentarios que se afirme hayan sido infringidos.",
  "app.terms.line18": "(b) En la medida máxima en que lo permita la legislación aplicable, su único y exclusivo recurso para solucionar cualquier disconformidad con el software será dejar de usarlo.",
  "app.terms.line19": "(c) En ningún caso ninguna de nuestras entidades, directores, oficiales, empleados, agentes ni representantes serán responsables ante usted por ningún daño, pérdida de ingresos, de oportunidades, de reputación, de uso, de datos o de otros intangibles, sean adicionales, indirectos, emergentes, estatutarios, ejemplares, punitivos o especiales, o por la interceptación no autorizada por parte de terceros de los datos recopilados por el software, ya fuera previsible o no, sea cual sea su causa, y en virtud de cualquier contrato, responsabilidad civil (incluida, sin limitarse a ello, la negligencia), la responsabilidad absoluta o cualquier otra teoría, aun en el caso de haber sido advertida con anterioridad la posibilidad de que pudieran originarse dichos daños.",
  "demo.index.content.line1": "Durante esta demostración utilizaremos tu cámara para calcular dónde estás mirando. No te preocupes, no tomaremos ninguna foto ni vídeo.",
  "app.thankyou": "Gracias",
  "app.button.close": "Cerrar",
  "app.button.return": "Regresar",
  "app.init.welcome": "Bienvenido a la plataforma de estudio de Lumen Research",
  "app.init.intro": "La plataforma de estudio nos permite incorporar y configurar usuarios para un estudio de anuncios determinado.",
  "app.init.message1": "Dependiendo del tipo de estudio, se guiará al usuario a través de un conjunto de tareas.",
  "app.init.message2": "Estas tareas incluyen; realizar una encuesta, leer un conjunto de instrucciones y realizar una calibración de seguimiento ocular",
  "app.init.message3": "Ahora lo guiaremos a través de un estudio de demostración.",
  "app.init.message4": "A lo largo de este estudio, es posible que habilitemos su cámara web para realizar seguimiento ocular, pero ninguno de sus datos se registrará.",
  "app.init.message5": "El estudio actual está configurado para usar los siguientes enlaces en descartar/completar.",
  "app.index.webcamRetry": "No pudimos detectar una cámara web, que es necesaria para este estudio. Por favor proporcione acceso a su cámara web y vuelva a intentarlo.",
  "app.setup.retryPositioning": "No pudimos determinar la posición de su cabeza. Por favor mantenga la cabeza y el dispositivo quietos y no cubra la cámara, por ejemplo, con los dedos. Use el botón de abajo para volver a intentarlo",
  "app.setup.retryBroker": "No pudimos completar el proceso de calibración. Por favor intente cerrar las aplicaciones y pestañas no utilizadas para liberar recursos",
  "app.setup.calibrationProp.watch.george": "A continuación, cuando vea una cara, por favor siga mirándola mientras refleja los movimientos de su cabeza",
  "app.study.render.error": "Dominio no válido para el paso de procesamiento.",
  "app.error.cellquotafullnoactivecell": "No se ha encontrado ninguna celda activa para este estudio. Las cuotas de celdas están llenas.",
  "app.error.missingpanelparameter": "Parámetro del panel faltante.",
  "app.completion.congratulations-noet": "¡Felicitaciones! Ha completado el estudio.",
  "app.error.previousfail": "Anteriormente reprobó este estudio, por favor utilice el enlace original para comenzar desde el principio.",
  "app.error.previoussuccess": "Ha completado este estudio anteriormente.",
  "app.error.route": "Intentó iniciar un estudio que ya estaba en progreso, por favor utilice el enlace original para comenzar desde el principio.",
  "app.error.accesscode": "Ha intentado iniciar un estudio que no existe, por favor verifique su enlace.",
  "app.error.webcam": "Este estudio requiere acceso a una cámara web",
  "app.error.adblocker": "Este estudio requiere que el bloqueo de anuncios esté deshabilitado.",
  "app.error.startsession": "No se pudo iniciar la sesión",
  "app.error.fullscreen": "Este estudio requiere una vista de pantalla completa.",
  "app.error.zoom": "Este estudio requiere que el zoom del navegador esté configurado al 100%.",
  "app.index.webcamInstructions": "Estamos a punto de solicitar acceso a su cámara web, si recibe una ventana emergente, por favor haga clic en Permitir.",
  "app.setup.retryBack": "Por favor, absténgase de presionar el botón atrás del navegador durante este estudio, puede resultar en la pérdida de la recompensa. Use el botón a continuación para volver a intentarlo.",
  "app.setup.retryaudible": "Por favor asegurese de que su dispositivo no esté silenciado e inténtalo de nuevo.",
  "app.restore.retry": "No ha pasado suficiente tiempo en el paso anterior, ",
  "app.restore.timeremaining": " minutos restantes",
  "app.error.postexposure": "Lo siento, ya ha agotado todos sus intentos y, lamentablemente, no puede continuar con el estudio.",
  "app.index.content.line1gpdrwarning": "Durante esta encuesta, usaremos la cámara frontal de su dispositivo para capturar sus imágenes y secuencias de vídeo mientras realiza varios ejercicios que implican observar estímulos.",
  "app.index.adblockRetry": "¡Vaya! Hemos detectado un bloqueador de anuncios. Para obtener la mejor experiencia durante la encuesta, desactívelo y vuelva a intentarlo.",
  "app.index.zoomRetry": "Necesitamos que el navegador esté configurado al 100 % de zoom, acerque/aleje e inténtelo de nuevo.",
  "app.index.fullscreendesktopretry": "Esta encuesta requiere una vista de pantalla completa, asegúrese de que la ventana de su navegador esté maximizada.",
  "app.index.fullscreendesktoptest": "Esta encuesta requiere que la ventana de su navegador esté en pantalla completa, necesitamos realizar una prueba para asegurarnos de que la ventana de su navegador esté maximizada.",
  "app.index.fullscreendesktoptest2": "Aparecerán dos botones en breve, haga clic en ellos para continuar.",
  "app.index.fullscreendesktopmaximised": "Mi pantalla ya está maximizada",
  "app.index.fullscreendesktopskip": "Gracias, hemos registrado esta instancia y mejoraremos nuestros procesos de detección.",
  "app.index.fullscreendesktopreset": "Hemos detectado un cambio en el tamaño de la ventana, reiniciando la prueba.",
  "app.index.gotomobile": "Los usuarios de escritorio no son aptos para este estudio, utilice el código QR a continuación para abrir el estudio en el dispositivo móvil.",
  "app.index.returntopanel": "O, haga clic en el botón de abajo para volver al panel.",
  "app.setup.instruction0d": "Gire su dispositivo para que esté en modo horizontal.",
  "app.setup.instructioncamera": "Haga clic en el botón de abajo para solicitar acceso a la cámara. Para completar la encuesta, debe otorgar permisos de cámara durante todo el estudio.",
  "app.setup.cameraText": "Solicitar acceso",
  "app.setup.instruction3audio": "Escuche atentamente y siga las instrucciones que aparecen en la pantalla.",
  "app.faq.gdpr.intro1": "En este estudio, utilizaremos la cámara frontal de un dispositivo móvil para captar imágenes y secuencias de vídeo de un usuario mientras se le solicita que realice varios ejercicios que consisten en observar estímulos bajo una variedad de condiciones ambientales (por ejemplo, iluminación y posicionamiento).  Se estima que la participación dure alrededor de 5 minutos.  Los datos se utilizarán para ayudar al desarrollo continuo de software que pueda estimar dónde miran los usuarios en una pantalla.",
  "app.faq.gdpr.intro2": "Esta investigación se lleva a cabo de acuerdo con el Código de conducta de la MRS (Sociedad de investigación de mercado, por sus siglas en inglés) y la Ley de protección de datos de 2018.  Su privacidad es importante para nosotros.  Antes de participar en este estudio, debe confirmar que acepta que al participar en este estudio, comprende los datos que recopilamos y cómo se utilizarán, tal como se describe en esta Política de privacidad, y acepta que estos datos se utilicen de este modo.  Esta política describe con más detalle y precisión qué información se recopila y cómo se usa y comparte.",
  "app.faq.gdpr.question1": "¿Qué datos se recopilarán?",
  "app.faq.gdpr.answer1.line1": "Este estudio implicará la recopilación de los siguientes tipos de datos:",
  "app.faq.gdpr.answer1.line2": "“Características de la sesión”: una identificación anónima que se utiliza para identificar una recopilación de datos de la “Sesión” en particular y datos sobre las condiciones de prueba ambientales prevalecientes durante la sesión.",
  "app.faq.gdpr.answer1.line3": "“Características del participante”: datos sobre un encuestado que participa en una sesión determinada, incluida información sobre los atributos demográficos (p. ej.: edad, sexo), étnicos (p. ej.: tipo de piel) y biológicos (p. ej.: color de ojos) del participante.",
  "app.faq.gdpr.answer1.line4": "“Archivo de vídeo”: imágenes y/o secuencias de vídeo realizadas con la cámara frontal del dispositivo para capturar imágenes de los participantes mientras observan y potencialmente interactúan con los estímulos que se muestran en una pantalla o pantallas. Se capturarán imágenes de los ojos y la cara de un participante y cualquier otra cosa que la cámara frontal pueda captar sin querer mientras se completa la tarea. ",
  "app.faq.gdpr.answer1.line5": "“Metadatos”: datos sobre el momento en que los individuos interactuaron con los estímulos, vinculados a la marca de tiempo de cada imagen recopilada.",
  "app.faq.gdpr.answer1.line6": "“Datos del dispositivo físico”: datos físicos sobre el dispositivo, incluido el nombre del dispositivo, el tamaño de la pantalla, la rotación de la pantalla y la rotación del sensor de la cámara.",
  "app.faq.gdpr.answer1.line7": "Al participar en esta investigación, comprende y acepta que el Contenido que se le muestra en este estudio puede ser confidencial, propiedad de un tercero y/o sujeto a las leyes de marcas comerciales o derechos de autor, y que no hará capturas de pantalla, grabará o, de alguna otra manera, copiará cualquiera de los Contenidos o redistribuirá cualquiera de los Contenidos del estudio de cualquier manera.",
  "app.faq.gdpr.question2": "¿Cómo se utilizarán/procesarán los datos?",
  "app.faq.gdpr.answer2": "Los datos se utilizarán para el desarrollo de software que pueda estimar si los usuarios están presentes frente a un dispositivo, si están mirando estímulos y en qué parte de los estímulos pueden estar mirando.  También se puede utilizar con fines de demostración o para explicar o ayudar a evaluar a terceros (clientes, proveedores, socios) sobre cómo se ha desarrollado nuestra tecnología y su rendimiento.  Los datos pueden revisarse por cualquier número de 'anotadores' de datos: empleados, subcontratistas, socios o cualquier otro tercero al que se le solicitará que observe las secuencias de vídeo/imágenes para evaluar los 'estados' de atención de los participantes durante la investigación (p. ej.: ¿están presentes en el campo de visión de la cámara? ¿Están mirando a la pantalla?)  Las bases legales para el tratamiento de los datos serán el consentimiento informado y los intereses legítimos. ",
  "app.faq.gdpr.question3": "¿Cuánto tiempo se conservarán los datos?",
  "app.faq.gdpr.answer3": "Retendremos los datos para su uso durante el tiempo que sea razonablemente necesario para los fines descritos anteriormente.  El software que se está desarrollando se actualiza y evalúa periódicamente y estos datos se utilizarán para evaluar cada interacción, por lo que se espera que se conserven y conserven para este fin durante varios años, si no indefinidamente.  Los datos se eliminarán si se considera que ya no son útiles para desarrollar o evaluar el software de prueba de estímulos. ",
  "app.faq.gdpr.question4": "¿Quién recibirá y procesará los datos?",
  "app.faq.gdpr.answer4": "Lumen Research Ltd. conservará los datos. Lumen Research Ltd es una empresa registrada en Inglaterra, número de empresa 8682432.  Su domicilio social se encuentra en LABS house, 15-19 Bloomsbury Way, Holborn, Londres WC1A 2TH. Su referencia de registro de protección de datos es ZA130772.  Su Oficial de protección de datos es Matt Robinson (matt.robinson@lumen-research.com).  Tiene derecho a presentar una queja sobre la gestión y procesamiento de sus datos en cualquier momento comunicándose con la Autoridad de protección de datos del Reino Unido, la ICO (Oficina del comisionado de información, por sus siglas en inglés).  Los datos de contacto de la ICO están disponibles en su sitio web en https://ico.org.uk/global/contact-us/. Al participar en el estudio, otorga a Lumen una licencia mundial, libre de regalías, sublicenciable y transferible para alojar, almacenar, usar, exhibir, reproducir, modificar, adaptar, editar, publicar y distribuir estos datos.",
  "app.faq.gdpr.question5": "¿Tengo derecho a acceder a mis datos personales?",
  "app.faq.gdpr.answer5": "Sí, tiene derecho a acceder a sus datos personales, sin embargo, es importante que tenga en cuenta que su nombre no se almacenará con los datos recopilados durante este ejercicio.  Para identificar sus registros de datos, deberá proporcionarnos la hora y la fecha precisas en las que realizó la investigación, y necesitaremos solicitar una identificación visual para garantizar que el historial de datos coincida correctamente.",
  "app.faq.gdpr.question7": "¿Tengo derecho a retirar mi consentimiento para el procesamiento de mis datos personales o para el borrado de mis datos personales?",
  "app.faq.gdpr.answer7": "Sí, tiene derecho a retirar su consentimiento para el procesamiento de sus datos personales y para el borrado de estos datos.  Sin embargo, es importante tener en cuenta que su nombre no se almacenará con los datos recopilados durante este ejercicio.  Para identificar su registro de datos, deberá proporcionarnos la hora y la fecha precisas en las que realizó la investigación, y le solicitaremos una identificación visual para garantizar que el historial de datos coincida correctamente.  ",
  "app.faq.gdpr.question8": "¿Cómo sé que mi información personal está segura?",
  "app.faq.gdpr.answer8": "Los datos recopilados por Lumen Research se cifran mediante la tecnología Secure Sockets Layer (SSL).  Esta es una tecnología de encriptación de alto estándar comúnmente utilizada. Esta tecnología se utiliza tanto para asegurar los canales de transferencia como para el almacenamiento final.",
  "app.faq.gdpr.question9": "¿Cómo usaremos y divulgaremos la información que recopilamos?",
  "app.faq.gdpr.answer9": "Podemos divulgar la información que recopilamos a otros proveedores de servicios externos, para que puedan prestarnos servicios. También podemos divulgar información a un tercero en caso de reorganización, fusión, venta, empresa conjunta, cesión, transferencia u otra disposición de la totalidad o una parte de nuestro negocio, activos o acciones (incluso en relación con cualquier quiebra o procedimientos similares). También podemos usar o divulgar la información que recopilamos de usted y sobre usted según lo consideremos necesario o apropiado: (a) según la ley aplicable, incluidas las leyes fuera de su país de residencia; (b) para cumplir con un proceso legal; (c) para responder a solicitudes de autoridades públicas y gubernamentales, incluidas autoridades públicas y gubernamentales fuera de su país de residencia; (d) para hacer cumplir nuestro acuerdo de licencia de usuario final; (e) para proteger nuestras operaciones o las de cualquiera de nuestras afiliadas; (f) para proteger nuestros derechos, privacidad, seguridad o propiedad, y/o los de nuestros afiliados, usted u otros; (g) para permitirnos buscar los recursos disponibles o limitar los daños que podamos sufrir; y (h) para detectar y resolver cualquier fraude o problemas de seguridad. ",
  "app.faq.gdpr.question10": "¿Qué medidas se toman para mantener los datos de forma segura?",
  "app.faq.gdpr.answer10": "Tomamos medidas razonables para proteger su información utilizando medidas de seguridad físicas, electrónicas o de procedimiento adecuadas a la sensibilidad de la información bajo nuestra custodia o control, que incluyen salvaguardas para proteger contra pérdida o robo, así como acceso no autorizado, divulgación, copia, uso o modificación. "
}
