{
    "app.button.faqs": "Usein kysyttyä",
    "app.button.terms": "Käyttöehdot",
    "app.button.privacy": "Tietosuojakäytäntö",
    "app.button.feedback": "Palaute",
    "app.button.selectLanguage": "Valitse kieli",
    "app.button.help": "Ohje",
    "app.button.start": "Aloita",
    "app.button.next": "Seuraava",
    "app.button.submit": "Lähetä",
    "app.button.takepart": "OK – Osallistun",
    "app.menu.feedback.content": "Jos tutkimuksessa esiintyi ongelmia tai jos haluaisit kommentoida jotakin, voit kirjoittaa tähän.",
    "app.contactModal.feedback": "Kiitos palautteestasi!",
    "app.takePartModal.line1": "Jos poistut nyt, et ole oikeutettu samaan osallistumispalkkiota.",
    "app.takePartModal.line2": "Tarvitset vain verkkokameran!",
    "app.takePartModal.reason1": "Minulla ei ole verkkokameraa.",
    "app.takePartModal.reason2": "En halua käyttää verkkokameraani tässä tutkimuksessa.",
    "app.takePartModal.reason3": "Minulla ei nyt ole aikaa tähän.",
    "app.takePartModal.reason4": "Muu syy.",
    "app.generic.redirect": "Sinut uudelleenohjataan pian.",
    "app.prestart.loading": "Ladataan, odota.",
    "app.index.title": "Tervetuloa",
    "app.index.content.line1": "Käytämme kameraasi tässä tutkimuksessa arvioidaksemme mihin katsot. Ei huolta, emme ota valokuvia tai videoita.",
    "app.index.content.line1alt": "Varmista, että istut mukavasti ja aloita sitten napauttamalla Aloita.",
    "app.index.content.line2": "Onko laitteessasi kamera? Jos on, paina alla olevaa Aloita-painiketta.",
    "app.index.rejection": "Vaikuttaa siltä, että olet seurannut virheellistä linkkiä.",
    "app.index.webview": "Vaikuttaa siltä, että olet siirtynyt sivullemme webview-sovelluksesta. Avaa tämä linkki selaimessasi. Tämä saattaa onnistua suoraan webview-valikosta.",
    "app.index.webview.chrome.1": "Napauta yllä olevaa painiketta ja valitse sitten Avaa Chromessa.",
    "app.index.webview.chrome.2": "Tämä avaa tutkimuksen selainsovelluksessa, jotta se toimii kunnolla.",
    "app.index.webview.android.1": "Napauta alla olevaa Aloita-painiketta avataksesi tämän tutkimuksen selainsovelluksessa, jotta se toimii kunnolla.",
    "app.index.webview.android.2": "Jos tämä ei onnistu, avaa linkki tälle sivulle selainsovelluksessa manuaalisesti (esim. kopioi linkki Chromen osoitekenttään tai käytä esim. yllä olevassa osoitepalkissa olevaa Avaa Chromessa -valintaa).",
    "app.index.webview.safari.1": "Jatka napauttamalla näytön alaosassa olevaa Safari-kuvaketta.",
    "app.index.webview.safari.2": "Se näyttää tältä:",
    "app.index.webview.safari.3": "Tämä avaa tutkimuksen Safarissa, jotta se toimii kunnolla.",
    "app.index.wifi": "Vaikuttaa siltä, että verkkoyhteytesi on liian hidas. Oletko yhteydessä wifi-verkkoon?",
    "app.index.tellmemore": "Kerro lisää.",
    "app.index.takepart": "En halua osallistua.",
    "app.instructions.title": "Ohjeet",
    "app.instructions.instruction1": "Mikäli mahdollista, älä käytä silmälaseja tutkimuksen aikana.",
    "app.instructions.instruction2": "Istu mukavasti niin, että pääsi ja laite eivät liiku.",
    "app.instructions.instruction3": "Liikuta silmiäsi älä päätäsi.",
    "app.instructions.instruction4": "Oletko valmis aloittamaan?",
    "app.instructions.instruction5": "Käytä wifi-yhteyttä, jotta vältyt datasiirtomaksuilta.",
    "app.instructions.instruction6": "Lue seuraavat ohjeet huolellisesti.",
    "app.instructions.instruction7": "Aseta puhelimesi vakaalle alustalle.",
    "app.instructions.instruction8": "Varmista, että huoneessa on hyvä valaistus, mieluiten alaspäin suuntautuva (ei takaa tuleva valo).",
    "app.setup.title": "Asetukset",
    "app.setup.instruction0": "Pidä laitteesi pystyasennossa.",
    "app.setup.instruction0a": "Käännä laitteesi pystyasentoon.",
    "app.setup.instruction0b": "Käännä laitettasi niin, että kamera on vasemmalla.",
    "app.setup.instruction0c": "Käännä laitettasi niin, että kamera on oikealla.",
    "app.setup.instruction1": "Alustetaan...",
    "app.setup.instruction2": "Kun sinut on tunnistettu, aseta pääsi ruudun sisään.",
    "app.setup.instruction3": "Pidä pääsi ja laite paikoillaan siihen asti, kunnes tutkimus on päättynyt.",
    "app.setup.instruction4": "Nyt tarvitaan vain vahvistus.",
    "app.setup.instruction5": "Kiitos! Muista pitää pääsi ja laite samassa asennossa siihen asti, kunnes tutkimus on päättynyt.",
    "app.setup.instruction6": "Piilota osoitepalkki pyyhkäisemällä ylös.",
    "app.setup.retry": "Virhe havaittiin. Pidä pääsi ja laite paikoillaan äläkä peitä kameraa esim. sormillasi. Yritä uudelleen käyttämällä alla olevaa painiketta.",
    "app.setup.retryInit": "Virhe havaittiin. Varmista, että selaimella on pääsy kameraasi. Jos olet aikaisemmin estänyt kameran käyttöoikeudet, ne täytyy hyväksyä selaimen yksityisyysasetuksissa. Yritä uudelleen käyttämällä alla olevaa painiketta.",
    "app.setup.retryButton": "Yritä uudelleen.",
    "app.setup.calibrationProp.watch": "Seuraa pistettä, kun se ilmestyy näytölle.",
    "app.setup.calibrationProp.watch.george": "Seuraavaksi, kun näet kasvot, pidä katseesi niissä samalla kun matkit pään liikkeitä.",
    "app.setup.calibrationProp.click": "Jatka napsauttamalla missä vain.",
    "app.setup.calibrationProp.tap": "Jatka napauttamalla missä vain.",
    "app.study.validationProp.watch": "Seuraa pistettä, kun se ilmestyy näytölle.",
    "app.study.validationProp.restart": "Yritä uudelleen.",
    "app.study.validationProp.click": "Jatka napsauttamalla missä vain.",
    "app.study.validationProp.tap": "Jatka napauttamalla missä vain.",
    "app.study.render.error": "Verkkotunnus ei toimi.",
    "app.error.title": "Virhe",
    "app.error.generic": "Hups! Jotakin meni pieleen.",
    "app.error.initialise": "Alustus ei onnistunut!",
    "app.error.findCamera": "Kameraa ei löydy!",
    "app.error.headPosition": "Pääsi asentoa ei voida määrittää.",
    "app.error.calibrate": "Kalibrointi ei onnistu.",
    "app.error.validate": "Vahvistus ei onnistu.",
    "app.error.start": "Aloitus ei onnistunut.",
    "app.error.assign": "Valinta ei onnistunut.",
    "app.error.osnotallowed": "Käyttöjärjestelmäsi ei ole sallittu. Tarkista, mitä laitetta voit käyttää tässä tutkimuksessa, ja yritä uudelleen.",
    "app.error.demoquotafullnoactivecell": "Kiitos kiinnostuksestasi. Tämä tutkimus on nyt suljettu väestöryhmältäsi.",
    "app.invalid.title": "Virheellinen",
    "app.invalid.generic": "Valitettavasti et sovellu tähän tutkimukseen.",
    "app.completion.title": "Valmis",
    "app.completion.congratulations": "Onnittelut! Olet suorittanut tutkimuksen loppuun. Tämän tutkimuksen yhteys kameraasi katkaistaan nyt.",
    "app.questions.required": "Tämä kysymys on pakollinen.",
    "app.faq.question1": "K: Mikä on Lumen Study Platform ja mitä katseenseurantatutkimukseen osallistuminen pitää sisällään?",
    "app.faq.answer1": "V: Tässä tutkimuksessa käytetään katseenseurantateknologiaa.  Sinua pyydetään istumaan laitteesi verkkokameran tai etukameran edessä ja tekemään kalibrointi, jotta voidaan arvioida, mihin kohtaan näyttöäsi katsot. Tutkimuksen aikana sinua pyydetään katsomaan tiettyjä asioita (esim. kuvia, verkkosivustoja tai videoita) samalla kun tutkimuslaite arvioi mihin katsot. Tästä katseenseurannasta saadut tiedot ovat koordinaatteja, jotka muodostuvat näytölläsi olevista arvioiduista katsepisteistä ajan kuluessa. Ohjelmisto tekee nämä arvioit käyttäen verkkokameralla sinusta saatuja kuvia, mutta se ei ota kuvia tai videoita sinusta. Sinulle saatetaan myös esittää kysymyksiä itsestäsi, kokemuksestasi ja asioista, joita olet nähnyt tutkimuksen aikana. Tiedot lähetetään Lumen Research Limited -yhtiölle. Lume Research analysoi tiedot ja jakaa ne asiakkaidensa kanssa.",
    "app.faq.question2": "K: Miksi ohjelmisto vaatii verkkokameran tai kameran ja miten sitä käytetään?",
    "app.faq.answer2": "V: Ohjelmisto käyttää verkkokamerallasi tai laitteesi kameralla saatuja kuvia, joilla se arvioi mihin kohtaan näyttöä katsot. Se käyttää kasvojenseuranta- ja katseenseurantaohjelmiston yhdistelmää, joka analysoi kuvat paikallisesti koneessasi reaaliaikaisesti.  Nämä kuvat käsitellään ja näytetään sinulle kalibroinnin aikana, jotta voit korjata asentoasi. Kuvia ei tallenneta, joten yksityisyytesi on suojattu.  Katseenseurannasta tallennetut ja kerätyt tiedot eivät ole kuvia vaan numeerista dataa, joka on saatu ärsykkeeseen kohdistuneista arvioiduista katsepisteistä ajan kuluessa.",
    "app.faq.question3": "K: Otetaanko minusta kuvia katseenseurannan yhteydessä?",
    "app.faq.answer3": "V: Kuvia käytetään vain siksi, että voit korjata asentoasi kalibroinnin aikana. Näitä kuvia ei tallenneta, ja vain sinä voit nähdä ne. Katseenseurannassa tallennetut ja kerätyt tiedot eivät ole kuvia vaan numeerista dataa, joka on saatu ärsykkeeseen kohdistuneista arvioiduista katsepisteistä ajan kuluessa.",
    "app.faq.question4": "K: Mitä tietoja tutkimuksen aikana kerätään?",
    "app.faq.answer4.line1": "V: Ohjelmisto kerää jotakin seuraavasta viidestä tietotyypistä tai kaikki niistä:",
    "app.faq.answer4.line2": "Käyttäjätunnus – Käyttäjätunnusta käytetään vastaajien tunnistamiseen (osallistujien palkkioiden hallinnointitarkoituksiin).",
    "app.faq.answer4.line3": "Katsetiedot – Arviot siitä, mihin käyttäjät katsovat näytöllään, kun heille näytetään ärsykkeitä näytöllä.",
    "app.faq.answer4.line4": "Tutkimustiedot – Vastaukset käyttäjien vastaamiin kysymyksiin.",
    "app.faq.answer4.line5": "Metadata – Tiedot ajasta, jolloin henkilöt osallistuivat tutkimukseen.",
    "app.faq.answer4.line6": "Laitteen fyysiset tiedot – Tiedot osallistujan osallistumiseen käyttämästä tietokoneesta, esim. RAM-muistin koko, koneen suorittimen tyyppi ja käyttäjäagentti (käyttöjärjestelmä ja selainversio) sekä tietoja tietokoneen näytöstä (koko ja resoluutio), verkkokamerasta (merkki ja resoluutio) ja virustorjuntaohjelmista.",
    "app.faq.question5": "K: Miten kerättyjä tietoja käytetään?",
    "app.faq.answer5.line1": "V: Kerätyillä tiedoilla tutkitaan, kuinka ihmiset katsovat eri tyyppisiä visuaalisia kuvia ja mediaa ja kuinka he reagoivat niihin, sekä arvioidaan ihmisten mielipiteitä heille näytetyistä kuvista.",
    "app.faq.answer5.line2": "Lumen saattaa jakaa tutkimustietoa sidosyrityksille, liikekumppaneille ja asiakkaille. Vaikka tutkimustietoa saatetaan jakaa sidosyrityksille, liikekumppaneille ja asiakkaille, se tehdään tyypillisesti nimettömänä. Nämä sidosyritykset, liikekumppanit ja asiakkaat saattavat käyttää näitä tietoja luodakseen yhteenvetoja monenlaisista kuluttajakäyttäytymisistä ja kuluttajaryhmistä voidakseen kehittää tuotteita tai tarjota markkinointiratkaisuja omille asiakkailleen tai kuluttajille.",
    "app.faq.answer5.line3": "Lumen kerää laitteen fyysisiä tietoja ja metadataa helpottaakseen tietojen keräämisen ja käsittelyn hallintaa, kuten laadunvalvontaa ja määrän hallintaa.",
    "app.faq.answer5.line4": "Kerättyjä tietoja säilytetään niin pitkään kuin mainittujen tarkoitusten toteuttaminen edellyttää.",
    "app.faq.question6": "K: Kuinka hyvin tietoni on suojattu?",
    "app.faq.answer6": "V: Lumenin keräämät tiedot on salattu SSL-tekniikalla. Tämä on yleisimmin käytetty korkeatasoinen salaustekniikka, jota pankit käyttävät pankkitoimintojen ja muiden erittäin arkaluontoisten liiketoimintojen suojaamiseen. Sitä käyttävät myös Amazon, PayPal, eBay ja muut verkkosivustot, joilla tarvitaan pankkitasoisia suojausjärjestelmiä. Tieteilijät arvioivat, että 256-bittisen salauksen murtamiseen nykytekniikalla menisi huomattavasti pidempi aika kuin mitä maailmankaikkeuden ikä on. Kerätyt tiedot tallennetaan suojattuihin verkkopalvelimiin, joita hallitsee Amazon Web Services (AWS). AWS on yksi kaikkein yleisimmin käytetyistä tallennuspaikoista ja luotettavimmista pilvipalvelualustoista, jota käyttävät suurimmat pilvipohjaiset kaupalliset palvelut, kuten Netflix ja Expedia.",
    "app.faq.question7": "K: Mitä velvollisuuksia näkemäsi sisältö asettaa sinulle?",
    "app.faq.answer7": "V: Osallistumalla ymmärrät ja hyväksyt sen, että tässä tutkimuksessa näkemäsi sisältö saattaa olla luottamuksellista, kolmannen osapuolen omaisuutta ja/tai sitä saattavat sitoa tavaramerkki- tai tekijänoikeuslait, ja että et ota kuvakaappauksia, tallenna tai muulla tavalla kopioi mitään sisältöä etkä jaa mitään tutkimuksen sisältöä millään tavalla.",
    "app.faq.question8": "K: En tiedä, kuinka hyvä verkkokamerani on. Toimiiko se katseenseurannassa?",
    "app.faq.answer8": "V: Järjestelmä toimii lukuisten eri verkkokameroiden kanssa, joten sen ei pitäisi aiheuttaa ongelmaa.",
    "app.faq.question9": "K: Olen suorittanut tutkimuksen loppuun. Täytyykö minun tehdä jotain muuta?",
    "app.faq.answer9": "V: Ei, sinun ei tarvitse tehdä mitään muuta. Ohjelmisto sulkeutuu automaattisesti.",
    "app.faq.question10": "K: Täytyykö minulla olla internet-yhteys, jotta voin osallistua?",
    "app.faq.answer10": "V: Kyllä. Tarvitset internet-yhteyden, jotta voit ladata ohjelman ja tehdä tehtävän.",
    "app.faq.question11": "K: Täytyykö tehtävä tehdä vakaalla alustalla?",
    "app.faq.answer11": "V: Kyllä. Onnistumisen kannalta on tärkeää, että istut mukavasti tietokoneesi näytön edessä, ja kun kalibrointi on päättynyt, pidät pääsi paikallaan sillä aikaa kun ärsyke näytetään aina siihen saakka, kunnes saat ilmoituksen. Emme suosittele osallistumista, jos olet junassa tai jos kannettava tietokoneesi on sylissäsi jne.",
    "app.faq.question12": "K: En saanut ratkaisua ongelmaani Usein kysyttyä -vastauksista. Mitä voin tehdä nyt?",
    "app.faq.answer12": "V: Lähetä meille sähköpostia osoitteeseen study-feedback@lumen-research.com, ja vastaamme sinulle niin pian kuin voimme.",
    "app.faq.patent": "Lumenin verkkosisällön testauksen tarjoaa oma  asiayhteyteen perustuva testausteknologiamme (Brittiläinen patenttihakemus 1816158.8).",
    "app.terms.line1": "Nämä käyttöehdot (”käyttöehdot”) määrittävät sinun ja Lumen Research Ltd (”Lumen” tai ”me”) -yhtiön (rekisteröity Englannissa, yhtiönumero 8682432, rekisteröity toimipaikka Unit 215, 22 Highbury Grove, London N5 2EF, tietosuojan rekisteröintiviite ZA130772) välistä sopimusta koskien ViewPoints-katseenseurantaohjelmiston käyttöäsi (”ohjelmisto”) omalla laitteellasi.",
    "app.terms.line2": "1. Ehtojen hyväksyminen",
    "app.terms.line3": "Ohjelmistoa käyttämällä ymmärrät, että olet suostunut sitoutumaan tähän sopimukseen. Jos et hyväksy tämän sopimuksen ehtoja, älä tee ViewPoints-tutkimustehtäviä.",
    "app.terms.line4": "2. Luottamukselliset tiedot",
    "app.terms.line5": "Kun asennat tai käytät ViewPoints-ohjelmistoa tai millään muulla tavalla aktivoit sen, saatat päästä tietoon ja/tai sisältöön, joka kuuluu Lumenille tai liittyy siihen, mukaan lukien tiedot, joita Lumen tai mitkä tahansa sen asiakkaat pitävät tai käsittelevät luottamuksellisina, tai tiedot, jotka luonteensa, olosuhteiden tai niiden paljastustavan perusteella ovat ilmeisesti luottamuksellisia, mukaan lukien (rajoittamatta edellä mainittua yleisluonteisuutta) kaikki tiedot liiketoimintasuunnitelmista, erääntyvistä liiketoimintamahdollisuuksista, tutkimus- ja kehitystyöprojekteista, suunnitelmista, salaisista prosesseista, koodeista, ohjelmistosuunnitelmista, liikesalaisuuksista, tuote- tai palvelukehittämisestä ja -kaavoista, tietotaidosta, keksinnöistä, myyntitilastoista ja -ennusteista, markkinointistrategioista ja -suunnitelmista, tuotoista ja tappioista sekä muista taloudellisista tiedoista (riippumatta siitä, onko ne tallennettu kirjallisesti tai sähköisesti tai muussa muodossa) (”luottamuksellinen tieto”). Tällaisen luottamuksellisen tiedon omistusoikeus säilyy vain ja ainoastaan Lumenilla ja/tai sen asiakkailla, etkä sinä missään tilanteessa saa mitään oikeutta, omistusoikeutta ja/tai -osuutta tällaiseen luottamukselliseen tietoon.",
    "app.terms.line6": "Sinulla ei ole lupaa ViewPoints-osallistumisesi aikana tai missään vaiheessa (ilman aikarajaa) kyseisen osallistumisen jälkeen paljastaa tai kertoa kenellekään mitään luottamuksellista tietoa, käyttää sitä omiin tarkoituksiisi tai antaa minkään huolimattoman toiminnan johtaa minkä tahansa luottamuksellisen tiedon luvattomaan paljastumiseen. Sinun täytyy aina pyrkiä parhaasi mukaan estämään minkä tahansa luottamuksellisen tiedon julkaiseminen tai paljastaminen. Nämä rajoitukset lakkaavat koskemasta sellaista tietoa, joka tulee yleisesti saataville muutoin kuin sinun laiminlyöntisi seurauksesta.",
    "app.terms.line7": "3. Lumenin omaisuus",
    "app.terms.line8": "ViewPoints-ohjelmisto kaikki data, koodit, avaimet, salasanat, suunnitelmat, piirustukset, tallenteet ja muut asiakirjat tai materiaalit, jotka liittyvät ohjelmistoon, ovat ja pysyvät Lumenin omistuksessa koko sen ajan, kun osallistut ViewPoints-tutkimukseen. Lumen omistaa kaikki aineettomat tai omistusoikeudet kaikkiin olemassa oleviin ja tuleviin aineettomiin tai teollisoikeuksiin ja kaikkiin asiakirjoihin, materiaaleihin, malleihin, suunnitelmiin, piirustuksiin, prosesseihin, keksintöihin, kaavoihin, tietokonekoodeihin, dataan, järjestelmien käyttöliittymiin, menetelmiin, tietotaitoon, luottamukselliseen tietoon tai ohjelmistoon ja/tai laitteeseen millään lailla liittyvään muuhun teokseen, jonka sinä yksin tai jonkun toisen henkilön kanssa olet tehnyt, luonut, laatinut, kehittänyt, parannellut, purkanut tai keksinyt, tai jota on voitu käyttää tai soveltaa käytettäväksi ohjelmistossa tai laitteessa tai niiden yhteydessä (riippumatta siitä onko ne rekisteröity vai ei), mukaan lukien kaikki olemassa olevat ja tulevat patentit, tekijänoikeudet, mallisuojaoikeudet, tietokantaoikeudet, tavaramerkit, oikeudet puolijohdetuotteiden piirimalleihin, kasvinjalostajanoikeuksiin, internetoikeudet/domain-nimet, tietotaito ja mitkä tahansa ja kaikki sovellukset mihin tahansa edellä mainittuihin ja kaikki oikeudet hakea mitä tahansa edellä mainituista (”tekijänoikeudet”). Mitkään omistusoikeudet eivät siirry sinulle missään vaiheessa.",
    "app.terms.line9": "4. Käyttöehdot ja -rajoitukset",
    "app.terms.line10": "ViewPoints-ohjelmisto on lisensoitu loppukäyttäjille. Suostumme tämän sopimuksen nojalla antamaan sinulle ei-yksinomaisen, peruutettavissa olevan, ei-siirrettävän, ei-alilisensoitavan ja rajatun luvan käyttää ViewPoints-ohjelmistoa omistamallasi tai hallitsemallasi laitteella tai henkilökohtaisella tietokoneella, johon vahvistat ja takaat omaavasi kaikki tarvittavat oikeudet ja luvat. Epäselvyyksien välttämiseksi sinulla ei ole lupaa käyttää ohjelmistoa ViewPoints-tutkimuksen ulkopuolisiin tarkoituksiin eikä kääntää, kopioida, uudelleentuottaa, välittää telelaitteiden avulla, soveltaa, yhdistää, muuttaa tai muokata sitä millään tavalla.",
    "app.terms.line11": "Suostut siihen, että (i) et kopioi, muokkaa, sovella, aseta saataville, vuokraa, liisaa, anna aikaosuutta, luo johdannaisteoksia tai yritä myydä tai muulla tavalla siirtää mitään ohjelmiston oikeuksia; (ii) et pura tai takaisinmallinna mitään ohjelmiston osia millä tavalla, etkä salli tai avusta ketään tai mitään saamaan tai pyrkimään saada selville ohjelmiston lähdekoodia, et pura ohjelmistoa tai sen salausta, etkä pura tai takaisinmallinna ohjelmistoa tai ryhdy mihinkään toimenpiteisiin saadaksesi ohjelmistoa koskevia suunnittelutietoja; (iii) et käytä ohjelmistoa mihinkään muuhun tarkoitukseen kuin siihen miksi se sinulle alun perin toimitettiin; (iv) et käytä hakurobotteja, robotteja tai muita automaattisia tiedonlouhintatekniikoita ohjelmiston luetteloimiseen, lataamiseen, tallentamiseen tai uudelleentuottamiseen tai jakelemiseen millään tavalla tai minkään tutkimustulosten tai minkään muun aktiviteetin tulosten manipuloimiseen; (v) et ryhdy mihinkään toimenpiteisiin vaikuttaaksesi ohjelmistoon tai kenenkään henkilön ohjelmiston käyttöön, esimerkiksi ylikuormittamalla, aiheuttamalla tulvaa, sähköpostipommituksilla tai kaatamalla ohjelmistoa tai tiedontallennusjärjestelmää, jota ohjelmisto käyttää Amazon Web Services -palvelun kautta; (vi) et lähetä meille mitään laitonta, vilpillistä tai haitallista koodia, kuten mitään viruksia, vakoiluohjelmia, mainosohjelmia tai mitään muita haitallisia koodeja.",
    "app.terms.line12": "Kaikki oikeudet, joita sinulle ei tässä erikseen ole myönnetty, on varattu meille ja lisenssin antajillemme. Hyväksyt sen, että tämä sopimus tai mikään muukaan ei millään tavalla velvoita meitä korjaamaan mitään ohjelmassa esiintyviä vikoja, puutteita tai virheitä tai muulla tavalla tukemaan, ylläpitämään, parantamaan, muokkaamaan, päivittämään tai kehittämään ohjelmistoa. Meidän vastuullamme ei ole tarjota apua sinulle tai nimeämillesi henkilöille ohjelmiston käytössä millään tavalla.",
    "app.terms.line13": "Emme ole millään tavalla vastuussa tietokoneellesi aiheutuvista vahingoista, esimerkiksi ohjelmiston käytöstäsi aiheutuvista vahingoista. Emme ole vastuussa mistään sinulle aiheutuvista ylimääräisistä datasiirtomaksuista, jotka johtuvat ohjelmiston suorittamasta tiedon keräämisestä tutkimukseen osallistumisesi yhteydessä.",
    "app.terms.line14": "5. Vastuuvapauslauseke",
    "app.terms.line15": "Me ja pääjohtajamme, virkailijamme, työntekijämme, edustajamme, lisenssin antajamme ja operaattorimme emme esitä mitään väitteitä tai anna mitään takuita tai ehtoja siitä, (a) että ohjelmisto olisi käytettävissä oikeaan aikaan tai että ohjelmaan pääsisi häiriöittä tai turvallisesti; (b) että puutteet tai viat korjattaisiin tai (c) että ohjelmisto tai palvelimet tai verkot, joiden kautta ohjelmistoa ja laitteita käytetään, olisivat turvallisia tai vapaita haittaohjelmista tai muista haitallisista tekijöistä. Meillä ei ole mitään velvoitteita tarjota mitään ohjelmistoon liittyviä kunnossapito- ja/tai huoltopalveluita.",
    "app.terms.line16": "6. Vastuunrajoitus",
    "app.terms.line17": "(a) Sovellettavan lain täysimääräisessä laajuudessa suostut siihen, että me ja pääjohtajamme, virkailijamme, työntekijämme, edustajamme, lisenssin antajamme ja operaattorimme emme ole missään vastuussa mistään tappioista, vahingoista tai kuluista, jotka johtuvat tekemästäsi ohjelman asennuksesta, ohjelman käytöstäsi tai siihen pääsystäsi, mistään ohjelmistossa tai sen kautta käytettäväksi annetusta informaatiosta tai materiaalista ja/tai muulla tavalla ohjelmistoon liittyvästä tai muulla tavalla tähän sopimukseen liittyvästä syystä riippumatta siitä, minkä luontoista lakiin perustuva, tasavertaisuus- tai lakisääteinen oikeus, jota väitetään loukatun, on.",
    "app.terms.line18": "(b) Sovellettavan lain täysimääräisessä laajuudessa ainoa ja yksinomainen apu ohjelmistoon tyytymättömyyteesi on lopettaa sen käyttö.",
    "app.terms.line19": "(c) Mikään yhteisömme tai kukaan pääjohtajamme, virkailijamme, työntekijämme tai edustajamme ei missään tapauksessa ole vastuuvelvollinen sinulle mistään tilapäisistä, epäsuorista, välillisistä, lakisääteisistä, esimerkillisistä, rangaistavista tai erityisistä vahingoista, menetetyistä tuloista, mahdollisuuksista, menetetystä maineesta, käytön estymisestä, tietojen tai muun aineettoman menetyksestä, tai kolmansien osapuolten ohjelmistojen keräämien tietojen luvattomasta tiedonkeruusta syystä riippumatta ja minkä tahansa sopimuksen, rikkomuksen (esimerkiksi huolimattomuus), ankaran vastuun tai muun teorian alaisuudessa, vaikka kyseisten vahinkojen mahdollisuudesta olisi kerrottu etukäteen.",
    "demo.index.content.line1": "Käytämme kameraasi tämän esittelyn aikana arvioidaksemme mihin katsot. Ei huolta, emme ota valokuvia tai videoita.",
    "app.index.webcamInstructions": "Pyydämme pääsyä webbikameraasi, jos saat ponnahdusikkunan, klikkaa sallia."
  }