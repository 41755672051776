import { store } from "../../../../store";
import DeviceController from "../../../../utils/controllers/DeviceController";
import Beacon from "../../Beacon";

const getPayload = (action) => {
  let {
    participant,
    accessCode,
    test,
    routedRegion,
    fastestRegion,
    panel,
  } = store.getState().studyReducer;

  let deviceInfo = DeviceController.getDeviceInfo();

  let body = {
    access_code: accessCode,
    panel: panel,
    participant: participant,
    test: test,
    device: deviceInfo.device,
    os: deviceInfo.osString.toLowerCase(),
    os_version: deviceInfo.os.version,
    browser: deviceInfo.name.toLowerCase(),
    browser_version: deviceInfo.version,
    is_mobile: deviceInfo.isMobile,
    is_touch_capable: deviceInfo.isMobile,
    device_model: deviceInfo.device,
    routed_region: routedRegion,
    fastest_region: fastestRegion,
    graphics: deviceInfo.graphics,
    screen_resolution_width: deviceInfo.screen_resolution_width,
    screen_resolution_height: deviceInfo.screen_resolution_height,
    browser_width: deviceInfo.browser_width,
    browser_height: deviceInfo.browser_height,
    browser_left: deviceInfo.browser_left,
    browser_top: deviceInfo.browser_top,
    viewport_width: deviceInfo.viewport_width,
    viewport_height: deviceInfo.viewport_height,
    browser_zoom: deviceInfo.browser_zoom,
    update_message: action,
  };

  return body
};

export default (studyId, action) => {
  return new Beacon(
    "update-last-section-completed",
    `${process.env.REACT_APP_PARTICIPATION_URL}/participation/studies/${studyId}/update-last-section-completed`,
    () => getPayload(action)
  );
};
