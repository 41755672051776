import config from "../config";
import PlatformError from "../PlatformError";

export default new PlatformError(
  "ERROR",
  config.defaultMessage,
  "SurveyError",
  "$error_name$ - $error_message$",
  config.defaultMessageId
);
