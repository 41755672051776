{
  "app.button.faqs": "Domande frequenti",
  "app.button.terms": "Termini d’uso",
  "app.button.privacy": "Informativa sulla privacy",
  "app.button.feedback": "Feedback",
  "app.button.selectLanguage": "Seleziona la lingua",
  "app.button.help": "Guida",
  "app.button.start": "Inizia",
  "app.button.next": "Avanti",
  "app.button.submit": "Invia",
  "app.button.takepart": "OK - Partecipo",
  "app.menu.feedback.content": "Se hai riscontrato un problema con il sondaggio oppure desideri semplicemente lasciare un commento, non esitare a farlo qui.",
  "app.contactModal.feedback": "Grazie per il tuo feedback!",
  "app.takePartModal.line1": "Se abbandoni ora il sondaggio, non avrai diritto ad alcun premio per la partecipazione.",
  "app.takePartModal.line2": "Ti serve solo una webcam!",
  "app.takePartModal.reason1": "Non ho una webcam.",
  "app.takePartModal.reason2": "Non mi fa sentire a mio agio il fatto che la mia webcam venga utilizzata per questo sondaggio.",
  "app.takePartModal.reason3": "Ora non ho tempo.",
  "app.takePartModal.reason4": "Altro motivo.",
  "app.generic.redirect": "Verrai ridirezionato/a tra 3 secondi.",
  "app.prestart.loading": "Caricamento in corso, ti preghiamo di attendere.",
  "app.index.title": "Benvenuto/a",
  "app.index.content.line1": "Durante questo sondaggio utilizzeremo la tua telecamera per stimare il punto da te osservato. Non preoccuparti, non scatteremo alcuna foto né gireremo video.",
  "app.index.content.line1alt": "Assicurati di essere seduto in una posizione comoda, quindi tocca Inizio per cominciare.",
  "app.index.content.line2": "Il tuo dispositivo è dotato di telecamera? Se la risposta è sì, premi il pulsante di avvio qui sotto.",
  "app.index.rejection": "Siamo spiacenti, a quanto sembra hai seguito un link malformato.",
  "app.index.webview": "Siamo spiacenti, ma pare che abbia raggiunto la nostra pagina in modalità webview. La preghiamo di aprire questo link all’interno del Suo browser. Dovrebbe essere possibile farlo direttamente dal menu webview.",
  "app.index.webview.chrome.1": "Toccare il pulsante menu qui sopra e poi selezionare ‘Apri in Chrome’.",
  "app.index.webview.chrome.2": "Questo aprirà il sondaggio in una app, di modo che funzioni come si deve.",
  "app.index.webview.android.1": "Toccare “Start” qui sotto per aprire il sondaggio in una app, di modo che funzioni come si deve.",
  "app.index.webview.android.2": "Se dovesse non funzionare, aprire il link per questa pagina in una app manualmente (ad es. copiando e incollando il link nella barra degli indirizzi in Chrome o usando un’opzione come ‘Aprire in Chrome’ nella barra dei menu qui sopra).",
  "app.index.webview.safari.1": "Toccare l’icona di Safari in fondo allo schermo per continuare.",
  "app.index.webview.safari.2": "È fatta così:",
  "app.index.webview.safari.3": "Questo aprirà il sondaggio in Safari di modo che funzioni come si deve.",
  "app.index.wifi": "A quanto sembra, la tua connessione Internet è troppo lenta. Sei connesso/a alla rete Wi-Fi?",
  "app.index.tellmemore": "Ditemi qualcosa di più.",
  "app.index.takepart": "Non voglio partecipare.",
  "app.index.webcamRetry": "Non siamo riusciti a rilevare la presenza di una webcam, requisito necessario per questo studio. Ti preghiamo di consentire l'accesso alla tua webcam e di riprovare.",
  "app.index.adblockRetry": "Durante questo studio ti chiediamo di disabilitare tutti i software per bloccare gli annunci pubblicitari. Disabilita il tuo ad blocker e riprova.",
  "app.instructions.title": "Istruzioni",
  "app.instructions.instruction1": "Se non è un problema, ti chiediamo di non indossare occhiali durante il sondaggio.",
  "app.instructions.instruction2": "Siediti in modo da poter tenere più facilmente fermi testa e dispositivo.",
  "app.instructions.instruction3": "Sposta gli occhi, non la testa.",
  "app.instructions.instruction4": "Sei pronto/a ad iniziare?",
  "app.instructions.instruction5": "Utilizza la rete Wi-Fi per evitare spese per traffico dati.",
  "app.instructions.instruction6": "Leggi attentamente le seguenti istruzioni.",
  "app.instructions.instruction7": "Posa il tuo telefono su una superficie stabile.",
  "app.instructions.instruction8": "Assicurati che la tua stanza sia ben illuminata, preferibilmente dall'alto (non da dietro).",
  "app.setup.title": "Configurazione",
  "app.setup.instruction0": "Tieni il tuo dispositivo in modalità Portrait (ritratto).",
  "app.setup.instruction0a": "Ruota il dispositivo in modalità orizzontale.",
  "app.setup.instruction0b": "Ruota il dispositivo in modo che la telecamera sia a sinistra.",
  "app.setup.instruction0c": "Ruota il dispositivo in modo che la telecamera sia a destra.",
  "app.setup.instructioncamera": "Fai clic sul tasto Richiedi accesso qui sotto e autorizza l’uso della fotocamera nella casella che apparirà. Se non autorizzerai l’uso della fotocamera non potremo tracciare i tuoi movimenti oculari e perderai il tuo incentivo.",
  "app.setup.cameraText": "Richiedi accesso",
  "app.setup.instruction1": "Inizializzazione in corso...",
  "app.setup.instruction2": "Una volta rilevata la tua presenza, posiziona la testa all’interno del riquadro.",
  "app.setup.instruction3": "Mantieni fermi testa e dispositivo fino a quando non avrai completato il sondaggio.",
  "app.setup.instruction4": "Ora dobbiamo solo effettuare la convalida.",
  "app.setup.instruction5": "Grazie! Ricordati di mantenere fermi testa e dispositivo fino a quando non avrai completato il sondaggio.",
  "app.setup.instruction6": "Scorri il dito verso l’alto per nascondere la barra degli indirizzi.",
  "app.setup.retry": "Siamo spiacenti, qualcosa è andato storto. Usa il pulsante qui sotto per riprovare.",
  "app.setup.retryInit": "È stato rilevato un errore. Accertarsi che il browser possa accedere alla fotocamera. Se ha precedentemente rifiutato l’accesso alla fotocamera, dovrà consentirne l’accesso nelle impostazioni relative alla privacy del browser. Usare il pulsante sottostante per ritentare.",
  "app.setup.retryButton": "Riprova",
  "app.setup.calibrationProp.watch": "Seguire il puntino quando appare sullo schermo",
  "app.setup.calibrationProp.watch.george": "Quindi, quando vedrai una faccia, continua a guardarla copiando i movimenti della testa.",
  "app.setup.calibrationProp.click": "Clicca in qualsiasi punto per proseguire",
  "app.setup.calibrationProp.tap": "Tocca qualsiasi punto per proseguire",
  "app.study.validationProp.watch": "Seguire il puntino quando appare sullo schermo",
  "app.study.validationProp.restart": "Riprova",
  "app.study.validationProp.click": "Clicca in qualsiasi punto per proseguire",
  "app.study.validationProp.tap": "Tocca qualsiasi punto per proseguire",
  "app.study.render.error": "Dominio non valido per la fase di rendering.",
  "app.error.title": "Errore",
  "app.error.generic": "Oops! Qualcosa è andato storto.",
  "app.error.initialise": "Impossibile eseguire l’inizializzazione!",
  "app.error.findCamera": "Impossibile trovare la telecamera!",
  "app.error.headPosition": "Impossibile determinare la posizione della testa.",
  "app.error.calibrate": "Calibrazione non riuscita.",
  "app.error.validate": "Convalida non riuscita.",
  "app.error.start": "Avvio non riuscito.",
  "app.error.assign": "Assegnazione non riuscita.",
  "app.error.osnotallowed": "Il Suo sistema operativo non è compatibile. La preghiamo di controllare quale dispositivo sia possibile usare per questo sondaggio e di ritentare.",
  "app.error.demoquotafullnoactivecell": "Grazie per il tuo interesse a partecipare. La quota di persone con un profilo corrispondente al tuo é giá stata raggiunta.",
  "app.invalid.title": "Non valido",
  "app.invalid.generic": "Siamo spiacenti, non risulti idoneo/a a partecipare a questo studio.",
  "app.completion.title": "Conclusione",
  "app.completion.congratulations": "Congratulazioni! Ha completato lo studio. L’accesso alla fotocamera ai fini del sondaggio verrà terminato.",
  "app.questions.required": "Questa domanda è obbligatoria.",
  "app.faq.question1": "D: Che cos’è la Piattaforma di studio Lumen e cosa implica la partecipazione a un sondaggio con sistema di eye tracking?",
  "app.faq.answer1": "R: Questo sondaggio prevede l’utilizzo di tecnologia per l’eye tracking. Ti verrà chiesto di sederti davanti alla webcam del tuo dispositivo o alla telecamera frontale e di effettuare una calibrazione in modo che possa essere fatta una stima del punto da te osservato sullo schermo. Nel corso del sondaggio, ti verrà chiesto di visionare alcuni supporti (ad es. immagini, siti web o video) mentre lo strumento del sondaggio effettua una stima del punto da te osservato. I dati raccolti tramite questa tecnologia di eye tracking sono le coordinate dei punti stimati dello sguardo sul tuo schermo nel corso del tempo. Per effettuare queste stime, il software utilizza tue immagini tratte dalla tua webcam ma non acquisisce foto o video. Potremmo anche farti domande su di te, la tua esperienza e le cose che hai visto nel corso del sondaggio. I dati vengono inviati a Lumen Research Ltd. Lumen Research analizzerà i dati e condividerà i risultati con i suoi clienti.",
  "app.faq.question2": "D: Perché il software ha bisogno di una webcam/telecamera e come viene utilizzata?",
  "app.faq.answer2": "R: Il software usa immagini tratte dalla tua webcam o dalla telecamera del tuo dispositivo per stimare il punto dello schermo da te osservato. Lo fa utilizzando una combinazione di tracciamento facciale e software per l’eye tracking che analizza immagini a livello locale sul tuo dispositivo in tempo reale. Queste immagini vengono elaborate e ti vengono mostrate durante la calibrazione in modo che tu possa posizionarti correttamente ma le immagini non vengono registrate, pertanto la tua privacy è protetta. I dati registrati e raccolti tramite l’eye tracking non consistono di immagini ma di dati numerici relativi ai punti dello sguardo sullo stimolo nel corso del tempo.",
  "app.faq.question3": "D: Acquisite mie immagini durante l’eye tracking?",
  "app.faq.answer3": "R: L’unica volta in cui vengono utilizzate le immagini è per consentirti di posizionarti correttamente durante la calibrazione. Queste immagini non sono salvate, e sono visibili solo da te. I dati registrati e raccolti tramite l’eye tracking non consistono di immagini ma di dati numerici relativi ai punti dello sguardo sullo stimolo nel corso del tempo.",
  "app.faq.question4": "D: Quali dati vengono raccolti durante il sondaggio?",
  "app.faq.answer4.line1": "R: Il software raccoglierà alcuni o la totalità dei seguenti cinque tipi di dati:",
  "app.faq.answer4.line2": "“ID utente” - L’ID utente viene utilizzata per identificare gli intervistati (ai fini dell'assegnazione dei premi per la partecipazione);",
  "app.faq.answer4.line3": "“Dati sullo sguardo” – Stime relative ai punti osservati dagli utenti sullo schermo nel momento in cui vengono loro mostrati stimoli sullo schermo",
  "app.faq.answer4.line4": "“Dati del sondaggio” – Risposte a domande poste agli utenti",
  "app.faq.answer4.line5": "“Metadati” - Dati relativi al momento in cui gli individui hanno partecipato con successo alla Ricerca.",
  "app.faq.answer4.line6": "“Dati del Dispositivo fisico” – Dati relativi al computer sul quale un utente partecipa, ad es. livello di RAM, tipo di CPU del dispositivo e agente utente (sistema operativo e versione di browser) oltre ad informazioni in merito allo schermo del computer (dimensioni e risoluzione), alla webcam (marca e risoluzione), e ai programmi antivirus.",
  "app.faq.question5": "D: In che modo verranno utilizzati i dati raccolti?",
  "app.faq.answer5.line1": "R: I dati raccolti verranno utilizzati per scoprire in che modo gli individui visualizzano e interagiscono con vari tipi di supporti e immagini visive e sondare le opinioni delle persone relativamente alle immagini che vengono loro mostrate.",
  "app.faq.answer5.line2": "Lumen potrebbe condividere i dati della ricerca con società affiliate e con partner commerciali e clienti. Sebbene i dati della ricerca potrebbero essere condivisi con affiliati, partner e clienti, la condivisione avverrà in modo anonimo. Tali affiliati, partner e clienti potrebbero utilizzare tali dati per creare descrizioni riepilogative di una vasta gamma di comportamenti dei consumatori e segmenti di consumatori per sviluppare prodotti o per fornire soluzioni di marketing ai loro clienti o consumatori.",
  "app.faq.answer5.line3": "Lumen raccoglie “Dati del Dispositivo fisico” e “Metadati” per gestire la raccolta e l’elaborazione dei dati, inclusi la gestione del volume e il controllo della qualità.",
  "app.faq.answer5.line4": "I dati raccolti verranno conservati per il periodo di tempo necessario a perseguire le finalità descritte.",
  "app.faq.question6": "D: In che modo vengono protetti i miei dati?",
  "app.faq.answer6": "R: I dati raccolti da Lumen sono codificati tramite la tecnologia SSL. Questo è il più comune standard elevato di tecnologia di codifica ed è utilizzato dalle banche per proteggere le transazioni bancarie e altre comunicazioni altamente sensibili. Viene inoltre utilizzato da Amazon, PayPal, eBay e altri siti web che richiedono sistemi di sicurezza di livello bancario. Gli scienziati stimano che decrittare una codifica a 256 bit utilizzando la tecnologia attualmente a disposizione richiederebbe molto più tempo dell'età dell’universo. I dati raccolti vengono memorizzati su web server protetti gestiti da Amazon Web Services (AWS). AWS è una delle piattaforme di servizi di cloud computing più comunemente utilizzate, sicure e affidabili ed è utilizzata da importanti servizi commerciali basati su cloud, tra cui Netflix e Expedia.",
  "app.faq.question7": "D: Quali obblighi hai relativamente al Contenuto che ti viene mostrato?",
  "app.faq.answer7": "R: Accettando di partecipare, riconosci e accetti anche che il Contenuto che ti viene mostrato potrebbe essere riservato, di proprietà di terzi e/o soggetto a leggi sui marchi o i diritti d’autore e ti impegni a non scattare screenshot, registrare o copiare in altro modo il Contenuto o ridistribuire in alcun modo qualsiasi parte del Contenuto dello studio.",
  "app.faq.question8": "D: Non so quale sia la qualità della mia webcam, sarà sufficiente per l’eye tracking?",
  "app.faq.answer8": "R: Il sistema funziona con una vasta gamma di webcam, non dovrebbe essere un problema.",
  "app.faq.question9": "D: Ho completato lo studio, devo fare qualcos’altro?",
  "app.faq.answer9": "R: No, non è necessario fare nient’altro. Il software si chiuderà automaticamente. Ora puoi disinstallare il software e cancellare il file scaricato.",
  "app.faq.question10": "D: Devo essere connesso/a ad Internet per partecipare?",
  "app.faq.answer10": "R: Sì, ti servirà una connessione Internet per scaricare il programma e completare l’attività.",
  "app.faq.question11": "D: Devo svolgere l'attività su una superficie stabile?",
  "app.faq.answer11": "R: Sì, per ottenere i migliori risultati, dovresti sederti comodamente davanti allo schermo del tuo computer e, dopo la calibrazione, tenere la testa ferma quando ti viene presentato lo stimolo, fino a nuove istruzioni. Ti consigliamo di non partecipare se ti trovi a bordo di un treno oppure se tieni il tuo laptop sulle ginocchia, ecc.",
  "app.faq.question12": "D: Le Domande frequenti non hanno risolto il mio problema, cosa posso fare?",
  "app.faq.answer12": "R: Inviaci un’e-mail all’indirizzo study-feedback@lumen-research.com e ti risponderemo quanto prima.",
  "app.faq.patent": "L’analisi del contenuto web di Lumen è resa possibile dalla nostra tecnologia brevettata di analisi in contesto (numero di domanda di brevetto UK 1816158.8)",
  "app.terms.line1": "I presenti termini d’uso (“Termini d’uso”) regolamentano l’accordo tra l’Utente e Lumen Research Ltd (“Lumen” o “Noi”), una società registrata in Inghilterra, con numero 8682432, sede legale al seguente indirizzo: Unit 215, 22 Highbury Grove, London N5 2EF e codice di registrazione protezione dei dati ZA130772, relativamente all’utilizzo del software per eye tracking “ViewPoints” (il “Software”) sul dispositivo dell’Utente.",
  "app.terms.line2": "1. Accettazione dei termini",
  "app.terms.line3": "Utilizzando questo Software, l’Utente accetta di essere vincolato dai termini dell’Accordo. Qualora l'Utente non accetti i termini e le condizioni del presente Accordo, lo invitiamo a non completare il sondaggio di ViewPoints.",
  "app.terms.line4": "2. Informazioni riservate",
  "app.terms.line5": "In fase di installazione, utilizzo o qualsiasi altra interazione con ViewPoints, l’Utente potrebbe avere accesso ad informazioni e/o contenuti appartenenti o relativi a Lumen, come informazioni identificate o trattate come riservate da Lumen o qualsiasi cliente di Lumen o che, dato il loro carattere o le circostanze o la maniera in cui vengono divulgate, siano evidentemente riservate, come (fermo restando il carattere generale di quanto precede) qualsiasi informazione su piani aziendali, nuove opportunità commerciali in fase di maturazione, progetti di ricerca e sviluppo, disegni, processi segreti, codici, progetti software, segreti commerciali, sviluppo e formule per prodotti o servizi, know-how, invenzioni, statistiche e previsioni di vendita, strategie e piani di marketing, costi, profitti e perdite e altre informazioni finanziarie (salvo nel caso di documenti contabili certificati e pubblicati), strutture di prezzi e sconti (registrati per iscritto o in formato elettronico o altra forma) (“Informazioni riservate”). Queste Informazioni riservate rimarranno di esclusiva proprietà di Lumen e/o dei suoi clienti, e in nessun caso otterrete o riceverete alcun diritto, titolo e/o interesse in relazione a queste Informazioni riservate.",
  "app.terms.line6": "Inoltre, durante la partecipazione a ViewPoints o in qualsiasi altro momento (senza limite) dopo la conclusione di tale partecipazione, l’Utente non dovrà: divulgare o comunicare ad alcuna persona; utilizzare per le proprie finalità; oppure a causa del mancato utilizzo della cura e della diligenza dovute, causare qualsivoglia divulgazione non autorizzata di qualsiasi Informazione riservata. L’Utente dovrà adoperarsi per evitare la pubblicazione o divulgazione di qualsiasi Informazione riservata. Queste restrizioni cesseranno di essere applicabili a qualsiasi informazione che diventerà di dominio pubblico per cause non imputabili all’Utente.",
  "app.terms.line7": "3. Proprietà di Lumen",
  "app.terms.line8": "Il Software ViewPoints e tutti i dati, i codici, le chiavi, le password, i progetti, i disegni, le registrazioni e altri documenti o materiali relativi al Software sono e rimangono di proprietà di Lumen per tutta la durata della partecipazione dell’Utente a un sondaggio di ViewPoints. Lumen detiene tutti i diritti di proprietà o di proprietà intellettuale in qualsiasi diritto esistente e futuro di proprietà intellettuale o industriale di tutti i documenti, materiali, modelli, progetti, disegni, processi, invenzioni, formule, codici informatici, dati, interfacce di sistema, metodologie, know-how, Informazioni riservate o altra opera/altro lavoro svolto, eseguito, creato, concepito, sviluppato, potenziato, destrutturato o scoperto dall’Utente da solo oppure con altra persona in relazione al Software e/o al Dispositivo o che può essere utilizzato o adattato per l’uso nello stesso o in relazione allo stesso (registrato o meno), ivi inclusi tutti gli esistenti e futuri brevetti, diritti d’autore, diritti relativi al design, diritti di database, marchi commerciali, diritti di topografie di prodotti a semiconduttori, diritti su varietà vegetali, diritti Internet/nomi di domini, know-how e qualsiasi domanda per ciascuno dei precedenti e qualsiasi diritto di fare domanda per ciascuno dei precedenti (“Diritti di proprietà”). Nessun Diritto di proprietà verrà trasferito in alcun momento all’Utente.",
  "app.terms.line9": "4. Termini d’uso e restrizioni",
  "app.terms.line10": "Il Software ViewPoints è concesso in licenza agli utenti finali. In base ai termini del presente Accordo, accettiamo di permettere all’Utente, su base limitata non esclusiva, revocabile, non trasferibile, non concedibile in ulteriore licenza, di utilizzare il software ViewPoints su un dispositivo di cui detiene la proprietà o il controllo o un personal computer in relazione al quale dichiara e garantisce di essere il legittimo titolare di tutti i diritti e i permessi necessari per tale installazione. Per dissipare ogni dubbio, va sottolineato che l’Utente non può utilizzare per alcuna finalità estranea a ViewPoints, tradurre, copiare, riprodurre, trasmettere tramite telecomunicazioni, adattare, fondere, cambiare o modificare il Software in alcun modo.",
  "app.terms.line11": "L’Utente si impegna a non: (i) copiare, modificare, adattare, rendere disponibile, affittare, noleggiare, condividere con altri soggetti, creare opere derivate o tentare di vendere o trasferire in altro modo qualsiasi diritto sul Software (ii) disassemblare, decompilare o decodificare qualsiasi parte del Software tramite qualsiasi mezzo; non consentire o aiutare terzi a individuarne o cercare di individuarne il codice sorgente, disassemblare, decifrare, decompilare o decodificare il Software; non adottare nessun’altra misura volta a individuare informazioni progettuali relative al Software; (iii) utilizzare il Software per qualsiasi finalità diversa da quella per cui era stato originariamente distribuito all’Utente; (iv) utilizzare spider, robot o altre tecniche automatizzate di data mining per catalogare, scaricare, memorizzare o riprodurre o distribuire in altro modo il Software, oppure per manipolare i risultati di qualsiasi sondaggio o altra attività; (v) adottare qualsiasi misura per interferire con il Software o l’uso del Software da parte di un individuo, ivi inclusi, senza limitazioni, pratiche di sovraccaricamento, “flooding”, “mail bombing” o “crashing” del Software e del sistema di memorizzazione dei dati utilizzato dal Software tramite Amazon Web Services (vi) inviarci alcun codice illegale, ingannevole o dannoso, ivi incluso, senza limitazione, qualsiasi virus, spyware, adware, o qualsiasi altro codice dannoso.",
  "app.terms.line12": "Tutti i diritti non espressamente concessi nel presente Accordo sono riservati a noi e ai nostri licenziatari. L’Utente riconosce che noi non abbiamo alcun obbligo ai sensi del presente Accordo o altro di correggere eventuali bug, difetti od errori del Software, o di sostenere, mantenere, migliorare, modificare, arricchire, aggiornare o potenziare il Software. Non saremo in alcun modo responsabili di fornire assistenza all’Utente o ai soggetti da esso designati per l’uso del Software.",
  "app.terms.line13": "Non potremo essere ritenuti responsabili per alcun danno causato dal PC dell’Utente, incluso, non in via limitativa, il danno derivante dall’uso del Software da parte dell’Utente. Non potremo essere ritenuti responsabili per eventuali spese ulteriori per traffico dati sostenute dall’Utente a causa dei dati raccolti dal Software nel corso della partecipazione alla Ricerca.",
  "app.terms.line14": "5. Liberatoria",
  "app.terms.line15": "Noi e i nostri rispettivi direttori, funzionari, dipendenti, agenti, rappresentanti, licenziatari e fornitori non forniamo alcuna garanzia, dichiarazione o condizione di alcun tipo relativamente al fatto (a)che il Software sarà disponibile tempestivamente, o che l'accesso al software sarà ininterrotto o sicuro; (b)che i difetti o gli errori saranno corretti; o (c)che il Software o i server o le reti tramite cui vengono resi disponibili il Software e il dispositivo sono sicuri o privi di virus o altri componenti dannosi. Non avremo alcun obbligo di fornire servizi di manutenzione e/o assistenza relativamente al Software.",
  "app.terms.line16": "6. Limitazione di responsabilità",
  "app.terms.line17": "(a) Nella misura massima consentita ai sensi della legge applicabile, l’Utente accetta che noi e i nostri rispettivi direttori, funzionari, dipendenti, agenti, rappresentanti, licenziatari e fornitori non saremo responsabili in alcun caso per qualsiasi perdita, danno o costo di qualunque tipo derivante da installazione, uso o accesso del software da parte dell’Utente, dall’uso o dall’incapacità di usare il software da parte dell’Utente, da qualsiasi informazione o materiale reso disponibile sul Software o tramite lo stesso e/o altrimenti associato al Software o comunque correlato al presente Accordo, indipendentemente dalla natura del diritto legale, equitativo o statutario che si dichiara violato.",
  "app.terms.line18": "(b) Nella misura massima consentita ai sensi della legge applicabile, il solo ed esclusivo rimedio a disposizione dell’Utente in caso di insoddisfazione in merito al software è quello di disinstallarlo e smettere di utilizzarlo.",
  "app.terms.line19": "(c) In nessun caso qualcuna delle nostre entità, dei nostri direttori, funzionari, dipendenti, agenti, rappresentanti saranno responsabili nei confronti dell’Utente per eventuali danni incidentali, indiretti, consequenziali, statutari, esemplari, di natura sanzionatoria o speciali, perdita di entrate, perdita di opportunità, perdita di reputazione, perdita di utilizzo, perdita di dati, perdita di altre attività immateriali, o intercettazione non autorizzata di dati raccolti dal Software da parte di terzi, prevedibile o meno, a prescindere dalla causa, e in base a qualsiasi contratto, illecito (inclusa la negligenza), responsabilità oggettiva o in virtù di qualunque altra teoria legale, indipendentemente dal fatto che l’Utente abbia informato in anticipo della possibilità che tali danni avrebbero potuto verificarsi.",
  "demo.index.content.line1": "Durante questa dimostrazione, utilizzeremo la tua telecamera per stimare la direzione del tuo sguardo. Non preoccuparti, non scatteremo alcuna foto né gireremo video.",
  "app.button.close": "Chiudi",
  "app.init.welcome": "Benvenuto/a nella piattaforma per ricerche di mercato di Lumen Research",
  "app.init.intro": "La piattaforma per ricerche di mercato ci consente di accogliere e preparare gli utenti che partecipano a un determinato studio di ricerca.",
  "app.init.message1": "A seconda del tipo di ricerca, l’utente verrà guidato attraverso una serie di attività.",
  "app.init.message2": "Queste attività potrebbero essere, ad esempio, il completamento di un'indagine, la lettura di una serie di istruzioni e l’esecuzione della calibrazione per il tracciamento oculare",
  "app.init.message3": "Ora ti guideremo attraverso uno studio dimostrativo.",
  "app.init.message4": "Nel corso di questo studio è possibile che venga attivata la tua webcam per eseguire il tracciamento oculare. Tuttavia, nessuno dei tuoi dati verrà registrato.",
  "app.init.message5": "Lo studio corrente è configurato in modo da utilizzare i seguenti collegamenti in caso di esclusione / completamento.",
  "app.setup.instruction0d": "Gira il dispositivo per attivare la vista orizzontale.",
  "app.setup.retryPositioning": "Non è stato possibile determinare la posizione della testa. Tieni ferma la testa e il dispositivo ed evita di coprire la fotocamera con le dita. Usa il pulsante sottostante per ritentare.",
  "app.setup.retryBroker": "Non è stato possibile completare il processo di calibrazione. Prova a chiudere le schede e le app inutilizzate per liberare risorse",
  "app.error.missingpanelparameter": "Parametro del panel mancante.",
  "app.error.previousfail": "In precedenza hai fallito la configurazione di questo studio. Utilizza il collegamento originale per riprovare dall'inizio.",
  "app.error.previoussuccess": "Hai già completato questo studio.",
  "app.error.route": "Hai tentato di avviare uno studio già in corso. Utilizza il collegamento originale per riprovare dall'inizio.",
  "app.error.accesscode": "Hai tentato di avviare uno studio che non esiste. Ricontrolla il collegamento.",
  "app.error.webcam": "Questo studio richiede l'accesso a una webcam",
  "app.error.adblocker": "Questo studio richiede la disattivazione dell’estensione adblock.",
  "app.error.startsession": "Impossibile avviare la sessione",
  "app.error.fullscreen": "Questo studio richiede la visualizzazione a schermo intero.",
  "app.error.zoom": "Questo studio richiede l’impostazione dello zoom del browser su 100%.",
  "app.index.webcamInstructions": "Stiamo per richiedere l'accesso alla tua webcam, se ricevi un popup, fai clic su consenti.",
  "app.thankyou": "Grazie.",
  "app.button.return": "Torna indietro",
  "app.index.content.line1gpdrwarning": "Durante questa indagine utilizzeremo la fotocamera frontale del Suo dispositivo per scattare immagini e filmati mentre Lei svolgerà varie attività che prevedono l'osservazione di materiali (detti “stimoli”).",
  "app.index.zoomRetry": "Il browser deve essere impostato con uno zoom del 100%, La preghiamo di ingrandire/rimpicciolire e riprovare",
  "app.index.fullscreendesktopretry": "Questa indagine richiede una visualizzazione a schermo intero; si assicuri di aver massimizzato la finestra del browser",
  "app.index.fullscreendesktoptest": "Questa indagine richiede che la finestra del browser sia a schermo intero; dobbiamo eseguire un test per verificare che la finestra del Suo browser sia massimizzata",
  "app.index.fullscreendesktoptest2": "A breve appariranno due pulsanti. Faccia clic su di essi per continuare",
  "app.index.fullscreendesktopmaximised": "Il mio schermo è già massimizzato",
  "app.index.fullscreendesktopskip": "Grazie, abbiamo registrato questa istanza e ci adopereremo per migliorare i nostri processi di rilevamento.",
  "app.index.fullscreendesktopreset": "Abbiamo rilevato un cambiamento nelle dimensioni della finestra e stiamo resettando il test.",
  "app.index.gotomobile": "Non è possibile partecipare a questa indagine da desktop; utilizzi il QR code qui sotto per aprire l’indagine su cellulare.",
  "app.index.returntopanel": "IN ALTERNATIVA, faccia clic pulsante qui sotto per tornare al sondaggio",
  "app.setup.instruction3audio": "Ascolti attentamente e segua le istruzioni che appariranno sullo schermo.",
  "app.error.cellquotafullnoactivecell": "Non sono state trovate celle attive per questa indagine. Numero massimo di celle raggiunto.",
  "app.completion.congratulations-noet": "Congratulazioni! Ha completato l’indagine.",
  "app.faq.gdpr.intro1": "In questa indagine utilizzeremo la fotocamera frontale di un dispositivo mobile per effettuare scatti di immagini e riprese video di un utente mentre gli/le viene chiesto di svolgere varie attività che prevedono l'osservazione di stimoli in una serie di condizioni ambientali (ad es. in relazione all’illuminazione e al posizionamento).  L’indagine dovrebbe richiedere circa 5 minuti per essere completata.  I dati raccolti saranno utilizzati per favorire il continuo sviluppo di software in grado di stimare dove stanno guardando gli utenti su uno schermo.",
  "app.faq.gdpr.intro2": "La presente ricerca è condotta in conformità al Codice di condotta MRS e al Data Protection Act 2018.  Noi teniamo alla Sua privacy.  Prima di partecipare a questa indagine, deve confermare che comprende quali dati raccoglieremo e le loro modalità di utilizzo come descritto nella presente Informativa sulla privacy, e che acconsente a tale utilizzo.  La presente informativa descrive in modo più dettagliato quali informazioni vengono raccolte e come vengono utilizzate e condivise.",
  "app.faq.gdpr.question1": "Quali dati verranno raccolti?",
  "app.faq.gdpr.answer1.line1": "Questa indagine prevede la raccolta dei seguenti tipi di dati:",
  "app.faq.gdpr.answer1.line2": "\"Caratteristiche della sessione\" - Un ID anonimo utilizzato per identificare una particolare “sessione” di raccolta dati e dati sulle condizioni ambientali prevalenti durante la sessione.",
  "app.faq.gdpr.answer1.line3": "\"Caratteristiche del partecipante\" - Dati relativi a un intervistato che partecipa a una determinata sessione, comprese le informazioni sui suoi attributi demografici (ad es. età, sesso), etnici (ad es. colore della pelle) e biologici (ad es. colore degli occhi).",
  "app.faq.gdpr.answer1.line4": "\"File video\" - Immagini e/o filmati ripresi utilizzando la fotocamera frontale del dispositivo per catturare immagini dei partecipanti mentre guardano, e possibilmente interagiscono, con degli stimoli visualizzati su uno o più schermi. Verranno catturate immagini degli occhi e del viso del partecipante e di qualsiasi altro oggetto che possa essere inavvertitamente ripreso dalla fotocamera frontale durante lo svolgimento dell’attività. ",
  "app.faq.gdpr.answer1.line5": "\"Metadati\" - Dati sull'ora in cui il partecipante ha interagito con gli stimoli, collegati al timestamp di ogni immagine raccolta.",
  "app.faq.gdpr.answer1.line6": "\"Dati fisici del dispositivo\": dati fisici relativi al dispositivo, tra cui il nome del dispositivo, le dimensioni dello schermo, la rotazione dello schermo e la rotazione del sensore della fotocamera.",
  "app.faq.gdpr.answer1.line7": "Partecipando a questa ricerca, Lei riconosce e accetta che il contenuto che Le sarà mostrato in questa indagine potrebbe essere riservato, di proprietà di terzi e/o soggetto a leggi sui marchi o sul diritto d’autore, e si impegna a non scattare screenshot, registrare o copiare in altro modo il contenuto o ridistribuire in alcun modo qualsiasi parte del contenuto dell’indagine.",
  "app.faq.gdpr.question2": "Come saranno utilizzati/elaborati i dati?",
  "app.faq.gdpr.answer2": "I dati saranno utilizzati ai fini dello sviluppo di un software in grado di stimare se gli utenti sono presenti davanti a un dispositivo, se stanno guardando degli stimoli e quale punto degli stimoli stanno guardando.  Potranno inoltre essere utilizzati a scopo dimostrativo o per spiegare a terzi (clienti, fornitori, partner) come è stata sviluppata la nostra tecnologia e le sue prestazioni, o per aiutarli a valutarle.  I dati potranno essere esaminati da un numero qualsiasi di \"annotatori\" di dati, ossia dipendenti, subappaltatori, partner o qualsiasi altra terza parte a cui verrà chiesto di rivedere i filmati/le immagini per valutare gli \"stati\" di attenzione dei partecipanti durante la ricerca (ad es. se sono presenti nel campo visivo della telecamera, se stanno guardando lo schermo, ecc.).  Le basi giuridiche per il trattamento dei dati saranno il consenso informato e gli interessi legittimi. ",
  "app.faq.gdpr.question3": "Per quanto tempo saranno conservati i dati?",
  "app.faq.gdpr.answer3": "Conserveremo i dati per il tempo ragionevolmente necessario per gli utilizzi sopra descritti.  Il software che stiamo sviluppando viene aggiornato e valutato periodicamente, e dal momento che questi dati saranno utilizzati per valutare ogni iterazione, prevediamo che saranno conservati e detenuti a tale scopo per parecchi anni, se non a tempo indeterminato.  I dati saranno cancellati se ritenuti non più utili ai fini dello sviluppo o della valutazione del software per il test degli stimoli. ",
  "app.faq.gdpr.question4": "Chi riceverà ed elaborerà i dati?",
  "app.faq.gdpr.answer4": "I dati saranno conservati e custoditi da Lumen Research Ltd., una società registrata in Inghilterra con numero di iscrizione 8682432.  La sua sede legale si trova a LABS House, 15-19 Bloomsbury Way, Holborn, Londra WC1A 2TH. Il suo identificativo di registrazione per la protezione dei dati è ZA130772.  Il suo Data Protection Officer è Matt Robinson (matt.robinson@lumen-research.com).  Lei ha il diritto di presentare reclamo in merito al trattamento e all'elaborazione dei Suoi dati in qualsiasi momento contattando l'Information Commissioner's Office, l'autorità britannica per la protezione dei dati.  I dati di contatto dell'ICO sono reperibili dal sito web https://ico.org.uk/global/contact-us/. Partecipando a questa indagine, Lei concede a Lumen una licenza a livello mondiale, esente da royalty, concedibile in sublicenza e trasferibile per ospitare, archiviare, utilizzare, visualizzare, riprodurre, modificare, adattare, modificare, pubblicare e distribuire questi dati.",
  "app.faq.gdpr.question5": "Ho il diritto di accedere ai miei dati personali?",
  "app.faq.gdpr.answer5": "Sì, ha il diritto di accedere ai Suoi dati personali, ma tenga presente che il Suo nome non verrà memorizzato insieme ai dati raccolti durante questa attività.  Affinché possiamo individuare i registri dei Suoi dati, dovrà fornirci l'ora e la data precisa in cui ha intrapreso la ricerca e dovremo chiederle di provare visivamente la Sua identità per assicurarci che i registri di dati corrispondano ad essa.",
  "app.faq.gdpr.question7": "Ho il diritto di revocare il consenso al trattamento dei miei dati personali o di cancellarli?",
  "app.faq.gdpr.answer7": "Sì, ha il diritto di revocare il consenso al trattamento dei Suoi dati personali e di cancellarli.  Tuttavia, tenga presente che il Suo nome non verrà memorizzato insieme ai dati raccolti durante questa attività.  Affinché possiamo individuare i registri dei Suoi dati, dovrà fornirci l'ora e la data precisa in cui ha intrapreso la ricerca e dovremo chiederle di provare visivamente la Sua identità per assicurarci che i registri di dati corrispondano ad essa.  ",
  "app.faq.gdpr.question8": "Come faccio a sapere che i miei dati personali sono al sicuro?",
  "app.faq.gdpr.answer8": "I dati raccolti da Lumen Research sono codificati tramite la tecnologia Secure Sockets Layer (SSL).  Si tratta di una tecnologia di crittografia di alto livello comunemente utilizzata. Questa tecnologia viene utilizzata sia per proteggere i canali di trasferimento che per l'archiviazione finale.",
  "app.faq.gdpr.question9": "Come saranno utilizzate e divulgate le informazioni raccolte?",
  "app.faq.gdpr.answer9": "Potremo divulgare le informazioni raccolte ad altri fornitori di servizi terzi per consentire loro di fornirci servizi. Potremo inoltre divulgare le informazioni a terzi in caso di riorganizzazione, fusione, vendita, joint venture, cessione, trasferimento o altra alienazione di tutta o parte della nostra attività, dei nostri beni o delle nostre azioni (anche in relazione a procedure fallimentari o simili). Potremo altresì utilizzare o divulgare le informazioni che raccogliamo da e su di Lei come riteniamo necessario o opportuno: (a) in base alla legge vigente, comprese le leggi al di fuori del Suo Paese di residenza; (b) per ottemperare a procedure legali; (c) per rispondere a richieste di autorità pubbliche e governative, ivi comprese autorità pubbliche e governative al di fuori del Suo Paese di residenza; (d) per far valere il nostro contratto di licenza con l'utente finale; (e) per proteggere le nostre operazioni o quelle di qualsiasi nostra affiliata; (f) per proteggere i nostri diritti, la nostra privacy, la nostra sicurezza o la nostra proprietà, e/o quella delle nostre affiliate, Sua o di altri; (g) per permetterci di perseguire i rimedi disponibili o limitare i danni che potremmo subire; e (h) per individuare e risolvere eventuali frodi o problemi di sicurezza. ",
  "app.faq.gdpr.question10": "Quali misure vengono adottate per conservare i dati in modo sicuro?",
  "app.faq.gdpr.answer10": "Adottiamo misure ragionevoli per proteggere le Sue informazioni utilizzando misure di sicurezza fisiche, elettroniche o procedurali adeguate alla sensibilità delle informazioni in nostra custodia o sotto il nostro controllo, che includono misure di protezione contro la perdita o il furto, nonché contro l'accesso, la divulgazione, la copia, l'uso o la modifica non autorizzati. ",
  "app.setup.retryBack": "La preghiamo di non premere il pulsante Indietro del browser durante l’indagine, in quanto ciò potrebbe comportare una perdita di ricompensa. Usi il pulsante sottostante per riprovare.",
  "app.setup.retryaudible": "Si assicuri che il Suo dispositivo non sia impostato su silenzioso e riprovi.",
  "app.restore.retry": "Non ha dedicato abbastanza tempo alla fase precedente, ",
  "app.restore.timeremaining": " minuti rimasti",
  "app.error.postexposure": "Siamo spiacenti, ha esaurito tutti i tentativi a Sua disposizione e purtroppo non potrà procedere nell’indagine."
}
