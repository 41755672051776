import LoggerController from "../../controllers/Logger/LoggerController";
import { RESET_CLICK_DATA, SET_CLICK_DATA } from "../../reducers/action-types";
import { store } from "../../store";

class MouseMoveListener {
  constructor() {
    this.isListening = false;
    this.clickData = null;
    this.throttle = null;

    this.subscribe = this.subscribe.bind(this);
    this.revoke = this.revoke.bind(this);
    this.resetThrottle = this.resetThrottle.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.updateState = this.updateState.bind(this);
    this.storeClickData = this.storeClickData.bind(this);
    this.clearData = this.clearData.bind(this);
  }

  subscribe(reset=false) {
    if (!this.isListening) {
      let currClickData = store.getState().baseReducer.clickData;
      if (!!!currClickData || !!!currClickData.click2 || !!!currClickData.click1 || reset) {
        this.isListening = true
        LoggerController.events.logSubscribe.log("mouseMoveListener")
        window.addEventListener("mousemove", this.handleMouseMove);
      } else {
        this.clickData = currClickData
      }
    }
  }

  revoke() {
    if (this.isListening) {
      this.isListening = false
      LoggerController.events.logRevoke.log("mouseMoveListener");
      window.removeEventListener("mousemove", this.handleMouseMove);
    }
  }

  resetThrottle(){
    this.throttle = null
  }

  handleMouseMove(e) {
    if (!this.throttle) {
      if (!this.clickData || (this.clickData && !this.clickData.click2)) {
        let newClickData = {
          xOffset: e.screenX - e.pageX,
          screenX: e.screenX,
          pageX: e.pageX,
        };

        if (
          this.clickData &&
          this.clickData.click1 && 
          Math.abs(this.clickData.click1.pageX - e.pageX) > 100
        ) {
          this.storeClickData(newClickData);
        } else if (this.clickData === null) {
          this.storeClickData(newClickData);
        }
      }

      this.throttle = setTimeout(this.resetThrottle, 500);
    }
  }

  updateState() {
    store.dispatch({ type: SET_CLICK_DATA, payload: this.clickData });
  }

  storeClickData(newClickData) {
    if (this.clickData && this.clickData.click1 && this.clickData.click2) {
      if (newClickData.screenX < this.clickData.click1.screenX) {
        this.clickData = { ...this.clickData, click1: newClickData };
      } else if (newClickData.screenX > this.clickData.click2.screenX) {
        this.clickData = { ...this.clickData, click2: newClickData };
      }
    } else if (this.clickData && this.clickData.click1) {
      if (newClickData.screenX < this.clickData.click1.screenX) {
        this.clickData = {
          click1: newClickData,
          click2: this.clickData.click1,
        };
      } else {
        this.clickData = {
          ...this.clickData,
          click2: newClickData,
        };
      }
    } else {
      this.clickData = { click1: newClickData };
    }

    if (this.clickData.click1 && this.clickData.click2) {
      this.revoke();
    }

    this.updateState();
  }

  clearData() {
    this.clickData = null;
    store.dispatch({ type: RESET_CLICK_DATA });
    this.subscribe(true);
  }
}

export default new MouseMoveListener();
