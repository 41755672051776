import { store } from "../../../../store";
import Endpoint from "../../Endpoint";

const getPayload = (answers) => {
    let {accessCode, cleanedParticipantReference} = store.getState().studyReducer

    let body = {
      access_code: accessCode,
      participant_reference: cleanedParticipantReference,
      question_answer_list: Object.values(answers),
    };

    return body;
  };

export default (studyId, answers) => {
  return new Endpoint(
    "pre-study-question-response",
    `${process.env.REACT_APP_PARTICIPATION_URL}/participation/studies/${studyId}/pre-study-question-response`,
    "POST",
    () => getPayload(answers),
    undefined,
    true
  );
};
