import React from "react";
import { FormattedMessage } from "react-intl";
import BackLanding from "../components/util/BackLanding";

import "./BasePage.css";

const BasePage = ({
  pageState,
  children,
  continueFunc,
  heading,
  headingTranslationId,
}) => {
  return (
    <>
      {pageState === "init" ? (
        <></>
      ) : pageState === "back" ? (
        <BackLanding continueFunc={continueFunc}></BackLanding>
      ) : (
        <div className={"ls-base-page"}>
          {!!heading && (
            <h1>
              <FormattedMessage
                id={headingTranslationId}
                defaultMessage={heading}
                description="Page Heading"
              ></FormattedMessage>
            </h1>
          )}
          {children}
        </div>
      )}
    </>
  );
};

export default BasePage;
