{
  "title": "﻿Plataforma de estudios Lumen - Política de privacidad",
  "date": "Julio de 2022",
  "contactDetails": {
    "title": "Nuestros datos de contacto ",
    "body": [
      "Nombre: Lumen Research Ltd (\"Lumen\")",
      "Dirección: Screenworks, Unit 215, 22 Highbury Grove, London N5 2ER",
      "Número de teléfono: +44(0)2037355199 ",
      "Correo electrónico: dataprotection@lumen-research.com",
      "Referencia de registro del ICO: ZA130772"
    ]
  },
  "sections": [
    {
      "body": [
        "Esta política de privacidad explica la naturaleza de la información que recopilamos de los participantes que toman parte en una encuesta de seguimiento ocular en la plataforma de estudios Lumen y nuestra política que rige cualquier información personal que pueda ser recopilada. "
      ],
      "heading": "Introducción"
    },
    {
      "body": [
        "En una encuesta típica, Lumen no recopila información personal según el Reglamento General de Protección de Datos del Reino Unido, pero recopilará estimaciones de los puntos a los que mira el participante; respuestas a la encuesta; metadatos sobre cuándo y en qué tipo de dispositivo tuvo lugar la recopilación de datos; e identificaciones de los encuestados con el fin de administrar las recompensas.",
        "En una encuesta típica se le pedirá que se siente frente a la cámara web o la cámara frontal de su dispositivo y realice una calibración para poder hacer una estimación de qué parte de su pantalla está mirando. Durante la encuesta, se le pedirá que mire algunos elementos (p. ej. imágenes, sitios web o vídeos) mientras la herramienta de la encuesta calcula dónde está mirando. Los datos que se obtienen de este \"seguimiento ocular\" son las coordenadas de los puntos a los que mira en su pantalla a lo largo del tiempo. El programa utiliza imágenes de su cámara web para hacer estas estimaciones, pero no captura fotos ni videos de usted. ",
        "También se le harán algunas preguntas sobre usted, su experiencia y lo que ha visto durante la encuesta. ",
        "Los datos de estas encuestas se envían a Lumen Research Ltd (\"Lumen\"). Lumen analizará los datos y compartirá los resultados con sus clientes. ",
        "En estas encuestas, un tercero transmitirá a Lumen una identificación alfanumérica del encuestado para que Lumen pueda hacer un seguimiento de quiénes completan la encuesta. Lumen compartirá esta información sobre las finalizaciones con terceros con el fin de dar recompensas a quienes hayan completado la encuesta. "
      ],
      "heading": "El tipo de información que recopilamos"
    },
    {
      "body": [
        "De manera ocasional, se puede pedir a los encuestados que participen en un proyecto de investigación que requiera más de un periodo de recopilación de datos. En este caso, la identificación del encuestado se utilizará como un identificador persistente para volver a contactar con un participante único con el fin de iniciar su participación en cualquier ejercicio de recopilación de datos posterior. En este caso, la identificación del encuestado se considerará información personal.  "
      ],
      "heading": "El tipo de información personal que recopilamos "
    },
    {
      "body": [
        "Al igual que con una encuesta típica, un tercero que haya reclutado a un participante para nuestra investigación nos transmitirá una identificación persistente del encuestado. La utilizaremos para volver a contactar con las personas para iniciar su posterior participación en la investigación y podremos compartirla con terceros para facilitar esta posterior participación.",
        "Según el Reglamento General de Protección de Datos (RGPD) del Reino Unido, las bases legales en las que nos basamos para tratar esta información son su consentimiento. Puede retirar su consentimiento en cualquier momento. Puede hacerlo contactándonos a dataprotection@lumen-research.com"
      ],
      "heading": "Cómo obtenemos la información personal y por qué lo hacemos"
    },
    {
      "body": [
        "Su información se almacena de forma segura y se cifra con la tecnología SSL. Esta es la tecnología de cifrado de alto nivel más utilizada y es la que utilizan los bancos para asegurar las transacciones bancarias y otras comunicaciones muy sensibles. También la utilizan Amazon, PayPal, eBay y otros sitios web que requieren sistemas de seguridad de nivel bancario. Los científicos estiman que romper el cifrado de 256 bits con la tecnología actual requeriría mucho más tiempo que la edad del universo. Los datos recopilados se almacenan en servidores web seguros gestionados por Amazon Web Services (AWS). AWS es una de las plataformas de servicios de computación en la nube más seguras y fiables y es utilizada por los principales servicios comerciales basados en la nube, incluidos Netflix y Expedia. ",
        "Conservamos los identificadores persistentes durante el periodo de tiempo necesario para volver a contactar con los encuestados en cualquier investigación de varias fases. Esto podría variar en función de la naturaleza de la investigación y se le notificará cuando dé su consentimiento para participar. Una vez finalizada la recopilación de datos para dicho proyecto, los identificadores se conservarán únicamente en la medida en que sea necesario para el análisis y la presentación de datos. Una vez finalizado el análisis y el informe del proyecto, eliminaremos esta información personal de nuestras bases de datos."
      ],
      "heading": "Cómo almacenamos su información personal "
    }
  ],
  "dataProtection": {
    "body": ["Según la Ley de Protección de Datos, sus derechos incluyen:"],
    "bullets": [
      {
        "title": "Su derecho de acceso",
        "text": [
          "Tiene derecho a solicitarnos copias de su información personal."
        ]
      },
      {
        "title": "Su derecho de rectificación",
        "text": [
          "Tiene derecho a solicitarnos que rectifiquemos su información personal que considere inexacta. También tiene derecho a solicitarnos que completemos su información si piensa que está incompleta."
        ]
      },
      {
        "title": "Su derecho a la eliminación",
        "text": [
          "Tiene derecho a solicitarnos que eliminemos su información personal en determinadas circunstancias."
        ]
      },
      {
        "title": "Su derecho a la restricción del tratamiento",
        "text": [
          "Tiene derecho a solicitarnos que restrinjamos el tratamiento de su información personal en determinadas circunstancias. "
        ]
      },
      {
        "title": "Su derecho a retirar su consentimiento",
        "text": [
          "Tiene derecho a retirar su consentimiento para el tratamiento de su información personal."
        ]
      },
      {
        "title": "Su derecho a la portabilidad de datos",
        "text": [
          "Tiene derecho a solicitarnos que transfiramos la información personal que nos ha facilitado a otra organización, o a usted, en determinadas circunstancias.",
          "No está obligado a pagar ningún cargo por ejercer sus derechos. Si hace una solicitud, tenemos un mes para responderle.",
          "Por favor, contáctenos a dataprotection@lumen-research.com si desea hacer una solicitud."
        ]
      }
    ],
    "title": "Sus derechos en materia de protección de datos"
  },
  "complaints": {
    "body": [
      "Si tiene alguna duda sobre el uso que hacemos de su información personal, puede presentar una reclamación en dataprotection@lumen-research.com.",
      "También puede presentar una reclamación ante el ICO si no está satisfecho con el uso que hemos hecho de sus datos."
    ],
    "address": {
      "body": [
        "Oficina del Comisario de Información",
        "Wycliffe House",
        "Water Lane",
        "Wilmslow",
        "Cheshire",
        "SK9 5AF"
      ],
      "title": "La dirección del ICO:            "
    },
    "title": "Cómo reclamar",
    "number": "Número del teléfono de asistencia: 0303 123 1113",
    "website": "Sitio web del ICO: https://www.ico.org.uk"
  }
}
