import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "../components/shared/Button";

const Retry = ({ text, id, onClick }) => {
  return (
    <>
      <p className="instructionsText" data-testid="retry-instructions-text">
        {!!id ? (
          <FormattedMessage
            id={id}
            defaultMessage={text}
            description="Setup Instructions"
          />
        ) : null}
      </p>
      <Button
        dataTestId="retry-button"
        onClick={onClick}
        text="Retry"
        translationId={"app.setup.retryButton"}
      ></Button>
    </>
  );
};

export default Retry;
