import * as Brokers from "@lumen-developer/lumen-common-js/brokers.bundle";
import TrackingBundleInternal from "@lumen-developer/lumen-common-js/trackingbundleinternal.bundle";
import { StorageHandler } from "@lumen-developer/lumen-common-js/storageHandler.bundle";
import config from "./config";
import { BROKER_TYPES } from "../../types/types.js";
import arrive from "arrive";
import helpers from "./helpers";
import Initialise from "./steps/Initialise";
import HeadPositioning from "./steps/HeadPositioning";
import Calibrate from "./steps/Calibrate";
import Validate from "./steps/Validate";
import ReInit from "./steps/ReInit";
import LoggerController from "../../controllers/Logger/LoggerController";
import { routeType } from "../util/util";
import ApiController from "../../controllers/Api/ApiController";
import { store } from "../../store/index.js";

class _LumenEyeTracking {
  constructor() {
    this.broker = null;
    this.brokerType = null;
    this.onErrorEvent = "lr-broker-error";
    this.lastError = "";
    this.trackingBundle = null;
    this.canvasCont = null;
    this.videoElement = null;
    this.faceOverlay = null;
    this.headPositionBool = false;
    this.eyePositionTimer = null;
    this.startCalibrationTime = null;
    this.instructions = [];
    this.errored = false;
    this.calibrationPoint = 0;
    this.calibrationTotal = 0;
    this.lastCalibration = 0;
    this.validationPoint = 0;
    this.validationTotal = 0;
    this.lastValidation = 0;
    this.pointListener = null;
    this.brokerSubEvents = [];
    this.firstFrame = 0;
    this.lastFrame = 0;
    this.totalFrames = 0;
    this.fps = 0;
    this.firstLandmark = 0;
    this.lastLandmark = 0;
    this.totalLandmarks = 0;
    this.landmarksPerSec = 0;
    this.setupComplete = false;
    this.isCrawler = false;
    this.newTrackingTables = false;

    // this.steps = {
    //   initialise: new Initialise(),
    //   headPositioning: new HeadPositioning(),
    //   calibrate: new Calibrate(),
    //   validate: new Validate(),
    //   reinit: new ReInit(),
    // };

    this.steps = [];

    this.currentStep = "idle";
    this.runningStep = null;

    this.initialise = this.initialise.bind(this);
    this.headPositioning = this.headPositioning.bind(this);
    this.calibrate = this.calibrate.bind(this);
    this.reInitialise = this.reInitialise.bind(this);
  }

  initialise(
    brokerUrl,
    userId,
    studyId,
    sessionId,
    cellId,
    trackingElement,
    isMobile,
    isCrawler,
    newTrackingTables = false
  ) {
    let initialiseStep = new Initialise();

    this.steps.push(initialiseStep);

    return initialiseStep.start(
      brokerUrl,
      userId,
      studyId,
      sessionId,
      cellId,
      trackingElement,
      isMobile,
      isCrawler,
      newTrackingTables
    );
  }

  headPositioning(timeout, element, userId, studyId, panelCode) {
    let headPositioningStep = new HeadPositioning();

    this.steps.push(headPositioningStep);

    return headPositioningStep.start(
      timeout,
      element,
      userId,
      studyId,
      panelCode
    );
  }

  calibrate(element, timeout = 120000) {
    let calibrateStep = new Calibrate();

    this.steps.push(calibrateStep);

    return calibrateStep.start(element, timeout);
  }

  validate(
    element,
    participant,
    userId,
    studyId,
    sessionId,
    cellId,
    stepId,
    stepOrderId,
    timeout = 120000,
    panelCode
  ) {
    let validateStep = new Validate();

    this.steps.push(validateStep);

    return validateStep.start(
      element,
      participant,
      userId,
      studyId,
      sessionId,
      cellId,
      stepId,
      stepOrderId,
      timeout,
      panelCode
    );
  }

  reInitialise(
    studyId,
    userId,
    sessionId,
    brokerUrl,
    cellId = null,
    isMobile = false,
    isCrawler = false,
    newTrackingTables = false
  ) {
    let reInitialiseStep = new ReInit();

    this.steps.push(reInitialiseStep);

    return reInitialiseStep.start(
      studyId,
      userId,
      sessionId,
      brokerUrl,
      cellId,
      isMobile,
      isCrawler,
      newTrackingTables
    );
  }

  debugVideo() {
    if (
      process.env.REACT_APP_LUMEN_ENV_VALUE !== "release" &&
      process.env.REACT_APP_LUMEN_ENV_VALUE !== "alpha"
    ) {
      let videoElement = document.getElementById("lr_record_video");
      videoElement.height = "100";
      videoElement.width = "100";
      videoElement.style.zIndex = "1000";
      videoElement.style.display = "block";
      videoElement.style.position = "fixed";
      videoElement.style.bottom = "0px";
    }
  }

  async errorHandler(resolve, cleanUp, errorOrigin, startTime) {
    if (errorOrigin !== "idle") {
      let logData = await LumenEyeTracking.getLogData(
        this.isCrawler ? false : true
      );

      if (
        logData.getUserMedia &&
        logData.getUserMedia &&
        !logData.getUserMedia.success
      ) {
        LumenEyeTracking.lastError.message = `[getUserMedia] ${logData.getUserMedia.message}`;
        LumenEyeTracking.lastError.retryCode = "cameraAccess";
      }

      LumenEyeTracking.lastError.errorCode =
        config.errorConfig[errorOrigin].errorCode;
      LumenEyeTracking.lastError.name =
        config.errorConfig[errorOrigin].errorCode;

      if (!LumenEyeTracking.lastError.retryCode) {
        LumenEyeTracking.lastError.retryCode = "genericError";
      }

      if (LumenEyeTracking.brokerType === BROKER_TYPES.AUDIBLE) {
        LumenEyeTracking.lastError.retryCode = "audible";
      }
      LoggerController.events.logSetupProgress.panelistAction(
        `${this.stepName} - errored`,
        {
          data: logData,
          error: LumenEyeTracking.lastError.toString(),
        }
      );
      LoggerController.sendToExtendedLogger(
        routeType(),
        "action",
        undefined,
        undefined,
        `${this.stepName}_error`,
        LumenEyeTracking.lastError.toString()
      );

      cleanUp();

      resolve({
        success: false,
        error: LumenEyeTracking.lastError,
        data: logData,
        cancelled: errorOrigin === "cancel",
      });
    }
  }

  async getLogData(full = true) {
    let timestamp = new Date().getTime();

    let dataOut = {};

    if (
      full &&
      !this.isCrawler &&
      LumenEyeTracking.brokerType === BROKER_TYPES.STANDARD
    ) {
      // repeated getUserMedia calls stops existing streams on older IOS versions\
      dataOut.getUserMedia = await helpers.getUserMedia(
        LumenEyeTracking.brokerType
      );
    }
    dataOut.enumerateDevices = await helpers.enumerateDevices();

    if (LumenEyeTracking.brokerType === BROKER_TYPES.STANDARD) {
      if (LumenEyeTracking.firstFrame !== LumenEyeTracking.lastFrame) {
        dataOut.frameData = {
          currFps: LumenEyeTracking.fps.toFixed(2),
          avgFps:
            (1000 * LumenEyeTracking.totalFrames) /
            (LumenEyeTracking.lastFrame - LumenEyeTracking.firstFrame),
          timeSinceLastFrame: timestamp - LumenEyeTracking.lastFrame,
        };
        if (LumenEyeTracking.firstLandmark !== LumenEyeTracking.lastLandmark) {
          dataOut.landmarkData = {
            currLps: LumenEyeTracking.landmarksPerSec.toFixed(2),
            avgLps:
              (1000 * LumenEyeTracking.totalLandmarks) /
              (LumenEyeTracking.lastLandmark - LumenEyeTracking.firstLandmark),
            timeSinceLastLandmark: timestamp - LumenEyeTracking.lastLandmark,
          };
        }
      }

      dataOut.brokerSubEvents = LumenEyeTracking.brokerSubEvents;

      if (LumenEyeTracking.validationPoint > 0) {
        dataOut.pointData = {
          type: "validation",
          pointsCompleted: LumenEyeTracking.validationPoint,
          averageTimeTaken: parseFloat(
            (
              LumenEyeTracking.validationTotal /
              LumenEyeTracking.validationPoint
            ).toFixed(2)
          ),
          timeSinceLastPoint: timestamp - LumenEyeTracking.lastValidation,
        };
      } else if (LumenEyeTracking.calibrationPoint > 0) {
        dataOut.pointData = {
          type: "calibration",
          pointsCompleted: LumenEyeTracking.calibrationPoint,
          averageTimeTaken: parseFloat(
            (
              LumenEyeTracking.calibrationTotal /
              LumenEyeTracking.calibrationPoint
            ).toFixed(2)
          ),
          timeSinceLastPoint: timestamp - LumenEyeTracking.lastCalibration,
        };
      }
    }

    return dataOut;
  }

  handleNextFrame() {
    let currTime = new Date().getTime();

    if (LumenEyeTracking.totalFrames === 0) {
      LumenEyeTracking.firstFrame = currTime;
    }

    if (LumenEyeTracking.lastFrame !== 0) {
      let timeTaken = (currTime - LumenEyeTracking.lastFrame) / 1000;
      LumenEyeTracking.fps = 1 / timeTaken;
    }

    LumenEyeTracking.totalFrames += 1;
    LumenEyeTracking.lastFrame = currTime;
  }

  handleNextLandmarks() {
    let currTime = new Date().getTime();

    if (LumenEyeTracking.totalLandmarks === 0) {
      LumenEyeTracking.firstLandmark = currTime;
    }

    if (LumenEyeTracking.lastGaze != 0) {
      let timeTaken = (currTime - LumenEyeTracking.lastLandmark) / 1000;
      LumenEyeTracking.landmarksPerSec = 1 / timeTaken;
    }

    LumenEyeTracking.totalLandmarks += 1;
    LumenEyeTracking.lastLandmark = currTime;
  }

  handleSubEvent(eventName) {
    let timestamp = new Date().getTime();
    let newEvent = { eventName, timestamp };

    if (LumenEyeTracking.lastFrame !== 0) {
      newEvent.frameData = {
        fps: LumenEyeTracking.fps.toFixed(0),
        timeSinceLastFrame: timestamp - LumenEyeTracking.lastFrame,
      };
    }

    LumenEyeTracking.brokerSubEvents = [
      ...LumenEyeTracking.brokerSubEvents,
      newEvent,
    ];
  }

  initialiseCustomEventHandlers() {
    if (
      LumenEyeTracking.brokerType !== BROKER_TYPES.MOUSE ||
      LumenEyeTracking.brokerType !== BROKER_TYPES.MOBILEMOUSE ||
      LumenEyeTracking.brokerType !== BROKER_TYPES.NO_EYETRACKING ||
      LumenEyeTracking.brokerType !== BROKER_TYPES.AUDIBLE
    ) {
      let subEventHandlers = config.brokerSubEvents.map((eventName) => {
        return {
          eventName,
          cb: () => {
            LumenEyeTracking.handleSubEvent(eventName);
          },
        };
      });

      let nextFrameHandler = {
        eventName: "onNextFrame",
        cb: () => {
          LumenEyeTracking.handleNextFrame();
        },
      };

      let nextLandmarksHandler = {
        eventName: "onNextLandmarks",
        cb: () => {
          LumenEyeTracking.handleNextLandmarks();
        },
      };

      return [...subEventHandlers, nextFrameHandler, nextLandmarksHandler];
    }

    return [];
  }

  initialiseErrorPassthrough() {
    if (
      LumenEyeTracking.broker &&
      LumenEyeTracking.broker.registerErrPassthrough
    ) {
      LumenEyeTracking.broker.registerErrPassthrough((e) => {
        if (e.message.includes("No Frame detected for")) {
          e.message = "VideoFrameReader: No frame detected for 10s";
        }

        let brokerError = new CustomEvent(LumenEyeTracking.onErrorEvent, {
          detail: { error: e, retryCode: "brokerError" },
        });

        if(!!LumenEyeTracking.broker.trackersController && !LumenEyeTracking.broker.trackersController.frameReader().running()) {
          brokerError = new CustomEvent(LumenEyeTracking.onErrorEvent, {
            detail: { error: {message:"Failed to turn on camera."}, retryCode: "brokerError" },
          });
        }
        
        document.dispatchEvent(brokerError);
      });
    }
  }

  initBrokerType(brokerUrl) {
    const { testType } = store.getState().studyReducer;
    if(testType === "3") {
      LumenEyeTracking.brokerType = BROKER_TYPES.AUTOMATED_TESTING_BROKER;
      return;
    }

    if (brokerUrl) {
      if (brokerUrl.includes("/mouse_broker/")) {
        LumenEyeTracking.brokerType = BROKER_TYPES.MOUSE;
      } else if (brokerUrl.includes("/mobile_mouse_broker/")) {
        LumenEyeTracking.brokerType = BROKER_TYPES.MOBILEMOUSE;
      } else if (brokerUrl.includes("/no_eyetracking_broker/")) {
        LumenEyeTracking.brokerType = BROKER_TYPES.NO_EYETRACKING;
      } else if (brokerUrl.includes("/x2_tobii_broker/")) {
        LumenEyeTracking.brokerType = BROKER_TYPES.X2;
      } else if (brokerUrl.includes("/audible_broker/")) {
        LumenEyeTracking.brokerType = BROKER_TYPES.AUDIBLE;
      } else {
        LumenEyeTracking.brokerType = BROKER_TYPES.STANDARD;
      }
    }
  }

  getTrackingBundle(userId, studyId, sessionId, cellId) {
    LumenEyeTracking.trackingBundle = new TrackingBundleInternal(
      LumenEyeTracking.broker,
      userId,
      studyId,
      sessionId,
      cellId
    );
  }

  async initHUD(userId = "", studyId = 0, sessionId = 0, cellId = 1) {
    LumenEyeTracking.initBrokerType("/no_eyetracking_broker/");
    LumenEyeTracking.getBroker();

    // no init for NOET broker
    // await LumenEyeTracking.broker.init(
    //   30000,
    //   null,
    //   { isMobile: false },
    //   []
    // );

    LumenEyeTracking.trackingBundle = new TrackingBundleInternal(
      LumenEyeTracking.broker,
      userId,
      studyId,
      sessionId,
      cellId
    );

    await LumenEyeTracking.trackingBundle.init(
      null,
      null,
      [],
      false,
      true,
      true
    );
  }

  showHud(which) {
    LumenEyeTracking.trackingBundle.hud.showHud = which;
    LumenEyeTracking.trackingBundle.hud.render();
  }

  // startHUD() {
  //   let script = document.createElement("script");
  //   script.src = "https://study.dev.lumen-research.com/bundles/hud.bundle.js";

  //   document.body.appendChild(script);
  // }

  startPointListener(valcal) {
    if (
      LumenEyeTracking.brokerType !== BROKER_TYPES.MOUSE ||
      LumenEyeTracking.brokerType !== BROKER_TYPES.MOBILEMOUSE ||
      LumenEyeTracking.brokerType !== BROKER_TYPES.NO_EYETRACKING ||
      LumenEyeTracking.brokerType !== BROKER_TYPES.AUDIBLE
    ) {
      if (
        valcal === "calibration" &&
        LumenEyeTracking.broker &&
        LumenEyeTracking.broker.trackersController &&
        LumenEyeTracking.broker.trackersController._detectorCalibrator
      ) {
        LumenEyeTracking.pointListener =
          LumenEyeTracking.broker.trackersController._detectorCalibrator
            .eventManager()
            .subscribe("onNextCalibrationPoint", () => {
              LumenEyeTracking.calibrationPoint =
                LumenEyeTracking.calibrationPoint += 1;
              if (LumenEyeTracking.calibrationPoint === 4) {
                clearTimeout(LumenEyeTracking.pointTimeout);
              } else {
                LumenEyeTracking.startPointTimeout();
              }
              let currTime = new Date().getTime();
              if (LumenEyeTracking.calibrationPoint === 1) {
                LumenEyeTracking.lastCalibration = new Date().getTime();
              } else {
                let timeTaken = currTime - LumenEyeTracking.lastCalibration;
                LumenEyeTracking.lastCalibration = currTime;
                LumenEyeTracking.calibrationTotal += timeTaken;
                if (LumenEyeTracking.calibrationPoint === 2) {
                  LoggerController.sendToExtendedLogger(
                    routeType(),
                    "action",
                    undefined,
                    undefined,
                    "calibration_point",
                    `${timeTaken}`
                  );
                }
              }
            });
      } else if (
        valcal === "validation" &&
        LumenEyeTracking.broker &&
        LumenEyeTracking.broker.trackersController &&
        LumenEyeTracking.broker.trackersController._validatorCalibrator
      ) {
        LumenEyeTracking.pointListener =
          LumenEyeTracking.broker.trackersController._validatorCalibrator
            .eventManager()
            .subscribe("onNextCalibrationPoint", () => {
              LumenEyeTracking.validationPoint =
                LumenEyeTracking.validationPoint += 1;
              if (LumenEyeTracking.validationPoint === 4) {
                clearTimeout(LumenEyeTracking.pointTimeout);
              } else {
                LumenEyeTracking.startPointTimeout();
              }
              let currTime = new Date().getTime();
              if (LumenEyeTracking.validationPoint === 1) {
                LumenEyeTracking.lastValidation = currTime;
              } else {
                let timeTaken = currTime - LumenEyeTracking.lastValidation;
                LumenEyeTracking.lastValidation = currTime;
                LumenEyeTracking.validationTotal += timeTaken;
                if (LumenEyeTracking.validationPoint === 2) {
                  LoggerController.sendToExtendedLogger(
                    routeType(),
                    "action",
                    undefined,
                    undefined,
                    "validation_point",
                    `${timeTaken}`
                  );
                }
              }
            });
      }
    }
  }

  getBroker() {
    switch (LumenEyeTracking.brokerType) {
      case BROKER_TYPES.MOUSE:
        LumenEyeTracking.broker = new Brokers.LRMouseBroker();
        break;
      case BROKER_TYPES.MOBILEMOUSE:
        LumenEyeTracking.broker = new Brokers.LRMobileMouseBroker();
        break;
      case BROKER_TYPES.NO_EYETRACKING:
        LumenEyeTracking.broker = new Brokers.LRNoETBroker();
        break;
      case BROKER_TYPES.AUDIBLE:
        LumenEyeTracking.broker = new Brokers.LRAudibleBroker();
        break;
      case BROKER_TYPES.X2:
        LumenEyeTracking.broker = new Brokers.LRX2Broker();
        break;
      case BROKER_TYPES.AUTOMATED_TESTING_BROKER:
        LumenEyeTracking.broker = new Brokers.LRAutomatedTestingBroker();
        break;
      default:
        LumenEyeTracking.broker = new Brokers.LRBroker();
    }
  }

  prepareCanvas(element) {
    element.style.display = "unset";
    element.style.visibility = "hidden";
    setTimeout(() => {
      element.style.visibility = "unset";
    }, 1000);

    LumenEyeTracking.canvasCont = document.createElement("div");
    LumenEyeTracking.canvasCont.style.left = "50%";
    LumenEyeTracking.canvasCont.style.top = "50%";
    LumenEyeTracking.canvasCont.style.minWidth = "300px";
    LumenEyeTracking.canvasCont.style.maxWidth = "1000px";

    element.appendChild(LumenEyeTracking.canvasCont);

    LumenEyeTracking.videoElement = document.getElementById("lr_show_video");

    LumenEyeTracking.canvasCont.appendChild(LumenEyeTracking.videoElement);

    var faceOverlay = document.createElement("face_overlay");
    faceOverlay.id = "faceOverlay";
    faceOverlay.style.position = "absolute";
    faceOverlay.style.top = "50%";
    faceOverlay.style.left = "50%";
    faceOverlay.style.border = "solid";
    faceOverlay.style.webkitTransform = "translate3d(-50%, -50%, 0)";
    //faceOverlay.style.webkitTransform = 'translate3d(0%, -100%, 0)';
    faceOverlay.style.zIndex = "25555";
    if (window.innerWidth <= 1023) {
      faceOverlay.style.width = "80%";
      faceOverlay.style.height = "80%";
    } else {
      faceOverlay.style.width = "20%";
      faceOverlay.style.height = "40%";
    }

    LumenEyeTracking.canvasCont.appendChild(faceOverlay);
  }

  createInstructions(element, position, text) {
    let instruction = document.createElement("h2");
    instruction.id = "positioningInstructions";
    instruction.style.position = "absolute";
    instruction.style.color = "white";
    instruction.style.left = "50%";
    instruction.style.transform = "translate(-50%, -50%)";
    instruction.style.textAlign = "center";
    instruction.style.lineHeight = "1";
    instruction.textContent = text;

    if (position == "bottom") {
      instruction.style.top = "calc(100% - 150px)";
    } else {
      instruction.style.top = "150px";
    }

    LumenEyeTracking.instructions.push(instruction);
    element.appendChild(instruction);
  }

  removeInstructions() {
    LumenEyeTracking.instructions.forEach((instructionElement) => {
      if (instructionElement) {
        instructionElement.remove();
      }
    });
  }

  setBrokerVideoDiv(element) {
    if (
      LumenEyeTracking.brokerType == BROKER_TYPES.STANDARD &&
      !!LumenEyeTracking.broker
    ) {
      LumenEyeTracking.broker.setVideoDiv(element);
    }
  }

  updateEyePosition(eyePosRaw) {
    let eyePos = {
      left_eye_out_y: eyePosRaw.left().out.y,
      left_eye_out_x: eyePosRaw.left().out.x,
      right_eye_out_y: eyePosRaw.right().out.y,
      right_eye_out_x: eyePosRaw.right().out.x,
    };
    var canvasWidth = document.querySelector("#lr_record_video").videoWidth;
    var canvasHeight = document.querySelector("#lr_record_video").videoHeight;
    var eyePosBool = LumenEyeTracking.processEyePos(
      eyePos,
      canvasWidth,
      canvasHeight
    );
    LumenEyeTracking.headPositionBool = eyePosBool;
    if (eyePosBool) {
      document.getElementById("faceOverlay").style.borderColor = "green";
      LumenEyeTracking.eyePositionTimer++;
    } else {
      document.getElementById("faceOverlay").style.borderColor = "red";
      LumenEyeTracking.eyePositionTimer = 0;
    }
  }

  async sendValidationData(stepId, stepOrderId, validStartTime, valData) {
    let validationData = {};
    if (
      LumenEyeTracking.brokerType == BROKER_TYPES.MOUSE ||
      LumenEyeTracking.brokerType == BROKER_TYPES.MOBILEMOUSE ||
      LumenEyeTracking.brokerType == BROKER_TYPES.AUDIBLE ||
      LumenEyeTracking.brokerType == BROKER_TYPES.NO_EYETRACKING
    ) {
      validationData = {
        accuracy: 100,
        accuracy_x: 100,
        accuracy_y: 100,
        raw_accuracy: 100,
        raw_accuracy_x: 100,
        raw_accuracy_y: 100,
        precision: 100,
        precision_x: 100,
        precision_y: 100,
        timestamp: Date.now(),
        hit50: 0,
        hit100: 0,
        hit200: 0,
        time_since_calibration: 1000,
        gaze_duration: 0.03,
        frame_rate: 25,
        extra_data: {},
      };
    } else if (valData) {
      validationData = {
        accuracy: valData.accuracy(),
        accuracy_x: valData.xAccuracy(),
        accuracy_y: valData.yAccuracy(),
        raw_accuracy: null,
        raw_accuracy_x: null,
        raw_accuracy_y: null,
        precision: valData.precision(),
        precision_x: valData.xPrecision(),
        precision_y: valData.yPrecision(),
        hit50: 0,
        hit100: 0,
        hit200: 0,
        time_since_calibration:
          Math.abs(LumenEyeTracking.startCalibrationTime - Date.now()) / 1000,
        gaze_duration: valData.duration() / valData.sampleCount() / 1000,
        frame_rate: valData._misc.errorCorrectionFramerate,
        extra_data: { ...valData },
      };
    }
    let response = await ApiController.request.tracking
      .validationresult(stepId, stepOrderId, validStartTime, validationData)
      .call();

    return response;
  }

  startPointTimeout() {
    clearTimeout(LumenEyeTracking.pointTimeout);

    LumenEyeTracking.pointTimeout = setTimeout(() => {
      let e = new Error("User didnt progress through calibrate points for 15s");
      e.name = "Point Timeout";

      LumenEyeTracking.lastError = e;
      LumenEyeTracking.errored = true;
      LumenEyeTracking.lastError.retryCode = "pointTimeout";

      let brokerError = new CustomEvent(LumenEyeTracking.onErrorEvent, {
        detail: { error: e, retryCode: "pointTimeout" },
      });

      document.dispatchEvent(brokerError);
    }, 15000);
  }

  startTracking(
    elementList,
    stepId = null,
    stepOrderId = null,
    showGaze = false,
    containerTracking = false
  ) {
    if (typeof elementList == "object") {
      LumenEyeTracking.trackingBundle.init(
        stepId,
        stepOrderId,
        elementList,
        showGaze,
        LumenEyeTracking.newTrackingTables
      );
    } else {
      LumenEyeTracking.trackingBundle.init(
        stepId,
        stepOrderId,
        [],
        showGaze,
        LumenEyeTracking.newTrackingTables
      );
    }
    LumenEyeTracking.is_tracking = true;
    return true;
  }

  stopTracking(userId) {
    return new Promise((resolve) => {
      LumenEyeTracking.is_tracking = false;
      LumenEyeTracking.step_order_id++;
      LumenEyeTracking.trackingBundle.stopLogger(resolve, userId);
    });
  }

  completeSession(studyId, userId) {
    if (LumenEyeTracking.is_tracking == true) {
      return false;
    } else {
      return true;
    }
  }

  cleanup() {
    if (LumenEyeTracking.is_tracking == true) {
      return false;
    } else {
      LumenEyeTracking.trackingBundle = null;
      LumenEyeTracking.validationBundle = null;
      LumenEyeTracking.sessionId = null;
      LumenEyeTracking.reinitialised = false;
      LumenEyeTracking.eyePositionTimer = 0;
      LumenEyeTracking.validatedFlag = false;

      LumenEyeTracking.cameraOff();

      StorageHandler.removeItem("environmentVars");
      return true;
    }
  }

  cameraOff() {
    if (!!LumenEyeTracking.broker && !!LumenEyeTracking.broker.turnOffCamera) {
      LumenEyeTracking.broker.turnOffCamera();
    }
  }

  cameraOn() {
    if (!!LumenEyeTracking.broker.turnOnCamera) {
      LumenEyeTracking.broker.turnOnCamera();
    }
  }

  async inContextRedirect(sessionId, stepOrderId, nextButtonText = "Next") {
    return new Promise((resolve, reject) => {
      if (LumenEyeTracking.broker.saveModel) {
        LumenEyeTracking.broker.saveModel(
          "lr_model_data",
          "lr_errorcorrection_data"
        );
      }

      var environmentVars = {
        sessionId: sessionId,
        is_tracking: LumenEyeTracking.is_tracking,
        validation_step_id: LumenEyeTracking.validation_step_id,
        step_order_id: stepOrderId,
        startCalibrationTime: LumenEyeTracking.startCalibrationTime,
      };

      StorageHandler.setItem(
        "environmentVars",
        JSON.stringify(environmentVars)
      );

      ApiController.request.participation
        .savecalibration(nextButtonText)
        .call()
        .then((response) => {
          if (response.success) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

  processEyePos(eyePos, canvasWidth, canvasHeight) {
    eyePos.left_eye_out_y = eyePos.left_eye_out_y / canvasHeight;
    eyePos.left_eye_out_x = eyePos.left_eye_out_x / canvasWidth;
    eyePos.right_eye_out_y = eyePos.right_eye_out_y / canvasHeight;
    eyePos.right_eye_out_x = eyePos.right_eye_out_x / canvasWidth;
    var minEyePos;
    var maxEyePos;
    if (window.innerWidth <= 1023) {
      minEyePos = 0.1;
      maxEyePos = 0.9;
    } else {
      minEyePos = 0.3;
      maxEyePos = 0.7;
    }

    if (
      eyePos.left_eye_out_y > minEyePos &&
      eyePos.left_eye_out_y < maxEyePos &&
      eyePos.left_eye_out_x > minEyePos &&
      eyePos.left_eye_out_x < maxEyePos &&
      eyePos.right_eye_out_y > minEyePos &&
      eyePos.right_eye_out_y < maxEyePos &&
      eyePos.right_eye_out_x > minEyePos &&
      eyePos.right_eye_out_x < maxEyePos
    ) {
      return true;
    } else {
      return false;
    }
  }

  recalibrate(studyId, userId, element, timeout = 120000) {
    return LumenEyeTracking.calibrate(
      element,
      userId,
      studyId,
      (timeout = 120000)
    );
  }

  loadModel(timeout = 15000) {
    let setupStart = new Date().getTime();

    return new Promise((resolve, reject) => {
      function cleanUp() {}

      function onLoadModelFail(message) {
        LumenEyeTracking.lastError = new Error(message);
        LumenEyeTracking.lastError.name = "ModelLoadError";
        LumenEyeTracking.errorHandler(
          resolve,
          cleanUp,
          "loadModel",
          setupStart
        );
      }

      function onLoadModelSuccess() {
        cleanUp();

        resolve({ success: true });
      }

      var timeoutCounter = 0;
      if (timeout < 5000) {
        timeout = 5000;
      }
      const checkIfReady = async () => {
        if (
          !!LumenEyeTracking.broker.trackersController &&
          LumenEyeTracking.broker.trackersController.gazeDetector()
        ) {
          try {
            if (LumenEyeTracking.broker.loadModel) {
              await LumenEyeTracking.broker.loadModel(
                "lr_model_data",
                "lr_errorcorrection_data"
              )
            }
            onLoadModelSuccess();
          } catch {
            onLoadModelFail("Unable to load model.");
          }
        } else if (
          !LumenEyeTracking.broker.trackersController
        ) {
          try {
            if (LumenEyeTracking.broker.loadModel) {
              await LumenEyeTracking.broker.loadModel(
                "lr_model_data",
                "lr_errorcorrection_data"
              )
            }
            onLoadModelSuccess();
          } catch {
            onLoadModelFail("Unable to load model.");
          }
        } else if (timeoutCounter > timeout / LumenEyeTracking.tickRate) {
          console.log("Load model took too long.");
          onLoadModelFail("Load model took too long.");
        } else {
          timeoutCounter++;
          setTimeout(checkIfReady, LumenEyeTracking.tickRate);
        }
      };
      setTimeout(checkIfReady, LumenEyeTracking.tickRate);
    });
  }
}

let LumenEyeTracking = new _LumenEyeTracking();

export default LumenEyeTracking;
