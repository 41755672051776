import React from 'react';
import {connect} from 'react-redux';
import LSModal from '../modal'
import {toggleLanguageModal, selectLanguage} from '../actions'
import { findFlagUrlByIso2Code } from "country-flags-svg";

function LSLanguageModalCont(props) {
  var svgStyle = {maxHeight: "10px",
                  marginBottom: "2px", maxWidth: "15px"};
  var modalContent = <React.Fragment>
    <span className="link" onClick={props.selectLanguageEn}><img alt="GB flag" style={svgStyle} src={findFlagUrlByIso2Code("GB")} /> GB</span><br />
    <span className="link" onClick={props.selectLanguageDe}><img alt="DE flag" style={svgStyle} src={findFlagUrlByIso2Code("DE")} /> DE</span><br />
    <span className="link" onClick={props.selectLanguageFr}><img alt="FR flag" style={svgStyle} src={findFlagUrlByIso2Code("FR")} /> FR</span><br />
    <span className="link" onClick={props.selectLanguageIt}><img alt="IT flag" style={svgStyle} src={findFlagUrlByIso2Code("IT")} /> IT</span><br />
    <span className="link" onClick={props.selectLanguageEs}><img alt="ES flag" style={svgStyle} src={findFlagUrlByIso2Code("ES")} /> ES</span><br />
    <span className="link" onClick={props.selectLanguageJa}><img alt="JA flag" style={svgStyle} src={findFlagUrlByIso2Code("JP")} /> JA</span><br />
    <span className="link" onClick={props.selectLanguageNl}><img alt="NL flag" style={svgStyle} src={findFlagUrlByIso2Code("NL")} /> NL</span><br />
    <span className="link" onClick={props.selectLanguageBe}><img alt="BE flag" style={svgStyle} src={findFlagUrlByIso2Code("BE")} /> BE</span><br />
    <span className="link" onClick={props.selectLanguageSv}><img alt="SV flag" style={svgStyle} src={findFlagUrlByIso2Code("SE")} /> SV</span><br />
    <span className="link" onClick={props.selectLanguageNo}><img alt="NO flag" style={svgStyle} src={findFlagUrlByIso2Code("NO")} /> NO</span><br />
    <span className="link" onClick={props.selectLanguageId}><img alt="ID flag" style={svgStyle} src={findFlagUrlByIso2Code("ID")} /> ID</span><br />
    <span className="link" onClick={props.selectLanguageCl}><img alt="CL flag" style={svgStyle} src={findFlagUrlByIso2Code("CL")} /> CL</span><br />
    <span className="link" onClick={props.selectLanguageDa}><img alt="DK flag" style={svgStyle} src={findFlagUrlByIso2Code("DK")} /> DA</span><br />
    <span className="link" onClick={props.selectLanguageFi}><img alt="FI flag" style={svgStyle} src={findFlagUrlByIso2Code("FI")} /> FI</span><br />
    <span className="link" onClick={props.selectLanguageBr}><img alt="BR flag" style={svgStyle} src={findFlagUrlByIso2Code("BR")} /> BR</span><br />
    <span className="link" onClick={props.selectLanguageHr}><img alt="HR flag" style={svgStyle} src={findFlagUrlByIso2Code("HR")} /> HR</span><br />
    <span className="link" onClick={props.selectLanguageAr}><img alt="SA flag" style={svgStyle} src={findFlagUrlByIso2Code("SA")} /> AR</span><br />
    <span className="link" onClick={props.selectLanguageTr}><img alt="TR flag" style={svgStyle} src={findFlagUrlByIso2Code("TR")} /> TR</span><br />
    <span className="link" onClick={props.selectLanguagePl}><img alt="PL flag" style={svgStyle} src={findFlagUrlByIso2Code("PL")} /> PL</span><br />
    <span className="link" onClick={props.selectLanguageEsUs}><img alt="ES flag" style={svgStyle} src={findFlagUrlByIso2Code("ES")} /> ES (US)</span><br />
    <span className="link" onClick={props.selectLanguageSr}><img alt="RS flag" style={svgStyle} src={findFlagUrlByIso2Code("RS")} /> SR</span><br />
    <span className="link" onClick={props.selectLanguageVi}><img alt="VN flag" style={svgStyle} src={findFlagUrlByIso2Code("VN")} /> VI</span><br />
    <span className="link" onClick={props.selectLanguageZhTw}><img alt="TW flag" style={svgStyle} src={findFlagUrlByIso2Code("TW")} /> ZH (TW)</span><br />
    <span className="link" onClick={props.selectLanguageZhCn}><img alt="CN flag" style={svgStyle} src={findFlagUrlByIso2Code("CN")} /> ZH (CN)</span><br />
    <span className="link" onClick={props.selectLanguageMs}><img alt="MY flag" style={svgStyle} src={findFlagUrlByIso2Code("MY")} /> MS</span>
  </React.Fragment>
  return (
    props.displayLanguageModal
    ? <LSModal title="Language Selection" titleId="app.button.selectLanguage" content={modalContent} modalToggle={props.toggleLanguageModal}/>
    : null)
}

const mapStateToProps = function(state) {
  return {displayLanguageModal: state.modalReducer.displayLanguageModal}
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    toggleLanguageModal: () => dispatch(toggleLanguageModal()),
    selectLanguageEn: () => dispatch(selectLanguage("en")),
    selectLanguageDe: () => dispatch(selectLanguage("de")),
    selectLanguageFr: () => dispatch(selectLanguage("fr")),
    selectLanguageJa: () => dispatch(selectLanguage("ja")),
    selectLanguageIt: () => dispatch(selectLanguage("it")),
    selectLanguageEs: () => dispatch(selectLanguage("es")),
    selectLanguageNl: () => dispatch(selectLanguage("nl")),
    selectLanguageBe: () => dispatch(selectLanguage("be")),
    selectLanguageSv: () => dispatch(selectLanguage("sv")),
    selectLanguageNo: () => dispatch(selectLanguage("no")),
    selectLanguageId: () => dispatch(selectLanguage("id")),
    selectLanguageCl: () => dispatch(selectLanguage("cl")),
    selectLanguageDa: () => dispatch(selectLanguage("da")),
    selectLanguageFi: () => dispatch(selectLanguage("fi")),
    selectLanguageBr: () => dispatch(selectLanguage("br")),
    selectLanguageHr: () => dispatch(selectLanguage("hr")),
    selectLanguageAr: () => dispatch(selectLanguage("ar")),
    selectLanguageTr: () => dispatch(selectLanguage("tr")),
    selectLanguagePl: () => dispatch(selectLanguage("pl")),
    selectLanguageEsUs: () => dispatch(selectLanguage("es_US")),
    selectLanguageSr: () => dispatch(selectLanguage("sr")),
    selectLanguageVi: () => dispatch(selectLanguage("vi")),
    selectLanguageZhTw: () => dispatch(selectLanguage("zh")),
    selectLanguageZhCn: () => dispatch(selectLanguage("zh_CN")),
    selectLanguageMs: () => dispatch(selectLanguage("ms")),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LSLanguageModalCont);
