import { store } from "../../store/index";
import { REGIONS } from '../../types/types';

function urlWithRegion(noVer = false) {
    let currentRegion = store.getState().baseReducer.region;
    let endpoint = noVer ? process.env.REACT_APP_API_URL_NO_VER : process.env.REACT_APP_API_URL;
    if ((process.env.REACT_APP_LUMEN_ENV_VALUE !== 'release' && process.env.REACT_APP_LUMEN_ENV_VALUE !== 'alpha') || currentRegion === REGIONS.DEFAULT) {
        return endpoint
    } else {
        switch(currentRegion) {
        case REGIONS.AP:
            return endpoint.replace("api.", "api.ap.");
            break;
        case REGIONS.US:
            return endpoint.replace("api.", "api.us.");
            break;
        case REGIONS.US2:
            return endpoint.replace("api.", "api.us2.");
            break;
        case REGIONS.SA:
            return endpoint.replace("api.", "api.sa.");
            break;
        }
    }
}

export default urlWithRegion