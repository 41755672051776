const initialState = {
  previewActive: false,
  stepComplete: false,
  stepType: "",
  timeToMoveNext: 0,
  timeToShowNext: 0,
  showHud: false,
  startTimers: false,
  showNext: false,
  moveNext: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "INIT_PREVIEW":
      return {
        ...state,
        previewActive: true,
        stepComplete: false,
        showHud: false,
        startTimers: false,
        showNext: false,
        moveNext: false,
        ...action.payload,
      };
    case "PREVIEW_STEP_COMPLETE":
      return { ...state, stepComplete: true };
    case "PREVIEW_SHOW_HUD":
      return { ...state, showHud: action.payload };
    case "PREVIEW_START_TIMERS":
      return { ...state, startTimers: true };
    case "PREVIEW_SHOW_NEXT":
      return { ...state, showNext: true };
    case "PREVIEW_MOVE_NEXT":
      return { ...state, moveNext: true };
    default:
      return state;
  }
}
