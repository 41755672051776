import React from "react";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import gestureListener from "../../utils/listeners/gestureListener";

const Button = ({ singleClick, onClick, text, translationId, dataTestId }) => {
  let buttonRef = useRef();

  const handleClick = async () => {
    if (singleClick && buttonRef.current) {
      buttonRef.current.disabled = true;
    }

    await gestureListener.playVideo()

    onClick();
  };

  return (
    <button
      data-testid={dataTestId}
      className="ls-button"
      ref={buttonRef}
      onClick={handleClick}
    >
      {translationId ? (
        <FormattedMessage
          id={translationId}
          defaultMessage={text}
          description={`${text}-button`}
        ></FormattedMessage>
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
