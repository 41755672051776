import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const navigateWithParams = (newPath, newParams) => {
    let currentParams = window.location.pathname.split("/");
    if(newParams){
        history.push(`/${currentParams[1]}/${currentParams[2]}/${newPath}${newParams}`)
    } else {
        history.push(`/${currentParams[1]}/${currentParams[2]}/${newPath}`)
    }
    
}

const navigateWithOutParams = (newPath) => {
    let currentParams = window.location.pathname.split("/");
    history.push(newPath)
}

const navigateFromLastKnownPath = (lastKnownPath, newPath, newParams) => {
    let currentParams = lastKnownPath.split("/")
    if(newParams){
        history.push(`/${currentParams[1]}/${currentParams[2]}/${newPath}${newParams}`)
    } else {
        history.push(`/${currentParams[1]}/${currentParams[2]}/${newPath}`)
    }
}

const navigateToNewPath = (newPath, newParams) => {
    if(newParams){
        history.push(`${newPath}${newParams}`)
    } else {
        history.push(newPath)
    }
}

export { history, navigateWithParams, navigateWithOutParams, navigateFromLastKnownPath, navigateToNewPath};