import React from 'react';
import { IntlProvider, createIntl, createIntlCache } from "react-intl";
// import { addLocaleData } from "react-intl";
// import locale_en from "react-intl/locale-data/en";
// import locale_de from "react-intl/locale-data/de";
// import locale_fr from "react-intl/locale-data/fr";
// import locale_ja from "react-intl/locale-data/ja";
// import locale_it from "react-intl/locale-data/it";
// import locale_es from "react-intl/locale-data/es";
// import locale_nl from "react-intl/locale-data/nl";
// import locale_sv from "react-intl/locale-data/sv";
// import locale_no from "react-intl/locale-data/no";
// import locale_id from "react-intl/locale-data/id";
// import locale_da from "react-intl/locale-data/da";
// import locale_fi from "react-intl/locale-data/fi";
// import locale_pt from "react-intl/locale-data/pt";
import messages_en from "./en.json";
import messages_de from "./de.json";
import messages_fr from "./fr.json";
import messages_ja from "./ja.json";
import messages_it from "./it.json";
import messages_es from "./es.json";
import messages_nl from "./nl.json";
import messages_be from "./be.json";
import messages_sv from "./sv.json";
import messages_no from "./no.json";
import messages_id from "./id.json";
import messages_cl from "./cl.json";
import messages_da from "./da.json";
import messages_fi from "./fi.json";
import messages_br from "./br.json";
import messages_hr from "./hr.json";
import messages_ar from "./ar.json";
import messages_tr from "./tr.json";
import messages_pl from "./pl.json";
import messages_pt from "./pt.json";
import messages_es_us from "./es_US.json";
import messages_sr from "./sr.json";
import messages_th from "./th.json";
import messages_vi from "./vi.json";
import messages_ko from "./ko.json";
import messages_zh from './zh.json'
import messages_zh_cn from './zh_CN.json';
import messages_ms from "./ms.json";
import messages_ur from "./ur.json";
import messages_es_mx from "./es_MX.json";
import { store } from "../store/index";

// addLocaleData([
//   ...locale_en,
//   ...locale_de,
//   ...locale_fr,
//   ...locale_ja,
//   ...locale_it,
//   ...locale_es,
//   ...locale_nl,
//   ...locale_sv,
//   ...locale_no,
//   ...locale_id,
//   ...locale_da,
//   ...locale_fi,
//   ...locale_pt
// ]);

export const messages = {
  en: messages_en,
  de: messages_de,
  fr: messages_fr,
  ja: messages_ja,
  it: messages_it,
  es: messages_es,
  nl: messages_nl,
  be: messages_be,
  sv: messages_sv,
  no: messages_no,
  id: messages_id,
  cl: messages_cl,
  da: messages_da,
  fi: messages_fi,
  br: messages_br,
  hr: messages_hr,
  ar: messages_ar,
  tr: messages_tr,
  pl: messages_pl,
  pt: messages_pt,
  es_US: messages_es_us,
  sr: messages_sr,
  th: messages_th,
  vi: messages_vi,
  ko: messages_ko,
  zh: messages_zh,
  zh_CN: messages_zh_cn,
  cn: messages_zh_cn,
  ms: messages_ms,
  ur: messages_ur,
  es_MX: messages_es_mx,
  mx: messages_es_mx
};

const cache = createIntlCache()

export const customLocale = (selectedLanguage) => {
  if (selectedLanguage == "be") {
    return "nl";
  } else if (selectedLanguage == "cl" || selectedLanguage == "es_US" || selectedLanguage == "es_MX" || selectedLanguage == "mx") {
    return "es";
  } else if (selectedLanguage == "br") {
    return "pt"
  } else if (selectedLanguage == "zh" || selectedLanguage == "zh_CN" || selectedLanguage == "cn") {
    return "zh"
  } else {
    return selectedLanguage;
  }
};

export const IntlProviderWrapper = (props) => {
  const locale = customLocale(props.selectedLanguage);
  return (
    <IntlProvider
      locale={locale}
      defaultLocale='en'
      messages={messages[props.selectedLanguage]}
    >
      {props.children}
    </IntlProvider>
  );
};

export class LSIntlFormat {
  static IntlMessageFormat(id, defaultMessage) {
    const selectedLanguage = store.getState().modalReducer.selectedLanguage
    const locale = customLocale(selectedLanguage);
    const intl = createIntl(
      {
        // Locale of the application
        locale,
        // Locale of the fallback defaultMessage
        defaultLocale: 'en',
        messages: messages[selectedLanguage],
      },
      cache
    )
    return intl.formatMessage({ id: id, defaultMessage: defaultMessage });
  }
}
