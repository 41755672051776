import {
  updateDetails,
  updateDimensions,
} from "../../reducers/DeviceState/actions";
import platform from "platform";
import useragent from "useragent-ng";

class DeviceController {
  constructor() {
    this.isMobile = undefined;
    this.isIOS = undefined;
    this.store = undefined;
  }

  injectStore = (_store) => {
    this.store = _store;
  }

  updateDeviceInfo = () => {
    var agent = useragent.parse(navigator.userAgent);
    let isMobile =
      platform.os.family &&
      (platform.os.family.includes("iOS") ||
        platform.os.family.includes("Android"))
        ? true
        : false;

    let payload = {
      name: agent.family,
      version: platform.version,
      product: platform.product,
      manufacturer: platform.manufacturer,
      layout: platform.layout,
      os: platform.os,
      osString: this.getOS(),
      description: platform.description,
      iosModelPredicition: this.getiPhoneModel(),
      connectionType: !!navigator.connection
        ? navigator.connection.effectiveType
        : "4g",
      isMobile,
      device: this.getDevice(),
      graphics: this.getGPU(),
    };

    this.isMobile = isMobile;
    this.isIOS = payload.os.family.includes("iOS");

    if (this.store) {
      this.store.dispatch(updateDetails(payload));
    }

    return payload;
  };

  getDeviceInfo = () => {
    let { device_details, details_loaded } = this.store.getState().deviceReducer;

    if (details_loaded) {
      return device_details;
    } else {
      return this.updateDeviceInfo();
    }
  };

  updateDeviceDimensions = () => {
    let payload = {
      browser_zoom: this.getScale(),
      browser_width: window.outerWidth,
      browser_height: window.outerHeight,
      browser_left: window.screenX,
      browser_top: window.screenY,
      viewport_width:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      viewport_height:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
      screen_resolution_width: window.screen.width,
      screen_resolution_height: window.screen.height,
    };

    if (this.store) {
      this.store.dispatch(updateDimensions(payload));
    }

    return payload;
  };

  getDimensions = () => {
    let { device_dimensions, dimensions_loaded } =
      this.store.getState().deviceReducer;

    if (dimensions_loaded) {
      return device_dimensions;
    } else {
      return this.updateDeviceDimensions();
    }
  };

  getIsMobile = () => {
    if (this.isMobile !== undefined) {
      return this.isMobile;
    } else {
      return this.updateDeviceInfo().isMobile;
    }
  };

  getIsIOS = () => {
    if (this.isIOS !== undefined) {
      return this.isIOS;
    } else {
      return this.updateDeviceInfo().os.family.includes("iOS");
    }
  };

  getOS = () => {
    if (platform.os.family === "Windows") {
      return platform.os.family + " " + platform.os.version;
    } else if (platform.os.family === "OS X") {
      return "Mac " + platform.os.family;
    } else {
      return platform.os.family;
    }
  };

  getGPU = () => {
    let renderer = null;
    let vendor = null;

    let canvas = document.createElement("canvas");
    canvas.height = 0;
    canvas.width = 0;
    document.body.appendChild(canvas);

    let gl = canvas.getContext("webgl");

    if (gl) {
      var dbgRenderInfo = gl.getExtension("WEBGL_debug_renderer_info");
      if (dbgRenderInfo != null) {
        renderer = gl.getParameter(dbgRenderInfo.UNMASKED_RENDERER_WEBGL);
        vendor = gl.getParameter(dbgRenderInfo.UNMASKED_VENDOR_WEBGL);
      }
    }

    document.body.removeChild(canvas);

    let graphicsString = "";

    if (renderer && vendor) {
      graphicsString = `${renderer}-${vendor}`;
    } else if (renderer) {
      graphicsString = `${renderer}`;
    } else if (vendor) {
      graphicsString = `$?-${vendor}`;
    }

    return graphicsString;
  };

  getDevice = () => {
    if (
      platform.os.family &&
      (platform.os.family.includes("iOS") ||
        platform.os.family.includes("Android"))
    ) {
      return "Mobile";
    } else {
      return "Desktop";
    }
  };

  getiPhoneModel = () => {
    // Create a canvas element which can be used to retrieve information about the GPU.
    var canvas = document.createElement("canvas");
    if (canvas) {
      var context =
        canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
      if (context) {
        var info = context.getExtension("WEBGL_debug_renderer_info");
        if (info) {
          var renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
        }
      }
    }

    // iPhone X
    if (
      window.screen.height / window.screen.width === 812 / 375 &&
      window.devicePixelRatio == 3
    ) {
      return "iPhone X";
      // iPhone 6+/6s+/7+ and 8+
    } else if (
      window.screen.height / window.screen.width === 736 / 414 &&
      window.devicePixelRatio === 3
    ) {
      switch (renderer) {
        default:
          return "iPhone 6 Plus, 6s Plus, 7 Plus or 8 Plus";
        case "Apple A8 GPU":
          return "iPhone 6 Plus";
        case "Apple A9 GPU":
          return "iPhone 6s Plus";
        case "Apple A10 GPU":
          return "iPhone 7 Plus";
        case "Apple A11 GPU":
          return "iPhone 8 Plus";
      }
      // iPhone 6+/6s+/7+ and 8+ in zoom mode
    } else if (
      window.screen.height / window.screen.width === 667 / 375 &&
      window.devicePixelRatio === 3
    ) {
      switch (renderer) {
        default:
          return "iPhone 6 Plus, 6s Plus, 7 Plus or 8 Plus (display zoom)";
        case "Apple A8 GPU":
          return "iPhone 6 Plus (display zoom)";
        case "Apple A9 GPU":
          return "iPhone 6s Plus (display zoom)";
        case "Apple A10 GPU":
          return "iPhone 7 Plus (display zoom)";
        case "Apple A11 GPU":
          return "iPhone 8 Plus (display zoom)";
      }
      // iPhone 6/6s/7 and 8
    } else if (
      window.screen.height / window.screen.width === 667 / 375 &&
      window.devicePixelRatio === 2
    ) {
      switch (renderer) {
        default:
          return "iPhone 6, 6s, 7 or 8";
        case "Apple A8 GPU":
          return "iPhone 6";
        case "Apple A9 GPU":
          return "iPhone 6s";
        case "Apple A10 GPU":
          return "iPhone 7";
        case "Apple A11 GPU":
          return "iPhone 8";
      }
      // iPhone 5/5C/5s/SE or 6/6s/7 and 8 in zoom mode
    } else if (
      window.screen.height / window.screen.width === 1.775 &&
      window.devicePixelRatio === 2
    ) {
      switch (renderer) {
        default:
          return "iPhone 5, 5C, 5S, SE or 6, 6s, 7 and 8 (display zoom)";
        case "PowerVR SGX 543":
          return "iPhone 5 or 5c";
        case "Apple A7 GPU":
          return "iPhone 5s";
        case "Apple A8 GPU":
          return "iPhone 6 (display zoom)";
        case "Apple A9 GPU":
          return "iPhone SE or 6s (display zoom)";
        case "Apple A10 GPU":
          return "iPhone 7 (display zoom)";
        case "Apple A11 GPU":
          return "iPhone 8 (display zoom)";
      }
      // iPhone 4/4s
    } else if (
      window.screen.height / window.screen.width === 1.5 &&
      window.devicePixelRatio === 2
    ) {
      switch (renderer) {
        default:
          return "iPhone 4";
        case "PowerVR SGX 535":
          return "iPhone 4";
        case "PowerVR SGX 543":
          return "iPhone 4s";
      }
      // iPhone 1/3G/3GS
    } else if (
      window.screen.height / window.screen.width === 1.5 &&
      window.devicePixelRatio === 1
    ) {
      switch (renderer) {
        default:
          return "iPhone 3G";
        case "ALP0298C05":
          return "iPhone 3GS";
        case "S5L8900":
          return "iPhone 3G";
      }
    } else {
      return "Not an iPhone";
    }
  };

  getScale = () => {
    if (
      platform.os.family &&
      (platform.os.family.includes("iOS") ||
        platform.os.family.includes("Android"))
    ) {
      return window.visualViewport && window.visualViewport.scale
        ? Math.round(window.visualViewport.scale * 100)
        : 100;
    } else {
      return Math.round(window.devicePixelRatio * 100);
    }
  };
}

export default new DeviceController();
