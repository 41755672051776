import * as DEBUGLOG from "../../../components/util/debugLog";
import participationUrl from "../participationUrl";

const fail = async (studyId, body) => {
    let failRequestDate = DEBUGLOG.debugLogRequestStart("fail");
    let response = await fetch(participationUrl() + "/participation/studies/" + studyId + "/fail", {
      method: 'POST',
      body: JSON.stringify(body)})
    DEBUGLOG.debugLogRequestFinish(failRequestDate);

    if (response.ok) {
        let responseBody = await response.json()
        return responseBody
    } else {
      let e = new Error(response.statusText);
      e.name = response.status || "";
      throw e;
    }
}

export default fail